import React from 'react';
import { Link } from 'gatsby';

import {
    header,
    black,
    opened,
    container,
    closeBtn,
    logoBox,
    logoWatermark,
    closeIcon,
    navPanel,
} from './mobile-nav-panel.module.scss';
import LogoSygnet from '../../assets/images/svg/logo-sygnet.svg';

const MobileNavPanel = ({ children, closeFunc, open = false, dark = false, className = '' }) => {
    return (
        <>
            <div
                className={`
                    ${navPanel}
                    ${open ? opened : ''}
                    ${dark ? black : ''}
                    ${className}
                `}
            >
                <div className={header}>
                    <Link to="/">
                        <div className={logoBox}>
                            <LogoSygnet />
                        </div>
                    </Link>
                    <button onClick={closeFunc} className={closeBtn}>
                        zamknij <span className={closeIcon}>&#10005;</span>
                    </button>
                </div>
                <div className={container}>{children}</div>
                <div className={logoWatermark}>
                    <LogoSygnet />
                </div>
            </div>
        </>
    );
};

export default MobileNavPanel;
