import React from 'react';

import { wrapper, image, background } from './icon-ink.module.scss';

export const IconLink = ({ data }) => {
    const { Icon, link } = data;

    return (
        <div className={wrapper}>
            <div className={background} />
            <div className={image}>
                <a target="_blank" rel="noreferrer" href={link}>
                    <Icon />
                </a>
            </div>
        </div>
    );
};

export default IconLink;
