import { types } from './order-history.actions';

const { SET_ORDER_HISTORY, CLEAR_ORDER_HISTORY } = types;

export const initialState = {};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_ORDER_HISTORY:
            return {
                ...state,
                ...payload,
            };
        case CLEAR_ORDER_HISTORY:
            return initialState;
        default:
            return state;
    }
}
