// extracted by mini-css-extract-plugin
export var comments = "comments-module--comments--+CFxd";
export var container = "comments-module--container--80QN2";
export var disabledContent = "comments-module--disabled-content--oXliT";
export var form = "comments-module--form--0kY8D";
export var header = "comments-module--header--4q13N";
export var loading = "comments-module--loading--p-vC9";
export var noComments = "comments-module--no-comments--0L0br";
export var submitButton = "comments-module--submit-button--yVotV";
export var success = "comments-module--success--Byj6L";
export var successText = "comments-module--success-text--JP2fF";
export var textarea = "comments-module--textarea--91W3q";
export var title = "comments-module--title---WPsd";