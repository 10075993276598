import React from 'react';

import { config } from '../../config';
import gamificationReasons from '../../global-enums/gamification-reasons.json';

import GetPointsButton from '../organisms/get-points-button';
import GetPointsButtonWrapper from './get-points-button-wrapper';

const { personalTraining } = config;

export interface IMovieGroupPlayerPointsButtonProps {
    listTypeContext: string;
    movieId: number;
    trainingId: number;
    className?: string;
}

export default function MovieGroupPlayerPointsButton({
    movieId,
    trainingId,
    listTypeContext,
    className = '',
}: IMovieGroupPlayerPointsButtonProps) {
    let pointsReasonId = gamificationReasons.personalTrainingComplete;
    let pointsObjectId = trainingId;
    let text = 'Ukończyłem trening';

    if (listTypeContext === personalTraining.typesMap.additionals.key) {
        pointsReasonId = gamificationReasons.personalTrainingAdditionalWatched;
        pointsObjectId = movieId;
        text = 'Obejrzałem wideo';
    }

    return (
        <GetPointsButtonWrapper className={className}>
            <GetPointsButton reasonId={pointsReasonId} objectId={pointsObjectId}>
                {text}
            </GetPointsButton>
        </GetPointsButtonWrapper>
    );
}
