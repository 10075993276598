import { config } from '../../config';

import {
    WELCOME_SLIDES,
    types,
    setWelcomeSlidesAction,
    restoreInitialStateAction,
} from './welcome-slides.actions';
import { statusIdle } from '../ui/ui.actions';

const {
    GET_WELCOME_SLIDES,
    FETCH_WELCOME_SLIDES_REQUEST,
    FETCH_WELCOME_SLIDES_SUCCESS,
    FETCH_WELCOME_SLIDES_FAIL,
    CLEAR_WELCOME_SLIDES,
} = types;

const welcomeSlidesMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload } = action;
    next(action);

    switch (type) {
        case GET_WELCOME_SLIDES:
            const fetchConfig = {
                types: [
                    FETCH_WELCOME_SLIDES_REQUEST,
                    FETCH_WELCOME_SLIDES_SUCCESS,
                    FETCH_WELCOME_SLIDES_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.welcomeSlides.get.page(),
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: WELCOME_SLIDES,
                },
            };
            dispatch(fetchConfig);
            break;

        case FETCH_WELCOME_SLIDES_SUCCESS:
            dispatch(setWelcomeSlidesAction(payload.data.items));
            break;

        case CLEAR_WELCOME_SLIDES:
            dispatch(statusIdle(WELCOME_SLIDES));
            dispatch(restoreInitialStateAction());
            break;

        default:
            break;
    }
};

export default welcomeSlidesMiddleware;
