import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import { modal } from './subscription-type-info-modal.module.scss';

import Button from '../../atoms/button';
import CustomModal from '../../../templates/custom-modal';

const SubscriptionTypeInfo = ({ modalId, onConfirm }) => {
    const subscriptionProduct = useStaticQuery(query).product;

    const confirm = () => {
        navigate(`/sklep/${subscriptionProduct.slug}`);
        onConfirm();
    };

    return (
        <CustomModal modalId={modalId} className={modal} title="Niestety..." forceAction={true}>
            <p>Twój typ konta nie zgadza się z typem subskrypcji jaką chcesz zakupić.</p>
            <Button onClick={confirm} color="yellow">
                Wybierz inny plan
            </Button>
        </CustomModal>
    );
};

SubscriptionTypeInfo.propTypes = {
    modalId: PropTypes.number.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default SubscriptionTypeInfo;

export const query = graphql`
    query {
        product(productId: { eq: 1 }) {
            slug
        }
    }
`;
