import React, { PropsWithChildren } from 'react';

import { error } from './text-error.module.scss';

export interface ITextErrorProps {
    className?: string;
}

const TextError = ({ children, className = '' }: PropsWithChildren<ITextErrorProps>) => {
    return <div className={`${error} ${className}`}>{children}</div>;
};

export default TextError;
