import { types as apiTypes } from '../api/api.actions';

export const COURSES = '[COURSES]';

export const types = {
    // flow/commands actions
    GET_COURSES: `${COURSES} get list`,
    CLEAR_COURSES: `${COURSES} clear`,

    // API actions
    FETCH_COURSES_REQUEST: `${COURSES} ${apiTypes.API_REQUEST}`,
    FETCH_COURSES_SUCCESS: `${COURSES} ${apiTypes.API_SUCCESS}`,
    FETCH_COURSES_FAIL: `${COURSES} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_COURSES: `${COURSES} set list data`,
    RESTORE_INITIAL_STATE: `${COURSES} restore initial`,
};

// middleware actions
export const getCourses = ({ page, perPage, courseType, isMine }) => ({
    type: types.GET_COURSES,
    payload: { page, perPage, courseType, isMine },
});

export const clearCourses = () => ({
    type: types.CLEAR_COURSES,
});

// reducer actions
export const setCourses = (payload) => ({
    type: types.SET_COURSES,
    payload: payload,
});

export const restoreInitialState = () => ({
    type: types.RESTORE_INITIAL_STATE,
});
