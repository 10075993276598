import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';

interface ILinkProps extends React.PropsWithoutRef<GatsbyLinkProps<Record<string, any>>> {
    external?: boolean;
}

const Link: React.FC<ILinkProps> = ({ external, to, ...props }) => {
    if (external) {
        return <a href={to} target="_blank" rel="noopener noreferrer" {...props} />;
    }

    return <GatsbyLink to={to} {...props} />;
};

export default Link;
