import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { wrapper, content, stepsContainer } from './shop-steps.module.scss';
import { mainGrid } from '../../style/grid.module.scss';
import { eventMap, trackEvent } from '../../utills/FbAnalytics';
import {
    setBuyingProcessGlobalStep,
    validateBuyingProcessGlobalSteps,
} from '../../redux/buying-process/global/actions/buying-process-global-actions';
import {
    selectAllBuyingSteps,
    selectChosenStepId,
    selectComponentByName,
} from '../../redux/buying-process/global/selectors/buying-process-global-selectors';
import { selectCart, selectCartIsLoading } from '../../redux/shop/cart/cart.selectors';
import { selectLoaderByEntity } from '../../redux/ui/ui.selectors';
import { selectUserData } from '../../redux/user/user.selectors';
import { clearXsellingPromotions } from '../../redux/shop/order/order.actions';

import SEO from '../../components/seo';
import Main from '../../layouts/main';
import StepBar from '../../components/molecules/step-bar';
import Loader from '../../components/atoms/loader';
import { navigate } from 'gatsby';

export const ShopSteps = () => {
    const dispatch = useDispatch();

    const {
        isUserLogged,
        shopSteps,
        currentStep,
        orderLoading,
        cart,
        shopStepComponent,
        isCartLoading,
    } = useSelector((state) => {
        return {
            isUserLogged: selectUserData(state) !== null,
            shopSteps: selectAllBuyingSteps(state),
            currentStep: selectChosenStepId(state),
            orderLoading: selectLoaderByEntity(state, 'order'),
            isCartLoading: selectCartIsLoading(state),
            cart: selectCart(state),
            shopStepComponent: selectComponentByName(state),
        };
    });

    useEffect(() => {
        dispatch(validateBuyingProcessGlobalSteps());

        return () => {
            dispatch(clearXsellingPromotions());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isUserLogged && currentStep === 0) {
            dispatch(setBuyingProcessGlobalStep(1));
        }
    }, [isUserLogged, currentStep, dispatch]);

    useEffect(() => {
        // 🦞 Alert!
        // Navigate just in case the user lands on this page without anything in the cart.
        // The timeout is to ensure that navigation is performed first after
        // creating the order (to the card form or payment provider),
        // then the cart is deleted and before the redirection is performed,
        // the user has nothing in the cart
        const timeout = setTimeout(() => {
            if (!cart?.items?.length && !isCartLoading) {
                navigate('/koszyk/', { replace: true });
            }
        }, 500);

        if (cart?.items?.length > 0) {
            const items = cart.items.map((item) => ({
                id: `${item.product.productId}`,
                quantity: item.quantity,
            }));

            const itemsIds = cart.items.map((item) => `${item.product.productId}`);

            const analyticsParams = {
                content_ids: itemsIds,
                contents: items,
                currency: 'PLN',
                num_items: cart.items.length, //@todo: to ma być liczba pozycji w koszyku czy sumaryczna liczba sztuk?
                value: cart.price.gross,
            };

            trackEvent(eventMap.initiateCheckout, analyticsParams);
        }

        return () => clearTimeout(timeout);
    }, [cart, isCartLoading]);

    return (
        <>
            <SEO title="Kroki zakupu" />
            <Main isShopCart={true}>
                <div className={`${mainGrid} ${wrapper}`}>
                    {orderLoading && <Loader fullContainer={true} />}
                    <div className={stepsContainer}>
                        <StepBar activeStep={currentStep} steps={shopSteps} />
                    </div>
                    <div className={content}>{shopStepComponent}</div>
                </div>
            </Main>
        </>
    );
};

export default ShopSteps;
