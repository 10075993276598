import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    active,
    name,
    label,
    inactive,
    templateListItem,
} from './meal-planer-template-list.module.scss';
import Trash from '../../../assets/images/svg/icon-trash.svg';
import { types } from '../../../redux/meal-planer-template/meal-planer-template.actions';
import Separator from '../../atoms/separator';
import Button from '../../atoms/button';
import IconButton from '../../atoms/icon-button';

const MealPlannerTemplateList = ({ onChooseTemplate }) => {
    const dispatch = useDispatch();
    const items = useSelector((state) => state.mealPlanerTemplate.templateList);

    useEffect(() => {
        dispatch({ type: types.GET_TEMPLATE_LIST });
    }, [dispatch]);

    const deleteTemplate = (id) => {
        dispatch({ type: types.DEL_TEMPLATE, payload: { id } });
    };

    return (
        <div>
            <h4>Wybierz menu do edycji</h4>

            <Separator withMargin />

            <ul>
                {items.map((item) => {
                    const isActive = item.status === 1;
                    const restrictedAccess = item.auth.length > 0;
                    return (
                        <li key={`templ-${item.id}`} className={templateListItem}>
                            <div className={name}>
                                {item.name}
                                <span className={`${label} ${isActive ? active : inactive}`}>
                                    {isActive ? 'aktywny' : 'nieaktywny'}
                                </span>
                                <span className={`${label}`}>
                                    {restrictedAccess ? 'z uprawnieniami' : 'darmowy'}
                                </span>
                            </div>

                            <Button
                                size="extraSmall"
                                color="yellow"
                                onClick={() => onChooseTemplate(item.id)}
                            >
                                Wybierz
                            </Button>

                            <IconButton icon={Trash} onClick={() => deleteTemplate(item.id)} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

MealPlannerTemplateList.propTypes = {
    onChooseTemplate: PropTypes.func,
};

MealPlannerTemplateList.defaultProps = {
    onChooseTemplate: () => {},
};

export default MealPlannerTemplateList;
