// extracted by mini-css-extract-plugin
export var banner = "training-page-module--banner--mVGmR";
export var bannerContent = "training-page-module--bannerContent--1i9U7";
export var clues = "training-page-module--clues--1TQak";
export var description = "training-page-module--description--y09bZ";
export var loader = "training-page-module--loader--cCULc";
export var relatedExercises = "training-page-module--relatedExercises--Rsdnh";
export var relatedSeparator = "training-page-module--relatedSeparator--1835K";
export var seeAllButton = "training-page-module--seeAllButton--z9h0h";
export var separator = "training-page-module--separator--myGTU";
export var trainer = "training-page-module--trainer--YEDPA";
export var video = "training-page-module--video--Bo54C";
export var wrapper = "training-page-module--wrapper--oSFcX";