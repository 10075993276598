import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

const usePagination = ({
    entitySelector,
    action,
    clearAction,
    customPerPage,
    isUrlPaginated = true,
}) => {
    const { pathname, search } = useLocation();
    const dispatch = useDispatch();
    const { pages, pagination } = useSelector(entitySelector);
    const [page, setPage] = useState(null);

    const perPage = customPerPage || pagination.perPage;

    const getPage = (newPage) => {
        navigateToPage(pathname, newPage, false);
    };

    useEffect(() => {
        if (!isUrlPaginated) return;
        const urlPage = new URLSearchParams(search).get('strona');
        if (!urlPage) {
            navigateToPage(pathname, 1, true);
            return;
        }
        if (pagination.pageCount > 0 && urlPage > pagination.pageCount) {
            navigateToPage(pathname, pagination.pageCount, true);
            return;
        }
        setPage(urlPage);
    }, [search, pagination.pageCount, pathname, isUrlPaginated]);

    useEffect(() => {
        if (isUrlPaginated || page) return;
        setPage(1);
    }, [isUrlPaginated, page]);

    useEffect(() => {
        if (!page || pages[page]) return;
        dispatch(action(page, perPage));
    }, [page, pages, perPage, action, dispatch]);

    useEffect(() => {
        return () => dispatch(clearAction());
    }, [clearAction, dispatch]);

    return {
        page,
        pages,
        pagination,
        getPage: isUrlPaginated ? getPage : setPage,
    };
};

async function navigateToPage(pathname, pageNumber, replace) {
    await navigate(`${pathname}?strona=${pageNumber}`, {
        replace: replace,
    });
}

export default usePagination;
