import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    nav,
    post,
    logo,
    copy,
    footer,
    social,
    created,
    createdLogo,
    socialList,
    createdLink,
} from './footer.module.scss';
import { mainGrid } from '../../style/grid.module.scss';

import Logo from '../../assets/images/svg/logo.svg';
import LogoAlterpage from '../../assets/images/svg/logo_ap.svg';
import PostPreview from '../molecules/post-preview';
import SocialList from '../molecules/social-list';
import FooterNav from '../molecules/footer-nav';

const navZone = {
    title: 'Strefa przemian',
    links: [
        { target: '/', text: 'Home' },
        { target: '/o-nas/', text: 'O nas' },
        { target: '/cennik/', text: 'Cennik' },
        { target: '/sklep/', text: 'Sklep' },
        { target: '/blog/', text: 'Blog' },
        { target: '/faq/', text: 'FAQ' },
        { target: '/kontakt/', text: 'Kontakt' },
    ],
};

const navShopping = {
    title: 'Zakupy',
    links: [
        { target: '/zwroty', text: 'Zwroty' },
        { target: '/dostawa', text: 'Dostawa' },
    ],
};

const navService = {
    title: 'Serwis',
    links: [
        {
            target: '/docs/regulamin-serwisu-strefa-przemian.pdf',
            text: 'Regulamin serwisu',
            openInNewTab: true,
        },
    ],
};

const navHelp = {
    title: 'Pomoc',
    links: [{ target: '/kontakt/', text: 'Formularz kontaktowy' }],
};

const Footer = ({ siteMetadata }) => {
    const data = useStaticQuery(query);

    return (
        <footer className={`${mainGrid} ${footer}`}>
            <Logo className={logo} />

            <SocialList
                className={social}
                listStyle={socialList}
                externalLinks={data.allExternalLink.edges.map((edge) => edge.node)}
            />

            <section className={nav}>
                <FooterNav content={navZone} />
                <FooterNav content={navShopping} />
                <FooterNav content={navService} />
                <FooterNav content={navHelp} />
            </section>

            <section className={post}>
                {data.allPost.edges.map((post) => (
                    <PostPreview small={true} key={post.node.articleId} post={post.node} />
                ))}
            </section>

            <div className={copy}>
                Copyrights {siteMetadata.title} © {new Date().getFullYear()}
            </div>

            <div className={created}>
                Created by
                <a href="https://alterpage.pl/" className={createdLink}>
                    <LogoAlterpage className={createdLogo} />
                </a>
            </div>
        </footer>
    );
};

const query = graphql`
    {
        allPost(limit: 1, sort: { fields: publishedAt, order: DESC }) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        allExternalLink {
            edges {
                node {
                    linkId
                    name
                    uri
                }
            }
        }
    }
`;

export default Footer;
