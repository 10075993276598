import {
    types,
    viewAlreadySentModal,
    viewBuySubscriptionModal,
    setAlert as setGamificationAlert,
    clearAllAlerts,
    clearGamification,
    initGamification,
    setForbiddenReasonId,
} from './gamification.actions';
import userHaveRightsTo from '../../rbac/utils';
import { entity } from '../../rbac/permissions';
import { config } from '../../config';
import genericListMiddleware from '../generic-list/generic-list.middleware';
import { GAMIFICATION, GAMIFICATION_HISTORY, GAMIFICATION_TARIFF } from './gamification.reducer';
import { genActionName } from '../generic-list/generic-list.actions';
import { actions } from '../generic-list/generic-list.actions';
import isGamificationEnabled from '../../utills/gamification/is-gamification-enabled';
import { errorHandler } from '../../communication/utils';
import { setAlert } from '../alerts/alerts.actions';
import { selectForbiddenReasonId, selectIsForbiddenReasonId } from './gamification.selectors';

const baseGamificationMiddleware = (state) => (next) => (action) => {
    const { getState, dispatch } = state;
    const { type, payload, error, meta } = action;

    next(action);
    const rbac = getState().rbac;
    const metaReasonId = meta?.previousAction?.meta?.reasonId;
    const metaObjectId = meta?.previousAction?.meta?.objectId;
    const postValidateId = { reasonId: metaReasonId, objectId: metaObjectId };
    const forbiddenIds = selectForbiddenReasonId(getState());
    const isForbiddenId = selectIsForbiddenReasonId(postValidateId)(getState());

    switch (type) {
        case types.INIT_GAMIFICATION:
            dispatch({
                type: genActionName(
                    `${GAMIFICATION} ${GAMIFICATION_HISTORY}`,
                    actions.command.fetch
                ),
                payload: {
                    params: {
                        'per-page': 50,
                    },
                    url: config.endpoints.gamification.transactions,
                },
            });
            break;
        case types.POST_TRANSACTION:
            dispatch({
                types: [
                    types.FETCH_POST_TRANSACTIONS,
                    types.FETCH_POST_TRANSACTIONS_SUCCESS,
                    types.FETCH_POST_TRANSACTIONS_ERROR,
                ],
                payload: {
                    request: {
                        url: config.endpoints.gamification.transactions,
                        method: 'POST',
                        data: payload,
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: GAMIFICATION,
                    reasonId: payload.reasonId,
                    objectId: payload.objectId,
                },
            });
            break;
        case types.FETCH_POST_TRANSACTIONS_SUCCESS:
            if (!userHaveRightsTo(entity.SUBSCRIBER, rbac)) {
                dispatch(viewBuySubscriptionModal('joinAndUnlock'));
            }
            dispatch(setForbiddenReasonId([...forbiddenIds, postValidateId]));
            break;
        case types.FETCH_POST_TRANSACTIONS_ERROR:
            if (error.response.status === 403) {
                //FORBIDDEN - user need premium subscription
                dispatch(viewBuySubscriptionModal('joinAndGetPoints'));
            } else if (error.response.status === 409) {
                //CONFLICT - user has gotten points
                dispatch(viewAlreadySentModal());
            } else {
                const errors = errorHandler(action.error);
                errors.forEach((err) => dispatch(setAlert(err, GAMIFICATION)));
            }
            break;
        case types.UPDATE_TRANSACTIONS:
            dispatch({
                type: genActionName(
                    `${GAMIFICATION} ${GAMIFICATION_HISTORY}`,
                    actions.command.addItem
                ),
                payload: payload,
            });
            dispatch(setGamificationAlert(payload.transactionId));
            break;
        case types.CLEAR_GAMIFICATION:
            dispatch(clearAllAlerts());
            dispatch({
                type: genActionName(
                    `${GAMIFICATION} ${GAMIFICATION_HISTORY}`,
                    actions.document.clear
                ),
            });
            dispatch({
                type: genActionName(
                    `${GAMIFICATION} ${GAMIFICATION_TARIFF}`,
                    actions.document.clear
                ),
            });
            break;
        case types.RELOAD_GAMIFICATION:
            dispatch(clearGamification());
            dispatch(initGamification());
            break;

        case types.POST_TRANSACTION_VALIDATE:
            dispatch({
                types: [
                    types.FETCH_POST_TRANSACTIONS_VALIDATE,
                    types.FETCH_POST_TRANSACTIONS_VALIDATE_SUCCESS,
                    types.FETCH_POST_TRANSACTIONS_VALIDATE_ERROR,
                ],
                payload: {
                    request: {
                        url: config.endpoints.gamification.transactionsValidate,
                        method: 'POST',
                        data: payload,
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: GAMIFICATION,
                    reasonId: payload.reasonId,
                    objectId: payload.objectId,
                },
            });
            break;
        case types.FETCH_POST_TRANSACTIONS_VALIDATE_SUCCESS:
            if (isForbiddenId) {
                //usuń z tablicy ten id
                const newForbiddenIds = [...forbiddenIds];
                newForbiddenIds.splice(newForbiddenIds.indexOf(postValidateId), 1);
                dispatch(setForbiddenReasonId(newForbiddenIds));
            }
            break;
        case types.FETCH_POST_TRANSACTIONS_VALIDATE_ERROR:
            if (error.response.status === 409 && !isForbiddenId) {
                //dodaj do tablicy ten id
                dispatch(setForbiddenReasonId([...forbiddenIds, postValidateId]));
            }
            break;
        //no default
    }
};

const historyGamificationMiddleware = genericListMiddleware(GAMIFICATION, GAMIFICATION_HISTORY);
const tariffGamificationMiddleware = genericListMiddleware(GAMIFICATION, GAMIFICATION_TARIFF);

const gamificationMiddleware = isGamificationEnabled()
    ? [baseGamificationMiddleware, historyGamificationMiddleware, tariffGamificationMiddleware]
    : [];

export default gamificationMiddleware;
