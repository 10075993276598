import React from 'react';
import { useDispatch } from 'react-redux';

import { buttons, button, termButton, rightButtonsWrapper } from './partner-code-tile.module.scss';
import Terms from '../../assets/images/svg/terms.svg';
import useWriteToClipboard from '../../hooks/use-write-to-clipboard';
import { downloadFile } from '../../redux/download-files/download-files.actions';

import BusinessPartnerTile, { IBusinessPartnerTileProps } from './business-partner-tile';
import Button from '../atoms/button';
import IconButton from '../atoms/icon-button';
import LinkButton from '../atoms/link-button';

export default function PartnerCodeTile({ data, ...props }: IBusinessPartnerTileProps) {
    const { partner } = data;
    const { ref, isCopied, copyText } = useWriteToClipboard<HTMLParagraphElement>();
    const dispatch = useDispatch();

    const handleCopyCode = (code?: string) => async () => {
        await copyText(code);
    };

    const handleTermDownload = () => {
        const urlSections = partner.termUrl?.split('/');
        dispatch(
            downloadFile({
                url: partner.termUrl,
                type: 'application/json',
                filename: urlSections ? urlSections[urlSections.length - 1] : 'bez_nazwy.pdf',
            })
        );
    };

    return (
        <div>
            <BusinessPartnerTile codeRef={ref} noHover data={transformPartner(data)} {...props} />
            <div className={buttons}>
                <div className={rightButtonsWrapper}>
                    {partner.shopUrl && (
                        <LinkButton
                            className={button}
                            small
                            external
                            color="yellow"
                            to={partner.shopUrl}
                        >
                            Przejdź do sklepu
                        </LinkButton>
                    )}
                    <Button
                        className={button}
                        size="small"
                        outline
                        color="blank"
                        onClick={handleCopyCode(data.code)}
                    >
                        {isCopied ? 'Skopiowano' : 'Kopiuj'}
                    </Button>
                </div>
                {partner.termUrl && (
                    <IconButton
                        icon={Terms}
                        isUnderLine={false}
                        iconFilled={false}
                        className={termButton}
                        onClick={handleTermDownload}
                    >
                        Regulamin
                    </IconButton>
                )}
            </div>
        </div>
    );
}

const transformPartner = (partner: IBusinessPartnerTileProps['data']) => ({
    ...partner,
    cost: undefined, //we don't want cost badge
});
