import { types as apiTypes } from '../api/api.actions';

export const DOWNLOAD_FILE = '[DOWNLOAD_FILE]';

export const types = {
    DOWNLOAD_FILE: `${DOWNLOAD_FILE} get_data`,

    DOWNLOAD_FILE_REQUEST: `${DOWNLOAD_FILE} ${apiTypes.API_REQUEST}`,
    DOWNLOAD_FILE_SUCCESS: `${DOWNLOAD_FILE} ${apiTypes.API_SUCCESS}`,
    DOWNLOAD_FILE_FAIL: `${DOWNLOAD_FILE} ${apiTypes.API_FAIL}`,
};

/*
 * payload: {
 *  url: string;
 *  type: string; //application/pdf
 *  filename: string;
 * }
 */
export const downloadFile = (payload) => ({
    type: types.DOWNLOAD_FILE,
    payload,
});
