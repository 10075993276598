import React from 'react';

import { titleText, summarySectionContent } from './order-summary-section.module.scss';

const OrderSummarySection = ({ title, children, contentClassName }) => {
    return (
        <section>
            <h4 className={titleText}>{title}</h4>
            <div className={`${summarySectionContent} ${contentClassName}`}>{children}</div>
        </section>
    );
};

export default OrderSummarySection;
