import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import {
    button,
    title as titleClass,
    pillContainer,
    active,
    slider,
    pillInnerContainer,
    pillOuterContainer,
    pillSwitcher,
    small,
} from './pill-switcher.module.scss';
import { IOption } from '../../models/option.model';

type TAllowedValues = (number | string)[];
type TPillSwitcherSize = 'normal' | 'small';

export interface IPillSwitcherProps {
    options?: IOption[];
    title?: string;
    onChange?: (pill: IOption) => void;
    activeOption?: IOption;
    optionClassName?: string;
    className?: string;
    disabled?: boolean;
    allowedValues?: (number | string)[];
    tooltip?: boolean;
    buttonClassName?: string;
    size?: TPillSwitcherSize;
}

interface IPillDimensions {
    value?: string;
    width: number;
    left: number;
}

const PillSwitcher = ({
    options = [],
    title = '',
    onChange,
    activeOption = options[0],
    optionClassName = '',
    className = '',
    disabled = false,
    allowedValues,
    tooltip = false,
    buttonClassName = '',
    size = 'normal',
}: IPillSwitcherProps) => {
    const [activePill, setActivePill] = useState(activeOption || options[0]);
    const [pillsDimsArray, setPillsDimsArray] = useState<IPillDimensions[] | null>(null);

    const innerContainerRef = useRef<HTMLDivElement | null>(null);
    const sizeClass = sizeClasses[size];

    const calculatePillsDims = () => {
        if (innerContainerRef.current) {
            const pillsArr = Array.from(innerContainerRef.current.children) as HTMLDivElement[];
            setPillsDimsArray(
                pillsArr.map((pill) => ({
                    value: pill.dataset.value,
                    width: pill.clientWidth,
                    left: pill.offsetLeft,
                }))
            );
        }
    };

    const handlePillClick = (pill: IOption) => () => {
        if (activeOption === null) {
            setActivePill(pill);
        }
        if (onChange && typeof onChange === 'function') {
            onChange(pill);
        }
    };

    useEffect(() => {
        if (activeOption !== null) {
            setActivePill(activeOption);
        }
    }, [activeOption]);

    useLayoutEffect(() => {
        setTimeout(() => {
            calculatePillsDims();
        }, 0); // setTimeout to ensure that pills dimensions will be calculated properly
        window.addEventListener('resize', calculatePillsDims);

        return () => window.removeEventListener('resize', calculatePillsDims);
    }, []);

    return (
        <div className={`${pillSwitcher} ${className} ${sizeClass}`}>
            {title && <h6 className={titleClass}>{title}</h6>}
            <div className={pillOuterContainer}>
                <div className={pillContainer}>
                    <div
                        className={pillInnerContainer}
                        ref={innerContainerRef}
                        {...(tooltip ? { 'data-tip': tooltip } : {})}
                    >
                        {options.map((option, index) => {
                            return (
                                <button
                                    data-value={option.value}
                                    type="button"
                                    key={`pill-${index}`}
                                    className={`
                                        ${button} 
                                        ${getActiveOptionClassName({
                                            allowedValues,
                                            activeOption: activePill,
                                            option,
                                        })}
                                        ${optionClassName}
                                        ${buttonClassName}
                                    `}
                                    onClick={handlePillClick(option)}
                                    disabled={
                                        disabled || !isValueAllowed(allowedValues, option.value)
                                    }
                                >
                                    {option.label}
                                </button>
                            );
                        })}
                    </div>
                    {Array.isArray(pillsDimsArray) &&
                    pillsDimsArray.length > 0 &&
                    isValueAllowed(allowedValues, activePill.value) ? (
                        <div
                            className={slider}
                            style={{
                                width: `${
                                    pillsDimsArray[getPillIndex(activePill, options)].width
                                }px`,
                                left: `${pillsDimsArray[getPillIndex(activePill, options)].left}px`,
                            }}
                        />
                    ) : null}
                </div>
            </div>
            {tooltip && <ReactTooltip />}
        </div>
    );
};

const sizeClasses: Record<TPillSwitcherSize, string> = {
    normal: '',
    small: small,
};

interface IGetActiveOptionClassNameProps {
    allowedValues?: TAllowedValues;
    activeOption: IOption;
    option: IOption;
}

const getActiveOptionClassName = ({
    allowedValues,
    activeOption,
    option,
}: IGetActiveOptionClassNameProps) => {
    if (allowedValues && allowedValues.length === 0) {
        return '';
    }

    if (activeOption.value === option.value) {
        return active;
    }

    return '';
};

function getPillIndex(pill: IOption, options: IOption[]) {
    return options.findIndex((option) => pill.value === option.value);
}

const isValueAllowed = (allowedValues: TAllowedValues | undefined, value: string | number) => {
    if (!allowedValues) {
        return true;
    }

    return allowedValues.includes(value);
};

export default PillSwitcher;
