import { types as apiTypes } from '../api/api.actions';
import { ADDRESSES } from '../addresses/addresses.actions';

export const ADDRESS = '[ADDRESS]';

export const types = {
    // flow/commands actions
    GET_ADDRESS: `${ADDRESS} get list`,
    CREATE_ADDRESS: `${ADDRESS} create`,
    EDIT_ADDRESS: `${ADDRESS} edit`,
    DELETE_ADDRESS: `${ADDRESS} delete`,
    CLEAR_ADDRESS: `${ADDRESS} clear`,

    // API actions
    FETCH_ADDRESS_REQUEST: `${ADDRESS} ${apiTypes.API_REQUEST}`,
    FETCH_ADDRESS_SUCCESS: `${ADDRESS} ${apiTypes.API_SUCCESS}`,
    FETCH_ADDRESS_FAIL: `${ADDRESS} ${apiTypes.API_FAIL}`,

    CREATE_ADDRESS_REQUEST: `${ADDRESS} ${apiTypes.API_REQUEST} create`,
    CREATE_ADDRESS_SUCCESS: `${ADDRESS} ${apiTypes.API_SUCCESS} create`,
    CREATE_ADDRESS_FAIL: `${ADDRESS} ${apiTypes.API_FAIL} create`,

    EDIT_ADDRESS_REQUEST: `${ADDRESS} ${apiTypes.API_REQUEST} edit`,
    EDIT_ADDRESS_SUCCESS: `${ADDRESS} ${apiTypes.API_SUCCESS} edit`,
    EDIT_ADDRESS_FAIL: `${ADDRESS} ${apiTypes.API_FAIL} edit`,

    DELETE_ADDRESS_REQUEST: `${ADDRESS} ${apiTypes.API_REQUEST} delete`,
    DELETE_ADDRESS_SUCCESS: `${ADDRESS} ${apiTypes.API_SUCCESS} delete`,
    DELETE_ADDRESS_FAIL: `${ADDRESS} ${apiTypes.API_FAIL} delete`,

    // writing actions (reducer)
    SET_ADDRESS: `${ADDRESS} set`,
    RESTORE_INITIAL_STATE: `${ADDRESSES} restore initial state`,
};

// middleware actions
export const getAddressAction = (addressId) => ({
    type: types.GET_ADDRESS,
    payload: addressId,
});

export const createAddressAction = (data, formikBag) => ({
    type: types.CREATE_ADDRESS,
    payload: data,
    meta: { formikBag },
});

export const editAddressAction = (addressId, data, formikBag) => ({
    type: types.EDIT_ADDRESS,
    payload: { addressId, data },
    meta: { formikBag },
});

export const deleteAddressAction = (addressId) => ({
    type: types.DELETE_ADDRESS,
    payload: addressId,
});

// reducer actions
export const setAddressAction = (data) => ({
    type: types.SET_ADDRESS,
    payload: data,
});

export const restoreInitialStateAction = () => ({
    type: types.RESTORE_INITIAL_STATE,
});

export const clearAddressAction = () => ({
    type: types.CLEAR_ADDRESS,
});
