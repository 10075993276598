import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
    container,
    companyInput,
    streetInput,
    houseInput,
    apartmentInput,
    cityInput,
    zipInput,
    taxInput,
} from './address-invoice-fields.module.scss';
import { config } from '../../config';

import Input from '../atoms/form-poc/input';

const { required } = config.messages.form;

export const addressInvoiceInitialValues = {
    companyName: '',
    street: '',
    houseNumber: '',
    apartmentNumber: '',
    city: '',
    zipCode: '',
    taxNumber: '',
};

export const addressInvoiceValidationSchema = {
    companyName: Yup.string().required(required),
    street: Yup.string().required(required),
    houseNumber: Yup.string().required(required),
    apartmentNumber: Yup.string().nullable(true),
    city: Yup.string().required(required),
    zipCode: Yup.string().required(required),
    taxNumber: Yup.string().required(required),
};

const AddressInvoiceFields = ({ className, prefixes }) => {
    return (
        <div className={`${container} ${className}`}>
            <Input
                containerClass={companyInput}
                name={[...prefixes, 'companyName'].join('.')}
                placeholder="Nazwa firmy"
            />
            <Input
                containerClass={streetInput}
                name={[...prefixes, 'street'].join('.')}
                placeholder="Ulica"
            />
            <Input
                containerClass={houseInput}
                name={[...prefixes, 'houseNumber'].join('.')}
                placeholder="Numer domu"
            />
            <Input
                containerClass={apartmentInput}
                name={[...prefixes, 'apartmentNumber'].join('.')}
                placeholder="Numer mieszkania"
            />
            <Input
                containerClass={cityInput}
                name={[...prefixes, 'city'].join('.')}
                placeholder="Miasto"
            />
            <Input
                containerClass={zipInput}
                name={[...prefixes, 'zipCode'].join('.')}
                placeholder="Kod pocztowy"
            />
            <Input
                containerClass={taxInput}
                name={[...prefixes, 'taxNumber'].join('.')}
                placeholder="Numer NIP"
            />
        </div>
    );
};

AddressInvoiceFields.propTypes = {
    className: PropTypes.string,
    prefixes: PropTypes.arrayOf(PropTypes.string),
    hasLabel: PropTypes.bool,
};

AddressInvoiceFields.defaultProps = {
    className: '',
    prefixes: [],
    hasLabel: true,
};

export default AddressInvoiceFields;
