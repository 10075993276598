import { useEffect, useState } from 'react';

import useGenericList, { IUseGenericList } from './use-generic-list';

export default function useAutoFetchGenericList(props: IUseGenericList) {
    const listData = useGenericList(props);
    const [listReloaded, setListReloaded] = useState(false);

    const { getNextPage, forceClear } = listData;

    useEffect(() => {
        if (!listReloaded) {
            getNextPage();
        }

        setListReloaded(true);
    }, [getNextPage, listReloaded]);

    useEffect(() => {
        return () => {
            forceClear();
        };
    }, [forceClear]);

    return listData;
}
