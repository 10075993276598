import { addRecipeToFavorites, removeRecipeFromFavorites } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';
import { addModalAction } from '../../../actions/actions-modals';
import { setSingleProfile } from '../../../profile/profile.actions';
import { getCurrentProfile } from '../../../profile/profile.selectors';
import { FAVORITE, RECIPES } from '../../recipes.reducer';
import { genActionName, actions } from '../../../generic-list/generic-list.actions';
import { clearFavoriteRecipesAction } from '../../favorite/actions/clear-favorite-recipes';

export const toggleIsFavoriteRecipeAction = (recipeId, profileId, isFavorite) => async (
    dispatch,
    getState
) => {
    dispatch(toggleIsFavoriteRecipeStartAction());
    try {
        const currentProfile = getCurrentProfile(getState());
        const favouriteRecipeIds = currentProfile.favouriteRecipeIds || [];
        let newFavouriteRecipes;

        if (isFavorite) {
            await addRecipeToFavorites(recipeId, profileId);
            newFavouriteRecipes = [...favouriteRecipeIds, recipeId];
            dispatch(clearFavoriteRecipesAction());
        } else {
            await removeRecipeFromFavorites(recipeId, profileId);
            newFavouriteRecipes = favouriteRecipeIds.filter((likedId) => likedId !== recipeId);

            const favoritesCollection = getState()[RECIPES][FAVORITE];
            const items = favoritesCollection.items.filter((item) => item.id !== recipeId);
            const newFavorites = {
                ...favoritesCollection,
                items: items,
            };

            dispatch({
                type: genActionName(`${RECIPES} ${FAVORITE}`, actions.document.set),
                payload: newFavorites,
            });
        }
        dispatch(
            setSingleProfile({
                ...currentProfile,
                favouriteRecipeIds: newFavouriteRecipes,
            })
        );
        dispatch(toggleIsFavoriteRecipeSuccessAction());
    } catch {
        dispatch(
            addModalAction({
                type: 'error',
                title: 'Coś poszło nie tak!',
                content: isFavorite
                    ? `Nie udało się dodać przepisu do ulubionych`
                    : `Nie udało się usunąć przepisu z ulubionych`,
            })
        );
        dispatch(toggleIsFavoriteRecipeErrorAction());
    }
};

export const toggleIsFavoriteRecipeClearSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.toggleIsFavorite,
        entitiesMap.recipes.single.allowedActions.clearSuccess
    ),
});

export const toggleIsFavoriteRecipeClearErrorAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.toggleIsFavorite,
        entitiesMap.recipes.single.allowedActions.clearErrors
    ),
});

const toggleIsFavoriteRecipeStartAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.toggleIsFavorite,
            entitiesMap.recipes.single.allowedActions.start
        ),
        payload: {},
    };
};

const toggleIsFavoriteRecipeSuccessAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.toggleIsFavorite,
            entitiesMap.recipes.single.allowedActions.success
        ),
        payload: {},
    };
};

const toggleIsFavoriteRecipeErrorAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.toggleIsFavorite,
            entitiesMap.recipes.single.allowedActions.error
        ),
        payload: {},
    };
};
