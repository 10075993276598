import { types } from './address.actions';

const { SET_ADDRESS, RESTORE_INITIAL_STATE } = types;

export const initialState = null;

const addressReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_ADDRESS:
            return payload;
        case RESTORE_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default addressReducer;
