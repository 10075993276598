import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '../config';
import { selectLoaderByEntity } from '../redux/ui/ui.selectors';
import { selectList } from '../redux/generic-list/generic-list.selectors';
import { actions, genActionName } from '../redux/generic-list/generic-list.actions';
import { GenericListFilters, GenericListSort } from '../models/generic-list.model';

const { apiStatusMap } = config;

const apiStatuses = [apiStatusMap.fail, apiStatusMap.loading];
const {
    fetch: fetchAction,
    sort: sortAction,
    filter: filterAction,
    search: searchAction,
} = actions.command;

export interface IUseGenericList {
    entity: string;
    subEntity: string;
    perPage?: number;
    url: string;
    expand?: string;
}

const INITIAL_LOCAL_CURRENT_PAGE = -1;

export default function useGenericList({
    entity,
    subEntity,
    perPage = 12,
    url,
    expand,
}: IUseGenericList) {
    const [localCurrentPage, setLocalCurrentPage] = useState(INITIAL_LOCAL_CURRENT_PAGE);
    const dispatch = useDispatch();
    const { apiStatus, collection } = useSelector((state) => {
        return {
            apiStatus: selectLoaderByEntity(state, `${entity} ${subEntity}`),
            collection: selectList(state, entity, subEntity),
        };
    });

    const entityName = `${entity} ${subEntity}`;
    const { filters, sort, pagination, items, search } = collection;

    const getNextPage = useCallback(() => {
        const doesNotListHavePagesToFetch = pagination.currentPage >= pagination.pageCount;
        const cantFetch = pagination.currentPage === localCurrentPage;
        const isLoading = apiStatus === apiStatusMap.loading;
        const isError = apiStatus === apiStatusMap.fail;

        if (doesNotListHavePagesToFetch || cantFetch || isError || isLoading) {
            return;
        }

        dispatch({
            type: genActionName(entityName, fetchAction),
            payload: {
                params: {
                    'per-page': perPage,
                    expand,
                },
                url,
            },
        });

        setLocalCurrentPage(pagination.currentPage);
    }, [dispatch, entityName, perPage, expand, url, pagination, localCurrentPage, apiStatus]);

    const applyFilters = (filters: GenericListFilters) => {
        if (!apiStatuses.includes(apiStatus)) {
            dispatch({
                type: genActionName(entityName, filterAction),
                payload: {
                    filters,
                    url,
                    params: {
                        'per-page': perPage,
                        expand,
                    },
                },
            });
            setLocalCurrentPage(INITIAL_LOCAL_CURRENT_PAGE);
        }
    };

    const applySort = (sort: GenericListSort) => {
        if (!apiStatuses.includes(apiStatus)) {
            dispatch({
                type: genActionName(entityName, sortAction),
                payload: {
                    sort,
                    url,
                    params: {
                        'per-page': perPage,
                        expand,
                    },
                },
            });
            setLocalCurrentPage(INITIAL_LOCAL_CURRENT_PAGE);
        }
    };

    const handleSearchChange = (searchValue: string, callbackData: Record<'key', string>) => {
        const { key } = callbackData;
        dispatch({
            type: genActionName(entityName, searchAction),
            payload: {
                key,
                value: searchValue,
                url,
                params: {
                    'per-page': perPage,
                    expand,
                },
            },
        });
        setLocalCurrentPage(INITIAL_LOCAL_CURRENT_PAGE);
    };

    const forceClear = useCallback(() => {
        dispatch({
            type: genActionName(entityName, actions.document.clear),
        });
    }, [dispatch, entityName]);

    const clear = useCallback(() => {
        if (!apiStatuses.includes(apiStatus)) {
            forceClear();
        }
    }, [apiStatus, forceClear]);

    return {
        apiStatus,
        filters,
        sort,
        pagination,
        items,
        search,
        entityName,
        getNextPage,
        applyFilters,
        applySort,
        handleSearchChange,
        forceClear,
        clear,
    };
}
