import React from 'react';

import { bold as boldClass, light as lightClass, title } from './title.module.scss';

export interface ITitleProps {
    Tag?: React.ElementType;
    className?: string;
    children?: React.ReactNode;
    light?: boolean;
    bold?: boolean;
}

const Title = ({ Tag = 'h2', className = '', children, light, bold }: ITitleProps) => (
    <Tag
        className={`${title} 
            ${light ? lightClass : ''} 
            ${bold ? boldClass : ''}
            ${className}`}
    >
        {children}
    </Tag>
);

export default Title;
