// extracted by mini-css-extract-plugin
export var buttonClose = "gallery-modal-module--button-close--nz69a";
export var container = "gallery-modal-module--container--RvX+G";
export var dot = "gallery-modal-module--dot--csUZj";
export var dots = "gallery-modal-module--dots--DydAp";
export var hiddenCloseButton = "gallery-modal-module--hidden-close-button--iGRvA";
export var img = "gallery-modal-module--img--whbYx";
export var next = "gallery-modal-module--next--5AWZc";
export var prev = "gallery-modal-module--prev--HOyOI";
export var ratioBox = "gallery-modal-module--ratio-box--2Vlek";
export var slide = "gallery-modal-module--slide--fNSKP";
export var slider = "gallery-modal-module--slider--L49GE";
export var sliderContainer = "gallery-modal-module--slider-container--qci-u";