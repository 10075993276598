import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import {
    modal,
    button,
    separator,
    loader,
    form,
    error,
    grid,
    infiniteContainer,
    noContent,
    noSelection,
} from './choose-plan-modal.module.scss';
import { config } from '../../../config';
import { MEAL_PLANER } from '../../../redux/meal-planer/meal-planer.actions';
import {
    MEAL_PLANER_TEMPLATE,
    types,
} from '../../../redux/meal-planer-template/meal-planer-template.actions';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { addModalAction } from '../../../redux/actions/actions-modals';

import InputSelect from '../../atoms/form-poc/input-select';
import Button from '../../atoms/button';
import Loader from '../../atoms/loader';
import InfiniteContainer from '../../hoc/infinite-continer';
import Separator from '../../atoms/separator';
import PlanCard from '../../molecules/plan-card';
import CustomModal from '../../../templates/custom-modal';

const initialValues = {
    dietTypes: [],
};

const statusMap = config.apiStatusMap;

const ChoosePlanModal = ({ modalId, confirm, onPlanSelectApproval }) => {
    const dietTypes = useStaticQuery(query).allTag.edges.map((edge) => ({
        label: edge.node.name,
        value: edge.node.tagId,
    }));

    const [selectedDietTypes, setSelectedDietTypes] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [actualPage] = useState(0);
    const [pageCount] = useState(0);

    const plans = useSelector((state) => state.mealPlanerTemplate.templateList);
    const templateStatus = useSelector((state) =>
        selectLoaderByEntity(state, MEAL_PLANER_TEMPLATE)
    );
    const status = useSelector((state) => selectLoaderByEntity(state, MEAL_PLANER));

    const dispatch = useDispatch();

    const handleDietChange = async (formik) => {
        await formik.submitForm();
    };

    const handleSubmit = (formValues) => {
        const newDietTypes = formValues.dietTypes.map((item) => item.value);
        setSelectedDietTypes(newDietTypes);
    };

    const handlePlanSelectApproval = () => {
        if (confirm) {
            dispatch(
                addModalAction({
                    modalKey: 'CONFIRMATION_MODAL',
                    modalProps: {
                        title: 'Czy na pewno?',
                        content: 'Zawartość planera zostanie zastąpiona wybranym planem.',
                        onConfirm: () => {
                            onPlanSelectApproval(selectedPlan);
                        },
                    },
                })
            );
        } else {
            onPlanSelectApproval(selectedPlan);
        }
    };

    const handleScroll = () => {
        getPlans(selectedDietTypes);
    };

    const getPlans = (dietTypes) => {
        const tagIds = dietTypes.reduce((acc, item, index) => {
            return index === 0 ? `${item}` : `${acc},${item}`;
        }, '');

        dispatch({
            type: types.GET_TEMPLATE_LIST,
            payload: {
                params: tagIds.length ? { 'filter[tags]': tagIds } : null,
            },
        });
    };

    const renderContent = () => {
        if (templateStatus === statusMap.fail) {
            return <div className={error}>Wystąpił błąd przy pobieraniu planów.</div>;
        }

        if (templateStatus === statusMap.idle && !plans.length) {
            return (
                <div className={noSelection}>Aby zobaczyć dostępne plany wybierz typ diety.</div>
            );
        }

        if (templateStatus === statusMap.loading && !plans.length) {
            return <Loader className={loader} />;
        }

        if (templateStatus === statusMap.success && !plans.length) {
            return <div className={noContent}>Brak planów o wybranych parametrach.</div>;
        }

        return (
            <InfiniteContainer
                className={infiniteContainer}
                loading={status === statusMap.loading || templateStatus === statusMap.loading}
                disabled={
                    status === statusMap.loading ||
                    templateStatus === statusMap.loading ||
                    actualPage === pageCount
                }
                onScroll={handleScroll}
                loaderFullContainer
            >
                <div className={grid}>
                    {plans.map((plan, index) => {
                        return (
                            <PlanCard
                                key={`plan-card-${plan.id}`}
                                plan={plan}
                                order={index + 1}
                                selected={selectedPlan?.id === plan.id}
                                onClick={(plan) => setSelectedPlan(plan)}
                            />
                        );
                    })}
                </div>
            </InfiniteContainer>
        );
    };

    useEffect(() => {
        setSelectedPlan(null);
        getPlans(selectedDietTypes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDietTypes]);

    return (
        <CustomModal
            modalId={modalId}
            className={modal}
            title="Skorzystaj z jadłospisów Strefy Przemian"
        >
            {dietTypes && dietTypes.length > 0 && (
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {(formik) => (
                        <Form className={form}>
                            <InputSelect
                                name="dietTypes"
                                label="Typ diety"
                                options={dietTypes}
                                isSearchable={false}
                                isMulti={true}
                                onChange={() => handleDietChange(formik)}
                            />
                        </Form>
                    )}
                </Formik>
            )}

            {renderContent()}

            <Separator className={separator} />

            <Button
                className={button}
                onClick={handlePlanSelectApproval}
                size="small"
                color="yellow"
                disabled={status === statusMap.loading || !selectedPlan}
            >
                Skorzystaj
            </Button>
        </CustomModal>
    );
};

ChoosePlanModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    confirm: PropTypes.bool,
    dietTypes: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    onPlanSelectApproval: PropTypes.func,
};

ChoosePlanModal.defaultProps = {
    confirm: false,
    dietTypes: [],
    onPlanSelectApproval: () => {},
};

export default ChoosePlanModal;

export const query = graphql`
    query {
        allTag(filter: { type: { eq: 3 } }) {
            edges {
                node {
                    tagId
                    name
                }
            }
        }
    }
`;
