import { config } from '../../../config';
import { CART, sendCart, setCartData, setErrors, types } from './cart.actions';
import { localStorageKeys } from '../../utills/local-storage-keys';
import { errorHandler } from '../../../communication/utils';
import { clearAllAlerts, setAlert } from '../../alerts/alerts.actions';
import {
    orderValidate,
    removePayByPointsItem,
    requestPayByPoints,
    submitOrder,
} from '../order/order.actions';
import { removeModalAction } from '../../actions/actions-modals';
import { eventMap, trackEvent } from '../../../utills/FbAnalytics';
import { pickField } from '../../../utills/data-convertion';
import { navigate } from 'gatsby';

const { GUEST_CART } = localStorageKeys;

const { endpoints } = config;

const successMsg = {
    type: 'success',
    content: `Udało się zaktualizować koszyk`,
};

const cartMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    next(action);

    const cart = getState().shop.cart;

    switch (type) {
        case types.ADD_ITEM:
            dispatch(
                sendCart(
                    { items: [{ ...payload, action: `add` }] },
                    { ...meta, action: `add`, product: payload }
                )
            );
            break;

        case types.DEL_ITEM:
            dispatch(
                sendCart(
                    { items: [{ ...payload, action: `delete` }] },
                    { ...meta, action: `delete` }
                )
            );
            break;

        case types.PREPARE_CART_DATA:
            dispatch(clearAllAlerts());

            const fetchData = {
                types: [types.SEND_CART_REQUEST, types.SEND_CART_SUCCESS, types.SEND_CART_FAIL],
                payload: {
                    request: {
                        method: cart && cart.id ? 'put' : 'post',
                        url: endpoints.shop.cart(cart?.id),
                        data: {
                            items: payload.items,
                            price: getState().shop.cart?.price,
                        },
                        params: {
                            expand: `items,items.product,items.variant,items.discounts,items.variant.attributesValues,items.product.media,items.productPromotions`,
                        },
                        withCredentials: true,
                    },
                },
                meta: meta,
            };
            dispatch(fetchData);
            break;

        case types.SEND_CART_SUCCESS:
            dispatch(setCartData({ ...payload.data }));

            if (meta?.previousAction?.meta?.submitAfter) {
                dispatch(submitOrder());
            }

            if (meta?.previousAction?.meta?.validateAfter) {
                dispatch(orderValidate());
            }

            if (meta?.previousAction.meta?.payByPointsData) {
                const newItems = payload.data.items.map(pickField('itemId')).sort();
                const oldItems = cart.items.map(pickField('itemId')).sort();

                // TODO: add promotionId and productId
                if (!oldItems.includes(newItems.at(-1))) {
                    dispatch(
                        requestPayByPoints({
                            itemId: newItems.at(-1),
                            productId: meta?.previousAction.meta?.payByPointsData.productId,
                            promotionId: meta?.previousAction.meta?.payByPointsData.promotionId,
                            checked: true,
                            amount:
                                meta?.previousAction.meta?.payByPointsData.labelPointsBenefit
                                    .pointsAmount,
                        })
                    );
                }
            }

            if (meta?.previousAction?.meta?.modalId) {
                dispatch(removeModalAction(meta.previousAction.meta.modalId));
            }

            if (meta?.previousAction?.meta?.action === `add`) {
                trackEvent(eventMap.addToCart, {
                    content_type: 'product',
                    contents: [
                        {
                            id: `${meta.previousAction.meta.product.productId}`,
                            quantity: meta.previousAction.meta.product.quantity,
                        },
                    ],
                    content_ids: [`${meta.previousAction.meta.product.productId}`],
                });
            }

            if (meta?.previousAction?.meta?.action === 'delete') {
                dispatch(removePayByPointsItem({ itemId: meta?.previousAction?.meta?.cartItemId }));
            }

            if (meta?.previousAction?.meta?.redirectToCart) {
                navigate('/koszyk/');
            }

            dispatch(setAlert(successMsg, CART));
            break;

        case types.SEND_CART_FAIL:
            const error = errorHandler(action.error);
            error.map((err) => dispatch(setAlert(err, CART)));
            dispatch(setErrors(error));
            break;

        case types.CLEAR_CART:
            localStorage.removeItem(GUEST_CART);
            break;
        // no default
    }
};

export default cartMiddleware;
