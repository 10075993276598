import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik';

import { IComment, ICommentFormValues } from '../models/comment.model';
import { IResponsePagination } from '../models/pagination';
import { TStatus } from '../models/status.model';
import { selectForumComments } from '../redux/forum/forum.selectors';
import { selectLoaderByEntity } from '../redux/ui/ui.selectors';
import {
    clearForumComments,
    createForumComment,
    deleteForumComment,
    FORUM_COMMENT,
    FORUM_COMMENT_ANSWERS,
    FORUM_COMMENT_ANSWERS_PER_PAGE,
    FORUM_COMMENTS,
    getForumCommentAnswers,
    getForumComments,
    reportForumComment,
    updateForumComment,
} from '../redux/forum/forum.actions';

interface IForumCommentsState {
    items: IComment[];
    pagination: IResponsePagination;
}

export const useForumComments = (threadId?: number | string) => {
    const dispatch = useDispatch();
    const comments: IForumCommentsState = useSelector(selectForumComments);
    const status: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, FORUM_COMMENTS);
    });
    const statusSingle: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, FORUM_COMMENT);
    });
    const statusAnswers: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, FORUM_COMMENT_ANSWERS);
    });

    const getNextPage = () => {
        if (!threadId) return;
        if (comments.pagination.currentPage > comments.pagination.pageCount) return;
        if (
            comments.items.length &&
            comments.pagination.currentPage === comments.pagination.pageCount
        )
            return;
        dispatch(
            getForumComments({
                page: comments.pagination.currentPage + 1,
                perPage: comments.pagination.perPage,
                threadId,
            })
        );
    };

    const getAnswers = (comment: IComment) => {
        const currentCount = comment.answers.length;
        const page =
            currentCount < FORUM_COMMENT_ANSWERS_PER_PAGE
                ? 1
                : currentCount / FORUM_COMMENT_ANSWERS_PER_PAGE + 1;
        dispatch(
            getForumCommentAnswers({
                commentId: comment.commentId,
                threadId: threadId,
                perPage: FORUM_COMMENT_ANSWERS_PER_PAGE,
                page: page,
                isAfterCreate: false,
            })
        );
    };

    useEffect(() => {
        return () => {
            dispatch(clearForumComments());
        };
    }, [dispatch]);

    return {
        ...comments,
        status: status || 'idle',
        statusSingle: statusSingle || 'idle',
        statusAnswers: statusAnswers || 'idle',
        getNextPage,
        getAnswers,
        create: (config: ICreateForumCommentConfig) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(createForumComment(config));
        },
        update: (config: IUpdateForumCommentConfig) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(updateForumComment(config));
        },
        delete: (config: IDeleteOrReportForumCommentConfig) => {
            dispatch(deleteForumComment(config));
        },
        report: (config: IDeleteOrReportForumCommentConfig) => {
            dispatch(reportForumComment(config));
        },
    };
};

interface ICreateForumCommentConfig {
    values: ICommentFormValues;
    formik: FormikHelpers<ICommentFormValues>;
    threadId: number | string;
    parentId?: number | null;
    elementToScroll?: HTMLElement | null;
}

interface IUpdateForumCommentConfig extends ICreateForumCommentConfig {
    commentId: number;
}

interface IDeleteOrReportForumCommentConfig {
    commentId: number;
    threadId: number | string;
}
