import { setMessagesData } from './api';
import { fetchMessages } from './fetch-actions';
import { clearAttachmentsPill } from '../additional-attachments-to-send/actions';
import { config } from '../../../config';
import { formatFormikErrors } from '../../../communication/utils';
import { getCurrentProfileId } from '../../profile/profile.selectors';

const moduleName = '[MESSENGER SEND]';

export const actions = {
    START: `${moduleName} start`,
    SUCCESS: `${moduleName} set success`,
    SET_ERRORS: `${moduleName} set errors`,

    CLEAR_SUCCESS: `${moduleName} clear success`,
};

const startSendingMessage = () => ({
    type: actions.START,
});

const successSendingMessage = () => ({
    type: actions.SUCCESS,
});

export const clearSuccessMessage = () => ({
    type: actions.CLEAR_SUCCESS,
});

const setErrors = (payload) => ({
    type: actions.SET_ERRORS,
    payload,
});

export const SendMessage = (dataToSend, formikBag) => async (dispatch, getState) => {
    const attachmentsPills = getState().messenger.additionalAttachmentsToSend.data;
    const profileId = getCurrentProfileId(getState());
    dispatch(startSendingMessage());
    formikBag.setStatus(config.formsStatusMap.loading);

    let newData;
    try {
        newData = {
            content: dataToSend.content,
            attachments: attachmentsPills.map((item) => {
                return {
                    name: item.name,
                    coverBase64: item.blob,
                };
            }),
        };
    } catch (err) {}

    if (newData) {
        try {
            const response = await setMessagesData(newData, profileId);
            if (response.status === 201) {
                dispatch(successSendingMessage());
                dispatch(fetchMessages());
                dispatch(clearAttachmentsPill());
                formikBag.resetForm();
                formikBag.setStatus(config.formsStatusMap.success);
            }
        } catch (err) {
            dispatch(setErrors(err));
            formikBag.setStatus(config.formsStatusMap.fail);
            formikBag.setErrors(formatFormikErrors(err));
        }
    }
};
