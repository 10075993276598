import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
    list,
    title,
    wrapper,
    header,
    checkbox,
    buttons,
    buttonHolder,
    fields,
    readonly,
    labelInput,
    inputs,
    addressInputs,
} from './shop-step-address.module.scss';
import { mainGrid } from '../../../style/grid.module.scss';
import { config } from '../../../config';
import { EProductKind } from '../../../models/product-kind.enum';
import {
    ADDRESSES,
    getAddressesAction,
    clearAddressesAction,
} from '../../../redux/addresses/addresses.actions';
import { selectAddressesByTypeKey } from '../../../redux/addresses/addresses.selectors';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { setBuyingProcessAddresses } from '../../../redux/buying-process/step-two/actions/buying-process-step-two-actions';
import { setBuyingProcessGlobalStep } from '../../../redux/buying-process/global/actions/buying-process-global-actions';
import {
    selectCurrentDeliveryId,
    selectDeliveryMethods,
} from '../../../redux/shop/order/order.selectors';
import { selectCart } from '../../../redux/shop/cart/cart.selectors';
import { getCurrentProfile } from '../../../redux/profile/profile.selectors';

import ShopCartSummaryPrice from '../../../components/atoms/shop-cart-summary-price/shop-cart-summary-price';
import CartButton from '../../../components/atoms/cart-button';
import Title from '../../../components/atoms/title';
import AddressDeliveryFields, {
    getAddressDeliveryInitialValues,
    getAddressDeliveryValidationSchema,
} from '../../../components/molecules/address-delivery-fields';
import AddressInvoiceFields, {
    addressInvoiceInitialValues,
    addressInvoiceValidationSchema,
} from '../../../components/molecules/address-invoice-fields';
import Checkbox from '../../../components/atoms/form-poc/checkbox';
import Loader from '../../../components/atoms/loader';
import Input from '../../../components/atoms/form-poc/input';
import AddressSelectList from '../../../components/organisms/address-select-list';
import ShowHideBar from '../../../components/molecules/show-hide-bar';
import CheckboxGroup from '../../../components/atoms/form-poc/checkbox-group';

const {
    addressTypes,
    apiStatusMap,
    messages: {
        form: { required },
    },
} = config;

const ShopStepAddress = () => {
    const formikRef = useRef(null);
    const dispatch = useDispatch();
    const {
        deliveryAddresses,
        invoiceAddresses,
        status,
        initialValues,
        deliveryMethods,
        noPhysicalProducts,
    } = useSelector((state) => {
        const stepData = state.buyingProcess.stepTwo;
        return {
            initialValues: {
                ...stepData,
                addressDelivery: {
                    ...(stepData.addressDelivery || {
                        ...getAddressDeliveryInitialValues(
                            getIsNoPhysicalProductsInCart(selectCart(state)),
                            getCurrentProfile(state)
                        ),
                        label: '',
                        save: false,
                    }),
                    type: addressTypes.delivery.id,
                },
                addressInvoice: {
                    ...(stepData.addressInvoice || {
                        ...addressInvoiceInitialValues,
                        label: '',
                        save: false,
                    }),
                    type: addressTypes.invoice.id,
                },
                payment: 'PayU',
                delivery: selectCurrentDeliveryId(state),
            },
            deliveryAddresses: selectAddressesByTypeKey(state, addressTypes.delivery.key),
            invoiceAddresses: selectAddressesByTypeKey(state, addressTypes.invoice.key),
            status: selectLoaderByEntity(state, ADDRESSES),
            deliveryMethods: getDeliveryMethods(selectDeliveryMethods(state)),
            noPhysicalProducts: getIsNoPhysicalProductsInCart(selectCart(state)),
        };
    });
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(initialValues.wantInvoice, noPhysicalProducts)
    );
    const [showDeliveryAddresses, setShowDeliveryAddresses] = useState(
        !!initialValues.selectedDeliveryId
    );
    const [showInvoiceAddresses, setShowInvoiceAddresses] = useState(
        !!initialValues.selectedInvoiceId
    );

    const handleCancelAddress = (addressTypeId, formik) => {
        if (addressTypeId === addressTypes.delivery.id) {
            if (formik.values.selectedDeliveryId) {
                formik.setFieldValue('addressDelivery', {
                    ...getAddressDeliveryInitialValues(noPhysicalProducts),
                });
                formik.setFieldValue('selectedDeliveryId', '');
            }
            setShowDeliveryAddresses(false);
        } else {
            if (formik.values.selectedInvoiceId) {
                formik.setFieldValue('addressInvoice', {
                    ...addressInvoiceInitialValues,
                });
                formik.setFieldValue('selectedInvoiceId', '');
            }
            setShowInvoiceAddresses(false);
        }
    };

    const handleAddressSelect = (address, formik) => {
        if (
            [formik.values.selectedDeliveryId, formik.values.selectedInvoiceId].includes(
                address.addressId
            )
        )
            return;
        if (address.type === addressTypes.delivery.id) {
            formik.setFieldValue('selectedDeliveryId', address.addressId, false);
            Object.keys(formik.values.addressDelivery)
                .filter((key) => key !== 'label')
                .forEach((key) => {
                    formik.setFieldValue(`addressDelivery.${key}`, address[key], false);
                });
            formik.setFieldError('addressDelivery', '');
            formik.setFieldValue('addressDelivery.save', false, false);
        } else {
            formik.setFieldValue('selectedInvoiceId', address.addressId, false);
            Object.keys(formik.values.addressInvoice)
                .filter((key) => key !== 'label')
                .forEach((key) => {
                    formik.setFieldValue(`addressInvoice.${key}`, address[key], false);
                });
            formik.setFieldError('addressInvoice', '');
            formik.setFieldValue('addressInvoice.save', false, false);
        }
    };

    const handleWantInvoiceChange = (formik) => {
        const wantInvoice = !formik.values.wantInvoice;
        if (!wantInvoice) {
            formik.setFieldValue('addressInvoice', {
                ...addressInvoiceInitialValues,
            });
            formik.setFieldTouched('addressInvoice', false);
        }
        formik.setFieldValue('wantInvoice', wantInvoice);
        setValidationSchema(getValidationSchema(wantInvoice, noPhysicalProducts));
    };

    const handleSubmit = (values) => {
        dispatch(setBuyingProcessAddresses(values));
        dispatch(setBuyingProcessGlobalStep(2));
    };

    const handleValidate = (values) => {
        if (values.selectedDeliveryId) {
            const selected = deliveryAddresses.find(
                (address) => address.addressId === values.selectedDeliveryId
            );
            if (!isSelectedEqualToEntered(selected, values.addressDelivery)) {
                formikRef.current.setFieldValue('selectedDeliveryId', '');
            }
        }
        if (values.selectedInvoiceId) {
            const selected = invoiceAddresses.find(
                (address) => address.addressId === values.selectedInvoiceId
            );
            if (!isSelectedEqualToEntered(selected, values.addressInvoice)) {
                formikRef.current.setFieldValue('selectedInvoiceId', '');
            }
        }
    };

    useEffect(() => {
        if (deliveryMethods === null) {
            dispatch(getDeliveryMethods());
        }
    }, [dispatch, deliveryMethods, initialValues]);

    useEffect(() => {
        dispatch(getAddressesAction());
        return () => {
            dispatch(clearAddressesAction());
        };
    }, [dispatch]);

    if (status === apiStatusMap.loading) {
        return <Loader />;
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
            validate={handleValidate}
        >
            {(formik) => (
                <Form className={`${mainGrid} ${wrapper}`}>
                    <div className={inputs}>
                        <div className={addressInputs}>
                            <h2 className={header}>Dane zamawiającego</h2>
                            <AddressDeliveryFields
                                className={fields}
                                hasLabel={false}
                                isShort={noPhysicalProducts}
                                prefixes={['addressDelivery']}
                            />
                            {!noPhysicalProducts && (
                                <>
                                    <Checkbox
                                        name="addressDelivery.save"
                                        containerClass={`
                                            ${formik.values.selectedDeliveryId ? readonly : ''}
                                            ${checkbox}
                                        `}
                                    >
                                        Zapisz adres w "MOJE ADRESY"
                                    </Checkbox>
                                    {formik.values.addressDelivery.save && (
                                        <Input
                                            containerClass={labelInput}
                                            name="addressDelivery.label"
                                            placeholder="Nazwa adresu np. Dom lub praca"
                                        />
                                    )}
                                    {deliveryAddresses.length > 0 && (
                                        <ShowHideBar
                                            className={buttons}
                                            onShow={() => setShowDeliveryAddresses(true)}
                                            onHide={() =>
                                                handleCancelAddress(
                                                    addressTypes.delivery.id,
                                                    formik
                                                )
                                            }
                                            isShowing={showDeliveryAddresses}
                                            showText={`Wybierz adres z "MOJE ADRESY"`}
                                            cancelText="Anuluj"
                                        />
                                    )}
                                    {showDeliveryAddresses && (
                                        <AddressSelectList
                                            className={list}
                                            addresses={deliveryAddresses}
                                            onSelect={(address) =>
                                                handleAddressSelect(address, formik)
                                            }
                                            selectedId={formik.values.selectedDeliveryId}
                                        />
                                    )}
                                </>
                            )}
                            <Checkbox
                                name="wantInvoice"
                                onChange={() => handleWantInvoiceChange(formik)}
                                withoutFormik={true}
                                checked={formik.values.wantInvoice}
                                containerClass={checkbox}
                            >
                                Chcę fakturę VAT
                            </Checkbox>
                            {formik.values.wantInvoice && (
                                <>
                                    <h2 className={header}>Dane do faktury</h2>
                                    <AddressInvoiceFields
                                        className={fields}
                                        hasLabel={false}
                                        prefixes={['addressInvoice']}
                                    />
                                    <Checkbox
                                        name="addressInvoice.save"
                                        containerClass={`
                                        ${formik.values.selectedInvoiceId ? readonly : ''}
                                        ${checkbox}
                                    `}
                                    >
                                        Zapisz dane w "MOJE FAKTURY"
                                    </Checkbox>
                                    {formik.values.addressInvoice.save && (
                                        <Input
                                            containerClass={labelInput}
                                            name="addressInvoice.label"
                                            placeholder="Nazwa adresu np. Faktura"
                                        />
                                    )}
                                    {invoiceAddresses.length > 0 && (
                                        <ShowHideBar
                                            className={buttons}
                                            onShow={() => setShowInvoiceAddresses(true)}
                                            onHide={() =>
                                                handleCancelAddress(addressTypes.invoice.id, formik)
                                            }
                                            isShowing={showInvoiceAddresses}
                                            showText={`Wybierz dane z "MOJE FAKTURY"`}
                                            cancelText="Anuluj"
                                        />
                                    )}
                                    {showInvoiceAddresses && (
                                        <AddressSelectList
                                            className={list}
                                            addresses={invoiceAddresses}
                                            onSelect={(address) =>
                                                handleAddressSelect(address, formik)
                                            }
                                            selectedId={formik.values.selectedInvoiceId}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        <div>
                            <div className={header}>
                                Sposób płatności
                                <br />
                                <small>
                                    Wygodną dla siebie opcję płatności wybierzesz po wciśnięciu w
                                    ostatnim kroku zamówienia przycisku "Zamów".
                                </small>
                            </div>
                            <CheckboxGroup
                                name={'payment'}
                                options={checkboxes.payment}
                                singleChoice={true}
                            />
                        </div>
                        <div>
                            <div className={header}>Wybierz sposób dostawy</div>
                            {deliveryMethods.length > 0 && (
                                <CheckboxGroup
                                    name={'delivery'}
                                    options={deliveryMethods}
                                    singleChoice={true}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        <Title className={title}>Łączna kwota</Title>
                        <ShopCartSummaryPrice isDropdownStyle={false} />
                        <div className={buttonHolder}>
                            <CartButton fullSize={true} transitions={false} title="DALEJ" />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const checkboxes = {
    payment: [{ value: 'PayU', label: 'Płatność online' }],
};

function getIsNoPhysicalProductsInCart(cart) {
    if (!cart?.items) return true;
    return cart.items.every((item) => item.product.kind !== EProductKind.Physical);
}

function getDeliveryMethods(methods) {
    if (Array.isArray(methods)) {
        return methods.map((item) => {
            return {
                value: item.id,
                label: `${item.label} ${item.price?.grossDisplay ? item.price.grossDisplay : ''}`,
            };
        });
    }
    return [];
}

function getValidationSchema(withInvoice = false, noPhysicalProducts = false) {
    return Yup.object({
        addressDelivery: Yup.object({
            ...getAddressDeliveryValidationSchema(noPhysicalProducts),
            label: Yup.string().when('save', {
                is: true,
                then: Yup.string().required(required),
                otherwise: Yup.string(),
            }),
        }),
        ...(withInvoice
            ? {
                  addressInvoice: Yup.object({
                      ...addressInvoiceValidationSchema,
                      label: Yup.string().when('save', {
                          is: true,
                          then: Yup.string().required(required),
                          otherwise: Yup.string(),
                      }),
                  }),
              }
            : {}),
    });
}

function isSelectedEqualToEntered(selected, entered) {
    return Object.keys(entered)
        .filter((key) => key !== 'save')
        .every((key) => entered[key] === selected[key]);
}

export default ShopStepAddress;
