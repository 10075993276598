import React from 'react';
import PropTypes from 'prop-types';

import { title, lock, container, ordered, chosen } from './plan-card.module.scss';
import LockIcon from '../../assets/images/svg/icon-lock.svg';
import { useDispatch, useSelector } from 'react-redux';
import { forbiddenClick } from '../../redux/rbac/rbac.actions';

const PlanCard = ({ className, plan, order, selected, onClick }) => {
    const userRights = useSelector((state) => state.rbac);
    const dispatch = useDispatch();

    const hasAccess = () => {
        let matchingRightCount = 0;

        if (!plan.auth.length) {
            matchingRightCount++;
        } else {
            for (let index = 0; index < userRights.length; index++) {
                if (plan.auth.includes(userRights[index])) matchingRightCount++;
            }
        }
        return matchingRightCount > 0;
    };

    const selectPlane = () => {
        if (hasAccess()) {
            onClick(plan);
        } else {
            dispatch(forbiddenClick());
        }
    };

    return (
        <button
            className={`
                ${container}
                ${className}
                ${selected ? chosen : ''}
            `}
            onClick={selectPlane}
        >
            {hasAccess() ? (
                <div className={ordered}>{selected ? '✔' : order}</div>
            ) : (
                <div>
                    <div className={lock}>
                        <LockIcon />
                    </div>
                </div>
            )}

            <p className={title}>{plan.name}</p>
        </button>
    );
};

PlanCard.propTypes = {
    plan: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }).isRequired,
    order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    className: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
};

PlanCard.defaultProps = {
    className: '',
    selected: false,
    onClick: () => {},
};

export default PlanCard;
