import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    list as listClass,
    titleWrapper,
    listTitle,
    extensions,
    attachmentPill,
    separator,
    loader,
    childList,
    childListItem,
} from './attachments.module.scss';
import { fetchAttachments } from '../../../../../redux/messenger/attachments/list/actions';

import EmptyAttachments from '../../../../molecules/empty-attachments/empty-attachments';
import Loader from '../../../../atoms/loader';
import Title from '../../../../atoms/title';
import Separator from '../../../../atoms/separator';
import AttachmentPill from '../../../../molecules/attachment-pill/attachment-pill';

const Attachments = ({ list, loading, success, errors, dispatch }) => {
    useEffect(() => {
        dispatch(fetchAttachments());
    }, [dispatch]);

    if (errors) {
        return (
            <div className={errors}>
                Wystąpił błąd przy pobieraniu załączników, spróbuj poźniej...
            </div>
        );
    }

    if (loading || !success) {
        return <Loader className={loader} />;
    }

    return (
        <div>
            {list.length > 0 ? (
                list.map((data, key) => (
                    <div className={listClass} key={`attachment-list-${key}`}>
                        <div className={titleWrapper}>
                            <Title Tag={'h3'} className={listTitle}>
                                {data.name}
                            </Title>
                            <span className={extensions}>
                                {data.extensions.length > 0 &&
                                    data.extensions.map((ext, index) =>
                                        data.extensions.length !== ++index ? `${ext},  ` : `.itp`
                                    )}
                            </span>
                        </div>
                        <Separator className={separator} />
                        <ul className={childList}>
                            {data.attachments.map((item, index) => (
                                <li className={childListItem} key={`attachments--${index}`}>
                                    <AttachmentPill
                                        canDownload={true}
                                        data={item}
                                        className={attachmentPill}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                ))
            ) : (
                <EmptyAttachments />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        list: state.messenger.attachments.data,
        loading: state.messenger.attachments.meta.loading,
        success: state.messenger.attachments.meta.success,
        errors: state.messenger.attachments.meta.errors,
    };
};

export default connect(mapStateToProps, null)(Attachments);
