import React from 'react';
import { Link, navigate } from 'gatsby';
import { useDispatch } from 'react-redux';

import {
    container,
    header,
    title,
    author,
    locked,
    unlocked,
    publicThread,
    dot,
    access,
    footer,
    pin,
    comment,
    meta,
} from './thread-card.module.scss';
import LockIcon from '../../assets/images/svg/lock.svg';
import UnlockIcon from '../../assets/images/svg/unlock.svg';
import PinIcon from '../../assets/images/svg/pin.svg';
import { IThread } from '../../models/thread.model';
import { addModalAction } from '../../redux/actions/actions-modals';

import ThreadMeta from '../atoms/thread-meta';

interface IThreadCardProps {
    className?: string;
    thread: IThread;
    url: string;
}

const ThreadCard: React.FC<IThreadCardProps> = ({ className = '', thread, url }) => {
    const dispatch = useDispatch();
    const { latestComment } = thread;
    const accessClass = accessClasses[thread.access];
    const Component = thread.access === 'locked' ? 'button' : Link;

    const handleLocked = () => {
        dispatch(
            addModalAction({
                type: 'info',
                title: 'Cześć!',
                runOnCloseOnlyOnButtonClick: true,
                onClose: () => navigate('/cennik/'),
                buttonText: 'Zainwestuj w siebie',
                noEmotics: true,
                content:
                    'Wybrana przez Ciebie usługa jest dostępna tylko dla podopiecznych Strefy Przemian. Skorzystaj teraz ze zniżki i tak jak inni, dołącz do nas!',
            })
        );
    };

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
            {...(thread.access === 'locked'
                ? { onClick: handleLocked }
                : { to: url, state: { prevPathname: window.location.pathname } })}
            className={`${container} ${className} ${accessClass}`}
        >
            <div className={header}>
                <ThreadMeta className={meta} thread={thread} isVerticalOnMobile={true} />
                {thread.access !== 'public' && (
                    <p className={access}>
                        {thread.access === 'locked' && (
                            <>
                                <LockIcon /> Odblokuj
                            </>
                        )}
                        {thread.access === 'unlocked' && (
                            <>
                                <UnlockIcon /> Odblokowane
                            </>
                        )}
                    </p>
                )}
            </div>
            <h3 className={title}>{thread.title}</h3>
            <div className={footer}>
                {latestComment && (
                    <p className={comment}>
                        Ostatnia odpowiedź od{' '}
                        <span className={author}>
                            {latestComment.author.firstName} {latestComment.author.lastName}
                        </span>
                        <span className={dot}>•</span>
                        {latestComment.displayCreatedAtDate}
                        <span className={dot}>•</span>
                        {latestComment.displayCreatedAtTime}
                    </p>
                )}
                {thread.isPinned && <PinIcon className={pin} />}
            </div>
        </Component>
    );
};

const accessClasses: Record<IThread['access'], string> = {
    locked: locked,
    public: publicThread,
    unlocked: unlocked,
};

export default ThreadCard;
