import React from 'react';

import { price, basePrice, orderCard, orderHeader, summary } from './order-list-item.module.scss';
import { config } from '../../../config';
import { getFormattedDate } from '../../../utills/date-utils';
import getDiscountInfo from '../../../utills/get-discount-info';

import LinkButton from '../../atoms/link-button';
import OrderDetailsBox from '../../atoms/order-history/order-details-box';
import PriceFragment from '../../atoms/price-fragment';

const statusMap = config.shop.order.statusMap;

const OrderListItem = ({ order }) => {
    const { isDiscounted } = getDiscountInfo({
        price: order.price,
        basePrice: order.basePrice,
    });

    return (
        <div className={orderCard}>
            <div className={orderHeader}>
                <p>
                    <strong>Numer zamówienia: {order.id}</strong>
                </p>

                <LinkButton to={`${order.id}`} small noMargin>
                    Zobacz zamówienie
                </LinkButton>
            </div>

            <div className={summary}>
                <OrderDetailsBox title={'Data zamówienia:'}>
                    {getFormattedDate(order.createdAt, false, true)}
                </OrderDetailsBox>
                <OrderDetailsBox title={'Całkowity koszt:'}>
                    <PriceFragment
                        price={order.price}
                        basePrice={order.basePrice}
                        isDiscounted={isDiscounted}
                        basePriceClassName={basePrice}
                        priceClassName={price}
                    />
                    <br />
                    {order.isRecurringPayment && <small>Płatne w ratach</small>}
                </OrderDetailsBox>
                <OrderDetailsBox title={'Status zamówienia:'}>
                    {statusMap[order.status].label}
                </OrderDetailsBox>
            </div>
        </div>
    );
};

export default OrderListItem;
