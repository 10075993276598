import { IProductVariant } from '../models/product-variant.model';
import { ICartItem } from '../models/cart.model';
import getDiscountInfo from './get-discount-info';

export function getVariantQuery(variant: IProductVariant) {
    return variant ? `?variant=${variant.variantId}` : '';
}

export function getPrice(item: ICartItem) {
    let price;
    let basePrice;

    const { variant, product, basePriceTotal, priceTotal } = item;

    if (variant) {
        price = variant.price;
        basePrice = variant.basePrice;
    } else if (basePriceTotal && priceTotal) {
        price = priceTotal;
        basePrice = basePriceTotal;
    } else {
        price = product.price;
        basePrice = product.basePrice;
    }

    return {
        price,
        basePrice,
    };
}

export const getCartItemDiscount = (item: ICartItem) => {
    const prices = getPrice(item);

    const discountObject = {
        ...prices,
        promotions: item.discounts || item.productPromotions.promotions,
        labelPointsBenefit: getCartItemLabelPointsBenefit(item),
    };

    return {
        ...prices,
        ...getDiscountInfo(discountObject),
    };
};

const getCartItemLabelPointsBenefit = (item: ICartItem) => {
    return (
        item.discounts?.find((discount) => discount.labelPointsBenefit)?.labelPointsBenefit ||
        item.productPromotions?.labelPointsBenefit
    );
};
