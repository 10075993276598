import React from 'react';

import { container, infoBox, dateText, statusText, actionBox, button } from './payment.module.scss';
import { config } from '../../../config';
import { getFormattedDate } from '../../../utills/date-utils';

import Button from '../button';

const paymentStatusMap = config.shop.order.paymentStatusMap;

const Payment = (props) => {
    const { showRetryButton, onRetry, payment } = props;

    const { executeAt, amount, status } = payment;

    const paymentDate = executeAt ? getFormattedDate(executeAt, false, true) : 'Do opłacenia';
    const paymentAmount = amount.display || null;
    const paymentStatus = paymentStatusMap[status].label;

    return (
        <div className={container}>
            <div className={infoBox}>
                <span className={dateText}>{paymentDate}</span>
                <span>{paymentAmount}</span>
                <span className={`${paymentStatusMap[status].key} ${statusText}`}>
                    {paymentStatus}
                </span>
            </div>
            {showRetryButton && (
                <div className={actionBox}>
                    <Button size="small" className={button} onClick={() => onRetry(payment)}>
                        Ponów płatność
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Payment;
