import React from 'react';
import { useSelector } from 'react-redux';
import { Link, navigate } from 'gatsby';

import {
    dropdown,
    title,
    separator,
    emptyCartInfo,
    moreItems,
    link,
    loader,
} from './cart-dropdown.module.scss';
import { selectCart, selectCartIsLoading } from '../../../redux/shop/cart/cart.selectors';

import Separator from '../../atoms/separator';
import Dropdown from '../dropdown';
import CartButton from '../../atoms/cart-button';
import { CartListConnected } from './cart-list';
import ShopCartSummaryPrice from '../../atoms/shop-cart-summary-price/shop-cart-summary-price';
import Loader from '../../atoms/loader';

export const CartDropdown = ({ isOpen = false, closeFunc }) => {
    const cart = useSelector(selectCart);
    const isLoading = useSelector(selectCartIsLoading);
    const count = cart?.items?.length || 0;

    return (
        <Dropdown open={isOpen} closeFunc={closeFunc} className={dropdown}>
            <div className={title}>Mój koszyk</div>
            <Separator className={separator} />
            {isLoading && <Loader fullContainer className={loader} />}
            {count > 0 ? (
                <CartListConnected context="cart-dropdown" />
            ) : (
                <div className={emptyCartInfo}>Nie masz nic w koszyku...</div>
            )}
            {count > 3 && (
                <>
                    <div className={moreItems}>
                        +{count - 3}{' '}
                        <Link to={'/koszyk'} className={link}>
                            więcej
                        </Link>
                    </div>
                    <Separator />
                </>
            )}
            {count > 0 && (
                <>
                    <ShopCartSummaryPrice />
                    <CartButton
                        title={'koszyk'}
                        fullSize={true}
                        transitions={false}
                        onClick={() => navigate('/koszyk')}
                    />
                </>
            )}
        </Dropdown>
    );
};

export default CartDropdown;
