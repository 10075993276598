import React from 'react';
import PropTypes from 'prop-types';

import { filterGroup } from './filter-group.module.scss';

import FilterGroupTitle from '../../atoms/filter-atoms/filter-group-title';
import FilterGroupList from './filter-group-list';

const FilterGroup = ({
    group,
    openedItem,
    handleOpen = () => {},
    applyFilters,
    filterClick,
    withButtons,
    showApplied,
    clear,
    Tag = 'li',
    className = '',
}) => {
    let title = group.label;
    if (showApplied) {
        title = Object.values(group.values).find((val) => val.applied)?.label || group.label;
    }

    return (
        <Tag className={`${filterGroup} ${className}`} key={`filter-group-${group.key}`}>
            <FilterGroupTitle
                title={title}
                open={openedItem === group.key}
                onClick={() => handleOpen(group.key)}
            />
            <FilterGroupList
                group={group}
                open={openedItem === group.key}
                optionClick={filterClick}
                apply={applyFilters}
                clear={clear}
                withButtons={withButtons}
            />
        </Tag>
    );
};

export default FilterGroup;

FilterGroup.propTypes = {
    group: PropTypes.object,
    openedItem: PropTypes.string,
    handleOpen: PropTypes.func,
    clear: PropTypes.func,
    applyFilters: PropTypes.func,
    filterClick: PropTypes.func,
    withButtons: PropTypes.bool,
    showApplied: PropTypes.bool,
    Tag: PropTypes.elementType,
    className: PropTypes.string,
};

FilterGroup.defaultProps = {
    withButtons: true,
    showApplied: false,
};
