import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { listItem, textWrapper } from './messenger-tree.module.scss';
import { fetchMessages } from '../../../redux/messenger/messages/fetch-actions';

import Message from '../message/message';
import Loader from '../../atoms/loader';

const MessengerTree = ({ messages, loading }) => {
    const { pagination } = messages.meta;
    const { data } = messages;

    const listRef = useRef(null);

    const [scrollProgress, setScrollProgress] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        const timeInterval = 10000;

        const interval = setInterval(() => {
            dispatch(fetchMessages(false, true));
        }, timeInterval);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch]);

    useEffect(() => {
        const { totalCount, lastLoadingCount } = pagination;
        const minPercentagesToStartLoad = 0.85;
        const isDataToLoad = lastLoadingCount < totalCount;
        const hasToLoad = scrollProgress >= minPercentagesToStartLoad && isDataToLoad;

        if (hasToLoad && !loading) {
            dispatch(fetchMessages(true));
        }
    }, [pagination, scrollProgress, dispatch, loading]);

    const checkToLoad = () => {
        const { scrollTop, clientHeight, scrollHeight } = listRef.current;
        setScrollProgress((scrollTop + clientHeight) / scrollHeight);
    };

    return (
        <div>
            {/*eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions*/}
            <ul
                className={textWrapper}
                ref={listRef}
                onLoad={() => checkToLoad()}
                onScroll={() => checkToLoad()}
            >
                {data.map((message) => (
                    <li className={listItem} key={`message-${message.messageId}`}>
                        <Message message={message} />
                    </li>
                ))}
            </ul>
            {loading && <Loader />}
        </div>
    );
};

export default connect(
    (state) => ({
        messages: state.messenger.messages,
        loading: state.messenger.messages.meta.loading,
    }),
    null
)(MessengerTree);
