import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    container,
    nameBox,
    name,
    dot,
    listIcon,
    loading,
    actionRow,
    actionCount,
    actionButtons,
    editButton,
    pdfButton,
    list,
    item,
    deleteButton,
    deleteButtonText,
} from './shopping-list.module.scss';
import ShoppingListIcon from '../../../assets/images/svg/icon-shopping-list.svg';
import TrashIcon from '../../../assets/images/svg/icon-trash.svg';
import { config } from '../../../config';
import {
    clearSingleShoppingList,
    editShoppingList,
    getSingleShoppingList,
    SINGLE_SHOPPING_LIST,
    SINGLE_SHOPPING_LIST_CHANGE,
} from '../../../redux/single-shopping-list/single-shopping-list.actions';
import { addModalAction } from '../../../redux/actions/actions-modals';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { selectSingleShoppingList } from '../../../redux/single-shopping-list/single-shopping-list.selectors';
import { statusIdle } from '../../../redux/ui/ui.actions';

import Loader from '../../atoms/loader';
import Moment from 'react-moment';
import Separator from '../../atoms/separator';
import LinkButton from '../../atoms/link-button';

const { apiStatusMap } = config;

const ShoppingList = ({ id }) => {
    const dispatch = useDispatch();
    const shoppingList = useSelector(selectSingleShoppingList);
    const fetchStatus = useSelector((state) => selectLoaderByEntity(state, SINGLE_SHOPPING_LIST));
    const deleteStatus = useSelector((state) =>
        selectLoaderByEntity(state, SINGLE_SHOPPING_LIST_CHANGE)
    );

    const handleDelete = (positionId) => {
        const data = {
            ingredients: shoppingList.positions.filter((item) => item.id !== positionId),
        };
        dispatch(editShoppingList(id, data));
    };

    useEffect(() => {
        if (!shoppingList || shoppingList.id !== Number(id)) {
            dispatch(getSingleShoppingList(id));
        }
    }, [shoppingList, dispatch, id]);

    useEffect(() => {
        if (deleteStatus === apiStatusMap.loading || deleteStatus === apiStatusMap.idle) return;
        if (deleteStatus === apiStatusMap.fail) {
            dispatch(
                addModalAction({
                    type: 'error',
                    title: 'Nie udało się!',
                    content: 'Coś poszło nie tak, nie udało się usunąć składnika...',
                })
            );
        }
        dispatch(statusIdle(SINGLE_SHOPPING_LIST_CHANGE));
    }, [deleteStatus, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearSingleShoppingList());
        };
    }, [dispatch]);

    if (!shoppingList && fetchStatus === apiStatusMap.fail) {
        return <div>Wystąpił błąd, lista zakupowa nie została odnaleziona...</div>;
    }

    if (!shoppingList || fetchStatus === apiStatusMap.loading) {
        return <Loader />;
    }

    return (
        <div
            className={`
                ${container} 
                ${deleteStatus === apiStatusMap.loading ? loading : ''}
            `}
        >
            <h3 className={nameBox}>
                <ShoppingListIcon className={listIcon} />
                <div className={name}>{shoppingList.name}</div>
                <div>
                    <span className={dot}>•</span>
                    <Moment locale="pl" format="DD.MM.YYYY" unix={true} element={''}>
                        {shoppingList.createdAt}
                    </Moment>
                </div>
            </h3>
            <div className={actionRow}>
                <div className={actionCount}>
                    Produktów do kupienia: {shoppingList.positions.length}
                </div>
                <div className={actionButtons}>
                    <LinkButton
                        to={`../edytuj/${id}`}
                        color="blank"
                        small={true}
                        className={editButton}
                    >
                        Edytuj listę
                    </LinkButton>
                    <LinkButton
                        to={config.endpoints.shoppingLists.pdf(id)}
                        color="blank"
                        small={true}
                        className={pdfButton}
                        external={true}
                    >
                        Wygeneruj PDF
                    </LinkButton>
                </div>
            </div>
            <ul className={list}>
                {shoppingList.positions.map((position) => (
                    <li key={position.id} className={item}>
                        <div>
                            {position.name}
                            {` `}
                            {position.quantity}
                            {` `}
                            {position.unit.name}
                        </div>
                        <button className={deleteButton} onClick={() => handleDelete(position.id)}>
                            <TrashIcon />
                            <span className={deleteButtonText}>Usuń z listy</span>
                        </button>
                    </li>
                ))}
            </ul>
            <Separator />
        </div>
    );
};

export default ShoppingList;
