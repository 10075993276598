import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    wrapper,
    changeBox,
    changeButton,
    content,
    dropdown,
    replacementButton,
    replacement,
    simple,
} from './recipe-ingredient.module.scss';
import ArrowIcon from '../../../assets/images/svg/down-arrow.svg';
import { sortAlphabeticallyByKey } from '../../../utills/sort-alphabetically-by-key';

import Button from '../button';
import Dropdown from '../../molecules/dropdown';

const RecipeIngredient = ({ ingredient, multiplier, onIngredientChange, simpleView }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const hasReplacement = ingredient.replacements && ingredient.replacements.length > 0;

    const handleReplace = (replacementId) => {
        setDropdownOpen(false);
        if (typeof onIngredientChange === 'function') {
            onIngredientChange(ingredient.id, replacementId);
        }
    };

    const getQuantity = () => {
        const rawQuantity = ingredient.quantity * multiplier;
        if (rawQuantity >= 10) {
            return Math.round(rawQuantity);
        }
        return Math.round(rawQuantity * 10) / 10;
    };

    return (
        <li
            className={`
                ${wrapper} 
                ${hasReplacement ? replacement : ''}
                ${simpleView ? simple : ''}
            `}
        >
            <div className={content}>
                {getQuantity()}
                &nbsp;
                {ingredient.unit}
                {` `}
                {ingredient.name}
            </div>
            {hasReplacement && (
                <div className={changeBox}>
                    <Button
                        color="black"
                        className={changeButton}
                        icon={ArrowIcon}
                        size="small"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                        Zmień
                    </Button>
                    <Dropdown
                        className={dropdown}
                        open={dropdownOpen}
                        closeFunc={() => setDropdownOpen(false)}
                    >
                        {sortAlphabeticallyByKey(ingredient.replacements, 'name').map(
                            (replacement) => (
                                <button
                                    key={`replacement-${replacement.id}`}
                                    className={replacementButton}
                                    onClick={() => handleReplace(replacement.id)}
                                >
                                    {replacement.name}
                                </button>
                            )
                        )}
                    </Dropdown>
                </div>
            )}
        </li>
    );
};

RecipeIngredient.propTypes = {
    ingredient: PropTypes.object.isRequired,
    multiplier: PropTypes.number,
    onIngredientChange: PropTypes.func,
    simpleView: PropTypes.bool,
};

RecipeIngredient.defaultProps = {
    multiplier: 1,
    onIngredientChange: null,
    simpleView: false,
};

export default RecipeIngredient;
