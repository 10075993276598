import React from 'react';

import {
    label,
    step as stepClass,
    steps as stepsClass,
    separator,
    number,
    active,
    stepButton,
    completed,
} from './step-bar.module.scss';

const StepBar = ({ steps = [], activeStep = 0, onStepClick }) => {
    const handleStepClick = (index, step) => () => {
        if (typeof onStepClick !== 'function') return;
        onStepClick(index, step);
    };

    return (
        <ul className={stepsClass}>
            {steps.map((step, index, arr) => {
                return (
                    <li
                        className={`
                            ${stepClass}
                            ${activeStep === index ? active : ''}
                            ${activeStep > index ? completed : ''}
                        `}
                        key={`step-${index}`}
                    >
                        <button className={stepButton} onClick={handleStepClick(index, step)}>
                            <div className={number}>{activeStep > index ? '' : index + 1}</div>
                            <p className={label}>{step.label}</p>
                        </button>
                        {index < arr.length - 1 && <span className={separator} />}
                    </li>
                );
            })}
        </ul>
    );
};

export default StepBar;
