import React, { useEffect } from 'react';

import { container, info, message } from './formum-container.module.scss';
import { getAbsolutePath } from '../../../routes';
import { useForumThreadCategories } from '../../../hooks/use-forum-thread-categories';
import { useDispatch } from 'react-redux';
import { clearForum } from '../../../redux/forum/forum.actions';

import Link from '../../atoms/link';
import Loader from '../../atoms/loader';

interface IForumContainerProps {
    className?: string;
    children?: React.ReactNode;
    isFavorite?: boolean;
}

const ForumContainer: React.FC<IForumContainerProps> = ({
    className = '',
    children,
    isFavorite,
}) => {
    const dispatch = useDispatch();
    const categories = useForumThreadCategories(isFavorite);

    useEffect(() => {
        return () => {
            dispatch(clearForum());
        };
    }, [dispatch]);

    return (
        <div className={`${container} ${className}`}>
            <p className={info}>
                Tematy i grupy tematyczne tworzone są przez dietetyków Strefy Przemian, w odpowiedzi
                na Wasze potrzeby. Jeśli uważasz, że w naszej grupie brakuje jakiejś kategorii
                tematycznej lub tematu, napisz do nas{' '}
                <Link to={getAbsolutePath('APP_MY_ACCOUNT_MESSENGER')}>wiadomość</Link>. Przemyślimy
                Twoją sugestię i być może dzięki Tobie baza wiedzy w naszym portalu powiększy się!
            </p>
            {(categories.status === 'loading' || categories.status === 'idle') &&
                !categories.list.length && <Loader />}
            {categories.status === 'fail' && (
                <p className={message}>
                    Nie udało się pobrać danych grupy, spróbuj ponownie później...
                </p>
            )}
            {categories.status === 'success' && categories.list.length === 0 && (
                <p className={message}>Nie ma jeszcze żandych postów do wyświetlenia.</p>
            )}
            {(categories.status === 'success' ||
                (categories.status === 'loading' && categories.list.length > 0)) &&
                children}
        </div>
    );
};

export default ForumContainer;
