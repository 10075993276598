import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import processStructuredData from '../utills/process-structured-data';
import getMetaWithTranslations from '../utills/get-meta-with-translations';
import mergeArrayWithReplace from '../utills/merge-array-with-replace';

const SEO = ({
    description,
    lang,
    meta,
    title: defaultTitle,
    titleTemplate,
    image,
    structuredData,
    navigation,
    replaceMeta,
}) => {
    const { site } = useStaticQuery(query);
    const { pathname } = useLocation();

    const metaDescription = description || site.siteMetadata.description;
    const metaImage = image || `${site.siteMetadata.url}${site.siteMetadata.image}`;
    const { prev, next } = navigation || {};

    const defaultMeta = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:image`,
            content: metaImage,
        },
        {
            property: `og:title`,
            content: `${defaultTitle} | ${site.siteMetadata.title}`,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            property: `og:url`,
            content: `${site.siteMetadata.url}${pathname}`,
        },
    ].concat(meta);

    const replaceMetaWithTranslations = getMetaWithTranslations(replaceMeta, 'pl');

    let title = defaultTitle;
    let mergedMeta = defaultMeta;

    if (replaceMetaWithTranslations?.length > 0) {
        const titleTagIndex = replaceMetaWithTranslations.findIndex((meta) => meta.Tag === 'title');

        if (titleTagIndex !== -1) {
            title = replaceMetaWithTranslations.splice(titleTagIndex, 1)[0].content;
        }

        mergedMeta = mergeArrayWithReplace(defaultMeta, replaceMetaWithTranslations);
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={titleTemplate ? titleTemplate : `%s | ${site.siteMetadata.title}`}
            meta={mergedMeta}
        >
            {prev && <link rel="prev" href={prev} />}
            {next && <link rel="next" href={next} />}
            {structuredData ? (
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        ...processStructuredData(structuredData),
                    })}
                </script>
            ) : null}
        </Helmet>
    );
};

const query = graphql`
    query {
        site {
            ...siteFields
        }
    }
`;

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    titleTemplate: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    structuredData: PropTypes.shape({
        '@type': PropTypes.string.isRequired,
        name: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.string,
        description: PropTypes.string,
        title: PropTypes.string,
    }),
};

SEO.defaultProps = {
    lang: `pl-PL`,
    meta: [],
    description: ``,
    image: '',
};

export default SEO;
