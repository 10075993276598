// extracted by mini-css-extract-plugin
export var avatarContainer = "my-account-module--avatar-container--ueHyJ";
export var avatarCurrent = "my-account-module--avatar-current--MYA2i";
export var avatarName = "my-account-module--avatar-name--tut93";
export var avatarNotCurrent = "my-account-module--avatar-not-current--Xj6lt";
export var changeButton = "my-account-module--change-button--XvCDv";
export var container = "my-account-module--container--t89w9";
export var editButton = "my-account-module--edit-button--S6kDb";
export var editButtonText = "my-account-module--edit-button-text--6rn3a";
export var header = "my-account-module--header--03G02";
export var justifyCenter = "my-account-module--justify-center--LVGy5";
export var myAccount = "my-account-module--my-account--4lyTP";
export var sidebar = "my-account-module--sidebar--BphD+";
export var title = "my-account-module--title--6R6Rm";