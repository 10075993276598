import { combineReducers } from 'redux';
import { genericListReducer } from '../generic-list/generic-list.reducer';

export const TRAININGS_ATLAS = '[Trainings_atlas]';
export const LIST = 'list';

const trainingAtlas = combineReducers({
    [LIST]: genericListReducer(TRAININGS_ATLAS, LIST),
    // add more reducers for subEntities if needed
});

export default trainingAtlas;
