import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    list,
    items,
    summaryItem,
    summary,
    summaryPrices,
    benefitInfo,
    recurringPaymentInfo,
    orderHeader,
} from './ordrer-summary.module.scss';
import { config } from '../../../../config';
import {
    getSingleOrder,
    ORDER_HISTORY,
} from '../../../../redux/shop/order-history/order-history.actions';
import { selectOrderById } from '../../../../redux/shop/order-history/order-history.selectors';
import { selectLoaderByEntity } from '../../../../redux/ui/ui.selectors';
import { getFormattedDate } from '../../../../utills/date-utils';
import { getAbsolutePath, ORDER_ROUTES } from '../../../../routes';
import { getNewOrderPayment } from '../../../../communication/order-payment';
import { addModalAction } from '../../../../redux/actions/actions-modals';

import Loader from '../../../atoms/loader';
import OrderDetailsBox from '../../../atoms/order-history/order-details-box';
import OrderSummarySection from '../../../atoms/order-history/order-summary-section';
import Payment from '../../../atoms/order-history/payment';
import CartList from '../../../molecules/cart/cart-list';

const apiStatusMap = config.apiStatusMap;
const paymentStatusMap = config.shop.order.paymentStatusMap;

const OrderSummary = ({ id }) => {
    const dispatch = useDispatch();
    const { singleOrder, apiStatus } = useSelector((state) => ({
        singleOrder: selectOrderById(state, id),
        apiStatus: selectLoaderByEntity(state, ORDER_HISTORY),
    }));

    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const [invoiceAddress, setInvoiceAddress] = useState(null);
    const [createPaymentLoading, setCreatePaymentLoading] = useState(false);

    const shouldDisplayDiscountInfo =
        !!singleOrder && singleOrder.basePrice.grossInteger - singleOrder.price.grossInteger > 0;
    const discountAmount =
        singleOrder &&
        ((singleOrder.basePrice.grossInteger - singleOrder.price.grossInteger) / 100).toFixed(2);

    const handlePaymentRetry = async (payment) => {
        if (singleOrder.isRecurringPayment) {
            await navigate(
                `${getAbsolutePath('ORDER_CREDIT_CARD_RETRY', ORDER_ROUTES)}/${payment.paymentId}`
            );
            return;
        }

        if (payment.url) {
            await navigate(payment.url);
            return;
        }

        try {
            setCreatePaymentLoading(true);
            const newPayment = await getNewOrderPayment(payment.paymentId);
            await navigate(newPayment.data.url);
        } catch {
            dispatch(
                addModalAction({
                    type: 'error',
                    title: 'Coś poszło nie tak...',
                    content: 'Nie udało się ponowić płatności. Spróbuj później.',
                })
            );
        } finally {
            setCreatePaymentLoading(false);
        }
    };

    useEffect(() => {
        if (!singleOrder) {
            dispatch(getSingleOrder(id));
        } else {
            setDeliveryAddress(singleOrder?.addresses?.find((item) => item.type === 0));
            setInvoiceAddress(singleOrder?.addresses?.find((item) => item.type === 1));
        }
    }, [dispatch, id, singleOrder]);

    const renderSummary = () => {
        return (
            <div className={summary}>
                {createPaymentLoading && <Loader fullContainer={true} />}
                <div className={orderHeader}>
                    <p>
                        <strong>Numer zamówienia: {singleOrder.id}</strong>
                    </p>

                    <Link to={'../'}>Wróć</Link>
                </div>
                <OrderDetailsBox title={'Data zamówienia'}>
                    {getFormattedDate(singleOrder.createdAt, false, true)}
                </OrderDetailsBox>
                <br />

                <OrderSummarySection title={'Produkty'}>
                    <CartList
                        readOnly
                        items={singleOrder.items}
                        className={list}
                        context="order-history"
                    />
                </OrderSummarySection>

                <OrderSummarySection title={'Płatność'}>
                    <OrderDetailsBox title={'Metoda płatności'}>karta płatnicza</OrderDetailsBox>
                    {invoiceAddress && (
                        <OrderDetailsBox title={'Dane do faktury'}>
                            <p>{invoiceAddress.contactPerson}</p>
                            <p>
                                {invoiceAddress.street} {invoiceAddress.houseNumber}
                                {invoiceAddress.houseNumber
                                    ? ` / ${invoiceAddress.houseNumber}`
                                    : null}
                            </p>
                            <p>
                                {invoiceAddress.city} {invoiceAddress.zipCode}
                            </p>
                            <p>{invoiceAddress.contactNumber}</p>
                        </OrderDetailsBox>
                    )}
                    <OrderDetailsBox title={'Status płatności'} grown>
                        {Array.isArray(singleOrder.payments) &&
                            singleOrder.payments.map((payment) => {
                                return (
                                    <Payment
                                        key={`payment-${payment.paymentId}`}
                                        payment={payment}
                                        showRetryButton={showRetryButton(
                                            payment,
                                            singleOrder,
                                            paymentStatusMap
                                        )}
                                        onRetry={handlePaymentRetry}
                                    />
                                );
                            })}
                    </OrderDetailsBox>
                </OrderSummarySection>

                <OrderSummarySection title={'Dostawa'}>
                    <OrderDetailsBox title={'Metoda dostawy'}>
                        produkt elektroniczny
                    </OrderDetailsBox>
                    {deliveryAddress && (
                        <OrderDetailsBox title={'Adres dostawy'}>
                            <p>{deliveryAddress.contactPerson}</p>
                            <p>
                                {deliveryAddress.street} {deliveryAddress.houseNumber}
                                {deliveryAddress.houseNumber
                                    ? ` / ${deliveryAddress.houseNumber}`
                                    : null}
                            </p>
                            <p>
                                {deliveryAddress.city} {deliveryAddress.zipCode}
                            </p>
                            <p>{deliveryAddress.contactNumber}</p>
                        </OrderDetailsBox>
                    )}
                </OrderSummarySection>

                <OrderSummarySection title={'Koszt całkowity'} contentClassName={summaryPrices}>
                    <div className={items}>
                        <p>Produkty: </p>
                        <p>{singleOrder.basePrice.grossDisplay}</p>
                    </div>
                    {shouldDisplayDiscountInfo && (
                        <div className={`${items} ${benefitInfo}`}>
                            <p>Rabat: </p>
                            <p>-{discountAmount}zł</p>
                        </div>
                    )}
                    <div className={`${items} ${summaryItem}`}>
                        <p>Razem</p>
                        <p>{singleOrder.price.grossDisplay}</p>
                    </div>
                    {singleOrder.isRecurringPayment && (
                        <div className={`${items} ${recurringPaymentInfo}`}>
                            <span>Płatne w ratach</span>
                        </div>
                    )}
                </OrderSummarySection>
            </div>
        );
    };

    switch (true) {
        case apiStatus === apiStatusMap.loading:
            return <Loader />;
        case apiStatus === apiStatusMap.fail:
            return <div>Niestety, nie udało się pobrać zamówienia.</div>;
        case typeof singleOrder !== 'undefined':
            return renderSummary();
        default:
            return <div>Nie znaleźliśmy takiego zamówienia.</div>;
    }
};

function showRetryButton(payment, order, statusMap) {
    const paymentStatus = statusMap[payment.status].key;
    const unixNow = new Date().getTime() / 1000;
    const paymentIsExpired = unixNow > payment.executeAt;

    if (order.isRecurringPayment) {
        const failureStatusesRecurring = [statusMap['200'].key, statusMap['0'].key];
        return failureStatusesRecurring.includes(paymentStatus) && paymentIsExpired;
    } else {
        const failureStatusesSingle = [
            statusMap['200'].key,
            statusMap['0'].key,
            statusMap['5'].key,
        ];
        const lastOrderPayment = order.payments[order.payments.length - 1];
        const isLastPayment = payment.paymentId === lastOrderPayment.paymentId;
        return failureStatusesSingle.includes(paymentStatus) && paymentIsExpired && isLastPayment;
    }
}

export default OrderSummary;
