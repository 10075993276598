import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import { container, header, headerText, list, card } from './addresses.module.scss';
import { selectAddressesByTypeKey } from '../../../redux/addresses/addresses.selectors';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import {
    ADDRESSES,
    clearAddressesAction,
    getAddressesAction,
} from '../../../redux/addresses/addresses.actions';
import { getAbsolutePath } from '../../../routes';
import { ADDRESS } from '../../../redux/address/address.actions';
import { config } from '../../../config';

import Loader from '../../atoms/loader';
import AddressCard from '../../molecules/address-card';
import Button from '../../atoms/button';

const { apiStatusMap, addressTypes } = config;

const Addresses = ({ routeData }) => {
    const {
        routeProps: { addressType },
    } = routeData;
    const dispatch = useDispatch();
    const { addresses, status, deleteStatus } = useSelector((state) => {
        return {
            addresses: selectAddressesByTypeKey(state, addressType.key),
            status: selectLoaderByEntity(state, ADDRESSES) || apiStatusMap.idle,
            deleteStatus: selectLoaderByEntity(state, ADDRESS),
        };
    });

    useEffect(() => {
        dispatch(getAddressesAction());
        return () => dispatch(clearAddressesAction());
    }, [dispatch]);

    if (!addresses.length && status === apiStatusMap.loading) {
        return <Loader />;
    }

    if (!addresses.length && status === apiStatusMap.success) {
        navigate(getAbsolutePath(getPathKey(addressType.id)), {
            state: { noAddresses: true },
            replace: true,
        });
        return null;
    }

    if (status === apiStatusMap.fail) {
        return <div>Wystąpił błąd przy pobieraniu adresów. Proszę spróbować później...</div>;
    }

    return (
        <div className={container}>
            {deleteStatus === apiStatusMap.loading && <Loader fullContainer={true} />}
            <div className={header}>
                <h3 className={headerText}>
                    Potrzebujesz dodać nowy adres{' '}
                    {addressType.id === addressTypes.delivery.id ? 'wysyłki' : 'do faktury VAT'}?
                </h3>
                <Button
                    onClick={() => navigate(getAbsolutePath(getPathKey(addressType.id)))}
                    color="yellow"
                    size="small"
                >
                    Dodaj nowy adres
                </Button>
            </div>
            <ul className={list}>
                {addresses.map((address) => {
                    return (
                        <li key={`address-item-${address.addressId}`}>
                            <AddressCard address={address} className={card} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

function getPathKey(addressTypeId) {
    if (addressTypeId === addressTypes.delivery.id) {
        return 'APP_MY_ACCOUNT_USER_ZONE_ADDRESS_DELIVERY_ADD';
    }
    return 'APP_MY_ACCOUNT_USER_ZONE_ADDRESS_INVOICE_ADD';
}

export default Addresses;
