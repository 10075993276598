// extracted by mini-css-extract-plugin
export var container = "rating-module--container--o6My7";
export var count = "rating-module--count--mMsT4";
export var header = "rating-module--header--6my-M";
export var infoBox = "rating-module--info-box---qxfL";
export var loading = "rating-module--loading--WRrHy";
export var starBox = "rating-module--star-box--288nA";
export var stars = "rating-module--stars--oUbIN";
export var subtitle = "rating-module--subtitle--nIkp5";
export var title = "rating-module--title--YQRfx";
export var tooltip = "rating-module--tooltip--fr0iU";