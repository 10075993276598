import React from 'react';
import { useDispatch } from 'react-redux';

import { label, name, delBtn } from './meal-type-label.module.scss';
import TrashIcon from '../../../assets/images/svg/icon-trash.svg';
import { config } from '../../../config';
import { types as templateTypes } from '../../../redux/meal-planer-template/meal-planer-template.actions';
import { types } from '../../../redux/meal-planer/meal-planer.actions';

const MealTypeLabel = ({ type, isTemplate }) => {
    const dispatch = useDispatch();

    const typeLabelStyle = {
        '--col': config.mealPlanner.coordinates.cols.type.key,
        '--row': type.key,
    };

    const deleteMealType = () => {
        const action = { payload: type.mealTypeId };
        action.type = isTemplate ? templateTypes.DEL_MEAL_TYPE : types.DEL_MEAL_TYPE;
        dispatch(action);
    };

    const cssClass = `${label} meal-planer__grid-position`;

    return (
        <div className={cssClass} style={typeLabelStyle}>
            <button
                type={'button'}
                className={delBtn}
                onClick={deleteMealType}
                disabled={type.positionsCount === 0}
            >
                <TrashIcon />
            </button>

            <div className={`${name} ${type.key}`}>{type.label}</div>
        </div>
    );
};

export default MealTypeLabel;
