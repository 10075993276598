import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    list,
    wrapper,
    innerWrapper,
    titleHolder,
    item,
} from './attachments-to-send-list.module.scss';
import { clearAttachmentsPill } from '../../../redux/messenger/additional-attachments-to-send/actions';

import AttachmentPill from '../attachment-pill/attachment-pill';

const AttachmentsToSendList = () => {
    const dispatch = useDispatch();
    const additionalAttachmentsToSend = useSelector(
        (state) => state.messenger.additionalAttachmentsToSend
    );
    const { data } = additionalAttachmentsToSend;

    const [mapResData, setMapResData] = useState(data);

    useEffect(() => {
        const mapData = () => {
            const response = data.map((item, index) => {
                const { name, type, size, blob } = item;
                return {
                    index,
                    name,
                    size,
                    file: {
                        uri: blob,
                        type,
                    },
                };
            });
            setMapResData(response);
        };
        mapData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, additionalAttachmentsToSend]);

    useEffect(() => {
        return () => {
            dispatch(clearAttachmentsPill());
        };
    }, [dispatch]);

    return (
        <div className={wrapper}>
            {mapResData.length > 0 && (
                <div className={innerWrapper}>
                    <div className={titleHolder}>Załączniki</div>
                    <ul className={list}>
                        {mapResData.map((attachmentData, index) => {
                            return (
                                <li key={index} className={item}>
                                    <AttachmentPill
                                        reverseColor={true}
                                        data={attachmentData}
                                        isAttachmentToMessage={true}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AttachmentsToSendList;
