import React from 'react';

import { box, title as titleClass, hidden } from './simple.card.module.scss';
import Separator from '../separator';

const SimpleCard = ({ title, children, className = '' }) => {
    return (
        <div className={`${!children ? hidden : ''}`}>
            <span className={titleClass}> {title} </span>
            <Separator className={className} />
            <div className={box}>{children}</div>
        </div>
    );
};

export default SimpleCard;
