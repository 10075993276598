import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';

import { wrapper, hero } from './forbidden-403.module.scss';

import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import LinkButton from '../components/atoms/link-button';

const Forbidden = () => {
    const { heroImg, heroImgMobile } = useStaticQuery(query);

    const bannerData = {
        fluidImg: withArtDirection(getImage(heroImg), [
            {
                image: getImage(heroImgMobile),
                media: `(max-width: 420px)`,
            },
        ]),
        title: `Niestety! `,
        subtitle: `Nie masz uprawnień.`,
    };

    return (
        <Main className={wrapper} transparentHeader={true}>
            <MainBanner bannerData={bannerData} className={hero} dark={true}>
                <p>Aby zobaczyć tę stronę musisz posiadać aktywną subskrypcję.</p>

                <LinkButton to={`/cennik/`} color="yellow">
                    Cennik
                </LinkButton>
            </MainBanner>
        </Main>
    );
};

const query = graphql`
    {
        heroImg: file(relativePath: { eq: "hero-add-recipe.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        heroImgMobile: file(relativePath: { eq: "hero-add-recipe-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
    }
`;

export default Forbidden;
