import { types as apiTypes } from '../api/api.actions';

export const WELCOME_SLIDES = '[WELCOME_SLIDES]';

export const types = {
    // flow/commands actions
    GET_WELCOME_SLIDES: `${WELCOME_SLIDES} get list`,
    CLEAR_WELCOME_SLIDES: `${WELCOME_SLIDES} clear`,

    // API actions
    FETCH_WELCOME_SLIDES_REQUEST: `${WELCOME_SLIDES} ${apiTypes.API_REQUEST}`,
    FETCH_WELCOME_SLIDES_SUCCESS: `${WELCOME_SLIDES} ${apiTypes.API_SUCCESS}`,
    FETCH_WELCOME_SLIDES_FAIL: `${WELCOME_SLIDES} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_WELCOME_SLIDES: `${WELCOME_SLIDES} set list`,
    RESTORE_INITIAL_STATE: `${WELCOME_SLIDES} restore initial`,
};

// middleware actions
export const getWelcomeSlidesAction = () => ({
    type: types.GET_WELCOME_SLIDES,
});

export const clearWelcomeSlidesAction = () => ({
    type: types.CLEAR_WELCOME_SLIDES,
});

// reducer actions
export const setWelcomeSlidesAction = (payload) => ({
    type: types.SET_WELCOME_SLIDES,
    payload: payload,
});

export const restoreInitialStateAction = () => ({
    type: types.RESTORE_INITIAL_STATE,
});
