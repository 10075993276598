import {
    sseClearEvents,
    sseSetEvent,
    sseSetEventStatus,
} from './sse-subscription.actions';

export const SSE = `[SSE]`;

export const sseStatusMap = {
    connecting: 1,
    open: 1,
    closed: 2,
    init: 3,
    idle: 4,
};

export const sseInitState = {
    status: sseStatusMap.idle,
    subscriptions: {},
};

export const sseSubscriptionReducer = (state = sseInitState, action) => {
    const { type, payload } = action;

    switch (type) {
        case sseSetEvent().type:
            return {
                ...state,
                subscriptions: {
                    ...state.subscriptions,
                    [`${Date.now()}-sse`]: payload,
                },
            };
        case sseSetEventStatus().type:
            return {
                ...state,
                status: payload,
            };
        case sseClearEvents().type:
            return sseInitState;
        default:
            return state;
    }
};
