import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { form, loader, submitButton, modal, inputSelect } from './add-meal-type-modal.module.scss';
import { config } from '../../../config';

import Button from '../../atoms/button';
import Loader from '../../atoms/loader';
import InputSelect from '../../atoms/form-poc/input-select';
import CustomModal from '../../../templates/custom-modal';

const { required } = config.messages.form;
const { formsStatusMap } = config;

const initialValues = {
    mealType: '',
};

const validationSchema = Yup.object({
    mealType: Yup.object().required(required),
});

const AddMealTypeModal = ({ modalId, onSubmit, options }) => {
    return (
        <CustomModal modalId={modalId} className={modal} title="Dodaj nowy typ posiłku">
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {(formik) => (
                    <>
                        {formsStatusMap.loading === formik.status && (
                            <Loader fullContainer={true} className={loader} />
                        )}
                        <Form className={form}>
                            <InputSelect
                                containerClass={inputSelect}
                                name="mealType"
                                label="Typ posiłku"
                                options={options}
                                isSearchable={false}
                            />
                            <Button size="small" color="yellow" className={submitButton}>
                                Dodaj
                            </Button>
                        </Form>
                    </>
                )}
            </Formik>
        </CustomModal>
    );
};

AddMealTypeModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.number,
        })
    ),
    onSubmit: PropTypes.func,
};

AddMealTypeModal.defaultProps = {
    onSubmit: () => {},
};

export default AddMealTypeModal;
