import moment from 'moment';
import { getFormattedDate } from '../../utills/date-utils';

const selectTrainingById = (state, id) => {
    return state.personalTrainings.knownTrainings[id];
};
const selectCurrentTraining = (state) => {
    const currentId = state.personalTrainings.current.id;
    return selectTrainingById(state, currentId);
};
// select only archived trainings
const selectArchivedTrainingsForList = (state) => {
    const list = state.personalTrainings.trainingsList.ids;

    return list.reduce((arr, id) => {
        const training = selectTrainingById(state, id);
        if (training && moment(training.dateTo).isBefore(moment())) {
            arr.push(training);
        }
        return arr;
    }, []);
};

const selectPlayerMovie = (state) => {
    const index = state.personalTrainings.current.playlistMovieIndex;
    return selectPlayerMovieByIndex(state, index);
};

const selectNextPlayerMovie = (state) => {
    const index = state.personalTrainings.current.playlistMovieIndex + 1;
    return selectPlayerMovieByIndex(state, index);
};

const selectPlayerMovieByIndex = (state, index) => {
    const type = state.personalTrainings.current.listType;
    const movies = selectCurrentMovies(state, type);
    return movies && movies[index] ? movies[index] : null;
};

const selectCurrentMovies = (state, movieType) => {
    const current = state.personalTrainings.current;
    const date = getFormattedDate(current.date);
    const training = selectCurrentTraining(state);

    return training &&
        training.days &&
        training.days[date] &&
        training.days[date][movieType]
        ? training.days[date][movieType]
        : null;
};

export {
    selectTrainingById,
    selectCurrentTraining,
    selectCurrentMovies,
    selectPlayerMovie,
    selectNextPlayerMovie,
    selectArchivedTrainingsForList,
};
