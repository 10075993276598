import React from 'react';
import PropTypes from 'prop-types';

import {
    container,
    title,
    link,
    counterLabel,
    counterBox,
    counter,
} from './recipe-simple-header.module.scss';
import useRecipeAbsolutePath from '../../../hooks/use-recipe-absolute-path';

import Counter from '../../atoms/counter';

const RecipeSimpleHeader = ({ recipe, portions, className = '', onPortionsChange }) => {
    const recipePath = useRecipeAbsolutePath(recipe);

    const handlePortionsChange = (value) => {
        if (typeof onPortionsChange === 'function') {
            onPortionsChange(value);
        }
    };

    return (
        <div className={`${container} ${className}`}>
            <a href={recipePath} rel="noopener noreferrer" target="_blank" className={link}>
                <h2 className={title}>{recipe.name}</h2>
            </a>
            <div className={counterBox}>
                <span className={counterLabel}>Porcja:</span>
                <Counter
                    min={1}
                    className={counter}
                    initialValue={portions}
                    onChange={handlePortionsChange}
                />
            </div>
        </div>
    );
};

RecipeSimpleHeader.propTypes = {
    recipe: PropTypes.object.isRequired,
    className: PropTypes.string,
    onPortionsChange: PropTypes.func,
};

RecipeSimpleHeader.defaultProps = {
    className: '',
    onPortionsChange: null,
};

export default RecipeSimpleHeader;
