import React from 'react';

import {
    listItem,
    listActiveItem,
    topWrapper,
    list,
    underLine,
    lowerHolder,
} from './switcher.module.scss';
import { config } from '../../../config';

import PlayList from './movie-additional-training-list/play-list';

const { typesMap } = config.personalTraining;

const Switcher = ({ listTypeContext, toggleListType }) => {
    const genCssClass = (key) => {
        return ` ${listItem} ${listTypeContext === key ? listActiveItem : ''}`;
    };

    return (
        <>
            <div className={topWrapper}>
                <div className={list}>
                    {typesMap &&
                        Object.values(typesMap).map((label) => {
                            return (
                                <button
                                    className={genCssClass(label.key)}
                                    onClick={() => toggleListType(label.key)}
                                    key={`label-switcher--${label.key}`}
                                >
                                    {label.name}
                                    <div className={underLine} />
                                </button>
                            );
                        })}
                </div>
            </div>
            <div className={lowerHolder}>
                <PlayList currentListType={listTypeContext} />
            </div>
        </>
    );
};

export default Switcher;
