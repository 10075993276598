// extracted by mini-css-extract-plugin
export var bottomHolder = "courses-list-item-module--bottom-holder--z+gc4";
export var buttonHolder = "courses-list-item-module--button-holder--pOFOm";
export var content = "courses-list-item-module--content--Qe8+T";
export var iconInfoWrapper = "courses-list-item-module--icon-info-wrapper--pzteR";
export var iconLock = "courses-list-item-module--icon-lock--XNuuM";
export var image = "courses-list-item-module--image--F43GI";
export var imageWrapper = "courses-list-item-module--image-wrapper--HDFQg";
export var listContentWrapper = "courses-list-item-module--list-content-wrapper--8SXUt";
export var listItem = "courses-list-item-module--list-item--nxfZ1";
export var lockIconWrapper = "courses-list-item-module--lock-icon-wrapper--pagxz";
export var placeHolder = "courses-list-item-module--place-holder--lBUhC";
export var placeHolderContent = "courses-list-item-module--place-holder-content--vFfAA";
export var ratioClass = "courses-list-item-module--ratio-class--oIq1d";
export var timeIcon = "courses-list-item-module--time-icon--G0JNS";
export var timeIconWrapper = "courses-list-item-module--time-icon-wrapper--qEQUR";
export var tvIcon = "courses-list-item-module--tv-icon--YkKdK";
export var tvIconWrapper = "courses-list-item-module--tv-icon-wrapper--ZwULA";
export var wrapper = "courses-list-item-module--wrapper--hzav-";