import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    button,
    container,
    contentBox,
    emotics,
    text,
    titleText,
    disclaimer,
    infoIcon,
} from './order-result.module.scss';
import SuccessEmotics from '../../assets/images/svg/success-emotics.svg';
import ErrorEmotics from '../../assets/images/svg/error-emotics.svg';
import InfoIcon from '../../assets/images/svg/icon-info-black.svg';
import { types } from '../../redux/shop/order/order.actions';
import { getAbsolutePath } from '../../routes';

import Main from '../../layouts/main';
import SEO from '../../components/seo';
import Title from '../../components/atoms/title';
import Emotic from '../../components/atoms/emotic';
import LinkButton from '../../components/atoms/link-button';

const OrderResult = ({ routeData, location, isVerificationError, isSubscriptionResult }) => {
    const isUrlErrorParam = new URLSearchParams(location.search).get('error');
    const dispatch = useDispatch();
    const resultType = getResultType({
        isUrlErrorParam,
        isVerificationError,
        isSubscriptionResult,
    });
    const { emotic, title, content, firstLink, secondLink, additionalInfo } = resultType;

    useEffect(() => {
        dispatch({ type: types.CHECK });
    }, [dispatch]);

    return (
        <>
            <SEO title={routeData.label} />
            <Main isShopCart={true} className={container} isPaddingEqualToHeader={true}>
                <div className={contentBox}>
                    <Emotic emotic={emotic} className={emotics} />
                    <Title Tag="h1" className={titleText}>
                        {title}
                    </Title>
                    <p className={text}>{content}</p>
                    <LinkButton
                        to={firstLink.key ? getAbsolutePath(firstLink.key) : firstLink.to}
                        color="yellow"
                        className={button}
                    >
                        {firstLink.label}
                    </LinkButton>

                    {secondLink && (
                        <LinkButton
                            to={secondLink.key ? getAbsolutePath(secondLink.key) : secondLink.to}
                            className={button}
                        >
                            {secondLink.label}
                        </LinkButton>
                    )}

                    {additionalInfo && (
                        <p className={disclaimer}>
                            <InfoIcon className={infoIcon} />
                            {additionalInfo}
                        </p>
                    )}
                </div>
            </Main>
        </>
    );
};

function getResultType({ isUrlErrorParam, isVerificationError, isSubscriptionResult }) {
    if (isVerificationError) return orderResultTypes.recurringError;
    if (isUrlErrorParam) return orderResultTypes.singleError;
    if (isSubscriptionResult) return orderResultTypes.subscriptionSuccess;
    return orderResultTypes.singleSuccess;
}

const orderResultTypes = {
    singleSuccess: {
        emotic: SuccessEmotics,
        title: `Płatność przebiegła pomyślnie!`,
        content: `Dziękujemy za złożenie zamówienia.`,
        firstLink: {
            key: '',
            to: '/sklep/',
            label: 'Wróć do sklepu',
        },
        additionalInfo: ``,
    },
    singleError: {
        emotic: ErrorEmotics,
        title: `Wystąpiły problemy z płatnością...`,
        content: `Nie udało się prawidłowo dokonać płatności. Sprawdź swoje zamówienie w moje konto > historia płatności i w razie potrzeby ponów płatność.`,
        firstLink: {
            key: '',
            to: '/sklep/',
            label: 'Wróć do sklepu',
        },
        additionalInfo: ``,
    },
    subscriptionSuccess: {
        emotic: SuccessEmotics,
        title: `Witaj w gronie naszych podopiecznych!`,
        content: (
            <>
                Właśnie rozpoczyna się nowy rozdział w&nbsp;Twoim życiu! <br />
                Po wykupieniu karnetu prosimy Cię teraz o&nbsp;wypełnienie ankiety, na&nbsp;bazie
                której ułożymy dla Ciebie trening oraz plan żywieniowy.
            </>
        ),
        firstLink: {
            key: 'APP',
            to: '',
            label: 'Idź do Moje Konto',
        },
        additionalInfo: (
            <>
                Ankietę znajdziesz w&nbsp;sekcji Moje konto&nbsp;{'>'} Strefa Użytkownika&nbsp;{'>'}{' '}
                Twoje ankiety
            </>
        ),
    },
    recurringError: {
        emotic: ErrorEmotics,
        title: `Wystąpiły trudności w weryfikacji płatności...`,
        content: `Weryfikacja płatności u operatora trwa zbyt długo. Sprawdź swoje zamówienie w moje konto > historia płatności i w razie potrzeby ponów płatność.`,
        firstLink: {
            key: '',
            to: '/sklep/',
            label: 'Wróć do sklepu',
        },
        additionalInfo: ``,
    },
};

export default OrderResult;
