import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IThread } from '../models/thread.model';
import { TStatus } from '../models/status.model';
import { IProfile } from '../models/profile.model';
import { selectForumThread } from '../redux/forum/forum.selectors';
import { selectLoaderByEntity } from '../redux/ui/ui.selectors';
import {
    clearForum,
    FORUM_THREAD,
    FORUM_THREAD_TOGGLE_FAVORITE,
    getForumThread,
    toggleForumThreadFavorite,
} from '../redux/forum/forum.actions';
import { getCurrentProfile } from '../redux/profile/profile.selectors';

export const useForumThread = (threadId: number | string) => {
    const dispatch = useDispatch();
    const thread: IThread | null = useSelector(selectForumThread);
    const profile: IProfile = useSelector(getCurrentProfile);
    const status: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, FORUM_THREAD);
    });
    const toggleFavoriteStatus: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, FORUM_THREAD_TOGGLE_FAVORITE);
    });

    useEffect(() => {
        if (!threadId) return;
        dispatch(getForumThread(threadId));

        return () => {
            dispatch(clearForum());
        };
    }, [threadId, dispatch]);

    return {
        data: thread,
        status: status || 'idle',
        isFavorite: !!profile?.favouriteGroupThreadsIds?.includes(Number(threadId)),
        toggleFavorite: () => dispatch(toggleForumThreadFavorite(Number(threadId))),
        toggleFavoriteStatus: toggleFavoriteStatus || 'idle',
    };
};
