import { genericListInitState } from './generic-list.reducer';

export const selectList = (state, entity, subEntity) => {
    if (state[entity]) {
        return state[entity][subEntity] || genericListInitState;
    }
};

export const selectListItem = (state, entity, subEntity, id) => {
    const list = selectList(state, entity, subEntity);
    return list.items.find((item) => item.id === id);
};
