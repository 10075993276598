import React, { useRef } from 'react';

import { title as titleClass, open as openClass, arrow } from './filter-group-title.module.scss';

const FilterGroupTitle = ({ title, appliedCount = 0, open = false, onClick, ...rest }) => {
    const ref = useRef(null);

    const handleClick = () => {
        ref.current.blur();
        if (onClick) {
            onClick();
        }
    };

    return (
        <button ref={ref} onClick={handleClick} className={titleClass} {...rest} type={'button'}>
            <span>
                {title}
                {appliedCount > 0 && <span>{appliedCount}</span>}
            </span>
            <span className={`${arrow} ${open ? openClass : ''}`}>&#10094;</span>
        </button>
    );
};

export default FilterGroupTitle;
