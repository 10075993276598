import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { onlyText } from 'react-children-utilities';

import {
    form,
    inputGroup,
    bigLabel,
    form__wrapper,
    checkbox,
    profileInputs,
    names,
    tabs,
    guarantee,
    rodoNote,
} from './register-form.module.scss';
import { config } from '../../../config';
import { registerUser } from '../../../redux/user/user.actions';
import { findSubscriptionTypeInCart } from '../../../redux/shop/cart/cart.selectors';
import { copy } from '../../../copy';

import Input from '../../atoms/form-poc/input';
import Button from '../../atoms/button';
import TabSwitcher, { Tab, TabPanel } from '../../molecules/tab-switcher';
import Loader from '../../atoms/loader';
import Checkbox from '../../atoms/form-poc/checkbox';
import TextError from '../../atoms/form-poc/text-error';
import ReadMore from '../../atoms/read-more';
import CheckAllCheckbox from '../../atoms/form-poc/check-all-checkbox';

const accountType = config.subscriptionMap.attributes.accountType.values;

const { consents } = copy;

const {
    form: { required, email },
} = config.messages;

const formsStatusMap = config.formsStatusMap;
const { profileTypesMap, consentsMap } = config;

const initialValues = {
    profileType: profileTypesMap.single,
    disabledIds: [],
    firstProfile: {
        firstName: '',
        lastName: '',
    },
    secondProfile: {
        firstName: '',
        lastName: '',
    },
    email: '',
    consents: {
        newsletterShort: false,
        regulations: false,
        rodo: false,
        newsletter: false,
    },
};

const validationSchema = Yup.object({
    profileType: Yup.number(),
    disabledIds: Yup.array(),
    firstProfile: Yup.object({
        firstName: Yup.string().required(required),
        lastName: Yup.string().required(required),
    }),
    secondProfile: Yup.object().when('profileType', {
        is: profileTypesMap.couple,
        then: Yup.object({
            firstName: Yup.string().required(required),
            lastName: Yup.string().required(required),
        }),
    }),
    email: Yup.string().email(email).required(required),
    consents: Yup.object({
        newsletterShort: Yup.boolean(),
        regulations: Yup.boolean().required(required).oneOf([true], required),
        rodo: Yup.boolean().required(required).oneOf([true], required),
        newsletter: Yup.boolean().when('newsletterShort', {
            is: (newsletterShort) => newsletterShort,
            then: Yup.boolean().required(required).oneOf([true], required),
        }),
    }),
});

const RegisterForm = ({ context = {} }) => {
    const dispatch = useDispatch();
    const [initialFormState, setInitialFormState] = useState(initialValues);
    const subscriptionType = useSelector(findSubscriptionTypeInCart);

    const handleSubmit = async (formValues, formikBag) => {
        const {
            email,
            password,
            profileType,
            firstProfile,
            secondProfile,
            consents: formConsents,
        } = formValues;

        const data = {
            email,
            password,
            profiles:
                profileType === profileTypesMap.single
                    ? [firstProfile]
                    : [firstProfile, secondProfile],
            subscribeNewsletter: formConsents.newsletterShort,
            consents: [
                {
                    source: consentsMap.source.registerForm,
                    key: consentsMap.key.newsletterShort,
                    content: consents.newsletterShort,
                    agreed: formConsents.newsletterShort ? 1 : 0,
                },
                {
                    source: consentsMap.source.registerForm,
                    key: consentsMap.key.newsletter,
                    content: consents.newsletter,
                    agreed: formConsents.newsletter ? 1 : 0,
                },
                {
                    source: consentsMap.source.registerForm,
                    key: consentsMap.key.regulations,
                    content: onlyText(consents.regulations),
                    agreed: formConsents.regulations ? 1 : 0,
                },
                {
                    source: consentsMap.source.registerForm,
                    key: consentsMap.key.rodo,
                    content: consents.rodo,
                    agreed: formConsents.rodo ? 1 : 0,
                },
            ],
            context,
        };

        dispatch(registerUser(data, formikBag));
    };

    const handleChangeTab = (activeTab, formik) => {
        formik.setTouched({});
        formik.setFieldValue('profileType', activeTab, true);
    };

    useEffect(() => {
        let subType = {
            ...initialFormState,
            disabledIds: [],
        };

        if (subscriptionType === accountType.pair.key) {
            subType = {
                ...initialFormState,
                profileType: profileTypesMap.couple,
                disabledIds: [profileTypesMap.single],
            };
        }
        if (subscriptionType === accountType.single.key) {
            subType = {
                ...initialFormState,
                profileType: profileTypesMap.single,
                disabledIds: [profileTypesMap.couple],
            };
        }
        setInitialFormState(subType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionType]);

    return (
        <Formik
            initialValues={initialFormState}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {(formik) => {
                return (
                    <div className={form__wrapper}>
                        {(formik.status === formsStatusMap.loading ||
                            formik.status === formsStatusMap.success) && <Loader fullContainer />}
                        <Form className={form}>
                            <TabSwitcher
                                onChange={(activeTab) => handleChangeTab(activeTab, formik)}
                                openedId={formik.values.profileType}
                                disabledIds={formik.values.disabledIds}
                                className={profileInputs}
                            >
                                <div className={tabs}>
                                    <Tab id={profileTypesMap.single}>Pojedyczne</Tab>
                                    <Tab id={profileTypesMap.couple}>Dla par</Tab>
                                </div>
                                <TabPanel whenActive={profileTypesMap.couple}>
                                    <div className={bigLabel}>Pierwsza osoba</div>
                                </TabPanel>
                                <div className={names}>
                                    <Input
                                        name="firstProfile.firstName"
                                        placeholder="Imię"
                                        containerClass={inputGroup}
                                        // eslint-disable-next-line jsx-a11y/no-autofocus
                                        autoFocus
                                    />
                                    <Input
                                        name="firstProfile.lastName"
                                        placeholder="Nazwisko"
                                        containerClass={inputGroup}
                                    />
                                </div>
                                <TabPanel whenActive={profileTypesMap.couple}>
                                    <div className={bigLabel}>Druga osoba</div>
                                    <div className={names}>
                                        <Input
                                            name="secondProfile.firstName"
                                            placeholder="Imię"
                                            containerClass={inputGroup}
                                        />
                                        <Input
                                            name="secondProfile.lastName"
                                            placeholder="Nazwisko"
                                            containerClass={inputGroup}
                                        />
                                    </div>
                                    <div className={bigLabel}>Wspólne dane logowania</div>
                                </TabPanel>
                            </TabSwitcher>
                            <Input
                                name="email"
                                id={'register-email'}
                                type="email"
                                placeholder="Adres e-mail"
                                containerClass={inputGroup}
                            />
                            <div className={guarantee}>{consents.warrantyTwo}</div>
                            <CheckAllCheckbox
                                checkboxNames={[
                                    'consents.newsletterShort',
                                    'consents.newsletter',
                                    'consents.regulations',
                                    'consents.rodo',
                                ]}
                                containerClass={checkbox}
                            />
                            <Checkbox name="consents.newsletterShort" containerClass={checkbox}>
                                {consents.newsletterShort}
                            </Checkbox>
                            {formik.values.consents.newsletterShort && (
                                <Checkbox name="consents.newsletter" containerClass={checkbox}>
                                    <ReadMore>{consents.newsletter}</ReadMore>
                                </Checkbox>
                            )}
                            <Checkbox name="consents.regulations" containerClass={checkbox}>
                                {consents.regulations}
                            </Checkbox>
                            <div className={rodoNote}>
                                <ReadMore>{consents.rodoDisclaimer}</ReadMore>
                            </div>
                            <Checkbox name="consents.rodo" containerClass={checkbox}>
                                {consents.rodo}
                            </Checkbox>
                            {formik.errors.global && <TextError>{formik.errors.global}</TextError>}
                            <Button
                                color="yellow"
                                fullwidth={true}
                                disabled={formik.status === formsStatusMap.loading}
                            >
                                Utwórz konto
                            </Button>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};

export default RegisterForm;
