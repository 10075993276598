// extracted by mini-css-extract-plugin
export var activity = "form-module--activity--MUFs3";
export var age = "form-module--age--pAJxJ";
export var checkboxContainer = "form-module--checkbox-container--j5Qub";
export var collaboration = "form-module--collaboration--wyjwk";
export var fourColumns = "form-module--four-columns--XrNGm";
export var generalInfo = "form-module--general-info--5GArQ";
export var height = "form-module--height--FHRtS";
export var row = "form-module--row--e1Ad+";
export var separator = "form-module--separator--WKyf7";
export var sleep = "form-module--sleep--OEKp2";
export var subtitle = "form-module--subtitle--9tAiN";
export var thigh = "form-module--thigh--wIUn5";
export var title = "form-module--title--OSErS";
export var waist = "form-module--waist--uJrWD";
export var weight = "form-module--weight--eQr-G";