import React from 'react';
import AnimateHeight from 'react-animate-height';

import { title, subtitle, separator, row, checkboxContainer } from './form.module.scss';
import { checkboxes } from './survey-config';

import Input from '../../atoms/form-poc/input';
import Separator from '../../atoms/separator';
import CheckboxGroup from '../../atoms/form-poc/checkbox-group';

const FormTraining = ({ formik }) => {
    return (
        <>
            <h3 className={title}>Aktywność fizyczna</h3>

            <h4 className={subtitle}>Z kim preferujesz ćwiczyć?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="preferredTrainer"
                    options={checkboxes.preferredTrainer}
                    singleChoice={true}
                    containerClass={checkboxContainer}
                />
            </div>

            <h4 className={subtitle}>
                Jakie są Twoje najbardziej priorytetowe cele w kontekście planu treningowego?
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="trainingTargets"
                    options={checkboxes.trainingTargets}
                    containerClass={checkboxContainer}
                />
            </div>
            <AnimateHeight height={formik.values.trainingTargets.includes('specific') ? 'auto' : 0}>
                <div className={row}>
                    <Input as="textarea" name="specificTrainingTargetDescription" label="Opis" />
                </div>
            </AnimateHeight>

            <h4 className={subtitle}>Jakie partie ciała są dla Ciebie priorytetem?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="bodyParts"
                    options={checkboxes.bodyParts}
                    containerClass={checkboxContainer}
                />
            </div>

            <h4 className={subtitle}>
                Czy występują u Ciebie przeciwwskazania do podjęcia wysiłku fizycznego? Jeżeli tak,
                jakie?
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="trainingContraindications" />
            </div>

            <h4 className={subtitle}>
                Czy występują u Ciebie dolegliwości bólowe? Jeżeli tak, jakie?
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="painComplaints" />
            </div>

            <h4 className={subtitle}>
                Czy posiadasz obecnie lub doszło u Ciebie kiedykolwiek do kontuzji?
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="injuriesDescription" />
            </div>

            <h4 className={subtitle}>Jaki posiadasz staż treningowy?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="trainingExperience"
                    options={checkboxes.trainingExperience}
                    singleChoice={true}
                    containerClass={checkboxContainer}
                />
            </div>

            <h4 className={subtitle}>Ile czasu chcesz przeznaczyć na trening?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="preferredTrainingDuration"
                    options={checkboxes.preferredTrainingDuration}
                    singleChoice={true}
                    containerClass={checkboxContainer}
                />
            </div>

            <h4 className={subtitle}>Jakie są Twoje ulubione ćwiczenia?</h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="favoriteExercises" />
            </div>

            <h4 className={subtitle}>Jakie są ćwiczenia, których nie lubisz?</h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="dislikedExercises" />
            </div>

            <h4 className={subtitle}>Gdzie wolisz realizować plan treningowy?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="preferredTrainingPlace"
                    options={checkboxes.preferredTrainingPlace}
                    containerClass={checkboxContainer}
                    singleChoice={true}
                />
            </div>

            <h4 className={subtitle}>
                Z jakim sprzętem będziesz realizować przyszły plan treningowy?
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="preferredTrainingEquipment"
                    options={checkboxes.preferredTrainingEquipment}
                    containerClass={checkboxContainer}
                />
            </div>
            <AnimateHeight
                height={formik.values.preferredTrainingEquipment.includes('other') ? 'auto' : 0}
            >
                <div className={row}>
                    <Input as="textarea" name="otherPreferredTrainingEquipment" label="Opis" />
                </div>
            </AnimateHeight>

            <h4 className={subtitle}>
                Jakie są największe zauważone przez Ciebie problemy podczas treningów?
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="trainingProblems" />
            </div>

            <h4 className={subtitle}>Jakie masz oczekiwania wobec planu treningowego?</h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="trainingExpectations" />
            </div>
        </>
    );
};

export default FormTraining;
