import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IThread } from '../models/thread.model';
import { IResponsePagination } from '../models/pagination';
import { TStatus } from '../models/status.model';
import { selectForumThreads, selectForumThreadsPinned } from '../redux/forum/forum.selectors';
import { selectLoaderByEntity } from '../redux/ui/ui.selectors';
import {
    clearForumThreadsItems,
    clearForumThreadsPinnedItems,
    FORUM_THREADS,
    FORUM_THREADS_PINNED,
    getForumThreads,
    getForumThreadsPinned,
} from '../redux/forum/forum.actions';

interface IForumThreadsState {
    items: IThread[];
    pagination: IResponsePagination;
}

export const useForumThreads = ({
    categoryId,
    isFavorite,
    search,
}: {
    categoryId: number | string;
    isFavorite?: boolean;
    search: string;
}) => {
    const dispatch = useDispatch();
    const threads: IForumThreadsState = useSelector(selectForumThreads);
    const threadsPinned: IThread[] = useSelector(selectForumThreadsPinned);
    const statusAll: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, FORUM_THREADS);
    });
    const statusPinned: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, FORUM_THREADS_PINNED);
    });

    const getNextPage = () => {
        if (!categoryId) return;
        if (threads.pagination.currentPage === 0) return;
        if (threads.pagination.currentPage > threads.pagination.pageCount) return;
        if (threads.items.length && threads.pagination.currentPage === threads.pagination.pageCount)
            return;
        dispatch(
            getForumThreads({
                page: threads.pagination.currentPage + 1,
                perPage: threads.pagination.perPage,
                categoryId,
                isFavorite,
                search,
            })
        );
    };

    useEffect(() => {
        if (!categoryId) return;
        dispatch(clearForumThreadsItems());
        dispatch(clearForumThreadsItems());
        dispatch(
            getForumThreads({
                page: 1,
                perPage: threads.pagination.perPage,
                categoryId,
                isFavorite,
                search,
            })
        );
    }, [dispatch, search, categoryId, threads.pagination.perPage, isFavorite]);

    useEffect(() => {
        dispatch(clearForumThreadsPinnedItems());
        if (!isFavorite) {
            dispatch(getForumThreadsPinned(categoryId));
        }
    }, [categoryId, dispatch, isFavorite]);

    return {
        all: {
            ...threads,
            status: statusAll || 'idle',
            getNextPage,
        },
        pinned: {
            items: threadsPinned,
            status: statusPinned || 'idle',
        },
    };
};
