import { config } from '../../config';
import { EXERCISE_ATLAS, LIST } from './exercise-atlas.reducer';
import genericListMiddleware from '../generic-list/generic-list.middleware';

const listEndpoint = config.endpoints.workout.exercises;
const expand = `bodyParts,equipment,difficulty`;

const exerciseAtlasListMiddleware = genericListMiddleware(
    EXERCISE_ATLAS,
    LIST,
    listEndpoint,
    expand
);

const exerciseAtlasMiddleware = [
    exerciseAtlasListMiddleware,
    // add more middlewares for subEntities if needed
];

export default exerciseAtlasMiddleware;
