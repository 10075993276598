import React, { useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import {
    container,
    list,
    paginationRow,
    error,
    noContent,
    loader,
} from './files-to-download.module.scss';
import { config } from '../../../config';
import {
    getFilesToDownload,
    clearFilesToDownload,
    FILES_TO_DOWNLOAD,
} from '../../../redux/files-to-download/files-to-download.actions';
import { selectFilesToDownload } from '../../../redux/files-to-download/files-to-download.selectors';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { getAbsolutePath } from '../../../routes';

import DownloadBlock from '../../molecules/download-block/download-block';
import Pagination from '../../molecules/pagination';
import Loader from '../../atoms/loader';

const statusMap = config.apiStatusMap;

const FilesToDownload = ({ routeData, location }) => {
    const dispatch = useDispatch();
    const { pages, pagination } = useSelector(selectFilesToDownload);
    const status = useSelector((state) => selectLoaderByEntity(state, FILES_TO_DOWNLOAD));

    const [page, setPage] = useState(null);

    const navigateToPage = useCallback(
        async (pageNumber, replace = false) => {
            await navigate(`${getAbsolutePath(routeData.key)}?page=${pageNumber}`, {
                replace: replace,
            });
        },
        [routeData.key]
    );

    useEffect(() => {
        return () => {
            dispatch(clearFilesToDownload());
        };
    }, [dispatch]);

    useEffect(() => {
        const urlPage = new URLSearchParams(location.search).get('page');
        if (urlPage) {
            setPage(urlPage);
        } else {
            navigateToPage(1, true).then();
        }
    }, [location.search, navigateToPage]);

    useEffect(() => {
        if (page && !pages[page]) {
            dispatch(getFilesToDownload(page, pagination.perPage));
        }
    }, [page, dispatch, pages, pagination.perPage]);

    switch (true) {
        case status === statusMap.fail:
            return (
                <div className={error}>
                    Wystąpił błąd, nie udało się pobrać danych. Spróbój później.
                </div>
            );
        case !Object.keys(pages).length:
            return <Loader className={loader} />;
        case status === statusMap.success && pagination.totalCount === 0:
            return <div className={noContent}>Nie masz jeszcze żadnych plików do pobrania.</div>;
        default:
            return (
                <div className={container}>
                    <div className={list}>
                        {status === statusMap.loading || !pages[page] ? (
                            <Loader className={loader} />
                        ) : (
                            pages[page] &&
                            pages[page].map((item) => {
                                const file = item.media[0];
                                if (!file) return null;
                                return <DownloadBlock file={file} key={item.id} />;
                            })
                        )}
                    </div>
                    {pagination.pageCount > 1 && (
                        <Pagination
                            className={paginationRow}
                            page={Number(page)}
                            pageCount={pagination.pageCount}
                            onChange={(page) => navigateToPage(page)}
                            disabled={status === statusMap.loading}
                        />
                    )}
                </div>
            );
    }
};

export default FilesToDownload;
