/*
 * TODO: Delete deprecated props. Components should use partner props instead
 */
import React, { useCallback } from 'react';

import {
    badge,
    codeText,
    labelText,
    benefitLabelWrapper,
    notHovered,
    highlight,
    logo,
    blurred,
    businessPartnerTile,
    disabled,
} from './business-partner-tile.module.scss';
import { IPartner, IPartnerCode, IPartnerZonePool } from '../../models/partners-zone.model';
import { ToggleRequired } from '../../utills/types';
import pointsWithText from '../../utills/gamification/points-with-text';
import isPartnersZoneEnabled from '../../utills/partners-zone/is-partners-zone-enabled';

import Badge from '../atoms/badge';

export interface IBusinessPartnerTileData
    extends ToggleRequired<Omit<Partial<IPartnerZonePool>, 'partner'>, 'label'> {
    partner: ToggleRequired<Partial<IPartner>, 'logoUrl' | 'name'>;
    code?: IPartnerCode['code'];
}

export interface IBusinessPartnerTileProps {
    data: IBusinessPartnerTileData;
    className?: string;
    noHover?: boolean;
    highlighted?: boolean; //DEPRECATED
    onClick?: () => void;
    codeRef?: React.RefObject<HTMLParagraphElement>;
}

export default function BusinessPartnerTile({
    data,
    className,
    noHover,
    highlighted,
    onClick,
    codeRef,
}: IBusinessPartnerTileProps) {
    const { cost, label, isHighlighted, isOutOfStock, code, partner } = data;

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    return (
        <button
            className={getClassNames({
                className,
                isHighlighted: highlighted || isHighlighted,
                isOutOfStock,
                noHover,
            })}
            onClick={handleClick}
        >
            {cost && (
                <Badge
                    color={isOutOfStock ? 'var(--color-grey-mid)' : 'var(--color-yellow-medium)'}
                    className={badge}
                >
                    {pointsWithText(cost)}
                </Badge>
            )}
            <div className={benefitLabelWrapper}>
                <p className={labelText}>
                    {isOutOfStock ? 'Aktualnie niedostępny' : `Kod rabatowy - ${label}`}
                </p>
                {code && (
                    <p ref={codeRef} className={codeText}>
                        {code}
                    </p>
                )}
            </div>
            <img src={partner.logoUrl} alt="logo" className={logo} />
        </button>
    );
}

const getClassNames = ({
    className,
    isHighlighted,
    isOutOfStock,
    noHover,
}: Pick<IBusinessPartnerTileProps, 'className' | 'noHover'> &
    Pick<IBusinessPartnerTileProps['data'], 'isHighlighted' | 'isOutOfStock'>) => {
    return `${businessPartnerTile} ${isOutOfStock ? disabled : isHighlighted ? highlight : ''} ${
        !isPartnersZoneEnabled() ? blurred : ''
    } ${noHover ? notHovered : ''}${className}`;
};
