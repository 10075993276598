import React from 'react';
import { ErrorMessage, Field } from 'formik';

import {
    input,
    label,
    box,
    check,
    container,
    radio,
    iconWrapper,
    withIcon,
} from './checkbox-group.module.scss';

import TextError from './text-error';

const CheckboxGroup = ({
    name,
    options,
    containerClass = '',
    outerContainerClass = '',
    singleChoice = false,
    isWithIcon = false,
    ...rest
}) => {
    return (
        <div className={outerContainerClass}>
            <div className={`${container} ${containerClass}`}>
                <Field id={name} name={name} {...rest}>
                    {({ field }) => {
                        return options.map((option) => {
                            const { Icon, iconClassName = '', wrapperClassName = '' } = option;
                            return (
                                <label
                                    key={`${name}-${option.value}`}
                                    htmlFor={`${name}-${option.value}`}
                                    className={`${label} ${isWithIcon ? withIcon : ''}`}
                                >
                                    <input
                                        type={singleChoice ? 'radio' : 'checkbox'}
                                        id={`${name}-${option.value}`}
                                        {...field}
                                        value={option.value}
                                        checked={
                                            singleChoice
                                                ? field.value.toString() === option.value.toString()
                                                : field.value.includes(option.value.toString())
                                        }
                                        className={input}
                                    />
                                    <span
                                        className={`
                                            ${box}
                                            ${singleChoice ? radio : check}
                                        `}
                                    />
                                    {option.label}

                                    {isWithIcon ? (
                                        <div className={`${iconWrapper} ${wrapperClassName}`}>
                                            <div className={iconClassName}>
                                                <Icon />
                                            </div>
                                        </div>
                                    ) : null}
                                </label>
                            );
                        });
                    }}
                </Field>
            </div>
            <ErrorMessage name={name} component={TextError} />
        </div>
    );
};

export default CheckboxGroup;
