import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
    form,
    button,
    separator,
    globalError,
    inputFile,
    inputFileInfo,
    inputRow,
    modal,
} from './add-meal-modal.module.scss';
import { config } from '../../../config';
import { getImgUriFromMedia } from '../../../utills/get-img-uri-from-media';

import SeparatorWithText from '../../atoms/separator-with-text';
import FileInputField from '../../molecules/form/file-input-field';
import Button from '../../atoms/button';
import Input from '../../atoms/form-poc/input';
import TextError from '../../atoms/form-poc/text-error';
import Loader from '../../atoms/loader';
import CustomModal from '../../../templates/custom-modal';

const { required } = config.messages.form;
const { formsStatusMap } = config;

const initialValues = {
    name: '',
    description: '',
    mealPhoto: '',
};

const validationSchema = Yup.object({
    name: Yup.string().required(required),
});

const getFormValues = (meal) => {
    const imgUri = getImgUriFromMedia(meal);
    return {
        name: meal.name,
        description: meal.description || '',
        mealPhoto: imgUri ? [{ uri: imgUri }] : '',
    };
};

const AddMealModal = ({ modalId, meal, onReturn, onSubmitNewMeal, onSubmitEditMeal }) => {
    const [initialFormValues, setInitialFormValues] = useState(initialValues);

    const handleReturnClick = () => onReturn();

    const handleSubmit = (formikValues, formikBag) => {
        if (meal) {
            onSubmitEditMeal(formikValues, formikBag, meal.mealId, modalId);
        } else {
            onSubmitNewMeal(formikValues, formikBag, modalId);
        }
    };

    useEffect(() => {
        if (meal) {
            setInitialFormValues(getFormValues(meal));
        }
    }, [meal]);

    return (
        <CustomModal
            className={modal}
            modalId={modalId}
            title={`${meal ? 'Edytuj' : 'Dodaj'} WŁASNY posiłek`}
        >
            <Formik
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {(formik) => (
                    <Form className={form}>
                        {formik.status === formsStatusMap.loading && (
                            <Loader fullContainer={true} />
                        )}
                        <div className={inputRow}>
                            <Input
                                name="name"
                                label="Nazwa posiłku"
                                placeholder="np. Granola z jogurtem"
                            />
                            <Input
                                name="description"
                                label="Szczegóły"
                                placeholder="np. Objętość Twojego posiłku"
                                as="textarea"
                                rows={1}
                            />
                        </div>
                        <FileInputField
                            name="mealPhoto"
                            label="Dodaj zdjęcie posiłku"
                            fileTypes={config.fileTypesMap.images}
                            imgOnly={true}
                            multiple={false}
                            containerClass={inputFile}
                            small={true}
                        />
                        <p className={inputFileInfo}>( Opcjonalnie )</p>
                        {formik.errors.global && (
                            <div className={globalError}>
                                <TextError>{formik.errors.global}</TextError>
                            </div>
                        )}
                        <Button color="yellow" className={button} size="small">
                            {meal ? 'Zapisz zmiany' : 'Dodaj posiłek'}
                        </Button>
                        {!meal && (
                            <>
                                <SeparatorWithText className={separator}>Lub</SeparatorWithText>
                                <Button
                                    type="button"
                                    onClick={handleReturnClick}
                                    className={button}
                                    color="blank"
                                    size="small"
                                >
                                    Wróć do posiłków ze Strefy
                                </Button>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </CustomModal>
    );
};

AddMealModal.propTypes = {
    modalId: PropTypes.number,
    meal: PropTypes.object,
    onReturn: PropTypes.func,
    onSubmitNewMeal: PropTypes.func,
    onSubmitEditMeal: PropTypes.func,
};

AddMealModal.defaultProps = {
    modalId: null,
    meal: null,
};

export default AddMealModal;
