import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { config } from '../../../config';
import { addModalAction, removeModalAction } from '../../../redux/actions/actions-modals';
import { forbiddenClick } from '../../../redux/rbac/rbac.actions';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { getCurrentProfileId } from '../../../redux/profile/profile.selectors';
import {
    createShoppingList,
    editShoppingList,
    SINGLE_SHOPPING_LIST_CHANGE,
} from '../../../redux/single-shopping-list/single-shopping-list.actions';
import { statusIdle } from '../../../redux/ui/ui.actions';

import Button from '../button';
import AddShoppingListModal from '../../organisms/custom-modals/add-shopping-list-modal';

const { apiStatusMap } = config;

const AddToListButton = ({
    className = '',
    ingredients,
    textOnly,
    forbidden,
    onAddToListClick,
}) => {
    const dispatch = useDispatch();
    const { status, profileId } = useSelector((state) => {
        return {
            status: selectLoaderByEntity(state, SINGLE_SHOPPING_LIST_CHANGE),
            profileId: getCurrentProfileId(state),
        };
    });

    const [addToListModalId, setAddToListModalId] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(false);

    const Tag = textOnly ? 'button' : Button;
    const tagProps = textOnly ? {} : { color: forbidden ? 'grey' : 'blank' };

    const handleAddToNewShoppingList = (formValues, formikBag, modalId) => {
        setAddToListModalId(modalId);
        const data = {
            profileId: profileId,
            name: formValues.name,
            ingredients: ingredients,
        };
        dispatch(createShoppingList(data, formikBag));
    };

    const handleAddToOldShoppingList = (formValues, formikBag, modalId) => {
        setAddToListModalId(modalId);
        const data = { ingredients };
        dispatch(editShoppingList(formValues.selectedList.value, data, formikBag, true));
    };

    const handleAddToShoppingList = () => {
        if (forbidden) {
            dispatch(forbiddenClick());
            return;
        }
        setButtonClicked(true);
        onAddToListClick();
        dispatch(
            addModalAction({
                renderComponent: (modalId) => (
                    <AddShoppingListModal
                        modalId={modalId}
                        onNewListSubmit={handleAddToNewShoppingList}
                        onEditListSubmit={handleAddToOldShoppingList}
                    />
                ),
            })
        );
    };

    useEffect(() => {
        if (!buttonClicked || status === apiStatusMap.loading || status === apiStatusMap.idle)
            return;
        if (status === apiStatusMap.success) {
            dispatch(removeModalAction(addToListModalId));
            addShoppingListSuccessModal(dispatch, () => setButtonClicked(false));
        }
        if (status === apiStatusMap.fail) {
            addShoppingListErrorModal(dispatch, () => setButtonClicked(false));
        }
        dispatch(statusIdle(SINGLE_SHOPPING_LIST_CHANGE));
    }, [addToListModalId, dispatch, buttonClicked, status]);

    return (
        <Tag {...tagProps} className={className} onClick={handleAddToShoppingList}>
            Dodaj do listy zakupowej
        </Tag>
    );
};

function addShoppingListSuccessModal(dispatch, onClose) {
    dispatch(
        addModalAction({
            type: 'info',
            title: 'Składniki dodane',
            content: 'Składniki zostały dodane do listy zakupowej.',
            onClose: onClose,
        })
    );
}

function addShoppingListErrorModal(dispatch, onClose) {
    dispatch(
        addModalAction({
            type: 'error',
            title: 'Coś poszło nie tak!',
            content: 'Nie udało się dodać składników do listy zakupowej.',
            onClose: onClose,
        })
    );
}

AddToListButton.propTypes = {
    className: PropTypes.string,
    ingredients: PropTypes.arrayOf(PropTypes.object),
    textOnly: PropTypes.bool,
    forbidden: PropTypes.bool,
    onAddToListClick: PropTypes.func,
};

AddToListButton.defaultProps = {
    className: '',
    ingredients: [],
    textOnly: false,
    forbidden: false,
    onAddToListClick: () => {},
};

export default AddToListButton;
