import React from 'react';

import { video, videoPreview } from './recipe-video-preview.module.scss';
import { title, separator } from './common-recipe-molecules.module.scss';

import Separator from '../../atoms/separator';
import VideoPostPreview from '../video-post-preview';

const RecipeVideoPreview = ({ recipe, className = '' }) => {
    return (
        <div className={`${video} ${className}`}>
            <h2 className={title}>Video</h2>
            <Separator className={separator} />
            <VideoPostPreview
                className={videoPreview}
                videoUrl={recipe.videoUri}
                provider={recipe.videoProvider}
                imageUrl={recipe.coverUri}
            />
        </div>
    );
};

export default RecipeVideoPreview;
