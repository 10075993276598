import { types as apiTypes } from '../api/api.actions';

export const DIET_TYPES = '[DIET_TYPES]';

export const types = {
    // user triggered actions
    FETCH: `${DIET_TYPES} fetch`,

    // api actions
    FETCH_REQUEST: `${DIET_TYPES} ${apiTypes.API_REQUEST}`,
    FETCH_SUCCESS: `${DIET_TYPES} ${apiTypes.API_SUCCESS}`,
    FETCH_FAIL: `${DIET_TYPES} ${apiTypes.API_SUCCESS}`,

    // write actions
    SET_DIET_TYPES: `${DIET_TYPES} set data`,
    CLEAR_DIET_TYPES: `${DIET_TYPES} clear data`,
};

export const fetchDietTypes = () => ({
    type: types.FETCH,
});

export const setDietTypes = (payload) => ({
    type: types.SET_DIET_TYPES,
    payload: payload,
});

export const clearDietTypes = () => ({
    type: types.CLEAR_DIET_TYPES,
});
