import React, { useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

import { form, inputGroup, registerButton, textCenter } from './login-form.module.scss';
import { config } from '../../../config';
import { loginUser as newLoginUser } from '../../../redux/user/user.actions';

import Input from '../../atoms/form-poc/input';
import Button from '../../atoms/button';
import LinkUnderline from '../../atoms/link-underline';
import SeparatorWithText from '../../atoms/separator-with-text';
import Loader from '../../atoms/loader';
import TextError from '../../atoms/form-poc/text-error';

const FILE_REDIRECT_PARAM_KEY = 'redirectToFile';

const {
    form: { required, email },
} = config.messages;

const initialValues = {
    email: '',
    password: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email(email).required(required),
    password: Yup.string().required(required),
});

const LoginForm = ({ isShoppingStep = false, classname }) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const formikRef = useRef(null);

    const handleSubmit = (formValues, formikBag) => {
        dispatch(newLoginUser({ formValues, formikBag }));
    };

    useEffect(() => {
        if (!search || !formikRef.current) return;
        const params = new URLSearchParams(search);
        const fileRedirectParam = params.get(FILE_REDIRECT_PARAM_KEY);
        if (!fileRedirectParam) return;
        formikRef.current.setFieldValue(FILE_REDIRECT_PARAM_KEY, fileRedirectParam);
    }, [search]);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formik) => (
                <>
                    <Form className={`${form} ${classname}`}>
                        {formik.status === config.formsStatusMap.loading && (
                            <Loader fullContainer />
                        )}
                        <Input
                            containerClass={inputGroup}
                            name="email"
                            type="email"
                            placeholder="Adres e-mail"
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            {...(isShoppingStep ? {} : { autoFocus: true })}
                        />
                        <Input
                            containerClass={inputGroup}
                            name="password"
                            type="password"
                            placeholder="Hasło"
                        />
                        {formik.errors.global && <TextError>{formik.errors.global}</TextError>}
                        <Button color="yellow" fullwidth={true}>
                            Zaloguj się
                        </Button>
                        <Link to="/lost-password/">
                            <LinkUnderline className={`${isShoppingStep ? textCenter : ''}`}>
                                Nie pamietam hasła!
                            </LinkUnderline>
                        </Link>
                    </Form>
                    {isShoppingStep ? null : (
                        <>
                            <SeparatorWithText>Lub</SeparatorWithText>
                            <Link to="/register/" className={registerButton}>
                                <Button color="blank" fullwidth={true}>
                                    Zarejestruj się
                                </Button>
                            </Link>
                        </>
                    )}
                </>
            )}
        </Formik>
    );
};

export default LoginForm;
