function isNodeInputElement(node: Node): node is HTMLInputElement {
    return node.nodeName === 'INPUT';
}

/*
 * navigator.clipboard is available only in secure content (https) or in localhost
 */
export async function writeToClipboard(text: string) {
    if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        return true;
    }

    return false;
}

export function oldCopyToClipboard(node: Node) {
    // if node is input we can select text by select() method
    if (isNodeInputElement(node)) {
        node.select();
        return;
    }

    //Otherwise, we have to use range

    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection?.removeAllRanges();
    selection?.addRange(range);

    /*
     * I have to use deprecated method, because Clipboard API haven't been fully implemented yet and
     * it requires user to set permissions for reading and writing clipboard.
     */
    document.execCommand('copy');
}

export default async function copyTextElement(node: Node, text = node.textContent) {
    if (text) {
        if (!(await writeToClipboard(text))) {
            //eslint-disable-next-line no-console
            console.warn(
                'navigator.clipboard was not found - using fallback way to copy text. Be sure you are in secure context or on localhost'
            );
            oldCopyToClipboard(node);
        }
    }
}
