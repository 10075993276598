import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import {
    wrapper,
    title,
    loader,
    holder,
    actions,
    actionBtns,
    createOrEdit,
    planer,
} from './meal-planer-templates.module.scss';
import Plus from '../../assets/images/svg/icon-plus.svg';
import { config } from '../../config';
import { selectLoaderByEntity } from '../../redux/ui/ui.selectors';
import MealPlannerTemplateList from '../../components/organisms/meal-planner-template/meal-planer-template-list';
import MealPlannerTemplateForm from '../../components/organisms/meal-planner-template/meal-planer-template-form';
import {
    MEAL_PLANER_TEMPLATE,
    types,
} from '../../redux/meal-planer-template/meal-planer-template.actions';
import { ROUTES } from '../../routes';

import Main from '../../layouts/main';
import SEO from '../../components/seo';
import Title from '../../components/atoms/title';
import MealPlannerGrid from '../../components/organisms/meal-planner/meal-planer-grid';
import Loader from '../../components/atoms/loader';
import ActionButtons from '../../components/molecules/meal-planner/action-buttons';

const MealPlanerTemplates = ({ routeData, id }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => ({
        id: state.mealPlanerTemplate.current.id,
        current: state.mealPlanerTemplate.current,
        status: selectLoaderByEntity(state, MEAL_PLANER_TEMPLATE),
    }));
    const [thisRootPath] = useState(findRootPath('APP_MEAL_PLANER_TEMPLATE'));

    const editTemplate = () => {
        dispatch({ type: types.EDIT_TEMPLATE });
    };

    const addMealType = () => {
        dispatch({ type: types.ADD_MEAL_TYPE });
    };

    const goToList = () => {
        dispatch({ type: types.CLEAR_CURRENT_TEMPLATE_FROM_STATE });
        navigate(`/app/${thisRootPath}`);
    };

    const handleChooseTemplate = (templateId) => {
        navigate(`/app/${thisRootPath}/${templateId}`);
    };

    useEffect(() => {
        if (id) {
            dispatch({ type: types.GET_TEMPLATE_DATA, payload: { id } });
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (!id && state.id) {
            navigate(`/app/${thisRootPath}/${state.id}`);
        }
    }, [state, id, thisRootPath]);

    const buttons = [
        {
            id: 3,
            action: () => goToList(),
            title: 'Powrót do listy',
            color: 'black',
            outline: true,
        },
        {
            id: 2,
            action: () => addMealType(),
            title: 'Dodaj nowy typ posiłku',
            color: 'grey',
            outline: false,
            icon: Plus,
        },
        {
            id: 4,
            action: () => editTemplate(),
            title: 'Edytuj dane planu',
            color: 'yellow',
            outline: true,
        },
    ];

    return (
        <Main className={wrapper} hasNewsletterForm={false} isPaddingEqualToHeader>
            <SEO title={routeData.label} />
            <Helmet>
                {/* eslint-disable-next-line jsx-a11y/html-has-lang*/}
                <html className={'no-overflow'} />
            </Helmet>
            <section className={title}>
                <Title>{routeData.label}</Title>
            </section>

            <section className={holder}>
                {state.status === config.apiStatusMap.loading && <Loader className={loader} />}

                {state.current.id ? (
                    <>
                        <div className={actions}>
                            <h2>{state.current.name}</h2>

                            <ActionButtons className={actionBtns} buttons={buttons} />
                        </div>
                        <MealPlannerGrid className={planer} state={state.current} isTemplate />
                    </>
                ) : (
                    <div className={createOrEdit}>
                        <MealPlannerTemplateForm />
                        <MealPlannerTemplateList onChooseTemplate={handleChooseTemplate} />
                    </div>
                )}
            </section>
        </Main>
    );
};

const findRootPath = (key) => {
    const appRoute = ROUTES.find((route) => route.key === 'APP');
    return appRoute.routes.find((route) => route.key === key)?.path;
};

export default MealPlanerTemplates;
