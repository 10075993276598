import React from 'react';

import { tags as tagsClass } from './tag-list.module.scss';

import Tag from '../atoms/tag';

const TagList = ({ className = '', tags = [], selectable = false, active = true, onClick }) => {
    return (
        <div className={`${tagsClass} ${className}`}>
            {tags.map((tag) => {
                return (
                    <Tag
                        key={tag.tagId}
                        selectable={selectable}
                        active={active}
                        tag={tag}
                        onClick={onClick}
                    />
                );
            })}
        </div>
    );
};

export default TagList;
