// extracted by mini-css-extract-plugin
export var bigLabel = "register-form-module--big-label--ey1J-";
export var button = "register-form-module--button--P5mnX";
export var checkbox = "register-form-module--checkbox--XfIhD";
export var form = "register-form-module--form--YK5+e";
export var form__wrapper = "register-form-module--form__wrapper--5Z1dW";
export var guarantee = "register-form-module--guarantee--Zk7Vg";
export var inputGroup = "register-form-module--input-group--ULSqg";
export var loader = "register-form-module--loader--yhOoD";
export var names = "register-form-module--names--rfhm-";
export var profileInputs = "register-form-module--profile-inputs--Ehu3u";
export var rodoNote = "register-form-module--rodo-note--IsR1W";
export var tabs = "register-form-module--tabs--fHH4I";
export var title = "register-form-module--title--WtPRf";