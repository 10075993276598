// extracted by mini-css-extract-plugin
export var aboutTraining = "personal-training-module--about-training--ocr7n";
export var calendar = "personal-training-module--calendar--lwpNz";
export var dateRange = "personal-training-module--date-range--NJ+EK";
export var entryMovie = "personal-training-module--entry-movie--r29bM";
export var header = "personal-training-module--header--4AcJE";
export var horizontalLineEntryMovie = "personal-training-module--horizontal-line-entry-movie--ZmIa2";
export var horizontalLineMoviePlayerTitle = "personal-training-module--horizontal-line-movie-player-title--QsG9w";
export var horizontalLineTitleDescription = "personal-training-module--horizontal-line-title-description--JMnVa";
export var image_wrapper = "personal-training-module--image_wrapper--rJTTL";
export var noTrainingInfo = "personal-training-module--no-training-info--gSp8I";
export var textStart = "personal-training-module--text-start--0c4Iw";
export var tips = "personal-training-module--tips--FH2zb";
export var toDo = "personal-training-module--to-do--ukma8";
export var trainingTitle = "personal-training-module--training-title--J6uQj";
export var wrapper = "personal-training-module--wrapper--ikpxw";