// extracted by mini-css-extract-plugin
export var banner = "link-banner-module--banner--WFZb8";
export var descriptionText = "link-banner-module--description-text--7sGnE";
export var imageContent = "link-banner-module--imageContent--ebznK";
export var imageDescription = "link-banner-module--imageDescription--u1JkY";
export var imageTitle = "link-banner-module--imageTitle--GCiNO";
export var imageWrapper = "link-banner-module--imageWrapper--07l2s";
export var linkPass = "link-banner-module--linkPass--PIsY5";
export var linkPassBox = "link-banner-module--linkPassBox--AXsW3";
export var lockIcon = "link-banner-module--lockIcon--Ir6BB";
export var notActive = "link-banner-module--not-active--hMCgW";
export var titleText = "link-banner-module--title-text--slFhR";
export var wip = "link-banner-module--wip--LuMZ5";