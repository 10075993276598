import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { container, link, name, label, img, lock, nameBox } from './recipe-search-item.module.scss';
import LockIcon from '../../../assets/images/svg/icon-lock.svg';
import { forbiddenClick } from '../../../redux/rbac/rbac.actions';
import useRecipeAbsolutePath from '../../../hooks/use-recipe-absolute-path';
import useIsProfileRecipeOwner from '../../../hooks/use-is-profile-recipe-owner';

import Avatar from '../avatar';

const RecipeSearchItem = ({ className, recipe, onRecipeSelect }) => {
    const dispatch = useDispatch();
    const isOwnedByProfile = useIsProfileRecipeOwner(recipe);
    const recipePath = useRecipeAbsolutePath(recipe);

    const isRestricted = recipe.restrictedFields?.length > 0;

    const handleClick = () => {
        if (isRestricted) {
            dispatch(forbiddenClick());
        } else {
            onRecipeSelect(recipe);
        }
    };

    return (
        <div className={`${container} ${className}`}>
            <button className={nameBox} onClick={handleClick}>
                {isRestricted ? (
                    <div className={lock}>
                        <LockIcon />
                    </div>
                ) : (
                    <Avatar className={img} avatarUri={recipe.coverUri} hasShadow={false} />
                )}
                <span className={name}>
                    {isOwnedByProfile && <span className={label}>Twój przepis</span>}
                    {recipe.name}
                </span>
            </button>

            {!isRestricted && (
                <a href={recipePath} target="_blank" rel="noreferrer" className={link}>
                    zobacz <span>przepis</span>
                </a>
            )}
        </div>
    );
};

RecipeSearchItem.propTypes = {
    recipe: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        coverUri: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    onRecipeSelect: PropTypes.func,
};

RecipeSearchItem.defaultProps = {
    className: '',
    onItemSelect: '',
    onRecipeSelect: () => {},
};

export default RecipeSearchItem;
