// extracted by mini-css-extract-plugin
export var button = "course-not-owned-information-module--button--NQi1p";
export var iconSingleHolder = "course-not-owned-information-module--icon-single-holder--iGD36";
export var iconsHolder = "course-not-owned-information-module--icons-holder--Tpitd";
export var listItem = "course-not-owned-information-module--list-item--6eueN";
export var movieIcon = "course-not-owned-information-module--movie-icon--JI+QI";
export var mr = "course-not-owned-information-module--mr--RK1K3";
export var pillContainer = "course-not-owned-information-module--pill-container--Nk9gQ";
export var timeIcon = "course-not-owned-information-module--time-icon--++g7s";
export var title = "course-not-owned-information-module--title--KWQCQ";
export var tvIcon = "course-not-owned-information-module--tv-icon--xxc75";
export var wrapper = "course-not-owned-information-module--wrapper--0ZR5O";