import { types as apiTypes } from '../../api/api.actions';
import { PayloadAction, ActionCreator } from '../../../models/redux-action';
import { TDiscountState } from './discounts.reducer';

export const DISCOUNTS = '[DISCOUNTS]';

export const types = {
    REQUEST_DISCOUNTS_ALL: `${DISCOUNTS} request_all` as const,
    REQUEST_DISCOUNT: `${DISCOUNTS} request_discount` as const,

    FETCH_DISCOUNTS_ALL: `${DISCOUNTS} all ${apiTypes.API_REQUEST}`,
    FETCH_DISCOUNTS_ALL_SUCCESS: `${DISCOUNTS} all ${apiTypes.API_SUCCESS}`,
    FETCH_DISCOUNTS_ALL_FAIL: `${DISCOUNTS} all ${apiTypes.API_FAIL}`,
    FETCH_DISCOUNT: `${DISCOUNTS} item ${apiTypes.API_REQUEST}`,
    FETCH_DISCOUNT_SUCCESS: `${DISCOUNTS} item ${apiTypes.API_SUCCESS}`,
    FETCH_DISCOUNT_FAIL: `${DISCOUNTS} item ${apiTypes.API_FAIL}`,

    SET_DISCOUNTS: `${DISCOUNTS} set_discounts` as const,
};

type RequestDiscountsAllAction = PayloadAction<
    typeof types.REQUEST_DISCOUNTS_ALL,
    { page: number } | undefined
>;
type RequestDiscountAction = PayloadAction<typeof types.REQUEST_DISCOUNT, number | undefined>;
type SetDiscountsAction = PayloadAction<typeof types.SET_DISCOUNTS, TDiscountState | undefined>;

export type DiscountActions =
    | RequestDiscountsAllAction
    | RequestDiscountAction
    | SetDiscountsAction;

export const requestDiscountsAll: ActionCreator<RequestDiscountsAllAction> = (payload) => ({
    type: types.REQUEST_DISCOUNTS_ALL,
    payload,
});
export const requestDiscount: ActionCreator<RequestDiscountAction> = (payload) => ({
    type: types.REQUEST_DISCOUNT,
    payload,
});

export const setDiscounts: ActionCreator<SetDiscountsAction> = (payload) => ({
    type: types.SET_DISCOUNTS,
    payload,
});
