import React from 'react';

import { red, listItem, list, green } from './points-list.module.scss';

import PointsListItem, { PointListItem } from '../atoms/points-list-item';

export interface IPointsListProps {
    items: PointListItem[];
    noPointsColor?: boolean;
}

export default function PointsList({ items, noPointsColor = false }: IPointsListProps) {
    return (
        <ul className={list}>
            {items.length > 0 ? (
                items.map((item) => (
                    <PointsListItem
                        key={`point-list-item-${item.id}`}
                        item={item}
                        containerClassName={listItem}
                        pointsClassName={getPointsClassName(noPointsColor ? 0 : item.points)}
                    />
                ))
            ) : (
                <li>Niestety lista jest pusta</li>
            )}
        </ul>
    );
}

function getPointsClassName(points: number) {
    if (points > 0) {
        return green;
    } else if (points < 0) {
        return red;
    }
    return '';
}
