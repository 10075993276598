import axios from 'axios';

import { config } from '../config';

export const getExercise = (id) =>
    axios.get(config.endpoints.workout.exercise(id), {
        withCredentials: true,
    });

export const getExerciseList = (page, pageSize, sort) =>
    axios.get(config.endpoints.workout.exerciseCollection(page, pageSize, sort), {
        withCredentials: true,
    });

export const getTrainingsList = (page, pageSize, sort) =>
    axios.get(config.endpoints.workout.trainingsCollection(page, pageSize, sort), {
        withCredentials: true,
    });

export const getTraining = (id) =>
    axios.get(config.endpoints.workout.training(id), {
        withCredentials: true,
    });
