import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { mainGrid } from '../../style/grid.module.scss';
import { imgWrapper, joinBox, joinContainer } from './join-box.module.scss';

import Button from '../atoms/button';
import Title from '../atoms/title';

const JoinBox = ({ className = '' }) => {
    const data = useStaticQuery(query);

    return (
        <section className={`${mainGrid} ${joinBox} ${className}`}>
            <div className={joinContainer}>
                <div className={imgWrapper}>
                    <GatsbyImage
                        image={getImage(data.file)}
                        style={{ maxWidth: '310px' }}
                        imgStyle={{ objectFit: 'contain' }}
                        alt="Strefa Przemian"
                    />
                </div>
                <Title>
                    Pomogliśmy już tysiącom osób.
                    <br />
                    Dołącz do Strefy Przemian i zacznij swoją metamorfozę!!
                </Title>
                <Link to="/cennik/">
                    <Button>Chcę dołączyć!</Button>
                </Link>
            </div>
        </section>
    );
};

const query = graphql`
    {
        file(relativePath: { eq: "logo-strefa-photos.png" }) {
            childImageSharp {
                gatsbyImageData(width: 310, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
    }
`;

export default JoinBox;
