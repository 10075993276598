// extracted by mini-css-extract-plugin
export var actionBox = "recipe-card-module--action-box--yWTCR";
export var addButton = "recipe-card-module--add-button--4DHvW";
export var card = "recipe-card-module--card--6Xo4r";
export var headerLink = "recipe-card-module--header-link--u5X6E";
export var img = "recipe-card-module--img--FHiRE";
export var imgLink = "recipe-card-module--img-link--oeSFv";
export var imgWrapper = "recipe-card-module--img-wrapper--vgfd1";
export var lockBox = "recipe-card-module--lock-box--UDLPO";
export var lockLayer = "recipe-card-module--lock-layer--xlx+U";
export var metadata = "recipe-card-module--metadata--wXTXP";
export var ratioClass = "recipe-card-module--ratio-class--j-lh4";
export var title = "recipe-card-module--title--EVvuQ";