import React from 'react';
import { Link } from '@reach/router';

import { container, created, categories, counter, dot, vertical } from './thread-meta.module.scss';
import CommentIcon from '../../assets/images/svg/comment-01.svg';
import { IThread } from '../../models/thread.model';

interface IThreadMetaProps {
    className?: string;
    thread: IThread;
    categoryPathname?: string;
    isVerticalOnMobile?: boolean;
}

const ThreadMeta: React.FC<IThreadMetaProps> = ({
    className = '',
    thread,
    categoryPathname,
    isVerticalOnMobile = false,
}) => {
    const hasCategories =
        (thread.categories && thread.categories.length > 0) || thread.categoryNames.length > 0;
    return (
        <div className={`${container} ${className} ${isVerticalOnMobile ? vertical : ''}`}>
            {hasCategories && (
                <>
                    {thread.categories && thread.categories.length > 0 && categoryPathname ? (
                        thread.categories.map((category, index) => {
                            return (
                                <div className={categories}>
                                    {index > 0 && <span className={categories}>{`, `}</span>}
                                    <Link to={`${categoryPathname}/${category.categoryId}`}>
                                        {category.name}
                                    </Link>
                                </div>
                            );
                        })
                    ) : (
                        <span className={categories}>{thread.categoryNames.join(', ')}</span>
                    )}
                    <span className={dot}>•</span>
                </>
            )}
            <span className={created}>{thread.displayCreatedAt}</span>
            <span className={dot}>•</span>
            <span className={counter}>
                <CommentIcon /> {thread.commentsCount}
            </span>
        </div>
    );
};

export default ThreadMeta;
