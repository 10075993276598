import React from 'react';
import { useDispatch } from 'react-redux';

import { wrapper, folderWrapper, pillText, closeIcon } from './file-to-big-size-pill.module.scss';
import FolderSvg from '../../../assets/images/svg/folder.svg';
import IconClose from '../../../assets/images/svg/icon-close.svg';
import { removeAttachmentPillByIndex } from '../../../redux/messenger/additional-attachments-to-send/actions';

interface IFileToBigSizePillProps {
    className?: string;
    index: number;
}

const FileToBigSizePill: React.FC<IFileToBigSizePillProps> = ({ className = '', index }) => {
    const dispatch = useDispatch();

    const handleRemove = () => {
        dispatch(removeAttachmentPillByIndex(index));
    };

    return (
        <div className={`${wrapper} ${className}`}>
            <div className={folderWrapper}>
                <FolderSvg />
            </div>
            <div className={pillText}>Plik przekracza 32 MB</div>
            <button onClick={handleRemove} className={closeIcon}>
                <IconClose />
            </button>
        </div>
    );
};

export default FileToBigSizePill;
