import { types } from './survey.actions';

const { SET_SURVEY_VALUES, SET_SURVEY_STEP, RESTORE_INITIAL_STATE } = types;

const initialState = {
    step: 0,
    values: null,
};

const surveyReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SURVEY_VALUES:
            return {
                ...state,
                values: payload,
            };
        case SET_SURVEY_STEP:
            return {
                ...state,
                step: payload,
            };
        case RESTORE_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default surveyReducer;
