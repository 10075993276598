import { saveToPdfSingleRecipe } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';
import { downloadBlob } from '../../../../utills/download-blob';
import { addModalAction } from '../../../actions/actions-modals';

export const saveToPdfSingleRecipeAction = (id, ingredients) => async (dispatch, getState) => {
    dispatch(saveToPdfSingleRecipeStartAction());
    try {
        const res = await saveToPdfSingleRecipe(id, { ingredients });
        const pdfFileBlob = new Blob([res.data], { type: 'application/pdf' });
        const filename = getState().recipes.single.get.data.name;
        downloadBlob(pdfFileBlob, filename);
        dispatch(saveToPdfSingleRecipeSuccessAction());
    } catch {
        dispatch(saveToPdfSingleRecipeErrorAction());
        dispatch(
            addModalAction({
                type: 'error',
                title: 'Coś poszło nie tak!',
                content: 'Nie udało się pobrać pliku.',
            })
        );
    }
};

export const saveToPdfSingleRecipeClearSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.saveToPdf,
        entitiesMap.recipes.single.allowedActions.clearSuccess
    ),
});

export const saveToPdfSingleRecipeClearErrorAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.saveToPdf,
        entitiesMap.recipes.single.allowedActions.clearErrors
    ),
});

const saveToPdfSingleRecipeStartAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.saveToPdf,
            entitiesMap.recipes.single.allowedActions.start
        ),
        payload: {},
    };
};

const saveToPdfSingleRecipeSuccessAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.saveToPdf,
            entitiesMap.recipes.single.allowedActions.success
        ),
        payload: {},
    };
};

const saveToPdfSingleRecipeErrorAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.saveToPdf,
            entitiesMap.recipes.single.allowedActions.error
        ),
        payload: {},
    };
};
