import { config } from '../config';

export const errorHandler = (error) => {
    let messages = [];

    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response.data && error.response.data.messages) {
            error.response.data.messages.forEach(function (msg) {
                messages.push({
                    content: msg.content,
                    field: msg.field ? msg.field : 'global',
                    type: 'error',
                });
            });
        }
    } else if (error.request) {
        // The request was made but no response was received
        messages.push({
            content: config.messages.networkError,
            field: 'global',
            type: 'error',
        });
    } else {
        // Something happened in setting up the request and triggered an Error
        messages.push({
            content: config.messages.error,
            field: 'global',
            type: 'error',
        });
    }

    return messages;
};

export const formatFormikErrors = (errorObj) => {
    const errorsArray = errorHandler(errorObj);
    let formikErrors = {};

    errorsArray.forEach((item) => {
        formikErrors[item.field] = item.content;
    });
    return formikErrors;
};

export const getVideoId = (provider, videoUrl) => {
    const url = new URL(videoUrl);
    if (provider === 'youtube') {
        const urlParams = url.searchParams;
        return urlParams.get('v') || videoUrl;
    }
    if (provider === 'vimeo') {
        return url.pathname.split('/').slice(-1)[0];
    }
    return videoUrl;
};

export const humanizedSeconds = (inSeconds) => {
    const padLeft = (val) => val.toString().padStart(2, '0');
    const humanize = (values) => values.map(padLeft).join(':');

    const seconds = Math.floor(inSeconds % 60);
    const hours = Math.floor(inSeconds / 3600);
    const minutes = Math.floor((inSeconds % 3600) / 60);

    return hours > 0 ? humanize([hours, minutes, seconds]) : humanize([minutes, seconds]);
};
