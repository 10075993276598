import React from 'react';

import RecipeTypeIcon from '../../../assets/images/svg/icon-recipe-type.svg';
import DifficultyIcon from '../../../assets/images/svg/difficulty.svg';
import TimeIcon from '../../../assets/images/svg/time.svg';

import Metadata from '../metadata';

const RecipeMetadata = ({ color = 'white', recipe, className = '', fullInfo = false }) => {
    const getMetaItems = () => {
        let metaItems = [];

        const typesData = [
            ...recipe.mealTypes.map((type) => type.name),
            ...(fullInfo
                ? [
                      ...recipe.mealKinds.map((kind) => kind.name),
                      ...recipe.dietTypes.map((type) => type.name),
                      ...recipe.types.map((type) => type.name),
                  ]
                : []),
        ].join(', ');

        if (typesData) {
            metaItems.push({
                Icon: RecipeTypeIcon,
                data: typesData,
            });
        }

        if (recipe.difficultyLevel && recipe.difficultyLevel.length > 0) {
            metaItems.push({
                Icon: DifficultyIcon,
                data: recipe.difficultyLevel.map((type) => type.name).join(', '),
            });
        }

        if (recipe.preparationTime && recipe.preparationTime.length > 0) {
            metaItems.push({
                Icon: TimeIcon,
                data: recipe.preparationTime.map((type) => type.name).join(', '),
            });
        }

        return metaItems;
    };

    return (
        <div className={className}>
            <Metadata color={color} metaItems={getMetaItems()} centeredStyle={fullInfo} />
        </div>
    );
};

export default RecipeMetadata;
