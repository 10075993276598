import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { heart, heartEmpty } from './like-button.module.scss';
import HeartIcon from '../../../assets/images/svg/icon-heart.svg';
import HeartEmptyIcon from '../../../assets/images/svg/icon-heart-empty.svg';
import { IProfile } from '../../../models/profile.model';
import { toggleIsFavoriteRecipeAction } from '../../../redux/recipes/single/actions/toggle-is-favorite';
import { getCurrentProfile } from '../../../redux/profile/profile.selectors';

import Button, { TButtonColor } from '../button';

interface IRecipeLikeButtonProps {
    className?: string;
    recipeId: number;
    onToggleFavorite?(): void;
    textOnly?: boolean;
    color?: TButtonColor;
}

const LikeButton: React.FC<IRecipeLikeButtonProps> = ({
    className = '',
    recipeId,
    onToggleFavorite,
    textOnly = false,
    color = 'blank',
}) => {
    const dispatch = useDispatch();
    const profile = useSelector(getCurrentProfile);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const loading = useSelector((state) => state.recipes.single.toggleIsFavorite.loading);

    const isFavorite = checkIfFavorite(recipeId, profile);

    const handleToggleFavorite = () => {
        if (loading) return;
        if (typeof onToggleFavorite === 'function') {
            onToggleFavorite();
        }
        dispatch(toggleIsFavoriteRecipeAction(recipeId, profile.profileId, !isFavorite));
    };

    return textOnly ? (
        <button onClick={handleToggleFavorite} className={className}>
            {isFavorite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
        </button>
    ) : (
        <div className={className}>
            <Button
                color={color}
                circle={true}
                onClick={handleToggleFavorite}
                aria-label={isFavorite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
            >
                {isFavorite ? (
                    <HeartIcon className={heart} />
                ) : (
                    <HeartEmptyIcon className={heartEmpty} />
                )}
            </Button>
        </div>
    );
};

function checkIfFavorite(recipeId: number, profile: IProfile): boolean {
    if (!profile.favouriteRecipeIds) return false;
    return profile.favouriteRecipeIds.some((id) => id === recipeId);
}

export default LikeButton;
