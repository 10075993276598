import React from 'react';

import { wrapper, survey } from './survey-page.module.scss';

import Main from '../layouts/main';
import SEO from '../components/seo';
import Survey from '../components/organisms/survey/survey';

const SurveyPage = () => {
    return (
        <>
            <SEO title="Twoja Ankieta" />
            <Main className={wrapper}>
                <div className={survey}>
                    <Survey />
                </div>
            </Main>
        </>
    );
};

export default SurveyPage;
