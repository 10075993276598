export const product = {
    normal: 0,
    digital: 1,
};

export const businessMeaning = {
    subscription: 1,
    trainingAtlas: 2,
    plannerDemo: 3,
};
