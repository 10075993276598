import { permissionMap } from './permissions';

export const userHaveRightsTo = (action, userRoles = []) => {
    for (const role of userRoles) {
        let permissions = permissionMap[role] ? permissionMap[role] : [];
        if (permissions.includes(action)) {
            return true;
        }
    }
    return false;
};

export default userHaveRightsTo;
