import { types as apiTypes } from '../api/api.actions';

export const PERSONAL_TRAINING = '[PersonalTraining]';
export const PERSONAL_TRAINING_LIST = '[PersonalTrainingList]';
export const PERSONAL_TRAINING_CALENDAR = '[PersonalTrainingCalendar]';
export const PERSONAL_TRAINING_DAY = '[PersonalTrainingDay]';

export const types = {
    // user triggered actions eg. button click
    CHANGE_DAY: `${PERSONAL_TRAINING} change day`,
    CHANGE_LIST_TYPE: `${PERSONAL_TRAINING} change list type`,
    PLAY_MOVIE: `${PERSONAL_TRAINING} play movie`,
    GET_CURRENT_TRAINING: `${PERSONAL_TRAINING} get current`,
    GET_SINGLE_TRAINING: `${PERSONAL_TRAINING} get single`,
    GET_TRAININGS: `${PERSONAL_TRAINING} get collection`,

    // flow/commands actions
    GET_TRAINING: `${PERSONAL_TRAINING} get training`,
    GET_TRAINING_CALENDAR: `${PERSONAL_TRAINING} get calendar`,
    GET_TRAINING_DAY: `${PERSONAL_TRAINING} get day`,
    GET_TRAINING_LIST: `${PERSONAL_TRAINING} get list`,

    // api actions
    FETCH_SINGLE_TRAINING_REQUEST: `${PERSONAL_TRAINING} ${apiTypes.API_REQUEST}`,
    FETCH_SINGLE_TRAINING_SUCCESS: `${PERSONAL_TRAINING} ${apiTypes.API_SUCCESS}`,
    FETCH_SINGLE_TRAINING_FAIL: `${PERSONAL_TRAINING} ${apiTypes.API_FAIL}`,

    FETCH_TRAINING_CALENDAR_REQUEST: `${PERSONAL_TRAINING_CALENDAR} ${apiTypes.API_REQUEST}`,
    FETCH_TRAINING_CALENDAR_SUCCESS: `${PERSONAL_TRAINING_CALENDAR} ${apiTypes.API_SUCCESS}`,
    FETCH_TRAINING_CALENDAR_FAIL: `${PERSONAL_TRAINING_CALENDAR} ${apiTypes.API_FAIL}`,

    FETCH_TRAINING_LIST_REQUEST: `${PERSONAL_TRAINING_LIST} ${apiTypes.API_REQUEST}`,
    FETCH_TRAINING_LIST_SUCCESS: `${PERSONAL_TRAINING_LIST} ${apiTypes.API_SUCCESS}`,
    FETCH_TRAINING_LIST_FAIL: `${PERSONAL_TRAINING_LIST} ${apiTypes.API_FAIL}`,

    FETCH_TRAINING_DAY_REQUEST: `${PERSONAL_TRAINING_DAY} ${apiTypes.API_REQUEST}`,
    FETCH_TRAINING_DAY_SUCCESS: `${PERSONAL_TRAINING_DAY} ${apiTypes.API_SUCCESS}`,
    FETCH_TRAINING_DAY_FAIL: `${PERSONAL_TRAINING_DAY} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_CURRENT_TRAINING: `${PERSONAL_TRAINING} set`,
    SET_KNOWN_TRAININGS: `${PERSONAL_TRAINING} set known trainings`,
    SET_TRAINING_LIST: `${PERSONAL_TRAINING_LIST} set`,
    SET_TRAINING_CALENDAR: `${PERSONAL_TRAINING_CALENDAR} set`,
    SET_TRAINING_DAY: `${PERSONAL_TRAINING_DAY} set`,
    SET_TRAINING_MOVIE: `${PERSONAL_TRAINING} set movie`,
    CLEAR_TRAINING_DATA: `${PERSONAL_TRAINING} clear`,
};

// reducer actions (writing to state)
export const setCurrentTraining = (payload) => ({
    type: types.SET_CURRENT_TRAINING,
    payload: payload,
});
export const setTrainingList = (payload) => ({
    type: types.SET_TRAINING_LIST,
    payload: payload,
});
export const setKnownTraining = (payload) => ({
    type: types.SET_KNOWN_TRAININGS,
    payload: payload,
});
export const setTrainingCalendar = (payload) => ({
    type: types.SET_TRAINING_CALENDAR,
    payload: payload,
});
export const setTrainingDay = (payload) => ({
    type: types.SET_TRAINING_DAY,
    payload: payload,
});
export const setCurrentMovie = (payload) => ({
    type: types.SET_TRAINING_MOVIE,
    payload: payload,
});

// middleware actions
export const fetchTraining = (payload, meta) => ({
    type: types.GET_TRAINING,
    payload: payload,
    meta: meta,
});
export const fetchTrainingDay = (payload, meta) => ({
    type: types.GET_TRAINING_DAY,
    payload: payload,
    meta: meta,
});

export const fetchTrainingList = (payload, meta) => ({
    type: types.GET_TRAINING_LIST,
    payload: payload,
    meta: meta,
});
