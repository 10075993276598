import { types } from './subscriptions.actions';

const { SET_SUBSCRIPTIONS, RESTORE_INITIAL_SUBSCRIPTIONS_STATE } = types;

const initialState = {
    items: [],
    pagination: {
        currentPage: 0,
        pageCount: 0,
        perPage: 50,
        totalCount: 0,
    },
};

const subscriptionsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SUBSCRIPTIONS:
            return {
                ...state,
                items: payload.items,
                pagination: payload.pagination,
            };
        case RESTORE_INITIAL_SUBSCRIPTIONS_STATE:
            return initialState;
        default:
            return state;
    }
};

export default subscriptionsReducer;
