export const ALERTS = '[ALERTS]';

export const types = {
    SET_ALERT: `${ALERTS} set`,
    CLEAR_ALERT: `${ALERTS} clear`,
    CLEAR_ALL_ALERTS: `${ALERTS} clear all`,
};

export const setAlert = (alert, entity) => ({
    type: types.SET_ALERT,
    payload: {
        data: alert,
        meta: { entity, id: `${Date.now()}-${entity}` },
    },
});

export const clearAllAlerts = () => ({
    type: types.CLEAR_ALL_ALERTS,
});

export const deleteAlert = (alertId) => ({
    type: types.CLEAR_ALERT,
    payload: {
        data: alertId,
        meta: {},
    },
});
