// extracted by mini-css-extract-plugin
export var activeItem = "play-list-module--active-item--xLqq4";
export var additionalSingleMovieHolder = "play-list-module--additional-single-movie-holder--TNrji";
export var bold = "play-list-module--bold--mNz8Z";
export var breakTime = "play-list-module--break-time--vBJFL";
export var btn = "play-list-module--btn--fIKwi";
export var durationTime = "play-list-module--duration-time--DRK3o";
export var emptyInfo = "play-list-module--empty-info--yOSt5";
export var gatsbyImageWrapper = "play-list-module--gatsby-image-wrapper--W6DYv";
export var icon = "play-list-module--icon--aW3Yv";
export var labelName = "play-list-module--label-name--SEYMD";
export var listItem = "play-list-module--list-item--xnS2O";
export var movieCounter = "play-list-module--movie-counter--owg9a";
export var playIcon = "play-list-module--play-icon--54uYu";
export var playerItemInfo = "play-list-module--player-item-info--6F1OZ";
export var playlist = "play-list-module--playlist--GEHIi";
export var ratioClass = "play-list-module--ratio-class--xwYs9";
export var time = "play-list-module--time--4A7a4";
export var title = "play-list-module--title--oPYTn";
export var topWrapper = "play-list-module--top-wrapper--n61Yc";
export var trainingTypeWrapper = "play-list-module--training-type-wrapper--Z06cb";
export var wrapper = "play-list-module--wrapper--nVxhq";