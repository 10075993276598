import moment from 'moment';

export const defaultFormat = 'YYYY-MM-DD';

export const dotFormat = 'DD.MM.YYYY';

export const getFormattedDate = (date, isDotFormat = false, isTimestamp = false) => {
    const format = isDotFormat ? dotFormat : defaultFormat;
    const newDate = isTimestamp ? moment.unix(date) : moment(date);
    return newDate.format(format);
};

export const addDays = (date, amount) => {
    return moment(date).add(amount, 'days').format(defaultFormat);
};
export const subtractDays = (date, amount) => {
    return moment(date).subtract(amount, 'days').format(defaultFormat);
};

export const generateWeekDays = () => {
    const weekDays = {};

    for (let index = 0; index < 7; index++) {
        weekDays[moment().weekday(index).format('ddd')] = {
            key: index + 1,
            label: moment().weekday(index).format('dddd'),
            dimension: 'auto',
        };
    }

    return weekDays;
};
