import React from 'react';
import { useSelector } from 'react-redux';

import {
    wrapper,
    container,
    topWrapper,
    trainingTypeWrapper,
    emptyInfo,
    labelName,
    movieCounter,
    playlist,
    item,
} from './course-movies-list.module.scss';
import { currentMovieCourseIndex } from '../../../redux/current-course/selectors/course-movies-list.selectors';

import CoursePlayerListItem from '../course-player-list-item/course-player-list-item';

const CourseMoviesList = () => {
    const currentMovieIndex = useSelector((state) => {
        return currentMovieCourseIndex(state.currentCourse.currentCourse);
    });

    const chosenCourseCategory = useSelector((state) => {
        return state.currentCourse.currentCourse.chosenCategoryData;
    });

    return (
        <div className={container}>
            <div className={topWrapper}>
                <div className={playlist}>Playlista</div>
                <div className={trainingTypeWrapper}>
                    <span className={labelName}>{chosenCourseCategory.name}</span>
                    <span className={movieCounter}>
                        {currentMovieIndex} / {chosenCourseCategory.movies.length}
                    </span>
                </div>
            </div>
            <div className={wrapper}>
                {chosenCourseCategory.movies.length > 0 ? (
                    <ul>
                        {chosenCourseCategory.movies.map((movie, index) => {
                            return (
                                <li key={`course-player-list-item--${index}`} className={item}>
                                    <CoursePlayerListItem data={movie} />
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <div className={emptyInfo}>Brak filmów</div>
                )}
            </div>
        </div>
    );
};
export default CourseMoviesList;
