// extracted by mini-css-extract-plugin
export var avatarBox = "avatar-module--avatar-box--3oLA7";
export var avatarInnerBox = "avatar-module--avatar-inner-box--v9QpG";
export var big = "avatar-module--big--iKtYv";
export var border = "avatar-module--border--1gUZS";
export var dark = "avatar-module--dark--a6GNx";
export var huge = "avatar-module--huge--OAbw8";
export var image = "avatar-module--image--Hhe-P";
export var large = "avatar-module--large--rpvcv";
export var medium = "avatar-module--medium--DPrwv";
export var shadow = "avatar-module--shadow--qRNtg";
export var small = "avatar-module--small--F2PwV";