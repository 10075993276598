import React from 'react';

import {
    title,
    row,
    generalInfo,
    height,
    weight,
    age,
    waist,
    thigh,
    sleep,
    activity,
    collaboration,
} from './form.module.scss';

import Input from '../../atoms/form-poc/input';

const FormGeneralInfo = () => {
    return (
        <>
            <h3 className={title}>Informacje ogólne</h3>

            <div className={`${row} ${generalInfo}`}>
                <Input type="number" label="Wzrost cm" name="height" containerClass={height} />
                <Input type="number" label="Waga kg" name="weight" containerClass={weight} />
                <Input type="number" label="Wiek" name="age" containerClass={age} />
                <Input
                    type="number"
                    label="Obwód w pasie cm"
                    name="waist"
                    containerClass={waist}
                    hint="Zmierz obwód w pasie na wysokości pępka"
                />
                <Input
                    type="number"
                    label="Obwód uda cm"
                    name="thigh"
                    containerClass={thigh}
                    hint="Zmierz obwód uda tuż pod pośladkiem"
                />
                <Input
                    type="number"
                    label="Ile godzin śpisz? [h]"
                    name="sleepHours"
                    containerClass={sleep}
                />
                <Input
                    as="textarea"
                    label="Czy aktualnie wykonujesz aktywność fizyczną, jeśli tak to opisz w kilku zdaniach jak ona wygląda"
                    name="activityDescription"
                    containerClass={activity}
                />
                <Input
                    as="textarea"
                    label="Jakie masz oczekiwania wobec współpracy z nami?"
                    name="collaborationExpectations"
                    containerClass={collaboration}
                />
            </div>
        </>
    );
};

export default FormGeneralInfo;
