import { DISCOUNTS_ENABLE } from '../hooks/use-product-discounts';
import { IPrice } from '../models/price.model';
import { IPointsBenefitLabel, IProductDiscount } from '../models/product-promotion.model';
import { isNotNull } from './types-utility';

interface IGetDiscountInfoProps {
    price: IPrice;
    basePrice: IPrice;
    promotions?: IProductDiscount[];
    labelPointsBenefit: IPointsBenefitLabel;
}

export default function getDiscountInfo(props?: IGetDiscountInfoProps) {
    let isDiscounted = false;
    let benefitLabels: string[] = [];
    let conditionLabels: string[] = [];
    let color;

    if (props) {
        const { price, basePrice, promotions } = props;
        isDiscounted = DISCOUNTS_ENABLE && price && basePrice && price.gross !== basePrice.gross;
        benefitLabels = isDiscounted ? mapLabels(promotions, 'labelBenefit') : [];
        conditionLabels = isDiscounted ? mapLabels(promotions, 'labelCondition') : [];
        color = getDiscountColor(promotions);
    }

    return {
        isDiscounted,
        benefitLabels,
        conditionLabels,
        labelPointsBenefit:
            // TODO: change this condition when api will return correct types
            Array.isArray(props?.labelPointsBenefit) && props?.labelPointsBenefit.length === 0
                ? undefined
                : props?.labelPointsBenefit,
        color,
    };
}

const mapLabels = <FieldType extends keyof IProductDiscount>(
    promotions: IProductDiscount[] | undefined,
    field: FieldType
): NonNullable<IProductDiscount[FieldType]>[] => {
    return (
        promotions
            ?.map((promotion) => promotion[field])
            .filter<NonNullable<IProductDiscount[FieldType]>>(isNotNull) || []
    );
};

const getDiscountColor = (promotions?: IProductDiscount[]) => {
    if (promotions?.some((promotion) => promotion.isXselling)) {
        return '--color-discounts-secondary' as const;
    }
};
