import React from 'react';

import { planner } from './meal-planer-grid.module.scss';
import { config } from '../../../config';
import { generateGridCss } from '../../../utills/meal-planer';

import MealPlannerUnit from './meal-planer-unit';
import MealTypeLabel from '../../atoms/meal-planner/meal-type-label';
import DayLabel from '../../atoms/meal-planner/day-label';
import MealPlanerDivider from '../../atoms/meal-planner/meal-planer-divider';

const { mealTypesMap, coordinates } = config.mealPlanner;

const MealPlannerGrid = ({ className, isTemplate = false, state }) => {
    const { dates, planerUnits, columns, rows } = state;

    const layoutStyle = {
        '--columns': generateGridCss(columns),
        '--rows': generateGridCss(rows),
    };

    return (
        <div className={`${className} ${planner}`} style={layoutStyle}>
            <MealPlanerDivider row={coordinates.rows.dayLabel.key} />
            <MealPlanerDivider col={coordinates.cols.type.key} row={`3/-1`} vertical />

            {Object.entries(mealTypesMap).map(([key, type]) => {
                return <MealPlanerDivider key={`meal-type-${key}`} row={type.key} />;
            })}

            {dates.map((day) => {
                return <DayLabel key={`day-label-${day}`} day={day} isTemplate={isTemplate} />;
            })}

            {Object.entries(rows).map(([key, type]) => {
                if (key !== coordinates.rows.dayLabel.key) {
                    return (
                        <MealTypeLabel
                            key={`meal-type-${key}`}
                            type={type}
                            isTemplate={isTemplate}
                        />
                    );
                }
                return null;
            })}

            {Object.entries(planerUnits).map(([key, unit]) => {
                return (
                    <MealPlannerUnit key={key} unitId={key} unit={unit} isTemplate={isTemplate} />
                );
            })}
        </div>
    );
};

export default MealPlannerGrid;
