import pick from '../../utills/pick';

const prepareFilterParams = (filterObj) => {
    const params = {};
    if (filterObj) {
        Object.entries(filterObj).forEach(([key, filter]) => {
            Object.entries(filter.values).forEach(([valName, val]) => {
                if (val.applied) {
                    let separator = params[key] === undefined ? '' : ',';

                    if (valName === 'phrase') {
                        //hardcoded condition when filter is search
                        params[key] = val.value;
                    } else {
                        params[key] = (params[key] || '') + `${separator}${valName}`;
                    }
                }
            });
        });
    }

    return params;
};

const prepareMultipleFilterParams = (params) => {
    return Object.values(params).reduce(
        (acc, current) => ({
            ...acc,
            ...prepareFilterParams(current),
        }),
        {}
    );
};

const mergeStateWithPayload = (state, payload, fieldName) => {
    return {
        ...pick(state, ['filters', 'sort', 'search']),
        ...pick(payload, [fieldName]),
    };
};

const prepareGenericListParams = ({ state, payload, fieldName, page = 1 }) => {
    const mergedData = mergeStateWithPayload(state, payload, fieldName);

    return {
        page,
        ...payload.params,
        ...prepareMultipleFilterParams(mergedData),
    };
};

const arrayToItemIdIndexObject = (array, id = 'id') => {
    return array.reduce((acc, currentItem, index) => {
        if (currentItem[id]) {
            return {
                ...acc,
                [currentItem[id]]: index,
            };
        }
        return acc;
    }, {});
};

export {
    prepareFilterParams,
    prepareMultipleFilterParams,
    prepareGenericListParams,
    arrayToItemIdIndexObject,
};
