import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import {
    wrapper,
    asideList,
    emptyScreen,
    video,
    fakeWrapperIconLock,
    iconLock,
    pointsButtonWrapper,
    ratioClass,
} from './courses-movie-group.module.scss';
import IconLock from '../../../assets/images/svg/icon-lock.svg';
import gamificationReasons from '../../../global-enums/gamification-reasons.json';
import { selectCourseCurrentMovie } from '../../../redux/current-course/selectors/course-movies-list.selectors';
import useDimensions from '../../../hooks/use-dimensions';

import CourseMoviesList from '../course-movies-list/course-movies-list';
import VideoPlayer from '../video-player';
import GetPointsButton from '../../organisms/get-points-button';
import GetPointsButtonWrapper from '../get-points-button-wrapper';
import RatioImage from '../../atoms/ratio-image';

const CoursesMovieGroup = ({ className = '', playNext, isBought }) => {
    const currentMovie = useSelector(selectCourseCurrentMovie);
    const movieBoxRef = useRef(null);
    const [, movieBoxHeight] = useDimensions(movieBoxRef);

    return (
        <div className={className}>
            <div className={wrapper}>
                <section>
                    <div ref={movieBoxRef}>
                        <VideoPlayer
                            videoUrl={currentMovie.uri}
                            playNext={playNext}
                            autoplay={false}
                            followProgress={true}
                            wrapperClassName={video}
                        >
                            <div className={emptyScreen}>
                                <RatioImage
                                    src={currentMovie.coverViewUri}
                                    ratioClass={ratioClass}
                                />
                                <IconLock className={iconLock} />
                                <div className={fakeWrapperIconLock} />
                            </div>
                        </VideoPlayer>
                    </div>
                </section>
                <section className={asideList} style={{ height: `${movieBoxHeight || 400}px` }}>
                    <CourseMoviesList />
                </section>
                {isBought && (
                    <GetPointsButtonWrapper className={pointsButtonWrapper}>
                        <GetPointsButton
                            reasonId={gamificationReasons.courseMovieWatched}
                            objectId={currentMovie.movieId}
                        >
                            Ukończyłem lekcję
                        </GetPointsButton>
                    </GetPointsButtonWrapper>
                )}
            </div>
        </div>
    );
};

export default CoursesMovieGroup;
