import { componentLabel } from '../../../../templates/shop-steps/enums/component-maps';
import { buyingProcessGlobalActions } from '../actions/buying-process-global-actions';

const initialState = {
    choosenStepId: 0,
    allowedStepsId: [0, 1, 2],
    allSteps: [
        {
            id: 0,
            label: componentLabel.cart,
        },
        {
            id: 1,
            label: componentLabel.clientDataDeliveryAndPayment,
        },
        {
            id: 2,
            label: componentLabel.summary,
        },
    ],
};

export default function buyingProcessGlobalReducer(state = initialState, action) {
    switch (action.type) {
        case buyingProcessGlobalActions.setStep:
            return {
                ...state,
                choosenStepId: action.payload,
            };
        default:
            return state;
    }
}
