import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { TStatus } from '../models/status.model';
import { IThreadCategory } from '../models/thread.model';
import { selectForumThreadCategories } from '../redux/forum/forum.selectors';
import {
    FORUM_THREAD_CATEGORIES,
    getForumThreadCategories,
    getForumThreadCategoriesFavorite,
} from '../redux/forum/forum.actions';
import { selectLoaderByEntity } from '../redux/ui/ui.selectors';
import { getAbsolutePath } from '../routes';

export const useForumThreadCategories = (isFavorite?: boolean) => {
    const dispatch = useDispatch();
    const categories: IThreadCategory[] = useSelector(selectForumThreadCategories);
    const noCategoryRouteKey = isFavorite
        ? 'APP_MY_ACCOUNT_FORUM_FAVORITE'
        : 'APP_MY_ACCOUNT_FORUM_HOME';
    const status: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, FORUM_THREAD_CATEGORIES);
    });
    const firstCategoryId = categories[0]?.categoryId;
    const pathname = window.location.pathname;

    useEffect(() => {
        if (!firstCategoryId) return;
        const noCategoryPathname = getAbsolutePath(noCategoryRouteKey);
        if (pathname !== noCategoryPathname) return;
        navigate(`${noCategoryPathname}/${firstCategoryId}`, { replace: true });
    }, [firstCategoryId, noCategoryRouteKey, pathname]);

    useEffect(() => {
        if (status && status !== 'idle') return;
        if (isFavorite) {
            dispatch(getForumThreadCategoriesFavorite());
        } else {
            dispatch(getForumThreadCategories());
        }
    }, [dispatch, isFavorite, status]);

    return {
        list: categories,
        status: status || 'idle',
    };
};
