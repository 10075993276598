import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { modal, container, loader, notes } from './recipe-modal.module.scss';
import { selectPositionById } from '../../../redux/meal-planer/meal-planer.selectors';
import { posKey } from '../../../utills/meal-planer';

import CustomModal from '../../../templates/custom-modal';
import Loader from '../../atoms/loader';
import RecipeNotes from '../../molecules/recipe/recipe-notes';
import RecipeContent from '../recipe-content';

const RecipeModal = ({
    modalId,
    id,
    onGetRecipe,
    onNoteSubmit,
    onNoteDelete,
    onPortionsUpdate,
    isTemplate,
    unitId,
}) => {
    const position = useSelector((state) => {
        if (isTemplate) {
            return state.mealPlanerTemplate.current.positions[posKey(id)];
        } else {
            return selectPositionById(state, id);
        }
    });

    const portionsUpdate = (value) => {
        onPortionsUpdate({
            ...position,
            portions: value,
        });
    };

    useEffect(() => {
        if (!position || !position.recipe) onGetRecipe();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, position]);

    return (
        <CustomModal modalId={modalId} className={modal} imageOnTop={true}>
            {position && position.recipe ? (
                <div className={container}>
                    <RecipeContent
                        recipe={position.recipe}
                        portions={position.portions}
                        simpleView={true}
                        portionsUpdate={portionsUpdate}
                    />

                    {!isTemplate && (
                        <RecipeNotes
                            className={notes}
                            id={id}
                            unitId={unitId}
                            notes={position.notes}
                            onNoteSubmit={onNoteSubmit}
                            onNoteDelete={onNoteDelete}
                        />
                    )}
                </div>
            ) : (
                <Loader className={loader} />
            )}
        </CustomModal>
    );
};

RecipeModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    onGetRecipe: PropTypes.func.isRequired,
    isTemplate: PropTypes.bool,
    onPortionsUpdate: PropTypes.func,
    onNoteSubmit: PropTypes.func,
    onNoteDelete: PropTypes.func,
};

RecipeModal.defaultProps = {
    modalId: null,
    id: null,
    notes: [],
    isTemplate: false,
    onNoteSubmit: () => {},
    onNoteDelete: () => {},
    onGetRecipe: () => {},
    onPortionsUpdate: () => {},
};

export default RecipeModal;
