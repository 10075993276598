import React from 'react';
import { useDispatch } from 'react-redux';

import { button, container, trash } from './delete-button.module.scss';
import TrashIcon from '../../../assets/images/svg/icon-trash.svg';
import { addModalAction } from '../../../redux/actions/actions-modals';
import { deleteSingleRecipeAction } from '../../../redux/recipes/single/actions/delete-single-recipe';

import Button, { TButtonColor } from '../button';

interface IRecipeDeleteButtonProps {
    className?: string;
    recipeId: number;
    textOnly?: boolean;
    color?: TButtonColor;
    onDeleteClick?(): void;
}

const DeleteButton: React.FC<IRecipeDeleteButtonProps> = ({
    className = '',
    recipeId,
    textOnly = false,
    color = 'blank',
    onDeleteClick,
}) => {
    const dispatch = useDispatch();

    const handleTrashClick = () => {
        if (typeof onDeleteClick === 'function') {
            onDeleteClick();
        }
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: 'Usunąć przepis?',
                    content: 'Przepis zostanie nieodwracalnie usunięty z bazy.',
                    onConfirm: () => {
                        dispatch(deleteSingleRecipeAction(recipeId));
                    },
                },
            })
        );
    };

    return textOnly ? (
        <button onClick={handleTrashClick} className={className}>
            Usuń
        </button>
    ) : (
        <div className={`${container} ${className}`}>
            <Button
                className={button}
                color={color}
                circle={true}
                onClick={handleTrashClick}
                aria-label="Usuń przepis"
            >
                <TrashIcon className={trash} />
            </Button>
        </div>
    );
};

export default DeleteButton;
