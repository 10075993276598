import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IResponsePagination } from '../models/pagination';
import { TStatus } from '../models/status.model';
import { selectLoaderByEntity } from '../redux/ui/ui.selectors';
import { selectCourses } from '../redux/courses/courses.selectors';
import { clearCourses, COURSES, getCourses } from '../redux/courses/courses.actions';

interface ICoursesState {
    items: Record<string, any>[];
    pagination: IResponsePagination;
}

export type TCourseType = 'course' | 'trainingProgram' | 'challenge';

export const useCourses = (courseType: TCourseType, isMine: boolean) => {
    const dispatch = useDispatch();
    const courses: ICoursesState = useSelector(selectCourses);
    const status: TStatus | undefined = useSelector((state) => {
        return selectLoaderByEntity(state, COURSES);
    });

    const getNextPage = () => {
        if (courses.pagination.currentPage > courses.pagination.pageCount) return;
        if (courses.items.length && courses.pagination.currentPage === courses.pagination.pageCount)
            return;
        dispatch(
            getCourses({
                page: courses.pagination.currentPage + 1,
                perPage: courses.pagination.perPage,
                courseType,
                isMine,
            })
        );
    };

    useEffect(() => {
        return () => {
            dispatch(clearCourses());
        };
    }, [dispatch]);

    return {
        ...courses,
        status: status || 'idle',
        getNextPage,
    };
};
