import { ShopStepsEnum } from '../../../../models/shop-steps.enum';
import { orderValidate } from '../../../shop/order/order.actions';

const moduleName = '[BUYING PROCESS GLOBAL]';

export const buyingProcessGlobalActions = {
    setStep: `${moduleName} setStep`,
    validateStep: `${moduleName} validate_step`,
};

export const setBuyingProcessGlobalStep = (stepId, validate = true) => ({
    type: buyingProcessGlobalActions.setStep,
    payload: stepId,
    meta: {
        validate,
    },
});

export const validateBuyingProcessGlobalSteps = () => ({
    type: buyingProcessGlobalActions.validateStep,
});

export const validateOrderProcess = (payload, meta) => {
    const showXselling = payload === ShopStepsEnum.Summary;
    return orderValidate({ showXselling }, meta);
};
