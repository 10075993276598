import React, { Fragment } from 'react';

import { unitBox } from './meal-planer-unit.module.scss';
import { config } from '../../../config';

import MealCard from '../../molecules/meal-planner/meal-card';
import MealPlanerDropZone from '../../molecules/meal-planner/meal-planer-dropzone';

const typesMap = config.mealPlanner.mealTypesMap;

const MealPlannerUnit = ({ unit, isTemplate, unitId }) => {
    const unitStyle = {
        '--col': unit.col,
        '--row': unit.row,
    };

    const typeKey = (typesMap, id) => {
        const type = Object.values(typesMap).find((item) => item.mealTypeId === id);
        return type ? type.key : '';
    };

    const cssClass = `
    ${unitBox}
    ${typeKey(typesMap, unit.mealTypeId)}
    meal-planer__grid-position
  `;

    return (
        <div className={cssClass} style={unitStyle}>
            <MealPlanerDropZone
                date={unit.date}
                mealTypeId={unit.mealTypeId}
                isTemplate={isTemplate}
            />

            {unit.positions.length > 0 &&
                unit.positions.map((position, index) => {
                    return (
                        <Fragment key={`unit-pos-${position.id}`}>
                            <MealCard position={position} isTemplate={isTemplate} unitId={unitId} />
                            <MealPlanerDropZone
                                short={unit.longest}
                                date={unit.date}
                                sequence={index + 1}
                                mealTypeId={unit.mealTypeId}
                                isTemplate={isTemplate}
                            />
                        </Fragment>
                    );
                })}
        </div>
    );
};

export default MealPlannerUnit;
