import { editSingleRecipe } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';
import { config } from '../../../../config';
import { formatFormikErrors } from '../../../../communication/utils';
import { getSingleRecipeSuccessAction } from './get-single-recipe';
import { clearYourRecipesAction } from '../../your/actions/clear-your-recipes';

export const editSingleRecipeAction = (id, data, formikBag) => async (dispatch) => {
    formikBag.setStatus(config.formsStatusMap.loading);
    dispatch(editSingleRecipeStartAction());
    try {
        const res = await editSingleRecipe(id, data);
        formikBag.setStatus(config.formsStatusMap.success);
        dispatch(editSingleRecipeSuccessAction());
        dispatch(getSingleRecipeSuccessAction(res.data));
        dispatch(clearYourRecipesAction());
    } catch (error) {
        formikBag.setStatus(config.formsStatusMap.fail);
        formikBag.setErrors(formatFormikErrors(error));
        dispatch(editSingleRecipeErrorAction(error));
        return config.formsStatusMap.fail;
    }
};

export const editSingleRecipeClearSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.edit,
        entitiesMap.recipes.single.allowedActions.clearSuccess
    ),
});

export const editSingleRecipeClearErrorsAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.edit,
        entitiesMap.recipes.single.allowedActions.clearErrors
    ),
});

const editSingleRecipeStartAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.edit,
        entitiesMap.recipes.single.allowedActions.start
    ),
});

const editSingleRecipeSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.edit,
        entitiesMap.recipes.single.allowedActions.success
    ),
    payload: {},
});

const editSingleRecipeErrorAction = (error) => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.edit,
        entitiesMap.recipes.single.allowedActions.error
    ),
    payload: error,
});
