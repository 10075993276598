import { deleteAlert, types } from './alerts.actions';
import { config } from '../../config';

const { SET_ALERT } = types;
const { alerts } = config;

const alertsMiddleware = ({ dispatch }) => (next) => (action) => {
    const { type, payload } = action;
    next(action);

    switch (true) {
        case type.includes(SET_ALERT):
            const alertType = payload.data.type;
            if (alertType !== alerts.types.error) {
                setTimeout(() => dispatch(deleteAlert(payload.meta.id)), 3000);
            }
            break;
        // no default
    }
};

export default alertsMiddleware;
