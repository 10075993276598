import React from 'react';

import {
    avatarBox,
    small,
    medium,
    large,
    big,
    huge,
    avatarInnerBox,
    image,
    border,
    dark,
    shadow,
} from './avatar.module.scss';
import AccountIcon from '../../assets/images/svg/icon-account.svg';

type TAvatarSize = 'small' | 'medium' | 'large' | 'big' | 'huge';

interface IAvatarProps {
    className?: string;
    size?: TAvatarSize;
    avatarUri?: string | null;
    alt?: string;
    isDark?: boolean;
    hasShadow?: boolean;
    hasBorder?: boolean;
}

const Avatar: React.FC<IAvatarProps> = ({
    className = '',
    size = 'small',
    avatarUri,
    alt,
    isDark = false,
    hasShadow = true,
    hasBorder = false,
}) => {
    return (
        <div
            className={`
                ${avatarBox} 
                ${sizeClasses[size]} 
                ${isDark ? dark : ''} 
                ${hasShadow ? shadow : ''} 
                ${hasBorder ? border : ''} 
                ${className}
            `}
        >
            <div className={avatarInnerBox}>
                {avatarUri ? (
                    <img className={image} src={avatarUri} alt={alt} />
                ) : (
                    <div className={image}>
                        <AccountIcon />
                    </div>
                )}
            </div>
        </div>
    );
};

const sizeClasses: Record<TAvatarSize, string> = {
    small: small,
    medium: medium,
    large: large,
    big: big,
    huge: huge,
};

export default Avatar;
