import React from 'react';

import { container } from './animated-keton.module.scss';
import Keton from '../../assets/images/svg/keton.svg';

export interface IAnimatedKetonProps {
    className?: string;
}

export default function AnimatedKeton({ className = '' }: IAnimatedKetonProps) {
    return (
        <div className={`${container} ${className}`}>
            <Keton />
        </div>
    );
}
