import axiosInstance from './axiosInstance';

import { config } from '../config';

export const getPlannerTemplatesAuth = () => {
    return axiosInstance.default.client({
        url: config.endpoints.mealPlanner.templateAuth,
        withCredentials: true,
    });
};
