import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../hoc/markdown';

import { button, content, holder, error, success, warning } from './status-bar.module.scss';
import IconClose from './../../assets/images/svg/icon-close.svg';
import { config } from '../../config';

const { alerts } = config;

const StatusBar = ({ type, onSubmit, children }) => {
    const typeClass = typeClasses[type];
    return (
        <div className={`${holder} ${typeClass}`}>
            <div className={content}>
                <div>
                    {typeof children === 'string' && <Markdown>{children}</Markdown>}
                    {typeof children !== 'string' && children}
                </div>
            </div>
            <button className={button} aria-label="Zamknij notyfikację" onClick={onSubmit}>
                <IconClose />
            </button>
        </div>
    );
};

const typeClasses = {
    [alerts.types.success]: success,
    [alerts.types.error]: error,
    [alerts.types.warning]: warning,
};

StatusBar.propTypes = {
    type: PropTypes.oneOf(Object.values(alerts.types)).isRequired,
    children: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default StatusBar;
