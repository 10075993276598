// extracted by mini-css-extract-plugin
export var benefitInfo = "ordrer-summary-module--benefit-info--upUWj";
export var container = "ordrer-summary-module--container--f218n";
export var items = "ordrer-summary-module--items--GI4yR";
export var list = "ordrer-summary-module--list--OeUDz";
export var nameHolder = "ordrer-summary-module--name-holder--RID97";
export var orderHeader = "ordrer-summary-module--order-header--CgtAk";
export var recurringPaymentInfo = "ordrer-summary-module--recurring-payment-info--NFHJg";
export var summary = "ordrer-summary-module--summary--cufsi";
export var summaryItem = "ordrer-summary-module--summary-item--es0tI";
export var summaryPrices = "ordrer-summary-module--summary-prices--F+pZo";