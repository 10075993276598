import moment from 'moment';

import { types } from './meal-planer.actions';
import { config } from '../../config';
import { defaultFormat } from '../../utills/date-utils';

const { SET_PLANER_DATA, SET_POSITIONS, CLEAR_PLANER_DATA } = types;
const { coordinates, mealTypesMap } = config.mealPlanner;

export const initialState = {
    positions: {},
    rows: { ...coordinates.rows, ...mealTypesMap },
    columns: coordinates.cols,
    dateFrom: moment().startOf('week').format(defaultFormat),
    dateTo: moment().endOf('week').format(defaultFormat),
    planerUnits: {},
    dates: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_PLANER_DATA:
            return {
                ...state,
                ...payload,
            };
        case SET_POSITIONS:
            return {
                ...state,
                positions: payload,
            };
        case CLEAR_PLANER_DATA:
            return initialState;
        default:
            return state;
    }
}
