import React, { useState } from 'react';

import { cartIcon, cartWidget } from './cart-widget.module.scss';
import ShoppingCartIconSVG from '../../../assets/images/svg/icon-shopping-cart.svg';
import { config } from '../../../config';

import ShoppingCartDropdown from './cart-dropdown';
import CounterDot from '../../atoms/counter-dot';

const { counters } = config;

const CartWidget = ({ className = '' }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`${cartWidget} ${className} `}>
            <button className={cartIcon} onClick={() => setIsOpen(!isOpen)}>
                <ShoppingCartIconSVG />
            </button>
            <CounterDot type={counters.cart} isAbsolute={true} />
            <ShoppingCartDropdown isOpen={isOpen} closeFunc={() => setIsOpen(false)} />
        </div>
    );
};
export default CartWidget;
