import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';

import {
    modal,
    form,
    loader,
    separator,
    button,
    input,
    checkboxes,
    checkboxesLabel,
    checkboxesRow,
} from './add-plan-template-modal.module.scss';
import { config } from '../../../config';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { DIET_TYPES, fetchDietTypes } from '../../../redux/diet-types/diet-types.actions';
import { getPlannerTemplatesAuth } from '../../../communication/meal-planner';

import CustomModal from '../../../templates/custom-modal';
import Input from '../../atoms/form-poc/input';
import Button from '../../atoms/button';
import Loader from '../../atoms/loader';
import Separator from '../../atoms/separator';
import CheckboxGroup from '../../atoms/form-poc/checkbox-group';

const { required } = config.messages.form;
const { formsStatusMap, modelStatusMap, apiStatusMap } = config;

const initialValues = {
    name: '',
    tags: [],
    status: 0,
    auth: [],
};

const validationSchema = Yup.object({
    name: Yup.string().required(required),
});

const AddPlanTemplateModal = ({ modalId, plan, onSubmit }) => {
    const dispatch = useDispatch();
    const { tags, dietTypesLoading } = useSelector((state) => ({
        tags: state.dietTypes.items,
        dietTypesLoading: selectLoaderByEntity(state, DIET_TYPES),
    }));

    const dietTypes = useStaticQuery(query).allTag.edges.map((edge) => ({
        label: edge.node.name,
        value: edge.node.tagId,
    }));

    const [formInitialValues, setFormInitialValues] = useState(initialValues);
    const [authItems, setAuthItems] = useState([]);

    const handleSubmit = (formValues) => {
        const tags = [];

        for (const tag of formValues.tags) {
            tags.push({ tagId: parseInt(tag) });
        }

        onSubmit({
            name: formValues.name,
            status: parseInt(formValues.status),
            tags: tags,
            auth: formValues.auth,
        });
    };

    useEffect(() => {
        if (!tags.length) {
            dispatch(fetchDietTypes());
        }
    }, [dispatch, tags]);

    useEffect(() => {
        getPlannerTemplatesAuth()
            .then((resp) => {
                const authItems = [];
                for (const item of resp.data) {
                    authItems.push({ value: item, label: item });
                }
                setAuthItems(authItems);
            })
            .catch((er) => {
                // eslint-disable-next-line no-console
                console.error(er);
            });
    }, []);

    useEffect(() => {
        if (plan) {
            setFormInitialValues(plan);
        }
    }, [plan]);

    return (
        <CustomModal title={'Edytuj plan'} modalId={modalId} className={modal}>
            <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formik) => (
                    <Form className={form}>
                        {formsStatusMap.loading === formik.status && (
                            <Loader fullContainer={true} className={loader} />
                        )}
                        <Input
                            name="name"
                            label="Nazwa planu"
                            placeholder="np. Plan przy treningu siłowym"
                            containerClass={input}
                        />
                        <Input
                            name="status"
                            label="Status (czy Menu jest widoczne dla użytkowników)"
                            containerClass={input}
                            as={'select'}
                        >
                            {Object.entries(modelStatusMap).map(([key, item]) => {
                                return (
                                    <option value={item.status} key={`status-${key}`}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </Input>

                        <div className={checkboxesRow}>
                            <div className={checkboxesLabel}>Uprawnienia</div>

                            <Separator className={separator} />

                            <CheckboxGroup
                                name="auth"
                                options={authItems}
                                containerClass={checkboxes}
                            />
                        </div>

                        <div className={checkboxesRow}>
                            <div className={checkboxesLabel}>Typ diety</div>

                            <Separator className={separator} />

                            {dietTypesLoading === apiStatusMap.loading && <Loader />}

                            {dietTypesLoading === apiStatusMap.success &&
                                (dietTypes.length > 0 ? (
                                    <CheckboxGroup
                                        name="tags"
                                        options={dietTypes}
                                        containerClass={checkboxes}
                                    />
                                ) : (
                                    <div>Brak typów do przyporządkowania</div>
                                ))}
                        </div>

                        <Button className={button} size="small" color="yellow">
                            Zapisz
                        </Button>
                    </Form>
                )}
            </Formik>
        </CustomModal>
    );
};

AddPlanTemplateModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    plan: PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.number,
        dietTypes: PropTypes.arrayOf(PropTypes.string),
    }),
    onSubmit: PropTypes.func,
};

AddPlanTemplateModal.defaultProps = {
    plan: null,
    onSubmit: () => {},
};

export default AddPlanTemplateModal;

export const query = graphql`
    query {
        allTag(filter: { type: { eq: 3 } }) {
            edges {
                node {
                    tagId
                    name
                }
            }
        }
    }
`;
