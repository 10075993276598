import React from 'react';
import PropTypes from 'prop-types';

import { container, showButton, cancelButton } from './show-hide-bar.module.scss';

const ShowHideBar = ({ className, onShow, onHide, isShowing, showText, cancelText }) => {
    return (
        <div className={`${container} ${className}`}>
            <button type="button" className={showButton} onClick={onShow}>
                {showText}
            </button>
            {isShowing && (
                <button className={cancelButton} type="button" onClick={onHide}>
                    {cancelText}
                </button>
            )}
        </div>
    );
};

ShowHideBar.propTypes = {
    className: PropTypes.string,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    isShowing: PropTypes.bool,
    showText: PropTypes.string,
    cancelText: PropTypes.string,
};

ShowHideBar.defaultProps = {
    className: '',
    onShow: () => {},
    onHide: () => {},
    isShowing: false,
    showText: 'Pokaż',
    cancelText: 'Anuluj',
};

export default ShowHideBar;
