import React, { createContext, useEffect, useState } from 'react';

import { tab, active } from './tab-switcher.module.scss';

const { Provider, Consumer } = createContext({});

const TabPanel = ({ whenActive, children }) => (
    <Consumer>{({ activeTab }) => (activeTab === whenActive ? children : null)}</Consumer>
);

const Tab = ({ id, children }) => {
    const isTabActive = (activeTab) => id === activeTab;
    return (
        <Consumer>
            {({ activeTab, disabledIds, setActiveTab }) => (
                <button
                    className={`${tab} ${isTabActive(activeTab) ? active : ''}`}
                    type="button"
                    onClick={() => setActiveTab(id)}
                    disabled={disabledIds.includes(id)}
                >
                    {children}
                </button>
            )}
        </Consumer>
    );
};

const TabSwitcher = ({ children, onChange, openedId = 1, disabledIds = [], className = '' }) => {
    const [activeTab, setActiveTab] = useState(openedId);

    useEffect(() => {
        setActiveTab(openedId);
    }, [openedId]);

    const handleChange = (active) => {
        onChange(active);
        setActiveTab(active);
    };

    return (
        <Provider
            value={{
                activeTab,
                disabledIds,
                setActiveTab: handleChange,
            }}
        >
            <div className={className}>{children}</div>
        </Provider>
    );
};

export default TabSwitcher;
export { Tab, TabPanel };
