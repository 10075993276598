import { getMessagesData } from './api';
import { getCurrentProfileId } from '../../profile/profile.selectors';
import { setSingleProfile } from '../../profile/profile.actions';

const moduleName = '[MESSENGER messages]';

export const fetchActions = {
    START_FETCHING: `${moduleName} start fetching`,
    LOADING_SUCCESS: `${moduleName} loading success`,
    SET_ERRORS: `${moduleName} set errors`,

    SET_SEARCH_PHRASE: `${moduleName} set search phrase`,
    SET_FIRST_MESSAGE_AVAILABLE: `${moduleName} set first message available`,

    SET_PAGINATION: `${moduleName} set Pagination`,
    COUNT_UP_PAGE: `${moduleName} count up page`,
    SET_LAST_LOADING_TOTAL_COUNT: `${moduleName} set last loading total count`,
};

const startLoading = () => ({
    type: fetchActions.START_FETCHING,
});

const loadingSuccess = (payload) => ({
    type: fetchActions.LOADING_SUCCESS,
    payload,
});

const setErrors = (payload) => ({
    type: fetchActions.SET_ERRORS,
    payload,
});

const countUpPage = () => ({
    type: fetchActions.COUNT_UP_PAGE,
});

export const setFirstMessageAvailable = () => ({
    type: fetchActions.SET_FIRST_MESSAGE_AVAILABLE,
});

export const setSearchPhrase = (phrase) => ({
    type: fetchActions.SET_SEARCH_PHRASE,
    payload: phrase,
});

export const setMessengerPagination = (payload) => ({
    type: fetchActions.SET_PAGINATION,
    payload,
});

export const setLastLoadingTotalCount = (payload) => ({
    type: fetchActions.SET_LAST_LOADING_TOTAL_COUNT,
    payload,
});

export const fetchMessages = (
    wantToPageUp = false,
    fromInterval = false
) => async (dispatch, getState) => {
    const metaData = getState().messenger.messages.meta;
    const profileId = getCurrentProfileId(getState());
    const { phraseString, pagination } = metaData;
    const { pageToFetch, perPage, totalCount, lastLoadingCount } = pagination;
    if (!fromInterval) {
        dispatch(startLoading());
    }
    try {
        const countToDownload = perPage * pageToFetch;
        const response = await getMessagesData({
            phraseString,
            countToDownload,
            profileId,
        });
        if (response.status === 200) {
            if (wantToPageUp && lastLoadingCount < totalCount) {
                dispatch(countUpPage());
            }

            dispatch(
                setSingleProfile({ id: profileId, unreadMessagesCount: 0 })
            );

            dispatch(loadingSuccess(response.data.items));
            dispatch(setMessengerPagination(response.data.pagination));
            dispatch(setLastLoadingTotalCount(countToDownload));
        }
    } catch (error) {
        if (!fromInterval) {
            dispatch(setErrors(error));
        }
    }
};
