import { config } from '../../../config';
import { errorHandler } from '../../../communication/utils';
import { ORDER_HISTORY, setOrderHistory, types } from './order-history.actions';
import { clearAllAlerts, setAlert } from '../../alerts/alerts.actions';

const {
    GET_ORDERS_LIST,
    GET_SINGLE_ORDER,
    SEND_ORDER_HISTORY_REQUEST,
    SEND_ORDER_HISTORY_SUCCESS,
    SEND_ORDER_HISTORY_FAIL,
} = types;

const { endpoints } = config;

const orderHistoryMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload } = action;
    next(action);

    let ordersHistoryObj, ordersCollection, fetchData;

    switch (type) {
        case GET_ORDERS_LIST:
            dispatch(clearAllAlerts());

            fetchData = {
                types: [
                    SEND_ORDER_HISTORY_REQUEST,
                    SEND_ORDER_HISTORY_SUCCESS,
                    SEND_ORDER_HISTORY_FAIL,
                ],
                payload: {
                    request: {
                        url: endpoints.shop.order,
                        params: {
                            expand: `items,items.product,items.variant,items.product.media,payments,discounts,items.discounts`,
                            sort: '-createdAt',
                            per_page: 50,
                        },
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: ORDER_HISTORY,
                },
            };
            dispatch(fetchData);
            break;

        case GET_SINGLE_ORDER:
            dispatch(clearAllAlerts());

            fetchData = {
                types: [
                    SEND_ORDER_HISTORY_REQUEST,
                    SEND_ORDER_HISTORY_SUCCESS,
                    SEND_ORDER_HISTORY_FAIL,
                ],
                payload: {
                    request: {
                        url: `${endpoints.shop.order}/${payload}`,
                        params: {
                            expand: `items,items.product,items.variant,items.product.media,payments,discounts,items.discounts`,
                        },
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: ORDER_HISTORY,
                    id: payload,
                },
            };
            dispatch(fetchData);
            break;

        case SEND_ORDER_HISTORY_SUCCESS:
            ordersHistoryObj = {};

            if (action.meta?.previousAction?.meta?.id) {
                ordersCollection = [{ ...payload.data }];
            } else {
                ordersCollection = Array.from(payload.data.items);
            }

            for (const order of ordersCollection) {
                ordersHistoryObj[`order-${order.id}`] = order;
            }

            dispatch(setOrderHistory(ordersHistoryObj));
            break;

        case SEND_ORDER_HISTORY_FAIL:
            const error = errorHandler(action.error);
            error.map((err) => dispatch(setAlert(err, ORDER_HISTORY)));
            break;
        // no default
    }
};

export default orderHistoryMiddleware;
