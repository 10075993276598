import {
    types,
    SUBSCRIPTION,
    SUBSCRIPTION_UPGRADE,
    SUBSCRIPTION_SPECIAL_OFFER,
    SUBSCRIPTION_DEMAND_OFFER,
    setSubscriptionUpgradeVariantsAction,
    restoreInitialSubscriptionStateAction,
    setSubscriptionSpecialOfferAction,
} from './subscription.actions';
import { config } from '../../config';
import { statusFail, statusIdle, statusLoading } from '../ui/ui.actions';
import { setAlert } from '../alerts/alerts.actions';
import { errorHandler } from '../../communication/utils';
import { reloadSubscriptionsAction } from '../subscriptions/subscriptions.actions';
import { addModalAction, removeModalAction } from '../actions/actions-modals';
import { fetchUserData } from '../user/user.actions';

const {
    GET_SUBSCRIPTION_UPGRADE_VARIANTS,
    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_REQUEST,
    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_SUCCESS,
    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_FAIL,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_FAIL,
    CANCEL_SUBSCRIPTION_SUCCESS,
    UPGRADE_SUBSCRIPTION,
    UPGRADE_SUBSCRIPTION_REQUEST,
    UPGRADE_SUBSCRIPTION_SUCCESS,
    UPGRADE_SUBSCRIPTION_FAIL,
    GET_SUBSCRIPTION_SPECIAL_OFFER,
    SUBSCRIPTION_SPECIAL_OFFER_REQUEST,
    SUBSCRIPTION_SPECIAL_OFFER_SUCCESS,
    SUBSCRIPTION_SPECIAL_OFFER_FAIL,
    DEMAND_SUBSCRIPTION_SPECIAL_OFFER,
    SUBSCRIPTION_DEMAND_OFFER_REQUEST,
    SUBSCRIPTION_DEMAND_OFFER_SUCCESS,
    SUBSCRIPTION_DEMAND_OFFER_FAIL,
    CLEAR_SUBSCRIPTION,
} = types;

const PAYU_TOKEN_NEEDED_ERROR_STATUS = 424;

const subscriptionMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, meta } = action;
    const modalId = meta?.modalId || meta?.previousAction?.meta?.modalId;
    next(action);

    switch (type) {
        case GET_SUBSCRIPTION_UPGRADE_VARIANTS:
            dispatch({
                types: [
                    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_REQUEST,
                    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_SUCCESS,
                    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_FAIL,
                ],
                payload: {
                    request: {
                        method: 'GET',
                        url: config.endpoints.subscriptions.upgrade(payload),
                        withCredentials: true,
                    },
                },
                meta: { entity: SUBSCRIPTION_UPGRADE },
            });
            break;

        case FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_SUCCESS:
            dispatch(setSubscriptionUpgradeVariantsAction(payload.data));
            break;

        case FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_FAIL:
            const getVariantsErrors = errorHandler(action.error);
            getVariantsErrors.map((err) => dispatch(setAlert(err, SUBSCRIPTION_UPGRADE)));
            break;

        case UPGRADE_SUBSCRIPTION:
            dispatch(statusLoading(SUBSCRIPTION_UPGRADE));
            dispatch({
                types: [
                    UPGRADE_SUBSCRIPTION_REQUEST,
                    UPGRADE_SUBSCRIPTION_SUCCESS,
                    UPGRADE_SUBSCRIPTION_FAIL,
                ],
                payload: {
                    request: {
                        method: 'POST',
                        url: config.endpoints.subscriptions.upgrade(payload.subscriptionId),
                        withCredentials: true,
                        data: {
                            variant: payload.variantId,
                            ...(payload.token ? { token: payload.token } : {}),
                        },
                    },
                },
                meta: {
                    ...meta,
                    variantId: payload.variantId,
                    subscriptionId: payload.subscriptionId,
                },
            });
            break;

        case UPGRADE_SUBSCRIPTION_SUCCESS:
            dispatch(removeModalAction(modalId));
            dispatch(fetchUserData());
            dispatch(reloadSubscriptionsAction());
            dispatch(
                addModalAction({
                    type: 'info',
                    title: 'Udało się!',
                    content: 'Już od teraz możesz się cieszyć subskrypcją PREMIUM!',
                })
            );
            break;

        case UPGRADE_SUBSCRIPTION_FAIL:
            const errorStatus = action.error.response.status;
            if (errorStatus === PAYU_TOKEN_NEEDED_ERROR_STATUS) {
                dispatch(removeModalAction(modalId));
                const variantId = meta?.previousAction?.meta?.variantId;
                const subscriptionId = meta?.previousAction?.meta?.subscriptionId;
                if (variantId && subscriptionId) {
                    dispatch(
                        addModalAction({
                            modalKey: 'PAYU_CARD_FORM_MODAL',
                            modalProps: {
                                variantId,
                                subscriptionId,
                            },
                        })
                    );
                }
            } else {
                const upgradeErrors = errorHandler(action.error);
                dispatch(statusFail(SUBSCRIPTION_UPGRADE));
                upgradeErrors.map((err) => dispatch(setAlert(err, SUBSCRIPTION_UPGRADE)));
            }
            break;

        case CANCEL_SUBSCRIPTION:
            dispatch({
                types: [
                    CANCEL_SUBSCRIPTION_REQUEST,
                    CANCEL_SUBSCRIPTION_SUCCESS,
                    CANCEL_SUBSCRIPTION_FAIL,
                ],
                payload: {
                    request: {
                        method: 'POST',
                        url: config.endpoints.subscriptions.resign(payload),
                        withCredentials: true,
                    },
                },
                meta: { entity: SUBSCRIPTION },
            });
            break;

        case CANCEL_SUBSCRIPTION_SUCCESS:
            dispatch(reloadSubscriptionsAction());
            break;

        case CANCEL_SUBSCRIPTION_FAIL:
            const cancelErrors = errorHandler(action.error);
            cancelErrors.map((err) => dispatch(setAlert(err, SUBSCRIPTION)));
            break;

        case GET_SUBSCRIPTION_SPECIAL_OFFER:
            dispatch({
                types: [
                    SUBSCRIPTION_SPECIAL_OFFER_REQUEST,
                    SUBSCRIPTION_SPECIAL_OFFER_SUCCESS,
                    SUBSCRIPTION_SPECIAL_OFFER_FAIL,
                ],
                payload: {
                    request: {
                        method: 'GET',
                        url: config.endpoints.subscriptions.specialOffer,
                        withCredentials: true,
                    },
                },
                meta: { entity: SUBSCRIPTION_SPECIAL_OFFER, subscriptionId: payload },
            });
            break;

        case SUBSCRIPTION_SPECIAL_OFFER_SUCCESS:
            const subscriptionId = meta.previousAction.meta.subscriptionId;
            const offerPayload = {
                [subscriptionId]: payload.data.items[0],
            };
            dispatch(setSubscriptionSpecialOfferAction(offerPayload));
            break;

        case DEMAND_SUBSCRIPTION_SPECIAL_OFFER:
            dispatch({
                types: [
                    SUBSCRIPTION_DEMAND_OFFER_REQUEST,
                    SUBSCRIPTION_DEMAND_OFFER_SUCCESS,
                    SUBSCRIPTION_DEMAND_OFFER_FAIL,
                ],
                payload: {
                    request: {
                        method: 'POST',
                        url: config.endpoints.subscriptions.demandSpecialOffer,
                        withCredentials: true,
                    },
                },
                meta: { entity: SUBSCRIPTION_DEMAND_OFFER },
            });
            break;

        case SUBSCRIPTION_DEMAND_OFFER_SUCCESS:
            dispatch(
                addModalAction({
                    title: 'Prośba o ofertę specjalną wysłana\xa0💪!',
                    content: `Twoja prośba o ofertę specjalną została wysłana, czekaj na maila bądź powiadomienie o jej aktywacji.`,
                })
            );
            break;

        case SUBSCRIPTION_DEMAND_OFFER_FAIL:
            dispatch(
                addModalAction({
                    type: 'error',
                    title: 'Coś poszło nie tak...',
                    content: 'Nie udało się wysłać Twojej prośby, spróbuj ponownie później...',
                })
            );
            break;

        case CLEAR_SUBSCRIPTION:
            dispatch(statusIdle(SUBSCRIPTION));
            dispatch(statusIdle(SUBSCRIPTION_UPGRADE));
            dispatch(restoreInitialSubscriptionStateAction());
            break;

        default:
            break;
    }
};

export default subscriptionMiddleware;
