import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '../config';
import { selectSSE } from '../redux/sse-subscription/sse-subscription.selectors';
import { selectRbac } from '../redux/rbac/rbac.selectors';
import { getCurrentProfileId } from '../redux/profile/profile.selectors';
import { ISseInitialState } from '../redux/sse-subscription/sse-subscription.types';
import isSseEnabled from '../utills/is-sse-enabled';

const { baseApiUrl } = config.endpoints;

const useSSE = () => {
    const sse: ISseInitialState = useSelector(selectSSE);
    const profileId: number = useSelector(getCurrentProfileId);
    const rbac: Array<string> = useSelector(selectRbac);
    const dispatch = useDispatch();
    const evtSource = useRef<EventSource | null>(null);

    const handleNotification = useCallback(
        (event) => {
            const reduxAction = JSON.parse(event.data);

            if ('type' in reduxAction && typeof reduxAction.type === 'string') {
                dispatch(reduxAction);
            } else {
                //eslint-disable-next-line no-console
                console.warn('Received message which is not valid redux action', reduxAction);
            }
        },
        [dispatch]
    );

    const cleanEventSource = useCallback(() => {
        if (evtSource.current) {
            evtSource.current.removeEventListener('notification', handleNotification);
            evtSource.current.close();
            evtSource.current = null;
        }
    }, [handleNotification]);

    const createEventSource = useCallback(
        (/*numberOfRetries = 5, delayTime = 5 seconds */) => {
            evtSource.current = new EventSource(`${baseApiUrl}/sse/sse/${profileId}`, {
                withCredentials: true,
            });

            evtSource.current.addEventListener('notification', handleNotification);

            evtSource.current.onerror = function (err) {
                //eslint-disable-next-line no-console
                console.error(`Connecting to ${evtSource.current?.url || ''} failed!`, err);

                //TODO: uncomment reconnecting when backend allow us to send csrf tokens
                // console.error(`Connecting to ${evtSource.current.url} failed! Trying to reconnect after ${delayTime} seconds...`, err);
                //
                // if (numberOfRetries > 1) {
                //     setTimeout(() => {
                //         cleanEventSource();
                //         createEventSource(numberOfRetries - 1, delayTime + 5);
                //     }, delayTime * 1000);
                // }
            };

            // Uncomment this if it is needed
            // evtSource.current.onopen = function () {
            //     console.info('connection Open');
            // };
        },
        [profileId, handleNotification]
    );

    useEffect(() => {
        if (isSseEnabled()) {
            if (rbac.includes('User') && profileId) {
                createEventSource();
            } else {
                cleanEventSource();
            }
        }

        return () => {
            cleanEventSource();
        };
    }, [handleNotification, rbac, cleanEventSource, createEventSource, profileId]);

    return {
        sse,
    };
};

export default useSSE;
