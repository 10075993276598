import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { saveToPdfSingleRecipeAction } from '../../../redux/recipes/single/actions/save-to-pdf-recipe';
import { forbiddenClick } from '../../../redux/rbac/rbac.actions';
import { entitiesArray } from '../../../rbac/permissions';

import Button from '../button';

const PDFButton = ({
    className = '',
    id,
    ingredients,
    forbidden,
    permissionEntity,
    onSavePdfClick,
    textOnly,
}) => {
    const dispatch = useDispatch();

    const Tag = textOnly ? 'button' : Button;
    const tagProps = textOnly ? {} : { color: forbidden ? 'grey' : 'blank' };

    const handlePdfSave = () => {
        if (forbidden) {
            dispatch(forbiddenClick(permissionEntity));
            return;
        }
        dispatch(saveToPdfSingleRecipeAction(id, ingredients));
        onSavePdfClick();
    };

    return (
        <Tag {...tagProps} className={className} onClick={handlePdfSave}>
            Zapisz do pdf
        </Tag>
    );
};

PDFButton.propTypes = {
    id: PropTypes.number.isRequired,
    className: PropTypes.string,
    ingredients: PropTypes.arrayOf(PropTypes.object),
    forbidden: PropTypes.bool,
    permissionEntity: PropTypes.oneOf([...entitiesArray(), PropTypes.instanceOf(null), '']),
    onSavePdfClick: PropTypes.func,
    textOnly: PropTypes.bool,
};

PDFButton.defaultProps = {
    className: '',
    ingredients: [],
    forbidden: false,
    permissionEntity: null,
    onSavePdfClick: () => {},
    textOnly: false,
};

export default PDFButton;
