import types, { addProducts, PRODUCTS, requestProducts, setProducts } from './products.actions';
import { config } from '../../../config';
import { arrayToObject } from '../../../utills/data-convertion';

const productsMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, error } = action;

    next(action);

    switch (type) {
        case types.REQUEST_PRODUCTS:
            const filterString = payload.productIds.reduce(
                (text, currentId) => `${text}&filter[product_id][]=${currentId}`,
                ''
            );

            dispatch({
                types: [
                    types.FETCH_PRODUCTS,
                    types.FETCH_PRODUCTS_SUCCESS,
                    types.FETCH_PRODUCTS_FAIL,
                ],
                payload: {
                    request: {
                        url: `${config.endpoints.shop.productsAll()}${filterString}`,
                        withCredentials: true,
                        params: {
                            page: payload?.page,
                            'per-page': 50,
                        },
                    },
                },
                meta: {
                    entity: PRODUCTS,
                },
            });
            break;
        case types.FETCH_PRODUCTS_SUCCESS:
            const items = payload.data.items;
            const pagination = payload.data.pagination;

            if (pagination.currentPage > 1) {
                dispatch(addProducts(items));
            } else {
                dispatch(setProducts(items));
            }

            if (pagination.currentPage < pagination.pageCount) {
                dispatch(requestProducts({ page: pagination.currentPage + 1 }));
            }
            break;
        case types.FETCH_PRODUCTS_FAIL:
            console.error(error?.message);
            break;
        //no default
    }
};

export default productsMiddleware;
