import { types } from './subscription.actions';

const {
    SET_SUBSCRIPTION_UPGRADE_VARIANTS,
    SET_SUBSCRIPTION_SPECIAL_OFFER,
    RESTORE_INITIAL_SUBSCRIPTION_STATE,
} = types;

const initialState = {
    variants: [],
    specialOffers: {},
};

const subscriptionReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_SUBSCRIPTION_UPGRADE_VARIANTS:
            return {
                ...state,
                variants: payload,
            };
        case SET_SUBSCRIPTION_SPECIAL_OFFER:
            return {
                ...state,
                specialOffers: {
                    ...state.specialOffers,
                    ...payload,
                },
            };
        case RESTORE_INITIAL_SUBSCRIPTION_STATE:
            return initialState;
        default:
            return state;
    }
};

export default subscriptionReducer;
