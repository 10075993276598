import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { wrapper, emptyWrapper, classNameInput, messenger } from './messages.module.scss';
import {
    fetchMessages,
    setSearchPhrase,
} from '../../../../../redux/messenger/messages/fetch-actions';

import EmptyMessagesScreen from '../../../../molecules/empty-messages-screen/empty-messages-screen';
import Messenger from '../../../../molecules/messenger/messenger';
import InputSearch from '../../../../atoms/form-poc/input-search';

const Messages = ({ hasMassages, phrase, hasNewMessageAvailable, dispatch }) => {
    useEffect(() => {
        dispatch(fetchMessages());
    }, [dispatch, phrase]);

    const onSearchChange = (phrase) => {
        dispatch(setSearchPhrase(phrase));
    };

    return (
        <div className={`${wrapper}`}>
            {hasMassages || phrase.length > 0 || hasNewMessageAvailable ? (
                <div>
                    <InputSearch
                        className={classNameInput}
                        placeholder={'Wyszukaj w konwersacji'}
                        onChange={onSearchChange}
                    />
                    <Messenger className={messenger} />
                </div>
            ) : (
                <div className={emptyWrapper}>
                    <EmptyMessagesScreen />
                </div>
            )}
        </div>
    );
};

export default connect(
    (state) => ({
        hasMassages: state.messenger.messages.data.length > 0,
        phrase: state.messenger.messages.meta.phraseString,
        hasNewMessageAvailable: state.messenger.messages.meta.firstMessageAvailable,
    }),
    null
)(Messages);
