// extracted by mini-css-extract-plugin
export var actionButton = "meal-card-module--action-button--yuUNR";
export var actions = "meal-card-module--actions--vKEJr";
export var card = "meal-card-module--card--bNHhP";
export var copy = "meal-card-module--copy--lM0uZ";
export var delBtn = "meal-card-module--del-btn--uDX0Z";
export var labels = "meal-card-module--labels--RQv7b";
export var move = "meal-card-module--move--Y6HrJ";
export var nameText = "meal-card-module--name-text--8TJKQ";
export var portionsText = "meal-card-module--portions-text--ZhJE2";
export var preview = "meal-card-module--preview--3Y0GI";
export var yourRecipeLabel = "meal-card-module--your-recipe-label--z+cj5";