import { types as apiTypes } from '../api/api.actions';

export const FORUM = '[FORUM]';
export const FORUM_THREAD_CATEGORIES = '[FORUM_THREAD_CATEGORIES]';
export const FORUM_THREADS = '[FORUM_THREADS]';
export const FORUM_THREADS_PINNED = '[FORUM_THREADS_PINNED]';
export const FORUM_THREAD = '[FORUM_THREAD]';
export const FORUM_THREAD_TOGGLE_FAVORITE = '[FORUM_THREAD_TOGGLE_FAVORITE]';
export const FORUM_COMMENTS = '[FORUM_COMMENTS]';
export const FORUM_COMMENT = '[FORUM_COMMENT]';
export const FORUM_COMMENT_ANSWERS = '[FORUM_COMMENT_ANSWERS]';

export const FORUM_COMMENTS_PER_PAGE = 50;
export const FORUM_THREADS_PER_PAGE = 50;
export const FORUM_COMMENT_ANSWERS_PER_PAGE = 50;

export const types = {
    // flow/commands actions
    GET_FORUM_THREAD_CATEGORIES: `${FORUM_THREAD_CATEGORIES} get`,
    GET_FORUM_THREAD_CATEGORIES_FAVORITE: `${FORUM_THREAD_CATEGORIES} get favorite`,
    GET_FORUM_THREADS: `${FORUM_THREADS} get`,
    GET_FORUM_THREADS_PINNED: `${FORUM_THREADS_PINNED} get`,
    GET_FORUM_THREAD: `${FORUM_THREAD} get`,
    TOGGLE_FORUM_THREAD_FAVORITE: `${FORUM_THREAD_TOGGLE_FAVORITE}`,
    GET_FORUM_COMMENTS: `${FORUM_COMMENTS} get`,
    CREATE_FORUM_COMMENT: `${FORUM_COMMENT} create`,
    UPDATE_FORUM_COMMENT: `${FORUM_COMMENT} update`,
    DELETE_FORUM_COMMENT: `${FORUM_COMMENT} delete`,
    REPORT_FORUM_COMMENT: `${FORUM_COMMENT} report`,
    GET_FORUM_COMMENT_ANSWERS: `${FORUM_COMMENT_ANSWERS} get`,
    CLEAR_FORUM_COMMENTS: `${FORUM_COMMENTS} clear`,
    CLEAR_FORUM: `${FORUM} clear`,

    // api actions
    SEND_FORUM_THREAD_CATEGORIES_REQUEST: `${FORUM_THREAD_CATEGORIES} ${apiTypes.API_REQUEST}`,
    SEND_FORUM_THREAD_CATEGORIES_SUCCESS: `${FORUM_THREAD_CATEGORIES} ${apiTypes.API_SUCCESS}`,
    SEND_FORUM_THREAD_CATEGORIES_FAIL: `${FORUM_THREAD_CATEGORIES} ${apiTypes.API_FAIL}`,

    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_REQUEST: `${FORUM_THREAD_CATEGORIES} ${apiTypes.API_REQUEST} favorite`,
    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_SUCCESS: `${FORUM_THREAD_CATEGORIES} ${apiTypes.API_SUCCESS} favorite`,
    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_FAIL: `${FORUM_THREAD_CATEGORIES} ${apiTypes.API_FAIL} favorite`,

    SEND_FORUM_THREADS_REQUEST: `${FORUM_THREADS} ${apiTypes.API_REQUEST}`,
    SEND_FORUM_THREADS_SUCCESS: `${FORUM_THREADS} ${apiTypes.API_SUCCESS}`,
    SEND_FORUM_THREADS_FAIL: `${FORUM_THREADS} ${apiTypes.API_FAIL}`,

    SEND_FORUM_THREADS_PINNED_REQUEST: `${FORUM_THREADS_PINNED} ${apiTypes.API_REQUEST}`,
    SEND_FORUM_THREADS_PINNED_SUCCESS: `${FORUM_THREADS_PINNED} ${apiTypes.API_SUCCESS}`,
    SEND_FORUM_THREADS_PINNED_FAIL: `${FORUM_THREADS_PINNED} ${apiTypes.API_FAIL}`,

    SEND_FORUM_THREAD_REQUEST: `${FORUM_THREAD} ${apiTypes.API_REQUEST}`,
    SEND_FORUM_THREAD_SUCCESS: `${FORUM_THREAD} ${apiTypes.API_SUCCESS}`,
    SEND_FORUM_THREAD_FAIL: `${FORUM_THREAD} ${apiTypes.API_FAIL}`,

    SEND_TOGGLE_FORUM_THREAD_FAVORITE_REQUEST: `${FORUM_THREAD_TOGGLE_FAVORITE} ${apiTypes.API_REQUEST}`,
    SEND_TOGGLE_FORUM_THREAD_FAVORITE_SUCCESS: `${FORUM_THREAD_TOGGLE_FAVORITE} ${apiTypes.API_SUCCESS}`,
    SEND_TOGGLE_FORUM_THREAD_FAVORITE_FAIL: `${FORUM_THREAD_TOGGLE_FAVORITE} ${apiTypes.API_FAIL}`,

    SEND_FORUM_COMMENTS_REQUEST: `${FORUM_COMMENTS} ${apiTypes.API_REQUEST}`,
    SEND_FORUM_COMMENTS_SUCCESS: `${FORUM_COMMENTS} ${apiTypes.API_SUCCESS}`,
    SEND_FORUM_COMMENTS_FAIL: `${FORUM_COMMENTS} ${apiTypes.API_FAIL}`,

    POST_FORUM_COMMENT_REQUEST: `${FORUM_COMMENT} ${apiTypes.API_REQUEST} post`,
    POST_FORUM_COMMENT_SUCCESS: `${FORUM_COMMENT} ${apiTypes.API_SUCCESS} post`,
    POST_FORUM_COMMENT_FAIL: `${FORUM_COMMENT} ${apiTypes.API_FAIL} post`,

    SEND_FORUM_COMMENT_ANSWERS_REQUEST: `${FORUM_COMMENT_ANSWERS} ${apiTypes.API_REQUEST}`,
    SEND_FORUM_COMMENT_ANSWERS_SUCCESS: `${FORUM_COMMENT_ANSWERS} ${apiTypes.API_SUCCESS}`,
    SEND_FORUM_COMMENT_ANSWERS_FAIL: `${FORUM_COMMENT_ANSWERS} ${apiTypes.API_FAIL}`,

    PATCH_FORUM_COMMENT_REQUEST: `${FORUM_COMMENT} ${apiTypes.API_REQUEST} patch`,
    PATCH_FORUM_COMMENT_SUCCESS: `${FORUM_COMMENT} ${apiTypes.API_SUCCESS} patch`,
    PATCH_FORUM_COMMENT_FAIL: `${FORUM_COMMENT} ${apiTypes.API_FAIL} patch`,

    DELETE_FORUM_COMMENT_REQUEST: `${FORUM_COMMENT} ${apiTypes.API_REQUEST} delete`,
    DELETE_FORUM_COMMENT_SUCCESS: `${FORUM_COMMENT} ${apiTypes.API_SUCCESS} delete`,
    DELETE_FORUM_COMMENT_FAIL: `${FORUM_COMMENT} ${apiTypes.API_FAIL} delete`,

    REPORT_FORUM_COMMENT_REQUEST: `${FORUM_COMMENT} ${apiTypes.API_REQUEST} report`,
    REPORT_FORUM_COMMENT_SUCCESS: `${FORUM_COMMENT} ${apiTypes.API_SUCCESS} report`,
    REPORT_FORUM_COMMENT_FAIL: `${FORUM_COMMENT} ${apiTypes.API_FAIL} report`,

    // writing actions (reducer)
    SET_FORUM_THREAD_CATEGORIES: `${FORUM_THREAD_CATEGORIES} set`,
    SET_FORUM_THREAD_CATEGORIES_FAVORITE: `${FORUM_THREAD_CATEGORIES} set`,
    SET_FORUM_THREADS: `${FORUM_THREADS} set`,
    SET_FORUM_THREADS_PINNED: `${FORUM_THREADS_PINNED} set`,
    SET_FORUM_THREAD: `${FORUM_THREAD} set`,
    SET_FORUM_COMMENTS: `${FORUM_COMMENTS} set`,
    SET_FORUM_COMMENTS_ITEMS: `${FORUM_COMMENTS} set items`,
    SET_FORUM_COMMENTS_ANSWERS: `${FORUM_COMMENT_ANSWERS} answers`,
    CLEAR_FORUM_THREADS_ITEMS: `${FORUM_THREADS} clear items`,
    CLEAR_FORUM_THREADS_PINNED_ITEMS: `${FORUM_THREADS_PINNED} clear items`,
    RESTORE_FORUM_INITIAL_STATE: `${FORUM} restore`,
    RESTORE_FORUM_COMMENTS_INITIAL_STATE: `${FORUM_COMMENTS} restore`,
};

// reducer actions
export const setForumThreadCategories = (payload) => ({
    type: types.SET_FORUM_THREAD_CATEGORIES,
    payload: payload,
});

export const setForumThreadCategoriesFavorite = (payload) => ({
    type: types.SET_FORUM_THREAD_CATEGORIES_FAVORITE,
    payload: payload,
});

export const setForumThreads = (payload) => ({
    type: types.SET_FORUM_THREADS,
    payload: payload,
});

export const setForumThreadsPinned = (payload) => ({
    type: types.SET_FORUM_THREADS_PINNED,
    payload: payload,
});

export const setForumThread = (payload) => ({
    type: types.SET_FORUM_THREAD,
    payload: payload,
});

export const restoreForumInitialState = () => ({
    type: types.RESTORE_FORUM_INITIAL_STATE,
});

export const restoreForumCommentsInitialState = () => ({
    type: types.RESTORE_FORUM_COMMENTS_INITIAL_STATE,
});

export const setForumComments = (payload) => ({
    type: types.SET_FORUM_COMMENTS,
    payload: payload,
});

export const setForumCommentsItems = (payload) => ({
    type: types.SET_FORUM_COMMENTS_ITEMS,
    payload: payload,
});

export const setForumCommentsAnswers = (payload) => ({
    type: types.SET_FORUM_COMMENTS_ANSWERS,
    payload: payload,
});

// middleware actions
export const getForumThreadCategories = () => ({
    type: types.GET_FORUM_THREAD_CATEGORIES,
});

export const getForumThreadCategoriesFavorite = () => ({
    type: types.GET_FORUM_THREAD_CATEGORIES_FAVORITE,
});

export const getForumThreads = ({ page, perPage, categoryId, isFavorite, search }) => ({
    type: types.GET_FORUM_THREADS,
    payload: { page, perPage, categoryId, search },
    meta: { isFavorite },
});

export const getForumThreadsPinned = (categoryId) => ({
    type: types.GET_FORUM_THREADS_PINNED,
    payload: categoryId,
});

export const getForumThread = (threadId) => ({
    type: types.GET_FORUM_THREAD,
    payload: threadId,
});

export const getForumComments = ({ page, perPage, threadId }) => ({
    type: types.GET_FORUM_COMMENTS,
    payload: { page, perPage, threadId },
});

export const clearForum = () => ({
    type: types.CLEAR_FORUM,
});

export const clearForumComments = () => ({
    type: types.CLEAR_FORUM_COMMENTS,
});

export const clearForumThreadsItems = () => ({
    type: types.CLEAR_FORUM_THREADS_ITEMS,
});

export const clearForumThreadsPinnedItems = () => ({
    type: types.CLEAR_FORUM_THREADS_PINNED_ITEMS,
});

export const toggleForumThreadFavorite = (threadId) => ({
    type: types.TOGGLE_FORUM_THREAD_FAVORITE,
    payload: threadId,
});

export const createForumComment = ({ values, formik, parentId, threadId, elementToScroll }) => ({
    type: types.CREATE_FORUM_COMMENT,
    payload: { ...values, parentId },
    meta: { formikBag: formik, parentId, threadId, elementToScroll },
});

export const updateForumComment = ({ values, formik, parentId, threadId, commentId }) => ({
    type: types.UPDATE_FORUM_COMMENT,
    payload: { ...values, parentId },
    meta: { formikBag: formik, parentId, threadId, commentId },
});

export const deleteForumComment = ({ threadId, commentId }) => ({
    type: types.DELETE_FORUM_COMMENT,
    payload: { threadId, commentId },
});

export const reportForumComment = ({ threadId, commentId }) => ({
    type: types.REPORT_FORUM_COMMENT,
    payload: { threadId, commentId },
});

export const getForumCommentAnswers = ({ page, perPage, threadId, commentId, isAfterCreate }) => ({
    type: types.GET_FORUM_COMMENT_ANSWERS,
    payload: { page, perPage, threadId, commentId, isAfterCreate },
});
