import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
    wrapper,
    loader,
    relatedSeparator,
    separator,
    relatedExercises,
    clues,
    seeAllButton,
    description,
    bannerContent,
    banner,
    video,
    trainer,
} from './training-page.module.scss';
import { config } from '../config';
import gamificationReasons from '../global-enums/gamification-reasons.json';
import { getAbsolutePath } from '../routes';
import { getTraining } from '../communication/workout';
import { saveMovieLog } from '../communication/audit';

import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import Separator from '../components/atoms/separator';
import VideoPlayer from '../components/molecules/video-player';
import ExerciseMetadata from '../components/molecules/exercise/exercise-metadata';
import Tag from '../components/atoms/tag';
import BigTitle from '../components/atoms/big-title';
import RelatedList from '../components/organisms/related-list';
import Button from '../components/atoms/button';
import Error from '../components/atoms/form/error';
import Trainer from '../components/molecules/training-page/trainer/trainer';
import ExerciseCard from '../components/molecules/exercise/exercise-card';
import Loader from '../components/atoms/loader';
import GetPointsButton from '../components/organisms/get-points-button';
import GetPointsButtonWrapper from '../components/molecules/get-points-button-wrapper';
import Markdown from '../components/hoc/markdown';

const { audit } = config;

const TrainingPage = ({ id }) => {
    const [exercise, setExercise] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);

    const imageQuery = useStaticQuery(query);

    const bannerData = {
        fluidImg: getImage(imageQuery.exerciseBanner),
    };

    const handleWatched = async () => {
        const movieData = {
            module: audit.module.training,
            group: audit.group.exercise,
            action: audit.action.watch,
            params: id,
        };
        await saveMovieLog(movieData);
    };

    const renderRelated = (relatedTrainings) => {
        return (
            <>
                <Separator className={relatedSeparator} />
                <RelatedList
                    title="Wykonaj kolejny trening"
                    link={{
                        url: getAbsolutePath('APP_TRAININGS'),
                        name: 'Lub zobacz wszystkie treningi',
                    }}
                >
                    {errors && <Error>{errors}</Error>}
                    {relatedTrainings.map((related) => (
                        <ExerciseCard
                            key={`list-item-${related.id}`}
                            item={related}
                            {...related}
                            path={getAbsolutePath('APP_TRAININGS')}
                        />
                    ))}
                </RelatedList>
            </>
        );
    };

    useEffect(() => {
        getTraining(id)
            .then((resp) => setExercise(resp.data))
            .catch((error) => setErrors(error.response.data.messages))
            .finally(() => setLoading(false));
    }, [id]);

    return (
        <>
            <Main transparentHeader className={wrapper}>
                <MainBanner
                    className={banner}
                    bannerData={bannerData}
                    imgStyle={{
                        objectFit: 'cover',
                        objectPosition: 'top center',
                        filter: 'brightness(60%)',
                    }}
                >
                    {exercise && (
                        <div className={bannerContent}>
                            <Tag
                                tag={{
                                    tagId: 1,
                                    color: 'var(--color-pink-dark)',
                                    name: 'Trening',
                                }}
                            />
                            <BigTitle Tag="h1">{exercise.name}</BigTitle>
                            <ExerciseMetadata exercise={exercise} short={false} />
                        </div>
                    )}
                    {loading && <Loader className={loader} />}
                    {!exercise && !loading && (
                        <div className={bannerContent}>
                            <Tag
                                tag={{
                                    tagId: 1,
                                    color: 'var(--color-pink-dark)',
                                    name: 'Trening',
                                }}
                            />
                            <BigTitle Tag="h1">Ups! Nie znaleźliśmy takiego treningu.</BigTitle>
                        </div>
                    )}
                </MainBanner>
                {exercise && (
                    <>
                        {exercise.videoUri && (
                            <section className={video}>
                                <VideoPlayer
                                    autoplay={false}
                                    coverUrl={exercise.coverViewUri}
                                    videoUrl={exercise.videoUri}
                                    followProgress={true}
                                    onWatched={handleWatched}
                                />
                                <GetPointsButtonWrapper>
                                    <GetPointsButton
                                        reasonId={gamificationReasons.trainingComplete}
                                        objectId={exercise.trainingId}
                                    >
                                        Ukończyłem trening
                                    </GetPointsButton>
                                </GetPointsButtonWrapper>
                            </section>
                        )}

                        {exercise.videoUri === null &&
                            exercise.restrictedFields.includes('videoUri') && (
                                <section className={video}>
                                    <VideoPlayer>
                                        <p>sorry brak uprawnień</p>
                                    </VideoPlayer>
                                </section>
                            )}
                        {exercise.description && (
                            <section className={description}>
                                <h1>Opis</h1>
                                <Separator className={separator} />
                                <Markdown>{exercise.description}</Markdown>
                            </section>
                        )}
                        {exercise.clues && (
                            <section className={clues}>
                                <h1>Szczegóły treningu</h1>
                                <Separator className={separator} />
                                <Markdown>{exercise.clues}</Markdown>
                            </section>
                        )}
                        {exercise.trainer && (
                            <section className={trainer}>
                                <h1>Instruktor</h1>
                                <Separator className={separator} />
                                <Trainer data={exercise.trainer} />
                            </section>
                        )}
                        <section className={relatedExercises}>
                            {exercise.relatedTrainings && exercise.relatedTrainings.length > 0 ? (
                                renderRelated(exercise.relatedTrainings)
                            ) : (
                                <div className={seeAllButton}>
                                    <Link to={getAbsolutePath('APP_TRAININGS')}>
                                        <Button color="yellow">zobacz wszystkie treningi</Button>
                                    </Link>
                                </div>
                            )}
                        </section>
                    </>
                )}
            </Main>
        </>
    );
};

const query = graphql`
    {
        exerciseBanner: file(relativePath: { eq: "trainings-banner.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;

export default TrainingPage;
