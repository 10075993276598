import { rateSingleRecipe } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';
import { getSingleRecipeSuccessAction } from './get-single-recipe';

export const rateSingleRecipeAction = (id, profileId, rate) => async (dispatch, getState) => {
    dispatch(rateSingleRecipeStartAction());
    try {
        const res = await rateSingleRecipe(id, profileId, { rateValue: rate });
        if (res.status === 201) {
            const recipe = getState().recipes.single.get.data;
            const newRecipe = {
                ...recipe,
                rate: res.data,
            };
            dispatch(getSingleRecipeSuccessAction(newRecipe));
        }
        dispatch(rateSingleRecipeSuccessAction());
    } catch {
        dispatch(rateSingleRecipeErrorAction());
    }
};

export const rateSingleRecipeClearSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.rate,
        entitiesMap.recipes.single.allowedActions.clearSuccess
    ),
});

export const rateSingleRecipeClearErrorAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.rate,
        entitiesMap.recipes.single.allowedActions.clearErrors
    ),
});

const rateSingleRecipeStartAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.rate,
            entitiesMap.recipes.single.allowedActions.start
        ),
        payload: {},
    };
};

const rateSingleRecipeSuccessAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.rate,
            entitiesMap.recipes.single.allowedActions.success
        ),
        payload: {},
    };
};

const rateSingleRecipeErrorAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.rate,
            entitiesMap.recipes.single.allowedActions.error
        ),
        payload: {},
    };
};
