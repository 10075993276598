import { types as apiTypes } from '../api/api.actions';

export const SURVEY = '[SURVEY]';

export const types = {
    // flow/commands actions
    GET_SURVEY: `${SURVEY} get`,
    VALIDATE_SURVEY: `${SURVEY} validate`,
    CREATE_SURVEY: `${SURVEY} create`,
    CLEAR_SURVEY: `${SURVEY} clear`,

    // API actions
    FETCH_SURVEY_REQUEST: `${SURVEY} ${apiTypes.API_REQUEST}`,
    FETCH_SURVEY_SUCCESS: `${SURVEY} ${apiTypes.API_SUCCESS}`,
    FETCH_SURVEY_FAIL: `${SURVEY} ${apiTypes.API_FAIL}`,

    POST_SURVEY_REQUEST: `${SURVEY} ${apiTypes.API_REQUEST} post`,
    POST_SURVEY_SUCCESS: `${SURVEY} ${apiTypes.API_SUCCESS} post`,
    POST_SURVEY_FAIL: `${SURVEY} ${apiTypes.API_FAIL} post`,

    VALIDATE_SURVEY_REQUEST: `${SURVEY} ${apiTypes.API_REQUEST} validate`,
    VALIDATE_SURVEY_SUCCESS: `${SURVEY} ${apiTypes.API_SUCCESS} validate`,
    VALIDATE_SURVEY_FAIL: `${SURVEY} ${apiTypes.API_FAIL} validate`,

    // writing actions (reducer)
    SET_SURVEY_VALUES: `${SURVEY} set values`,
    SET_SURVEY_STEP: `${SURVEY} set step`,
    RESTORE_INITIAL_STATE: `${SURVEY} restore initial state`,
};

export const getSurveyAction = (surveyToProfileId) => ({
    type: types.GET_SURVEY,
    payload: surveyToProfileId,
});

export const createSurveyAction = (values, formikBag) => ({
    type: types.CREATE_SURVEY,
    payload: values,
    meta: { formikBag },
});

export const validateSurveyAction = (values, formikBag) => ({
    type: types.VALIDATE_SURVEY,
    payload: values,
    meta: { formikBag },
});

export const clearSurveyAction = () => ({
    type: types.CLEAR_SURVEY,
});

export const setSurveyValuesAction = (values) => ({
    type: types.SET_SURVEY_VALUES,
    payload: values,
});

export const setSurveyStepAction = (step) => ({
    type: types.SET_SURVEY_STEP,
    payload: step,
});

export const restoreInitialStateAction = () => ({
    type: types.RESTORE_INITIAL_STATE,
});
