import { types as apiTypes } from '../api/api.actions';

export const SURVEYS = '[SURVEYS]';

export const types = {
    // flow/commands actions
    GET_SURVEYS: `${SURVEYS} get`,
    CLEAR_SURVEYS: `${SURVEYS} clear`,

    // API actions
    FETCH_SURVEYS_REQUEST: `${SURVEYS} ${apiTypes.API_REQUEST}`,
    FETCH_SURVEYS_SUCCESS: `${SURVEYS} ${apiTypes.API_SUCCESS}`,
    FETCH_SURVEYS_FAIL: `${SURVEYS} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_SURVEYS: `${SURVEYS} set`,
    RESTORE_INITIAL_STATE: `${SURVEYS} restore initial state`,
};

export const getSurveysAction = (page, perPage) => ({
    type: types.GET_SURVEYS,
    payload: { page, perPage },
});

export const clearSurveysAction = () => ({
    type: types.CLEAR_SURVEYS,
});

export const setSurveysAction = (data) => ({
    type: types.SET_SURVEYS,
    payload: data,
});

export const restoreInitialStateAction = () => ({
    type: types.RESTORE_INITIAL_STATE,
});
