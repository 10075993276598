import { useEffect, useRef, useState } from 'react';

import copyTextElement from '../utills/copy-text-element';

export default function useWriteToClipboard<RefType extends HTMLElement>() {
    const [isCopied, setIsCopied] = useState(false);
    const ref = useRef<RefType>(null);
    let timeoutId: NodeJS.Timeout | null = null;

    const copyText = async (text?: string) => {
        if (ref.current) {
            await copyTextElement(ref.current, text);
            setIsCopied(true);
            timeoutId = setTimeout(() => setIsCopied(false), 5000);
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    return { ref, copyText, isCopied };
}
