import types, { ProductsActions } from './products.actions';
import { IProductCard } from '../../../models/product.model';

const INITIAL_STATE: IProductCard[] = [];

export default function productsReducer(state = INITIAL_STATE, action: ProductsActions) {
    switch (action.type) {
        case types.SET_PRODUCTS:
            return [...action.payload];
        case types.ADD_PRODUCTS:
            return [...state, ...action.payload];
        case types.CLEAR_PRODUCTS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
