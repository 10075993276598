import React from 'react';
import { Link } from 'gatsby';

const CustomLink = ({
    className = '',
    currentClassName = '',
    partiallyCurrentClassName = '',
    to,
    children,
    ...rest
}) => {
    const isLinkActive = ({ isCurrent, isPartiallyCurrent }) => {
        return {
            className: `${className} ${isCurrent ? currentClassName : ''} ${
                isPartiallyCurrent ? partiallyCurrentClassName : ''
            }`,
        };
    };

    return (
        <Link to={to} getProps={isLinkActive} children={children} {...rest} />
    );
};

export default CustomLink;
