import React from 'react';

import { list } from './social-zone.module.scss';

import FacebookBanner from '../../molecules/social-zone/facebook-banner/facebook-banner';
import AniaBartekAbout from '../../molecules/social-zone/ania-bartek-about/ania-bartek-about';
import { Metamorphosis } from '../../molecules/social-zone/metamorphosis/metamorphosis';

const SocialZone = () => {
    return (
        <div>
            <ul className={list}>
                <li>
                    <FacebookBanner />
                </li>
                <li>
                    <AniaBartekAbout />
                </li>
                <li>
                    <Metamorphosis />
                </li>
            </ul>
        </div>
    );
};

export default SocialZone;
