import axios from 'axios';

import { config } from '../config';

export const getAllCourses = (perPage) =>
    axios.get(config.endpoints.allCourses(perPage), {
        withCredentials: true,
    });

export const getMyCourses = (perPage) =>
    axios.get(config.endpoints.myCourses(perPage), {
        withCredentials: true,
    });

export const getCourse = (id) =>
    axios.get(config.endpoints.chosenCourse(id), {
        withCredentials: true,
    });
