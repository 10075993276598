import React, { useEffect } from 'react';
import { Link } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import {
    container,
    loading,
    header,
    title,
    headerAddButton,
    separator,
    list,
    loader,
    noListBox,
    noListComment,
    noListAddButton,
} from './my-shopping-lists.module.scss';
import { config } from '../../../config';
import { SINGLE_SHOPPING_LIST_CHANGE } from '../../../redux/single-shopping-list/single-shopping-list.actions';
import {
    getShoppingLists,
    clearShoppingLists,
    SHOPPING_LISTS,
} from '../../../redux/shopping-lists/shopping-lists.actions';
import { selectShoppingLists } from '../../../redux/shopping-lists/shopping-lists.selectors';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { addModalAction } from '../../../redux/actions/actions-modals';
import { statusIdle } from '../../../redux/ui/ui.actions';
import usePagination from '../../../hooks/use-pagination';

import Button from '../../atoms/button';
import Loader from '../../atoms/loader';
import Separator from '../../atoms/separator';
import ShoppingListItem from '../../molecules/shopping-list-item';
import Pagination from '../../molecules/pagination';

const { apiStatusMap } = config;

const MyShoppingLists = () => {
    const dispatch = useDispatch();
    const { fetchStatus, deleteStatus } = useSelector((state) => {
        return {
            fetchStatus: selectLoaderByEntity(state, SHOPPING_LISTS),
            deleteStatus: selectLoaderByEntity(state, SINGLE_SHOPPING_LIST_CHANGE),
        };
    });

    const { page, pages, pagination, getPage } = usePagination({
        entitySelector: selectShoppingLists,
        action: getShoppingLists,
        clearAction: clearShoppingLists,
    });

    useEffect(() => {
        if (deleteStatus === apiStatusMap.loading) return;
        if (deleteStatus === apiStatusMap.fail) {
            dispatch(
                addModalAction({
                    type: 'error',
                    title: 'Nie udało się!',
                    content: `Coś poszło nie tak, nie udało się usunąć listy zakupowej...`,
                })
            );
        }
        dispatch(statusIdle(SINGLE_SHOPPING_LIST_CHANGE));
    }, [deleteStatus, dispatch]);

    if (fetchStatus === apiStatusMap.fail) {
        return <div>Wystąpił błąd przy pobieraniu list zakupowych, spróbuj później...</div>;
    }

    if (!Object.keys(pages).length) {
        return <Loader />;
    }

    return (
        <div
            className={`
                ${container} 
                ${deleteStatus === apiStatusMap.loading ? loading : ''}
            `}
        >
            <div className={header}>
                <h3 className={title}>Twoje listy zakupowe</h3>
                {pagination.totalCount > 0 && (
                    <Link to="dodaj">
                        <Button color="yellow" size="small" className={headerAddButton}>
                            Stwórz listę zakupów
                        </Button>
                    </Link>
                )}
            </div>
            {pagination.totalCount > 0 ? (
                <>
                    <Separator className={separator} />
                    <ul className={list}>
                        {fetchStatus === apiStatusMap.loading || !pages[page] ? (
                            <Loader className={loader} />
                        ) : (
                            pages[page].map((item) => (
                                <ShoppingListItem key={`shopping-list-${item.id}`} item={item} />
                            ))
                        )}
                    </ul>
                    {pagination.pageCount > 1 && (
                        <Pagination
                            disabled={fetchStatus === apiStatusMap.loading}
                            pageCount={pagination.pageCount}
                            page={Number(page)}
                            onChange={(selectedPage) => getPage(selectedPage)}
                        />
                    )}
                </>
            ) : (
                <div className={noListBox}>
                    <h4 className={noListComment}>Nie stworzyłeś jeszcze żadnej listy zakupowej</h4>
                    <Link to="dodaj">
                        <Button color="yellow" size="small" className={noListAddButton}>
                            Stwórz listę zakupów
                        </Button>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default MyShoppingLists;
