import React from 'react';

import { content, keton, title, pointsTitle } from './points-title.module.scss';

import AnimatedKeton from './animated-keton';

export interface IKetoTitleProps {
    children?: string;
    after?: React.ReactNode;
}

export default function PointsTitle({ children, after }: IKetoTitleProps) {
    return (
        <div className={pointsTitle}>
            <div className={content}>
                <AnimatedKeton className={keton} />
                <h5 className={title}>{children}</h5>
            </div>
            <div>{after}</div>
        </div>
    );
}
