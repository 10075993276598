import React from 'react';

import { originalPrice } from './price.module.scss';
import { IPrice } from '../../models/price.model';

export interface IPriceFragmentProps {
    price?: IPrice;
    basePrice?: IPrice;
    basePriceClassName?: string;
    priceClassName?: string;
    isDiscounted?: boolean;
    color?: string;
}

export default function PriceFragment({
    price,
    basePrice,
    basePriceClassName = '',
    priceClassName = '',
    isDiscounted,
    color,
}: IPriceFragmentProps) {
    const shouldDisplayBasePrice = isDiscounted && basePrice;

    let originalPriceStyles;

    if (color) {
        originalPriceStyles = {
            color: `var(${color}`,
        };
    }

    return (
        <>
            <span className={priceClassName}>{price?.grossDisplay} </span>
            {shouldDisplayBasePrice && (
                <span
                    className={`${originalPrice} ${basePriceClassName}`}
                    style={originalPriceStyles}
                >
                    {basePrice.grossDisplay}
                </span>
            )}
        </>
    );
}
