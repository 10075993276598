import React from 'react';

import { box, flexGrow, titleText } from './order-details-box.module.scss';

const OrderDetailsBox = ({ title, grown, children }) => {
    return (
        <div className={`${box} ${grown ? flexGrow : ''}`}>
            <h6 className={titleText}>{title}</h6>
            <div>{children}</div>
        </div>
    );
};

export default OrderDetailsBox;
