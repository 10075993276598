import React from 'react';

import {
    filterContainer,
    filterList,
    filterTitle,
    loader,
    filterWrapper,
    sortTitle,
    sortIcon,
    sortWrapper,
} from './filters.module.scss';
import SortIcon from '../../../assets/images/svg/icon-sort.svg';
import useGenericFilters, { IUseGenericFilterProps } from '../../../hooks/use-generic-filters';

import FilterList from '../../molecules/filter-molecules/filter-list';

export interface IFiltersProps extends IUseGenericFilterProps {
    loading?: boolean;
}

const Filters = ({ loading, ...filterProps }: IFiltersProps) => {
    const {
        filtersCollection,
        sortCollection,
        openedItem,
        toggleOpen,
        applyFilters,
        filterClear,
        filterClick,
        sortClick,
    } = useGenericFilters(filterProps);

    return (
        <form className={`${filterContainer} ${loading ? loader : ''}`}>
            {Object.entries(filtersCollection).length > 0 && (
                <div className={filterWrapper}>
                    <div className={filterTitle}>Filtry:</div>
                    <ul className={filterList}>
                        <FilterList
                            items={filtersCollection}
                            itemProps={{
                                openedItem,
                                applyFilters,
                                clear: filterClear,
                                handleOpen: toggleOpen,
                                filterClick,
                            }}
                        />
                    </ul>
                </div>
            )}

            {Object.values(sortCollection)?.length > 0 && (
                <div className={sortWrapper}>
                    <div className={sortTitle}>
                        <SortIcon className={sortIcon} />
                        <div className={filterTitle}>Sortuj:</div>
                    </div>
                    <ul className={filterList}>
                        <FilterList
                            items={sortCollection}
                            itemProps={{
                                openedItem,
                                clear: filterClear,
                                handleOpen: toggleOpen,
                                filterClick: sortClick,
                                withButtons: false,
                                showApplied: true,
                            }}
                        />
                    </ul>
                </div>
            )}
        </form>
    );
};

export default Filters;
