import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';

import { title, list, item as itemClass, separator, actualRow } from './my-surveys.module.scss';
import { config } from '../../../config';
import { getCurrentProfile, getCurrentProfileId } from '../../../redux/profile/profile.selectors';
import { selectActualSurvey, selectOldSurveys } from '../../../redux/surveys/surveys.selectors';
import {
    clearSurveysAction,
    getSurveysAction,
    SURVEYS,
} from '../../../redux/surveys/surveys.actions';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';

import Loader from '../../atoms/loader';
import Separator from '../../atoms/separator';
import CustomLink from '../../hoc/custom-link';
import Button from '../../atoms/button';
import SurveyItem from '../../molecules/survey-item';

const { apiStatusMap } = config;
const PAGE = 1;
const PER_PAGE = 50;

const MySurveys = () => {
    const dispatch = useDispatch();
    const { actualSurvey, oldSurveys, status, profile, profileId } = useSelector((state) => {
        return {
            status: selectLoaderByEntity(state, SURVEYS),
            actualSurvey: selectActualSurvey(state),
            oldSurveys: selectOldSurveys(state),
            profile: getCurrentProfile(state),
            profileId: getCurrentProfileId(state),
        };
    });

    useEffect(() => {
        dispatch(getSurveysAction(PAGE, PER_PAGE));
    }, [dispatch, profileId]);

    useEffect(() => {
        return () => {
            dispatch(clearSurveysAction());
        };
    }, [dispatch]);

    if (!status || status === apiStatusMap.idle || status === apiStatusMap.loading) {
        return <Loader />;
    }

    if (status === apiStatusMap.success && !actualSurvey) {
        // noinspection JSIgnoredPromiseFromCall
        navigate('twoja-ankieta/wypelnij');
        return null;
    }

    return (
        <>
            <h3 className={title}>Ostatnio wypełniona ankieta</h3>
            <div className={actualRow}>
                <SurveyItem
                    createdAt={actualSurvey.createdAt}
                    lastName={profile.lastName}
                    firstName={profile.firstName}
                    isLink={false}
                />
                <CustomLink to={`edycja/${actualSurvey.surveyToProfileId}`}>
                    <Button size="small" color="yellow">
                        Uaktualnij Ankietę
                    </Button>
                </CustomLink>
            </div>
            {oldSurveys && oldSurveys.length > 0 && (
                <>
                    <h3 className={title}>Stare ankiety</h3>
                    <Separator className={separator} />
                    <ul className={list}>
                        {oldSurveys.map((item, index) => {
                            return (
                                <li key={`survey-${index}`} className={itemClass}>
                                    <SurveyItem
                                        createdAt={item.createdAt}
                                        lastName={profile.lastName}
                                        firstName={profile.firstName}
                                        isLink={true}
                                        to={`podglad/${item.surveyToProfileId}`}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
        </>
    );
};

export default MySurveys;
