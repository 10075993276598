import { config } from '../../config';

import { COURSES, types, setCourses, restoreInitialState } from './courses.actions';
import { setRequestCount, statusIdle } from '../ui/ui.actions';
import { selectRequestCountByEntity } from '../ui/ui.selectors';

const {
    GET_COURSES,
    CLEAR_COURSES,
    FETCH_COURSES_FAIL,
    FETCH_COURSES_REQUEST,
    FETCH_COURSES_SUCCESS,
} = types;

const coursesMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    next(action);

    switch (type) {
        case GET_COURSES:
            const url = payload.isMine
                ? config.endpoints.courses[payload.courseType].my(payload.page, payload.perPage)
                : config.endpoints.courses[payload.courseType].notMine(
                      payload.page,
                      payload.perPage
                  );
            const newCount = (selectRequestCountByEntity(getState(), COURSES) || 0) + 1;
            dispatch(setRequestCount(COURSES, newCount));
            dispatch({
                types: [FETCH_COURSES_REQUEST, FETCH_COURSES_SUCCESS, FETCH_COURSES_FAIL],
                payload: {
                    request: {
                        url,
                        withCredentials: true,
                    },
                },
                meta: { entity: COURSES, count: newCount },
            });
            break;

        case FETCH_COURSES_SUCCESS:
            const currentCount = selectRequestCountByEntity(getState(), COURSES);
            const requestCount = meta?.previousAction?.meta?.count;
            if (requestCount && currentCount !== requestCount) return;
            dispatch(setCourses(payload.data));
            break;

        case CLEAR_COURSES:
            dispatch(statusIdle(COURSES));
            dispatch(restoreInitialState());
            break;

        default:
            break;
    }
};

export default coursesMiddleware;
