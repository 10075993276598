// extracted by mini-css-extract-plugin
export var admin = "comment-module--admin--scWJ4";
export var answer = "comment-module--answer--R9Mk+";
export var answerButton = "comment-module--answer-button--ep+kG";
export var avatar = "comment-module--avatar--Ceu94";
export var bottom = "comment-module--bottom--OrZ9J";
export var container = "comment-module--container--89EuD";
export var content = "comment-module--content--Qfx5y";
export var contentColumn = "comment-module--content-column--pu5lX";
export var date = "comment-module--date--PkhFx";
export var deleteImageButton = "comment-module--delete-image-button--RxJPT";
export var deleted = "comment-module--deleted--FdYPZ";
export var dot = "comment-module--dot--W18fE";
export var dropdown = "comment-module--dropdown--OQqWJ";
export var dropdownButton = "comment-module--dropdown-button--NoPRd";
export var fields = "comment-module--fields--msCXB";
export var fileInput = "comment-module--file-input--BMCIw";
export var fileInputLabel = "comment-module--file-input-label--s6dsq";
export var form = "comment-module--form--E1gDM";
export var formActions = "comment-module--form-actions--a3GVO";
export var formButton = "comment-module--form-button--hLsYh";
export var header = "comment-module--header--udFQt";
export var image = "comment-module--image--kuu0J";
export var imageBox = "comment-module--image-box--fbgxu";
export var imageButton = "comment-module--image-button--ZiqZb";
export var images = "comment-module--images--PBelK";
export var input = "comment-module--input--lKAs-";
export var inputBox = "comment-module--input-box--NGQht";
export var more = "comment-module--more--7CsOx";
export var moreButton = "comment-module--more-button--9DN0I";
export var name = "comment-module--name--bCzHM";
export var nameBox = "comment-module--name-box--QPqCI";
export var open = "comment-module--open--5g0j7";
export var text = "comment-module--text--gSi-+";
export var yours = "comment-module--yours--bExd2";