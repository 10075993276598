import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { config } from '../../../../config';
import {
    wrapper,
    topWrapper,
    emptyInfo,
    trainingTypeWrapper,
    playlist,
    labelName,
    movieCounter,
} from './play-list.module.scss';
import IconInfo from './../../../../assets/images/svg/icon-info.svg';
import {
    PERSONAL_TRAINING_DAY,
    types,
} from '../../../../redux/personal-training/personal-training.actions';
import { selectCurrentMovies } from '../../../../redux/personal-training/personal-training.selectors';
import { selectLoaderByEntity } from '../../../../redux/ui/ui.selectors';

import PlayerListItem from '../player-list-item';
import Loader from '../../loader';
import Markdown from '../../../hoc/markdown';

const { personalTraining, apiStatusMap } = config;

const PlayList = ({ currentListType }) => {
    const dispatch = useDispatch();

    const { playlistMovieIndex, movies, playerListType, dayLoading, currentDate } = useSelector(
        (state) => {
            const training = state.personalTrainings;
            return {
                playlistMovieIndex: training.current.playlistMovieIndex,
                movies: selectCurrentMovies(state, currentListType),
                playerListType: training.current.listType,
                dayLoading: selectLoaderByEntity(state, PERSONAL_TRAINING_DAY),
                currentDate: training.current.date,
            };
        }
    );

    useEffect(() => {
        if (movies === null) {
            dispatch({ type: types.GET_TRAINING_DAY });
        }
    }, [dispatch, movies]);
    const playVideo = (index, listType) => {
        dispatch({
            type: types.PLAY_MOVIE,
            payload: { listType, playlistMovieIndex: index },
        });
    };

    return (
        <>
            <div className={topWrapper}>
                <div className={playlist}>Playlista</div>
                <div className={trainingTypeWrapper}>
                    <span className={labelName}>
                        {personalTraining.typesMap[currentListType].name}
                    </span>
                    <span className={movieCounter}>
                        {checkNumberToShow(
                            currentListType,
                            playerListType,
                            playlistMovieIndex,
                            movies
                        )}
                    </span>
                </div>
            </div>

            {dayLoading === apiStatusMap.loading && <Loader fullContainer />}

            <div className={wrapper}>
                {Array.isArray(movies) && movies.length > 0 ? (
                    <div>
                        {movies.map((training, index) => {
                            return (
                                <PlayerListItem
                                    key={`training-item-${training.id}-${index}`}
                                    listType={currentListType}
                                    currentListType={playerListType}
                                    training={training}
                                    index={index}
                                    playVideo={playVideo}
                                    activeVideoIndex={playlistMovieIndex}
                                    currentDate={currentDate}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <section className={emptyInfo}>
                        <IconInfo />
                        <Markdown>{personalTraining.typesMap[currentListType].emptyInfo}</Markdown>
                    </section>
                )}
            </div>
        </>
    );
};

const checkNumberToShow = (listType, playerType, index, movies = []) => {
    let indexToDisplay = index + 1;
    let moviesTotal = Array.isArray(movies) ? movies.length : 0;
    if (movies === null || movies.length <= 0 || playerType !== listType) {
        indexToDisplay = 0;
    }
    if (movies !== null && movies.length < indexToDisplay) {
        indexToDisplay = movies.length;
    }
    return `${indexToDisplay} / ${moviesTotal}`;
};

export default PlayList;
