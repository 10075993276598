// extracted by mini-css-extract-plugin
export var addressInputs = "shop-step-address-module--address-inputs--IomSn";
export var buttonHolder = "shop-step-address-module--button-holder--giic9";
export var buttons = "shop-step-address-module--buttons--UpRnJ";
export var checkbox = "shop-step-address-module--checkbox--WQ5kc";
export var fields = "shop-step-address-module--fields--ISzAH";
export var header = "shop-step-address-module--header--u7TON";
export var hideButton = "shop-step-address-module--hide-button--9kpTY";
export var inputs = "shop-step-address-module--inputs--hiinc";
export var invoiceDataShop = "shop-step-address-module--invoice-data-shop--6qdZ+";
export var labelInput = "shop-step-address-module--label-input--x8f5K";
export var list = "shop-step-address-module--list--8iF7T";
export var readonly = "shop-step-address-module--readonly--2WCWK";
export var title = "shop-step-address-module--title--EhC7u";
export var wrapper = "shop-step-address-module--wrapper--DbL8x";