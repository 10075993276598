import { useEffect, useState } from 'react';

const useWindowWidth = (): number | undefined => {
    const [windowWidth, setWindowWidth] = useState<number | undefined>(getWindowWidth());

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(getWindowWidth());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

function getWindowWidth(): number | undefined {
    return typeof window !== 'undefined' ? window.innerWidth : undefined;
}

export default useWindowWidth;
