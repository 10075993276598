import { Action } from 'redux';

import { types as apiTypes } from '../../api/api.actions';
import { ActionCreator, PayloadAction } from '../../../models/redux-action';
import { IProductCard } from '../../../models/product.model';

export const PRODUCTS = '[PRODUCTS]';

const types = {
    REQUEST_PRODUCTS: `${PRODUCTS} request_products` as const,

    FETCH_PRODUCTS: `${PRODUCTS} all ${apiTypes.API_REQUEST}`,
    FETCH_PRODUCTS_SUCCESS: `${PRODUCTS} all ${apiTypes.API_SUCCESS}`,
    FETCH_PRODUCTS_FAIL: `${PRODUCTS} all ${apiTypes.API_FAIL}`,

    SET_PRODUCTS: `${PRODUCTS} set_products` as const,
    ADD_PRODUCTS: `${PRODUCTS} add_products` as const,
    CLEAR_PRODUCTS: `${PRODUCTS} clear_products` as const,
};

type RequestProductsAction = PayloadAction<
    typeof types.REQUEST_PRODUCTS,
    { page?: number; productIds?: number[] } | undefined
>;
type SetProductsAction = PayloadAction<typeof types.SET_PRODUCTS, IProductCard[] | undefined>;
type AddProductsAction = PayloadAction<typeof types.ADD_PRODUCTS, IProductCard[] | undefined>;
type ClearProductsAction = Action<typeof types.CLEAR_PRODUCTS>;

export type ProductsActions =
    | RequestProductsAction
    | SetProductsAction
    | AddProductsAction
    | ClearProductsAction;

export const requestProducts: ActionCreator<RequestProductsAction> = (payload) => ({
    type: types.REQUEST_PRODUCTS,
    payload,
});

export const setProducts: ActionCreator<SetProductsAction> = (payload) => ({
    type: types.SET_PRODUCTS,
    payload,
});

export const addProducts: ActionCreator<AddProductsAction> = (payload) => ({
    type: types.ADD_PRODUCTS,
    payload,
});

export const clearProducts: ActionCreator<ClearProductsAction> = () => ({
    type: types.CLEAR_PRODUCTS,
});

export default types;
