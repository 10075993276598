// extracted by mini-css-extract-plugin
export var box = "checkbox-group-module--box--rIQ3d";
export var check = "checkbox-group-module--check---BtvL";
export var container = "checkbox-group-module--container--0Ud5l";
export var dark = "checkbox-group-module--dark--ezq0-";
export var disabled = "checkbox-group-module--disabled--SxnVf";
export var iconWrapper = "checkbox-group-module--icon-wrapper--KYgIm";
export var input = "checkbox-group-module--input--SmNP6";
export var label = "checkbox-group-module--label--5jLhS";
export var radio = "checkbox-group-module--radio--P2kbx";
export var withIcon = "checkbox-group-module--with-icon--8nYWa";