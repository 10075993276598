// extracted by mini-css-extract-plugin
export var additionalAction = "messenger-module--additional-action--I2gCZ";
export var additionalActionText = "messenger-module--additional-action-text--EkAX3";
export var additionalActionsWrapper = "messenger-module--additional-actions-wrapper--3EzIl";
export var form = "messenger-module--form--eiyV6";
export var input = "messenger-module--input--xHlJe";
export var loading = "messenger-module--loading--8dLX1";
export var paperClip = "messenger-module--paper-clip--ZtcoU";
export var submitButton = "messenger-module--submit-button--rJsDF";
export var submitButtonHolder = "messenger-module--submit-button-holder--5Wt89";
export var textarea = "messenger-module--textarea--HNAV0";