import { config } from '../../config';

import {
    SHOPPING_LISTS,
    types,
    setShoppingLists,
    restoreInitialState,
} from './shopping-lists.actions';
import { statusIdle } from '../ui/ui.actions';

const {
    GET_SHOPPING_LISTS,
    FETCH_SHOPPING_LISTS_REQUEST,
    FETCH_SHOPPING_LISTS_SUCCESS,
    FETCH_SHOPPING_LISTS_FAIL,
    CLEAR_SHOPPING_LISTS,
} = types;

const shoppingListsMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload } = action;
    next(action);

    switch (type) {
        case GET_SHOPPING_LISTS:
            const fetchShoppingLists = {
                types: [
                    FETCH_SHOPPING_LISTS_REQUEST,
                    FETCH_SHOPPING_LISTS_SUCCESS,
                    FETCH_SHOPPING_LISTS_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.shoppingLists.all(
                            getState().profile.currentId,
                            payload.page,
                            payload.perPage
                        ),
                        withCredentials: true,
                    },
                },
                meta: { entity: SHOPPING_LISTS },
            };
            dispatch(fetchShoppingLists);
            break;

        case FETCH_SHOPPING_LISTS_SUCCESS:
            dispatch(setShoppingLists(payload.data));
            break;

        case CLEAR_SHOPPING_LISTS:
            dispatch(statusIdle(SHOPPING_LISTS));
            dispatch(restoreInitialState());
            break;

        default:
            break;
    }
};

export default shoppingListsMiddleware;
