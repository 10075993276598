import { config } from '../../config';
import {
    types,
    FORUM_THREAD_CATEGORIES,
    FORUM_THREADS,
    FORUM_THREAD,
    setForumThreads,
    setForumThreadCategories,
    setForumThreadCategoriesFavorite,
    restoreForumInitialState,
    FORUM_THREADS_PINNED,
    setForumThreadsPinned,
    setForumThread,
    setForumComments,
    FORUM_COMMENTS,
    FORUM_THREAD_TOGGLE_FAVORITE,
    FORUM_COMMENT,
    clearForumComments,
    getForumCommentAnswers,
    setForumCommentsAnswers,
    FORUM_COMMENT_ANSWERS,
    FORUM_COMMENT_ANSWERS_PER_PAGE,
    setForumCommentsItems,
    restoreForumCommentsInitialState,
} from './forum.actions';
import { statusIdle } from '../ui/ui.actions';
import { getCurrentProfile } from '../profile/profile.selectors';
import { errorHandler, formatFormikErrors } from '../../communication/utils';
import { setAlert } from '../alerts/alerts.actions';
import { setSingleProfile } from '../profile/profile.actions';
import { selectForumComments } from './forum.selectors';

const {
    GET_FORUM_THREAD_CATEGORIES,
    SEND_FORUM_THREAD_CATEGORIES_REQUEST,
    SEND_FORUM_THREAD_CATEGORIES_SUCCESS,
    SEND_FORUM_THREAD_CATEGORIES_FAIL,
    GET_FORUM_THREAD_CATEGORIES_FAVORITE,
    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_REQUEST,
    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_SUCCESS,
    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_FAIL,
    CLEAR_FORUM,
    GET_FORUM_THREADS,
    SEND_FORUM_THREADS_REQUEST,
    SEND_FORUM_THREADS_SUCCESS,
    SEND_FORUM_THREADS_FAIL,
    GET_FORUM_THREADS_PINNED,
    SEND_FORUM_THREADS_PINNED_REQUEST,
    SEND_FORUM_THREADS_PINNED_SUCCESS,
    SEND_FORUM_THREADS_PINNED_FAIL,
    GET_FORUM_THREAD,
    SEND_FORUM_THREAD_REQUEST,
    SEND_FORUM_THREAD_SUCCESS,
    SEND_FORUM_THREAD_FAIL,
    TOGGLE_FORUM_THREAD_FAVORITE,
    SEND_TOGGLE_FORUM_THREAD_FAVORITE_REQUEST,
    SEND_TOGGLE_FORUM_THREAD_FAVORITE_SUCCESS,
    SEND_TOGGLE_FORUM_THREAD_FAVORITE_FAIL,
    GET_FORUM_COMMENTS,
    SEND_FORUM_COMMENTS_REQUEST,
    SEND_FORUM_COMMENTS_SUCCESS,
    SEND_FORUM_COMMENTS_FAIL,
    CREATE_FORUM_COMMENT,
    POST_FORUM_COMMENT_REQUEST,
    POST_FORUM_COMMENT_SUCCESS,
    POST_FORUM_COMMENT_FAIL,
    GET_FORUM_COMMENT_ANSWERS,
    SEND_FORUM_COMMENT_ANSWERS_REQUEST,
    SEND_FORUM_COMMENT_ANSWERS_SUCCESS,
    SEND_FORUM_COMMENT_ANSWERS_FAIL,
    UPDATE_FORUM_COMMENT,
    PATCH_FORUM_COMMENT_REQUEST,
    PATCH_FORUM_COMMENT_SUCCESS,
    PATCH_FORUM_COMMENT_FAIL,
    DELETE_FORUM_COMMENT,
    DELETE_FORUM_COMMENT_REQUEST,
    DELETE_FORUM_COMMENT_SUCCESS,
    DELETE_FORUM_COMMENT_FAIL,
    REPORT_FORUM_COMMENT,
    REPORT_FORUM_COMMENT_REQUEST,
    REPORT_FORUM_COMMENT_SUCCESS,
    REPORT_FORUM_COMMENT_FAIL,
    CLEAR_FORUM_COMMENTS,
} = types;

const { formsStatusMap } = config;

const forumMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const profile = getCurrentProfile(getState());
    const profileId = profile?.profileId;
    const comments = selectForumComments(getState());
    const { type, payload, meta } = action;
    const error = (action.error && errorHandler(action.error)) || [];
    next(action);

    switch (type) {
        case GET_FORUM_THREAD_CATEGORIES:
            dispatch({
                types: [
                    SEND_FORUM_THREAD_CATEGORIES_REQUEST,
                    SEND_FORUM_THREAD_CATEGORIES_SUCCESS,
                    SEND_FORUM_THREAD_CATEGORIES_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.forum.threadCategories,
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: FORUM_THREAD_CATEGORIES,
                },
            });
            break;

        case SEND_FORUM_THREAD_CATEGORIES_SUCCESS:
            dispatch(setForumThreadCategories(payload.data));
            break;

        case GET_FORUM_THREAD_CATEGORIES_FAVORITE:
            dispatch({
                types: [
                    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_REQUEST,
                    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_SUCCESS,
                    SEND_FORUM_THREAD_CATEGORIES_FAVORITE_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.forum.threadCategoriesFavorite(profileId),
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: FORUM_THREAD_CATEGORIES,
                },
            });
            break;

        case SEND_FORUM_THREAD_CATEGORIES_FAVORITE_SUCCESS:
            dispatch(setForumThreadCategoriesFavorite(payload.data));
            break;

        case GET_FORUM_THREADS:
            dispatch({
                types: [
                    SEND_FORUM_THREADS_REQUEST,
                    SEND_FORUM_THREADS_SUCCESS,
                    SEND_FORUM_THREADS_FAIL,
                ],
                payload: {
                    request: {
                        url: meta.isFavorite
                            ? config.endpoints.forum.threadsFavorite({ ...payload, profileId })
                            : config.endpoints.forum.threads(payload),
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: FORUM_THREADS,
                },
            });
            break;

        case SEND_FORUM_THREADS_SUCCESS:
            dispatch(setForumThreads(payload.data));
            break;

        case GET_FORUM_THREADS_PINNED:
            dispatch({
                types: [
                    SEND_FORUM_THREADS_PINNED_REQUEST,
                    SEND_FORUM_THREADS_PINNED_SUCCESS,
                    SEND_FORUM_THREADS_PINNED_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.forum.threadsPinned(payload),
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: FORUM_THREADS_PINNED,
                },
            });
            break;

        case SEND_FORUM_THREADS_PINNED_SUCCESS:
            dispatch(setForumThreadsPinned(payload.data));
            break;

        case GET_FORUM_THREAD:
            dispatch({
                types: [
                    SEND_FORUM_THREAD_REQUEST,
                    SEND_FORUM_THREAD_SUCCESS,
                    SEND_FORUM_THREAD_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.forum.thread(payload),
                        withCredentials: true,
                        params: {
                            expand: 'categories',
                        },
                    },
                },
                meta: {
                    entity: FORUM_THREAD,
                    payload,
                },
            });
            break;

        case SEND_FORUM_THREAD_SUCCESS:
            dispatch(setForumThread(payload.data));
            break;

        case TOGGLE_FORUM_THREAD_FAVORITE:
            dispatch({
                types: [
                    SEND_TOGGLE_FORUM_THREAD_FAVORITE_REQUEST,
                    SEND_TOGGLE_FORUM_THREAD_FAVORITE_SUCCESS,
                    SEND_TOGGLE_FORUM_THREAD_FAVORITE_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.forum.threadToggleFavorite({
                            threadId: payload,
                            profileId,
                        }),
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: FORUM_THREAD_TOGGLE_FAVORITE,
                    threadId: payload,
                },
            });
            break;

        case SEND_TOGGLE_FORUM_THREAD_FAVORITE_SUCCESS:
            const threadId = meta.previousAction.meta?.threadId;
            if (threadId) {
                const wasInFavorite = profile.favouriteGroupThreadsIds.includes(threadId);
                dispatch(
                    setSingleProfile({
                        ...profile,
                        favouriteGroupThreadsIds: wasInFavorite
                            ? profile.favouriteGroupThreadsIds.filter((id) => id !== threadId)
                            : [...profile.favouriteGroupThreadsIds, threadId],
                    })
                );
            }
            break;

        case SEND_TOGGLE_FORUM_THREAD_FAVORITE_FAIL:
            error.map((err) => dispatch(setAlert(err, FORUM_THREAD_TOGGLE_FAVORITE)));
            break;

        case GET_FORUM_COMMENTS:
            dispatch({
                types: [
                    SEND_FORUM_COMMENTS_REQUEST,
                    SEND_FORUM_COMMENTS_SUCCESS,
                    SEND_FORUM_COMMENTS_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.forum.comments(payload),
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: FORUM_COMMENTS,
                },
            });
            break;

        case SEND_FORUM_COMMENTS_SUCCESS:
            dispatch(setForumComments(payload.data));
            break;

        case CREATE_FORUM_COMMENT:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    POST_FORUM_COMMENT_REQUEST,
                    POST_FORUM_COMMENT_SUCCESS,
                    POST_FORUM_COMMENT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.forum.commentCreate(meta.threadId),
                        data: {
                            ...payload,
                            profileId,
                        },
                        withCredentials: true,
                    },
                },
                meta: { ...meta, entity: FORUM_COMMENT },
            });
            break;

        case POST_FORUM_COMMENT_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            meta.previousAction?.meta?.elementToScroll?.scrollIntoView({ block: 'start' });
            if (!payload.data.parentCommentId) {
                dispatch(clearForumComments());
            }
            if (payload.data.parentCommentId) {
                dispatch(
                    getForumCommentAnswers({
                        page: 1,
                        perPage: FORUM_COMMENT_ANSWERS_PER_PAGE,
                        threadId: meta.previousAction.meta.threadId,
                        commentId: payload.data.parentCommentId,
                        isAfterCreate: true,
                    })
                );
            }
            break;

        case POST_FORUM_COMMENT_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case UPDATE_FORUM_COMMENT:
            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch({
                types: [
                    PATCH_FORUM_COMMENT_REQUEST,
                    PATCH_FORUM_COMMENT_SUCCESS,
                    PATCH_FORUM_COMMENT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'patch',
                        url: config.endpoints.forum.commentUpdate({
                            threadId: meta.threadId,
                            commentId: meta.commentId,
                        }),
                        data: {
                            ...payload,
                            profileId,
                        },
                        withCredentials: true,
                    },
                },
                meta: { ...meta, entity: FORUM_COMMENT },
            });
            break;

        case PATCH_FORUM_COMMENT_SUCCESS:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.success);
            if (!payload.data.parentCommentId) {
                dispatch(
                    setForumCommentsItems(
                        comments.items.map((comment) => {
                            if (payload.data.commentId === comment.commentId) {
                                return { ...payload.data };
                            }
                            return comment;
                        })
                    )
                );
            }
            if (payload.data.parentCommentId) {
                dispatch(
                    setForumCommentsItems(
                        comments.items.map((comment) => {
                            if (payload.data.parentCommentId === comment.commentId) {
                                return {
                                    ...comment,
                                    answers: comment.answers.map((answer) => {
                                        if (answer.commentId === payload.data.commentId) {
                                            return { ...payload.data };
                                        }
                                        return answer;
                                    }),
                                };
                            }
                            return comment;
                        })
                    )
                );
            }
            break;

        case PATCH_FORUM_COMMENT_FAIL:
            meta.previousAction?.meta?.formikBag?.setStatus(formsStatusMap.fail);
            meta.previousAction?.meta?.formikBag?.setErrors(formatFormikErrors(action.error));
            break;

        case DELETE_FORUM_COMMENT:
            dispatch({
                types: [
                    DELETE_FORUM_COMMENT_REQUEST,
                    DELETE_FORUM_COMMENT_SUCCESS,
                    DELETE_FORUM_COMMENT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'delete',
                        url: config.endpoints.forum.commentDelete({
                            threadId: payload.threadId,
                            commentId: payload.commentId,
                        }),
                        withCredentials: true,
                    },
                },
                meta: { ...meta, entity: FORUM_COMMENT, commentId: payload.commentId },
            });
            break;

        case DELETE_FORUM_COMMENT_SUCCESS:
            const deletedCommentId = meta?.previousAction?.meta?.commentId;
            if (deletedCommentId) {
                dispatch(
                    setForumCommentsItems(
                        comments.items.map((comment) => {
                            if (deletedCommentId === comment.commentId) {
                                return { ...comment, status: 'deleted-by-user' };
                            }
                            return {
                                ...comment,
                                answers: comment.answers.map((answer) => {
                                    if (answer.commentId === deletedCommentId) {
                                        return { ...answer, status: 'deleted-by-user' };
                                    }
                                    return answer;
                                }),
                            };
                        })
                    )
                );
            }
            break;

        case DELETE_FORUM_COMMENT_FAIL:
            error.map((err) => dispatch(setAlert(err, FORUM_COMMENT)));
            break;

        case REPORT_FORUM_COMMENT:
            dispatch({
                types: [
                    REPORT_FORUM_COMMENT_REQUEST,
                    REPORT_FORUM_COMMENT_SUCCESS,
                    REPORT_FORUM_COMMENT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: config.endpoints.forum.commentReport(payload),
                        data: { profileId },
                        withCredentials: true,
                    },
                },
                meta: { ...meta, entity: FORUM_COMMENT },
            });
            break;

        case REPORT_FORUM_COMMENT_SUCCESS:
            dispatch(
                setAlert(
                    { type: 'success', content: 'Komentarz został zgłoszony do administratora.' },
                    FORUM_COMMENT
                )
            );
            break;

        case REPORT_FORUM_COMMENT_FAIL:
            error.map((err) => dispatch(setAlert(err, FORUM_COMMENT)));
            break;

        case CLEAR_FORUM_COMMENTS:
            dispatch(statusIdle(FORUM_COMMENT));
            dispatch(statusIdle(FORUM_COMMENT_ANSWERS));
            dispatch(statusIdle(FORUM_COMMENTS));
            dispatch(restoreForumCommentsInitialState());
            break;

        case CLEAR_FORUM:
            dispatch(statusIdle(FORUM_THREAD_CATEGORIES));
            dispatch(statusIdle(FORUM_THREADS));
            dispatch(statusIdle(FORUM_THREADS_PINNED));
            dispatch(statusIdle(FORUM_THREAD));
            dispatch(restoreForumInitialState());
            break;

        case GET_FORUM_COMMENT_ANSWERS:
            dispatch({
                types: [
                    SEND_FORUM_COMMENT_ANSWERS_REQUEST,
                    SEND_FORUM_COMMENT_ANSWERS_SUCCESS,
                    SEND_FORUM_COMMENT_ANSWERS_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.forum.commentAnswers(payload),
                        withCredentials: true,
                    },
                },
                meta: {
                    ...meta,
                    entity: FORUM_COMMENT_ANSWERS,
                    isAfterCreate: payload.isAfterCreate,
                },
            });
            break;

        case SEND_FORUM_COMMENT_ANSWERS_SUCCESS:
            const answersParentId = payload.data.items[0]?.parentCommentId;
            const isAfterCreate = meta.previousAction?.meta?.isAfterCreate;
            if (answersParentId) {
                dispatch(
                    setForumCommentsAnswers(
                        comments.items.map((comment) => {
                            if (comment.commentId !== answersParentId) return comment;
                            let newAnswers;
                            if (isAfterCreate) {
                                newAnswers = [payload.data.items[0]];
                            } else if (payload.data.pagination.currentPage === 1) {
                                newAnswers = [...comment.answers, ...payload.data.items.slice(1)];
                            } else {
                                newAnswers = [...comment.answers, ...payload.data.items];
                            }
                            return {
                                ...comment,
                                answers: newAnswers,
                                answersCount: payload.data.pagination.totalCount,
                            };
                        })
                    )
                );
            }
            break;

        default:
            break;
    }
};

export default forumMiddleware;
