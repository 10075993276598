import React from 'react';

import { link } from './link-underline.module.scss';

const LinkUnderline = ({ Tag = 'div', className = '', children, ...rest }) => {
    return (
        <Tag className={`${link} ${className}`} {...rest}>
            {children}
        </Tag>
    );
};

export default LinkUnderline;
