import { useEffect, useRef, useState } from 'react';

type UseIntersectReturnType<NodeType extends HTMLElement> = [
    (node: NodeType) => void,
    IntersectionObserverEntry | undefined
];

function useIntersect<NodeType extends HTMLElement>({
    root = null,
    rootMargin,
    threshold = 0,
}: IntersectionObserverInit): UseIntersectReturnType<NodeType> {
    const [entry, updateEntry] = useState<IntersectionObserverEntry>();
    const [node, setNode] = useState<NodeType | null>(null);

    const observer = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new window.IntersectionObserver(([entry]) => updateEntry(entry), {
            root,
            rootMargin,
            threshold,
        });

        const { current: currentObserver } = observer;

        if (node) currentObserver.observe(node);

        return () => currentObserver.disconnect();
    }, [node, root, rootMargin, threshold]);

    return [(node) => setNode(node), entry];
}

export default useIntersect;
