import { combineReducers } from 'redux';
import { genericListReducer } from '../generic-list/generic-list.reducer';

export const EXERCISE_ATLAS = '[Exercise_atlas]';
export const LIST = 'list';

const exerciseAtlas = combineReducers({
    [LIST]: genericListReducer(EXERCISE_ATLAS, LIST),
    // add more reducers for subEntities if needed
});

export default exerciseAtlas;
