import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import PropTypes from 'prop-types';

import {
    form,
    eraseButton,
    preview as previewClass,
    globalErrors,
    survey,
    stepsContainer,
    successBox,
    submitContainer,
} from './survey.module.scss';
import { initialValues, validationSchemas } from './survey-config';
import { getAbsolutePath } from '../../../routes';
import { config } from '../../../config';
import envelopeSrc from '../../../assets/images/envelope-with-arrow.png';
import { getCurrentProfileId } from '../../../redux/profile/profile.selectors';
import { types } from '../../../redux/profile/profile.actions';
import { selectSurvey, selectSurveyStatus } from '../../../redux/survey/survey.selectors';
import {
    getSurveyAction,
    validateSurveyAction,
    createSurveyAction,
    setSurveyStepAction,
    clearSurveyAction,
} from '../../../redux/survey/survey.actions';

import StepBar from '../../molecules/step-bar';
import Loader from '../../atoms/loader';
import FormGeneralInfo from './form-general-info';
import FormDiet from './form-diet';
import FormTraining from './form-training';
import FormAdditionalInfo from './form-additional-info';
import Button from '../../atoms/button';
import SuccessMessage from '../../molecules/success-message';

const surveySteps = [
    { label: 'Informacje ogólne' },
    { label: 'Odżywianie' },
    { label: 'Aktywność fizyczna' },
    { label: 'Dodatkowe informacje' },
    { label: 'Sukces!' },
];
const { formsStatusMap, apiStatusMap } = config;

const Survey = ({ preview, surveyToProfileId }) => {
    const dispatch = useDispatch();
    const { values, step } = useSelector(selectSurvey);
    const status = useSelector(selectSurveyStatus);
    const profileId = useSelector(getCurrentProfileId);

    const [initialFormValues, setInitialFormValues] = useState(initialValues);

    const surveyRef = useRef(null);

    const myAccountPath = getAbsolutePath('APP_MY_ACCOUNT');

    useEffect(() => {
        if (surveyToProfileId) {
            dispatch(getSurveyAction(surveyToProfileId));
        }
    }, [surveyToProfileId, profileId, dispatch]);

    useEffect(() => {
        dispatch({
            type: types.UPDATE_SINGLE_PROFILE,
            payload: {
                id: profileId,
                surveyFilledInSession: true,
            },
        });
    }, [dispatch, profileId]);

    useEffect(() => {
        if (values) {
            setInitialFormValues({
                // spreading initial values because after adding another
                // question to the database, the response may not have
                // the key for that question when survey is older
                ...initialValues,
                ...values,
            });
        }
    }, [values]);

    useEffect(() => {
        return () => {
            dispatch(clearSurveyAction());
        };
    }, [dispatch]);

    const onSubmit = async (formValues, formikBag) => {
        window.scrollTo(0, surveyRef.current.offsetTop);
        if (step === 3) {
            dispatch(createSurveyAction(formValues, formikBag));
        } else {
            dispatch(validateSurveyAction(formValues, formikBag));
        }
    };

    const handleFormReset = (formik) => {
        if (step > 3) return;
        setInitialFormValues(initialValues);
        dispatch(setSurveyStepAction(0));
        formik.resetForm();
    };

    const renderFormContent = (formik) => {
        if (preview) {
            return (
                <>
                    <FormGeneralInfo />
                    <FormDiet formik={formik} />
                    <FormTraining formik={formik} />
                    <FormAdditionalInfo />
                </>
            );
        }
        switch (step) {
            case 0:
                return <FormGeneralInfo />;
            case 1:
                return <FormDiet formik={formik} />;
            case 2:
                return <FormTraining formik={formik} />;
            case 3:
                return <FormAdditionalInfo />;
            case 4:
                return null;
            case 5:
                return (
                    <div className={successBox}>
                        <SuccessMessage
                            imgSrc={envelopeSrc}
                            title="Twoja ankieta została pomyślnie wysłana!"
                            to={myAccountPath}
                            buttonText="Idź do Moje Konto"
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    if (status === apiStatusMap.fail) {
        return <p>Coś poszło nie tak, nie udało się pobrać ankiety...</p>;
    }

    if (surveyToProfileId && !values) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchemas[step] ? validationSchemas[step] : {}}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formik) => {
                return (
                    <div
                        className={`
                            ${survey}
                            ${preview ? previewClass : ''}
                        `}
                        ref={surveyRef}
                    >
                        {!preview && (
                            <>
                                <button
                                    type="button"
                                    className={eraseButton}
                                    onClick={() => handleFormReset(formik)}
                                >
                                    Wyczyść wszystkie pola
                                </button>
                                <div className={stepsContainer}>
                                    <StepBar steps={surveySteps} activeStep={step} />
                                </div>
                            </>
                        )}

                        {formik.errors.global && (
                            <div className={globalErrors}>{formik.errors.global}</div>
                        )}

                        {formik.status === formsStatusMap.loading ? (
                            <Loader />
                        ) : (
                            <Form className={form}>
                                {renderFormContent(formik)}

                                {!preview && step < 4 && (
                                    <div className={submitContainer}>
                                        <Button color="yellow">
                                            {step < 3
                                                ? `Przejdź do kolejnego kroku`
                                                : `Wyślij ankietę`}
                                        </Button>
                                    </div>
                                )}

                                <FormikErrorFocus offset={100} duration={200} focusDelay={1000} />
                            </Form>
                        )}
                    </div>
                );
            }}
        </Formik>
    );
};

Survey.propTypes = {
    preview: PropTypes.bool,
    surveyToProfileId: PropTypes.number,
};

export default Survey;
