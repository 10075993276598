import React from 'react';
import { wrapper, dot, dotWrapper, blue, orange } from './dot.module.scss';

const Dot = ({ color = 'orange', children }) => {
    return (
        <div className={`${wrapper} ${colorClasses[color]}`}>
            <div className={dotWrapper}>
                <div className={dot} />
            </div>
            <div>{children}</div>
        </div>
    );
};

const colorClasses = {
    blue: blue,
    orange: orange,
};

export default Dot;
