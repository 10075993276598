import React from 'react';
import PropTypes from 'prop-types';

import { dot, option } from './colored-select-option.module.scss';

const ColoredSelectOption = ({ label, colorClass = '' }) => {
    return (
        <div className={option}>
            <span className={`${dot} ${colorClass}`} />
            {label}
        </div>
    );
};

ColoredSelectOption.propTypes = {
    label: PropTypes.string.isRequired,
    colorClass: PropTypes.string.isRequired,
};

export default ColoredSelectOption;
