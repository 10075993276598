import React from 'react';
import PropTypes from 'prop-types';

import { modal, closeButton } from './video-modal.module.scss';

import VideoPlayer from '../../molecules/video-player';
import ButtonCloseModal from '../../atoms/button-close-modal';

const VideoModal = ({
    modalId,
    coverUrl,
    videoUrl,
    autoplay,
    watchedMark,
    onWatched,
    followProgress,
}) => {
    return (
        <div className={modal}>
            <ButtonCloseModal modalId={modalId} className={closeButton} />
            <VideoPlayer
                coverUrl={coverUrl}
                videoUrl={videoUrl}
                autoplay={autoplay}
                watchedMark={watchedMark}
                onWatched={onWatched}
                followProgress={followProgress}
            />
        </div>
    );
};

VideoModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    coverUrl: PropTypes.string,
    videoUrl: PropTypes.string,
    autoplay: PropTypes.bool,
    watchedMark: PropTypes.number,
    onWatched: PropTypes.func,
    followProgress: PropTypes.bool,
};

VideoModal.defaultProps = {
    coverUrl: '',
    videoUrl: '',
    autoplay: true,
    watchedMark: 0.5,
    onWatched: () => {},
    followProgress: false,
};

export default VideoModal;
