// extracted by mini-css-extract-plugin
export var access = "thread-card-module--access--eutS+";
export var author = "thread-card-module--author--Qt60e";
export var comment = "thread-card-module--comment--IAfT9";
export var container = "thread-card-module--container--sp4UP";
export var dot = "thread-card-module--dot--iOuNy";
export var footer = "thread-card-module--footer--qSNFV";
export var header = "thread-card-module--header--CDZpq";
export var locked = "thread-card-module--locked--TH4wd";
export var meta = "thread-card-module--meta--BbAjm";
export var pin = "thread-card-module--pin--B5A34";
export var publicThread = "thread-card-module--public-thread--kNz2k";
export var title = "thread-card-module--title--7JVnc";
export var unlocked = "thread-card-module--unlocked--X9qfQ";