import { createSingleRecipe } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';
import { config } from '../../../../config';
import { formatFormikErrors } from '../../../../communication/utils';
import { clearYourRecipesAction } from '../../your/actions/clear-your-recipes';

export const createSingleRecipeAction = (data, formikBag) => async (dispatch) => {
    formikBag.setStatus(config.formsStatusMap.loading);
    dispatch(createSingleRecipeStartAction());
    try {
        await createSingleRecipe(data);
        formikBag.setStatus(config.formsStatusMap.success);
        dispatch(createSingleRecipeSuccessAction());
        dispatch(clearYourRecipesAction());
    } catch (error) {
        formikBag.setStatus(config.formsStatusMap.fail);
        formikBag.setErrors(formatFormikErrors(error));
        dispatch(createSingleRecipeErrorAction(error));
        return config.formsStatusMap.fail;
    }
};

export const createSingleRecipeClearSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.create,
        entitiesMap.recipes.single.allowedActions.clearSuccess
    ),
});

export const createSingleRecipeClearErrorsAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.create,
        entitiesMap.recipes.single.allowedActions.clearErrors
    ),
});

const createSingleRecipeStartAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.create,
        entitiesMap.recipes.single.allowedActions.start
    ),
});

const createSingleRecipeSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.create,
        entitiesMap.recipes.single.allowedActions.success
    ),
    payload: {},
});

const createSingleRecipeErrorAction = (error) => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.create,
        entitiesMap.recipes.single.allowedActions.error
    ),
    payload: error,
});
