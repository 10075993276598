import React from 'react';
import { useDispatch } from 'react-redux';

import { config } from '../../config';
import { updateProfile } from '../../redux/profile/profile.actions';
import { addModalAction } from '../../redux/actions/actions-modals';

import FileInputArea from '../atoms/form-poc/file-input-area';

const AvatarChange = ({ children, name }) => {
    const dispatch = useDispatch();
    const handleChange = (result) => {
        if (result.uploadErrors.length > 0) {
            result.uploadErrors.forEach((error) => {
                dispatch(
                    addModalAction({
                        type: 'error',
                        title: 'Nie udało się dodać pliku!',
                        content: error,
                        buttonText: 'Ok, rozumiem',
                    })
                );
            });
            return;
        }
        const payload = { avatarBase64: result.uploadedFiles[0].content };
        dispatch(updateProfile(payload));
    };

    return (
        <FileInputArea fileTypes={config.fileTypesMap.images} name={name} onChange={handleChange}>
            {children}
        </FileInputArea>
    );
};

export default AvatarChange;
