import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import { content, container, actions, date, deleteButton } from './recipe-note.module.scss';
import TrashIcon from '../../../assets/images/svg/icon-trash.svg';

const RecipeNote = ({ id, unitId, note, className, onNoteDelete }) => {
    const handleTrashClick = () => {
        onNoteDelete({ positionId: id, id: note.id, unitId: unitId });
    };

    return (
        <div className={`${container} ${className}`}>
            <p className={content}>{note.content}</p>
            <div className={actions}>
                <p className={date}>
                    Dodano:{` `}
                    <Moment unix={true} format="HH:MM • DD MMMM" locale="pl">
                        {note.updatedAt}
                    </Moment>
                </p>
                <button onClick={handleTrashClick} className={deleteButton}>
                    <TrashIcon /> <span>Usuń komentarz</span>
                </button>
            </div>
        </div>
    );
};

RecipeNote.propTypes = {
    note: PropTypes.shape({
        id: PropTypes.number,
        content: PropTypes.string,
        createdAt: PropTypes.number,
    }).isRequired,
    positionId: PropTypes.number,
    className: PropTypes.string,
    onNoteDelete: PropTypes.func,
};

RecipeNote.defaultProps = {
    id: null,
    className: '',
    onNoteDelete: () => {},
};

export default RecipeNote;
