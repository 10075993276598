import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
    title,
    form,
    success,
    container,
    header,
    loading,
    submitButton,
    successText,
    textarea,
    comments,
    noComments,
    disabledContent,
} from './comments.module.scss';
import SuccessEmotics from '../../assets/images/svg/success-emotics.svg';
import { config } from '../../config';

import Separator from '../atoms/separator';
import Button from '../atoms/button';
import Input from '../atoms/form-poc/input';
import CommentItem from '../molecules/comment-item';
import Emotic from '../atoms/emotic';

const {
    form: { required },
} = config.messages;

const initialValues = {
    content: '',
};

const validationSchema = Yup.object({
    content: Yup.string().required(required),
});

const Comments = ({ className, onSubmit, items, disabled, disabledElement }) => {
    const handleSubmit = (formValues, formikBag) => {
        if (typeof onSubmit === 'function') {
            onSubmit(formValues, formikBag);
        }
    };

    return (
        <div className={`${className} ${container}`}>
            <div className={header}>
                <h2 className={title}>Komentarze</h2>
                <Separator />
            </div>
            {disabled ? (
                <div className={disabledContent}>{disabledElement}</div>
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {(formik) => (
                        <Form
                            className={`${form} 
                            ${formik.status === config.formsStatusMap.loading ? loading : ''}`}
                        >
                            {formik.status === config.formsStatusMap.success ? (
                                <div className={success}>
                                    <Emotic emotic={SuccessEmotics} />
                                    <p className={successText}>Dziękujemy za Twój komentarz!</p>
                                </div>
                            ) : (
                                <>
                                    <Input
                                        as="textarea"
                                        label="Twój komentarz"
                                        name="content"
                                        containerClass={textarea}
                                        rows={3}
                                    />
                                    <Button color="yellow" className={submitButton}>
                                        Zapisz
                                    </Button>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            )}
            <div className={comments}>
                {items && items.length > 0 ? (
                    items
                        .sort((a, b) => a.createdAt - b.createdAt)
                        .map((item) => {
                            return (
                                <CommentItem
                                    comment={item}
                                    key={`comment-item-${item.commentId}`}
                                />
                            );
                        })
                ) : !disabled ? (
                    <p className={noComments}>
                        Nie dodano jeszcze żadnego komentarza, Twój komentarz może być pierwszy!
                    </p>
                ) : null}
            </div>
        </div>
    );
};

Comments.propTypes = {
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    disabled: PropTypes.bool,
    disabledElement: PropTypes.node,
};

Comments.defaultProps = {
    className: '',
    onSubmit: null,
    disabled: false,
    disabledElement: 'Nie masz uprawnień do dodawania komentarzy',
};

export default Comments;
