import React from 'react';
import { useSelector } from 'react-redux';

import { container } from './meal-day-scroller.module.scss';
import { cssDateVar, generateDatesArrayFromState } from '../../../utills/meal-planer';

import ScrollToDay from '../../atoms/meal-planner/planer-scroll-to-day-btn';

const DayScroller = ({ className = '' }) => {
    const dates = useSelector((state) => generateDatesArrayFromState(state));
    return (
        <div className={`${container} ${className}`}>
            {Array.isArray(dates) &&
                dates.map((day) => {
                    return <ScrollToDay key={`btn-${cssDateVar(day)}`} day={day} />;
                })}
        </div>
    );
};

export default DayScroller;
