import React from 'react';
import { useSelector } from 'react-redux';

import {
    bold,
    pink,
    bigListStyle,
    countHolder,
    countLine,
} from './shop-cart-summary-price.module.scss';
import { selectCart } from '../../../redux/shop/cart/cart.selectors';
import { DISCOUNTS_ENABLE } from '../../../hooks/use-product-discounts';

import Separator from '../separator';

export const ShopCartSummaryPrice = ({ isDropdownStyle = true }) => {
    const cart = useSelector(selectCart) || null;

    const cssClass = `${countHolder} ${isDropdownStyle ? '' : bigListStyle}`;

    const { price, benefitPrice, basePrice } = getPrices(cart);

    return (
        <div className={cssClass}>
            {!isDropdownStyle && (
                <div className={countLine}>
                    <p>Produkty</p>
                    <p>{basePrice}</p>
                </div>
            )}
            {!isDropdownStyle && <Separator />}
            {price && (
                <>
                    <div className={`${countLine} ${bold}`}>
                        <p>Razem</p>
                        <p>{price}</p>
                    </div>
                    {DISCOUNTS_ENABLE && benefitPrice !== null && (
                        <div className={`${countLine} ${pink}`}>
                            <p>Oszczędzasz</p>
                            <p>{benefitPrice}</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

function getPrices(cart) {
    const price = cart?.installmentPrice ? cart.installmentPrice : cart?.price || '';
    const basePrice = cart?.installmentBasePrice
        ? cart.installmentBasePrice
        : cart?.basePrice || '';

    const benefitPrice = basePrice && price ? basePrice.gross - price.gross : 0;

    if (benefitPrice === 0) {
        return {
            price: price.grossDisplay,
            basePrice: basePrice.grossDisplay,
            benefitPrice: null,
        };
    }

    return {
        price: price.grossDisplay,
        basePrice: basePrice.grossDisplay,
        benefitPrice: `${benefitPrice.toFixed(2)} zł`,
    };
}

export default ShopCartSummaryPrice;
