import { actions } from '../additional-attachments-to-send/actions';

const initialState = {
    data: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_DATA:
            const res = state.data;
            action.payload.forEach((item) => res.push(item));

            return {
                ...state,
                data: res,
            };

        case actions.REMOVE_PILL:
            const response = state.data;
            response.splice(action.payload, 1);
            return {
                ...state,
                data: response,
            };

        case actions.CLEAR_PILLS:
            return {
                ...state,
                data: [],
            };
        default:
            return state;
    }
}
