import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
    videoButton,
    imageWrapper,
    playButton,
    image as imageClass,
    header,
    defaultRatioClass,
} from './video-post-preview.module.scss';
import VideoPlayIcon from '../../assets/images/svg/video-play.svg';
import { addModalAction } from '../../redux/actions/actions-modals';

import ExerciseMetadata from './exercise/exercise-metadata';
import VideoModal from '../organisms/custom-modals/video-modal';

const VideoPostPreview = ({
    className,
    videoUrl,
    image,
    title,
    imageUrl,
    exercise,
    link,
    autoplay,
    watchedMark,
    onWatched,
    followProgress,
    isDisabled,
    ratioClass,
}) => {
    const Tag = link ? Link : 'button';

    const dispatch = useDispatch();

    const aspectRatioClass = ratioClass || defaultRatioClass;

    const handlePlayClick = () => {
        if (isDisabled) return;
        dispatch(
            addModalAction({
                fullWidth: true,
                renderComponent: (modalId) => (
                    <VideoModal
                        modalId={modalId}
                        videoUrl={videoUrl}
                        coverUrl={imageUrl}
                        autoplay={autoplay}
                        watchedMark={watchedMark}
                        onWatched={onWatched}
                        followProgress={followProgress}
                    />
                ),
            })
        );
    };

    return (
        <Tag
            {...(link ? { to: link } : { onClick: handlePlayClick })}
            className={`${link ? '' : videoButton} ${className}`}
        >
            <div className={imageWrapper}>
                <VideoPlayIcon className={playButton} />
                {/* TODO: Gatsby Image - consider change to RatioImage */}
                {image ? (
                    <GatsbyImage image={getImage(image)} alt={title} />
                ) : (
                    <div className={aspectRatioClass}>
                        <img className={imageClass} src={imageUrl} alt={title} />
                    </div>
                )}
            </div>
            <ExerciseMetadata exercise={exercise} color="grey" />
            <h3 className={header}>{title}</h3>
        </Tag>
    );
};

VideoPostPreview.propTypes = {
    className: PropTypes.string,
    videoUrl: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    exercise: PropTypes.object,
    link: PropTypes.string,
    autoplay: PropTypes.bool,
    watchedMark: PropTypes.number,
    onWatched: PropTypes.func,
    followProgress: PropTypes.bool,
    isDisabled: PropTypes.bool,
    ratioClass: PropTypes.string,
};

VideoPostPreview.defaultProps = {
    className: '',
    videoUrl: '',
    image: null,
    title: '',
    imageUrl: '',
    exercise: {},
    link: '',
    autoplay: true,
    watchedMark: 0.5,
    followProgress: false,
    isDisabled: false,
    ratioClass: '',
};

export default VideoPostPreview;
