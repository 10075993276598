import React from 'react';
import { Link } from 'gatsby';

import { link, active } from './nav-item.module.scss';

const NavItem = ({ to, children }) => (
    <Link to={to} className={link} activeClassName={active}>
        {children}
    </Link>
);

export default NavItem;
