// extracted by mini-css-extract-plugin
export var actionsDesktop = "recipe-header-module--actions-desktop--CwdJp";
export var actionsMobile = "recipe-header-module--actions-mobile--EN-Sc";
export var container = "recipe-header-module--container---TBC8";
export var counterBox = "recipe-header-module--counter-box--z7YrP";
export var counterLabel = "recipe-header-module--counter-label--4R9si";
export var dotsMore = "recipe-header-module--dots-more--BLFef";
export var dropdown = "recipe-header-module--dropdown--CwZiv";
export var dropdownButton = "recipe-header-module--dropdown-button--JmiBa";
export var editLoading = "recipe-header-module--edit-loading--661gP";
export var listButton = "recipe-header-module--list-button--FIns2";
export var pdfButton = "recipe-header-module--pdf-button--iIvgk";
export var planButton = "recipe-header-module--plan-button--RU0he";