import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { list, banner } from './exercises.module.scss';
import { entity } from '../../../rbac/permissions';

import LinkBanner from '../../molecules/link-banner';
import List from '../../molecules/list/list';
import UserCan from '../../../rbac/hoc';

const Exercises = () => {
    const {
        exerciseAtlasImg,
        trainingAtlasImg,
        personalTrainingImg,
        challengesImg,
        trainingProgramsImg,
        coursesImg,
    } = useStaticQuery(query);

    const workoutAreas = [
        {
            id: 1,
            to: '/app/cwiczenia',
            description: 'Baza wszystkich ćwiczeń dostępnych na platformie Strefa Przemian.',
            image: getImage(exerciseAtlasImg),
            title: 'Atlas Ćwiczeń',
            className: banner,
        },
        {
            id: 2,
            to: '/app/treningi',
            description: 'Pełnometrażowe programy treningowe.',
            image: getImage(trainingAtlasImg),
            title: 'Atlas Treningów',
            className: banner,
        },
        {
            id: 3,
            to: '/app/trening-personalny',
            description: <div>Spersonalizowany plan treningowy.</div>,
            image: getImage(personalTrainingImg),
            title: 'Trening Personalny',
            className: banner,
            permissionEntity: entity.PERSONAL_TRAININGS,
            disabled: UserCan({
                action: entity.PERSONAL_TRAININGS,
                yes: () => false,
                no: () => true,
            }),
        },
        {
            id: 4,
            to: '/app/moje-konto/strefa-rozwoju/wyzwania',
            description:
                'Tu odkryjesz poprzednie, aktualne i nadchodzące wyzwania, które systematycznie organizujemy dla naszej społeczności.',
            image: getImage(challengesImg),
            title: 'Wyzwania',
            className: banner,
        },
        {
            id: 5,
            to: '/app/moje-konto/strefa-rozwoju/programy-treningowe',
            description:
                'Spersonalizowane programy ćwiczeń dostosowane do Twoich potrzeb i poziomu zaawansowania.',
            image: getImage(trainingProgramsImg),
            title: 'Programy Treningowe',
            className: banner,
        },
        {
            id: 6,
            to: '/app/moje-konto/strefa-rozwoju/kursy',
            description:
                'Szeroki wybór szkoleń i materiałów edukacyjnych, które pomogą Ci rozwijać nowe umiejętności i wiedzę.',
            image: getImage(coursesImg),
            title: 'Kursy',
            className: banner,
        },
    ];

    return <List listClassName={list} ItemComponent={LinkBanner} items={workoutAreas} path={''} />;
};

const query = graphql`
    {
        exerciseAtlasImg: file(relativePath: { eq: "banner-atlas-cwiczen.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        trainingAtlasImg: file(relativePath: { eq: "banner-atlas-treningow.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        personalTrainingImg: file(relativePath: { eq: "banner-trening-personalny.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        challengesImg: file(relativePath: { eq: "banner-wyzwania.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        trainingProgramsImg: file(relativePath: { eq: "banner-programy-treningowe.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        coursesImg: file(relativePath: { eq: "banner-kursy.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
    }
`;

export default Exercises;
