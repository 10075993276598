import { permissionMap } from '../../rbac/permissions';
import { actions } from './rbac.actions';

export const initialState = permissionMap.User;

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_PERMISSIONS:
            return [...action.payload];

        case actions.CLEAR_PERMISSIONS:
            return initialState;

        default:
            return state;
    }
}
