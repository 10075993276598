import { combineReducers } from 'redux';

import currentCourse from './current-course.reducer';
import cleanEnhancer from '../../reducerEnhancers/cleanEnhancer';
import { types } from '../../user/user.actions';

const coursesReducer = combineReducers({
    currentCourse,
});

export default cleanEnhancer(coursesReducer, types.CLEAR_USER_DATA);
