import React from 'react';
import PropTypes from 'prop-types';

import {
    simpleView as simpleViewClass,
    stepItem,
    steps as stepsClass,
    stepList,
} from './recipe-preparation-steps.module.scss';
import {
    title,
    separator,
    simpleView as commonSimpleViewClass,
} from './common-recipe-molecules.module.scss';

import Separator from '../../atoms/separator';

const RecipePreparationSteps = ({ steps, className = '', simpleView }) => {
    return (
        <div
            className={`
                ${stepsClass}
                ${className}
                ${simpleView ? `${simpleViewClass} ${commonSimpleViewClass}` : ''}
            `}
        >
            <h2 className={title}>Sposób przygotowania</h2>
            <Separator className={separator} />
            <ol className={stepList}>
                {steps.map(({ content }, index) => (
                    <li key={`step-${index}`} className={stepItem}>
                        {content}
                    </li>
                ))}
            </ol>
        </div>
    );
};

RecipePreparationSteps.propTypes = {
    steps: PropTypes.array.isRequired,
    className: PropTypes.string,
    simpleView: PropTypes.bool,
};

RecipePreparationSteps.defaultProps = {
    className: '',
    simpleView: false,
};

export default RecipePreparationSteps;
