import React, { useEffect, useRef } from 'react';
import { ErrorMessage, useField } from 'formik';

import { input as inputBase } from '../../atoms/form/input.module.scss';
import {
    input,
    button,
    applied,
    inputWrapper,
    discountCodeInputWrapper,
    discountCodeInput,
    message,
    outlined,
} from './discount-code-input.module.scss';
import IconClose from '../../../assets/images/svg/icon-close.svg';
import ArrowRightShort from '../../../assets/images/svg/arrow-right-short.svg';

import DiscountLabel from '../../atoms/discount-label/discount-label';
import Button from '../../atoms/button';
import Input from '../../atoms/form-poc/input';
import TextError from '../../atoms/form-poc/text-error';

export default function DiscountCodeInput({ name, discountCode, ...rest }) {
    const [_, meta, helpers] = useField(name);
    const helpersRef = useRef(helpers);

    const isCodeApplied = discountCode?.length > 0;

    useEffect(() => {
        if (helpersRef.current) {
            helpersRef.current.setValue(discountCode);
        }
    }, [discountCode]);

    return (
        <div className={discountCodeInputWrapper}>
            <div className={`${inputBase} ${discountCodeInput} ${isCodeApplied ? outlined : ''}`}>
                <DiscountLabel label={isCodeApplied ? 'Kod rabatowy:' : 'Dodaj kod rabatowy:'} />
                <Input
                    inputClassname={`${input} ${isCodeApplied ? applied : ''}`}
                    containerClass={inputWrapper}
                    name={name}
                    showError={false}
                    {...rest}
                />
                <Button
                    color="yellow"
                    circle
                    disabled={meta.value?.length === 0}
                    className={button}
                    type={isCodeApplied ? 'reset' : 'submit'}
                >
                    {isCodeApplied ? <IconClose /> : <ArrowRightShort />}
                </Button>
            </div>
            <ErrorMessage name={name} component={TextError} />
            {isCodeApplied && <TextError className={message}>Zastosowano kod rabatowy</TextError>}
        </div>
    );
}
