import { actions, clearItems, genActionName, getItems } from './generic-list.actions';
import { prepareMultipleFilterParams } from './generic-list.utils';
import { clearAllAlerts, setAlert } from '../alerts/alerts.actions';
import { errorHandler } from '../../communication/utils';

const { document, api, command } = actions;

export const genericListMiddleware = (entity, subEntity, endpoint, expand = '') => (state) => (
    next
) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    next(action);

    let fetchConfig, params, data, error;
    const actionEntity = `${entity} ${subEntity}`;
    const listState = getState()[entity][subEntity];
    const { items, filters, sort, pagination } = listState;
    const { currentPage } = pagination;

    switch (type) {
        case genActionName(actionEntity, command.search):
            dispatch(clearItems(entity, subEntity));
            dispatch(
                getItems({
                    state: listState,
                    payload,
                    actionEntity,
                    params: {
                        page: 1,
                        ...payload.params,
                        [payload.key]: payload.value !== '' ? payload.value : undefined,
                        ...prepareMultipleFilterParams({ filters, sort }),
                    },
                })
            );
            break;

        case genActionName(actionEntity, command.filter):
            dispatch(clearItems(entity, subEntity));
            dispatch(
                getItems({
                    state: listState,
                    payload,
                    fieldName: 'filters',
                    actionEntity,
                })
            );
            break;

        case genActionName(actionEntity, command.sort):
            dispatch(clearItems(entity, subEntity));
            dispatch(
                getItems({
                    state: listState,
                    payload,
                    fieldName: 'sort',
                    actionEntity,
                })
            );
            break;

        case genActionName(actionEntity, command.fetch):
            dispatch(
                getItems({
                    state: listState,
                    payload,
                    actionEntity,
                    page: currentPage + 1,
                })
            );
            break;

        case genActionName(actionEntity, command.get):
            params = payload?.params;

            fetchConfig = {
                types: [
                    genActionName(actionEntity, api.request),
                    genActionName(actionEntity, api.success),
                    genActionName(actionEntity, api.fail),
                ],
                payload: {
                    request: {
                        url: payload.url ? payload.url : endpoint,
                        withCredentials: true,
                        params: {
                            ...params,
                            expand: params.expand ? params.expand : expand,
                        },
                    },
                },
                meta: { entity: actionEntity },
            };
            dispatch(clearAllAlerts());
            dispatch(fetchConfig);
            break;

        case genActionName(actionEntity, api.success):
        case genActionName(actionEntity, command.del):
            data = {};
            if (meta?.previousAction?.payload?.request?.params?.page === 1) {
                data = payload.data;
            } else {
                data = {
                    ...payload.data,
                    items: [...items, ...payload.data.items],
                };
            }
            dispatch({
                type: genActionName(actionEntity, document.set),
                payload: data,
            });
            break;

        case genActionName(actionEntity, api.fail):
            error = errorHandler(action.error);
            error.map((err) => dispatch(setAlert(err, actionEntity)));
            break;
        case genActionName(actionEntity, command.addItem):
            dispatch({
                type: genActionName(actionEntity, document.set),
                payload: {
                    items: [...items, payload],
                },
            });
        // no default
    }
};

export default genericListMiddleware;
