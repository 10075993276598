import { getRecipesUnits } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';

export const getRecipesUnitsAction = () => async (dispatch) => {
    dispatch(getRecipesUnitsStartAction());

    try {
        const res = await getRecipesUnits();

        if (res.status === 200) {
            dispatch(getRecipesUnitsSuccessAction(res.data.items));
        }
    } catch (error) {
        dispatch(getRecipesUnitsErrorAction(error));
    }
};

const getRecipesUnitsStartAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.units.baseName,
        entitiesMap.recipes.units.methodName.get,
        entitiesMap.recipes.units.allowedActions.start
    ),
});

const getRecipesUnitsSuccessAction = (data) => ({
    type: getFullActionName(
        entitiesMap.recipes.units.baseName,
        entitiesMap.recipes.units.methodName.get,
        entitiesMap.recipes.units.allowedActions.success
    ),
    payload: data,
});

const getRecipesUnitsErrorAction = (error) => ({
    type: getFullActionName(
        entitiesMap.recipes.units.baseName,
        entitiesMap.recipes.units.methodName.get,
        entitiesMap.recipes.units.allowedActions.error
    ),
    payload: error,
});
