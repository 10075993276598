import React, { useMemo, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

import {
    container,
    back,
    backIcon,
    details,
    header,
    meta,
    commentTree,
    likeButton,
    heartIcon,
    favorite,
    title,
    content,
    buttonAnswer,
    commentsContainer,
    commentBox,
    answerBox,
    moreButton,
    mainAnswerForm,
    message,
    loader,
    toggleLoading,
    loading,
    fixedLoader,
} from './thread.module.scss';
import ArrowLeftIcon from '../../../assets/images/svg/icon-arrow-left.svg';
import HeartIcon from '../../../assets/images/svg/icon-heart-empty.svg';
import ChevronIcon from '../../../assets/images/svg/chevron-down.svg';
import { IComment } from '../../../models/comment.model';
import { useForumThread } from '../../../hooks/use-forum-thread';
import { useForumComments } from '../../../hooks/use-forum-comments';
import { getAbsolutePath } from '../../../routes';

import ThreadMeta from '../../atoms/thread-meta';
import Markdown from '../../hoc/markdown';
import Button from '../../atoms/button';
import Comment, { ICommentProps } from '../../molecules/comment';
import Loader from '../../atoms/loader';
import IntersectBox from '../../atoms/intersect-box';

interface IThreadProps {
    className?: string;
    threadId: string;
}

const Thread: React.FC<IThreadProps> = ({ className = '', threadId }) => {
    const thread = useForumThread(threadId);
    const comments = useForumComments(threadId);
    const commentsRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const forumPathname = useMemo(() => getAbsolutePath('APP_MY_ACCOUNT_FORUM_HOME'), []);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const prevPathname = location.state?.prevPathname || forumPathname;

    const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
    const [answerForms, setAnswerForms] = useState<number[]>([]);

    const handleAddComment = () => {
        setIsCreateFormOpen(true);
    };

    const handleCancelAddComment = () => {
        setIsCreateFormOpen(false);
    };

    const handleSeeMore = (comment: IComment) => {
        return () => {
            comments.getAnswers(comment);
        };
    };

    const handleAnswer = (comment: IComment) => {
        setAnswerForms((prev) => [...prev, comment.commentId]);
    };

    const handleCancelAnswer = (comment: IComment) => {
        return () => {
            setAnswerForms((prev) => prev.filter((commentId) => commentId !== comment.commentId));
        };
    };

    const handleCreateComment = (parentId?: number) => {
        const handleSubmit: ICommentProps['onSubmit'] = ({ helpers, values }) => {
            comments.create({
                values,
                formik: helpers,
                parentId,
                threadId,
                elementToScroll: parentId
                    ? document.getElementById(`comment-${parentId}`)
                    : commentsRef.current,
            });
        };
        return handleSubmit;
    };

    const handleUpdateComment: ICommentProps['onSubmit'] = ({ comment, values, helpers }) => {
        if (!comment) return;
        comments.update({
            values,
            formik: helpers,
            parentId: comment.parentCommentId,
            threadId,
            commentId: comment.commentId,
        });
    };

    const handleDelete: ICommentProps['onConfirmDelete'] = (comment) => {
        if (!comment) return;
        comments.delete({ threadId, commentId: comment.commentId });
    };

    const handleReport: ICommentProps['onConfirmReport'] = (comment) => {
        if (!comment) return;
        comments.report({ threadId, commentId: comment.commentId });
    };

    return (
        <div className={`${container} ${className}`}>
            {(comments.statusSingle === 'loading' || comments.statusAnswers === 'loading') && (
                <Loader className={fixedLoader} />
            )}
            <Link to={prevPathname} className={back}>
                <ArrowLeftIcon className={backIcon} /> {copy.back}
            </Link>
            {thread.status === 'fail' && <p className={message}>{copy.threadError}</p>}
            {thread.status === 'loading' && <Loader className={loader} />}
            {thread.status === 'success' && thread.data && (
                <>
                    <div className={details}>
                        <div className={header}>
                            <ThreadMeta
                                thread={thread.data}
                                className={meta}
                                categoryPathname={forumPathname}
                            />
                            <button
                                className={`${likeButton} ${thread.isFavorite ? favorite : ''} ${
                                    thread.toggleFavoriteStatus === 'loading' ? toggleLoading : ''
                                }`}
                                onClick={thread.toggleFavorite}
                            >
                                <HeartIcon className={heartIcon} />
                            </button>
                        </div>
                        <h1 className={title}>{thread.data.title}</h1>
                        {thread.data.content && (
                            <Markdown className={`${content} ck-content`}>
                                {thread.data.content}
                            </Markdown>
                        )}
                        {!isCreateFormOpen && (
                            <Button
                                color="yellow"
                                size="small"
                                onClick={handleAddComment}
                                className={buttonAnswer}
                            >
                                {copy.answer}
                            </Button>
                        )}
                    </div>
                    <div
                        ref={commentsRef}
                        className={`${commentsContainer} ${
                            comments.statusSingle === 'loading' ||
                            comments.statusAnswers === 'loading'
                                ? loading
                                : ''
                        }`}
                    >
                        {isCreateFormOpen && (
                            <Comment
                                comment={null}
                                mode="create"
                                onCancel={handleCancelAddComment}
                                onSubmit={handleCreateComment()}
                            />
                        )}
                        {comments.status === 'fail' && (
                            <p className={message}>{copy.commentsError}</p>
                        )}
                        {comments.items.length === 0 && comments.status === 'success' && (
                            <p className={message}>{copy.noComments}</p>
                        )}
                        {comments.items.length > 0 && (
                            <div className={commentTree}>
                                {comments.items.map((comment) => {
                                    return (
                                        <div
                                            id={`comment-${comment.commentId}`}
                                            key={`comment-${comment.commentId}`}
                                            className={commentBox}
                                        >
                                            <Comment
                                                comment={comment}
                                                onAnswer={handleAnswer}
                                                onSubmit={handleUpdateComment}
                                                onConfirmDelete={handleDelete}
                                                onConfirmReport={handleReport}
                                            />
                                            {answerForms.includes(comment.commentId) && (
                                                <Comment
                                                    className={mainAnswerForm}
                                                    comment={null}
                                                    mode="create"
                                                    isAnswer={true}
                                                    onSubmit={handleCreateComment(
                                                        comment.commentId
                                                    )}
                                                    onCancel={handleCancelAnswer(comment)}
                                                />
                                            )}
                                            {comment.answers.length > 0 &&
                                                comment.answers.map((answer) => {
                                                    return (
                                                        <div
                                                            key={`answer-${answer.commentId}`}
                                                            className={answerBox}
                                                        >
                                                            <Comment
                                                                comment={answer}
                                                                isAnswer={true}
                                                                onAnswer={handleAnswer}
                                                                onSubmit={handleUpdateComment}
                                                                onConfirmDelete={handleDelete}
                                                                onConfirmReport={handleReport}
                                                            />
                                                            {answerForms.includes(
                                                                answer.commentId
                                                            ) && (
                                                                <Comment
                                                                    comment={null}
                                                                    mode="create"
                                                                    isAnswer={true}
                                                                    onSubmit={handleCreateComment(
                                                                        comment.commentId
                                                                    )}
                                                                    onCancel={handleCancelAnswer(
                                                                        answer
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            {comment.answersCount > comment.answers.length && (
                                                <button
                                                    onClick={handleSeeMore(comment)}
                                                    className={moreButton}
                                                >
                                                    {copy.more} <ChevronIcon />
                                                </button>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {comments.statusSingle !== 'loading' && (
                            <IntersectBox onIntersecting={comments.getNextPage}>
                                {(comments.status === 'loading' || comments.status === 'idle') && (
                                    <Loader className={loader} />
                                )}
                            </IntersectBox>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const copy = {
    answer: 'Odpowiedz',
    commentsError: 'Nie udało się pobrać komentarzy, spróbuj ponownie później...',
    back: 'Wróć',
    more: 'Zobacz więcej komentarzy',
    noComments: 'Do tego posta nie dodano jeszcze żadnych komentarzy.',
    threadError: 'Nie udało się pobrać danych posta, spróbuj ponownie później...',
};

export default Thread;
