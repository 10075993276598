import PropTypes from 'prop-types';

import { store } from '../redux/store';

import userHaveRightsTo from './utils';
import { entitiesArray } from './permissions';

const UserCan = (props) => {
    const userRights = store.getState().rbac;

    return userHaveRightsTo(props.action, userRights)
        ? props.yes()
        : props.no();
};

UserCan.propTypes = {
    action: PropTypes.oneOf(entitiesArray()).isRequired,
    yes: PropTypes.func,
    no: PropTypes.func,
};

UserCan.defaultProps = {
    yes: () => null,
    no: () => null,
};

export default UserCan;
