import { types } from './personal-training.actions';
import { config } from '../../config';

const { personalTraining } = config;

export const initialState = {
    trainingsList: {
        pagination: {
            currentPage: 0,
            nextPageToFetch: 1,
            pageCount: 20,
        },
        ids: [],
    },
    knownTrainings: [],
    current: {
        id: null,
        calendar: {
            days: [],
            length: 0,
        },
        days: {},
        date: new Date(),
        listType: personalTraining.typesMap.exercises.key,
        playlistMovieIndex: null,
        isPlaying: false,
    },
};

export default function personalTrainingReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.SET_CURRENT_TRAINING:
            return {
                ...state,
                current: {
                    ...state.current,
                    ...payload,
                },
            };
        case types.SET_TRAINING_LIST:
            return {
                ...state,
                trainingsList: {
                    ids: [...state.trainingsList.ids, ...payload.ids],
                    pagination: {
                        ...state.trainingsList.pagination,
                        ...payload.pagination,
                    },
                },
            };
        case types.SET_KNOWN_TRAININGS:
            return {
                ...state,
                knownTrainings: {
                    ...state.knownTrainings,
                    ...payload,
                },
            };
        case types.SET_TRAINING_CALENDAR:
            return {
                ...state,
                current: {
                    ...state.current,
                    calendar: payload,
                },
            };
        case types.SET_TRAINING_DAY:
            return {
                ...state,
                knownTrainings: {
                    ...state.knownTrainings,
                    [payload.id]: {
                        ...state.knownTrainings[payload.id],
                        ...payload,
                    },
                },
            };
        case types.SET_TRAINING_MOVIE:
            return {
                ...state,
                current: {
                    ...state.current,
                    listType: payload.listType,
                    playlistMovieIndex: payload.playlistMovieIndex,
                },
            };
        case types.CLEAR_TRAINING_DATA:
            return initialState;
        default:
            return state;
    }
}
