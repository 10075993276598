import { types as apiTypes } from '../../api/api.actions';

export const PAYU = '[PAYU]';

export const types = {
    // user triggered actions
    SUBMIT: `${PAYU} submit PayU token`,
    SUBMIT_RETRY: `${PAYU} submit PayU token retry`,

    // middleware actions
    CHECK_PAYMENT: `${PAYU} check payment status`,
    CHECK_ORDER_STATUS: `${PAYU} check order status`,

    // api actions
    SEND_PAYU_TOKEN_REQUEST: `${PAYU} ${apiTypes.API_REQUEST}`,
    SEND_PAYU_TOKEN_SUCCESS: `${PAYU} ${apiTypes.API_SUCCESS}`,
    SEND_PAYU_TOKEN_FAIL: `${PAYU} ${apiTypes.API_FAIL}`,

    SEND_PAYU_TOKEN_RETRY_REQUEST: `${PAYU} ${apiTypes.API_REQUEST}`,
    SEND_PAYU_TOKEN_RETRY_SUCCESS: `${PAYU} ${apiTypes.API_SUCCESS}`,
    SEND_PAYU_TOKEN_RETRY_FAIL: `${PAYU} ${apiTypes.API_FAIL}`,

    CHECK_PAYMENT_REQUEST: `${PAYU} check ${apiTypes.API_REQUEST}`,
    CHECK_PAYMENT_SUCCESS: `${PAYU} check ${apiTypes.API_SUCCESS}`,
    CHECK_PAYMENT_FAIL: `${PAYU} check ${apiTypes.API_FAIL}`,

    CHECK_ORDER_STATUS_REQUEST: `${PAYU} check order status ${apiTypes.API_REQUEST}`,
    CHECK_ORDER_STATUS_SUCCESS: `${PAYU} check order status ${apiTypes.API_SUCCESS}`,
    CHECK_ORDER_STATUS_FAIL: `${PAYU} check order status ${apiTypes.API_FAIL}`,

    // writing actions
    SET_CHECK_IN_PROGRESS: `${PAYU} set checking payu payment or order status`,
    SET_CHECK_COUNTER: `${PAYU} set check counter`,
    SET_ORDER_STATUS: `${PAYU} set order status`,
    CLEAR_PAYU: `${PAYU} clear`,
};

export const submitPayUTokenAction = (orderId, token) => ({
    type: types.SUBMIT,
    payload: { orderId, token },
});

export const submitPayUTokenRetryAction = (paymentId, token) => ({
    type: types.SUBMIT_RETRY,
    payload: { paymentId, token },
});

export const checkPaymentAction = (paymentId) => ({
    type: types.CHECK_PAYMENT,
    payload: { paymentId },
});

export const checkOrderStatusAction = (orderId) => ({
    type: types.CHECK_ORDER_STATUS,
    payload: { orderId },
});

export const setCheckInProgressAction = (isChecking) => ({
    type: types.SET_CHECK_IN_PROGRESS,
    payload: isChecking,
});

export const setCheckCounterAction = (count) => ({
    type: types.SET_CHECK_COUNTER,
    payload: count,
});

export const setOrderStatusAction = (orderStatus) => ({
    type: types.SET_ORDER_STATUS,
    payload: orderStatus,
});

export const clearPayUAction = () => ({
    type: types.CLEAR_PAYU,
});
