import React from 'react';

import { pointsValue, pointsListItem } from './points-list-item.module.scss';
import pointsWithText from '../../utills/gamification/points-with-text';

export type PointListItem = { id: number; label: string; points: number };

export interface IPointsListItem {
    item: PointListItem;
    containerClassName?: string;
    pointsClassName?: string;
}

export default function PointsListItem({
    item,
    containerClassName = '',
    pointsClassName = '',
}: IPointsListItem) {
    return (
        <li className={`${pointsListItem} ${containerClassName}`}>
            <span>{item.label}</span>
            <span className={`${pointsValue} ${pointsClassName}`}>
                {pointsWithText(item.points)}
            </span>
        </li>
    );
}
