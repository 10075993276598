import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from 'react-calendar';

import './callendar-styling.scss';
import {
    calendarBtn,
    calendarHolder,
    reactCalendar,
    dotHolderDay,
    dotHolderFooter,
    inputCalendarStyle,
} from './personal-training-callendar.module.scss';
import { config } from '../../../../../config';
import CalendarIcon from '../../../../../assets/images/svg/callendar.svg';
import DownArrowIcon from '../../../../../assets/images/svg/down-arrow.svg';
import { selectCurrentTraining } from '../../../../../redux/personal-training/personal-training.selectors';
import { getFormattedDate } from '../../../../../utills/date-utils';
import { selectLoaderByEntity } from '../../../../../redux/ui/ui.selectors';
import {
    PERSONAL_TRAINING_CALENDAR,
    types,
} from '../../../../../redux/personal-training/personal-training.actions';

import Dot from '../additional/dot';
import Dropdown from '../../../../molecules/dropdown';
import Loader from '../../../../atoms/loader';

const PersonalTrainingCalendar = () => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const dispatch = useDispatch();

    const { calendarLoader, current, personalTraining } = useSelector((state) => ({
        calendarLoader: selectLoaderByEntity(state, PERSONAL_TRAINING_CALENDAR),
        current: state.personalTrainings.current,
        personalTraining: selectCurrentTraining(state),
    }));

    const selectDate = (newDate) => {
        setIsCalendarOpen(false);
        dispatch({ type: types.CHANGE_DAY, payload: newDate });
    };

    useEffect(() => {
        if (!current.calendar.days.length) {
            dispatch({ type: types.GET_TRAINING_CALENDAR });
        }
    }, [dispatch, current]);

    const minDate = new Date(personalTraining.dateFrom);
    const maxDate = new Date(personalTraining.dateTo);

    const checkDots = ({ date }) => {
        return (
            <>
                <div className={dotHolderDay}>
                    {hasExercise(date, current.calendar.days) ? <Dot /> : ''}
                    {hasAdditionals(date, current.calendar.days) ? <Dot color={'blue'} /> : ''}
                </div>
            </>
        );
    };

    return (
        <>
            {calendarLoader === config.apiStatusMap.loading && <Loader />}
            <button onClick={() => setIsCalendarOpen(true)} className={calendarBtn}>
                <CalendarIcon />
                <span>{moment(current.date).format('D MMMM YYYY')}</span>
                <DownArrowIcon />
            </button>

            <div className={calendarHolder}>
                <Dropdown
                    className={inputCalendarStyle}
                    open={isCalendarOpen}
                    isTriangle={false}
                    closeFunc={() => setIsCalendarOpen(false)}
                >
                    <Calendar
                        className={reactCalendar}
                        defaultActiveStartDate={new Date()}
                        value={current.date}
                        onClickDay={selectDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        tileContent={checkDots}
                    />
                    <div className={dotHolderFooter}>
                        <Dot>Trening</Dot>
                        <Dot color={'blue'}>Dodatkowe</Dot>
                    </div>
                </Dropdown>
            </div>
        </>
    );
};

const hasExercise = (currentDay, days) => {
    return !!days.find(
        (singleDay) => singleDay.date === getFormattedDate(currentDay) && singleDay.hasExercises
    );
};

const hasAdditionals = (currentDay, days) => {
    return !!days.find(
        (singleDay) => singleDay.date === getFormattedDate(currentDay) && singleDay.hasAdditionals
    );
};

export default PersonalTrainingCalendar;
