import { useEffect, useState } from 'react';

import { getMediaImage } from '../utills/get-media-image';
import { IMediaImage } from '../models/media.model';
import { IUseMedia } from '../models/use-media.model';

type UseMediaReturnValue = [string, string] & Partial<IMediaImage>;

export default function useMedia({ media, relation, fallback }: IUseMedia): UseMediaReturnValue {
    const [mediaImage, setMediaImage] = useState<IMediaImage>(
        getMediaImage(media, relation, fallback)
    );

    useEffect(() => {
        setMediaImage(getMediaImage(media, relation, fallback));
    }, [media, relation, fallback]);

    const mediaArray: UseMediaReturnValue = [mediaImage.url, mediaImage.alt];
    mediaArray.alt = mediaImage.alt;
    mediaArray.url = mediaImage.url;

    return mediaArray;
}
