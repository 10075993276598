import { actions } from '../messages/send-actions';

const initialState = {
    meta: {
        loading: false,
        success: null,
        errors: null,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.START:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    loading: true,
                    success: null,
                    errors: null,
                },
            };

        case actions.SUCCESS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    loading: false,
                    success: true,
                },
            };

        case actions.SET_ERRORS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    loading: true,
                    success: false,
                    errors: action.payload,
                },
            };
        default:
            return state;
    }
}
