import React, { ChangeEvent, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { label } from './points-payment-checkbox.module.scss';
import { selectPayByPointsItem } from '../../redux/shop/order/order.selectors';
import { selectUserData } from '../../redux/user/user.selectors';
import { requestPayByPoints } from '../../redux/shop/order/order.actions';
import { IPointsBenefitLabel } from '../../models/product-promotion.model';
import pointsWithText from '../../utills/gamification/points-with-text';

import Checkbox from '../atoms/form-poc/checkbox';

export interface IPointsPaymentCheckbox {
    id: number | string;
    productId: number;
    className?: string;
    labelPointsBenefit?: IPointsBenefitLabel;
    context: 'cart' | 'cart-dropdown' | 'order-summary' | 'order-history';
}

export default function PointsPaymentCheckbox({
    id,
    productId,
    className,
    children,
    labelPointsBenefit,
    context,
}: PropsWithChildren<IPointsPaymentCheckbox>) {
    const isUserLogged = useSelector(selectUserData) !== null;
    const { checked, disabled } = useSelector(selectPayByPointsItem(id));
    const dispatch = useDispatch();

    if (!isUserLogged || !labelPointsBenefit || !labelPointsBenefit.pointsAmount) {
        return null;
    }

    const defaultText = `Płacę ${labelPointsBenefit.pointsPercent}% ceny Ketonami (${pointsWithText(
        labelPointsBenefit.pointsAmount
    )})`;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            requestPayByPoints({
                itemId: id,
                checked: event.target.checked,
                amount: labelPointsBenefit.pointsAmount,
                productId,
                promotionId: labelPointsBenefit.promotionId,
                validateAfter: context === 'order-summary',
            })
        );
    };

    return (
        <Checkbox
            containerClass={className}
            labelClass={label}
            name={`pay-points-${id}`}
            withoutFormik
            checked={context === 'order-history' || checked}
            disabled={context === 'order-history' || disabled}
            onChange={handleChange}
        >
            {children || defaultText}
        </Checkbox>
    );
}
