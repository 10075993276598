import React, { PropsWithChildren } from 'react';

import { label } from './label.module.scss';

export interface ILabelProps {
    color?: string;
    className?: string;
    Tag?: React.ElementType;
    onClick?: () => void;
}

export default function Label({
    color = '--color-black',
    children,
    className = '',
    Tag = 'span',
    onClick,
}: PropsWithChildren<ILabelProps>) {
    const labelStyle = {
        '--label-color': `var(${color})`,
    };

    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return (
        <Tag className={`${label} ${className}`} style={labelStyle} onClick={handleClick}>
            {children}
        </Tag>
    );
}
