// extracted by mini-css-extract-plugin
export var badge = "business-partner-tile-module--badge--3yHpI";
export var benefitLabelWrapper = "business-partner-tile-module--benefit-label-wrapper--5BID7";
export var blurred = "business-partner-tile-module--blurred--oaw1Q";
export var businessPartnerTile = "business-partner-tile-module--business-partner-tile--JwSxK";
export var codeText = "business-partner-tile-module--code-text--oAPQS";
export var disabled = "business-partner-tile-module--disabled--w7vuP";
export var highlight = "business-partner-tile-module--highlight--QsrI6";
export var labelText = "business-partner-tile-module--label-text--0CCX4";
export var logo = "business-partner-tile-module--logo--1JxuK";
export var notHovered = "business-partner-tile-module--not-hovered--64Vx+";