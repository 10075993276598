import React from 'react';

import { nav, navList } from './main-navigation.module.scss';

import NavItem from '../atoms/nav-item';

const MainNavigation = ({ navItems, className }) => (
    <nav className={`${nav} ${className}`}>
        <ul className={navList}>
            {navItems.map((item, index) => (
                <li key={`main-nav-item-${index}`}>
                    <NavItem to={item.absolutePath}>{item.label}</NavItem>
                </li>
            ))}
        </ul>
    </nav>
);

export default MainNavigation;
