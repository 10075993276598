import React from 'react';

import { ShopStepsEnum } from '../../../models/shop-steps.enum';

import ShopStepUserData from '../shop-step-user-data/shop-step-user-data';
import ShopStepAddress from '../shop-step-address/shop-step-address';
import ShopStepSummary from '../shop-step-summary/shop-step-summary';

export const componentLabel = {
    cart: 'Koszyk',
    clientDataDeliveryAndPayment: 'Dane zamawiającego',
    summary: 'Podsumowanie',
};

export const componentTypeMap = {
    [ShopStepsEnum.Cart]: <ShopStepUserData />,
    [ShopStepsEnum.ClientDataDeliveryAndPayment]: <ShopStepAddress />,
    [ShopStepsEnum.Summary]: <ShopStepSummary />,
};
