export const entity = {
    RECIPES: `recipes`,
    PERSONAL_TRAININGS: `personalTrainings`,
    TRAININGS_ATLAS: `trainingsAtlas`,
    EXERCISES_ATLAS: `exercisesAtlas`,
    MEAL_PLANER_TEMPLATE: `mealPlanerTemplate`,
    FOOD_PLANNER_ACCESS: `foodPlannerAccess`,
    SUBSCRIBER: 'subscriber',
};

export const entitiesArray = () => Object.values(entity);

const User = [entity.FOOD_PLANNER_ACCESS];

const FoodPlannerAccess = [entity.RECIPES];

const SubscriberStandard = [
    ...User,
    ...FoodPlannerAccess,
    entity.PERSONAL_TRAININGS,
    entity.EXERCISES_ATLAS,
    entity.SUBSCRIBER,
];

const SubscriberPremium = [...SubscriberStandard, entity.TRAININGS_ATLAS];

const Admin = [...SubscriberPremium, entity.MEAL_PLANER_TEMPLATE];
const Root = [...Admin];

const TrainingAccess = [...User, entity.TRAININGS_ATLAS];

export const permissionMap = {
    User: User,
    SubscriberStandard: SubscriberStandard,
    SubscriberPremium: SubscriberPremium,
    Admin: Admin,
    Root: Root,
    TrainingAccess: TrainingAccess,
    FoodPlannerAccess,
};
