import pick from './pick';

const arrayToObject = (array, keyField = 'id', processItem, processItemArgs = []) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] =
            typeof processItem === 'function' ? processItem(item, ...processItemArgs) : item;
        return obj;
    }, {});

const arrayToArrayOfKeys = (array, keyField) =>
    array.reduce((arr, item) => {
        arr.push(item[keyField]);
        return arr;
    }, []);

const formatComments = (comment) => {
    return comment.replace(/(?:\r\n|\r|\n)/g, '<br/>');
};

// pickField is faster because it doesn't use reduce.
const pickField = (field) => (object) => object[field];

const pickFields = (fields) => (object) => pick(object, fields);

const groupBy = (array, keyField, processItem, processItemArgs = []) => {
    return array.reduce((obj, item) => {
        const key = item[keyField];
        const processedItem =
            typeof processItem === 'function' ? processItem(item, ...processItemArgs) : item;
        if (Array.isArray(obj[key])) {
            obj[key].push(processedItem);
        } else {
            obj[key] = [processedItem];
        }

        return obj;
    }, {});
};

export { arrayToObject, formatComments, arrayToArrayOfKeys, pickField, pickFields, groupBy };
