// extracted by mini-css-extract-plugin
export var actionButtons = "subscription-card-module--action-buttons--HUxS-";
export var actionDisclaimers = "subscription-card-module--action-disclaimers--znK1Q";
export var button = "subscription-card-module--button--GVM-w";
export var container = "subscription-card-module--container--KOPVA";
export var item = "subscription-card-module--item--ULW7i";
export var itemLabel = "subscription-card-module--item-label--Ztusa";
export var list = "subscription-card-module--list--mUyLu";
export var offer = "subscription-card-module--offer--EAIPZ";
export var offerButton = "subscription-card-module--offer-button--7NTXB";
export var offerContent = "subscription-card-module--offer-content--d+SPH";
export var offerDescription = "subscription-card-module--offer-description--X5EHb";
export var offerName = "subscription-card-module--offer-name--U3rTT";
export var title = "subscription-card-module--title--etWRA";