import React from 'react';
import { Link } from 'gatsby';
import { useDispatch } from 'react-redux';

import {
    title,
    button,
    imgWrapper,
    img,
    iconWrapper,
    metadata,
    card,
    locked,
    ratioClass,
} from './exercise-card.module.scss';
import Lock from '../../../assets/images/svg/icon-lock.svg';
import Play from '../../../assets/images/svg/icon-play.svg';
import { forbiddenClick } from '../../../redux/rbac/rbac.actions';
import { entity } from '../../../rbac/permissions';

import Title from '../../atoms/title';
import ExerciseMetadata from './exercise-metadata';
import RatioImage from '../../atoms/ratio-image';

const ExerciseCard = ({ item, path }) => {
    const dispatch = useDispatch();
    const isLocked = item.restrictedFields && item.restrictedFields.length > 0;
    const permissionEntity = item.trainingId ? entity.TRAININGS_ATLAS : null;

    // TODO: Change it into component
    const renderImg = (imgUrl) => {
        return (
            <div className={imgWrapper}>
                <span
                    className={`
                        ${iconWrapper}
                        ${isLocked && locked}
                    `}
                >
                    {isLocked ? <Lock /> : <Play />}
                </span>

                <RatioImage src={imgUrl} className={img} alt="title" ratioClass={ratioClass} />
            </div>
        );
    };

    const Tag = ({ children }) => {
        if (isLocked) {
            return (
                <button
                    className={button}
                    onClick={() => dispatch(forbiddenClick(permissionEntity))}
                >
                    {children}
                </button>
            );
        } else {
            return <Link to={`${path}/${item.id}`}>{children}</Link>;
        }
    };

    return (
        <Tag>
            <article className={card}>
                {renderImg(item.coverListUri)}

                <ExerciseMetadata exercise={item} className={metadata} color="grey" />

                <Title Tag={'h3'} className={title}>
                    {item.name}
                </Title>
            </article>
        </Tag>
    );
};

export default ExerciseCard;
