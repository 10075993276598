// extracted by mini-css-extract-plugin
export var attachmentPill = "attachments-module--attachment-pill--n8fe8";
export var childList = "attachments-module--child-list--rDWn7";
export var childListItem = "attachments-module--child-list-item--NR9tg";
export var extensions = "attachments-module--extensions--nLrwf";
export var fullWidth = "attachments-module--full-width--aOsDR";
export var list = "attachments-module--list--BMWBL";
export var listTitle = "attachments-module--list-title--IzV1J";
export var loader = "attachments-module--loader--D+tAE";
export var separator = "attachments-module--separator--kABTq";
export var titleWrapper = "attachments-module--title-wrapper--ij-4k";