import React from 'react';
// TODO: Gatsby Image - change to Static Image
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { wrapper, text } from './facebook-banner.module.scss';

const FacebookBanner = () => {
    const imageQuery = useStaticQuery(query);
    return (
        <Link to={'/app/facebook'}>
            <div className={wrapper}>
                <div>
                    <GatsbyImage
                        image={imageQuery.file.childImageSharp.gatsbyImageData}
                        alt="Facebook banner"
                    />
                </div>
                <div className={text}>
                    <span>Dołącz do nas na Facebook'u</span>
                </div>
            </div>
        </Link>
    );
};

const query = graphql`
    {
        file: file(relativePath: { eq: "facebook-logo-cutted.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;

export default FacebookBanner;
