import React, { useRef } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
    button,
    underLine,
    iconFilled as iconFilledClass,
    uppercase,
} from './icon-buttton.module.scss';

const IconButton = ({
    className,
    link,
    children,
    onClick,
    icon: Icon,
    isUnderLine,
    iconFilled,
    isUppercase,
}) => {
    const ref = useRef(null);

    const handleClick = () => {
        ref.current.blur();
        if (onClick) {
            onClick();
        }
    };

    const Tag = link ? Link : 'button';

    return (
        <Tag
            {...(link ? { to: link } : { onClick: handleClick })}
            ref={ref}
            className={`
                ${className}
                ${button}
                ${isUnderLine ? underLine : ''}
                ${isUppercase ? uppercase : ''}
            `}
        >
            {Icon && <Icon className={iconFilled ? iconFilledClass : ''} />}
            {children}
        </Tag>
    );
};

IconButton.propTypes = {
    className: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.elementType,
    isUnderLine: PropTypes.bool,
    iconFilled: PropTypes.bool,
    isUppercase: PropTypes.bool,
    onClick: PropTypes.func,
};

IconButton.defaultProps = {
    className: '',
    link: '',
    icon: null,
    isUnderLine: true,
    iconFilled: true,
    isUppercase: false,
    onClick: () => {},
};

export default IconButton;
