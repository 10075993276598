import { DOWNLOAD_FILE, types } from '../download-files/download-files.actions';
import { downloadBlob } from '../../utills/download-blob';
import { setAlert } from '../alerts/alerts.actions';

const downloadFileMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, meta } = action;

    next(action);

    const fileMetadata = meta?.previousAction?.meta;

    switch (type) {
        case types.DOWNLOAD_FILE:
            const payloadRequest = {
                types: [
                    types.DOWNLOAD_FILE_REQUEST,
                    types.DOWNLOAD_FILE_SUCCESS,
                    types.DOWNLOAD_FILE_FAIL,
                ],
                payload: {
                    request: {
                        url: payload.url,
                        withCredentials: true,
                        responseType: 'blob',
                    },
                },
                meta: {
                    entity: DOWNLOAD_FILE,
                    ...payload,
                },
            };
            dispatch(payloadRequest);
            break;
        case types.DOWNLOAD_FILE_SUCCESS:
            const fileBlob = new Blob([payload.data], {
                type: fileMetadata.type,
            });
            downloadBlob(fileBlob, fileMetadata.filename);
            break;
        case types.DOWNLOAD_FILE_FAIL:
            dispatch(
                setAlert(
                    {
                        type: 'error',
                        content: `Nie udało się pobrać pliku`,
                    },
                    DOWNLOAD_FILE
                )
            );
            break;
        //no default
    }
};

export default downloadFileMiddleware;
