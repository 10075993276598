import { types as apiTypes } from '../api/api.actions';

export const MEAL_PLANER_TEMPLATE = '[MealPlanerTemplate]';
export const MEAL_PLANER_TEMPLATE_ACTION = '[PlanerTemplateAction]';
export const MEAL_PLANER_TEMPLATE_POS = '[PlanerTemplatePosition]';

export const types = {
    // user triggered actions eg. button click
    ADD_ITEM: `${MEAL_PLANER_TEMPLATE} add item`, // show adding modal
    DEL_ITEM: `${MEAL_PLANER_TEMPLATE} del item`, // send delete request
    UPDATE_ITEM: `${MEAL_PLANER_TEMPLATE} update item`, // send update request eg. reorder items on drag&drop

    SEARCH_RECIPES: `${MEAL_PLANER_TEMPLATE} search recipes`, // show recipe search modal

    ADD_MEAL_TYPE: `${MEAL_PLANER_TEMPLATE} add meal type`, // show modal with meal types
    DEL_MEAL_TYPE: `${MEAL_PLANER_TEMPLATE} del meal type`, // send request to del of all positions with this type

    CHOOSE_TEMPLATE: `${MEAL_PLANER_TEMPLATE} choose template`, // choose template to edit
    SHOW_ITEM_PREVIEW: `${MEAL_PLANER_TEMPLATE} show item preview`, // show item preview modal

    CREATE_TEMPLATE: `${MEAL_PLANER_TEMPLATE} create`, // trigger get data
    EDIT_TEMPLATE: `${MEAL_PLANER_TEMPLATE} edit`, // show edit modal
    DEL_TEMPLATE: `${MEAL_PLANER_TEMPLATE} delete`, // show item preview modal

    CLEAR_CURRENT_TEMPLATE_FROM_STATE: `${MEAL_PLANER_TEMPLATE} clear from state`, // show item preview modal

    // flow/commands actions

    GET_TEMPLATE_LIST: `${MEAL_PLANER_TEMPLATE} get list`, // trigger get list
    GET_TEMPLATE_DATA: `${MEAL_PLANER_TEMPLATE} get data`, // trigger get data

    FETCH_TEMPLATE_DATA: `${MEAL_PLANER_TEMPLATE} fetch data`, // trigger fetch
    FETCH_TEMPLATE_ACTION: `${MEAL_PLANER_TEMPLATE} send action`, // trigger action send

    FETCH_DEL_MEAL_TYPE: `${MEAL_PLANER_TEMPLATE} fetch del meal type`,
    FETCH_ADD_RECIPE: `${MEAL_PLANER_TEMPLATE} add recipe`,
    FETCH_DEL_POSITION: `${MEAL_PLANER_TEMPLATE} del position`,

    FETCH_POSITION: `${MEAL_PLANER_TEMPLATE_POS} fetch position`,

    // api actions
    SEND_PLANER_TEMPLATE_REQUEST: `${MEAL_PLANER_TEMPLATE} ${apiTypes.API_REQUEST}`,
    SEND_PLANER_TEMPLATE_SUCCESS: `${MEAL_PLANER_TEMPLATE} ${apiTypes.API_SUCCESS}`,
    SEND_PLANER_TEMPLATE_FAIL: `${MEAL_PLANER_TEMPLATE} ${apiTypes.API_FAIL}`,

    SEND_PLANER_TEMPLATE_ACTION_REQUEST: `${MEAL_PLANER_TEMPLATE_ACTION} ${apiTypes.API_REQUEST}`,
    SEND_PLANER_TEMPLATE_ACTION_SUCCESS: `${MEAL_PLANER_TEMPLATE_ACTION} ${apiTypes.API_SUCCESS}`,
    SEND_PLANER_TEMPLATE_ACTION_FAIL: `${MEAL_PLANER_TEMPLATE_ACTION} ${apiTypes.API_FAIL}`,

    SEND_PLANER_TEMPLATE_POSITION_REQUEST: `${MEAL_PLANER_TEMPLATE_POS} ${apiTypes.API_REQUEST}`,
    SEND_PLANER_TEMPLATE_POSITION_SUCCESS: `${MEAL_PLANER_TEMPLATE_POS} ${apiTypes.API_SUCCESS}`,
    SEND_PLANER_TEMPLATE_POSITION_FAIL: `${MEAL_PLANER_TEMPLATE_POS} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_PLANER_TEMPLATE_LIST: `${MEAL_PLANER_TEMPLATE} set list`,
    SET_PLANER_TEMPLATE_DATA: `${MEAL_PLANER_TEMPLATE} set_data`,
    SET_PLANER_TEMPLATE_AUTH: `${MEAL_PLANER_TEMPLATE} set_auth`,
    CLEAR_PLANER_TEMPLATE_DATA: `${MEAL_PLANER_TEMPLATE} clear`,
};
