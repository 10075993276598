import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { btn, btnHolder, weekSwitcher } from './week-switcher.module.scss';
import ArrowLeft from '../../../assets/images/svg/icon-arrow-left.svg';
import ArrowRight from '../../../assets/images/svg/icon-arrow-right.svg';
import { getNextWeek, getPrevWeek } from '../../../redux/meal-planer/meal-planer.actions';

const WeekSwitcher = ({ className = '' }) => {
    const dispatch = useDispatch();
    const { dateFrom, dateTo } = useSelector((state) => ({
        dateFrom: moment(state.mealPlaner.dateFrom).format('DD.MM'),
        dateTo: moment(state.mealPlaner.dateTo).format('DD.MM'),
    }));

    const nextWeek = () => dispatch(getNextWeek());
    const prevWeek = () => dispatch(getPrevWeek());

    return (
        <div className={`${weekSwitcher} ${className}`}>
            <p>
                {dateFrom} - {dateTo}
            </p>
            <p className={btnHolder}>
                <button className={btn} onClick={prevWeek}>
                    <ArrowLeft />
                </button>
                <button className={btn} onClick={nextWeek}>
                    <ArrowRight />
                </button>
            </p>
        </div>
    );
};

export default WeekSwitcher;
