import {
    changeCurrentProfileId,
    clearCurrentProfileId,
    clearProfileData,
    currentProfileIdChanged,
    removeCurrentProfileId,
    setCurrentProfileId,
    setProfileList,
    setSingleProfile,
    types,
    updateProfileRequest,
} from './profile.actions';
import { fetchUserData } from '../user/user.actions';
import { config } from '../../config';
import {
    getCurrentProfile,
    getCurrentProfileId,
    getProfileList,
    profileFilledSurvey,
} from './profile.selectors';
import { setFormikStatus } from './profile.utility';
import { addModalAction, removeModalAction } from '../actions/actions-modals';
import { clearAllRecipes } from '../recipes/recipes.actions';
import { arrayToObject } from '../../utills/data-convertion';
import {
    clearNotificationsAction,
    getNotificationsIntervalAction,
} from '../notifications/notifications.actions';
import { clearGamification, initGamification } from '../gamification/gamification.actions';
import { clearSurveyAction } from '../survey/survey.actions';
import { clearSurveysAction } from '../surveys/surveys.actions';

const profileMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    const { type, payload, meta, error } = action;

    const formikBag = meta?.formikBag || meta?.previousAction?.meta?.formikBag;
    const modalId = meta?.previousAction?.meta?.modalId;

    next(action);
    let currentProfile, profileList, profileListObj, modalData;

    switch (type) {
        case types.INIT_PROFILE:
            profileList = payload.map((item) => {
                profileListObj = getProfileList(getState());

                return {
                    ...item,
                    watchedOnboardingVideoInSession:
                        item.watchedOnboardingVideo ||
                        profileListObj[item.id]?.watchedOnboardingVideoInSession ||
                        false,
                    surveyFilledInSession: profileFilledSurvey(getState(), item.id),
                };
            });

            dispatch(setProfileList(arrayToObject(profileList)));
            currentProfile = getCurrentProfile(getState());

            dispatch(getNotificationsIntervalAction());

            if (!currentProfile && payload.length === 1) {
                dispatch(changeCurrentProfileId(payload[0].profileId));
            } else if (currentProfile) {
                dispatch(initGamification());
            }
            break;

        case types.UPDATE_SINGLE_PROFILE:
            dispatch(setSingleProfile(payload));
            break;

        case types.CHANGE_CURRENT_PROFILE_ID:
            dispatch(clearAllRecipes());
            dispatch(clearNotificationsAction());
            dispatch(clearSurveyAction());
            dispatch(clearSurveysAction());
            dispatch(setCurrentProfileId(payload));

            dispatch(currentProfileIdChanged());
            break;
        case types.CURRENT_PROFILE_ID_CHANGED:
            dispatch(getNotificationsIntervalAction());
            dispatch(clearGamification());
            dispatch(initGamification());
            break;
        case types.UPDATE_PROFILE:
            const currentProfileId = getCurrentProfileId(getState());

            setFormikStatus({
                formikBag,
                status: config.formsStatusMap.loading,
            });

            dispatch(
                updateProfileRequest({
                    profileId: currentProfileId,
                    profileData: payload,
                    meta,
                })
            );
            break;
        case types.UPDATE_PROFILE_SUCCESS:
            setFormikStatus({
                formikBag,
                status: config.formsStatusMap.success,
            });
            if (meta?.previousAction?.meta?.onboardingVideo) {
                modalData = {
                    type: 'info',
                    title: config.messages.profile.updateOnboardingVideoTitle,
                    content: config.messages.profile.updateOnboardingVideoContent,
                };
            } else {
                modalData = {
                    type: 'info',
                    title: config.messages.profile.updateSuccessTitle,
                    content: config.messages.profile.updateSuccessContent,
                };
            }

            dispatch(addModalAction(modalData));
            dispatch(fetchUserData());

            if (modalId) {
                dispatch(removeModalAction(modalId));
            }
            break;
        case types.UPDATE_PROFILE_FAIL:
            dispatch(
                addModalAction({
                    type: 'error',
                    title: config.messages.profile.updateFailTitle,
                    content: config.messages.profile.updateFailContent,
                })
            );

            setFormikStatus({
                formikBag,
                status: config.formsStatusMap.fail,
                error,
            });
            break;
        case types.CLEAR_ALL_PROFILE_DATA:
            dispatch(removeCurrentProfileId());
            dispatch(clearProfileData());
            break;
        case types.REMOVE_CURRENT_PROFILE_ID:
            dispatch(clearCurrentProfileId());
            break;

        // no default
    }
};

export default profileMiddleware;
