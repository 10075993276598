import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

import InjectableSlider from '../organisms/injectable-slider';

interface IMarkdownProps extends ReactMarkdownOptions {
    children: string;
    className?: string;
}

const Markdown: React.FC<IMarkdownProps> = ({ children, className = '', ...rest }) => {
    return (
        <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
                h1: 'h2',
                a: ({ href, children }) => {
                    return (
                        <a href={href} target="_blank" rel="noreferrer noopener">
                            {children}
                        </a>
                    );
                },
                p: ({ children, node }) => {
                    const text = children?.[0];
                    if (typeof text !== 'string') return <p>{children}</p>;

                    const regex = /\{\{slider=(\d+)\}\}/;
                    const match = text.match(regex);
                    if (!match) return <p>{children}</p>;

                    const sliderId = Number(match[1]);
                    if (isNaN(sliderId)) return null;
                    return <InjectableSlider sliderId={sliderId} />;
                },
            }}
            className={className}
            {...rest}
        >
            {children}
        </ReactMarkdown>
    );
};

export default Markdown;
