const trackEvent = (eventName, params = {}) => {
    const fbq = window.fbq;

    if (fbq !== 'undefined' && typeof fbq === 'function') {
        let eventType = 'track';
        let checkedEventName = '';

        if (Object.values(eventMap).includes(eventName)) {
            checkedEventName = eventName;
        }
        if (Object.values(customEventMap).includes(eventName)) {
            checkedEventName = eventName;
            eventType = 'trackCustom';
        }

        if (checkedEventName !== undefined) {
            fbq(eventType, checkedEventName, params);
        }
    }
};

const eventMap = {
    addToCart: 'AddToCart',
    initiateCheckout: 'InitiateCheckout',
    completeRegistration: 'CompleteRegistration',
    contact: 'Contact',
    viewContent: 'ViewContent',
    purchase: 'Purchase',
    subscribe: 'Subscribe',
};

const customEventMap = {
    notRecurringPayment: 'zakup bez rat',
    recurringPayment: 'zakup ratalny',
};

const prepareContents = (orderItems) => {
    return orderItems.map((item) => ({
        id: item.product.productId,
        quantity: item.quantity,
    }));
};

export { trackEvent, prepareContents, eventMap, customEventMap };
