import React from 'react';

import { wrapper, nameWrapper, button } from './download-block.module.scss';
import FolderIcon from '../../../assets/images/svg/icon-folder.svg';

import Button from '../../atoms/button';

const DownloadBlock = ({ file, className }) => {
    const { name, uri } = file;

    const getFile = () => {
        window.open(uri, '_blank');
    };

    return (
        <div className={`${wrapper} ${className}`}>
            <FolderIcon />
            <div className={nameWrapper}>{name}</div>
            {uri && (
                <Button color={'blank'} className={button} size="small" onClick={() => getFile()}>
                    Pobierz
                </Button>
            )}
        </div>
    );
};

export default DownloadBlock;
