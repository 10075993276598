import moment from 'moment';

import { defaultFormat } from './date-utils';

const unitKey = (date, type) => `unit-${date}-${type}`;
const posKey = (id) => `pos-${id}`;
const cssDateVar = (dateString) => `day-${dateString}`;
const weekDaysArr = [1, 2, 3, 4, 5, 6, 7];

const mapArrayToObj = (arr, oldPositions) => {
    const positions = {};

    for (const position of arr) {
        const recipe = oldPositions?.[posKey(position.positionId)]?.recipe;
        positions[posKey(position.positionId)] = {
            ...position,
            ...(recipe ? { recipe } : {}),
            date: position.day || position.date,
        };
    }

    return positions;
};

const updateObjInArray = (arr, obj, key) => {
    return arr.map((item) => (item[key] === obj[key] ? obj : item));
};

const generateDatesArrayFromState = (state) => {
    const from = state.mealPlaner?.dateFrom;
    const to = state.mealPlaner?.dateTo;
    return from && to ? generateDatesArray(from, to) : null;
};

const generateDatesArray = (from, to) => {
    const datesArray = [];

    const dateFrom = moment(from).startOf('day');
    const dateTo = moment(to).startOf('day');

    const now = dateFrom.clone();

    for (now; now.isSameOrBefore(dateTo); now.add(1, 'days')) {
        datesArray.push(now.format(defaultFormat));
    }

    return datesArray;
};

const generateEmptyUnits = (mealTypesMap, dates) => {
    let planerUnits = {};

    for (const type of Object.values(mealTypesMap)) {
        for (const day of dates) {
            planerUnits[unitKey(day, type.key)] = {
                date: day,
                mealTypeId: type.mealTypeId,
                col: cssDateVar(day),
                row: type.key,
                positions: [],
                longest: false,
            };
        }
    }

    return planerUnits;
};

const populatePlannerUnits = (planerUnits, planerItems, mealTypesMap) => {
    const newPlannerUnits = { ...planerUnits };

    for (const item of Object.values(planerItems)) {
        const { date, mealTypeId } = item;
        for (const [key, unit] of Object.entries(planerUnits)) {
            if (date === unit.date && mealTypeId === unit.mealTypeId) {
                newPlannerUnits[key].positions.push(item);
            }
        }
    }

    for (const type of Object.values(mealTypesMap)) {
        let positionsCount = maxPositionsCount(
            newPlannerUnits,
            type.mealTypeId
        );

        for (const unit of Object.values(newPlannerUnits)) {
            const length = unit.positions.length;
            if (
                type.mealTypeId === unit.mealTypeId &&
                length === positionsCount
            ) {
                unit.longest = true;
            }
        }
    }

    return newPlannerUnits;
};

const maxPositionsCount = (collectionObj, id) => {
    let maxPositionsCount = 0;

    maxPositionsCount = Object.values(collectionObj).reduce((acc, val) => {
        const length = val.positions.length;
        return id === val.mealTypeId && length >= acc ? length : acc;
    }, maxPositionsCount);

    return maxPositionsCount;
};

const generateAndPopulateUnits = (mealTypesMap, dates, positions) => {
    const planerUnits = generateEmptyUnits(mealTypesMap, dates);
    return populatePlannerUnits(planerUnits, positions, mealTypesMap);
};

const generateColumns = (dates, coordinates) => {
    let columns = { ...coordinates.cols };

    for (const day of dates) {
        columns[cssDateVar(day)] = {
            key: cssDateVar(day),
            dimension: 'minmax(18rem, 1fr)',
        };
    }
    return columns;
};

const generateGridCss = (coordinates) => {
    const initVal = '';
    return Object.values(coordinates).reduce(
        (acc, currentVal, index, array) => {
            const { key, dimension } = currentVal;
            const lastItemName = array[index - 1]?.key;

            switch (true) {
                case index === 0:
                    return `[first-start] 0 [first-end ${key}-start] var(--box-first-column)`;
                case index === array.length - 1:
                    return `${acc} [${lastItemName}-end ${key}-start] ${dimension} [${key}-end last-start] 0 [last-end]`;
                default:
                    return `${acc} [${lastItemName}-end ${key}-start] ${dimension}`;
            }
        },
        initVal
    );
};

const generateRows = (planerUnits, mealTypesMap, coordinates) => {
    let rows = { ...coordinates.rows };

    for (const type of Object.values(mealTypesMap)) {
        let height;

        let positionsCount = maxPositionsCount(planerUnits, type.mealTypeId);

        switch (true) {
            default:
            case type.isDefault:
                height = 'auto';
                break;
            case positionsCount === 0:
                height = '0';
                break;
        }

        rows[type.key] = { ...type, dimension: height, positionsCount };
    }
    return rows;
};

const delPositionsByMelaType = (mealTypeId, units) => {
    const payload = [];

    const matchingUnits = Object.values(units).filter(
        (unit) => unit.mealTypeId === mealTypeId
    );

    for (const unit of matchingUnits) {
        for (const position of unit.positions) {
            payload.push({
                action: 'delete',
                positionId: position.positionId,
            });
        }
    }

    return payload;
};

const excludedTypesArr = (rows) => {
    const excludedTypesArr = [];
    for (const row of Object.values(rows)) {
        if (row.dimension === 'auto') {
            excludedTypesArr.push(row.mealTypeId);
        }
    }
    return excludedTypesArr;
};

export {
    cssDateVar,
    unitKey,
    posKey,
    mapArrayToObj,
    generateEmptyUnits,
    populatePlannerUnits,
    generateColumns,
    generateRows,
    generateGridCss,
    generateDatesArray,
    generateAndPopulateUnits,
    delPositionsByMelaType,
    excludedTypesArr,
    generateDatesArrayFromState,
    weekDaysArr,
    updateObjInArray,
};
