import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    content,
    relatedSeparator,
    loader,
    hero,
    heroContent,
    error,
    related,
    comments,
    layout,
    editLoading,
    rating,
    ratingContainer,
    heroRatioClass,
} from './recipe.module.scss';
import { getSingleRecipeAction } from '../redux/recipes/single/actions/get-single-recipe';
import { getAbsolutePath } from '../routes';
import { addModalAction } from '../redux/actions/actions-modals';
import {
    rateSingleRecipeAction,
    rateSingleRecipeClearErrorAction,
} from '../redux/recipes/single/actions/rate-recipe';
import { commentSingleRecipeAction } from '../redux/recipes/single/actions/comment-recipe';
import { getCurrentProfileId } from '../redux/profile/profile.selectors';
import { changeSingleRecipeIngredientToProfileAction } from '../redux/recipes/single/actions/change-ingredient-to-profile';
import { config } from '../config';
import { entity } from '../rbac/permissions';

import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import Loader from '../components/atoms/loader';
import BigTitle from '../components/atoms/big-title';
import RecipeMetadata from '../components/atoms/recipe/recipe-metadata';
import RecipeAuthor from '../components/atoms/recipe/recipe-author';
import Separator from '../components/atoms/separator';
import UserCan from '../rbac/hoc';
import Rating from '../components/organisms/rating';
import Comments from '../components/organisms/comments';
import RelatedList from '../components/organisms/related-list';
import RecipeCard from '../components/molecules/recipe/recipe-card';
import RecipeContent from '../components/organisms/recipe-content';

const ratingLabelMap = [
    { value: 0.5, label: 'Nie ma mowy!' },
    { value: 1, label: 'Bardzo słaby' },
    { value: 1.5, label: 'Słaby' },
    { value: 2, label: 'Raczej nie' },
    { value: 2.5, label: 'Ujdzie' },
    { value: 3, label: 'W miarę' },
    { value: 3.5, label: 'Nawet spoko' },
    { value: 4, label: 'Dobre' },
    { value: 4.5, label: 'Pyszne' },
    { value: 5, label: 'Wybitne!' },
];

const Recipe = ({
    id,
    recipe,
    errors,
    profileId,
    type,
    toggleIsFavoriteLoading,
    addModalAction,
    getRecipeAction,
    rateRecipeAction,
    rateRecipeClearErrorAction,
    commentRecipeAction,
    changeIngredientAction,
    rateLoading,
    rateError,
}) => {
    const userCanRecipes = UserCan({
        action: entity.RECIPES,
        yes: () => true,
        no: () => false,
    });

    const handleRateRecipe = (rate) => {
        rateRecipeAction(id, profileId, rate);
    };

    const handleCommentSubmit = (formValues, formikBag) => {
        commentRecipeAction(id, profileId, formValues, formikBag);
    };

    const handleIngredientChange = (changeData) => {
        changeIngredientAction(id, profileId, changeData);
    };

    useEffect(() => {
        if (!recipe) {
            getRecipeAction(id);
        }
    }, [recipe, getRecipeAction, id]);

    useEffect(() => {
        if (rateError) {
            addModalAction({
                type: 'error',
                title: 'Ups!',
                content: 'Nie udało się dodać Twojej oceny',
            });
            rateRecipeClearErrorAction();
        }
    }, [rateError, addModalAction, rateRecipeClearErrorAction]);

    if (errors) {
        return (
            <Main transparentHeader={false} isPaddingEqualToHeader={true}>
                <div className={error}>Niestety, nie udało się odnaleźć przepisu...</div>
            </Main>
        );
    }

    if (!recipe) {
        return (
            <Main transparentHeader={false} isPaddingEqualToHeader={true}>
                <div className={loader}>
                    <Loader fullContainer={true} />
                </div>
            </Main>
        );
    }

    const recipeBanner = {
        ratioImage: {
            src: recipe.coverUri,
            ratioClass: heroRatioClass,
        },
    };

    return (
        <Main transparentHeader={true} className={layout} isPaddingEqualToHeader={true}>
            <MainBanner
                bannerData={recipeBanner}
                className={hero}
                dark={true}
                alt="Banner przepisu"
            >
                <div className={heroContent}>
                    {recipe.author && <RecipeAuthor author={recipe.author} />}
                    <BigTitle Tag="h1">{recipe.name}</BigTitle>
                    <RecipeMetadata color="white" recipe={recipe} fullInfo={true} />
                </div>
            </MainBanner>
            <RecipeContent
                className={content}
                recipe={recipe}
                type={type}
                onIngredientChange={handleIngredientChange}
            />
            {type !== config.recipeTypesMap.your && (
                <div className={ratingContainer}>
                    <Separator />
                    <Rating
                        title="Twoja ocena"
                        subtitle={
                            userCanRecipes
                                ? `Kliknij na gwiazdki i oceń przepis`
                                : `Aby dodać ocenę musisz mieć subskrypcję`
                        }
                        className={rating}
                        onChange={handleRateRecipe}
                        initialValue={recipe.rate?.rate || 0}
                        voiceCount={recipe.rate?.ratesCount || 0}
                        loading={rateLoading}
                        labelMap={ratingLabelMap}
                        disabled={!userCanRecipes}
                    />
                </div>
            )}
            {type !== config.recipeTypesMap.your && (
                <Comments
                    className={comments}
                    onSubmit={handleCommentSubmit}
                    items={recipe.comments}
                    disabled={!userCanRecipes}
                />
            )}
            {recipe.relatedRecipes.length > 0 && userCanRecipes && (
                <div className={`${related} ${toggleIsFavoriteLoading && editLoading}`}>
                    <Separator className={relatedSeparator} />
                    <RelatedList title="Zobacz inne przepisy">
                        {recipe.relatedRecipes
                            .sort((a, b) => b.createdAt - a.createdAt)
                            .slice(0, 3)
                            .map((relatedRecipe) => (
                                <RecipeCard
                                    item={{ ...relatedRecipe, type }}
                                    path={getAbsolutePath('APP_RECIPE')}
                                />
                            ))}
                    </RelatedList>
                </div>
            )}
        </Main>
    );
};

const mapStateToProps = (state, { id }) => {
    const recipe = state.recipes.single.get.data;
    return {
        recipe: recipe.id === Number(id) ? recipe : null,
        loading: state.recipes.single.get.loading,
        errors: state.recipes.single.get.errors,
        profileId: getCurrentProfileId(state),
        toggleIsFavoriteLoading: state.recipes.single.toggleIsFavorite.loading,
        rateLoading: state.recipes.single.rate.loading,
        rateError: state.recipes.single.rate.errors,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getRecipeAction: (id) => dispatch(getSingleRecipeAction(id)),
    addModalAction: (modalData) => dispatch(addModalAction(modalData)),
    rateRecipeAction: (id, profileId, rate) =>
        dispatch(rateSingleRecipeAction(id, profileId, rate)),
    rateRecipeClearErrorAction: () => dispatch(rateSingleRecipeClearErrorAction()),
    commentRecipeAction: (id, profileId, formValues, formikBag) =>
        dispatch(commentSingleRecipeAction(id, profileId, formValues, formikBag)),
    changeIngredientAction: (id, profileId, data) =>
        dispatch(changeSingleRecipeIngredientToProfileAction(id, profileId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recipe);
