import React from 'react';

import { wrapper, title as titleClass, content as contentClass } from './empty-courses.module.scss';

import Button from '../button';

const EmptyCourses = ({ title, content, buttonContent, className = '', onClick }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <div className={titleClass}>{title}</div>
            <div className={contentClass}>{content}</div>
            <div>
                <Button onClick={() => onClick()} color={'yellow'}>
                    {buttonContent}
                </Button>
            </div>
        </div>
    );
};

export default EmptyCourses;
