import { actions } from '../actions/current-course.actions';

const initialState = {
    courseId: null,
    isBought: null,
    data: {
        description: null,
        points: [],
        price: {
            grossDisplay: null,
        },
    },
    chosenCategoryData: {
        id: null,
        movies: [],
        name: null,
        shortDescription: null,
    },
    chosenMovie: {
        id: null,
        name: null,
        videoDuration: null,
        videoProvider: null,
        uri: null,
        description: null,
        information: null,
        sequence: null,
        coverViewUri: null,
        coverListUri: null,
    },
    meta: {
        loading: false,
        success: null,
        errors: null,
    },
};

let res;

export default function currentCourseReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case actions.SET_COURSE_ID:
            return {
                ...state,
                courseId: payload,
            };

        case actions.LOADING_COURSE_START:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    loading: true,
                    success: null,
                    errors: null,
                },
            };

        case actions.LOADING_COURSE_SUCCESS:
            return {
                ...state,
                data: payload,
                meta: {
                    ...state.meta,
                    loading: false,
                    success: true,
                },
            };

        case actions.LOADING_COURSE_ERROR:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    success: false,
                    loading: false,
                    errors: payload,
                },
            };

        case actions.SET_CHOSEN_CATEGORY:
            res = state.data.categories.find((category) => category.id === payload);
            return {
                ...state,
                chosenCategoryData: res,
            };

        case actions.SET_CHOSEN_MOVIE:
            return {
                ...state,
                chosenMovie: payload,
            };
        case actions.CLEAR_COURSE:
            return {
                ...initialState,
            };

        default:
            return state;
    }
}
