import React from 'react';

import {
    discountsDropdown,
    dropdownBadge,
    dropdownItem,
    dropdownLabel,
} from './discounts-dropdown.module.scss';

import Dropdown from './dropdown';
import Label from '../atoms/label';
import Badge from '../atoms/badge';

export interface IDiscountsDropdownProps {
    open?: boolean;
    benefits: string[];
    conditions: string[];
    offset?: number;
}

export default function DiscountsDropdown({
    open,
    benefits,
    conditions,
    offset,
}: IDiscountsDropdownProps) {
    const limit = Math.min(benefits.length, conditions.length);
    const slicedBenefits = benefits.slice(offset, limit);

    return (
        <Dropdown open={open} isTriangle={false} className={discountsDropdown}>
            {conditions.slice(offset, limit).map((condition, index) => (
                <div key={`discount-dropdown-item-${index}`} className={dropdownItem}>
                    <Label color="--color-discounts-primary" className={dropdownLabel}>
                        {condition}
                    </Label>
                    <Badge color="var(--color-discounts-primary)" className={dropdownBadge}>
                        {slicedBenefits[index]}
                    </Badge>
                </div>
            ))}
        </Dropdown>
    );
}
