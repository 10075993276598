import React from 'react';

import { line, verticalStyle } from './meal-planer-divider.module.scss';

const MealPlanerDivider = ({ row, col = '1 / -1', vertical = false }) => {
    const dividerStyle = {
        '--col': col,
        '--row': row,
    };

    const cssClass = `${line} ${vertical ? verticalStyle : ''} meal-planer__grid-position `;

    return (
        <span style={dividerStyle} className={cssClass}>
            {' '}
        </span>
    );
};

export default MealPlanerDivider;
