import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';

import {
    item as itemClass,
    deleteButton,
    deleteButtonText,
    detailsButton,
    itemActions,
    itemHeader,
    listIcon,
} from './shopping-list-item.module.scss';
import ShoppingListIcon from '../../assets/images/svg/icon-shopping-list.svg';
import TrashIcon from '../../assets/images/svg/icon-trash.svg';
import { addModalAction } from '../../redux/actions/actions-modals';
import { deleteShoppingList } from '../../redux/single-shopping-list/single-shopping-list.actions';

import Button from '../atoms/button';

const ShoppingListItem = ({ Tag, item }) => {
    const dispatch = useDispatch();

    const handleClickOnTrashIcon = () => {
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: 'Usunąć listę zakupową?',
                    content: 'Lista zakupowa zostanie nieodwracalnie usunięty z bazy.',
                    onConfirm: () => {
                        dispatch(deleteShoppingList(item.id));
                    },
                },
            })
        );
    };

    return (
        <Tag className={itemClass}>
            <Link to={`${item.id}`} className={itemHeader}>
                <ShoppingListIcon className={listIcon} />
                <div>
                    {item.name}&nbsp;
                    {`  `}•&nbsp;&nbsp;
                    <Moment locale="pl" format="DD.MM.YYYY" unix={true} element={''}>
                        {item.createdAt}
                    </Moment>
                </div>
            </Link>
            <div className={itemActions}>
                <Link to={`${item.id}`}>
                    <Button color="blank" className={detailsButton} size="small">
                        Szczegóły
                    </Button>
                </Link>
                <button className={deleteButton} onClick={handleClickOnTrashIcon}>
                    <TrashIcon />
                    <span className={deleteButtonText}>Usuń listę</span>
                </button>
            </div>
        </Tag>
    );
};

ShoppingListItem.propTypes = {
    Tag: PropTypes.string,
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        createdAt: PropTypes.number.isRequired,
    }).isRequired,
};

ShoppingListItem.defaultProps = {
    Tag: 'li',
};

export default ShoppingListItem;
