import React from 'react';
import { useSelector } from 'react-redux';

import {
    balance as balanceClass,
    left,
    pointsBalance,
    content,
    loader,
    right,
    icon,
} from './points-balance.module.scss';
import { config } from '../../config';
import IconInfoFull from '../../assets/images/svg/icon-info-black.svg';
import {
    selectGamificationHistoryLoading,
    selectPointsBalance,
} from '../../redux/gamification/gamification.selectors';

import Loader from '../atoms/loader';

const { apiStatusMap } = config;

export interface IPointsBalanceProps {
    children?: React.ReactNode[] | React.ReactNode;
}

export default function PointsBalance({ children }: IPointsBalanceProps) {
    const balance = useSelector(selectPointsBalance);
    const isLoading = useSelector(selectGamificationHistoryLoading) === apiStatusMap.loading;

    return (
        <div className={pointsBalance}>
            {isLoading && <Loader fullContainer innerElementClassName={loader} />}
            <div className={left}>
                <IconInfoFull className={icon} />
                <p className={content}>
                    Twoje Ketony: <span className={balanceClass}>{balance}</span>
                </p>
            </div>
            {children && <div className={right}>{children}</div>}
        </div>
    );
}
