export const actionTypes = {
    /** Base Actions**/
    start: 'start',
    success: 'success',
    updateSuccess: 'updateSuccess',
    error: 'error',
    clearData: 'clearData',
    clearSuccess: 'clearSuccess',
    clearErrors: 'clearErrors',

    /** Paginated list **/
    countUpPageNumber: 'countUpPageNumber',
    checkTableDataStart: 'checkTableDataStart',
    checkTableDataSuccess: 'checkTableDataSuccess',
    checkTableDataError: 'checkTableDataError',
};
