import React from 'react';

import { wrapper, content } from './label.module.scss';

const Label = ({ inputStyles, children }) => {
    return (
        <div className={`${wrapper}  ${inputStyles} `}>
            <span className={content}>{children}</span>
        </div>
    );
};

export default Label;
