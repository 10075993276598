import React from 'react';

import Loader from '../../atoms/loader';
import List from './list';
import IntersectBox from '../../atoms/intersect-box';

const InfinityList = ({
    callbackFunction,
    loading,
    items,
    ItemComponent,
    targetPath,
    itemProps,
    listClassName,
    ...rest
}) => {
    return (
        <>
            <section {...rest}>
                <List
                    listClassName={listClassName}
                    ItemComponent={ItemComponent}
                    items={items}
                    path={targetPath}
                    itemProps={itemProps}
                    loading={loading}
                />
                <IntersectBox onIntersecting={callbackFunction}>
                    {loading && <Loader />}
                </IntersectBox>
            </section>
        </>
    );
};

export default InfinityList;
