import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import Markdown from '../hoc/markdown';

import {
    container,
    read,
    icon,
    contentBox,
    titleText,
    contentText,
    date,
} from './notification.module.scss';

const Notification = ({ className = '', item, TitleTag }) => {
    const { message, readAt, plannedAt } = item;
    return (
        <div className={`${container} ${className} ${readAt ? read : ''}`}>
            <span className={icon}>i</span>
            <div className={contentBox}>
                <TitleTag className={titleText}>{message.title}</TitleTag>
                {message.content && <Markdown className={contentText}>{message.content}</Markdown>}
                <Moment className={date} unix={true} locale="pl" format="hh:mm • DD MMMM YYYY">
                    {plannedAt}
                </Moment>
            </div>
        </div>
    );
};

Notification.propTypes = {
    className: PropTypes.string,
    item: PropTypes.shape({
        message: PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string,
        }),
        plannedAt: PropTypes.number.isRequired,
        readAt: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
    }).isRequired,
    TitleTag: PropTypes.string,
};

Notification.defaultProps = {
    className: '',
    TitleTag: 'h2',
};

export default Notification;
