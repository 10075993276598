import { useCallback, useEffect, useRef, useState } from 'react';

const useSlider = (config = {}) => {
    const [slideIndex, setSlideIndex] = useState(config.initialSlide || 0);
    const [isChanging, setIsChanging] = useState(false);
    const sliderRef = useRef();

    const sliderSettings = {
        beforeChange: (oldIndex, newIndex) => {
            if (oldIndex === newIndex) {
                return;
            }
            setIsChanging(true);
            setSlideIndex(newIndex);
        },
        afterChange: () => {
            setIsChanging(false);
        },
        ...config,
    };

    const getNext = () => {
        return sliderRef?.current?.slickNext();
    };

    const getPrev = () => {
        return sliderRef?.current?.slickPrev();
    };

    const goTo = useCallback((index) => {
        return sliderRef?.current?.slickGoTo(index);
    }, []);

    useEffect(() => {
        if (!sliderRef.current) return;
        goTo(slideIndex);
    }, [slideIndex, goTo]);

    return {
        sliderRef,
        slideIndex,
        setSlideIndex,
        isChanging,
        sliderSettings,
        getNext,
        getPrev,
    };
};

export default useSlider;
