// extracted by mini-css-extract-plugin
export var form = "credit-card-data-module--form--tMIUk";
export var iconBox = "credit-card-data-module--icon-box--hFznB";
export var info = "credit-card-data-module--info--wSObM";
export var infoBox = "credit-card-data-module--info-box--757gX";
export var infoCard = "credit-card-data-module--info-card--8BDoI";
export var infoCardContent = "credit-card-data-module--info-card-content--eEezi";
export var infoCardText = "credit-card-data-module--info-card-text--KJQfQ";
export var infoCardTitle = "credit-card-data-module--info-card-title--E2pEz";
export var infoHeader = "credit-card-data-module--info-header--WXfTj";
export var loaderBox = "credit-card-data-module--loader-box--lDdkz";
export var logo = "credit-card-data-module--logo--UgR+G";
export var payuImg = "credit-card-data-module--payu-img--3ai5T";
export var statusItem = "credit-card-data-module--status-item--M8A1l";
export var statusList = "credit-card-data-module--status-list--VBBdl";
export var title = "credit-card-data-module--title--qFVt4";
export var wrapper = "credit-card-data-module--wrapper--WMRCD";