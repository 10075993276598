import React from 'react';

import { wrapper, text } from './separator-with-text.module.scss';

const SeparatorWithText = ({ children, className = '' }) => (
    <div className={`${wrapper} ${className}`}>
        <span className={text}>{children}</span>
    </div>
);

export default SeparatorWithText;
