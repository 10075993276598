import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/pl';

import {
    name,
    separator,
    content as contentClass,
    avatar,
    comment as commentClass,
    commentBox,
    date,
} from './comment-item.module.scss';

import Avatar from '../atoms/avatar';
import Separator from '../atoms/separator';

const CommentItem = ({ comment }) => {
    const { author, content, createdAt } = comment;
    return (
        <div className={commentClass}>
            <Avatar
                className={avatar}
                avatarUri={author.avatarUri}
                size="large"
                alt={`${author.firstName} ${author.lastName}`}
                hasShadow={false}
                isDark={true}
            />
            <div className={commentBox}>
                <h3 className={name}>
                    {author.firstName} {author.lastName}
                    {` `}
                </h3>
                <div className={date}>
                    •{` `}
                    <Moment unix local format="LL">
                        {createdAt}
                    </Moment>
                    {` `}• godz.{` `}
                    <Moment unix local format="HH:mm">
                        {createdAt}
                    </Moment>
                </div>
                <p className={contentClass}>{content}</p>
                <Separator className={separator} />
            </div>
        </div>
    );
};

export default CommentItem;
