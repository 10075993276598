import React from 'react';

import { partnersWrapper, businessTile } from './business-partners-list.module.scss';
import { IPartnerZonePool } from '../../models/partners-zone.model';

import BusinessPartnerTile, { IBusinessPartnerTileProps } from './business-partner-tile';

export interface IBusinessPartnersListProps {
    items: IPartnerZonePool[];
    isLoading?: boolean;
    onClick?: (partner: IPartnerZonePool) => void;
    ItemComponent?: React.ElementType<IBusinessPartnerTileProps>;
}

export default function BusinessPartnersList({
    items,
    onClick,
    isLoading,
    ItemComponent = BusinessPartnerTile,
}: IBusinessPartnersListProps) {
    const handleTileClick = (partner: IPartnerZonePool) => () => {
        if (onClick) {
            onClick(partner);
        }
    };

    return (
        <>
            {!isLoading && items.length === 0 && <p>Niestety lista jest pusta</p>}
            <div className={partnersWrapper}>
                {items.map((partner: IPartnerZonePool) => (
                    <ItemComponent
                        key={`business-partner-tile-${partner.id}`}
                        data={partner}
                        className={businessTile}
                        onClick={handleTileClick(partner)}
                    />
                ))}
            </div>
        </>
    );
}
