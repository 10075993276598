import React from 'react';

import { discountList, discountItem } from './discount-benefit-list.module.scss';

import Badge from '../atoms/badge';

export interface IDiscountBenefitListProps {
    benefits: string[];
    className?: string;
    badgeClassName?: string;
    limit?: number;
    color?: string;
}

export default function DiscountBenefitList({
    benefits,
    className = '',
    badgeClassName = '',
    limit,
    color = '--color-discounts-primary',
}: IDiscountBenefitListProps) {
    if (benefits?.length > 0) {
        return (
            <div className={`${discountList} ${className}`}>
                {benefits.slice(0, limit).map((discount, index) => (
                    <Badge
                        key={`discount-badge-${index}`}
                        color={`var(${color})`}
                        className={`${discountItem} ${badgeClassName}`}
                    >
                        {discount}
                    </Badge>
                ))}
            </div>
        );
    }

    return null;
}
