// extracted by mini-css-extract-plugin
export var answerBox = "thread-module--answer-box--IIVL8";
export var back = "thread-module--back--ViMVQ";
export var backIcon = "thread-module--back-icon--j4YJs";
export var buttonAnswer = "thread-module--button-answer--Fcjld";
export var commentBox = "thread-module--comment-box--a1Hv9";
export var commentTree = "thread-module--comment-tree--fJ4Jv";
export var commentsContainer = "thread-module--comments-container--mWgEd";
export var container = "thread-module--container--r5SVD";
export var content = "thread-module--content--+r2Hb";
export var details = "thread-module--details--HPYno";
export var favorite = "thread-module--favorite--+02pj";
export var fixedLoader = "thread-module--fixed-loader--R+MJD";
export var header = "thread-module--header--NcTkg";
export var heartIcon = "thread-module--heart-icon--27CDk";
export var likeButton = "thread-module--like-button--FFx44";
export var loader = "thread-module--loader--msiK+";
export var loading = "thread-module--loading--VxUV9";
export var mainAnswerForm = "thread-module--main-answer-form--GWXQk";
export var message = "thread-module--message--x0GWb";
export var meta = "thread-module--meta--wb5F1";
export var moreButton = "thread-module--more-button--WS3lv";
export var title = "thread-module--title--ulxRo";
export var toggleLoading = "thread-module--toggle-loading--FQWTd";