// extracted by mini-css-extract-plugin
export var container = "my-shopping-lists-module--container--VZftR";
export var header = "my-shopping-lists-module--header--aV-MM";
export var headerAddButton = "my-shopping-lists-module--header-add-button--k-sK8";
export var list = "my-shopping-lists-module--list--Afnnj";
export var loader = "my-shopping-lists-module--loader--Wtm7-";
export var loading = "my-shopping-lists-module--loading--XPG1F";
export var noListAddButton = "my-shopping-lists-module--no-list-add-button--O1Go2";
export var noListBox = "my-shopping-lists-module--no-list-box--Mz38y";
export var noListComment = "my-shopping-lists-module--no-list-comment--DPONb";
export var separator = "my-shopping-lists-module--separator--0weMR";
export var title = "my-shopping-lists-module--title--sr9mg";