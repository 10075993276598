import React from 'react';
import PropTypes from 'prop-types';

import {
    confirmation,
    buttons,
    button,
    closeButton,
} from '../organisms/custom-modals/confirmation-modal.module.scss';
import { config } from '../../config';
import { entitiesArray, entity } from '../../rbac/permissions';

import Title from '../atoms/title';
import LinkButton from '../atoms/link-button';
import ButtonCloseModal from '../atoms/button-close-modal';

const { messages } = config;

const RestrictedModal = ({ modalId, permissionEntity }) => {
    return (
        <div className={confirmation}>
            <ButtonCloseModal modalId={modalId} className={closeButton} />
            <div>
                <Title Tag="h3">Drogi użytkowniku</Title>
                <p>{getContent(permissionEntity)}</p>
            </div>
            <div className={buttons}>
                <LinkButton to="/cennik" color="yellow" className={button}>
                    Kup karnet online
                </LinkButton>
            </div>
        </div>
    );
};

function getContent(permissionEntity) {
    switch (permissionEntity) {
        case entity.TRAININGS_ATLAS:
            return messages.forbidden.trainingAtlas;
        case entity.RECIPES:
            return messages.forbidden.standard;
        case entity.FOOD_PLANNER_ACCESS:
            return messages.forbidden.standard;
        case entity.PERSONAL_TRAININGS:
            return messages.forbidden.standard;
        default:
            return messages.forbidden.premium;
    }
}

RestrictedModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    permissionEntity: PropTypes.oneOf([...entitiesArray(), PropTypes.instanceOf(null), '']),
};

RestrictedModal.defaultProps = {
    permissionEntity: null,
};

export default RestrictedModal;
