import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    nameText,
    delBtn,
    card,
    labels,
    portionsText,
    yourRecipeLabel,
    actions,
    actionButton,
    copy,
    move,
    preview,
} from './meal-card.module.scss';
import CommentsIcon from '../../../assets/images/svg/icon-comment.svg';
import TrashIcon from '../../../assets/images/svg/icon-trash.svg';
import CopyIcon from '../../../assets/images/svg/copy.svg';
import MoveIcon from '../../../assets/images/svg/move.svg';
import EyeIcon from '../../../assets/images/svg/eye.svg';
import { types } from '../../../redux/meal-planer/meal-planer.actions';
import { types as templateTypes } from '../../../redux/meal-planer-template/meal-planer-template.actions';

const MealCard = ({ isTemplate = false, position, unitId }) => {
    const { isMyRecipe, name, portions, positionId, notes, mealId, recipeId } = position;
    const dispatch = useDispatch();

    const dragPosition = (ev, mode) => {
        ev.dataTransfer.setData(
            'text/plain',
            JSON.stringify({ positionId, portions, mealId, recipeId, mode })
        );
    };

    const deleteItem = () => {
        const action = { payload: positionId };
        action.type = isTemplate ? templateTypes.DEL_ITEM : types.DEL_ITEM;
        dispatch(action);
    };

    const itemPreview = () => {
        const action = { payload: { positionId, unitId } };
        action.type = isTemplate ? templateTypes.SHOW_ITEM_PREVIEW : types.SHOW_ITEM_PREVIEW;
        dispatch(action);
    };

    return (
        <div className={`meal-planer__box`}>
            <button type={'button'} className={delBtn} onClick={deleteItem}>
                <TrashIcon />
            </button>
            <div className={card} tabIndex={0}>
                <h4 className={nameText} title={name}>
                    {name}
                </h4>
                <span className={portionsText}>Porcja: {portions}</span>
                <div className={labels}>
                    {Array.isArray(notes) && notes.length > 0 && (
                        <CommentsIcon className={`meal-planer__icon`} />
                    )}
                    {(isMyRecipe || mealId) && (
                        <span className={yourRecipeLabel}>
                            {isMyRecipe ? 'Twój przepis' : 'Twój posiłek'}
                        </span>
                    )}
                </div>
                <div className={actions} role="button" onClick={itemPreview}>
                    <button className={`${actionButton} ${preview}`} title="Zobacz">
                        <EyeIcon />
                    </button>
                    <button
                        title="Kopiuj"
                        className={`${actionButton} ${copy}`}
                        draggable
                        onDragStart={(ev) => dragPosition(ev, 'copy')}
                    >
                        <CopyIcon />
                    </button>
                    <button
                        title="Przenieś"
                        className={`${actionButton} ${move}`}
                        draggable
                        onDragStart={(ev) => dragPosition(ev, 'move')}
                    >
                        <MoveIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MealCard;
