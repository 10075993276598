import React from 'react';
import { useDispatch } from 'react-redux';

import { linksWrapper as commonLinksWrapper } from './gamification-introduction.module.scss';
import { linksWrapper, dropdown } from './gamification-businnes-partners-zone.module.scss';
import { config } from '../../config';
import { IPartnerZonePool } from '../../models/partners-zone.model';
import useAutoFetchGenericList from '../../hooks/use-auto-fetch-generic-list';
import { PARTNERS_ZONE, POOL } from '../../redux/partners-zone/partners-zone.reducer';
import useGenericFilters from '../../hooks/use-generic-filters';
import { viewPartnersZoneModal } from '../../redux/partners-zone/partners-zone.actions';

import PointsTitle from '../../components/atoms/points-title';
import PointsBalance from '../../components/molecules/points-balance';
import FilterList from '../../components/molecules/filter-molecules/filter-list';
import IntersectBox from '../../components/atoms/intersect-box';
import Loader from '../../components/atoms/loader';
import BusinessPartnersList from '../../components/molecules/business-partners-list';

export default function GamificationBusinessPartnersZone() {
    const {
        items,
        filters,
        applyFilters: onApplyFilters,
        getNextPage,
        apiStatus,
    } = useAutoFetchGenericList({
        entity: PARTNERS_ZONE,
        subEntity: POOL,
        url: config.endpoints.partnersZone.pool,
        perPage: 50,
        expand: 'partner',
    });

    const {
        filtersCollection,
        applyFilters,
        filterClear,
        filterClick,
        toggleOpen,
        openedItem,
    } = useGenericFilters({ filters, onApplyFilters });

    const dispatch = useDispatch();

    const handleTileClick = (partner: IPartnerZonePool) => {
        dispatch(viewPartnersZoneModal(partner));
    };

    const isLoading = apiStatus === config.apiStatusMap.loading;

    if (items.length === 0 && isLoading) {
        return (
            <>
                <Loader />
            </>
        );
    }

    return (
        <>
            <PointsTitle
                after={
                    <FilterList
                        items={filtersCollection}
                        itemProps={{
                            applyFilters,
                            filterClick,
                            clear: filterClear,
                            handleOpen: toggleOpen,
                            openedItem,
                            className: dropdown,
                            withButtons: false,
                        }}
                    />
                }
            >
                Wymień swoje ketony na super zniżki
            </PointsTitle>
            <PointsBalance />
            <div className={`${commonLinksWrapper} ${linksWrapper}`}>
                {!isLoading && (
                    <BusinessPartnersList
                        items={items}
                        isLoading={isLoading}
                        onClick={handleTileClick}
                    />
                )}
                <IntersectBox onIntersecting={getNextPage}>{isLoading && <Loader />}</IntersectBox>
            </div>
        </>
    );
}
