import { getAbsolutePath } from '../routes';
import useIsProfileRecipeOwner from './use-is-profile-recipe-owner';

const useRecipeAbsolutePath = (recipe) => {
    const isOwnedByProfile = useIsProfileRecipeOwner(recipe);
    const nodeKey = isOwnedByProfile ? 'APP_RECIPE_YOUR' : 'APP_RECIPE';
    return `${getAbsolutePath(nodeKey)}/${recipe.id}`;
};

export default useRecipeAbsolutePath;
