import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';

import {
    title,
    card,
    img,
    iconWrapper,
    imgWrapper,
    label,
    ratioClass,
} from './personal-training-item-card.module.scss';

import Title from '../../../atoms/title';
import Label from './additionals/label/label';
import Button from '../../../atoms/button';
import RatioImage from '../../../atoms/ratio-image';

const PersonalTrainingItemCard = ({ item, path }) => {
    // TODO: Change it into component
    const renderImg = (imgUrl) => {
        return (
            <div className={imgWrapper}>
                <span className={`${iconWrapper}`}>
                    <Button outline={true} color="white">
                        ZOBACZ TRENING
                    </Button>
                </span>

                <RatioImage src={imgUrl} className={img} alt="title" ratioClass={ratioClass} />
            </div>
        );
    };

    const Tag = ({ children }) => {
        return <Link to={`${path}/${item.id}`}>{children}</Link>;
    };

    return (
        <Tag>
            <article className={card}>
                {renderImg(item.coverUri)}

                <Label inputStyles={label}>ZAKOŃCZONY</Label>
                <span>
                    {moment(item.dateFrom).format('DD.MM')}&nbsp;-&nbsp;
                    {moment(item.dateTo).format('DD.MM.YYYY')}
                </span>
                <Title Tag={'h3'} className={title}>
                    {item.name}
                </Title>
            </article>
        </Tag>
    );
};

export default PersonalTrainingItemCard;
