import React from 'react';
import { Link } from 'gatsby';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
    img,
    card,
    imgWrapper,
    metadata,
    title,
    imgLink,
    headerLink,
    actionBox,
    lockBox,
    lockLayer,
    addButton,
    ratioClass,
} from './recipe-card.module.scss';
import PlusIcon from '../../../assets/images/svg/plus.svg';
import LockIcon from '../../../assets/images/svg/icon-lock.svg';
import { config } from '../../../config';
import { addModalAction } from '../../../redux/actions/actions-modals';
import { forbiddenClick } from '../../../redux/rbac/rbac.actions';
import { sendAddItem } from '../../../redux/meal-planer/meal-planer.actions';
import { entity } from '../../../rbac/permissions';
import { defaultFormat } from '../../../utills/date-utils';
import UserCan from '../../../rbac/hoc';

import RecipeMetadata from '../../atoms/recipe/recipe-metadata';
import Button from '../../atoms/button';
import AddRecipeToPlanModal from '../../organisms/custom-modals/add-recipe-to-plan-modal';
import RatioImage from '../../atoms/ratio-image';
import DeleteButton from '../../atoms/recipe/delete-button';
import EditButton from '../../atoms/recipe/edit-button';
import LikeButton from '../../atoms/recipe/like-button';

const RecipeCard = ({ item, path }) => {
    const { id, coverThumbnailUri, coverUri, name, type, restrictedFields } = item;

    const dispatch = useDispatch();

    const isRestricted = restrictedFields && restrictedFields.length > 0;
    const Tag = isRestricted ? 'button' : Link;
    const tagProp = isRestricted
        ? { onClick: () => dispatch(forbiddenClick()) }
        : { to: `${path}/${id}` };

    const userCanPlanner = UserCan({
        action: entity.FOOD_PLANNER_ACCESS,
        yes: () => true,
        no: () => false,
    });

    const handleSubmitRecipeToPlan = (formValues, formikBag, modalId) => {
        const payload = {
            date: moment(formValues.date).format(defaultFormat),
            mealTypeId: formValues.mealType.mealTypeId,
            recipeId: id,
        };
        const meta = { modalId, formikBag };
        dispatch(sendAddItem(payload, meta));
    };

    const handleAddToPlan = () => {
        if (userCanPlanner) {
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <AddRecipeToPlanModal
                            modalId={modalId}
                            onSubmit={handleSubmitRecipeToPlan}
                        />
                    ),
                })
            );
        } else {
            dispatch(forbiddenClick());
        }
    };

    return (
        <div className={card}>
            <div className={imgWrapper}>
                <Tag className={imgLink} {...tagProp}>
                    <RatioImage
                        src={coverThumbnailUri || coverUri}
                        className={img}
                        alt="Miniatura przepisu"
                        ratioClass={ratioClass}
                    />
                </Tag>
                {isRestricted ? (
                    <div className={lockLayer}>
                        <div className={lockBox}>
                            <LockIcon />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={actionBox}>
                            {type === config.recipeTypesMap.your ? (
                                <>
                                    <EditButton recipeId={id} color="transparentBlack" />
                                    <DeleteButton recipeId={id} color="transparentBlack" />
                                </>
                            ) : (
                                <LikeButton recipeId={id} color="transparentBlack" />
                            )}
                        </div>
                        <Button
                            icon={PlusIcon}
                            className={addButton}
                            size="small"
                            color="transparentBlack"
                            onClick={handleAddToPlan}
                        >
                            Dodaj do planera
                        </Button>
                    </>
                )}
            </div>
            <Tag className={headerLink} {...tagProp}>
                <RecipeMetadata recipe={item} color="grey" className={metadata} />
                <h3 className={title}>{name}</h3>
            </Tag>
        </div>
    );
};

export default RecipeCard;
