/**
 *
 *                                        *template*
 *    module: {
 *        baseName: string
 *        methodsName: {[key: string]: string}
 *        allowedMethods: {[key: string]: string} *REDUX METHOD eq.(set, get)* from ./action-types.js;
 *    }
 *
 *
 **/
import { actionTypes } from '../action-types';

export const entitiesMap = {
    newsletter: {
        baseName: `[NEWSLETTER]`,

        methodName: {
            subscribe: 'subscribe',
            unsubscribe: 'unsubscribe',
        },

        allowedActions: {
            start: actionTypes.start,
            success: actionTypes.success,
            error: actionTypes.error,
            clearSuccess: actionTypes.clearSuccess,
            clearErrors: actionTypes.clearErrors,
        },
    },

    personalTraining: {
        Previous: {
            list: {
                baseName: `[PERSONAL TRAINING PREVIOUS LIST]`,

                methodName: {
                    loadList: `load list`,
                },

                allowedActions: {
                    start: actionTypes.start,
                    success: actionTypes.success,
                    error: actionTypes.error,

                    countUpPageNumber: actionTypes.countUpPageNumber,

                    checkTableDataStart: actionTypes.checkTableDataStart,
                    checkTableDataSuccess: actionTypes.checkTableDataSuccess,
                    checkTableDataError: actionTypes.checkTableDataError,
                },
            },
        },

        current: {
            data: {
                baseName: `[PERSONAL TRAINING CURRENT DATA]`,

                methodName: {
                    load: `load`,
                },

                allowedActions: {
                    start: actionTypes.start,
                    success: actionTypes.success,
                    error: actionTypes.error,
                },
            },

            calendar: {
                baseName: `[PERSONAL_TRAINING_CURRENT_CALENDAR]`,

                methodName: {
                    load: `load`,
                },

                allowedActions: {
                    start: actionTypes.start,
                    success: actionTypes.success,
                    error: actionTypes.error,
                },
            },
        },
    },

    shoppingList: {
        baseName: `[SHOPPING LIST]`,

        methodName: {
            fetch: 'fetch',
        },

        allowedActions: {
            start: actionTypes.start,
            success: actionTypes.success,
            error: actionTypes.error,

            countUpPageNumber: actionTypes.countUpPageNumber,
        },
    },

    recipes: {
        all: {
            baseName: `[RECIPES ALL]`,
            methodName: {
                get: `get`,
            },
            allowedActions: {
                start: actionTypes.start,
                success: actionTypes.success,
                error: actionTypes.error,
                clearData: actionTypes.clearData,
                countUpPageNumber: actionTypes.countUpPageNumber,
                checkTableDataStart: actionTypes.checkTableDataStart,
                checkTableDataSuccess: actionTypes.checkTableDataSuccess,
                checkTableDataError: actionTypes.checkTableDataError,
            },
        },
        public: {
            baseName: `[RECIPES PUBLIC]`,
            methodName: {
                get: 'get',
            },
            allowedActions: {
                start: actionTypes.start,
                success: actionTypes.success,
                error: actionTypes.error,
                clearData: actionTypes.clearData,
                countUpPageNumber: actionTypes.countUpPageNumber,
            },
        },
        your: {
            baseName: `[RECIPES YOUR]`,
            methodName: {
                get: `get`,
            },
            allowedActions: {
                start: actionTypes.start,
                success: actionTypes.success,
                error: actionTypes.error,
                clearData: actionTypes.clearData,
                countUpPageNumber: actionTypes.countUpPageNumber,
                checkTableDataStart: actionTypes.checkTableDataStart,
                checkTableDataSuccess: actionTypes.checkTableDataSuccess,
                checkTableDataError: actionTypes.checkTableDataError,
            },
        },
        favorite: {
            baseName: `[RECIPES FAVORITE]`,
            methodName: {
                get: `get`,
            },
            allowedActions: {
                start: actionTypes.start,
                success: actionTypes.success,
                error: actionTypes.error,
                clearData: actionTypes.clearData,
                countUpPageNumber: actionTypes.countUpPageNumber,
                checkTableDataStart: actionTypes.checkTableDataStart,
                checkTableDataSuccess: actionTypes.checkTableDataSuccess,
                checkTableDataError: actionTypes.checkTableDataError,
            },
        },
        single: {
            baseName: `[RECIPES SINGLE]`,
            methodName: {
                get: 'get',
                create: 'create',
                edit: 'edit',
                delete: 'delete',
                toggleIsFavorite: 'toggleIsFavorite',
                rate: 'rate',
                comment: 'comment',
                saveToPdf: 'saveToPdf',
                changeIngredientToProfile: 'changeIngredientToProfile',
            },
            allowedActions: {
                start: actionTypes.start,
                success: actionTypes.success,
                error: actionTypes.error,
                clearData: actionTypes.clearData,
                clearSuccess: actionTypes.clearSuccess,
                clearErrors: actionTypes.clearErrors,
            },
        },
        ingredients: {
            baseName: `[RECIPES INGREDIENTS]`,
            methodName: {
                get: 'get',
            },
            allowedActions: {
                start: actionTypes.start,
                success: actionTypes.success,
                error: actionTypes.error,
            },
        },
        units: {
            baseName: `[RECIPES UNITS]`,
            methodName: {
                get: `get`,
            },
            allowedActions: {
                start: actionTypes.start,
                success: actionTypes.success,
                error: actionTypes.error,
            },
        },
    },
};

export const getFullActionName = (entity, methodName, action = '') => {
    return `${entity + ' ' + methodName + ' ' + action}`;
};
