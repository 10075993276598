import { types as apiTypes } from '../api/api.actions';
import { config } from '../../config';

const PROFILE = '[PROFILE]';

export const types = {
    //document actions:
    SET_PROFILE_LIST: `${PROFILE} set_profile_list`,
    SET_CURRENT_PROFILE: `${PROFILE} set_current_profile`,
    SET_SINGLE_PROFILE: `${PROFILE} set_single_profile`,
    CLEAR_PROFILE_DATA: `${PROFILE} clear_profile_data`,
    CLEAR_CURRENT_PROFILE_ID: `${PROFILE} clear_current_profile_id`,

    //commands:
    INIT_PROFILE: `${PROFILE} init_profile`,
    CHANGE_CURRENT_PROFILE_ID: `${PROFILE} change_current_profile_id`,
    REMOVE_CURRENT_PROFILE_ID: `${PROFILE} remove_current_profile_id`,
    CLEAR_ALL_PROFILE_DATA: `${PROFILE} clear_all_profile_data`,
    UPDATE_PROFILE: `${PROFILE} update_profile`, //command
    UPDATE_SINGLE_PROFILE: `${PROFILE} update_single_profile`, //command

    //api actions:
    UPDATE_PROFILE_REQUEST: `${PROFILE} update_profile ${apiTypes.API_REQUEST}`,
    UPDATE_PROFILE_SUCCESS: `${PROFILE} update_profile ${apiTypes.API_SUCCESS}`,
    UPDATE_PROFILE_FAIL: `${PROFILE} update_profile ${apiTypes.API_FAIL}`,

    //events:
    CURRENT_PROFILE_ID_CHANGED: `${PROFILE} current_profile_id_changed`,
};

export const setCurrentProfileId = (profileId) => ({
    type: types.SET_CURRENT_PROFILE,
    profileId,
});

export const setSingleProfile = (payload) => ({
    type: types.SET_SINGLE_PROFILE,
    payload,
});

export const clearProfileData = () => ({
    type: types.CLEAR_PROFILE_DATA,
});

export const clearCurrentProfileId = () => ({
    type: types.CLEAR_CURRENT_PROFILE_ID,
});

export const setProfileList = (profileList) => ({
    type: types.SET_PROFILE_LIST,
    profileList,
});

export const initProfile = (profiles) => ({
    type: types.INIT_PROFILE,
    payload: profiles,
});

export const changeCurrentProfileId = (profileId) => ({
    type: types.CHANGE_CURRENT_PROFILE_ID,
    payload: profileId,
});

export const removeCurrentProfileId = () => ({
    type: types.REMOVE_CURRENT_PROFILE_ID,
});

export const clearAllProfileData = () => ({
    type: types.CLEAR_ALL_PROFILE_DATA,
});

export const updateProfile = (payload, formikBag, modalId, meta) => ({
    type: types.UPDATE_PROFILE,
    payload,
    meta: {
        ...meta,
        formikBag,
        modalId,
    },
});

export const updateProfileRequest = ({ profileId, profileData, meta }) => ({
    types: [
        types.UPDATE_PROFILE_REQUEST,
        types.UPDATE_PROFILE_SUCCESS,
        types.UPDATE_PROFILE_FAIL,
    ],
    payload: {
        request: {
            url: config.endpoints.accountData.edit(profileId),
            method: 'PATCH',
            data: profileData,
            withCredentials: true,
        },
    },
    meta,
});

export const currentProfileIdChanged = () => ({
    type: types.CURRENT_PROFILE_ID_CHANGED,
});
