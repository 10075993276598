import React from 'react';
import PropTypes from 'prop-types';

import { container, numbers, number, button } from './pagination.module.scss';

import Button from '../atoms/button';
import LinkButton from '../atoms/link-button';

const getArrayFromNumber = (count) => {
    return Array.from({ length: count }, (_, index) => index + 1);
};

const Pagination = ({ className = '', page, pageCount, disabled, onChange, type, prefix }) => {
    const handleClick = (selectedPage) => {
        onChange(selectedPage);
    };

    const Component = type === 'url' ? LinkButton : Button;

    if (!page || !pageCount || pageCount < 2) return null;

    return (
        <div className={`${container} ${className}`}>
            <Component
                className={button}
                color="blank"
                disabled={page <= 1 || disabled}
                {...(type === 'url'
                    ? {
                          to: `/${prefix}/${page > 2 ? `${page - 1}/` : ''}`,
                          small: true,
                      }
                    : {
                          onClick: () => handleClick(page - 1),
                          size: 'small',
                      })}
            >
                {'<'}
                <span>Poprzednia</span>
            </Component>
            <div className={numbers}>
                {getArrayFromNumber(pageCount).map((pageNumber) => {
                    return (
                        <Component
                            key={`pagination-${pageNumber}`}
                            className={number}
                            color={page === pageNumber ? 'black' : 'blank'}
                            circle={true}
                            disabled={disabled}
                            {...(type === 'url'
                                ? {
                                      to: `/${prefix}/${pageNumber > 1 ? `${pageNumber}/` : ''}`,
                                      small: true,
                                  }
                                : {
                                      onClick: () => handleClick(pageNumber),
                                      size: 'small',
                                  })}
                        >
                            {pageNumber}
                        </Component>
                    );
                })}
            </div>
            <Component
                className={button}
                color="blank"
                size="small"
                disabled={page >= pageCount || disabled}
                {...(type === 'url'
                    ? {
                          to: `/${prefix}/${page < pageCount ? `${page + 1}/` : ''}`,
                          small: true,
                      }
                    : {
                          onClick: () => handleClick(page + 1),
                          size: 'small',
                      })}
            >
                <span>Następna</span>
                {'>'}
            </Component>
        </div>
    );
};

Pagination.propTypes = {
    className: PropTypes.string,
    page: PropTypes.number,
    pageCount: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(['url', 'handler']),
    prefix: PropTypes.string,
};

Pagination.defaultProps = {
    className: '',
    page: null,
    pageCount: null,
    disabled: false,
    type: 'handler',
    prefix: '/',
    onChange: () => {},
};

export default Pagination;
