import { getAttachmentsList } from './api';
import { getCurrentProfileId } from '../../../profile/profile.selectors';

const moduleName = '[MESSENGER ATTACHMENTS]';

export const actions = {
    START_FETCHING: `${moduleName} start fetching`,
    LOADING_SUCCESS: `${moduleName} loading success`,
    SET_ERRORS: `${moduleName} set errors`,
};
const startFetching = () => ({
    type: actions.START_FETCHING,
});

const setLoadingSuccess = (payload) => ({
    type: actions.LOADING_SUCCESS,
    payload,
});

const setErrors = (payload) => ({
    type: actions.SET_ERRORS,
    payload,
});

export const fetchAttachments = () => async (dispatch, getState) => {
    const profileId = getCurrentProfileId(getState());

    dispatch(startFetching());

    try {
        const response = await getAttachmentsList(profileId);

        if (response.status === 200) {
            dispatch(setLoadingSuccess(response.data.items));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};
