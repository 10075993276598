import { types } from './profile.actions';

export const initialState = {
    currentId: null,
    list: {},
};

export default function profileReducer(state = initialState, action) {
    const { payload } = action;

    switch (action.type) {
        case types.SET_CURRENT_PROFILE:
            return { ...state, currentId: action.profileId };
        case types.SET_PROFILE_LIST:
            return { ...state, list: action.profileList };
        case types.SET_SINGLE_PROFILE:
            return {
                ...state,
                list: {
                    ...state.list,
                    [payload.id]: {
                        ...state.list[payload.id],
                        ...payload,
                    },
                },
            };
        case types.CLEAR_CURRENT_PROFILE_ID:
            return {
                ...state,
                currentId: initialState.currentId,
            };
        case types.CLEAR_PROFILE_DATA:
            return { ...initialState };
        default:
            return state;
    }
}
