import { types as apiTypes } from '../../api/api.actions';

export const ORDER = '[ORDER]';

export const types = {
    // user triggered actions
    SUBMIT: `${ORDER} submit`,
    CHECK: `${ORDER} check`,
    ORDER_VALIDATE: `${ORDER} validate`,
    GET_DELIVERY_METHODS: `${ORDER} get_delivery_methods`,
    REQUEST_PAY_BY_POINTS: `${ORDER} request_pay_by_points`,

    // api actions
    SEND_ORDER_REQUEST: `${ORDER} ${apiTypes.API_REQUEST}`,
    SEND_ORDER_SUCCESS: `${ORDER} ${apiTypes.API_SUCCESS}`,
    SEND_ORDER_FAIL: `${ORDER} ${apiTypes.API_FAIL}`,

    GET_DELIVERY_METHODS_REQUEST: `${ORDER} get_delivery_methods ${apiTypes.API_REQUEST}`,
    GET_DELIVERY_METHODS_SUCCESS: `${ORDER} get_delivery_methods ${apiTypes.API_SUCCESS}`,
    GET_DELIVERY_METHODS_FAIL: `${ORDER} get_delivery_methods ${apiTypes.API_FAIL}`,

    CHECK_ORDER_REQUEST: `${ORDER} check ${apiTypes.API_REQUEST}`,
    CHECK_ORDER_SUCCESS: `${ORDER} check ${apiTypes.API_SUCCESS}`,
    CHECK_ORDER_FAIL: `${ORDER} check ${apiTypes.API_FAIL}`,

    SEND_ORDER_VALIDATE_REQUEST: `${ORDER} validate ${apiTypes.API_REQUEST}`,
    SEND_ORDER_VALIDATE_SUCCESS: `${ORDER} validate ${apiTypes.API_SUCCESS}`,
    SEND_ORDER_VALIDATE_FAIL: `${ORDER} validate ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_DELIVERY_METHODS: `${ORDER} set_delivery_methods`,
    SET_CURRENT_DELIVERY_METHOD: `${ORDER} set_current_delivery`,
    SET_ORDER_CONSENTS: `${ORDER} set_consents`,
    CLEAR_ORDER_DATA: `${ORDER} clear_order_data`,

    SET_ORDER_DISCOUNT_CODE: `${ORDER} set_discount_code`,
    SET_CHOSEN_XSELLING_PROMOTIONS: `${ORDER} set_order_xselling_promotions`,
    CLEAR_XSELLING_PROMOTIONS: `${ORDER} clear_xselling_promotions`,

    SET_PAY_BY_POINTS_ITEM: `${ORDER} set_pay_by_points_item`,
    REMOVE_PAY_BY_POINTS_ITEM: `${ORDER} remove_pay_by_points_item`,
    SET_PAY_BY_POINTS_BALANCE: `${ORDER} set_pay_by_points_balance`,
};

export const submitOrder = (payload) => ({
    type: types.SUBMIT,
    payload: payload,
});

export const setOrderConsents = (payload) => ({
    type: types.SET_ORDER_CONSENTS,
    payload: payload,
});

export const clearOrderData = () => ({
    type: types.CLEAR_ORDER_DATA,
});

export const orderValidate = (payload, meta) => ({
    type: types.ORDER_VALIDATE,
    payload,
    meta, // { formikBag, nextStep }
});

export const requestPayByPoints = (payload) => ({
    type: types.REQUEST_PAY_BY_POINTS,
    payload,
});

export const setOrderDiscountCode = (payload) => ({
    type: types.SET_ORDER_DISCOUNT_CODE,
    payload,
});

export const getDeliveryMethods = (payload) => ({
    type: types.GET_DELIVERY_METHODS,
    payload,
});

export const setDeliveryMethods = (payload) => ({
    type: types.SET_DELIVERY_METHODS,
    payload,
});

export const setCurrentDelivery = (payload) => ({
    type: types.SET_CURRENT_DELIVERY_METHOD,
    payload,
});

export const setChosenXsellingPromotions = (payload) => ({
    type: types.SET_CHOSEN_XSELLING_PROMOTIONS,
    payload,
});

export const clearXsellingPromotions = () => ({
    type: types.CLEAR_XSELLING_PROMOTIONS,
});

export const setPayByPointsItem = (payload) => ({
    type: types.SET_PAY_BY_POINTS_ITEM,
    payload,
});

export const removePayByPointsItem = (payload) => ({
    type: types.REMOVE_PAY_BY_POINTS_ITEM,
    payload,
});

export const setPayByPointsBalance = (payload) => ({
    type: types.SET_PAY_BY_POINTS_BALANCE,
    payload,
});
