function sortValues(a, b) {
    return b[1].enabled - a[1].enabled || b[1].applied - a[1].applied;
}

function prepareObjectForDispatch(obj, ev) {
    const { name, value, type } = ev.target;
    const targetValueApplied = obj[name].values[value].applied;

    let params = type === 'radio' || type === 'select' ? clearFilters(obj, ev) : { ...obj };

    params = {
        ...params,
        [name]: {
            ...params[name],
            values: {
                ...params[name].values,
                [value]: {
                    ...params[name].values[value],
                    applied: !targetValueApplied,
                },
            },
        },
    };

    return params;
}

function clearFilters(obj, ev) {
    const { name } = ev.target;
    const filterToClear = { ...obj[name] };

    Object.entries(filterToClear.values).forEach(([valName, val]) => {
        filterToClear.values[valName] = {
            ...filterToClear.values[valName],
            applied: false,
        };
    });

    return {
        ...obj,
        [name]: filterToClear,
    };
}

export { sortValues, prepareObjectForDispatch, clearFilters };
