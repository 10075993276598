import { types as apiTypes } from '../api/api.actions';

export const SINGLE_SHOPPING_LIST = '[SINGLE_SHOPPING_LIST]';
export const SINGLE_SHOPPING_LIST_CHANGE = '[SINGLE_SHOPPING_LIST_CHANGE]';

export const types = {
    // flow/commands actions
    GET_SINGLE_SHOPPING_LIST: `${SINGLE_SHOPPING_LIST} get single`,
    CREATE_SHOPPING_LIST: `${SINGLE_SHOPPING_LIST} create`,
    EDIT_SHOPPING_LIST: `${SINGLE_SHOPPING_LIST} edit`,
    DELETE_SHOPPING_LIST: `${SINGLE_SHOPPING_LIST} delete`,
    CLEAR_SINGLE_SHOPPING_LIST: `${SINGLE_SHOPPING_LIST} clear`,

    // API actions
    FETCH_SINGLE_SHOPPING_LIST_REQUEST: `${SINGLE_SHOPPING_LIST} ${apiTypes.API_REQUEST}`,
    FETCH_SINGLE_SHOPPING_LIST_SUCCESS: `${SINGLE_SHOPPING_LIST} ${apiTypes.API_SUCCESS}`,
    FETCH_SINGLE_SHOPPING_LIST_FAIL: `${SINGLE_SHOPPING_LIST} ${apiTypes.API_FAIL}`,

    POST_SHOPPING_LIST_REQUEST: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_REQUEST} post`,
    POST_SHOPPING_LIST_SUCCESS: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_SUCCESS} post`,
    POST_SHOPPING_LIST_FAIL: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_FAIL} post`,

    PUT_SHOPPING_LIST_REQUEST: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_REQUEST} put`,
    PUT_SHOPPING_LIST_SUCCESS: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_SUCCESS} put`,
    PUT_SHOPPING_LIST_FAIL: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_FAIL} put`,

    DELETE_SHOPPING_LIST_REQUEST: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_REQUEST} delete`,
    DELETE_SHOPPING_LIST_SUCCESS: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_SUCCESS} delete`,
    DELETE_SHOPPING_LIST_FAIL: `${SINGLE_SHOPPING_LIST_CHANGE} ${apiTypes.API_FAIL} delete`,

    // writing actions (reducer)
    SET_SINGLE_SHOPPING_LIST: `${SINGLE_SHOPPING_LIST} set data`,
    RESTORE_INITIAL_STATE: `${SINGLE_SHOPPING_LIST} restore initial`,
};

// middleware actions
export const getSingleShoppingList = (id) => ({
    type: types.GET_SINGLE_SHOPPING_LIST,
    payload: id,
});

export const createShoppingList = (data, formikBag) => ({
    type: types.CREATE_SHOPPING_LIST,
    payload: data,
    meta: { formikBag },
});

export const editShoppingList = (id, data, formikBag, fromRecipe) => ({
    type: types.EDIT_SHOPPING_LIST,
    payload: { id, data },
    meta: { formikBag, fromRecipe },
});

export const deleteShoppingList = (id) => ({
    type: types.DELETE_SHOPPING_LIST,
    payload: id,
});

// reducer actions
export const setSingleShoppingList = (payload) => ({
    type: types.SET_SINGLE_SHOPPING_LIST,
    payload: payload,
});

export const clearSingleShoppingList = () => ({
    type: types.CLEAR_SINGLE_SHOPPING_LIST,
});

export const restoreInitialState = () => ({
    type: types.RESTORE_INITIAL_STATE,
});
