import React from 'react';
import { Link } from 'gatsby';

import {
    titleText,
    imageWrapper,
    ratio,
    container,
    tagsWrapper,
    little,
} from './post-preview.module.scss';
import { config } from '../../config';
import useMedia from '../../hooks/use-media';

import PostMetadata from '../atoms/post-metadata';
import TagList from './tag-list';
import RatioImage from '../atoms/ratio-image';

const PostPreview = ({
    small = false,
    post: { author, title, media, tags, slug, publishedAt },
}) => {
    const mediaItem = useMedia({
        media,
        relation: config.mediaRelationsMap.mainImage,
    });
    return (
        <div className={`${container} ${small ? little : ''}`}>
            <Link to={`/${slug}/`}>
                <div className={imageWrapper}>
                    <RatioImage src={mediaItem.url} ratioClass={ratio} />
                    {!small && (
                        <div className={tagsWrapper}>
                            <TagList tags={tags} />
                        </div>
                    )}
                </div>
                <PostMetadata isSmall={small} author={author} publishedAt={publishedAt} />
                <h4 className={titleText}>{title}</h4>
            </Link>
        </div>
    );
};

export default PostPreview;
