import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
    button,
    form,
    inputEmail,
    inputFirstName,
    inputLastName,
    loading,
} from './account-data.module.scss';
import { config } from '../../../config';
import { getCurrentProfile } from '../../../redux/profile/profile.selectors';
import { updateProfile } from '../../../redux/profile/profile.actions';

import Loader from '../../atoms/loader';
import Input from '../../atoms/form-poc/input';
import Button from '../../atoms/button';

const {
    form: { required, email },
} = config.messages;
const { formsStatusMap } = config;

const validationSchema = Yup.object({
    firstName: Yup.string().required(required),
    lastName: Yup.string().required(required),
    email: Yup.string().email(email),
});

const AccountData = () => {
    const dispatch = useDispatch();
    const { userData, currentProfile } = useSelector((state) => ({
        userData: state.user.userData,
        currentProfile: getCurrentProfile(state),
    }));

    const initialValues = {
        firstName: currentProfile.firstName,
        lastName: currentProfile.lastName,
        email: userData.email,
    };

    const handleSubmit = (formValues, formikBag) => {
        dispatch(updateProfile({ ...formValues, avatarId: currentProfile.avatarId }, formikBag));
    };

    return userData ? (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {(formik) => (
                <Form
                    className={`
                        ${form}
                        ${formsStatusMap.loading === formik.status ? loading : ''}
                    `}
                >
                    {formsStatusMap.loading === formik.status && (
                        <Loader fullContainer={true} noBackground={true} />
                    )}
                    <Input
                        name="firstName"
                        placeholder="Imię"
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        containerClass={inputFirstName}
                    />
                    <Input name="lastName" placeholder="Nazwisko" containerClass={inputLastName} />
                    <Input
                        name="email"
                        placeholder="email"
                        disabled={true}
                        containerClass={inputEmail}
                    />
                    <Button className={button} color="yellow">
                        Zapisz
                    </Button>
                </Form>
            )}
        </Formik>
    ) : (
        <Loader />
    );
};

export default AccountData;
