import React, { useState } from 'react';
import Truncate from 'react-truncate';
import PropTypes from 'prop-types';

import { button, buttonLess } from './read-more.module.scss';

const ReadMore = ({ children, className, lines, moreText, lessText }) => {
    const [expanded, setExpanded] = useState(false);
    const [truncated, setTruncated] = useState(false);

    const toggleExpanded = (event) => {
        event.preventDefault();
        setExpanded(!expanded);
    };

    const ellipsis = (
        <span>
            ...{' '}
            <button type="button" onClick={toggleExpanded} className={button}>
                {moreText}
            </button>
        </span>
    );

    return (
        <div className={className}>
            <Truncate
                lines={expanded ? false : lines}
                ellipsis={ellipsis}
                onTruncate={(value) => setTruncated(value)}
            >
                {children}
            </Truncate>
            {expanded && !truncated && (
                <button
                    className={`${button} ${buttonLess}`}
                    type="button"
                    onClick={toggleExpanded}
                >
                    {lessText}
                </button>
            )}
        </div>
    );
};

ReadMore.propTypes = {
    className: PropTypes.string,
    lines: PropTypes.number,
    moreText: PropTypes.string,
    lessText: PropTypes.string,
};

ReadMore.defaultProps = {
    className: '',
    lines: 1,
    moreText: 'Rozwiń',
    lessText: 'Zwiń',
};

export default ReadMore;
