import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    container,
    dropdown,
    dropdownButton,
    actionsDesktop,
    actionsMobile,
    listButton,
    pdfButton,
    counterBox,
    counterLabel,
    dotsMore,
    editLoading,
    planButton,
} from './recipe-header.module.scss';
import { config } from '../../../config';
import { getAbsolutePath } from '../../../routes';
import { entity } from '../../../rbac/permissions';
import UserCan from '../../../rbac/hoc';

import Counter from '../../atoms/counter';
import Dropdown from '../dropdown';
import LikeButton from '../../atoms/recipe/like-button';
import EditButton from '../../atoms/recipe/edit-button';
import DeleteButton from '../../atoms/recipe/delete-button';
import PDFButton from '../../atoms/recipe/pdf-button';
import AddToListButton from '../../atoms/recipe/add-to-list-button';
import AddToPlanButton from '../../atoms/recipe/add-to-plan-button';

const RecipeHeader = ({
    recipe,
    type,
    className,
    onPortionsChange,
    deleteSuccess,
    loading,
    multiplier,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const isRestricted = recipe.restrictedFields?.length > 0;
    const userCanRecipes = UserCan({
        action: entity.RECIPES,
        yes: () => true,
        no: () => false,
    });

    const handlePortionsChange = (value) => {
        onPortionsChange(value);
    };

    const handleButtonClick = () => {
        setDropdownOpen(false);
    };

    const getLocalIngredients = (withName) => {
        return recipe.ingredients.map((ingredient) => {
            return {
                ...(withName ? { name: ingredient.name } : {}),
                ingredientId: ingredient.id,
                unitId: ingredient.unitId,
                quantity: ingredient.quantity * multiplier,
            };
        });
    };

    if (deleteSuccess) {
        navigate(getAbsolutePath('APP_YOUR_RECIPES'), { replace: true });
        return null;
    }

    return (
        <div className={`${container} ${className} ${loading && editLoading}`}>
            <div className={counterBox}>
                <div className={counterLabel}>Liczba porcji:</div>
                <Counter min={1} initialValue={recipe.portions} onChange={handlePortionsChange} />
            </div>
            <div className={actionsDesktop}>
                {type !== config.recipeTypesMap.your ? (
                    <LikeButton recipeId={recipe.recipeId} onToggleFavorite={handleButtonClick} />
                ) : (
                    <>
                        <EditButton recipeId={recipe.recipeId} />
                        <DeleteButton recipeId={recipe.recipeId} />
                    </>
                )}
                <PDFButton
                    className={pdfButton}
                    ingredients={getLocalIngredients(false)}
                    id={recipe.id}
                    forbidden={!userCanRecipes}
                    permissionEntity={entity.RECIPES}
                    onSavePdfClick={handleButtonClick}
                />
                <AddToListButton
                    className={listButton}
                    ingredients={getLocalIngredients(true)}
                    onAddToListClick={handleButtonClick}
                    forbidden={isRestricted}
                />
                <AddToPlanButton
                    className={planButton}
                    recipeId={recipe.id}
                    onAddToPlanClick={handleButtonClick}
                    forbidden={isRestricted}
                />
            </div>
            <div className={actionsMobile}>
                <button onClick={() => setDropdownOpen(!dropdownOpen)} className={dotsMore}>
                    •••
                </button>
                <Dropdown
                    closeFunc={() => setDropdownOpen(false)}
                    open={dropdownOpen}
                    className={dropdown}
                    isTriangle={false}
                >
                    {type !== config.recipeTypesMap.your ? (
                        <LikeButton
                            recipeId={recipe.recipeId}
                            onToggleFavorite={handleButtonClick}
                            textOnly={true}
                            className={dropdownButton}
                        />
                    ) : (
                        <>
                            <EditButton
                                recipeId={recipe.recipeId}
                                textOnly={true}
                                className={dropdownButton}
                            />
                            <DeleteButton
                                recipeId={recipe.recipeId}
                                textOnly={true}
                                className={dropdownButton}
                            />
                        </>
                    )}
                    <PDFButton
                        className={dropdownButton}
                        ingredients={getLocalIngredients(false)}
                        id={recipe.id}
                        forbidden={!userCanRecipes}
                        permissionEntity={entity.RECIPES}
                        onSavePdfClick={handleButtonClick}
                        textOnly={true}
                    />
                    <AddToListButton
                        className={dropdownButton}
                        ingredients={getLocalIngredients(true)}
                        onAddToListClick={handleButtonClick}
                        forbidden={isRestricted}
                        textOnly={true}
                    />
                    <AddToPlanButton
                        className={dropdownButton}
                        recipeId={recipe.id}
                        onAddToPlanClick={handleButtonClick}
                        forbidden={isRestricted}
                        textOnly={true}
                    />
                </Dropdown>
            </div>
        </div>
    );
};

RecipeHeader.propTypes = {
    recipe: PropTypes.object.isRequired,
    type: PropTypes.string,
    onPortionsChange: PropTypes.func,
    className: PropTypes.string,
    multiplier: PropTypes.number,
};

RecipeHeader.defaultProps = {
    type: '',
    className: '',
    multiplier: 1,
};

const mapStateToProps = (state) => {
    const loading =
        state.recipes.single.delete.loading ||
        state.recipes.single.toggleIsFavorite.loading ||
        state.recipes.single.saveToPdf.loading;
    return {
        deleteSuccess: state.recipes.single.delete.success,
        loading: loading,
    };
};

export default connect(mapStateToProps)(RecipeHeader);
