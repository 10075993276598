import { PARTNERS_ZONE } from './partners-zone.reducer';
import { addModalAction } from '../actions/actions-modals';
import { types as apiTypes } from '../api/api.actions';

export const types = {
    BUY_CODE: `${PARTNERS_ZONE} buy_code`,

    BUY_CODE_REQUEST: `${PARTNERS_ZONE} buy_code ${apiTypes.API_REQUEST}`,
    BUY_CODE_SUCCESS: `${PARTNERS_ZONE} buy_code ${apiTypes.API_SUCCESS}`,
    BUY_CODE_FAIL: `${PARTNERS_ZONE} buy_code ${apiTypes.API_FAIL}`,
};

export const viewPartnersZoneModal = (partner) => {
    return addModalAction({
        modalKey: 'PARTNERS_ZONE_MODAL',
        modalProps: {
            data: partner,
            code: null,
        },
    });
};

export const buyCode = (payload, meta) => {
    return {
        type: types.BUY_CODE,
        payload,
        meta,
    };
};
