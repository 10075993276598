import axios from 'axios';

import { config } from '../config';

const axiosInstance = {
    default: {
        client: axios.create({
            baseURL: config.endpoints.baseApiUrl,
        }),
    },
};

export default axiosInstance;
