import { deleteSingleRecipe } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';
import { RECIPES, YOUR } from '../../recipes.reducer';
import { actions, genActionName } from '../../../generic-list/generic-list.actions';

export const deleteSingleRecipeAction = (id) => async (dispatch, getState) => {
    dispatch(deleteSingleRecipeStartAction());
    try {
        await deleteSingleRecipe(id);

        const yourCollection = getState()[RECIPES][YOUR];

        const newItems = yourCollection.items.filter((item) => item.id === id);
        const newPublic = {
            ...yourCollection,
            items: newItems,
        };

        dispatch({
            type: genActionName(`${RECIPES} ${YOUR}`, actions.document.set),
            payload: newPublic,
        });

        dispatch(deleteSingleRecipeSuccessAction());
    } catch (error) {
        dispatch(deleteSingleRecipeErrorAction(error));
    }
};

export const deleteSingleRecipeClearSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.delete,
        entitiesMap.recipes.single.allowedActions.clearSuccess
    ),
});

export const deleteSingleRecipeClearErrorsAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.delete,
        entitiesMap.recipes.single.allowedActions.clearErrors
    ),
});

const deleteSingleRecipeStartAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.delete,
        entitiesMap.recipes.single.allowedActions.start
    ),
});

const deleteSingleRecipeSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.delete,
        entitiesMap.recipes.single.allowedActions.success
    ),
    payload: {},
});

const deleteSingleRecipeErrorAction = (error) => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.delete,
        entitiesMap.recipes.single.allowedActions.error
    ),
    payload: error,
});
