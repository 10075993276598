import React from 'react';
import { useFormikContext } from 'formik';

import Checkbox, { ICheckboxProps } from './checkbox';

interface ICheckAllCheckboxProps extends Omit<ICheckboxProps, 'name'> {
    checkboxNames: string[];
    children?: React.ReactNode;
}

const CheckAllCheckbox: React.FC<ICheckAllCheckboxProps> = ({
    checkboxNames,
    children,
    ...rest
}) => {
    const { setFieldTouched, setFieldValue, getFieldMeta } = useFormikContext();
    const allChecked = checkboxNames.every((checkName) => getFieldMeta(checkName).value === true);

    const handleChange = () => {
        checkboxNames.forEach((checkboxName) => {
            setFieldValue(checkboxName, !allChecked);
            setTimeout(() => {
                setFieldTouched(checkboxName, true);
            }, 0);
        });
    };

    return (
        <>
            <Checkbox
                name="checkAll"
                {...rest}
                withoutFormik={true}
                onChange={handleChange}
                checked={allChecked}
            >
                {children || 'Zaznacz wszystkie'}
            </Checkbox>
        </>
    );
};

export default CheckAllCheckbox;
