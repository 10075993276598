import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
    form as baseForm,
    button,
    input,
} from '../custom-modals/add-plan-template-modal.module.scss';
import { form } from './meal-planer-template-form.module.scss';
import { config } from '../../../config';
import { types } from '../../../redux/meal-planer-template/meal-planer-template.actions';

import Separator from '../../atoms/separator';
import Input from '../../atoms/form-poc/input';
import Button from '../../atoms/button';

const { required } = config.messages.form;

const initialValues = {
    name: '',
};

const validationSchema = Yup.object({
    name: Yup.string().required(required),
});

const MealPlannerTemplateForm = () => {
    const dispatch = useDispatch();

    const handleSubmit = (formValues) => {
        dispatch({
            type: types.CREATE_TEMPLATE,
            payload: formValues,
        });
    };

    return (
        <div className={form}>
            <h4>Utwórz nowe menu</h4>
            <Separator withMargin />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {() => (
                    <Form className={baseForm}>
                        <Input
                            name="name"
                            label="Nazwa menu"
                            placeholder="np. Plan przy treningu siłowym"
                            containerClass={input}
                        />

                        <Button className={button} size="small" color="yellow">
                            Zapisz
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default MealPlannerTemplateForm;
