import React from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';

import { container, icon, dot, date } from './survey-item.module.scss';
import SurveyIcon from '../../assets/images/svg/icon-survey.svg';

import CustomLink from '../hoc/custom-link';

const SurveyItem = ({ className, isLink, to, firstName, lastName, createdAt }) => {
    const Component = isLink ? CustomLink : 'div';
    return (
        <Component className={`${container} ${className}`} {...(isLink ? { to } : {})}>
            <SurveyIcon className={icon} />
            <div>Ankieta {`${firstName} ${lastName[0]}.`}</div>
            <span className={dot} />
            <div className={date}>
                <Moment locale="pl" format="LL" unix={true}>
                    {createdAt}
                </Moment>
            </div>
        </Component>
    );
};

SurveyItem.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    className: PropTypes.string,
    isLink: PropTypes.bool,
    to: PropTypes.string,
};

SurveyItem.defaultProps = {
    className: '',
    isLink: false,
    to: '',
};

export default SurveyItem;
