import React from 'react';
import moment from 'moment';

import { label } from './day-label.module.scss';
import { config } from '../../../config';
import { cssDateVar } from '../../../utills/meal-planer';

const DayLabel = ({ day, isTemplate }) => {
    const labelStyle = {
        '--col': cssDateVar(day),
        '--row': config.mealPlanner.coordinates.rows.dayLabel.key,
    };

    const dayLabelText = isTemplate
        ? moment().isoWeekday(day).format('dddd')
        : moment(day).format('dddd DD.MM');
    const cssClass = `meal-planer__grid-position ${label}`;

    return (
        <div className={cssClass} style={labelStyle} id={cssDateVar(day)}>
            {dayLabelText}
        </div>
    );
};

export default DayLabel;
