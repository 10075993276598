import { config } from '../../config';
import { RECIPES, PUBLIC, YOUR, FAVORITE, ALL } from './recipes.reducer';
import genericListMiddleware from '../generic-list/generic-list.middleware';

const publicListEndpoint = config.endpoints.recipes.publicEndpoint;

const expand = `mealTypes,preparationTime,difficultyLevel,favoriteByProfileIds`;

const publicRecipeList = genericListMiddleware(
    RECIPES,
    PUBLIC,
    publicListEndpoint,
    expand
);
const yourRecipeList = genericListMiddleware(RECIPES, YOUR, '', expand);
const favoriteRecipeList = genericListMiddleware(RECIPES, FAVORITE, '', expand);
const allRecipeList = genericListMiddleware(RECIPES, ALL, '', expand);

const recipesMiddleware = [
    publicRecipeList,
    yourRecipeList,
    favoriteRecipeList,
    allRecipeList,
    // add more middlewares for subEntities if needed
];

export default recipesMiddleware;
