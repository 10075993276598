import { fetchActions } from '../messages/fetch-actions';

export const initialState = {
    data: [],
    meta: {
        phraseString: '',
        firstMessageAvailable: false,
        loading: false,
        success: true,
        errors: null,

        pagination: {
            totalCount: 15,
            pageCount: 1,
            currentPage: 1,
            perPage: 5,

            pageToFetch: 1,
            lastLoadingCount: 5,
        },
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case fetchActions.START_FETCHING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    loading: true,
                },
            };

        case fetchActions.LOADING_SUCCESS:
            return {
                ...state,
                data: action.payload,
                meta: {
                    ...state.meta,
                    loading: false,
                },
            };

        case fetchActions.SET_ERRORS:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    errors: action.payload,
                    loading: false,
                },
            };

        case fetchActions.SET_SEARCH_PHRASE:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    phraseString: action.payload,
                },
            };

        case fetchActions.SET_FIRST_MESSAGE_AVAILABLE:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    firstMessageAvailable: true,
                },
            };

        case fetchActions.COUNT_UP_PAGE:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        pageToFetch: state.meta.pagination.pageToFetch + 1,
                    },
                },
            };

        case fetchActions.SET_PAGINATION:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        ...action.payload,
                    },
                },
            };

        case fetchActions.SET_LAST_LOADING_TOTAL_COUNT:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        lastLoadingCount: action.payload,
                    },
                },
            };
        default:
            return state;
    }
}
