import { types as apiTypes } from '../api/api.actions';

export const FILES_TO_DOWNLOAD = '[FILES_TO_DOWNLOAD]';

export const types = {
    // flow/commands actions
    GET_FILES_TO_DOWNLOAD: `${FILES_TO_DOWNLOAD} get list`,

    // api actions
    SEND_FILES_TO_DOWNLOAD_REQUEST: `${FILES_TO_DOWNLOAD} ${apiTypes.API_REQUEST}`,
    SEND_FILES_TO_DOWNLOAD_SUCCESS: `${FILES_TO_DOWNLOAD} ${apiTypes.API_SUCCESS}`,
    SEND_FILES_TO_DOWNLOAD_FAIL: `${FILES_TO_DOWNLOAD} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_FILES_TO_DOWNLOAD: `${FILES_TO_DOWNLOAD} set_data`,
    CLEAR_FILES_TO_DOWNLOAD: `${FILES_TO_DOWNLOAD} clear`,
};

// reducer actions
export const setFilesToDownload = (payload) => ({
    type: types.SET_FILES_TO_DOWNLOAD,
    payload: payload,
});

export const clearFilesToDownload = () => ({
    type: types.CLEAR_FILES_TO_DOWNLOAD,
});

// middleware actions
export const getFilesToDownload = (page, perPage) => ({
    type: types.GET_FILES_TO_DOWNLOAD,
    payload: { page, perPage },
});
