import React from 'react';
import { useSelector } from 'react-redux';

import {
    wrapper,
    attachmentListWrapper,
    imOwnerWrapper,
    himIsAnOwnerWrapper,
    messageWrapper,
    authorLabel,
    avatarHolder,
    contentText,
} from './message.module.scss';
import { getCurrentProfileId } from '../../../redux/profile/profile.selectors';
import { getTextWithLinks } from '../../../utills/get-text-with-links';

import Avatar from '../../atoms/avatar';
import AttachmentPill from '../attachment-pill/attachment-pill';
import Markdown from '../../hoc/markdown';

const Message = ({ message }) => {
    const { content, createdAt, attachments, author } = message;

    const profileId = useSelector(getCurrentProfileId);

    const contentWithLinks = getTextWithLinks(content);

    return (
        <div
            className={`${wrapper} ${
                profileId === author.profileId ? imOwnerWrapper : himIsAnOwnerWrapper
            }`}
        >
            <div className={avatarHolder}>
                <Avatar avatarUri={author.avatarUri} />
            </div>
            <div>
                <div className={messageWrapper}>
                    <Markdown className={contentText}>{contentWithLinks}</Markdown>
                    <ul className={attachmentListWrapper}>
                        {attachments.map((item) => (
                            <li key={`message-attachment-${item.file.fileId}`}>
                                <AttachmentPill
                                    data={item}
                                    reverseColor={profileId !== author.profileId}
                                />
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={authorLabel}>
                    {`${author.firstName} ${author.lastName} ${createdAt}`}
                </div>
            </div>
        </div>
    );
};

export default Message;
