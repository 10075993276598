import { types } from './meal-planer-template.actions';
import { config } from '../../config';
import { generateWeekDays } from '../../utills/date-utils';

const { coordinates, mealTypesMap } = config.mealPlanner;

export const initialState = {
    current: {
        id: null,
        positions: {},
        rows: { ...coordinates.rows, ...mealTypesMap },
        columns: { ...coordinates.cols, ...generateWeekDays() },
        name: '',
        planerUnits: {},
        tags: [],
        dates: [],
    },
    auth: [],
    templateList: [],
};

const mealPlanerTemplate = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_PLANER_TEMPLATE_LIST:
            return {
                ...state,
                templateList: payload,
            };
        case types.SET_PLANER_TEMPLATE_AUTH:
            return {
                ...state,
                auth: payload,
            };
        case types.SET_PLANER_TEMPLATE_DATA:
            return {
                ...state,
                current: {
                    ...state.current,
                    ...payload,
                },
            };
        case types.CLEAR_CURRENT_TEMPLATE_FROM_STATE:
            return {
                ...state,
                current: initialState.current,
            };
        case types.CLEAR_PLANER_TEMPLATE_DATA:
            return initialState;
        default:
            return state;
    }
};

export default mealPlanerTemplate;
