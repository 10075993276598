import { buyingProcessStepTwoActions } from '../actions/buying-process-step-two-actions';

const initialState = {
    addressDelivery: null,
    selectedDeliveryId: '',
    wantInvoice: false,
    addressInvoice: null,
    selectedInvoiceId: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case buyingProcessStepTwoActions.setAddresses:
            return action.payload;

        case buyingProcessStepTwoActions.clearAddresses:
            return initialState;

        default:
            return state;
    }
}
