import {
    ADD_MODAL,
    REMOVE_MODAL,
    REMOVE_MULTIPLE_MODALS,
    EDIT_MODAL,
} from '../actions/actionTypes';
import deepMerge from '../../utills/deep-merge';

const initialState = [];

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_MODAL:
            return [
                ...state,
                {
                    ...payload,
                    path: window.location.pathname,
                },
            ];
        case EDIT_MODAL:
            const newState = [...state];
            const modalId = newState.findIndex((item) => item.id === payload.id);
            const modalToChange = state[modalId];

            newState.splice(newState.indexOf(modalToChange), 1, deepMerge(modalToChange, payload));
            return newState;
        case REMOVE_MODAL:
            return [...state.filter((item) => item.id !== payload)];
        case REMOVE_MULTIPLE_MODALS:
            return [...state.filter((item) => !payload.includes(item.id))];
        default:
            return [...state];
    }
};
