import { types as apiTypes } from '../api/api.actions';

export const SUBSCRIPTION = `[SUBSCRIPTION]`;
export const SUBSCRIPTION_UPGRADE = `[SUBSCRIPTION_UPGRADE]`;
export const SUBSCRIPTION_SPECIAL_OFFER = `[SUBSCRIPTION_SPECIAL_OFFER]`;
export const SUBSCRIPTION_DEMAND_OFFER = `[SUBSCRIPTION_DEMAND_OFFER]`;

export const types = {
    GET_SUBSCRIPTION_UPGRADE_VARIANTS: `${SUBSCRIPTION} get upgrade variants`,
    UPGRADE_SUBSCRIPTION: `${SUBSCRIPTION} upgrade`,
    CANCEL_SUBSCRIPTION: `${SUBSCRIPTION} cancel`,
    GET_SUBSCRIPTION_SPECIAL_OFFER: `${SUBSCRIPTION_SPECIAL_OFFER} get special offer`,
    DEMAND_SUBSCRIPTION_SPECIAL_OFFER: `${SUBSCRIPTION_DEMAND_OFFER} demand special offer`,
    CLEAR_SUBSCRIPTION: `${SUBSCRIPTION} clear`,

    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_REQUEST: `${SUBSCRIPTION_UPGRADE} ${apiTypes.API_REQUEST} get upgrade variants`,
    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_SUCCESS: `${SUBSCRIPTION_UPGRADE} ${apiTypes.API_SUCCESS} get upgrade variants`,
    FETCH_SUBSCRIPTION_UPGRADE_VARIANTS_FAIL: `${SUBSCRIPTION_UPGRADE} ${apiTypes.API_FAIL} get upgrade variants`,

    UPGRADE_SUBSCRIPTION_REQUEST: `${SUBSCRIPTION_UPGRADE} ${apiTypes.API_REQUEST} upgrade`,
    UPGRADE_SUBSCRIPTION_SUCCESS: `${SUBSCRIPTION_UPGRADE} ${apiTypes.API_SUCCESS} upgrade`,
    UPGRADE_SUBSCRIPTION_FAIL: `${SUBSCRIPTION_UPGRADE} ${apiTypes.API_FAIL} upgrade`,

    CANCEL_SUBSCRIPTION_REQUEST: `${SUBSCRIPTION} ${apiTypes.API_REQUEST} cancel`,
    CANCEL_SUBSCRIPTION_SUCCESS: `${SUBSCRIPTION} ${apiTypes.API_SUCCESS} cancel`,
    CANCEL_SUBSCRIPTION_FAIL: `${SUBSCRIPTION} ${apiTypes.API_FAIL} cancel`,

    SUBSCRIPTION_SPECIAL_OFFER_REQUEST: `${SUBSCRIPTION_SPECIAL_OFFER} ${apiTypes.API_REQUEST} special offer`,
    SUBSCRIPTION_SPECIAL_OFFER_SUCCESS: `${SUBSCRIPTION_SPECIAL_OFFER} ${apiTypes.API_SUCCESS} special offer`,
    SUBSCRIPTION_SPECIAL_OFFER_FAIL: `${SUBSCRIPTION_SPECIAL_OFFER} ${apiTypes.API_FAIL} special offer`,

    SUBSCRIPTION_DEMAND_OFFER_REQUEST: `${SUBSCRIPTION_DEMAND_OFFER} ${apiTypes.API_REQUEST} demand offer`,
    SUBSCRIPTION_DEMAND_OFFER_SUCCESS: `${SUBSCRIPTION_DEMAND_OFFER} ${apiTypes.API_SUCCESS} demand offer`,
    SUBSCRIPTION_DEMAND_OFFER_FAIL: `${SUBSCRIPTION_DEMAND_OFFER} ${apiTypes.API_FAIL} demand offer`,

    SET_SUBSCRIPTION_UPGRADE_VARIANTS: `${SUBSCRIPTION} set upgrade variants`,
    SET_SUBSCRIPTION_SPECIAL_OFFER: `${SUBSCRIPTION_SPECIAL_OFFER} set offers`,
    RESTORE_INITIAL_SUBSCRIPTION_STATE: `${SUBSCRIPTION} restore initial`,
};

export const getSubscriptionUpgradeVariantsAction = (subscriptionId) => ({
    type: types.GET_SUBSCRIPTION_UPGRADE_VARIANTS,
    payload: subscriptionId,
});

export const upgradeSubscriptionAction = ({ subscriptionId, variantId, token, modalId }) => ({
    type: types.UPGRADE_SUBSCRIPTION,
    payload: { subscriptionId, variantId, token },
    meta: { modalId },
});

export const cancelSubscriptionAction = (subscriptionId) => ({
    type: types.CANCEL_SUBSCRIPTION,
    payload: subscriptionId,
});

export const getSubscriptionSpecialOfferAction = (subscriptionId) => ({
    type: types.GET_SUBSCRIPTION_SPECIAL_OFFER,
    payload: subscriptionId,
});

export const demandSubscriptionSpecialOfferAction = () => ({
    type: types.DEMAND_SUBSCRIPTION_SPECIAL_OFFER,
});

export const clearSubscriptionAction = () => ({
    type: types.CLEAR_SUBSCRIPTION,
});

export const setSubscriptionUpgradeVariantsAction = (variants) => ({
    type: types.SET_SUBSCRIPTION_UPGRADE_VARIANTS,
    payload: variants,
});

export const setSubscriptionSpecialOfferAction = (payload) => ({
    type: types.SET_SUBSCRIPTION_SPECIAL_OFFER,
    payload,
});

export const restoreInitialSubscriptionStateAction = () => ({
    type: types.RESTORE_INITIAL_SUBSCRIPTION_STATE,
});
