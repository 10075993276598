import React, { useCallback, useEffect, useState } from 'react';

import { pointsNotification, hidden } from './points-notification.module.scss';

export interface IPointsNotificationProps {
    points: number;
    className?: string;
    onLifeEnd?: () => void;
}

export default function PointsNotification({
    points,
    className,
    onLifeEnd,
}: IPointsNotificationProps) {
    const [isHidden, setIsHidden] = useState(false);

    let sign = '';

    if (points < 0) {
        sign = '-';
    } else if (points > 0) {
        sign = '+';
    }

    const timeoutCallback = useCallback(() => setIsHidden(true), []);

    const handleTransitionEnd = useCallback(() => {
        if (onLifeEnd && isHidden) {
            onLifeEnd();
        }
    }, [onLifeEnd, isHidden]);

    const handleMouseEnter = useCallback(() => {
        setIsHidden(false);
    }, []);

    const handleMouseLeave = useCallback(() => timeoutCallback(), [timeoutCallback]);

    useEffect(() => {
        setTimeout(timeoutCallback, 3000);
    }, [timeoutCallback]);

    return (
        <div
            className={`${pointsNotification} ${isHidden ? hidden : ''} ${className} `}
            onTransitionEnd={handleTransitionEnd}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <p>
                {sign} {Math.abs(points)}
            </p>
        </div>
    );
}
