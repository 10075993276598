import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
    container,
    slider,
    sliderContainer,
    slide,
    img,
    dots,
    dot,
    prev,
    next,
    hiddenCloseButton,
    buttonClose,
    ratioBox,
} from './gallery-modal.module.scss';
import useSlider from '../../../hooks/use-slider';
import { removeModalAction } from '../../../redux/actions/actions-modals';

import ArrowButton from '../../atoms/arrow-button';
import GalleryMiniatureList from '../../molecules/gallery-miniature-list';
import ButtonCloseModal from '../../atoms/button-close-modal';
import RatioImage from '../../atoms/ratio-image';

const sliderConfig = {
    fade: true,
    arrows: false,
    speed: 300,
};

const GalleryModal = ({ modalId, images, activeIndex }) => {
    const dispatch = useDispatch();
    const {
        sliderRef,
        sliderSettings,
        slideIndex,
        setSlideIndex,
        isChanging,
        getNext,
        getPrev,
    } = useSlider({
        ...sliderConfig,
        initialSlide: activeIndex,
    });

    const handleClose = () => {
        dispatch(removeModalAction(modalId));
    };

    return (
        <div className={container}>
            <ButtonCloseModal className={buttonClose} modalId={modalId} />
            <button
                className={hiddenCloseButton}
                onClick={handleClose}
                tabIndex={-1}
                aria-label="Zamknij galerię"
            />
            <div className={slider}>
                <div className={sliderContainer}>
                    <SlickSlider {...sliderSettings} ref={sliderRef}>
                        {images.map((item, index) => {
                            return (
                                <div className={slide} key={`gallery-modal-slide-${index}`}>
                                    <button
                                        tabIndex={-1}
                                        className={hiddenCloseButton}
                                        onClick={handleClose}
                                        aria-label="Zamknij galerię"
                                    />
                                    <RatioImage
                                        src={item.url}
                                        alt={item.alt}
                                        className={img}
                                        objectFit="contain"
                                        ratioClass={ratioBox}
                                    />
                                </div>
                            );
                        })}
                    </SlickSlider>
                </div>
            </div>
            {images.length > 1 && (
                <>
                    <ArrowButton
                        onClick={getPrev}
                        className={prev}
                        disabled={isChanging}
                        ariaLabel="Poprzednie zdjęcie"
                    />
                    <ArrowButton
                        onClick={getNext}
                        className={next}
                        rotateDeg={180}
                        disabled={isChanging}
                        ariaLabel="Następne zdjęcie"
                    />
                    <GalleryMiniatureList
                        mediaImages={images}
                        onClick={(index) => setSlideIndex(index)}
                        activeIndex={slideIndex}
                        className={dots}
                        miniatureClassName={dot}
                        disabled={isChanging}
                    />
                </>
            )}
        </div>
    );
};

GalleryModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
    activeIndex: PropTypes.number,
};

GalleryModal.defaultProps = {
    activeIndex: 0,
};

export default GalleryModal;
