import { types as apiTypes } from '../api/api.actions';

export const SHOPPING_LISTS = '[SHOPPING_LISTS]';

export const types = {
    // flow/commands actions
    GET_SHOPPING_LISTS: `${SHOPPING_LISTS} get list`,
    CLEAR_SHOPPING_LISTS: `${SHOPPING_LISTS} clear`,

    // API actions
    FETCH_SHOPPING_LISTS_REQUEST: `${SHOPPING_LISTS} ${apiTypes.API_REQUEST}`,
    FETCH_SHOPPING_LISTS_SUCCESS: `${SHOPPING_LISTS} ${apiTypes.API_SUCCESS}`,
    FETCH_SHOPPING_LISTS_FAIL: `${SHOPPING_LISTS} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_SHOPPING_LISTS: `${SHOPPING_LISTS} set list data`,
    RESTORE_INITIAL_STATE: `${SHOPPING_LISTS} restore initial`,
};

// middleware actions
export const getShoppingLists = (page, perPage) => ({
    type: types.GET_SHOPPING_LISTS,
    payload: { page, perPage },
});

export const clearShoppingLists = () => ({
    type: types.CLEAR_SHOPPING_LISTS,
});

// reducer actions
export const setShoppingLists = (payload) => ({
    type: types.SET_SHOPPING_LISTS,
    payload: payload,
});

export const restoreInitialState = () => ({
    type: types.RESTORE_INITIAL_STATE,
});
