import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
    banner,
    link,
    text,
    span,
    wrapper,
    button,
    buttonHolder,
    publicGroupHolder,
    privateGroupHolder,
} from './facebook.module.scss';
import { entity } from '../rbac/permissions';

import SEO from '../components/seo';
import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import JoinToFacebookGroup from '../components/molecules/join-to-facebook-group';
import Button from '../components/atoms/button';
import UserCan from '../rbac/hoc';
import LinkButton from '../components/atoms/link-button';

export const Facebook = () => {
    const imageQuery = useStaticQuery(query);

    const bannerData = {
        fluidImg: getImage(imageQuery.file),
        title: 'Strefa Przemian na Facebook’u',
        subtitle: 'Dołącz do naszych grup i ciesz się wsparciem oraz wiedzą!',
    };

    return (
        <>
            <SEO title="Facebook" />
            <Main transparentHeader isPaddingEqualToHeaderOnPhone={true} className={wrapper}>
                <MainBanner
                    className={banner}
                    bannerData={bannerData}
                    notFullWidth={true}
                    alt={`Banner strony "Strefa Social"`}
                    imgStyle={{
                        objectFit: 'cover',
                        objectPosition: 'top center',
                        filter: 'brightness(60%)',
                    }}
                />

                <section className={publicGroupHolder}>
                    <JoinToFacebookGroup image={imageQuery.publicGroup}>
                        <p className={text}>
                            Jeżeli potrzebujesz więcej motywacji, inspiracji i wsparcia innych osób
                            - ta grupa powstała właśnie dla Ciebie!
                            <br />
                            <span className={span}>
                                Dołącz teraz do dziesiątek tysięcy osób, które wraz z nami
                                codziennie dzielą się swoimi doświadczeniami.
                            </span>
                        </p>
                        <div className={buttonHolder}>
                            <Button color={'blank'} className={button}>
                                <a
                                    className={link}
                                    href="https://www.facebook.com/groups/178367079472426/"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    CHCĘ DOŁĄCZYĆ
                                </a>
                            </Button>
                        </div>
                    </JoinToFacebookGroup>
                </section>

                <section className={privateGroupHolder}>
                    <JoinToFacebookGroup image={imageQuery.privateGroup}>
                        <p className={text}>
                            Specjalna, zamknięta grupa wsparcia tylko dla użytkowników Strefy
                            Przemian.
                            <br />
                            <span className={span}>
                                "Z kim przystajesz, takim sie stajesz" - dołącz do naszej
                                społeczności!
                            </span>
                        </p>
                        <div className={buttonHolder}>
                            <UserCan
                                action={entity.SUBSCRIBER}
                                yes={() => (
                                    <Button color={'blank'} className={button}>
                                        <a
                                            className={link}
                                            href="https://www.facebook.com/groups/338785540346179/"
                                            target={'_blank'}
                                            rel="noreferrer"
                                        >
                                            CHCĘ DOŁĄCZYĆ
                                        </a>
                                    </Button>
                                )}
                                no={() => (
                                    <LinkButton color={'yellow'} to="/cennik/">
                                        CHCĘ WYKUPIĆ SUBSKRYBCJĘ ABY MÓC DOŁĄCZYĆ
                                    </LinkButton>
                                )}
                            />
                        </div>
                    </JoinToFacebookGroup>
                </section>
            </Main>
        </>
    );
};

const query = graphql`
    {
        file: file(relativePath: { eq: "facebook-logo-cutted.png" }) {
            childImageSharp {
                gatsbyImageData(width: 300, placeholder: BLURRED, layout: CONSTRAINED)
            }
        }
        publicGroup: file(relativePath: { eq: "open-facebook-group.png" }) {
            childImageSharp {
                gatsbyImageData(width: 300, placeholder: BLURRED, layout: CONSTRAINED)
            }
        }
        privateGroup: file(relativePath: { eq: "private-facebook-group.png" }) {
            childImageSharp {
                gatsbyImageData(width: 300, placeholder: BLURRED, layout: CONSTRAINED)
            }
        }
    }
`;

export default Facebook;
