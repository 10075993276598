import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
    video,
    banner,
    loader,
    wrapper,
    bannerContent,
    separator,
    description,
    cluesBox,
    relatedSeparator,
    relatedExercises,
    seeAllButton,
} from './exercise-page.module.scss';
import { config } from '../config';
import { getAbsolutePath } from '../routes';
import { getExercise } from '../communication/workout';
import { saveMovieLog } from '../communication/audit';

import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import Separator from '../components/atoms/separator';
import VideoPlayer from '../components/molecules/video-player';
import ExerciseMetadata from '../components/molecules/exercise/exercise-metadata';
import Tag from '../components/atoms/tag';
import BigTitle from '../components/atoms/big-title';
import RelatedList from '../components/organisms/related-list';
import Button from '../components/atoms/button';
import Error from '../components/atoms/form/error';
import ExerciseCard from '../components/molecules/exercise/exercise-card';
import Loader from '../components/atoms/loader';
import Markdown from '../components/hoc/markdown';

const { audit } = config;

const ExercisePage = ({ id }) => {
    const [exercise, setExercise] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);

    const imageQuery = useStaticQuery(query);

    const bannerData = {
        fluidImg: getImage(imageQuery.exerciseBanner),
    };

    const handleWatched = async () => {
        const movieData = {
            module: audit.module.exercise,
            group: audit.group.exercise,
            action: audit.action.watch,
            params: id,
        };
        await saveMovieLog(movieData);
    };

    const renderRelated = (relatedExercises) => {
        return (
            <>
                <Separator className={relatedSeparator} />
                <RelatedList
                    title="Wykonaj kolejne ćwiczenie"
                    link={{
                        url: getAbsolutePath('APP_EXERCISE'),
                        name: 'Lub zobacz wszystkie ćwiczenia',
                    }}
                >
                    {errors && <Error>{errors}</Error>}
                    {relatedExercises.map((related) => (
                        <ExerciseCard
                            key={`list-item-${related.id}`}
                            item={related}
                            {...related}
                            path={getAbsolutePath('APP_EXERCISE')}
                        />
                    ))}
                </RelatedList>
            </>
        );
    };

    useEffect(() => {
        getExercise(id)
            .then((resp) => setExercise(resp.data))
            .catch((error) => setErrors(error.response.data.messages))
            .finally(() => setLoading(false));
    }, [id]);

    return (
        <Main transparentHeader className={wrapper}>
            <MainBanner
                className={banner}
                bannerData={bannerData}
                imgStyle={{
                    objectFit: 'cover',
                    objectPosition: 'top center',
                    filter: 'brightness(60%)',
                }}
            >
                {exercise && (
                    <div className={bannerContent}>
                        <Tag
                            tag={{
                                tagId: 1,
                                color: '#89D8DC',
                                name: 'ćwiczenie',
                            }}
                        />
                        <BigTitle Tag="h1">{exercise.name}</BigTitle>
                        <ExerciseMetadata exercise={exercise} short={false} />
                    </div>
                )}
                {loading && <Loader className={loader} />}
                {!exercise && !loading && (
                    <div className={bannerContent}>
                        <Tag
                            tag={{
                                tagId: 1,
                                color: '#89D8DC',
                                name: 'ćwiczenie',
                            }}
                        />
                        <BigTitle Tag="h1">Ups! Nie znaleźliśmy takiego ćwiczenia</BigTitle>
                    </div>
                )}
            </MainBanner>
            {exercise && (
                <>
                    {exercise.videoUri && (
                        <section className={video}>
                            <VideoPlayer
                                autoplay={false}
                                coverUrl={exercise.coverViewUri}
                                videoUrl={exercise.videoUri}
                                followProgress={true}
                                onWatched={handleWatched}
                            />
                        </section>
                    )}
                    {exercise.description && (
                        <section className={description}>
                            <h1>Opis</h1>
                            <Separator className={separator} />
                            <Markdown className={'ck-content'}>{exercise.description}</Markdown>
                        </section>
                    )}
                    {exercise.clues && (
                        <section className={cluesBox}>
                            <h1>Wskazówki</h1>
                            <Separator className={separator} />
                            <Markdown className={'ck-content'}>{exercise.clues}</Markdown>
                        </section>
                    )}
                    <section className={relatedExercises}>
                        {exercise.related && exercise.related.length > 0 ? (
                            renderRelated(exercise.related)
                        ) : (
                            <div className={seeAllButton}>
                                <Link to={getAbsolutePath('APP_EXERCISE')}>
                                    <Button color="yellow">zobacz wszystkie ćwiczenia</Button>
                                </Link>
                            </div>
                        )}
                    </section>
                </>
            )}
        </Main>
    );
};

const query = graphql`
    {
        exerciseBanner: file(relativePath: { eq: "exercises-banner.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;

export default ExercisePage;
