import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { button, icon } from './button-close-modal.module.scss';
import CloseIcon from '../../assets/images/svg/icon-close.svg';
import { removeModalAction } from '../../redux/actions/actions-modals';

const ButtonCloseModal = ({ modalId, className }) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        if (modalId) {
            dispatch(removeModalAction(modalId));
        }
    };

    return (
        <button
            aria-label="Zamknij okno"
            onClick={handleClick}
            className={`${button} ${className}`}
        >
            <CloseIcon className={icon} />
        </button>
    );
};

ButtonCloseModal.propTypes = {
    modalId: PropTypes.number,
    className: PropTypes.string,
};

ButtonCloseModal.defaultProps = {
    modalId: null,
    className: '',
};

export default ButtonCloseModal;
