import { combineReducers } from 'redux';

import { entitiesMap } from '../reusable-reducers/entities-map';
import user from './user';
import profile from '../profile/profile.reducer';
import exercises from './exercises';
import trainings from './trainings';
import survey from '../survey/survey.reducer';
import surveys from '../surveys/surveys.reducer';
import modals from './modals';
import ui from '../ui/ui.reducer';
import alerts from '../alerts/alerts.reducer';
import cart from '../shop/cart/cart.reducer';
import rbac from '../rbac/rbac.reducer';
import buyingProcessGlobal from '../buying-process/global/reducers/buying-process-global';
import buyingProcessStepTwo from '../buying-process/step-two/reducers/buying-process-step-two';
import messenger from '../messenger/reducers/index';
import personalTrainingReducer from '../personal-training/personal-training.reducer';
import mealPlaner from '../meal-planer/meal-planer.reducer';
import { singlePostReducerWithNamedType } from '../reusable-reducers/single-post/single-post-reducer';
import orderHistory from '../shop/order-history/order-history.reducer';
import dietTypes from '../diet-types/diet-types.reducer';
import currentCourse from '../current-course/reducers/index';
import mealPlanerTemplate from '../meal-planer-template/meal-planer-template.reducer';
import filesToDownload from '../files-to-download/files-to-download.reducer';
import order from '../shop/order/order.reducer';
import recipes from './recipes';
import shoppingLists from '../shopping-lists/shopping-lists.reducer';
import singleShoppingList from '../single-shopping-list/single-shopping-list.reducer';
import payu from '../shop/payu/payu.reducer';
import notifications from '../notifications/notifications.reducer';
import cleanEnhancer from '../reducerEnhancers/cleanEnhancer';
import { types } from '../user/user.actions';
import exerciseAtlas, { EXERCISE_ATLAS } from '../exercise-atlas/exercise-atlas.reducer';
import trainingAtlas, { TRAININGS_ATLAS } from '../trainings-atlas/trainings-atlas.reducer';
import recipesReducer, { RECIPES } from '../recipes/recipes.reducer';
import address from '../address/address.reducer';
import addresses from '../addresses/addresses.reducer';
import discountsReducer from '../shop/discounts/discounts.reducer';
import gamificationReducer, { GAMIFICATION } from '../gamification/gamification.reducer';
import { SSE, sseSubscriptionReducer } from '../sse-subscription/sse-subscription.reducer';
import partnersZoneReducer, { PARTNERS_ZONE } from '../partners-zone/partners-zone.reducer';
import subscription from '../subscription/subscription.reducer';
import subscriptions from '../subscriptions/subscriptions.reducer';
import productsReducer from '../shop/products/products.reducer';
import welcomeSlides from '../welcome-slides/welcome-slides.reducer';
import forum from '../forum/forum.reducer';
import courses from '../courses/courses.reducer';

const rootReducer = combineReducers({
    currentCourse,
    user,
    rbac,
    exercises,
    [EXERCISE_ATLAS]: exerciseAtlas,
    [TRAININGS_ATLAS]: trainingAtlas,
    trainings,
    [SSE]: sseSubscriptionReducer,
    userZone: cleanEnhancer(
        combineReducers({
            newsletter: combineReducers({
                subscribe: singlePostReducerWithNamedType(
                    entitiesMap.newsletter.baseName,
                    entitiesMap.newsletter.methodName.subscribe
                ),
                unsubscribe: singlePostReducerWithNamedType(
                    entitiesMap.newsletter.baseName,
                    entitiesMap.newsletter.methodName.unsubscribe
                ),
            }),
        }),
        types.CLEAR_USER_DATA
    ),
    mealPlaner,
    mealPlanerTemplate,
    personalTrainings: personalTrainingReducer,
    shop: combineReducers({
        cart: cart,
        orderHistory: cleanEnhancer(orderHistory, types.CLEAR_USER_DATA),
        order: order,
        payu: payu,
        discounts: discountsReducer,
        products: productsReducer,
    }),
    profile,
    buyingProcess: cleanEnhancer(
        combineReducers({
            global: buyingProcessGlobal,
            stepTwo: buyingProcessStepTwo,
        }),
        types.CLEAR_USER_DATA
    ),
    survey,
    surveys,
    modals,
    ui: ui,
    alerts,
    recipes,
    [RECIPES]: recipesReducer,
    shoppingLists,
    subscription,
    subscriptions,
    dietTypes,
    messenger,
    filesToDownload,
    singleShoppingList,
    address,
    addresses,
    notifications,
    welcomeSlides,
    [GAMIFICATION]: gamificationReducer,
    [PARTNERS_ZONE]: partnersZoneReducer,
    forum,
    courses,
});

export default rootReducer;
