import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
    card,
    selected,
    cardContent,
    cardActionBox,
    cardTitle,
    clickable,
} from './address-card.module.scss';
import Icon from '../../assets/images/svg/icon-pencil.svg';
import { addModalAction } from '../../redux/actions/actions-modals';
import { deleteAddressAction } from '../../redux/address/address.actions';

import IconButton from '../atoms/icon-button';

const AddressCard = ({ className = '', address, onClick, isSelected }) => {
    const dispatch = useDispatch();

    const {
        addressId,
        label,
        contactPerson,
        companyName,
        street,
        houseNumber,
        apartmentNumber,
        city,
        zipCode,
        contactNumber,
        taxNumber,
    } = address;

    const Tag = onClick ? 'button' : 'div';

    const handleClick = () => {
        onClick(address);
    };

    const handleDelete = () => {
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: 'Usunąć adres?',
                    content: 'Adres zostanie nieodwracalnie usunięty z bazy',
                    onConfirm: () => {
                        dispatch(deleteAddressAction(addressId));
                    },
                },
            })
        );
    };

    const handleEdit = () => {
        navigate(`edytuj/${addressId}`);
    };

    return (
        <Tag
            className={`
                ${card} 
                ${className} 
                ${onClick ? clickable : ''}
                ${isSelected ? selected : ''}
            `}
            {...(onClick ? { onClick: handleClick, type: 'button' } : {})}
        >
            <div>
                <h4 className={cardTitle}>{label}</h4>
                <div className={cardContent}>
                    {contactPerson && <p>{contactPerson}</p>}
                    {companyName && <p>{companyName}</p>}
                    <p>
                        ul. {street} {houseNumber}
                        {apartmentNumber ? `/${apartmentNumber}` : ''}
                    </p>
                    <p>
                        {city} {zipCode}
                    </p>
                    {contactNumber && <p>{contactNumber}</p>}
                    {taxNumber && <p>NIP: {taxNumber}</p>}
                </div>
            </div>
            {!onClick && (
                <div className={cardActionBox}>
                    <IconButton icon={Icon} onClick={handleEdit} isUppercase={true}>
                        Edytuj
                    </IconButton>
                    <IconButton onClick={handleDelete} isUppercase={true}>
                        Usuń
                    </IconButton>
                </div>
            )}
        </Tag>
    );
};

export const AddressPropShape = PropTypes.shape({
    addressId: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    contactPerson: PropTypes.string,
    companyName: PropTypes.string,
    street: PropTypes.string.isRequired,
    houseNumber: PropTypes.string.isRequired,
    apartmentNumber: PropTypes.string,
    city: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    contactNumber: PropTypes.string,
    taxNumber: PropTypes.string,
});

AddressCard.propTypes = {
    className: PropTypes.string,
    address: AddressPropShape.isRequired,
    onClick: PropTypes.func,
};

AddressCard.defaultProps = {
    className: '',
    isSelected: false,
};

export default AddressCard;
