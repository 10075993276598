import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pointsWidget, visible, closeButton } from './points-widget.module.scss';
// import CloseIcon from '../../assets/images/svg/icon-close.svg';
import {
    selectGamificationAlerts,
    // selectPointsBalance,
} from '../../redux/gamification/gamification.selectors';
import { clearAlert } from '../../redux/gamification/gamification.actions';
import { getCurrentProfileId } from '../../redux/profile/profile.selectors';
import isGamificationEnabled from '../../utills/gamification/is-gamification-enabled';

import PointsNotification from '../atoms/points-notification';
// import PointsWidgetBalance from '../molecules/points-widget-balance';

type PointAlert = { id: number; change: number };

export default function PointsWidget() {
    // ⚠️ At the client's request, the display of widget elements has been limited
    // const pointsBalance = useSelector(selectPointsBalance);
    const alerts: Array<PointAlert | null> = useSelector(selectGamificationAlerts);
    const profileId = useSelector(getCurrentProfileId);
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);

    const handleNotificationLifeEnd = useCallback(
        (id: number) => {
            dispatch(clearAlert(id));
        },
        [dispatch]
    );

    // const handleCloseWidget = () => {
    //     setIsVisible(false);
    // };

    useEffect(() => {
        setIsVisible(!!(profileId && isGamificationEnabled()));
    }, [profileId]);

    return (
        <div className={`${pointsWidget} ${isVisible ? visible : ''}`}>
            {/*<button className={closeButton} onClick={handleCloseWidget}>*/}
            {/*    <CloseIcon />*/}
            {/*</button>*/}
            {alerts
                .filter((alert): alert is PointAlert => alert !== null)
                .map((alert) => (
                    <PointsNotification
                        key={`point-notification-${alert.id}`}
                        points={alert.change}
                        onLifeEnd={() => handleNotificationLifeEnd(alert.id)}
                    />
                ))}
            {/*<PointsWidgetBalance points={pointsBalance} />*/}
        </div>
    );
}
