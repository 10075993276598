import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
// TODO: Gatsby Image - change to Static Image
import { GatsbyImage } from 'gatsby-plugin-image';

import { wrapper, imageWrapper, textWrapper } from './metamorphosis.module.scss';

export const Metamorphosis = () => {
    const imageQuery = useStaticQuery(query);

    return (
        <Link to="/metamorfozy">
            <div className={wrapper}>
                <GatsbyImage
                    image={imageQuery.file.childImageSharp.gatsbyImageData}
                    className={imageWrapper}
                    alt="Banner Metamorfozy"
                />
                <section className={textWrapper}>
                    <span>Najlepsze metamorfozy</span>
                    <span>Strefy Przemian</span>
                </section>
            </div>
        </Link>
    );
};

const query = graphql`
    {
        file: file(relativePath: { eq: "metamorfozy.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;
export default Metamorphosis;
