import React, { useState } from 'react';
import Rate from 'rc-rate';
import PropTypes from 'prop-types';

import 'rc-rate/assets/index.css';
import {
    header,
    title as titleClass,
    count as countClass,
    subtitle as subtitleClass,
    loading as loadingClass,
    infoBox,
    starBox,
    stars,
    container,
    tooltip,
} from './rating.module.scss';
import StarEmptyIcon from '../../assets/images/svg/icon-star-empty.svg';
import StarHalfIcon from '../../assets/images/svg/icon-star-half.svg';
import StarFullIcon from '../../assets/images/svg/icon-star-full.svg';

import Dropdown from '../molecules/dropdown';

const Rating = ({
    className,
    title,
    subtitle,
    initialValue,
    count,
    voiceCount,
    loading,
    onChange,
    labelMap,
    disabled,
}) => {
    const [selectedValue, setSelectedValue] = useState(initialValue);
    const [openedTooltip, setOpenedTooltip] = useState(null);

    const getStar = (value, index) => {
        if (value < index + 0.25) {
            return <StarEmptyIcon />;
        }
        if (Math.floor(value) === index && value < index + 0.75) {
            return <StarHalfIcon />;
        }
        return <StarFullIcon />;
    };

    const handleValueChange = (value) => {
        let newValue = value;
        if (newValue === 0) {
            newValue = selectedValue;
        }
        setSelectedValue(newValue);
        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    };

    return (
        <div className={`${container} ${className} ${loading ? loadingClass : ''}`}>
            {(title || subtitle) && (
                <div className={header}>
                    {title && <h2 className={titleClass}>{title}</h2>}
                    {subtitle && <h3 className={subtitleClass}>{subtitle}</h3>}
                </div>
            )}
            <Rate
                className={stars}
                character={({ value, index }) => {
                    return (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <div
                            className={starBox}
                            onMouseEnter={() => setOpenedTooltip(index)}
                            onMouseLeave={() => setOpenedTooltip(null)}
                        >
                            {getStar(value, index)}
                            {labelMap && !disabled && (
                                <Dropdown open={openedTooltip === index} className={tooltip}>
                                    {labelMap.find((item) => item.value === value)?.label}
                                </Dropdown>
                            )}
                        </div>
                    );
                }}
                count={count}
                allowHalf={true}
                value={selectedValue}
                onChange={(value) => handleValueChange(value)}
                disabled={disabled}
            />
            <div className={infoBox}>
                {voiceCount === 0 ? (
                    <p>
                        Nie wystawiono jeszcze żadnej oceny
                        {!disabled ? `, Twoja ocena może być pierwsza!` : ''}
                    </p>
                ) : (
                    <>
                        <p>Średnia ocena: {initialValue.toFixed(1)} / 5</p>
                        <p className={countClass}>Głosów: {voiceCount}</p>
                    </>
                )}
            </div>
        </div>
    );
};

Rating.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    initialValue: PropTypes.number,
    count: PropTypes.number,
    voiceCount: PropTypes.number,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    labelMap: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        })
    ),
};

Rating.defaultProps = {
    className: '',
    title: '',
    subtitle: '',
    initialValue: 0,
    count: 5,
    voiceCount: 0,
    loading: false,
    onChange: null,
    disabled: false,
    labelMap: null,
};

export default Rating;
