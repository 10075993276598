import { GAMIFICATION, GAMIFICATION_HISTORY, GAMIFICATION_TARIFF } from './gamification.reducer';
import { selectList, selectListItem } from '../generic-list/generic-list.selectors';
import { selectLoaderByEntity } from '../ui/ui.selectors';

export const selectGamificationHistory = (state) =>
    selectList(state, GAMIFICATION, GAMIFICATION_HISTORY);
export const selectTariffList = (state) => selectList(state, GAMIFICATION, GAMIFICATION_TARIFF);

export const selectPointsBalance = (state) => {
    const history = selectGamificationHistory(state);

    if (Array.isArray(history.items) && history.items.length > 0) {
        //sort array for safety
        const sortedHistory = history.items.sort(
            (historyItemFirst, historyItemSecond) =>
                historyItemSecond.transactionId - historyItemFirst.transactionId
        );

        return sortedHistory[0].balance;
    }

    return 0;
};

export const selectGamificationLoading = (state, entity = GAMIFICATION) =>
    selectLoaderByEntity(state, entity);

export const selectGamificationHistoryLoading = (state) =>
    selectLoaderByEntity(state, `${GAMIFICATION} ${GAMIFICATION_HISTORY}`);

export const selectGamificationAlerts = (state) => {
    const alerts = Object.keys(state[GAMIFICATION].alerts);

    return alerts.map((alertId) => {
        const currentHistory = selectListItem(
            state,
            GAMIFICATION,
            GAMIFICATION_HISTORY,
            parseInt(alertId)
        );

        if (currentHistory) {
            return {
                id: currentHistory.transactionId,
                change: currentHistory.change,
            };
        }

        return null;
    });
};

export const selectForbiddenReasonId = (state) => state[GAMIFICATION].forbiddenReasonsId;

export const selectIsForbiddenReasonId = (id) => (state) =>
    !!selectForbiddenReasonId(state).find(
        (item) => item.reasonId === id.reasonId && item.objectId === id.objectId
    );
