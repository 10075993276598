import React from 'react';

import { nameText, icon, wrapper } from './link-pass.module.scss';
import LinkArrow from '../../assets/images/svg/link-arrow.svg';

const LinkPass = ({ name, className = '' }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <LinkArrow className={icon} />
            <div className={nameText}>{name}</div>
        </div>
    );
};

export default LinkPass;
