import React from 'react';

import { active, dark as darkClass, dotButton, dotItem, dotList } from './slider-dots.module.scss';

const SliderDots = ({
    slides,
    onClick,
    activeSlideIndex,
    disabled = false,
    dark = false,
    className = '',
}) => (
    <ul className={`${dotList} ${className}`}>
        {slides.map((_, index) => {
            return (
                <li className={dotItem} key={index}>
                    <button
                        className={`${dotButton} ${activeSlideIndex === index ? active : ''} ${
                            dark ? darkClass : ''
                        }`}
                        onClick={() => (onClick ? onClick(index) : null)}
                        disabled={disabled}
                        aria-label={`Zobacz slajd numer ${index + 1}`}
                    />
                </li>
            );
        })}
    </ul>
);

export default SliderDots;
