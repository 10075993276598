import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { banner, wrapper, content, trainingSwitcher } from './personal-training-page.module.scss';
import { getAbsolutePath } from '../routes';

import SEO from '../components/seo';
import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import TreningTabSwitcher from '../components/atoms/treining-switcher';

const PersonalTrainingPage = ({ children }) => {
    const imageQuery = useStaticQuery(query);

    const bannerData = {
        fluidImg: getImage(imageQuery.exerciseBanner),
        title: 'Trening personalny',
        subtitle: 'Spersonalizowany plan treningowy',
    };

    const label = [
        {
            id: 1,
            name: 'Aktualny trening',
            link: getAbsolutePath('APP_MY_ACCOUNT_PERSONAL_TRAINING_CURRENT'),
        },
        {
            id: 2,
            name: 'Poprzednie plany',
            link: getAbsolutePath('APP_MY_ACCOUNT_PERSONAL_TRAINING_PREVIOUS_LIST'),
        },
    ];

    return (
        <>
            <SEO title="Moje konto" />
            <Main transparentHeader isPaddingEqualToHeaderOnPhone={true} className={wrapper}>
                <MainBanner
                    className={banner}
                    bannerData={bannerData}
                    imgStyle={{
                        objectFit: 'cover',
                        objectPosition: 'top center',
                        filter: 'brightness(60%)',
                    }}
                />
                <section className={trainingSwitcher}>
                    <TreningTabSwitcher labels={label} />
                </section>
                <div className={content}>{children}</div>
            </Main>
        </>
    );
};

const query = graphql`
    {
        exerciseBanner: file(relativePath: { eq: "personal-training.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;

export default PersonalTrainingPage;
