import React from 'react';

import { title } from './login-shop.module.scss';

import LoginForm from '../../../../components/organisms/auth/login-form';
import Title from '../../../../components/atoms/title';

export const LoginShop = ({ className = '' }) => {
    return (
        <>
            <div className={`${className}`}>
                <Title className={title}>Witaj ponownie!</Title>
                <LoginForm isShoppingStep={true} />
            </div>
        </>
    );
};

export default LoginShop;
