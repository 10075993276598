import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/pl';

import { dot, avatarWrapper, metadata } from './post-metadata.module.scss';
import { IProfile } from '../../models/profile.model';

import Avatar from './avatar';

interface IPostMetadataProps {
    className?: string;
    publishedAt?: number;
    author?: IProfile;
    isSmall?: boolean;
}

const PostMetadata: React.FC<IPostMetadataProps> = ({
    className = '',
    isSmall = false,
    author,
    publishedAt,
}) => {
    const fullName = (author && `${author.firstName} ${author.lastName}`) || '';

    return (
        <div className={`${metadata} ${className}`}>
            {!isSmall && author && (
                <div className={avatarWrapper}>
                    <Avatar avatarUri={author.avatarUri} alt={fullName} isDark={true} />
                </div>
            )}
            {fullName}
            {publishedAt && (
                <>
                    <span className={dot} />
                    <Moment locale="pl" unix format={'LL'}>
                        {publishedAt}
                    </Moment>
                </>
            )}
        </div>
    );
};

export default PostMetadata;
