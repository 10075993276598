import moment from 'moment';

export default function processStructuredData(structuredData) {
    const searchFields = ['datePublished', 'dateModified'];

    //take properties with "date" name and process date to properly format
    const processedDataProperties = Object.entries(structuredData)
        .filter(([key]) => searchFields.includes(key))
        .reduce((acc, [key, entry]) => {
            acc[key] = moment.unix(entry).locale('us').format('YYYY-MM-DD');
            return acc;
        }, {});

    return { ...structuredData, ...processedDataProperties };
}
