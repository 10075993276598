import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';

import {
    img,
    small as smallClass,
    label as labelClass,
    container,
    deleteFile,
    fileContent,
    filesContainer,
    fileItem,
    fileList,
    imgGrid,
    loaderLayer,
    oneImgGrid,
    link,
} from './file-input-field.module.scss';
import PlusIcon from '../../../assets/images/svg/plus.svg';
import CloseIcon from '../../../assets/images/svg/icon-close.svg';

import TextError from '../../atoms/form-poc/text-error';
import Loader from '../../atoms/loader';
import FileInputArea from '../../atoms/form-poc/file-input-area';

const FileInputField = ({
    name,
    label,
    imgOnly = false,
    containerClass = '',
    maxFileSize = 5120000,
    maxFileCount = 10,
    fileTypes = [],
    multiple = true,
    small = false,
    ...rest
}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [remainingCount, setRemainingCount] = useState(maxFileCount);

    const handleChange = (result, form) => {
        setRemainingCount(maxFileCount - result.uploadedFiles.length - form.values[name].length);

        if (result.uploadedFiles.length) {
            form.setFieldValue(name, [
                ...(multiple ? form.values[name] : []),
                ...result.uploadedFiles,
            ]);
        }

        setErrors([...errors, ...result.uploadErrors]);
    };

    const handleDeleteFile = (form, toDeleteIndex) => {
        const newValue = form.values[name].filter((item, index) => index !== toDeleteIndex);
        form.setFieldValue(name, newValue);
        setRemainingCount(maxFileCount - newValue.length);
        setErrors([]);
    };

    const renderFiles = (form) => {
        let fileContainerClass;
        if (imgOnly && multiple) {
            fileContainerClass = imgGrid;
        } else if (imgOnly && !multiple) {
            fileContainerClass = `${imgGrid} ${oneImgGrid}`;
        } else {
            fileContainerClass = fileList;
        }
        return (
            <div className={fileContainerClass}>
                {form.values[name].map((file, index) => {
                    const ItemTag = file.uri ? 'a' : 'div';
                    const itemProps = file.uri ? { href: file.uri, target: '_blank' } : {};
                    return (
                        <div key={`file-${name}-${index}`} className={fileItem}>
                            <ItemTag
                                {...itemProps}
                                className={`
                                    ${fileContent}
                                    ${file.uri ? link : ''}
                                `}
                            >
                                {imgOnly ? (
                                    <img
                                        src={file.uri ? file.uri : file.content}
                                        alt={file.name}
                                        className={img}
                                    />
                                ) : (
                                    file.name
                                )}
                            </ItemTag>
                            <button
                                type="button"
                                className={deleteFile}
                                onClick={() => handleDeleteFile(form, index)}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <Field name={name} {...rest}>
            {({ field, form }) => {
                return (
                    <div className={`${container} ${containerClass}`}>
                        {isUploading && (
                            <div className={loaderLayer}>
                                <Loader />
                            </div>
                        )}
                        {form.values[name].length > 0 && (
                            <div className={filesContainer}>{renderFiles(form)}</div>
                        )}
                        <FileInputArea
                            form={form}
                            name={name}
                            onChange={handleChange}
                            onClick={() => setErrors([])}
                            onBlur={field.onBlur}
                            fileTypes={fileTypes}
                            multiple={true}
                            onUploadStart={() => setIsUploading(true)}
                            onUploadFinish={() => setIsUploading(false)}
                            maxFileCount={remainingCount}
                            maxFileSize={maxFileSize}
                        >
                            <div
                                className={`
                                    ${labelClass}
                                    ${small ? smallClass : ''}
                                `}
                            >
                                <PlusIcon />
                                {label}
                            </div>
                        </FileInputArea>
                        {errors.map((err, index) => {
                            return <TextError key={`err-${index}`}>{err}</TextError>;
                        })}
                        <ErrorMessage name={name} component={TextError} />
                    </div>
                );
            }}
        </Field>
    );
};

export default FileInputField;
