import { combineReducers } from 'redux';
import { paginationListReducerWithNamedType } from '../reusable-reducers/pagination-list/pagination-list-reducer';
import { entitiesMap } from '../reusable-reducers/entities-map';
import { singlePostReducerWithNamedType } from '../reusable-reducers/single-post/single-post-reducer';
import { basicArrayReducerWithNamedType } from '../reusable-reducers/basic-array/basic-array-reducer';
import cleanEnhancer from '../reducerEnhancers/cleanEnhancer';
import { types } from '../recipes/recipes.actions';

const reducer = combineReducers({
    all: paginationListReducerWithNamedType(
        entitiesMap.recipes.all.baseName,
        entitiesMap.recipes.all.methodName.get
    ),
    public: paginationListReducerWithNamedType(
        entitiesMap.recipes.public.baseName,
        entitiesMap.recipes.public.methodName.get
    ),
    your: paginationListReducerWithNamedType(
        entitiesMap.recipes.your.baseName,
        entitiesMap.recipes.your.methodName.get
    ),
    favorite: paginationListReducerWithNamedType(
        entitiesMap.recipes.favorite.baseName,
        entitiesMap.recipes.favorite.methodName.get
    ),
    single: combineReducers({
        get: singlePostReducerWithNamedType(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.get
        ),
        create: singlePostReducerWithNamedType(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.create
        ),
        edit: singlePostReducerWithNamedType(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.edit
        ),
        delete: singlePostReducerWithNamedType(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.delete
        ),
        toggleIsFavorite: singlePostReducerWithNamedType(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.toggleIsFavorite
        ),
        rate: singlePostReducerWithNamedType(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.rate
        ),
        comment: singlePostReducerWithNamedType(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.comment
        ),
        saveToPdf: singlePostReducerWithNamedType(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.saveToPdf
        ),
    }),
    ingredients: basicArrayReducerWithNamedType(
        entitiesMap.recipes.ingredients.baseName,
        entitiesMap.recipes.ingredients.methodName.get
    ),
    units: basicArrayReducerWithNamedType(
        entitiesMap.recipes.units.baseName,
        entitiesMap.recipes.units.methodName.get
    ),
});

export default cleanEnhancer(reducer, types.CLEAR_ALL_RECIPES);
