import React from 'react';

import {
    container,
    video,
    img,
    content,
    ratio,
    titleText,
    descriptionText,
    button,
} from './welcome-slide.module.scss';
import { config } from '../../config';
import { IWelcomeSlide } from '../../models/welcome-slide.model';
import useMedia from '../../hooks/use-media';
import useWindowWidth from '../../hooks/use-window-width';
import { saveMovieLog } from '../../communication/audit';

import VideoPostPreview from './video-post-preview';
import LinkButton from '../atoms/link-button';

const { mediaRelationsMap, audit } = config;
const BIG_PHONE_BREAKPOINT = 585;

interface IWelcomeSlideProps {
    className?: string;
    welcomeSlide: IWelcomeSlide;
    isDisabled?: boolean;
    TitleTag?: React.ElementType;
}

const WelcomeSlide: React.FC<IWelcomeSlideProps> = ({
    className = '',
    welcomeSlide,
    isDisabled,
    TitleTag = 'h3',
}) => {
    const {
        businessMeaning,
        buttonUri,
        buttonText,
        videoUri,
        media,
        shortDescription,
        title,
    } = welcomeSlide;
    const mediaImage = useMedia({ media, relation: mediaRelationsMap.mainImage });
    const mediaImageMobile = useMedia({ media, relation: mediaRelationsMap.mobileImage });

    const windowWidth = useWindowWidth() || 0;
    const image = windowWidth >= BIG_PHONE_BREAKPOINT ? mediaImage : mediaImageMobile;

    const handleWatched = async () => {
        const movieData = {
            module: audit.module.onBoarding,
            group: audit.group.movie,
            action: audit.action.watch,
        };
        await saveMovieLog(movieData);
    };

    return (
        <div className={`${container} ${className}`}>
            {videoUri ? (
                <VideoPostPreview
                    className={video}
                    videoUrl={welcomeSlide.videoUri}
                    imageUrl={image.url}
                    followProgress={true}
                    onWatched={(businessMeaning === 1 && handleWatched) || undefined}
                    isDisabled={isDisabled}
                    ratioClass={ratio}
                />
            ) : (
                <div className={`${content} ${ratio}`}>
                    {title && <TitleTag className={titleText}>{title}</TitleTag>}
                    {shortDescription && <p className={descriptionText}>{shortDescription}</p>}
                    {buttonText && buttonUri && (
                        <LinkButton className={button} small={true} to={buttonUri}>
                            {buttonText}
                        </LinkButton>
                    )}
                    <img src={image.url} alt={image.alt} className={img} />
                </div>
            )}
        </div>
    );
};

export default WelcomeSlide;
