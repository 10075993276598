import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import { title, subtitle, emoji } from './register-shop.module.scss';
import { selectCartId } from '../../../../redux/shop/cart/cart.selectors';

import RegisterForm from '../../../../components/organisms/auth/register-form';
import Button from '../../../../components/atoms/button';
import Title from '../../../../components/atoms/title';

export const RegisterShop = ({ className = '' }) => {
    const cartId = useSelector(selectCartId);

    const [isRegistrationStart, setRegistration] = useState(false);
    const [orderContext, setOrderContext] = useState({});

    useEffect(() => {
        if (cartId) {
            setOrderContext({
                returnTo: 'order',
                cardId: cartId,
            });
        }
    }, [cartId]);

    return (
        <>
            <div className={`${className}`}>
                <Title className={title}>Jestem tu pierwszy raz!</Title>
                <p className={subtitle}>
                    Wszystkie produkty są dostępne na naszej platformie po zalogowaniu. Stwórz swoje
                    konto w mniej niż minutę i zacznij działać!
                    <span className={emoji} role="img" aria-label="smile">
                        😊
                    </span>
                </p>
                <AnimateHeight duration={500} height={isRegistrationStart ? 'auto' : 0}>
                    <RegisterForm context={orderContext} />
                </AnimateHeight>
                {isRegistrationStart ? (
                    ''
                ) : (
                    <Button color="blank" fullwidth={true} onClick={() => setRegistration(true)}>
                        Zarejestruj się
                    </Button>
                )}
            </div>
        </>
    );
};

export default RegisterShop;
