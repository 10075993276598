import { types } from './ui.actions';
import { config } from '../../config';

const statusMap = config.apiStatusMap;

const {
    START_LOADER,
    END_LOADER,
    SET_STATUS_LOADING,
    SET_STATUS_SUCCESS,
    SET_STATUS_FAIL,
    SET_STATUS_IDLE,
    SET_REQUEST_COUNT,
} = types;

export const initialState = {
    loaders: {
        cart: false,
        list: false,
    },
    requestCount: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case START_LOADER:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [payload]: true,
                },
            };
        case END_LOADER:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [payload]: false,
                },
            };
        case SET_STATUS_LOADING:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [payload]: statusMap.loading,
                },
            };
        case SET_STATUS_SUCCESS:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [payload]: statusMap.success,
                },
            };
        case SET_STATUS_FAIL:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [payload]: statusMap.fail,
                },
            };
        case SET_STATUS_IDLE:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [payload]: statusMap.idle,
                },
            };
        case SET_REQUEST_COUNT:
            return {
                ...state,
                requestCount: {
                    ...state.requestCount,
                    [payload.entity]: payload.count,
                },
            };
        default:
            return state;
    }
}
