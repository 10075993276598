export const FETCH_SURVEYS = 'FETCH_SURVEYS';
export const FETCH_SINGLE_SURVEY = 'FETCH_SINGLE_SURVEY';
export const CREATE_SURVEY = 'CREATE_SURVEY';
export const CLEAR_SURVEY = 'CLEAR_SURVEY';

export const SET_EXERCISES_LIST_ERRORS = '[Exercises] set fetch errors';
export const SET_EXERCISES_LIST_ITEMS = '[Exercises] set items';
export const SET_EXERCISES_LIST_LOADING = '[Exercises] set loading...';
export const SET_EXERCISES_LIST_PAGINATION = '[Exercises] update pagination';
export const SET_EXERCISES_LIST_SORT = '[Exercises] set sort';
export const CLEAR_EXERCISES_LIST = '[Exercises] clear';

export const SET_TRAININGS_LIST_LOADING = 'SET_TRAININGS_LIST_LOADING';
export const SET_TRAININGS_LIST_ERRORS = 'SET_TRAININGS_LIST_ERRORS';
export const SET_TRAININGS_LIST_ITEMS = 'SET_TRAININGS_LIST_ITEMS';
export const SET_TRAININGS_LIST_SORT = 'SET_TRAININGS_LIST_SORT';
export const SET_TRAININGS_LIST_PAGINATION = 'SET_TRAININGS_LIST_PAGINATION';
export const CLEAR_TRAININGS_LIST = 'CLEAR_TRAININGS_LIST';

export const ADD_MODAL = 'ADD_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';
export const REMOVE_MULTIPLE_MODALS = 'REMOVE_MULTIPLE_MODALS';
export const EDIT_MODAL = 'EDIT_MODAL';
