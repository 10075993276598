import React from 'react';
import { useSelector } from 'react-redux';

import { chooseProfile } from './choose-profile-template.module.scss';
import { getProfileListAsArray } from '../redux/profile/profile.selectors';

import Main from '../layouts/main';
import SEO from '../components/seo';
import NoProfiles from '../components/organisms/my-account/no-profiles';
import ChooseProfile from '../components/organisms/my-account/choose-profile';

const ChooseProfileTemplate = () => {
    const profiles = useSelector(getProfileListAsArray);

    return (
        <>
            <SEO title="Wybór profilu" />
            <Main className={chooseProfile} isPaddingEqualToHeader={true}>
                {profiles.length !== 0 ? <ChooseProfile profiles={profiles} /> : <NoProfiles />}
            </Main>
        </>
    );
};

export default ChooseProfileTemplate;
