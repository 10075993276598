import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { mainGrid } from '../../style/grid.module.scss';
import {
    wrapper,
    header,
    toDo,
    tips,
    calendar,
    image_wrapper,
    dateRange,
    aboutTraining,
    trainingTitle,
    noTrainingInfo,
    horizontalLineEntryMovie,
    horizontalLineTitleDescription,
    textStart,
} from './personal-training.module.scss';
import { getCurrentProfile } from '../../redux/profile/profile.selectors';
import { selectLoaderByEntity } from '../../redux/ui/ui.selectors';
import {
    selectCurrentTraining,
    selectPlayerMovie,
} from '../../redux/personal-training/personal-training.selectors';
import { formatComments } from '../../utills/data-convertion';
import { PERSONAL_TRAINING, types } from '../../redux/personal-training/personal-training.actions';
import { config } from '../../config';

import Title from '../atoms/title';
import SimpleCard from '../atoms/personal-training/simple-card';
import VideoPostPreview from '../molecules/video-post-preview';
import MovieGroupPlayer from './movie-group-player/movie-group-player';
import PersonalTrainingCalendar from './personal-training/current/calendar/personal-treining-calendar';
import Loader from '../atoms/loader';
import Separator from '../atoms/separator';
import BigTitle from '../atoms/big-title';
import Markdown from '../hoc/markdown';

const { apiStatusMap } = config;

const PersonalTraining = ({ id = null }) => {
    const dispatch = useDispatch();

    const { movie, trainingLoader, personalTraining, currentProfile } = useSelector((state) => {
        return {
            movie: selectPlayerMovie(state),
            trainingLoader: selectLoaderByEntity(state, PERSONAL_TRAINING),
            personalTraining: selectCurrentTraining(state),
            currentProfile: getCurrentProfile(state),
        };
    });

    useEffect(() => {
        let action;
        switch (true) {
            case id !== null:
                action = {
                    type: types.GET_SINGLE_TRAINING,
                    payload: parseInt(id),
                };
                break;
            default:
                action = { type: types.GET_CURRENT_TRAINING };
        }
        dispatch(action);
    }, [id, dispatch]);

    if (trainingLoader === apiStatusMap.loading) {
        return <Loader />;
    }

    if (personalTraining) {
        return (
            <div className={`${wrapper} ${mainGrid}`}>
                <section className={header}>
                    <div className={dateRange}>
                        Plan na dni&nbsp;
                        {moment(personalTraining.dateFrom).format('DD.MM')}
                        {' - '}
                        {moment(personalTraining.dateTo).format('DD.MM')}
                    </div>

                    <BigTitle className={textStart}>{personalTraining.name}</BigTitle>
                </section>

                <section className={aboutTraining}>
                    <SimpleCard title={'Opis treningu'} className={horizontalLineTitleDescription}>
                        {personalTraining.description ? (
                            <Markdown>{personalTraining.description}</Markdown>
                        ) : (
                            `${currentProfile?.firstName}, ${config.personalTraining.defaultDescription}`
                        )}
                    </SimpleCard>

                    {personalTraining.intro && (
                        <SimpleCard
                            title={'Film wprowadzający'}
                            className={horizontalLineEntryMovie}
                        >
                            <div className={image_wrapper}>
                                <VideoPostPreview
                                    videoUrl={personalTraining.intro.videoUri}
                                    provider={'vimeo'}
                                    imageUrl={personalTraining.coverUri}
                                />
                            </div>
                        </SimpleCard>
                    )}
                </section>

                <section className={toDo}>
                    <div>Do wykonania dziś:</div>
                    <Title className={trainingTitle}>Trening</Title>
                    <Separator withMargin />

                    <section>
                        <div className={calendar}>
                            <PersonalTrainingCalendar />
                        </div>

                        <MovieGroupPlayer />
                    </section>
                </section>

                {movie && (
                    <div className={tips}>
                        {movie.description && (
                            <SimpleCard title={'Opis'}>
                                <Markdown className={`ck-content`}>{movie.description}</Markdown>
                                {movie.comment && (
                                    <div>
                                        <Separator withMargin />
                                        <Markdown>{formatComments(movie.comment)}</Markdown>
                                    </div>
                                )}
                            </SimpleCard>
                        )}

                        {movie.clues && (
                            <SimpleCard title={'Wskazówki'}>
                                <Markdown className={`ck-content`}>{movie.clues}</Markdown>
                            </SimpleCard>
                        )}
                    </div>
                )}
            </div>
        );
    }

    return (
        <Title className={noTrainingInfo}>
            Dziękujemy za przesłanie ankiety. <br />
            Na jej podstawie stworzymy Twój indywidualny plan ćwiczeń w ciągu 7 dni roboczych.{' '}
            <br />
            Pamiętaj, żeby za 6-8 tygodni zaktualizować ankietę, aby dostać kolejny spersonalizowany
            plan treningowy.
        </Title>
    );
};

export default PersonalTraining;
