import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
    noAddressesMessage,
    form,
    labelInput,
    globalError,
    button,
} from './address-add.module.scss';
import {
    ADDRESS,
    clearAddressAction,
    createAddressAction,
    editAddressAction,
    getAddressAction,
} from '../../../redux/address/address.actions';
import { selectAddress } from '../../../redux/address/address.selectors';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { config } from '../../../config';

import AddressDeliveryFields, {
    getAddressDeliveryInitialValues,
    getAddressDeliveryValidationSchema,
} from '../../molecules/address-delivery-fields';
import AddressInvoiceFields, {
    addressInvoiceValidationSchema,
    addressInvoiceInitialValues,
} from '../../molecules/address-invoice-fields';
import Button from '../../atoms/button';
import TextError from '../../atoms/form-poc/text-error';
import Loader from '../../atoms/loader';
import Input from '../../atoms/form-poc/input';

const {
    addressTypes,
    apiStatusMap,
    messages: {
        form: { required },
    },
} = config;

const AddressAdd = ({ id, routeData }) => {
    const {
        routeProps: { addressType },
    } = routeData;
    const dispatch = useDispatch();
    const { address, status } = useSelector((state) => ({
        address: selectAddress(state),
        status: selectLoaderByEntity(state, ADDRESS),
    }));
    const { state: locationState } = useLocation();
    const [initialValues, setInitialValues] = useState(getInitialValues(addressType.id));

    const handleSubmit = (values, helpers) => {
        const newValues = { ...values, type: addressType.id };
        if (id) {
            dispatch(editAddressAction(id, newValues, helpers));
        } else {
            dispatch(createAddressAction(newValues, helpers));
        }
    };

    useEffect(() => {
        if (!id) return;
        dispatch(getAddressAction(id));
        return () => {
            dispatch(clearAddressAction());
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (!address) return;
        setInitialValues(address);
    }, [address]);

    if (id && !address && status === apiStatusMap.loading) {
        return <Loader />;
    }

    if (id && !address) {
        return <div>Nie udało sie pobrać adresu. Spróbuj później...</div>;
    }

    return (
        <>
            <div className={noAddressesMessage}>
                {locationState.noAddresses && (
                    <>
                        <p>
                            {addressType.id === addressTypes.delivery.id
                                ? 'Nie masz jeszcze żadnego adresu, wprowadź nowy adres dostawy dla Twoich zakupów.'
                                : 'Nie masz jeszcze żadnego adresu od faktury VAT.'}
                        </p>
                        <p>Pamiętaj możesz dodawać, usuwać oraz edytować adresy!</p>
                    </>
                )}
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={getValidationSchema(addressType.id)}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {(formik) => {
                    return (
                        <Form className={form}>
                            {formik.status === apiStatusMap.loading && (
                                <Loader fullContainer={true} />
                            )}
                            <Input
                                containerClass={labelInput}
                                name="label"
                                placeholder={
                                    addressType.id === addressTypes.delivery.id
                                        ? 'Nazwa adresu np. Dom lub praca'
                                        : 'Nazwa adresu np. Faktura'
                                }
                            />
                            {addressType.id === addressTypes.delivery.id ? (
                                <AddressDeliveryFields />
                            ) : (
                                <AddressInvoiceFields />
                            )}
                            {formik.errors.global && (
                                <TextError className={globalError}>
                                    {formik.errors.global}
                                </TextError>
                            )}
                            <Button color="yellow" className={button}>
                                {id ? 'Zapisz zmiany' : 'Zapisz adres'}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

function getInitialValues(addressTypeId) {
    const initialValues =
        addressTypes.delivery.id === addressTypeId
            ? getAddressDeliveryInitialValues()
            : addressInvoiceInitialValues;
    return {
        label: '',
        ...initialValues,
    };
}

function getValidationSchema(addressTypeId) {
    const validationSchema =
        addressTypes.delivery.id === addressTypeId
            ? getAddressDeliveryValidationSchema()
            : addressInvoiceValidationSchema;
    return Yup.object({
        label: Yup.string().required(required),
        ...validationSchema,
    });
}

export default AddressAdd;
