import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    input as inputSearch,
    wrapper,
    clearButton,
    clearIcon,
    searchIcon,
    large,
    small,
} from './input-search.module.scss';
import { input } from './input.module.scss';
import SearchIcon from '../../../assets/images/svg/icon-search.svg';
import ClearIcon from '../../../assets/images/svg/icon-close.svg';

const InputSearch = ({
    className = '',
    inputClassName,
    initValue,
    placeholder,
    onChange,
    wait,
    callbackData,
    reinitialize,
    size,
}) => {
    const [value, setValue] = useState(initValue);
    const [actualTimeout, setActualTimeout] = useState(0);

    const sizeClass = sizeClasses[size];

    const handleChange = (event) => {
        if (actualTimeout) {
            clearTimeout(actualTimeout);
        }
        const newValue = event.target.value;
        const newTimeout = setTimeout(() => {
            onChange(newValue, callbackData);
        }, wait);
        setValue(newValue);
        setActualTimeout(newTimeout);
    };

    const handleClear = () => {
        setValue('');
        onChange('', callbackData);
    };

    useEffect(() => {
        if (reinitialize) {
            setValue(initValue);
        }
    }, [initValue, reinitialize]);

    return (
        <div className={`${wrapper} ${className} ${sizeClass}`}>
            <input
                className={`
                    ${input}
                    ${inputSearch}
                    ${inputClassName}
                `}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
            />
            <SearchIcon className={searchIcon} />
            {value && (
                <button className={clearButton} onClick={handleClear}>
                    <ClearIcon className={clearIcon} />
                </button>
            )}
        </div>
    );
};

const sizeClasses = {
    large: large,
    small: small,
};

InputSearch.propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    initValue: PropTypes.string,
    placeholder: PropTypes.string,
    wait: PropTypes.number,
    onChange: PropTypes.func,
    callbackData: PropTypes.object,
    reinitialize: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'small']),
};

InputSearch.defaultProps = {
    className: '',
    inputClassName: '',
    initValue: '',
    placeholder: 'Szukaj...',
    wait: 0,
    onChange: () => {},
    callbackData: {},
    reinitialize: false,
    size: 'large',
};

export default InputSearch;
