import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
// TODO: Change to Static Image
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    header,
    container,
    button,
    card,
    title,
    label,
    input,
    cardCvv,
    error,
    cardExpiration,
    cardNumber,
    errorsBox,
} from './payu-card-form.module.scss';
import { config } from '../../config';

import Button from '../atoms/button';
import Loader from '../atoms/loader';

const configPayU = process.env.NODE_ENV === 'development' ? { dev: true } : {};

const optionsForms = {
    cardIcon: true,
    style: {
        basic: {
            fontSize: '16px',
        },
    },
    placeholder: {
        number: '',
        date: 'MM/YY',
        cvv: '',
    },
    lang: 'pl',
};

const PayUCardForm = ({ onTokenSuccess, loaderText = '', isLoading = false }) => {
    const { payULogo } = useStaticQuery(query);
    const [payUSdkForms, setPayUSdkForms] = useState(null);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const handlePayUScriptInject = (addedTags) => {
        if (addedTags.scriptTags) {
            const payUScript = addedTags.scriptTags[0];
            payUScript.onload = () => {
                setPayUSdkForms(window.PayU(config.payU.posId, configPayU));
            };
        }
    };

    const handleSubmit = () => {
        setErrors([]);
        setLoading(true);

        payUSdkForms
            .tokenize('MULTI')
            .then((result) => {
                if (result.status === 'SUCCESS') {
                    // sendPayUToken(result.body.token);
                    setLoading(false);
                    if (typeof onTokenSuccess !== 'function') return;
                    onTokenSuccess(result.body.token);
                } else {
                    const newErrors = result.error.messages.map((err) => err.message);
                    setErrors([...newErrors]);
                    setLoading(false);
                }
            })
            .catch(() => {
                setErrors(['Wystąpił błąd, nie udało się zweryfikować danych karty']);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (payUSdkForms) {
            const secureForms = payUSdkForms.secureForms();
            const cardNumber = secureForms.add('number', optionsForms);
            const cardDate = secureForms.add('date', optionsForms);
            const cardCvv = secureForms.add('cvv', optionsForms);

            cardNumber.render('#payu-card-number');
            cardDate.render('#payu-card-date');
            cardCvv.render('#payu-card-cvv');
        }
    }, [payUSdkForms]);

    return (
        <>
            <Helmet onChangeClientState={(_, addedTags) => handlePayUScriptInject(addedTags)}>
                <script src={config.payU.sdk} type="text/javascript" />
            </Helmet>
            <div className={container}>
                {(loading || isLoading) && <Loader fullContainer={true} text={loaderText} />}
                <div className={header}>
                    <h2 className={title}>Dane karty</h2>
                    <GatsbyImage image={getImage(payULogo)} alt="PayU Logo" />
                </div>
                <div className={card}>
                    <div className={cardNumber}>
                        <aside className={label}>Numer karty</aside>
                        <div className={input} id="payu-card-number" />
                    </div>
                    <div className={cardExpiration}>
                        <aside className={label}>Ważna do</aside>
                        <div className={input} id="payu-card-date" />
                    </div>
                    <div className={cardCvv}>
                        <aside className={label}>CVV</aside>
                        <div className={input} id="payu-card-cvv" />
                    </div>
                </div>
                {errors.length > 0 && (
                    <div className={errorsBox}>
                        {errors.map((err, index) => (
                            <p key={`payu-error-${index}`} className={error}>
                                {err}
                            </p>
                        ))}
                    </div>
                )}
                <Button className={button} color="lime" onClick={handleSubmit} disabled={loading}>
                    Zapłać
                </Button>
            </div>
        </>
    );
};

const query = graphql`
    {
        payULogo: file(relativePath: { eq: "payu-logo-color.png" }) {
            childImageSharp {
                gatsbyImageData(width: 70, quality: 100, layout: FIXED, placeholder: BLURRED)
            }
        }
    }
`;

export default PayUCardForm;
