import React from 'react';
import PropTypes from 'prop-types';

import { list } from './gallery-miniature-list.module.scss';

import GalleryMiniature from './gallery-miniature';

const GalleryMiniatureList = ({
    className,
    miniatureClassName,
    mediaImages,
    activeIndex,
    onClick,
    disabled,
}) => {
    return (
        <ul className={`${list} ${className}`}>
            {mediaImages.map((mediaImage, index) => {
                return (
                    <li key={`gallery-miniature-${index}`}>
                        <GalleryMiniature
                            className={miniatureClassName}
                            mediaImage={mediaImage}
                            onClick={() => onClick(index)}
                            isActive={index === activeIndex}
                            disabled={disabled}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

GalleryMiniatureList.propTypes = {
    className: PropTypes.string,
    miniatureClassName: PropTypes.string,
    mediaImages: PropTypes.array.isRequired,
    activeIndex: PropTypes.number,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

GalleryMiniatureList.defaultProps = {
    className: '',
    miniatureClassName: '',
    activeIndex: 0,
    onClick: () => {},
    disabled: false,
};

export default GalleryMiniatureList;
