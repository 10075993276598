import React, { useEffect } from 'react';
import { FieldArray } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    container,
    addButton,
    deleteButton,
    ingredientSelect,
    inputGroup,
} from './ingredient-field-array.module.scss';
import TrashIcon from '../../assets/images/svg/icon-trash.svg';
import PlusIcon from '../../assets/images/svg/plus.svg';
import { getRecipesIngredientsAction } from '../../redux/recipes/ingredients/actions/get-recipes-ingredients';
import { getRecipesUnitsAction } from '../../redux/recipes/units/actions/get-recipes-units';

import InputSelect from '../atoms/form-poc/input-select';
import Input from '../atoms/form-poc/input';
import Button from '../atoms/button';
import Loader from '../atoms/loader';

const IngredientFieldArray = ({
    formik,
    name,
    ingredientName,
    quantityName,
    unitName,
    ingredientLabel,
    quantityLabel,
    unitLabel,
    ingredientPlaceholder,
    quantityPlaceholder,
    unitPlaceholder,
    className,
    addButtonClass,
    allowUserInput,
    ingredients,
    defaultUnits,
    dispatch,
}) => {
    const emptyIngredient = {
        [ingredientName]: '',
        [quantityName]: '',
        [unitName]: '',
    };

    const isIngredientSelected = (index) => {
        const ingredientValue = formik.values[name][index][ingredientName].label;
        return !ingredientValue;
    };

    const checkUnitValue = (newIngredient, index) => {
        const selectedUnit = formik.values[name][index][unitName];

        if (!selectedUnit) return;

        const allowedUnits = getAllowedUnits(null, newIngredient);
        const isUnitAllowed = allowedUnits.some((unit) => unit.value === selectedUnit.value);

        if (!isUnitAllowed) {
            formik.setFieldValue(`${name}.${index}.${unitName}`, '');
        }
    };

    const getAllowedUnits = (index, ingredient) => {
        const ingredientValue = ingredient || formik.values[name][index][ingredientName];

        if (!ingredientValue) return [];

        if (ingredientValue.allowedUnits?.length > 0) {
            return ingredientValue.allowedUnits;
        }

        return defaultUnits;
    };

    const handleAddIngredient = (arrayHelpers) => {
        arrayHelpers.insert(formik.values.ingredients.length, emptyIngredient);
    };

    const handleDeleteIngredient = (arrayHelpers, index) => {
        if (formik.values[name].length <= 1) {
            arrayHelpers.replace(index, emptyIngredient);
            return;
        }
        arrayHelpers.remove(index);
    };

    const getIngredients = (formik) => {
        const selectedIngredientsIds = formik.values[name].map((item) => {
            return item.ingredient.value;
        });
        return ingredients.filter(
            (ingredient) => !selectedIngredientsIds.includes(ingredient.value)
        );
    };

    useEffect(() => {
        if (!ingredients.length) {
            dispatch(getRecipesIngredientsAction());
        }
        if (!defaultUnits.length) {
            dispatch(getRecipesUnitsAction());
        }
    }, [dispatch, ingredients.length, defaultUnits.length]);

    if (!ingredients.length || !defaultUnits.length) {
        return <Loader />;
    }

    return (
        <FieldArray name={name}>
            {(arrayHelpers) => {
                return (
                    <div className={`${container} ${className}`}>
                        {formik.values[name].map((ingredient, index) => (
                            <div className={inputGroup} key={`input-group-${name}-${index}`}>
                                <InputSelect
                                    value={formik.values[name][index][ingredientName]}
                                    name={`${name}.${index}.${ingredientName}`}
                                    options={getIngredients(formik)}
                                    label={`${ingredientLabel} ${index + 1}`}
                                    containerClass={ingredientSelect}
                                    placeholder={ingredientPlaceholder}
                                    onChange={(newIngredient) =>
                                        checkUnitValue(newIngredient, index)
                                    }
                                    allowUserInput={allowUserInput}
                                />
                                <Input
                                    type="number"
                                    name={`${name}.${index}.${quantityName}`}
                                    label={quantityLabel}
                                    placeholder={quantityPlaceholder}
                                />
                                <InputSelect
                                    value={formik.values[name][index][unitName]}
                                    name={`${name}.${index}.${unitName}`}
                                    options={getAllowedUnits(index)}
                                    label={unitLabel}
                                    isDisabled={isIngredientSelected(index)}
                                    placeholder={unitPlaceholder}
                                />
                                <button
                                    type="button"
                                    className={deleteButton}
                                    onClick={() => handleDeleteIngredient(arrayHelpers, index)}
                                    aria-label={`usuń ${ingredientLabel}`}
                                >
                                    <TrashIcon />
                                </button>
                            </div>
                        ))}
                        <Button
                            color="blank"
                            className={`${addButton} ${addButtonClass}`}
                            type="button"
                            onClick={() => handleAddIngredient(arrayHelpers)}
                            icon={PlusIcon}
                            size="small"
                        >
                            Dodaj kolejny {ingredientLabel.toLowerCase()}
                        </Button>
                    </div>
                );
            }}
        </FieldArray>
    );
};

IngredientFieldArray.propTypes = {
    formik: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    ingredientName: PropTypes.string.isRequired,
    quantityName: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    ingredientLabel: PropTypes.string,
    quantityLabel: PropTypes.string,
    unitLabel: PropTypes.string,
    ingredientPlaceholder: PropTypes.string,
    quantityPlaceholder: PropTypes.string,
    unitPlaceholder: PropTypes.string,
    className: PropTypes.string,
    addButtonClass: PropTypes.string,
    allowUserInput: PropTypes.bool,
};

IngredientFieldArray.defaultProps = {
    ingredientLabel: 'Składnik',
    quantityLabel: 'Wartość',
    unitLabel: 'Jednostka',
    ingredientPlaceholder: '',
    quantityPlaceholder: '',
    unitPlaceholder: '',
    className: '',
    addButtonClass: '',
    allowUserInput: false,
};

const mapStateToProps = (state) => {
    const ingredients = state.recipes.ingredients.data.map((ingredient) => {
        return {
            label: ingredient.name,
            value: ingredient.ingredientId,
            allowedUnits: ingredient.units.map((unit) => {
                return {
                    label: unit.name,
                    value: unit.unitId,
                };
            }),
        };
    });
    const defaultUnits = state.recipes.units.data.map((unit) => {
        return {
            label: unit.name,
            value: unit.unitId,
        };
    });
    return { ingredients, defaultUnits };
};

export default connect(mapStateToProps)(IngredientFieldArray);
