// extracted by mini-css-extract-plugin
export var comments = "recipe-module--comments--wCv52";
export var content = "recipe-module--content--plJB0";
export var editLoading = "recipe-module--edit-loading--VC6l-";
export var error = "recipe-module--error--HR4h6";
export var hero = "recipe-module--hero--HZsTp";
export var heroContent = "recipe-module--hero-content--u2-Ky";
export var heroRatioClass = "recipe-module--hero-ratio-class--CzxHv";
export var layout = "recipe-module--layout--zfTqA";
export var loader = "recipe-module--loader--MBsao";
export var rating = "recipe-module--rating--noyEq";
export var ratingContainer = "recipe-module--rating-container--iQl75";
export var related = "recipe-module--related--Hyv-J";
export var relatedSeparator = "recipe-module--related-separator--x-R9T";