import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { container, sliderBox, imgBox } from './injectable-slider.module.scss';
import QueryEdgeNode from '../../models/query-edge-node.model';
import { IInjectableSlider } from '../../models/injectable-slider.model';
import getNodes from '../../utills/get-nodes';

import Slider from '../hoc/slider';

interface IInjectableSliderProps {
    className?: string;
    sliderId: number;
}

interface InjectableSliderQueryResult {
    allInjectableSlider: QueryEdgeNode<IInjectableSlider>;
}

const InjectableSlider: React.FC<IInjectableSliderProps> = ({ className = '', sliderId }) => {
    const { allInjectableSlider } = useStaticQuery<InjectableSliderQueryResult>(query);
    const sliders = getNodes(allInjectableSlider);
    const slider = sliders.find((slider) => slider.injectableSliderId === sliderId);
    const slides = slider?.media;

    if (!slides || !slides.length) return null;

    return (
        <div className={`${container} ${className}`}>
            <Slider className={sliderBox}>
                {slides.map((slide) => {
                    return (
                        <div key={`slide-${slide.fileId}`} className={imgBox}>
                            <img src={slide.url} alt={slide.alt || ''} />
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

const query = graphql`
    query {
        allInjectableSlider {
            edges {
                node {
                    ...injectableSliderFields
                }
            }
        }
    }
`;

export default InjectableSlider;
