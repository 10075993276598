import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link as ReachRouterLink } from '@reach/router';

import { container, title, button } from './dashboard-bar.module.scss';

import Button from '../atoms/button';

interface IDashboardBarProps {
    className?: string;
    onClick?(): void;
    buttonText?: string;
    to?: string;
    linkType?: 'gatsby' | 'reach-router';
    children?: React.ReactNode;
}

const DashboardBar: React.FC<IDashboardBarProps> = ({
    className = '',
    onClick,
    buttonText = '',
    to,
    linkType = 'reach-router',
    children,
}) => {
    const Link = linkType === 'reach-router' ? ReachRouterLink : GatsbyLink;
    return (
        <div className={`${container} ${className}`}>
            <h3 className={title}>{children}</h3>
            {to ? (
                <Link to={to}>
                    <Button className={button} size="small" color="yellow">
                        {buttonText}
                    </Button>
                </Link>
            ) : (
                <Button className={button} onClick={onClick} size="small" color="yellow">
                    {buttonText}
                </Button>
            )}
        </div>
    );
};

export default DashboardBar;
