import axios from 'axios';

import { config } from '../config';

export const setNewsletter = (data) => {
    return axios.post(config.endpoints.newsletterSignUp, data, {
        withCredentials: true,
    });
};

export const deleteNewsletter = (data) => {
    return axios.post(
        config.endpoints.newsletterUnsubscribe,
        { email: data.email },
        {
            withCredentials: true,
        }
    );
};
