import React from 'react';
import PropTypes from 'prop-types';

import { author, header } from './recipe-ingredient-list.module.scss';
import {
    simpleView as simpleViewClass,
    title,
    separator,
} from './common-recipe-molecules.module.scss';

import RecipeAuthor from '../../atoms/recipe/recipe-author';
import Separator from '../../atoms/separator';
import RecipeIngredient from '../../atoms/recipe/recipe-ingredient';

const RecipeIngredientList = ({
    recipe,
    className = '',
    onIngredientChange,
    simpleView,
    multiplier,
}) => {
    return (
        <div
            className={`
                ${className}
                ${simpleView ? simpleViewClass : ''}
            `}
        >
            <div className={header}>
                <h2 className={title}>Składniki</h2>
                {!simpleView && recipe.author && (
                    <RecipeAuthor className={author} author={recipe.author} color="black" />
                )}
            </div>
            <Separator className={separator} />
            <ul>
                {recipe.ingredients.map((ingredient, index) => (
                    <RecipeIngredient
                        index={index}
                        key={`ingredient-${index}`}
                        ingredient={ingredient}
                        multiplier={multiplier}
                        onIngredientChange={onIngredientChange}
                        simpleView={simpleView}
                    />
                ))}
            </ul>
        </div>
    );
};

RecipeIngredientList.propTypes = {
    recipe: PropTypes.object.isRequired,
    className: PropTypes.string,
    onIngredientChange: PropTypes.func,
    simpleView: PropTypes.bool,
};

RecipeIngredientList.defaultProps = {
    className: '',
    onIngredientChange: null,
    simpleView: false,
};

export default RecipeIngredientList;
