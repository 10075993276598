import React, { useMemo } from 'react';
import { navigate } from '@reach/router';
import { Link } from 'gatsby';

import {
    container,
    list,
    loader,
    message,
    switcher,
    header,
    title,
    back,
    backIcon,
} from './courses.module.scss';
import ArrowLeftIcon from '../../../assets/images/svg/icon-arrow-left.svg';
import { IRouteData } from '../../../models/router.model';
import { getAbsolutePath } from '../../../routes';
import { TCourseType, useCourses } from '../../../hooks/use-courses';

import EmptyCourses from '../../atoms/empty-courses/empty-courses';
import CoursesListItem from './courses/courses-list-item/courses-list-item';
import IntersectBox from '../../atoms/intersect-box';
import Loader from '../../atoms/loader';
import List from '../../molecules/list/list';
import PillSwitcher, { IPillSwitcherProps } from '../../molecules/pill-switcher';

interface ICoursesProps {
    courseType: TCourseType;
    isMine: boolean;
    routeData: IRouteData;
}

const MY_OPTION_VALUE = 'my';
const NOT_MINE_OPTION_VALUE = 'not-mine';

const Courses: React.FC<ICoursesProps> = ({ courseType, isMine, routeData }) => {
    const courses = useCourses(courseType, isMine);
    const options: IPillSwitcherProps['options'] = [
        {
            label: copy.my,
            value: MY_OPTION_VALUE,
        },
        {
            label: copy.notMine,
            value: NOT_MINE_OPTION_VALUE,
        },
    ];
    const activeOption = isMine
        ? options.find((option) => option.value === MY_OPTION_VALUE)
        : options.find((option) => option.value === NOT_MINE_OPTION_VALUE);
    const backPathname = useMemo(() => {
        return getAbsolutePath('APP_MY_ACCOUNT_EXERCISES_HOME');
    }, []);

    const handleChange: IPillSwitcherProps['onChange'] = async (option) => {
        const key =
            option.value === MY_OPTION_VALUE ? keys.my[courseType] : keys.notMine[courseType];
        await navigate(getAbsolutePath(key));
    };

    return (
        <div className={container}>
            <Link to={backPathname} className={back}>
                <ArrowLeftIcon className={backIcon} /> {copy.back}
            </Link>
            <div className={header}>
                {routeData.label && <h3 className={title}>{routeData.label}</h3>}
                <PillSwitcher
                    className={switcher}
                    size="small"
                    options={options}
                    activeOption={activeOption}
                    onChange={handleChange}
                    disabled={courses.status === 'loading'}
                />
            </div>
            {courses.items.length > 0 && (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <List listClassName={list} items={courses.items} ItemComponent={CoursesListItem} />
            )}
            <IntersectBox onIntersecting={courses.getNextPage}>
                {(courses.status === 'idle' || courses.status === 'loading') && (
                    <Loader className={loader} />
                )}
                {courses.status === 'fail' && <p className={message}>{copy[courseType].error}</p>}
                {courses.status === 'success' && courses.items.length === 0 && isMine && (
                    <EmptyCourses
                        onClick={() => navigate(getAbsolutePath(keys.notMine[courseType]))}
                        title={copy[courseType].empty.title}
                        content={copy[courseType].empty.content}
                        buttonContent={copy[courseType].empty.button}
                    />
                )}
                {courses.status === 'success' && courses.items.length === 0 && !isMine && (
                    <p className={message}>{copy[courseType].noItems}</p>
                )}
            </IntersectBox>
        </div>
    );
};

const keys: Record<'my' | 'notMine', Record<TCourseType, string>> = {
    my: {
        course: 'APP_MY_ACCOUNT_COURSES_MY',
        challenge: 'APP_MY_ACCOUNT_CHALLENGES_MY',
        trainingProgram: 'APP_MY_ACCOUNT_TRAINING_PROGRAMS_MY',
    },
    notMine: {
        course: 'APP_MY_ACCOUNT_COURSES_NOT_MINE',
        challenge: 'APP_MY_ACCOUNT_CHALLENGES_NOT_MINE',
        trainingProgram: 'APP_MY_ACCOUNT_TRAINING_PROGRAMS_NOT_MINE',
    },
};

const copy = {
    my: 'Moje',
    notMine: 'Dodatkowe',
    back: 'Wróć',
    course: {
        noItems: 'Nie ma jeszcze żadnych kursów.',
        error: 'Nie udało się pobrać kursów, spróbuj ponownie później...',
        empty: {
            title: 'Nie posiadasz jeszcze wykupionych kursów',
            content: 'Kliknij w przycisk poniżej aby przejść do wszystkich dostępnych kursów.',
            button: 'ZOBACZ KURSY',
        },
    },
    trainingProgram: {
        noItems: 'Nie ma jeszcze żadnych programów treningowych.',
        error: 'Nie udało się pobrać planów treningowych, spróbuj ponownie później...',
        empty: {
            title: 'Nie posiadasz jeszcze wykupionych programów treningowych',
            content:
                'Kliknij w przycisk poniżej aby przejść do wszystkich dostępnych programów treningowych.',
            button: 'ZOBACZ PROGRAMY TRENINGOWE',
        },
    },
    challenge: {
        noItems: 'Nie ma jeszcze żadnych wyzwań.',
        error: 'Nie udało się pobrać wyzwań, spróbuj ponownie później...',
        empty: {
            title: 'Nie posiadasz jeszcze wykupionych wyzwań',
            content: 'Kliknij w przycisk poniżej aby przejść do wszystkich wyzwań.',
            button: 'ZOBACZ WYZWANIA',
        },
    },
};

export default Courses;
