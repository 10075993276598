import { actions } from '../attachments/list/actions';

export const initialState = {
    data: [],
    meta: {
        loading: false,
        success: null,
        errors: null,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.START_FETCHING:
            return {
                ...state,
                meta: {
                    loading: true,
                    success: null,
                    errors: null,
                },
            };

        case actions.LOADING_SUCCESS:
            return {
                ...state,
                data: action.payload,
                meta: {
                    loading: false,
                    success: true,
                    errors: null,
                },
            };

        case actions.SET_ERRORS:
            return {
                ...state,
                meta: {
                    loading: false,
                    success: null,
                    errors: action.payload,
                },
            };
        default:
            return state;
    }
}
