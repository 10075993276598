import { types } from './courses.actions';

const { SET_COURSES, RESTORE_INITIAL_STATE } = types;

export const initialState = {
    items: [],
    pagination: {
        perPage: 50,
        pageCount: 0,
        totalCount: 0,
        currentPage: 0,
    },
};

const coursesReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_COURSES:
            return {
                ...state,
                items: [...state.items, ...payload.items],
                pagination: payload.pagination,
            };
        case RESTORE_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default coursesReducer;
