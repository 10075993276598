import { types } from './cart.actions';

export const initialState = {
    cartId: null,
    price: {},
    items: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.SET_CART_DATA:
            return { ...payload };
        case types.MERGE_CART_DATA:
            return {
                ...state,
                ...payload,
            };
        case types.SET_CART_ERRORS:
            return {
                ...state,
                errors: payload,
            };
        case types.CLEAR_CART:
            return null;
        default:
            return state;
    }
}
