import { combineReducers } from 'redux';

import { types } from './gamification.actions';
import { genericListReducer } from '../generic-list/generic-list.reducer';

export const GAMIFICATION = '[GAMIFICATION]';
export const GAMIFICATION_HISTORY = 'history';
export const GAMIFICATION_TARIFF = 'tariff';

const initialState = {};

function alertsGamificationReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.SET_ALERT:
            return {
                ...state,
                [payload]: '',
            };
        case types.CLEAR_ALERT:
            const newState = { ...state };
            delete newState[payload];
            return newState;
        case types.CLEAR_ALL_ALERTS:
            return initialState;
        default:
            return state;
    }
}

function transactionValidateReducer(state = [], action) {
    const { type, payload } = action;

    switch (type) {
        case types.SET_FORBIDDEN_REASON_ID:
            return [...payload];
        default:
            return state;
    }
}

const gamificationReducer = combineReducers({
    alerts: alertsGamificationReducer,
    [GAMIFICATION_HISTORY]: genericListReducer(GAMIFICATION, GAMIFICATION_HISTORY),
    [GAMIFICATION_TARIFF]: genericListReducer(GAMIFICATION, GAMIFICATION_TARIFF),
    forbiddenReasonsId: transactionValidateReducer,
});

export default gamificationReducer;
