import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
    title,
    loader,
    grid,
    slider,
    prev,
    next,
    dots,
    list,
    banner,
    listBox,
} from './welcome.module.scss';
import { getCurrentProfile } from '../../../redux/profile/profile.selectors';
import useSlider from '../../../hooks/use-slider';
import {
    selectWelcomeSlides,
    selectWelcomeSlidesStatus,
} from '../../../redux/welcome-slides/welcome-slides.selectors';
import {
    clearWelcomeSlidesAction,
    getWelcomeSlidesAction,
} from '../../../redux/welcome-slides/welcome-slides.actions';

import Title from '../../atoms/title';
import Loader from '../../atoms/loader';
import ArrowButton from '../../atoms/arrow-button';
import SliderDots from '../../atoms/slider-dots';
import WelcomeSlide from '../../molecules/welcome-slide';
import LinkBanner from '../../molecules/link-banner';
import List from '../../molecules/list/list';

const sliderConfig = {
    arrows: false,
    autoplay: false,
    dots: false,
    speed: 700,
};

const Welcome = () => {
    const {
        dietImg,
        developmentImg,
        forumImg,
        challengesImg,
        notificationsImg,
        prizesImg,
    } = useStaticQuery(query);
    const dispatch = useDispatch();
    const currentProfile = useSelector(getCurrentProfile);
    const welcomeSlides = useSelector(selectWelcomeSlides);
    const status = useSelector(selectWelcomeSlidesStatus);

    const links = [
        {
            id: 1,
            to: '/app/moje-konto/dieta',
            description:
                'Tu znajdziesz pyszne przepisy, listę zakupową oraz planer posiłków, a w nim jadłospisy.',
            image: getImage(dietImg),
            title: 'Dieta',
            className: banner,
        },
        {
            id: 2,
            to: '/app/moje-konto/strefa-rozwoju',
            description:
                'Tu są wszystkie ćwiczenia, treningi personalne, wyzwania, programy treningowe oraz kursy.',
            image: getImage(developmentImg),
            title: 'Strefa rozwoju',
            className: banner,
        },
        {
            id: 3,
            to: '/app/moje-konto/grupa',
            description:
                'Tu jest forum naszej społeczności, w którym znajdziesz mnóstwo wartości, motywacji i wsparcia.',
            image: getImage(forumImg),
            title: 'Grupa',
            className: banner,
        },
        {
            id: 4,
            to: '/app/moje-konto/strefa-rozwoju/wyzwania',
            description:
                'Tu odkryjesz poprzednie, aktualne i nadchodzące wyzwania, które systematycznie organizujemy dla naszej społeczności.',
            image: getImage(challengesImg),
            title: 'Wyzwania',
            className: banner,
        },
        {
            id: 5,
            to: '/app/moje-konto/powiadomienia',
            description: 'Bądź na bieżąco z najnowszymi informacjami i aktualizacjami.',
            image: getImage(notificationsImg),
            title: 'Powiadomienia',
            className: banner,
        },
        {
            id: 6,
            to: '/app/moje-konto/twoje-ketony',
            description:
                'Doceniamy Twoją aktywność i dodatkowo wynagradzamy za zaangażowanie. Opłaca się być w Strefie Przemian.',
            image: getImage(prizesImg),
            title: 'Nagrody',
            className: banner,
        },
    ];

    const {
        getPrev,
        getNext,
        sliderSettings,
        slideIndex,
        setSlideIndex,
        isChanging,
        sliderRef,
    } = useSlider(sliderConfig);

    useEffect(() => {
        dispatch(getWelcomeSlidesAction());
        return () => {
            dispatch(clearWelcomeSlidesAction());
        };
    }, [dispatch]);

    return (
        <>
            <Title Tag="h3" className={title}>
                Witaj, {currentProfile && currentProfile.firstName}!
            </Title>
            {!status || (status === 'loading' && <Loader className={loader} />)}
            {status === 'success' && (
                <>
                    <div className={grid}>
                        <div className={slider}>
                            <SlickSlider {...sliderSettings} ref={sliderRef}>
                                {welcomeSlides.map((welcomeSlide) => {
                                    return (
                                        <WelcomeSlide
                                            welcomeSlide={welcomeSlide}
                                            key={`welcome-slide-${welcomeSlide.articleId}`}
                                            isDisabled={isChanging}
                                        />
                                    );
                                })}
                            </SlickSlider>
                        </div>
                        {welcomeSlides.length > 1 && (
                            <>
                                <ArrowButton
                                    className={prev}
                                    onClick={getPrev}
                                    disabled={isChanging}
                                    dark={true}
                                />
                                <ArrowButton
                                    className={next}
                                    onClick={getNext}
                                    rotateDeg={180}
                                    disabled={isChanging}
                                    dark={true}
                                />
                                <SliderDots
                                    className={dots}
                                    slides={welcomeSlides}
                                    onClick={setSlideIndex}
                                    activeSlideIndex={slideIndex}
                                    disabled={isChanging}
                                    dark={true}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
            <div className={listBox}>
                <List listClassName={list} ItemComponent={LinkBanner} items={links} path={''} />
            </div>
        </>
    );
};

const query = graphql`
    {
        dietImg: file(relativePath: { eq: "banner-dieta.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        developmentImg: file(relativePath: { eq: "banner-strefa-rozwoju.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        forumImg: file(relativePath: { eq: "banner-grupa.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        challengesImg: file(relativePath: { eq: "banner-wyzwania.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        notificationsImg: file(relativePath: { eq: "banner-powiadomienia.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        prizesImg: file(relativePath: { eq: "banner-nagrody.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        exerciseAtlasImg: file(relativePath: { eq: "banner-atlas-cwiczen.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        trainingAtlasImg: file(relativePath: { eq: "banner-atlas-treningow.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        personalTrainingImg: file(relativePath: { eq: "banner-trening-personalny.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
    }
`;

export default Welcome;
