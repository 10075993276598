import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { REHYDRATE } from 'redux-persist/es/constants';

export interface IBackgroundRehydratorProps {
    storageKey?: string;
}

export default function BackgroundRehydrator({
    storageKey = 'persist:root',
    children,
}: PropsWithChildren<IBackgroundRehydratorProps>) {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleStorageChange = () => {
            dispatch({ type: REHYDRATE, key: storageKey });
        };

        if (process.env.BACKGROUND_REHYDRATION_ENABLE !== '1') {
            return;
        }

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [storageKey, dispatch]);

    return children;
}
