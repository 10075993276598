import React from 'react';

import { container } from './get-points-button-wrapper.module.scss';

export interface IGetPointsButtonWrapperProps {
    children?: React.ReactNode;
    className?: string;
}

export default function GetPointsButtonWrapper({
    children,
    className = '',
}: IGetPointsButtonWrapperProps) {
    return <div className={`${container} ${className}`}>{children}</div>;
}
