import { RefObject, useLayoutEffect, useState } from 'react';

import useWindowWidth from './use-window-width';

type elementWidth = number | undefined;
type elementHeight = number | undefined;

type TUseDimensionsReturn = [elementWidth, elementHeight];

const useDimensions = (ref: RefObject<HTMLElement>): TUseDimensionsReturn => {
    const windowWidth = useWindowWidth();
    const [width, setWidth] = useState<number | undefined>();
    const [height, setHeight] = useState<number | undefined>();

    useLayoutEffect(() => {
        if (!ref.current) return;
        setWidth(ref.current.getBoundingClientRect().width);
        setHeight(ref.current.getBoundingClientRect().height);
    }, [ref, windowWidth]);

    return [width, height];
};

export default useDimensions;
