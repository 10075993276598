import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { labelBox } from './nested-nav.module.scss';

import CustomLink from '../hoc/custom-link';
import CounterDot from '../atoms/counter-dot';

const NestedNav = ({ navItems, classes, buttonContent, onItemClick, collapse }) => {
    const [openSubNavs, setOpenSubNavs] = useState([]);

    const toggleSubNav = (e, clickedKey) => {
        e.stopPropagation();
        e.preventDefault();
        let newOpenSubNavs;
        if (openSubNavs.includes(clickedKey)) {
            newOpenSubNavs = openSubNavs.filter((key) => key !== clickedKey);
        } else {
            newOpenSubNavs = [...openSubNavs, clickedKey];
        }
        setOpenSubNavs(newOpenSubNavs);
    };

    const handleItemClick = () => {
        onItemClick();
    };

    useEffect(() => {
        if (collapse) {
            setOpenSubNavs([]);
        }
    }, [collapse]);

    const renderNav = (itemsArr, level, key) => {
        const actualLevel = level || level === 0 ? level + 1 : 0;
        const levelClass = classes[`level_${actualLevel}`] ? classes[`level_${actualLevel}`] : '';

        const setListClosedClass = () => {
            if (!openSubNavs.includes(key) && classes.navListClosed && actualLevel > 0) {
                return classes.navListClosed;
            }
            return '';
        };

        return (
            <ul
                className={`
                    ${classes.navList ? classes.navList : ''}
                    ${levelClass}
                    ${setListClosedClass()}
                `}
            >
                {itemsArr.map((item) => {
                    if (item.hideInNav) return null;
                    const isSubNavNeeded = item.routes && item.routes.length > 1;
                    return (
                        <li
                            key={`nav-item-${item.key}`}
                            className={classes.navItem ? classes.navItem : ''}
                        >
                            <div className={classes.navLinkBox ? classes.navLinkBox : ''}>
                                <CustomLink
                                    to={item.absolutePath}
                                    onClick={handleItemClick}
                                    className={classes.navLink ? classes.navLink : ''}
                                    currentClassName={
                                        classes.navLinkActive ? classes.navLinkActive : ''
                                    }
                                    partiallyCurrentClassName={
                                        classes.navLinkPartiallyActive
                                            ? classes.navLinkPartiallyActive
                                            : ''
                                    }
                                >
                                    <span className={labelBox}>
                                        {item.label}
                                        {item.routeProps?.counter && (
                                            <CounterDot type={item.routeProps.counter} />
                                        )}
                                    </span>
                                    {isSubNavNeeded && (
                                        <button
                                            onClick={(e) => toggleSubNav(e, item.key)}
                                            className={classes.button ? classes.button : ''}
                                        >
                                            {buttonContent}
                                        </button>
                                    )}
                                </CustomLink>
                            </div>
                            {isSubNavNeeded && renderNav(item.routes, actualLevel, item.key)}
                        </li>
                    );
                })}
            </ul>
        );
    };

    return navItems && navItems.length > 0 ? (
        <nav className={classes.nav ? classes.nav : ''}>{renderNav(navItems)}</nav>
    ) : null;
};

NestedNav.propTypes = {
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            absolutePath: PropTypes.string.isRequired,
            routes: PropTypes.array,
        })
    ),
    classes: PropTypes.shape({
        nav: PropTypes.string,
        navList: PropTypes.string,
        navListClosed: PropTypes.string,
        navItem: PropTypes.string,
        navLink: PropTypes.string,
        navLinkActive: PropTypes.string,
        navLinkPartiallyActive: PropTypes.string,
        button: PropTypes.string,
        level_1: PropTypes.string,
    }),
    buttonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onItemClick: PropTypes.func,
    collapse: PropTypes.bool,
};

NestedNav.defaultProps = {
    buttonContent: '...',
    classes: {
        nav: '',
        navList: '',
        navListClosed: '',
        navItem: '',
        navLink: '',
        navLinkActive: '',
        navLinkPartiallyActive: '',
        button: '',
        level_1: '',
    },
    onItemClick: () => {},
    collapse: false,
};

export default NestedNav;
