import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
    input,
    button,
    separator,
    modal,
    errorMessage,
    formAdd,
    formSelect,
} from './add-shopping-list-modal.module.scss';
import { titleText } from '../../../templates/custom-modal.module.scss';
import { config } from '../../../config';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { selectShoppingLists } from '../../../redux/shopping-lists/shopping-lists.selectors';
import {
    clearShoppingLists,
    getShoppingLists,
    SHOPPING_LISTS,
} from '../../../redux/shopping-lists/shopping-lists.actions';
import usePagination from '../../../hooks/use-pagination';

import SeparatorWithText from '../../atoms/separator-with-text';
import Input from '../../atoms/form-poc/input';
import Button from '../../atoms/button';
import Loader from '../../atoms/loader';
import CustomModal from '../../../templates/custom-modal';
import InputSelectInfinite from '../../atoms/form-poc/input-select-infinite';
import TextError from '../../atoms/form-poc/text-error';

const { required } = config.messages.form;
const { formsStatusMap, apiStatusMap } = config;

const initialValuesAddList = { name: '' };
const initialValuesSelectList = { selectedList: '' };

const validationSchemaAddList = Yup.object({
    name: Yup.string().required(required),
});
const validationSchemaSelectList = Yup.object({
    selectedList: Yup.object().required(required),
});

const getOptions = (pages) => {
    if (Object.keys(pages).length) {
        const lists = [];
        Object.values(pages).forEach((page) => {
            page.forEach((list) => lists.push(list));
        });
        return lists.map((list) => {
            return {
                label: `🧾 ${list.name} • ${moment(new Date(list.createdAt * 1000)).format(
                    'DD.MM.YYYY'
                )}`,
                value: list.id,
            };
        });
    }
    return [];
};

const AddShoppingListModal = ({ modalId, onNewListSubmit, onEditListSubmit }) => {
    const fetchStatus = useSelector((state) => selectLoaderByEntity(state, SHOPPING_LISTS));
    const { page, pages, pagination, getPage } = usePagination({
        entitySelector: selectShoppingLists,
        action: getShoppingLists,
        clearAction: clearShoppingLists,
        isUrlPaginated: false,
    });

    const handleSubmitNewList = (formValues, formikBag) => {
        onNewListSubmit(formValues, formikBag, modalId);
    };

    const handleSubmitEditList = (formValues, formikBag) => {
        onEditListSubmit(formValues, formikBag, modalId);
    };

    return (
        <CustomModal modalId={modalId} className={modal}>
            <Formik
                initialValues={initialValuesAddList}
                onSubmit={handleSubmitNewList}
                validationSchema={validationSchemaAddList}
            >
                {(formik) => (
                    <Form className={formAdd}>
                        {formik.status === formsStatusMap.loading && (
                            <Loader fullContainer={true} />
                        )}
                        <h2 className={titleText}>Wygeneruj nową listę zakupów</h2>
                        <Input label="Nazwa listy zakupów" name="name" containerClass={input} />
                        <Button color="yellow" size="small" className={button}>
                            Utwórz listę
                        </Button>
                    </Form>
                )}
            </Formik>

            <SeparatorWithText className={separator}>Lub</SeparatorWithText>

            <Formik
                initialValues={initialValuesSelectList}
                onSubmit={handleSubmitEditList}
                validationSchema={validationSchemaSelectList}
            >
                {(formik) => (
                    <Form className={formSelect}>
                        {((fetchStatus === apiStatusMap.loading &&
                            Object.keys(pages).length === 0) ||
                            formik.status === formsStatusMap.loading) && (
                            <Loader fullContainer={true} />
                        )}
                        <h2 className={titleText}>Dodaj składniki do istniejącej listy</h2>
                        <InputSelectInfinite
                            className={input}
                            name="selectedList"
                            placeholder="Wybierz listę zakupową"
                            onScroll={() => getPage(page + 1)}
                            disabled={page === pagination.pageCount}
                            options={getOptions(pages)}
                            loading={fetchStatus === apiStatusMap.loading}
                        />
                        {fetchStatus === apiStatusMap.fail && (
                            <TextError className={errorMessage}>
                                Wystąpił błąd, nie udało się załadować list zakupowych...
                            </TextError>
                        )}
                        <Button color="yellow" size="small" className={button}>
                            Dodaj składniki
                        </Button>
                    </Form>
                )}
            </Formik>
        </CustomModal>
    );
};

AddShoppingListModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    onNewListSubmit: PropTypes.func,
    onEditListSubmit: PropTypes.func,
};

AddShoppingListModal.defaultProps = {
    onNewListSubmit: () => {},
    onEditListSubmit: () => {},
};

export default AddShoppingListModal;
