import React, { useEffect, useState } from 'react';

import { container, content, title, text, button, emoji } from './cookies.module.scss';

import LinkUnderline from '../atoms/link-underline';
import Button from '../atoms/button';

const Cookies = () => {
    const [visible, setVisible] = useState(false);

    const handleCookiesAccept = () => {
        localStorage.setItem('cookies', 'accepted');
        setVisible(false);
    };

    useEffect(() => {
        const cookiesConsent = localStorage.getItem('cookies');
        if (!cookiesConsent) {
            setVisible(true);
        }
    }, []);

    return visible ? (
        <div className={container}>
            <div className={content}>
                <h5 className={title}>Ta strona używa plików cookie.</h5>
                <p className={text}>
                    Informujemy, że w&nbsp;Serwisie korzystamy z&nbsp;informacji zapisanych
                    za&nbsp;pomocą Plików Cookies na&nbsp;urządzeniach końcowych Użytkowników.
                    Szczegółowe zasady używania Plików Cookies znajdują się w&nbsp;
                    <LinkUnderline
                        Tag="a"
                        href="/docs/regulamin-serwisu-strefa-przemian.pdf"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Regulaminie.
                    </LinkUnderline>
                </p>
            </div>
            <Button className={button} onClick={handleCookiesAccept} size="small">
                <span className={emoji} role="img" aria-label="cookie">
                    🍪
                </span>{' '}
                Rozumiem
            </Button>
        </div>
    ) : null;
};

export default Cookies;
