import React from 'react';

import { search as searchInput } from '../organisms/enhanced-list/enhanced-list.module.scss';

import InputSearch from '../atoms/form-poc/input-search';

export default function InputSearchList({ search, onChange }) {
    return Object.entries(search).map(([key, searchGroup]) => {
        if (searchGroup?.values?.phrase) {
            return (
                <InputSearch
                    key={`search-group-${key}`}
                    placeholder={searchGroup?.label}
                    className={searchInput}
                    onChange={onChange}
                    wait={1000}
                    callbackData={{ key }}
                    initValue={searchGroup.values.phrase.value}
                />
            );
        }
        return null;
    });
}
