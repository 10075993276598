import React from 'react';
import { Link } from 'gatsby';

import { button, container, pencil } from './edit-button.module.scss';
import PencilIcon from '../../../assets/images/svg/icon-pencil.svg';
import { getAbsolutePath } from '../../../routes';
import { TButtonColor } from '../button';

import LinkButton from '../link-button';

interface IRecipeEditButtonProps {
    className?: string;
    recipeId: number;
    textOnly?: boolean;
    color?: TButtonColor;
}

const EditButton: React.FC<IRecipeEditButtonProps> = ({
    className = '',
    recipeId,
    textOnly = false,
    color = 'blank',
}) => {
    const path = `${getAbsolutePath('APP_RECIPE_YOUR_EDIT')}/${recipeId}`;

    return textOnly ? (
        <Link to={path} className={className}>
            Edytuj
        </Link>
    ) : (
        <div className={`${container} ${className}`}>
            <LinkButton to={path} className={button} color={color} circle={true}>
                <PencilIcon className={pencil} />
            </LinkButton>
        </div>
    );
};

export default EditButton;
