import React, { useMemo, useRef, useState } from 'react';
import { navigate } from 'gatsby';

import { container, content, listBox, listTitle, message, list, loader } from './forum.module.scss';
import PinIcon from '../../../assets/images/svg/pin.svg';
import CommentIcon from '../../../assets/images/svg/comment-02.svg';
import { getAbsolutePath } from '../../../routes';
import { useForumThreadCategories } from '../../../hooks/use-forum-thread-categories';
import { useForumThreads } from '../../../hooks/use-forum-threads';

import ThreadCategoryList, { IThreadCategoryListProps } from '../../molecules/thread-category-list';
import ThreadCard from '../../molecules/thread-card';
import IntersectBox from '../../atoms/intersect-box';
import Loader from '../../atoms/loader';
import InputSearch from '../../atoms/form-poc/input-search';

interface IForumProps {
    className?: string;
    isFavorite?: boolean;
    threadCategoryId: string;
}

const Forum: React.FC<IForumProps> = ({ className = '', isFavorite = false, threadCategoryId }) => {
    const [search, setSearch] = useState('');
    const prevSearchRef = useRef(search);
    const threadUrl = useMemo(() => getAbsolutePath('APP_MY_ACCOUNT_FORUM_THREAD_HOME'), []);
    const forumUrl = useMemo(
        () =>
            getAbsolutePath(
                isFavorite ? 'APP_MY_ACCOUNT_FORUM_FAVORITE' : 'APP_MY_ACCOUNT_FORUM_HOME'
            ),
        [isFavorite]
    );
    const categories = useForumThreadCategories(isFavorite);
    const threads = useForumThreads({ categoryId: threadCategoryId, isFavorite, search });

    const handleCategorySelect: IThreadCategoryListProps['onCategorySelect'] = async (category) => {
        await navigate(`${forumUrl}/${category.categoryId}`);
    };

    const handleSearchChange = (newSearch: string) => {
        if (
            (newSearch.length >= 4 || (prevSearchRef.current.length >= 4 && !newSearch)) &&
            newSearch !== prevSearchRef.current
        ) {
            setSearch(newSearch);
            prevSearchRef.current = newSearch;
        }
    };

    return (
        <div className={`${container} ${className}`}>
            <ThreadCategoryList
                categories={categories.list}
                onCategorySelect={handleCategorySelect}
                activeCategoryId={Number(threadCategoryId)}
            />
            {!isFavorite && (
                <InputSearch
                    placeholder={copy.placeholder}
                    onChange={handleSearchChange}
                    wait={1000}
                />
            )}
            <div className={content}>
                {!isFavorite && (
                    <div className={listBox}>
                        <p className={listTitle}>
                            <PinIcon /> {copy.pinned}
                        </p>
                        {threads.pinned.status === 'success' &&
                            threads.pinned.items.length === 0 && (
                                <p className={message}>{copy.noPinned}</p>
                            )}
                        {(threads.pinned.status === 'loading' ||
                            threads.pinned.status === 'idle') &&
                            threads.pinned.items.length === 0 && <Loader className={loader} />}
                        {threads.pinned.status === 'fail' && (
                            <p className={message}>{copy.errorPinned}</p>
                        )}
                        {threads.pinned.items.length > 0 && threads.pinned.status !== 'fail' && (
                            <ul className={list}>
                                {threads.pinned.items.map((thread) => {
                                    return (
                                        <li key={`thread-${thread.threadId}`}>
                                            <ThreadCard
                                                thread={thread}
                                                url={`${threadUrl}/${thread.threadId}`}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                )}
                <div className={listBox}>
                    {!isFavorite && (
                        <p className={listTitle}>
                            <CommentIcon /> {copy.all}
                        </p>
                    )}
                    {threads.all.items.length > 0 && (
                        <ul className={list}>
                            {threads.all.items.map((thread) => {
                                return (
                                    <li key={`thread-${thread.threadId}`}>
                                        <ThreadCard
                                            thread={thread}
                                            url={`${threadUrl}/${thread.threadId}`}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <IntersectBox onIntersecting={threads.all.getNextPage}>
                        {(threads.all.status === 'idle' || threads.all.status === 'loading') && (
                            <Loader className={loader} />
                        )}
                        {threads.all.status === 'fail' && (
                            <p className={message}>{copy.errorAll}</p>
                        )}
                        {threads.all.status === 'success' && threads.all.items.length === 0 && (
                            <p className={message}>{copy.noAll}</p>
                        )}
                    </IntersectBox>
                </div>
            </div>
        </div>
    );
};

const copy = {
    all: 'Wszystkie posty',
    errorAll: 'Wystąpił błąd przy próbie pobrania postów, spróbuj ponownie później...',
    errorPinned:
        'Wystąpił błąd przy próbie pobrania przypiętych postów, spróbuj ponownie później...',
    noAll: 'Brak postów.',
    noPinned: 'Brak przypiętych postów.',
    pinned: 'Przypięte posty',
    placeholder: 'Szukaj w temacie',
};

export default Forum;
