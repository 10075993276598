import { types as apiTypes } from '../api/api.actions';

export const SUBSCRIPTIONS = '[SUBSCRIPTIONS]';

export const types = {
    GET_SUBSCRIPTIONS: `${SUBSCRIPTIONS} get`,
    RELOAD_SUBSCRIPTIONS: `${SUBSCRIPTIONS} reload`,
    CLEAR_SUBSCRIPTIONS: `${SUBSCRIPTIONS} clear`,

    FETCH_SUBSCRIPTIONS_REQUEST: `${SUBSCRIPTIONS} ${apiTypes.API_REQUEST}`,
    FETCH_SUBSCRIPTIONS_SUCCESS: `${SUBSCRIPTIONS} ${apiTypes.API_SUCCESS}`,
    FETCH_SUBSCRIPTIONS_FAIL: `${SUBSCRIPTIONS} ${apiTypes.API_REQUEST}`,

    SET_SUBSCRIPTIONS: `${SUBSCRIPTIONS} set`,
    RESTORE_INITIAL_SUBSCRIPTIONS_STATE: `${SUBSCRIPTIONS} restore initial`,
};

export const getSubscriptionsAction = (page, perPage) => ({
    type: types.GET_SUBSCRIPTIONS,
    payload: { page, perPage },
});

export const reloadSubscriptionsAction = () => ({
    type: types.RELOAD_SUBSCRIPTIONS,
});

export const setSubscriptionsAction = (data) => ({
    type: types.SET_SUBSCRIPTIONS,
    payload: data,
});

export const clearSubscriptionsAction = () => ({
    type: types.CLEAR_SUBSCRIPTIONS,
});

export const restoreInitialSubscriptionsStateAction = () => ({
    type: types.RESTORE_INITIAL_SUBSCRIPTIONS_STATE,
});
