import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    wrapper,
    absoluteWrapper,
    movieWrapper,
    pointsButtonWrapper,
    movieContainer,
    additionalMoviesHolder,
    breakThumbnail,
    breakTimeBox,
    breakTimeContent,
    visible,
} from './movie-group-player.module.scss';
import { config } from '../../../config';
import { saveMovieLog } from '../../../communication/audit';
import { humanizedSeconds } from '../../../communication/utils';
import {
    selectNextPlayerMovie,
    selectPlayerMovie,
} from '../../../redux/personal-training/personal-training.selectors';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import {
    PERSONAL_TRAINING_DAY,
    setCurrentMovie,
    setCurrentTraining,
} from '../../../redux/personal-training/personal-training.actions';

import VideoPlayer from '../../molecules/video-player';
import Switcher from '../../atoms/movie-group-player/switcher';
import Loader from '../../atoms/loader';
import EmptyScreen from '../personal-training/current/additional/empty-screen';
import MovieGroupPlayerPointsButton from '../../molecules/movie-group-player-points-button';

const { audit, apiStatusMap, personalTraining } = config;

export const MovieGroupPlayer = () => {
    const dispatch = useDispatch();
    const {
        trainingId,
        movie,
        nextMovie,
        dayLoading,
        movieIndex,
        listType,
        isPlaying,
    } = useSelector((state) => {
        const training = state.personalTrainings.current;
        return {
            trainingId: training.id,
            movie: selectPlayerMovie(state),
            nextMovie: selectNextPlayerMovie(state),
            dayLoading: selectLoaderByEntity(state, PERSONAL_TRAINING_DAY),
            movieIndex: training.playlistMovieIndex,
            listType: training.listType,
            isPlaying: training.isPlaying,
        };
    });

    const [remainingReps, setRemainingReps] = useState(movie?.repetitions || 1);
    const [breakTime, setBreakTime] = useState(movie?.breakTime || 0);
    const [listTypeContext, toggleListType] = useState(personalTraining.typesMap.exercises.key);

    const onTimerEnd = () => setBreakTime(0);

    const playNext = () => setRemainingReps(0);

    const setAutoplay = () => {
        dispatch(setCurrentTraining({ isPlaying: true }));
    };

    useEffect(() => {
        if (movie) {
            setRemainingReps(movie.repetitions ? movie.repetitions : 1);
        } else {
            setRemainingReps(0);
        }
        setBreakTime(movie?.breakTime || 0);
    }, [movie]);

    useEffect(() => {
        if (remainingReps === 0 && breakTime === 0) {
            const playerObj = {
                listType: listType,
                playlistMovieIndex: movieIndex + 1,
            };
            dispatch(setCurrentMovie(playerObj));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, breakTime, remainingReps]);

    const handleWatched = async () => {
        const movieData = {
            module: audit.module.personalTraining,
            group:
                listType === personalTraining.typesMap.exercises.key
                    ? audit.group.exercise
                    : audit.group.additional,
            action: audit.action.watch,
            params: movie.id,
        };

        await saveMovieLog(movieData);
    };

    return (
        <div>
            <div className={wrapper}>
                <section className={movieContainer}>
                    {dayLoading === apiStatusMap.loading && <Loader fullContainer />}

                    {remainingReps > 0 && (
                        <VideoPlayer
                            videoUrl={movie?.videoUri}
                            followProgress={true}
                            onWatched={handleWatched}
                            autoplay={isPlaying}
                            playNext={playNext}
                            onStart={setAutoplay}
                            wrapperClassName={movieWrapper}
                            loop={true}
                            remainingReps={remainingReps}
                            videoDuration={movie?.videoDuration}
                        />
                    )}
                    {remainingReps === 0 && breakTime > 0 && (
                        <Timer breakTime={breakTime} onComplete={onTimerEnd} />
                    )}

                    {nextMovie && nextMovie.coverUri && (
                        <img
                            src={nextMovie.coverUri}
                            className={`${breakThumbnail} ${
                                remainingReps === 0 && breakTime > 0 ? visible : ''
                            }`}
                            alt=""
                        />
                    )}

                    {remainingReps === 0 && breakTime === 0 && (
                        <EmptyScreen toggleListType={toggleListType} />
                    )}
                </section>

                <div className={absoluteWrapper}>
                    <section className={additionalMoviesHolder}>
                        <Switcher
                            listTypeContext={listTypeContext}
                            toggleListType={toggleListType}
                        />
                    </section>
                </div>
                <MovieGroupPlayerPointsButton
                    listTypeContext={listTypeContext}
                    movieId={movie?.id}
                    trainingId={trainingId}
                    className={pointsButtonWrapper}
                />
            </div>
        </div>
    );
};

export default MovieGroupPlayer;

const Timer = ({ breakTime = 0, onComplete }) => {
    const [remainingTime, setRemainingTime] = useState(breakTime);

    useEffect(() => {
        let timeOut;

        if (remainingTime > 0) {
            timeOut = setTimeout(() => {
                setRemainingTime(remainingTime - 1);
            }, 1000);
        }

        if (remainingTime === 0) {
            onComplete();
        }

        return () => {
            clearTimeout(timeOut);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingTime]);

    return (
        <div className={breakTimeBox}>
            <div className={breakTimeContent}>
                PRZERWA
                <br />
                {humanizedSeconds(remainingTime)}
            </div>
        </div>
    );
};
