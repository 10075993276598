import React from 'react';

import BodyPartIcon from '../../../assets/images/svg/body-part.svg';
import DifficultyIcon from '../../../assets/images/svg/difficulty.svg';
import TimeIcon from '../../../assets/images/svg/time.svg';

import { humanizedSeconds } from '../../../communication/utils';
import Metadata from '../../atoms/metadata';

const ExerciseMetadata = ({ color = 'white', exercise = {}, className = '', short = true }) => {
    const getMetaItems = () => {
        let metaItems = [];

        if (exercise.bodyParts && exercise.bodyParts.length > 0) {
            metaItems.push({
                Icon: BodyPartIcon,
                data: exercise.bodyParts.map((type) => type.name).join(', '),
            });
        }
        if (exercise.difficulty) {
            metaItems.push({
                Icon: DifficultyIcon,
                data: exercise.difficulty.name,
            });
        }
        if (exercise.videoDuration) {
            metaItems.push({
                Icon: TimeIcon,
                data: humanizedSeconds(exercise.videoDuration),
            });
        }
        if (!short && exercise.equipment && exercise.equipment.length > 0) {
            metaItems.push({
                Icon: BodyPartIcon,
                data: exercise.equipment.map((type) => type.name).join(', '),
            });
        }
        if (!short && exercise.intensity && exercise.intensity.length > 0) {
            metaItems.push({
                Icon: BodyPartIcon,
                data: exercise.intensity.map((type) => type.name).join(', '),
            });
        }
        if (!short && exercise.group && exercise.group.length > 0) {
            metaItems.push({
                Icon: BodyPartIcon,
                data: exercise.group.map((type) => type.name).join(', '),
            });
        }
        return metaItems;
    };

    return (
        <div className={className}>
            <Metadata color={color} metaItems={getMetaItems()} />
        </div>
    );
};

export default ExerciseMetadata;
