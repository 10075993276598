// extracted by mini-css-extract-plugin
export var container = "recipe-content-module--container--AH0XN";
export var content = "recipe-content-module--content--JR+hV";
export var fullRatioClass = "recipe-content-module--full-ratio-class--6n35i";
export var header = "recipe-content-module--header--3Jebv";
export var img = "recipe-content-module--img--pBqcN";
export var imgBox = "recipe-content-module--img-box--xSFF5";
export var ingredientList = "recipe-content-module--ingredient-list--VnR2C";
export var simple = "recipe-content-module--simple--i0iNI";
export var simpleRatioClass = "recipe-content-module--simple-ratio-class--Iormr";
export var stepList = "recipe-content-module--step-list--vGHR3";
export var video = "recipe-content-module--video--durqR";