import React from 'react';

import { config } from '../../config';
import { GAMIFICATION_TARIFF } from '../../redux/gamification/gamification.reducer';
import { TariffObject } from '../../models/gamification.model';

import GamificationListView from './gamification-list-view';

export default function GamificationTariff() {
    const itemTransformFunction = (tariffObject: TariffObject) => ({
        id: tariffObject.reasonId,
        label: tariffObject.label,
        points: tariffObject.amount,
    });

    return (
        <GamificationListView
            title="Zobacz za co możesz zdobyć punkty."
            subEntity={GAMIFICATION_TARIFF}
            url={config.endpoints.gamification.tariff}
            itemTransformFunction={itemTransformFunction}
        />
    );
}
