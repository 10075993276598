export const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
            resolve({
                name: file.name,
                content: reader.result,
                mimeType: file.type,
            });
        reader.onerror = () =>
            reject(`Wystąpił błąd podczas ładowania pliku ${file.name}`);
    });
};
