import { commentSingleRecipe } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';
import { getSingleRecipeSuccessAction } from './get-single-recipe';
import { config } from '../../../../config';
import { formatFormikErrors } from '../../../../communication/utils';
import { getCurrentProfile } from '../../../profile/profile.selectors';

export const commentSingleRecipeAction = (id, profileId, data, formikBag) => async (
    dispatch,
    getState
) => {
    dispatch(commentSingleRecipeStartAction());
    formikBag.setStatus(config.formsStatusMap.loading);
    try {
        const res = await commentSingleRecipe(id, profileId, data);
        if (res.status === 201) {
            const recipe = getState().recipes.single.get.data;
            const newComment = {
                ...res.data,
                author: {
                    ...getCurrentProfile(getState()),
                },
            };
            const newRecipe = {
                ...recipe,
                comments: [...recipe.comments, newComment],
            };
            dispatch(getSingleRecipeSuccessAction(newRecipe));
        }
        dispatch(commentSingleRecipeSuccessAction());
        formikBag.setStatus(config.formsStatusMap.success);
    } catch (error) {
        dispatch(commentSingleRecipeErrorAction());
        formikBag.setStatus(config.formsStatusMap.fail);
        formikBag.setErrors(formatFormikErrors(error));
        return config.formsStatusMap.fail;
    }
};

export const commentSingleRecipeClearSuccessAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.comment,
        entitiesMap.recipes.single.allowedActions.clearSuccess
    ),
});

export const commentSingleRecipeClearErrorAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.comment,
        entitiesMap.recipes.single.allowedActions.clearErrors
    ),
});

const commentSingleRecipeStartAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.comment,
            entitiesMap.recipes.single.allowedActions.start
        ),
        payload: {},
    };
};

const commentSingleRecipeSuccessAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.comment,
            entitiesMap.recipes.single.allowedActions.success
        ),
        payload: {},
    };
};

const commentSingleRecipeErrorAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.comment,
            entitiesMap.recipes.single.allowedActions.error
        ),
        payload: {},
    };
};
