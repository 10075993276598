import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { config } from '../../../../config';
import { ORDER_HISTORY } from '../../../../redux/shop/order-history/order-history.actions';
import { getOrdersList } from '../../../../redux/shop/order-history/order-history.actions';
import { selectLoaderByEntity } from '../../../../redux/ui/ui.selectors';

import OrderListItem from '../../../molecules/order-history/order-list-item';
import Loader from '../../../atoms/loader';

const statusMap = config.apiStatusMap;

const ShoppingHistory = () => {
    const ordersList = useSelector((state) => Object.entries(state.shop.orderHistory));
    const apiStatus = useSelector((state) => selectLoaderByEntity(state, ORDER_HISTORY));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrdersList());
    }, [dispatch]);

    switch (true) {
        case apiStatus === statusMap.loading:
            return <Loader />;
        case apiStatus === statusMap.fail:
            return <div>Niestety! Nie udało się pobrać listy zamowień.</div>;
        case apiStatus === statusMap.success && ordersList.length > 0:
            return (
                <div>
                    {ordersList.map(([key, order]) => {
                        return <OrderListItem key={key} order={order} />;
                    })}
                </div>
            );
        case apiStatus === statusMap.success && ordersList.length === 0:
        default:
            return <div>Lista zamówień jest pusta.</div>;
    }
};

export default ShoppingHistory;
