import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { getImage, withArtDirection } from 'gatsby-plugin-image';

import { hero, header, list, grid, addButton } from './recipe-list.module.scss';
import PlusIcon from '../assets/images/svg/plus.svg';
import { config } from '../config';
import { getAbsolutePath } from '../routes';
import { getCurrentProfileId } from '../redux/profile/profile.selectors';
import { RECIPES, PUBLIC, YOUR, FAVORITE } from '../redux/recipes/recipes.reducer';

import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import RecipeCard from '../components/molecules/recipe/recipe-card';
import LinkButton from '../components/atoms/link-button';
import EnhancedList from '../components/organisms/enhanced-list/enhanced-list';

const { recipeTypesMap, endpoints } = config;

const url = endpoints.recipes.publicEndpoint;

const RecipeList = ({ type, routeData, description }) => {
    const {
        publicRecipesImg,
        publicRecipesImgMobile,
        yourRecipesImg,
        yourRecipesImgMobile,
        favoriteRecipesImg,
        favoriteRecipesImgMobile,
    } = useStaticQuery(query);

    const { profileId } = useSelector((state) => ({
        profileId: getCurrentProfileId(state),
    }));

    const bannerImages = {
        [config.recipeTypesMap.public]: withArtDirection(getImage(publicRecipesImg), [
            {
                image: getImage(publicRecipesImgMobile),
                media: `(max-width: 420px)`,
            },
        ]),
        [config.recipeTypesMap.your]: withArtDirection(getImage(yourRecipesImg), [
            {
                image: getImage(yourRecipesImgMobile),
                media: `(max-width: 420px)`,
            },
        ]),
        [config.recipeTypesMap.favorite]: withArtDirection(getImage(favoriteRecipesImg), [
            {
                image: getImage(favoriteRecipesImgMobile),
                media: `(max-width: 420px)`,
            },
        ]),
    };
    const bannerData = {
        title: routeData.label,
        subtitle: description,
        fluidImg: bannerImages[type],
    };

    const getSubEntityBasedOnListType = () => {
        switch (type) {
            case recipeTypesMap.your:
                return YOUR;
            case recipeTypesMap.favorite:
                return FAVORITE;
            default:
                return PUBLIC;
        }
    };

    const getEndpointUrlBasedOnListType = () => {
        switch (type) {
            case recipeTypesMap.your:
                return `${url}/profile/${profileId}`;
            case recipeTypesMap.favorite:
                return `${url}/profile/${profileId}/favorite`;
            default:
                return url;
        }
    };

    const getPathBasedOnListType = () => {
        switch (type) {
            case config.recipeTypesMap.your:
                return getAbsolutePath('APP_RECIPE_YOUR');
            default:
                return getAbsolutePath('APP_RECIPE');
        }
    };

    return (
        <Main transparentHeader={true} className={grid}>
            <MainBanner bannerData={bannerData} className={hero} />
            <div className={header}>
                {type === config.recipeTypesMap.your && (
                    <LinkButton
                        to={getAbsolutePath('APP_RECIPE_YOUR_ADD')}
                        className={addButton}
                        icon={PlusIcon}
                    >
                        Dodaj przepis
                    </LinkButton>
                )}
            </div>

            <EnhancedList
                className={list}
                entity={RECIPES}
                subEntity={getSubEntityBasedOnListType()}
                targetLocation={getPathBasedOnListType()}
                itemComponent={RecipeCard}
                url={getEndpointUrlBasedOnListType()}
                itemProps={{ type, subEntity: getSubEntityBasedOnListType() }}
            />
        </Main>
    );
};

const query = graphql`
    {
        publicRecipesImg: file(relativePath: { eq: "hero-public-recipes.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        publicRecipesImgMobile: file(relativePath: { eq: "hero-public-recipes-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        yourRecipesImg: file(relativePath: { eq: "hero-your-recipes.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        yourRecipesImgMobile: file(relativePath: { eq: "hero-your-recipes-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        favoriteRecipesImg: file(relativePath: { eq: "hero-favorite-recipes.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        favoriteRecipesImgMobile: file(relativePath: { eq: "hero-favorite-recipes-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(width: 500, quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
    }
`;

export default RecipeList;
