import React from 'react';
import moment from 'moment';

import { config } from '../../config';
import { GAMIFICATION_HISTORY } from '../../redux/gamification/gamification.reducer';
import { TransactionHistoryObject } from '../../models/gamification.model';

import GamificationListView from './gamification-list-view';

export default function GamificationHistory() {
    const itemTransformFunction = (historyItem: TransactionHistoryObject) => ({
        id: historyItem.transactionId,
        label: `${historyItem.label} • ${moment.unix(historyItem.createdAt).format('DD.MM.YYYY')}`,
        points: historyItem.change,
    });

    return (
        <GamificationListView
            title="Sprawdź historię swoich ketonów."
            subEntity={GAMIFICATION_HISTORY}
            url={config.endpoints.gamification.transactions}
            itemTransformFunction={itemTransformFunction}
        />
    );
}
