import React from 'react';

import { title, subtitle, separator, row } from './form.module.scss';

import Input from '../../atoms/form-poc/input';
import Separator from '../../atoms/separator';

const FormAdditionalInfo = () => {
    return (
        <>
            <h3 className={title}>Informacje dodatkowe</h3>

            <h4 className={subtitle}>
                Jeśli masz dla nas dodatkowe informacje, o które nie pytaliśmy,
                <br />a mogą pomóc w jeszcze dokładniejszym ułożeniu diety lub planu treningowego
                opisz je poniżej
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="additionalInformation" />
            </div>
        </>
    );
};

export default FormAdditionalInfo;
