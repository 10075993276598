import React from 'react';
import { Link } from 'gatsby';

import { link, linksWrapper } from './gamification-introduction.module.scss';

import PointsTitle from '../../components/atoms/points-title';
import PointsBalance from '../../components/molecules/points-balance';

export default function GamificationIntroduction() {
    return (
        <>
            <PointsTitle>
                Ketony to odpowiednik wirtualnej waluty stosowanej w naszym serwisie. 100 Ketonów to
                1 zł.
            </PointsTitle>
            <PointsBalance />
            <div className={linksWrapper}>
                <p>
                    Przejdź do{' '}
                    <Link to="/sklep" className={link}>
                        sklepu
                    </Link>{' '}
                    i zobacz na co możesz je wymienić.
                </p>
                {/*TODO: Uncomment paragraphs when specific pages will be ready*/}
                <p>
                    Przejdź do zakładki{' '}
                    <Link to="/app/moje-konto/twoje-ketony/taryfikator" className={link}>
                        Taryfikator
                    </Link>
                    , aby dowiedzieć się za co możesz zdobywać Ketony.
                </p>
                <p>
                    Sprawdź również, na co możesz je wymienić w zakładce{' '}
                    <Link to="/app/moje-konto/twoje-ketony/strefa-partnerow" className={link}>
                        Partnerzy.
                    </Link>
                </p>
                <p>
                    Cały przegląd zdobytych punktów i wymian możesz zobaczyć w zakładce{' '}
                    <Link to="/app/moje-konto/twoje-ketony/historia" className={link}>
                        Historia.
                    </Link>
                </p>
            </div>
        </>
    );
}
