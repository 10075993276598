import React, { useRef, Suspense, MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';

import {
    title as titleClass,
    fullWidth as fullWidthClass,
    content as contentClass,
    icons,
    modal,
    button,
    container,
    error,
} from './modal.module.scss';
import MODALS from '../../organisms/custom-modals/modals';
import { removeModalAction } from '../../../redux/actions/actions-modals';

import ErrorEmotics from '../../../assets/images/svg/error-emotics.svg';
import SuccessEmotics from '../../../assets/images/svg/success-emotics.svg';

import Title from '../../atoms/title';
import Button from '../../atoms/button';
import Emotic from '../../atoms/emotic';
import Loader from '../../atoms/loader';

export type TGlobalModalType = 'info' | 'error';

export interface IGlobalModalProps {
    id: number;
    data: {
        type?: TGlobalModalType;
        title?: React.ReactNode;
        content?: React.ReactNode;
        buttonText?: React.ReactNode;
        emotics?: React.SVGFactory;
        forceAction?: boolean;
        fullWidth?: boolean;
        renderComponent?: (id: number) => React.ReactNode;
        onClose?: () => void;
        modalKey?: keyof typeof MODALS;
        modalProps?: Record<string, any>;
        runOnCloseOnlyOnButtonClick?: boolean;
        noEmotics?: boolean;
    };
}

const GlobalModal: React.FC<IGlobalModalProps> = ({ id, data }) => {
    const {
        type = 'info',
        title = '',
        content = '',
        buttonText = 'Zamknij',
        emotics,
        renderComponent,
        forceAction = false,
        fullWidth = false,
        onClose = () => {},
        modalKey,
        modalProps,
        runOnCloseOnlyOnButtonClick,
        noEmotics,
    } = data || {};

    const dispatch = useDispatch();
    const modalRef = useRef(null);

    const handleModalClose: MouseEventHandler = (event) => {
        if (event.target === modalRef.current && !forceAction) {
            if (!runOnCloseOnlyOnButtonClick) {
                onClose();
            }
            dispatch(removeModalAction(id));
        }
    };

    const handleButtonClose = () => {
        onClose();
        dispatch(removeModalAction(id));
    };

    const modalEmotics = emotics || defaultEmotics[type];
    const Modal = modalKey ? MODALS[modalKey] : null;

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            className={`${modal} ${fullWidth ? fullWidthClass : ''}`}
            ref={modalRef}
            onClick={handleModalClose}
        >
            {Modal ? (
                <Suspense fallback={<Loader />}>
                    <Modal modalId={id} {...modalProps} />
                </Suspense>
            ) : renderComponent ? (
                renderComponent(id) // example below propTypes
            ) : (
                <div className={`${container} ${type === 'error' ? error : ''}`}>
                    {!noEmotics && <Emotic emotic={modalEmotics} className={icons} />}
                    {title && (
                        <Title Tag="h3" className={titleClass}>
                            {title}
                        </Title>
                    )}
                    {content && <p className={contentClass}>{content}</p>}
                    <Button
                        className={button}
                        {...(type !== 'error' ? { color: 'yellow' } : {})}
                        onClick={handleButtonClose}
                    >
                        {buttonText}
                    </Button>
                </div>
            )}
        </div>
    );
};

const defaultEmotics: Record<TGlobalModalType, React.SVGFactory> = {
    error: ErrorEmotics,
    info: SuccessEmotics,
};

/** Example of usage renderComponent key - passing component to modal:

 const handleTrashClick = () => {
    dispatch(addModalAction({
        renderComponent: (modalId) => (
            <Confirmation
                modalId={modalId}
                title="Usunąć przepis?"
                content="Przepis zostanie nieodwracalnie usunięty z bazy"
                onConfirm={() => dispatch(deleteSingleRecipeAction(id))}
            />
        )
    }));
};

 */

export default GlobalModal;
