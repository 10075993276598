import { combineReducers } from 'redux';

import messages from './messages-reducer';
import attachments from './attachemts-reducer';
import sendMessages from './send-message.reducer';
import additionalAttachmentsToSend from './additional-attachments-to-send.reducer';
import cleanEnhancer from '../../reducerEnhancers/cleanEnhancer';
import { types } from '../../user/user.actions';

const messengerReducer = combineReducers({
    messages,
    attachments,
    sendMessages,
    additionalAttachmentsToSend,
});
export default cleanEnhancer(messengerReducer, types.CLEAR_USER_DATA);
