import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import {
    container,
    contactInput,
    streetInput,
    houseInput,
    apartmentInput,
    cityInput,
    zipInput,
    phoneInput,
} from './address-delivery-fields.module.scss';
import { config } from '../../config';

import Input from '../atoms/form-poc/input';

const { required } = config.messages.form;

export function getAddressDeliveryInitialValues(isShort = false, profile = undefined) {
    return {
        contactPerson: (profile && `${profile.firstName} ${profile.lastName}`) || '',
        ...(isShort
            ? {}
            : {
                  street: '',
                  houseNumber: '',
                  apartmentNumber: '',
                  city: '',
                  zipCode: '',
                  contactNumber: '',
              }),
    };
}

export function getAddressDeliveryValidationSchema(isShort = false) {
    return {
        contactPerson: Yup.string().required(required),
        ...(isShort
            ? {}
            : {
                  street: Yup.string().required(required),
                  houseNumber: Yup.string().required(required),
                  apartmentNumber: Yup.string().nullable(true),
                  city: Yup.string().required(required),
                  zipCode: Yup.string().required(required),
                  contactNumber: Yup.string().required(required),
              }),
    };
}

const AddressDeliveryFields = ({ className, prefixes, isShort = false }) => {
    return (
        <div className={`${container} ${className}`}>
            <Input
                containerClass={contactInput}
                name={[...prefixes, 'contactPerson'].join('.')}
                placeholder="Imię i nazwisko"
            />
            {!isShort && (
                <>
                    <Input
                        containerClass={streetInput}
                        name={[...prefixes, 'street'].join('.')}
                        placeholder="Ulica"
                    />
                    <Input
                        containerClass={houseInput}
                        name={[...prefixes, 'houseNumber'].join('.')}
                        placeholder="Numer domu"
                    />
                    <Input
                        containerClass={apartmentInput}
                        name={[...prefixes, 'apartmentNumber'].join('.')}
                        placeholder="Numer mieszkania"
                    />
                    <Input
                        containerClass={cityInput}
                        name={[...prefixes, 'city'].join('.')}
                        placeholder="Miasto"
                    />
                    <Input
                        containerClass={zipInput}
                        name={[...prefixes, 'zipCode'].join('.')}
                        placeholder="Kod pocztowy"
                    />
                    <Input
                        containerClass={phoneInput}
                        name={[...prefixes, 'contactNumber'].join('.')}
                        placeholder="Numer telefonu"
                    />
                </>
            )}
        </div>
    );
};

AddressDeliveryFields.propTypes = {
    className: PropTypes.string,
    prefixes: PropTypes.arrayOf(PropTypes.string),
    hasLabel: PropTypes.bool,
};

AddressDeliveryFields.defaultProps = {
    className: '',
    prefixes: [],
    hasLabel: true,
};

export default AddressDeliveryFields;
