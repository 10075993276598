import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    container,
    header,
    img,
    content,
    imgBox,
    simple,
    ingredientList,
    video,
    stepList,
    fullRatioClass,
    simpleRatioClass,
} from './recipe-content.module.scss';

import RecipeHeader from '../molecules/recipe/recipe-header';
import RecipeSimpleHeader from '../molecules/recipe/recipe-simple-header';
import RecipeIngredientList from '../molecules/recipe/recipe-ingredient-list';
import RecipePreparationSteps from '../molecules/recipe/recipe-preparation-steps';
import RecipeVideoPreview from '../molecules/recipe/recipe-video-preview';
import useRecipeAbsolutePath from '../../hooks/use-recipe-absolute-path';
import RatioImage from '../atoms/ratio-image';

const RecipeContent = ({
    recipe,
    portions,
    type,
    className,
    simpleView,
    portionsUpdate,
    onIngredientChange,
}) => {
    const [localRecipe, setLocalRecipe] = useState(recipe);
    const [multiplier, setMultiplier] = useState(portions ? portions / recipe.portions : 1);
    const [currentPortions, setCurrentPortions] = useState(portions ? portions : recipe.portions);
    const recipePath = useRecipeAbsolutePath(recipe);

    const ImgBoxTag = simpleView ? 'a' : 'div';
    const imgBoxProps = simpleView
        ? {
              rel: 'noopener noreferrer',
              target: '_blank',
              href: recipePath,
          }
        : {};

    const handlePortionsChange = (newPortions) => {
        setMultiplier(newPortions / localRecipe.portions);
        setCurrentPortions(newPortions);

        if (simpleView) {
            portionsUpdate(newPortions);
        }
    };

    const handleIngredientChange = (ingredientId, replacementId) => {
        const oldIngredient = {
            ...localRecipe.ingredients.find((ingredient) => ingredientId === ingredient.id),
        };
        const newIngredient = {
            ...oldIngredient.replacements.find((ingredient) => replacementId === ingredient.id),
        };

        const oldReplacements = oldIngredient.replacements.filter(
            (ingredient) => replacementId !== ingredient.id
        );
        delete oldIngredient.replacements;

        newIngredient.replacements = [...oldReplacements, oldIngredient];

        if (typeof onIngredientChange === 'function') {
            const mainIngredient = newIngredient.isMain
                ? newIngredient
                : newIngredient.replacements.find((ingredient) => ingredient.isMain);
            if (!mainIngredient) return;
            const changeData = {
                ingredientId: mainIngredient.ingredientId,
                replaceIngredientId: newIngredient.ingredientId,
                unitId: newIngredient.unitId,
                unitValue: newIngredient.quantity,
            };
            onIngredientChange(changeData);
        }

        setLocalRecipe({
            ...localRecipe,
            ingredients: [
                ...localRecipe.ingredients.map((ingredient) => {
                    if (ingredient.id === ingredientId) {
                        return newIngredient;
                    }
                    return ingredient;
                }),
            ],
        });
    };

    return (
        <div className={`${container} ${className} ${simpleView ? simple : ''}`}>
            <ImgBoxTag className={imgBox} {...imgBoxProps}>
                <RatioImage
                    className={img}
                    src={recipe.coverUri}
                    ratioClass={simpleView ? simpleRatioClass : fullRatioClass}
                />
            </ImgBoxTag>
            <div className={content}>
                {simpleView ? (
                    <RecipeSimpleHeader
                        recipe={localRecipe}
                        onPortionsChange={handlePortionsChange}
                        className={header}
                        portions={currentPortions}
                    />
                ) : (
                    <RecipeHeader
                        className={header}
                        recipe={localRecipe}
                        type={type}
                        portions={currentPortions}
                        onPortionsChange={handlePortionsChange}
                        multiplier={multiplier}
                    />
                )}

                {localRecipe.ingredients && localRecipe.ingredients.length > 0 && (
                    <RecipeIngredientList
                        recipe={localRecipe}
                        className={ingredientList}
                        onIngredientChange={handleIngredientChange}
                        simpleView={simpleView}
                        multiplier={multiplier}
                    />
                )}

                {localRecipe.preparationSteps && localRecipe.preparationSteps.length > 0 && (
                    <RecipePreparationSteps
                        steps={localRecipe.preparationSteps}
                        className={stepList}
                        simpleView={simpleView}
                    />
                )}

                {localRecipe.videoUri && !simpleView && (
                    <RecipeVideoPreview recipe={localRecipe} className={video} />
                )}
            </div>
        </div>
    );
};

RecipeContent.propTypes = {
    recipe: PropTypes.object.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
    simpleView: PropTypes.bool,
    portions: PropTypes.number,
    portionsUpdate: PropTypes.func,
};

RecipeContent.defaultProps = {
    type: null,
    className: '',
    simpleView: false,
    portions: null,
    portionsUpdate: () => {},
};

export default RecipeContent;
