import React from 'react';

import {
    setNewsletterToSubscribeClearErrors,
    setNewsletterToSubscribeClearSuccess,
} from '../../../../redux/newsletter/user-zone/actions/user-zone-newsletter-subscribe';
import BicepsIcon from '../../../../assets/images/svg/biceps.svg';
import {
    setNewsletterToUnSubscribeClearErrors,
    setNewsletterToUnSubscribeClearSuccess,
} from '../../../../redux/newsletter/user-zone/actions/user-zone-newsletter-unsubscribe';

import StatusBar from '../../../atoms/status-bar';

const AdditionalInformationWrapper = ({
    isUselessRequest,
    setIsUselessRequest,
    isSubscribeSuccess,
    isSubscribeError,
    isUnsubscribeSuccess,
    isUnSubscribeError,
    userHasNewsletter,
    dispatch,
}) => {
    return (
        <>
            {/** Success Status **/}

            {isSubscribeSuccess ? (
                <StatusBar
                    onSubmit={() => dispatch(setNewsletterToSubscribeClearSuccess())}
                    type={'success'}
                >
                    Brawo, zostałeś zapisany do Newslettera!
                    <BicepsIcon />
                </StatusBar>
            ) : null}

            {isUnsubscribeSuccess ? (
                <StatusBar
                    onSubmit={() => dispatch(setNewsletterToUnSubscribeClearSuccess())}
                    type={'success'}
                >
                    zostałeś wypisany z Newslettera.
                </StatusBar>
            ) : null}

            {/** Waring Status **/}

            {isUselessRequest ? (
                <StatusBar onSubmit={() => setIsUselessRequest(null)} type={'warning'}>
                    Aktualnie jesteś już
                    {userHasNewsletter ? (
                        <>
                            {' '}
                            zapisany!
                            <BicepsIcon />
                        </>
                    ) : (
                        <> wypisany.</>
                    )}
                </StatusBar>
            ) : null}

            {/** Error Status **/}

            {isSubscribeError ? (
                <StatusBar
                    onSubmit={() => dispatch(setNewsletterToSubscribeClearErrors())}
                    type={'error'}
                >
                    Niestety nie udało się zapisać do Newslettera.
                </StatusBar>
            ) : null}

            {isUnSubscribeError ? (
                <StatusBar
                    onSubmit={() => dispatch(setNewsletterToUnSubscribeClearErrors())}
                    type={'error'}
                >
                    Niestety nie udało się wypisać z Newslettera.
                </StatusBar>
            ) : null}
        </>
    );
};

export default AdditionalInformationWrapper;
