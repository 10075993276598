import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

import {
    input,
    labelText,
    hint as hintClass,
    dropdown,
    hintIcon,
    labelBox,
} from './input.module.scss';

import TextError from './text-error';
import Dropdown from '../../molecules/dropdown';

const Input = ({
    label,
    name,
    hint,
    containerClass = '',
    as,
    id,
    rows = 2,
    inputClassname = '',
    identifier = '',
    showError = true,
    ...rest
}) => {
    const [isHintHovered, setIsHintHovered] = useState(false);

    return (
        <div className={containerClass}>
            <div className={labelBox}>
                {label && (
                    <label htmlFor={`${name}${identifier}`} className={labelText}>
                        {label}
                    </label>
                )}
                {hint && (
                    <button
                        className={hintClass}
                        onMouseEnter={() => setIsHintHovered(true)}
                        onMouseLeave={() => setIsHintHovered(false)}
                        type="button"
                        tabIndex="-1"
                    >
                        <span className={hintIcon}>i</span>
                        <Dropdown open={isHintHovered} className={dropdown}>
                            {hint}
                        </Dropdown>
                    </button>
                )}
            </div>
            <Field
                as={as === 'textarea' ? TextareaAutosize : as}
                {...(as === 'textarea' ? { minRows: rows } : {})}
                id={id ? id : `${name}${identifier}`}
                name={name}
                className={`${input} ${inputClassname}`}
                {...rest}
            />
            {showError ? <ErrorMessage name={name} component={TextError} /> : null}
        </div>
    );
};

export default Input;
