import React from 'react';

import { bigTitle } from './big-title.module.scss';

export interface IBigTitleProps {
    Tag?: React.ElementType;
    className?: string;
    children?: React.ReactNode;
}

const BigTitle = ({ Tag = 'h2', className = '', children }: IBigTitleProps) => (
    <Tag className={`${bigTitle} ${className}`}>{children}</Tag>
);

export default BigTitle;
