import { FORUM_COMMENTS_PER_PAGE, FORUM_THREADS_PER_PAGE, types } from './forum.actions';

const {
    SET_FORUM_THREAD_CATEGORIES,
    SET_FORUM_THREAD_CATEGORIES_FAVORITE,
    SET_FORUM_THREADS,
    SET_FORUM_THREADS_PINNED,
    RESTORE_FORUM_INITIAL_STATE,
    CLEAR_FORUM_THREADS_ITEMS,
    CLEAR_FORUM_THREADS_PINNED_ITEMS,
    SET_FORUM_THREAD,
    SET_FORUM_COMMENTS,
    SET_FORUM_COMMENTS_ANSWERS,
    SET_FORUM_COMMENTS_ITEMS,
    RESTORE_FORUM_COMMENTS_INITIAL_STATE,
} = types;

export const initialState = {
    threadCategories: [],
    threads: {
        items: [],
        pagination: {
            perPage: FORUM_THREADS_PER_PAGE,
            pageCount: 0,
            totalCount: 0,
            currentPage: 0,
        },
    },
    threadsPinned: [],
    thread: null,
    comments: {
        items: [],
        pagination: {
            perPage: FORUM_COMMENTS_PER_PAGE,
            pageCount: 0,
            totalCount: 0,
            currentPage: 0,
        },
    },
};

export default function forumReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_FORUM_THREAD_CATEGORIES:
        case SET_FORUM_THREAD_CATEGORIES_FAVORITE:
            return {
                ...state,
                threadCategories: payload,
            };
        case SET_FORUM_THREADS:
            return {
                ...state,
                threads: {
                    items: [...state.threads.items, ...payload.items],
                    pagination: payload.pagination,
                },
            };
        case SET_FORUM_THREADS_PINNED:
            return {
                ...state,
                threadsPinned: payload,
            };
        case CLEAR_FORUM_THREADS_ITEMS:
            return {
                ...state,
                threads: initialState.threads,
            };
        case CLEAR_FORUM_THREADS_PINNED_ITEMS:
            return {
                ...state,
                threadsPinned: initialState.threadsPinned,
            };
        case SET_FORUM_THREAD:
            return {
                ...state,
                thread: payload,
            };
        case SET_FORUM_COMMENTS:
            return {
                ...state,
                comments: {
                    items: [...state.comments.items, ...payload.items],
                    pagination: payload.pagination,
                },
            };
        case SET_FORUM_COMMENTS_ITEMS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    items: payload,
                },
            };
        case SET_FORUM_COMMENTS_ANSWERS:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    items: payload,
                },
            };
        case RESTORE_FORUM_COMMENTS_INITIAL_STATE:
            return {
                ...state,
                comments: initialState.comments,
            };
        case RESTORE_FORUM_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
}
