import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    dropArea,
    inDropArea,
    dropZone,
    dropZoneTxt,
    small,
} from './meal-planer-dropzone.module.scss';
import Plus from '../../../assets/images/svg/plus.svg';
import { types } from '../../../redux/meal-planer/meal-planer.actions';
import { types as templateTypes } from '../../../redux/meal-planer-template/meal-planer-template.actions';

const MealPlanerDropZone = ({ short = false, date, sequence = 0, mealTypeId, isTemplate }) => {
    const [dropZoneDepth, setDropZoneDepth] = useState(0);
    const dispatch = useDispatch();

    const action = { payload: { date, sequence, mealTypeId } };

    const addPosition = () => {
        action.type = isTemplate ? templateTypes.ADD_ITEM : types.ADD_ITEM;
        dispatch(action);
    };

    const handleDragEnter = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setDropZoneDepth(dropZoneDepth + 1);
    };
    const handleDragLeave = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setDropZoneDepth(dropZoneDepth - 1);
    };
    const handleDragOver = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
    };
    const handleDrop = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setDropZoneDepth(0);

        const dragPayload = JSON.parse(ev.dataTransfer.getData('text/plain'));

        let action;
        if (dragPayload.mode === 'copy') {
            action = {
                type: isTemplate ? templateTypes.FETCH_ADD_RECIPE : types.SEND_ADD_RECIPE,
                payload: { date, sequence, mealTypeId, ...dragPayload },
            };
        } else {
            action = {
                type: isTemplate ? templateTypes.UPDATE_ITEM : types.UPDATE_ITEM,
                payload: { date, sequence, mealTypeId, ...dragPayload },
            };
        }

        dispatch(action);
    };

    return (
        <div
            className={`
                ${dropArea}
                ${dropZoneDepth > 0 ? inDropArea : ''}
            `}
            onDragEnter={(ev) => handleDragEnter(ev)}
            onDragOver={(ev) => handleDragOver(ev)}
            onDragLeave={(ev) => handleDragLeave(ev)}
            onDrop={(ev) => handleDrop(ev)}
            role="button"
            tabIndex="0"
        >
            <button
                className={`
                    ${dropZone}
                    ${short ? small : ''}
                `}
                onClick={addPosition}
            >
                <span className={dropZoneTxt}>
                    <Plus />
                    Dodaj kolejny
                </span>
            </button>
        </div>
    );
};

export default MealPlanerDropZone;
