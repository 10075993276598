// extracted by mini-css-extract-plugin
export var additionalSingleMovieHolder = "course-player-list-item-module--additional-single-movie-holder--J84dR";
export var clickable = "course-player-list-item-module--clickable--nJfFs";
export var durationTime = "course-player-list-item-module--duration-time--JUmyt";
export var fakeWrapperIconLock = "course-player-list-item-module--fake-wrapper-icon-lock--K75NE";
export var gatsbyImageWrapper = "course-player-list-item-module--gatsby-image-wrapper--RHTaq";
export var icon = "course-player-list-item-module--icon--EDPnD";
export var iconLock = "course-player-list-item-module--icon-lock--UKevF";
export var listItem = "course-player-list-item-module--list-item--nliPu";
export var playIcon = "course-player-list-item-module--play-icon--7Q10+";
export var playerItemInfo = "course-player-list-item-module--player-item-info--IB2N3";
export var ratioClass = "course-player-list-item-module--ratio-class--euDjB";
export var relative = "course-player-list-item-module--relative--VlOuU";
export var time = "course-player-list-item-module--time--z2+-A";
export var title = "course-player-list-item-module--title--6E9Yl";