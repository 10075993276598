import { selectLoaderByEntity } from '../ui/ui.selectors';
import {
    SUBSCRIPTION,
    SUBSCRIPTION_UPGRADE,
    SUBSCRIPTION_DEMAND_OFFER,
} from './subscription.actions';

export const selectSubscriptionStatus = (state) => selectLoaderByEntity(state, SUBSCRIPTION);
export const selectSubscriptionUpgradeVariants = (state) => state.subscription.variants;
export const selectSubscriptionUpgradeStatus = (state) => {
    return selectLoaderByEntity(state, SUBSCRIPTION_UPGRADE);
};
export const selectSubscriptionSpecialOffer = (subscriptionId) => (state) => {
    return state.subscription.specialOffers[subscriptionId];
};
export const selectSubscriptionDemandOfferStatus = (state) => {
    return selectLoaderByEntity(state, SUBSCRIPTION_DEMAND_OFFER);
};
