import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import { onlyText } from 'react-children-utilities';

import {
    form,
    button,
    title,
    rodoNote,
    checkboxConsent,
    consents as consentsClass,
    checkboxSubscription,
} from './newsletter.module.scss';
import { copy } from '../../../../copy';
import { config } from '../../../../config';
import {
    setNewsletterToSubscribe,
    setNewsletterToSubscribeClearErrors,
    setNewsletterToSubscribeClearSuccess,
} from '../../../../redux/newsletter/user-zone/actions/user-zone-newsletter-subscribe';
import { getCurrentProfile } from '../../../../redux/profile/profile.selectors';
import {
    setNewsletterToUnSubscribe,
    setNewsletterToUnSubscribeClearErrors,
    setNewsletterToUnSubscribeClearSuccess,
} from '../../../../redux/newsletter/user-zone/actions/user-zone-newsletter-unsubscribe';
import { fetchUserData } from '../../../../redux/user/user.actions';

import AdditionalInformationWrapper from './additional-information-wrapper';
import Button from '../../../atoms/button';
import Loader from '../../../atoms/loader';
import Checkbox from '../../../atoms/form-poc/checkbox';

const { consents } = copy;
const { required } = config.messages.form;
const { consentsMap } = config;

const getInitialValues = (userHasNewsletter) => {
    return {
        newsletterAccount: {
            subscription: userHasNewsletter,
            ...(!userHasNewsletter
                ? {
                      consents: {
                          regulations: false,
                          newsletter: false,
                          rodo: false,
                      },
                  }
                : {}),
        },
    };
};

const validationSchema = Yup.object({
    newsletterAccount: Yup.object({
        subscription: Yup.bool(),
        consents: Yup.object({
            regulations: Yup.boolean().required(required).oneOf([true], required),
            newsletter: Yup.boolean().required(required).oneOf([true], required),
            rodo: Yup.boolean().required(required).oneOf([true], required),
        }),
    }),
});

const Newsletter = ({
    userHasNewsletter,
    userEmail,
    currentProfile,
    isSubscribeLoading,
    isSubscribeError,
    isUnsubscribeLoading,
    isUnSubscribeError,
    isSubscribeSuccess,
    isUnsubscribeSuccess,
    dispatch,
}) => {
    const [isUselessRequest, setIsUselessRequest] = useState(null);

    useEffect(() => {
        if (isSubscribeSuccess || isUnsubscribeSuccess) {
            dispatch(fetchUserData());
        }
    }, [dispatch, isSubscribeSuccess, isUnsubscribeSuccess]);

    const onSubmit = (formValues) => {
        const { subscription } = formValues.newsletterAccount;

        /** Clear previous redux call.**/
        try {
            dispatch(setNewsletterToUnSubscribeClearSuccess());
            dispatch(setNewsletterToSubscribeClearSuccess());
            dispatch(setNewsletterToSubscribeClearErrors());
            dispatch(setNewsletterToUnSubscribeClearErrors());

            if (isUselessRequest) {
                setIsUselessRequest(null);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }

        let data = {
            name: `${currentProfile.firstName} ${currentProfile.lastName}`,
            email: userEmail,
        };

        if (subscription && !userHasNewsletter) {
            data = {
                ...data,
                consents: [
                    {
                        source: consentsMap.source.newsletterAccount,
                        key: consentsMap.key.regulations,
                        content: onlyText(consents.regulations),
                        agreed: formValues.newsletterAccount.consents.regulations ? 1 : 0,
                    },
                    {
                        source: consentsMap.source.newsletterAccount,
                        key: consentsMap.key.newsletter,
                        content: consents.newsletter,
                        agreed: formValues.newsletterAccount.consents.newsletter ? 1 : 0,
                    },
                    {
                        source: consentsMap.source.newsletterAccount,
                        key: consentsMap.key.rodo,
                        content: consents.rodo,
                        agreed: formValues.newsletterAccount.consents.rodo ? 1 : 0,
                    },
                ],
            };

            dispatch(setNewsletterToSubscribe(data, dispatch));
        } else if (!subscription && userHasNewsletter) {
            dispatch(setNewsletterToUnSubscribe(data, dispatch));
        } else {
            setIsUselessRequest(true);
        }
    };

    if (isSubscribeLoading || isUnsubscribeLoading) {
        return <Loader />;
    }

    return (
        <>
            {!userHasNewsletter && (
                <div className={title}>
                    Nie zapisałeś się jeszcze do&nbsp;naszego newslettera! Bez obaw możesz zrobić
                    to&nbsp;tutaj!
                </div>
            )}
            <AdditionalInformationWrapper
                isUselessRequest={isUselessRequest}
                setIsUselessRequest={setIsUselessRequest}
                isSubscribeSuccess={isSubscribeSuccess}
                isSubscribeError={isSubscribeError}
                isUnsubscribeSuccess={isUnsubscribeSuccess}
                isUnSubscribeError={isUnSubscribeError}
                userHasNewsletter={userHasNewsletter}
                dispatch={dispatch}
            />
            <Formik
                initialValues={getInitialValues(userHasNewsletter)}
                {...(!userHasNewsletter ? { validationSchema } : {})}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(formik) => (
                    <Form className={form}>
                        <Checkbox
                            name="newsletterAccount.subscription"
                            containerClass={checkboxSubscription}
                        >
                            Chcę dostawać newsletter!
                        </Checkbox>
                        <AnimateHeight
                            height={
                                !userHasNewsletter && formik.values.newsletterAccount.subscription
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <div className={consentsClass}>
                                <Checkbox
                                    name="newsletterAccount.consents.regulations"
                                    containerClass={checkboxConsent}
                                >
                                    {consents.regulations}
                                </Checkbox>
                                <Checkbox
                                    name="newsletterAccount.consents.newsletter"
                                    containerClass={checkboxConsent}
                                >
                                    {consents.newsletter}
                                </Checkbox>
                                <div className={rodoNote}>{consents.rodoDisclaimer}</div>
                                <Checkbox
                                    name="newsletterAccount.consents.rodo"
                                    containerClass={checkboxConsent}
                                >
                                    {consents.rodo}
                                </Checkbox>
                            </div>
                        </AnimateHeight>
                        <Button
                            className={button}
                            size="small"
                            disabled={
                                userHasNewsletter === formik.values.newsletterAccount.subscription
                            }
                        >
                            Zapisz
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default connect((state) => {
    return {
        userHasNewsletter: state.user.userData && state.user.userData.isNewsletterSubscriber,
        userEmail: state.user.userData.email,
        currentProfile: getCurrentProfile(state),

        isSubscribeLoading: state.userZone.newsletter.subscribe.loading,
        isSubscribeError: state.userZone.newsletter.subscribe.error,
        isSubscribeSuccess: state.userZone.newsletter.subscribe.success,

        isUnsubscribeLoading: state.userZone.newsletter.unsubscribe.loading,
        isUnSubscribeError: state.userZone.newsletter.unsubscribe.error,
        isUnsubscribeSuccess: state.userZone.newsletter.unsubscribe.success,
    };
})(Newsletter);
