import React, { useRef, useState, useLayoutEffect, createRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { open, dropdown, buttonBox, flexBar, visibleBox } from './flex-bar.module.scss';

const FlexBar = ({
    children,
    activeItemIndex,
    correctionValue,
    onChange,
    buttonContent,
    classes,
    isActiveItemInVisible,
}) => {
    const [items, setItems] = useState(null);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [recalculate, setRecalculate] = useState(true);

    const barRef = useRef(null);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleItemClick = (index, child) => {
        if (onChange) {
            onChange(index, child);
        }
    };

    const handleButtonClick = (event) => {
        if (!isDropdownOpen) {
            event.stopPropagation();
            openDropdown();
        }
    };

    const openDropdown = () => {
        setIsDropdownOpen(true);
    };

    const closeDropdown = useCallback((e) => {
        if (e.target !== dropdownRef.current) {
            setIsDropdownOpen(false);
            document.removeEventListener('click', closeDropdown);
        }
    }, []);

    useEffect(() => {
        if (!isDropdownOpen) {
            return;
        }
        document.addEventListener('click', closeDropdown);
    }, [isDropdownOpen, closeDropdown]);

    useLayoutEffect(() => {
        const createFlexItems = () => {
            return children.map((child, index) => {
                const ref = createRef();

                return {
                    id: index,
                    ref: ref,
                    visible: true,
                    component: (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <div
                            className={classes.item ? classes.item : ''}
                            ref={ref}
                            key={`flex-item-${index}`}
                            onClick={() => handleItemClick(index, child)}
                            role="button"
                            tabIndex={-1}
                        >
                            {child}
                        </div>
                    ),
                };
            });
        };

        if (children && !items && typeof activeItemIndex === 'number') {
            setItems(createFlexItems());
        } else if (
            items &&
            items[activeItemIndex] &&
            !items[activeItemIndex].visible &&
            isActiveItemInVisible
        ) {
            setRecalculate(true);
        }
    }, [items, children, activeItemIndex, classes.item, handleItemClick, isActiveItemInVisible]);

    useLayoutEffect(() => {
        const calculateFlexBar = (itemsArr) => {
            const barWidth = barRef.current.clientWidth;
            const allItemWidth = items
                .map((item) => item.ref.current.clientWidth)
                .reduce((prev, next) => prev + next);
            const isEnoughSpace = barWidth >= allItemWidth;
            let newItems;
            if (!isEnoughSpace && itemsArr[activeItemIndex]) {
                const activeItemWidth = itemsArr[activeItemIndex].ref.current.clientWidth;
                const buttonWidth = buttonRef.current.clientWidth;
                const availableSpace =
                    barWidth -
                    (isActiveItemInVisible ? activeItemWidth : 0) -
                    (isEnoughSpace ? 0 : buttonWidth) -
                    correctionValue;

                let addedWidth = 0;
                newItems = itemsArr.map((item) => {
                    if (activeItemIndex === item.id && isActiveItemInVisible) {
                        return { ...item, visible: true };
                    }
                    addedWidth += item.ref.current.clientWidth;
                    return {
                        ...item,
                        visible: addedWidth <= availableSpace,
                    };
                });
                setItems(newItems);
            } else {
                newItems = itemsArr.map((item) => {
                    return { ...item, visible: true };
                });
            }

            setItems(newItems);
            setIsButtonVisible(!isEnoughSpace);
            setRecalculate(false);
        };

        if (items && recalculate) {
            calculateFlexBar(items);
        }
    }, [items, recalculate, correctionValue, activeItemIndex, isActiveItemInVisible]);

    useEffect(() => {
        const handleResize = () => {
            setRecalculate(true);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });

    return children ? (
        <div
            className={`${flexBar} ${classes.bar ? classes.bar : ''} ${
                isDropdownOpen && classes.barOpen ? classes.barOpen : ''
            }`}
            ref={barRef}
        >
            <div className={`${visibleBox} ${classes.visibleBox ? classes.visibleBox : ''}`}>
                {items &&
                    (recalculate
                        ? items.map((item) => item.component)
                        : items.filter((item) => item.visible).map((item) => item.component))}
            </div>
            <div className={`${buttonBox} ${classes.buttonBox ? classes.buttonBox : ''}`}>
                {(isButtonVisible || recalculate) && (
                    <button
                        className={classes.button ? classes.button : ''}
                        ref={buttonRef}
                        onClick={handleButtonClick}
                    >
                        {buttonContent}
                    </button>
                )}
                <div
                    className={`
                        ${dropdown} 
                        ${classes.dropdown ? classes.dropdown : ''}
                        ${isDropdownOpen && isButtonVisible ? open : ''}
                    `}
                    ref={dropdownRef}
                >
                    {items &&
                        !recalculate &&
                        items.filter((item) => !item.visible).map((item) => item.component)}
                </div>
            </div>
        </div>
    ) : null;
};

FlexBar.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    activeItemIndex: PropTypes.number,
    correctionValue: PropTypes.number,
    onChange: PropTypes.func,
    buttonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    classes: PropTypes.shape({
        bar: PropTypes.string,
        barOpen: PropTypes.string,
        item: PropTypes.string,
        buttonBox: PropTypes.string,
        button: PropTypes.string,
        dropdown: PropTypes.string,
        visibleBox: PropTypes.string,
    }),
    isActiveItemInVisible: PropTypes.bool,
};

FlexBar.defaultProps = {
    activeItemIndex: 0,
    correctionValue: 0,
    onChange: null,
    buttonContent: '...',
    classes: {
        bar: '',
        barOpen: '',
        item: '',
        buttonBox: '',
        button: '',
        dropdown: '',
        visibleBox: '',
    },
    isActiveItemInVisible: true,
};

export default FlexBar;
