import { types } from '../user/user.actions';
const { SET_USER_DATA, CLEAR_USER_DATA, SET_USER_VERIFICATION_STATUS } = types;

export const initialState = {
    userData: null,
    isUserVerificationFinished: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_DATA:
            return { ...state, userData: action.payload };
        case SET_USER_VERIFICATION_STATUS:
            return {
                ...state,
                isUserVerificationFinished: action.isUserVerificationFinished,
            };
        case CLEAR_USER_DATA:
            return initialState;
        default:
            return state;
    }
}
