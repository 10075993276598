const selectDiscountCode = (state) => state.shop.order.discountCode;

const selectDeliveryMethods = (state) => {
    return state.shop.order.delivery.methods;
};

const selectCurrentDeliveryId = (state) => {
    return state.shop.order.delivery.current;
};

const selectCurrentDeliveryMethod = (state) => {
    const current = selectCurrentDeliveryId(state);
    const methods = state.shop.order.delivery.methods;
    if (current === null || !Array.isArray(methods)) {
        return null;
    }
    return methods.find((item) => item.id === current) || null;
};

const selectChosenXselling = (state) => state.shop.order.chosenXselling;

const selectPayByPointsItem = (itemId) => (state) => {
    return state.shop.order.payByPoints?.items[itemId] || {};
};

const selectPayByPointsBalance = (state) => {
    return state.shop.order.payByPoints?.balance;
};

const selectPayByPointsItems = (state) => state.shop.order.payByPoints.items;

export {
    selectDeliveryMethods,
    selectDiscountCode,
    selectCurrentDeliveryMethod,
    selectCurrentDeliveryId,
    selectChosenXselling,
    selectPayByPointsItem,
    selectPayByPointsBalance,
    selectPayByPointsItems,
};
