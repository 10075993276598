import {
    CLEAR_TRAININGS_LIST,
    SET_TRAININGS_LIST_ERRORS,
    SET_TRAININGS_LIST_ITEMS,
    SET_TRAININGS_LIST_LOADING,
    SET_TRAININGS_LIST_PAGINATION,
    SET_TRAININGS_LIST_SORT,
} from '../actions/actionTypes';

export const initialState = {
    loading: false,
    items: [],
    filters: [
        // {
        //     key: 'body_parts',
        //     label: 'Części ciała',
        //     urlKey: 'czesc-ciala',
        //     values: [
        //         {
        //             key: '1',
        //             label: 'brzuch',
        //             urlKey: 'brzuch',
        //         },
        //         {
        //             key: '2',
        //             label: 'całe ciało',
        //             urlKey: 'cale-cialo',
        //             applied: true,
        //         },
        //         {
        //             key: '3',
        //             label: 'klatka piersiowa',
        //             urlKey: 'klatka-piersiowa',
        //         },
        //         {
        //             key: '4',
        //             label: 'nogi i pupa',
        //             urlKey: 'nogi-i-pupa',
        //             applied: true,
        //         },
        //         {
        //             key: '5',
        //             label: 'plecy',
        //             urlKey: 'plecy',
        //         },
        //         {
        //             key: '6',
        //             label: 'ramiona',
        //             urlKey: 'ramiona',
        //             applied: true,
        //         },
        //     ],
        // },
        // {
        //     key: 'duration',
        //     label: 'Czas trwania',
        //     urlKey: 'czas-trwania',
        //     values: [
        //         {
        //             key: '1',
        //             label: '15 minut',
        //             urlKey: '15',
        //         },
        //         {
        //             key: '2',
        //             label: '20 minut',
        //             urlKey: '20',
        //             applied: true,
        //         },
        //         {
        //             key: '3',
        //             label: '30 minut',
        //             urlKey: '30',
        //         },
        //         {
        //             key: '4',
        //             label: '>30 minut',
        //             urlKey: 'powyzej30',
        //             applied: true,
        //         },
        //     ],
        // },
        // {
        //     key: 'trainer',
        //     label: 'Trener',
        //     urlKey: 'trener',
        //     values: [
        //         {
        //             key: '1',
        //             label: 'Ania',
        //             urlKey: 'ania',
        //         },
        //         {
        //             key: '2',
        //             label: 'Bartek',
        //             urlKey: 'bartek',
        //             applied: true,
        //         },
        //         {
        //             key: '3',
        //             label: 'Tomek',
        //             urlKey: 'tomek',
        //         },
        //     ],
        // },
        // {
        //     key: 'intensity',
        //     label: 'Intensywność',
        //     urlKey: 'intensywnosc',
        //     values: [
        //         {
        //             key: '1',
        //             label: 'lekki',
        //             urlKey: 'lekki',
        //         },
        //         {
        //             key: '2',
        //             label: 'średni',
        //             urlKey: 'sredni',
        //             applied: true,
        //         },
        //         {
        //             key: '3',
        //             label: 'ciężki',
        //             urlKey: 'ciezki',
        //         },
        //         {
        //             key: '4',
        //             label: 'bardzo ciężki',
        //             urlKey: 'bardzo-ciezki',
        //             applied: true,
        //         },
        //     ],
        // },
        // {
        //     key: 'type',
        //     label: 'Typ treningu',
        //     urlKey: 'typ-treningu',
        //     values: [
        //         {
        //             key: '1',
        //             label: 'cardio',
        //             urlKey: 'cardio',
        //         },
        //         {
        //             key: '2',
        //             label: 'modelowanie sylwetki',
        //             urlKey: 'modelowanie-sylwetki',
        //             applied: true,
        //         },
        //         {
        //             key: '3',
        //             label: 'relaksacja',
        //             urlKey: 'relaksacja',
        //         },
        //         {
        //             key: '4',
        //             label: 'taniec',
        //             urlKey: 'taniec',
        //             applied: true,
        //         },
        //     ],
        // },
        // {
        //     key: 'difficulty',
        //     label: 'Poziom trudności',
        //     urlKey: 'poziom-trudnosci',
        //     values: [
        //         {
        //             key: '1',
        //             label: 'początkujący',
        //             urlKey: 'poczatkujący',
        //         },
        //         {
        //             key: '2',
        //             label: 'średni',
        //             urlKey: 'sredni',
        //             applied: true,
        //         },
        //         {
        //             key: '3',
        //             label: 'średnio-zaawansowany',
        //             urlKey: 'srednio-zaawansowany',
        //         },
        //         {
        //             key: '4',
        //             label: 'zaawansowany',
        //             urlKey: 'zaawansowany',
        //             applied: true,
        //         },
        //     ],
        // },
        // {
        //     key: 'hardware',
        //     label: 'Sprzęt',
        //     urlKey: 'sprzet',
        //     values: [
        //         {
        //             key: '1',
        //             label: 'hantelki',
        //             urlKey: 'hantelki',
        //         },
        //         {
        //             key: '2',
        //             label: 'kettlebell',
        //             urlKey: 'kettlebell',
        //             applied: true,
        //         },
        //         {
        //             key: '3',
        //             label: 'masażer',
        //             urlKey: 'masazer',
        //         },
        //         {
        //             key: '4',
        //             label: 'mata',
        //             urlKey: 'mata',
        //         },
        //         {
        //             key: '5',
        //             label: 'step',
        //             urlKey: 'step',
        //         },
        //         {
        //             key: '6',
        //             label: 'swiss ball',
        //             urlKey: 'swiss-ball',
        //         },
        //         {
        //             key: '7',
        //             label: 'sztanga',
        //             urlKey: 'sztanga',
        //         },
        //         {
        //             key: '8',
        //             label: 'taśma mini band',
        //             urlKey: 'tasma-mini-band',
        //         },
        //     ],
        // },
    ],
    sort: {
        key: 'sort',
        label: 'Sortuj',
        urlKey: 'sort',
        type: 'radio',
        values: [
            {
                key: '-createdAt',
                label: 'od najnowszych',
                urlKey: 'od-najnowszych',
            },
            {
                key: 'createdAt',
                label: 'od najstarszych',
                urlKey: 'od-najstarszych',
            },
        ],
        applied: '-createdAt',
    },
    pagination: {
        currentPage: 0,
        perPage: 9,
    },
    errors: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_TRAININGS_LIST_LOADING:
            return { ...state, loading: action.loading };
        case SET_TRAININGS_LIST_ERRORS:
            return { ...state, errors: action.errors };
        case SET_TRAININGS_LIST_ITEMS:
            return { ...state, items: action.items };
        case SET_TRAININGS_LIST_PAGINATION:
            return { ...state, pagination: action.pagination };
        case SET_TRAININGS_LIST_SORT:
            return { ...state, sort: { ...state.sort, applied: action.sort } };
        case CLEAR_TRAININGS_LIST:
            return initialState;
        default:
            return state;
    }
}
