import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { modal, form, loader, grid, button } from './add-recipe-to-plan-modal.module.scss';
import { config } from '../../../config';
import { getMealTypesForSelect } from '../../../utills/get-meal-types-for-select';

import CustomModal from '../../../templates/custom-modal';
import Button from '../../atoms/button';
import InputSelect from '../../atoms/form-poc/input-select';
import InputDate from '../../atoms/form-poc/input-date';
import Loader from '../../atoms/loader';

const { required } = config.messages.form;
const { mealTypesMap, formsStatusMap } = config;

const initialValues = {
    date: new Date(),
    mealType: '',
};

const validationSchema = Yup.object({
    date: Yup.date().required(required),
    mealType: Yup.object().required(required),
});

const AddRecipeToPlanModal = ({ modalId, onSubmit }) => {
    const handleSubmit = (formValues, formikBag) => {
        onSubmit(formValues, formikBag, modalId);
    };

    return (
        <CustomModal modalId={modalId} className={modal} title="Dodaj ten przepis do:">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <Form className={form}>
                        {formik.status === formsStatusMap.loading && (
                            <Loader fullContainer={true} className={loader} />
                        )}
                        <div className={grid}>
                            <InputDate name="date" label="Dzień" />
                            <InputSelect
                                name="mealType"
                                label="Typ posiłku"
                                isSearchable={false}
                                options={getMealTypesForSelect(mealTypesMap, true)}
                            />
                        </div>
                        <Button className={button} color="yellow" size="small">
                            Dodaj
                        </Button>
                    </Form>
                )}
            </Formik>
        </CustomModal>
    );
};

AddRecipeToPlanModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func,
};

AddRecipeToPlanModal.defaultProps = {
    onSubmit: () => {},
};

export default AddRecipeToPlanModal;
