import { types } from '../api/api.actions';
import { endLoader, startLoader, statusFail, statusLoading, statusSuccess } from './ui.actions';

const { API_REQUEST, API_SUCCESS, API_FAIL } = types;

const uiMiddleware = ({ dispatch }) => (next) => (action) => {
    const { type, meta = null } = action;
    next(action);

    let entity = type.replace('[', '').split(']')[0].toLowerCase();

    let actionEntity = meta?.entity;
    let previousActionEntity = meta?.previousAction?.meta?.entity;

    switch (true) {
        case actionEntity && type.includes(API_REQUEST):
            dispatch(statusLoading(actionEntity));
            break;
        case previousActionEntity && type.includes(API_SUCCESS):
            dispatch(statusSuccess(previousActionEntity));
            break;
        case previousActionEntity && type.includes(API_FAIL):
            dispatch(statusFail(previousActionEntity));
            break;
        case type.includes(API_REQUEST):
            dispatch(startLoader(entity));
            break;
        case type.includes(API_SUCCESS):
        case type.includes(API_FAIL):
            dispatch(endLoader(entity));
            break;
        // no default
    }
};

export default uiMiddleware;
