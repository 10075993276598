import React from 'react';

import { wrapper } from './shop-step-user-data.module.scss';
import { mainGrid } from '../../../style/grid.module.scss';

import LoginShop from './login-shop/login-shop';
import RegisterShop from './register-shop/register-shop';

export const ShopStepUserData = () => {
    return (
        <div className={`${mainGrid} ${wrapper}`}>
            <LoginShop />
            <RegisterShop />
        </div>
    );
};

export default ShopStepUserData;
