import React from 'react';

import { input, label } from './file-input-area.module.scss';

const FileInputArea = ({
    form,
    children,
    className = '',
    name,
    fileTypes = [],
    multiple = false,
    maxFileCount = 1,
    maxFileSize = 5120000,
    onChange,
    onClick,
    onBlur,
    onUploadStart,
    onUploadFinish,
}) => {
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
                resolve({
                    name: file.name,
                    content: reader.result,
                    mimeType: file.type,
                });
            reader.onerror = () => reject(`Wystąpił błąd podczas ładowania pliku ${file.name}`);
        });
    };

    const validateFiles = (files) => {
        let validFiles = [];
        let validationErrors = [];
        let remainingCount = maxFileCount;

        files.forEach((file) => {
            if (remainingCount <= 0) {
                validationErrors.push(
                    `Plik ${file.name} nie został dodany, dodano już maksymalną ilość plików.`
                );
            } else if (fileTypes.length && !fileTypes.includes(file.type)) {
                validationErrors.push(`Plik ${file.name} nie jest dozwolony.`);
            } else if (file.size > maxFileSize) {
                validationErrors.push(
                    `Plik ${file.name} jest za duży. Maksymalna wielkość to ${Math.round(
                        maxFileSize / 1024 / 1000
                    )} MB.`
                );
            } else {
                validFiles.push(file);
                remainingCount--;
            }
        });

        return {
            validFiles,
            validationErrors,
        };
    };

    const handleChange = (event) => {
        if (typeof onUploadStart === 'function') {
            onUploadStart();
        }

        const files = Array.from(event.target.files);
        const { validFiles, validationErrors } = validateFiles(files);

        event.target.value = null;

        let uploadResult = {};
        Promise.allSettled(validFiles.map((file) => getBase64(file))).then((data) => {
            const newValues = data
                .filter((item) => {
                    return item.status === 'fulfilled';
                })
                .map((item) => item.value);
            uploadResult.uploadedFiles = [...newValues];

            const readErrors = data
                .filter((item) => {
                    return item.status === 'rejected';
                })
                .map((item) => item.reason);
            uploadResult.uploadErrors = [...validationErrors, ...readErrors];

            if (typeof onChange === 'function') {
                onChange(uploadResult, form);
            }

            if (typeof onUploadFinish === 'function') {
                onUploadFinish();
            }
        });
    };

    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return (
        <div className={className}>
            <input
                className={input}
                type="file"
                id={name}
                name={name}
                {...(fileTypes.length > 0 ? { accept: fileTypes.join(',') } : {})}
                multiple={multiple}
                onChange={(event) => handleChange(event)}
                onClick={handleClick}
                {...(typeof onBlur === 'function' ? { onBlur: onBlur } : {})}
            />
            <label className={label} htmlFor={name}>
                {children}
            </label>
        </div>
    );
};

export default FileInputArea;
