import { types as apiTypes } from '../api/api.actions';

export const ADDRESSES = '[ADDRESSES]';

export const types = {
    // flow/commands actions
    GET_ADDRESSES: `${ADDRESSES} get list`,
    CLEAR_ADDRESSES: `${ADDRESSES} clear`,

    // API actions
    FETCH_ADDRESSES_REQUEST: `${ADDRESSES} ${apiTypes.API_REQUEST}`,
    FETCH_ADDRESSES_SUCCESS: `${ADDRESSES} ${apiTypes.API_SUCCESS}`,
    FETCH_ADDRESSES_FAIL: `${ADDRESSES} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_ADDRESSES: `${ADDRESSES} set`,
    RESTORE_INITIAL_STATE: `${ADDRESSES} restore initial state`,
};

// middleware actions
export const getAddressesAction = () => ({
    type: types.GET_ADDRESSES,
});

// reducer actions
export const setAddressesAction = (items) => ({
    type: types.SET_ADDRESSES,
    payload: items,
});

export const restoreInitialStateAction = () => ({
    type: types.RESTORE_INITIAL_STATE,
});

export const clearAddressesAction = () => ({
    type: types.CLEAR_ADDRESSES,
});
