import React from 'react';
import { Link } from 'gatsby';

import { list, wrapper } from './related-list.module.scss';
import Emotic from '../atoms/emotic';
import Title from '../atoms/title';
import SubTitle from '../atoms/subtitle';
import Button from '../atoms/button';

const RelatedList = ({ emotics, title, subtitle, link, children, className = '' }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <div>
                {emotics && <Emotic emotic={emotics} />}
                <Title>{title}</Title>
                <SubTitle>{subtitle}</SubTitle>
            </div>
            <div className={list}>{children}</div>
            {link && link.url && (
                <Link to={link.url}>
                    <Button color="yellow" icon={link.icon}>
                        {link.name}
                    </Button>
                </Link>
            )}
        </div>
    );
};

export default RelatedList;
