import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import PlusIcon from '../../../assets/images/svg/plus.svg';
import { defaultFormat } from '../../../utills/date-utils';
import { forbiddenClick } from '../../../redux/rbac/rbac.actions';
import { addModalAction } from '../../../redux/actions/actions-modals';
import { sendAddItem } from '../../../redux/meal-planer/meal-planer.actions';

import Button from '../button';
import AddRecipeToPlanModal from '../../organisms/custom-modals/add-recipe-to-plan-modal';

const AddToPlanButton = ({ recipeId, className = '', forbidden, textOnly, onAddToPlanClick }) => {
    const dispatch = useDispatch();

    const Tag = textOnly ? 'button' : Button;
    const tagProps = textOnly ? {} : { icon: PlusIcon, color: forbidden ? 'grey' : 'yellow' };

    const handleSubmit = (formValues, formikBag, modalId) => {
        const payload = {
            date: moment(formValues.date).format(defaultFormat),
            mealTypeId: formValues.mealType.mealTypeId,
            recipeId: recipeId,
        };
        const meta = { modalId, formikBag };
        dispatch(sendAddItem(payload, meta));
    };

    const handleAddToPlan = () => {
        if (forbidden) {
            dispatch(forbiddenClick());
            return;
        }
        onAddToPlanClick();
        dispatch(
            addModalAction({
                renderComponent: (modalId) => (
                    <AddRecipeToPlanModal modalId={modalId} onSubmit={handleSubmit} />
                ),
            })
        );
    };

    return (
        <Tag {...tagProps} className={className} onClick={handleAddToPlan}>
            Dodaj do planera
        </Tag>
    );
};

AddToPlanButton.propTypes = {
    recipeId: PropTypes.number.isRequired,
    className: PropTypes.string,
    forbidden: PropTypes.bool,
    textOnly: PropTypes.bool,
    onAddToPlanClick: PropTypes.func,
};

AddToPlanButton.defaultProps = {
    className: '',
    forbidden: false,
    textOnly: false,
    onAddToPlanClick: () => {},
};

export default AddToPlanButton;
