import { config } from '../../config';
import { TRAININGS_ATLAS, LIST } from './trainings-atlas.reducer';
import genericListMiddleware from '../generic-list/generic-list.middleware';

const listEndpoint = config.endpoints.workout.trainings;
const expand = `bodyParts,equipment,difficulty`;

const trainingsAtlasListMiddleware = genericListMiddleware(
    TRAININGS_ATLAS,
    LIST,
    listEndpoint,
    expand
);

const trainingsAtlasMiddleware = [
    trainingsAtlasListMiddleware,
    // add more middlewares for subEntities if needed
];

export default trainingsAtlasMiddleware;
