import axios from 'axios';
import { config } from '../../../config';

export const getMessagesData = ({
    phraseString,
    countToDownload,
    profileId,
}) => {
    if (phraseString.length > 0) {
        return axios.get(
            config.endpoints.messages.findFetch({
                phraseString,
                countToDownload,
                profileId,
            }),
            {
                withCredentials: true,
            }
        );
    } else {
        return axios.get(
            config.endpoints.messages.fetchThread({
                countToDownload,
                profileId,
            }),
            {
                withCredentials: true,
            }
        );
    }
};

export const setMessagesData = (sendData, profileId) => {
    return axios.post(config.endpoints.messages.send(profileId), sendData, {
        withCredentials: true,
    });
};
