import React, { useState } from 'react';

import { discountConditionMoreWidget, labelButton } from './discount-condition-list.module.scss';

import Label from '../atoms/label';

export interface IDiscountConditionList {
    conditions: string[];
    className?: string;
    labelClassName?: string;
    limit?: number;
    color?: string;
    DropdownComponent?: React.ReactComponentElement<any>;
}

export default function DiscountConditionList({
    conditions,
    className = '',
    labelClassName = '',
    limit,
    color = '--color-discounts-primary',
    DropdownComponent,
}: IDiscountConditionList) {
    const [showDropdown, setShowDropdown] = useState(false);

    if (conditions?.length > 0) {
        return (
            <div className={className}>
                {conditions.slice(0, limit).map((condition, index) => (
                    <Label
                        key={`discount-benefit-${index}`}
                        color={color}
                        className={labelClassName}
                    >
                        {condition}
                    </Label>
                ))}
                {Number(limit) < conditions.length && DropdownComponent && (
                    <div className={discountConditionMoreWidget}>
                        <Label
                            Tag="button"
                            color={
                                showDropdown
                                    ? '--color-grey-super-light'
                                    : '--color-grey-ultra-super-light'
                            }
                            className={`${labelButton} ${labelClassName}`}
                            onClick={() => setShowDropdown(!showDropdown)}
                        >
                            ...
                        </Label>
                        {
                            <DropdownComponent.type
                                {...DropdownComponent.props}
                                open={showDropdown}
                                offset={limit}
                            />
                        }
                    </div>
                )}
            </div>
        );
    }

    return null;
}
