import React, { useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import Calendar from 'react-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';

import { labelBox, input, labelText } from './input.module.scss';
import { input as inputDate, dropdown, container, icon, inputBox } from './input-date.module.scss';
import CalendarIcon from '../../../assets/images/svg/callendar.svg';

import Dropdown from '../../molecules/dropdown';
import TextError from './text-error';

const InputDate = ({ label, name, min, max, containerClass, ...rest }) => {
    const [open, setOpen] = useState(false);

    const handleDateSelect = (date, form) => {
        setOpen(false);
        form.setFieldValue(name, date);
    };

    return (
        <div className={`${container} ${containerClass}`}>
            <div className={labelBox}>
                {label && (
                    <label htmlFor={name} className={labelText}>
                        {label}
                    </label>
                )}
            </div>
            <Field name={name}>
                {({ form, field }) => (
                    <div className={inputBox}>
                        <input
                            id={name}
                            name={name}
                            className={`${input} ${inputDate}`}
                            readOnly={true}
                            onClick={() => setOpen(true)}
                            value={moment(field.value).format('DD MMMM YYYY')}
                            {...rest}
                        />
                        <CalendarIcon className={icon} />
                        <Dropdown
                            className={dropdown}
                            open={open}
                            closeFunc={() => setOpen(false)}
                            isTriangle={false}
                        >
                            <Calendar
                                defaultActiveStartDate={new Date()}
                                {...(field.value ? { value: field.value } : {})}
                                onClickDay={(date) => handleDateSelect(date, form)}
                                minDate={min}
                                maxDate={max}
                            />
                        </Dropdown>
                    </div>
                )}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    );
};

InputDate.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    containerClass: PropTypes.string,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
};

InputDate.defaultProps = {
    label: '',
    containerClass: '',
    min: null,
    max: null,
};

export default InputDate;
