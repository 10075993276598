import omit from './omit';

export default function mergeArrayWithReplace(...args) {
    if (args.length > 1) {
        return mergeArrayWithReplace(
            [
                ...args[0].filter(
                    (item) =>
                        !args[1]
                            .map((innerItem) =>
                                JSON.stringify(omit(innerItem, ['content']))
                            )
                            .includes(JSON.stringify(omit(item, ['content'])))
                ),
                ...args[1],
            ],
            ...args.slice(2)
        );
    } else {
        return args[0];
    }
}
