import React from 'react';
import PropTypes from 'prop-types';

import { button, active } from './gallery-miniature.module.scss';

const GalleryMiniature = ({ className, isActive, onClick, mediaImage, disabled }) => {
    return (
        <button
            className={`${button} ${className} ${isActive ? active : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            <img src={mediaImage.url} alt={mediaImage.alt} />
        </button>
    );
};

GalleryMiniature.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    mediaImage: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

GalleryMiniature.defaultProps = {
    className: '',
    isActive: false,
    onClick: () => {},
    disabled: false,
};

export default GalleryMiniature;
