import React from 'react';

import { componentTypeMap } from '../../../../templates/shop-steps/enums/component-maps';

const selectBuyingProcessGlobal = (state) => {
    return state.buyingProcess.global;
};

export const selectCurrentStepById = (state) => {
    const { choosenStepId, allSteps } = selectBuyingProcessGlobal(state);

    return allSteps.find((step) => step.id === choosenStepId);
};

export const selectAllBuyingSteps = (state) => selectBuyingProcessGlobal(state).allSteps;
export const selectChosenStepId = (state) => selectBuyingProcessGlobal(state).choosenStepId;

export const selectComponentByName = (state) => {
    const chosenStepId = selectChosenStepId(state);

    return componentTypeMap[chosenStepId] || null;
};
