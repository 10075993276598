import { config } from '../config';

const {
    fileTypesMap: { images },
} = config;

export const getImgUriFromMedia = (obj) => {
    if (Array.isArray(obj.media) && obj.media.length) {
        return obj.media.find((item) => {
            if (item.type !== null) {
                return images.includes(item.type);
            }
            return null;
        })?.uri;
    } else {
        return null;
    }
};
