import React from 'react';

import {
    full,
    noBg,
    little,
    ldsRipple,
    loadingText,
    ldsRipple__wrapper,
} from './loader.module.scss';

export interface ILoaderProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    innerElementClassName?: string;
    fullContainer?: boolean;
    noBackground?: boolean;
    text?: string;
    small?: boolean;
}

const Loader = ({
    className = '',
    innerElementClassName = '',
    fullContainer,
    noBackground,
    text,
    small,
    ...rest
}: ILoaderProps) => {
    return (
        <div
            className={`
                ${ldsRipple__wrapper}
                ${className}
                ${fullContainer ? full : ''}
                ${noBackground ? noBg : ''}
                ${small ? little : ''}
            `}
            {...rest}
        >
            {text && <p className={loadingText}>{text}</p>}
            <div className={`${ldsRipple} ${innerElementClassName}`}>
                <div />
                <div />
            </div>
        </div>
    );
};

export default Loader;
