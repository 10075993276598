import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
    container,
    dots,
    active,
    slide,
    prev,
    next,
    sliderNav,
    buttonBox,
} from './slider.module.scss';
import useSlider from '../../hooks/use-slider';

import ArrowButton from '../atoms/arrow-button';
import SliderDots from '../atoms/slider-dots';

const sliderConfig = {
    arrows: false,
    dots: false,
    speed: 300,
};

interface ISliderProps {
    className?: string;
    children?: React.ReactNode;
}

const Slider: React.FC<ISliderProps> = ({ children, className = '' }) => {
    const {
        sliderSettings,
        sliderRef,
        slideIndex,
        setSlideIndex,
        getPrev,
        getNext,
        isChanging,
    } = useSlider(sliderConfig);

    return (
        <div className={`${container} ${className}`}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            <SlickSlider {...sliderSettings} ref={sliderRef}>
                {Array.isArray(children) &&
                    React.Children.map(children, (child, index) => {
                        return (
                            <div
                                className={`${slide} ${index === slideIndex ? active : ''}`}
                                key={`slide-${index}`}
                            >
                                {child}
                            </div>
                        );
                    })}
            </SlickSlider>
            {Array.isArray(children) && children.length > 1 && (
                <div className={sliderNav}>
                    <div className={`${buttonBox} ${prev}`}>
                        <ArrowButton
                            dark={true}
                            onClick={getPrev}
                            disabled={isChanging}
                            ariaLabel="wcześniejszy"
                        />
                    </div>
                    <SliderDots
                        slides={children}
                        disabled={isChanging}
                        onClick={setSlideIndex}
                        activeSlideIndex={slideIndex}
                        dark={true}
                        className={dots}
                    />
                    <div className={`${buttonBox} ${next}`}>
                        <ArrowButton
                            dark={true}
                            rotateDeg={180}
                            onClick={getNext}
                            disabled={isChanging}
                            ariaLabel="następny"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Slider;
