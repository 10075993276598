import { useCallback, useEffect, useState } from 'react';

import { clearFilters, prepareObjectForDispatch } from '../utills/filter-utils';
import { GenericListFilters, GenericListSort } from '../models/generic-list.model';

export interface IUseGenericFilterProps {
    filters?: GenericListFilters;
    sort?: GenericListSort;
    onApplyFilters?: (filters: GenericListFilters) => void;
    onApplySort?: (sort: GenericListSort) => void;
}

export default function useGenericFilters({
    filters,
    sort,
    onApplyFilters,
    onApplySort,
}: IUseGenericFilterProps) {
    const [openedItem, setOpenedItem] = useState<string | null>(null);
    const [filtersCollection, setFiltersCollection] = useState<GenericListFilters>({});
    const [sortCollection, setSortCollection] = useState<GenericListSort>({});

    const toggleOpen = useCallback(
        (key?) => (openedItem === key ? setOpenedItem(null) : setOpenedItem(key)),
        [openedItem]
    );

    const applyFilters = useCallback(() => {
        toggleOpen();

        if (onApplyFilters) {
            onApplyFilters(filtersCollection);
        }
    }, [filtersCollection, toggleOpen, onApplyFilters]);

    const filterClear = useCallback(
        (event) => {
            toggleOpen();

            if (onApplyFilters) {
                onApplyFilters(clearFilters(filtersCollection, event));
            }
        },
        [toggleOpen, filtersCollection, onApplyFilters]
    );

    const filterClick = useCallback(
        (event) => {
            const newFilters = prepareObjectForDispatch(filtersCollection, event);
            setFiltersCollection(newFilters);

            if (event.target.type === 'radio' || event.target.type === 'select') {
                toggleOpen();

                if (onApplyFilters) {
                    onApplyFilters(newFilters);
                }
            }
        },
        [filtersCollection, onApplyFilters, toggleOpen]
    );

    const sortClick = useCallback(
        (event) => {
            const newSort = prepareObjectForDispatch(sortCollection, event);
            setSortCollection(newSort);

            if (event.target.type === 'radio') {
                toggleOpen();

                if (onApplySort) {
                    onApplySort(newSort);
                }
            }
        },
        [onApplySort, toggleOpen, sortCollection]
    );

    useEffect(
        function isFiltersChanged() {
            if (filters) {
                setFiltersCollection(filters);
            }
            // @todo: handle write filters to url params
        },
        [filters]
    );

    useEffect(
        function isSortChanged() {
            if (sort) {
                setSortCollection(sort);
            }
            // @todo: handle write sort to url params
        },
        [sort]
    );

    return {
        filtersCollection,
        sortCollection,
        openedItem,
        toggleOpen,
        applyFilters,
        filterClear,
        filterClick,
        sortClick,
    };
}
