const moduleName = '[BUYING PROCESS STEP TWO]';

export const buyingProcessStepTwoActions = {
    setAddresses: `${moduleName} set addresses`,
    clearAddresses: `${moduleName} clear addresses`,
};

export const setBuyingProcessAddresses = (data) => ({
    type: buyingProcessStepTwoActions.setAddresses,
    payload: data,
});

export const clearBuyingProcessAddresses = () => ({
    type: buyingProcessStepTwoActions.clearAddresses,
});
