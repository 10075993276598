import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import {
    container,
    title,
    list,
    item,
    itemLabel,
    actionButtons,
    button,
    actionDisclaimers,
    offer,
    offerContent,
    offerName,
    offerDescription,
    offerButton,
} from './subscription-card.module.scss';
import { ISubscription } from '../../models/subscription.model';
import { IProduct } from '../../models/product.model';
import { getFormattedDate } from '../../utills/date-utils';
import { addModalAction } from '../../redux/actions/actions-modals';
import {
    cancelSubscriptionAction,
    demandSubscriptionSpecialOfferAction,
    getSubscriptionSpecialOfferAction,
} from '../../redux/subscription/subscription.actions';
import { selectSubscriptionSpecialOffer } from '../../redux/subscription/subscription.selectors';

import Button from '../atoms/button';
import LinkButton from '../atoms/link-button';
import Markdown from '../hoc/markdown';

interface ISubscriptionCardProps {
    className?: string;
    subscription: ISubscription;
    NameTag?: React.ElementType;
}

interface ISubscriptionCardQueryResult {
    product: IProduct | null;
}

const DURATION_ATTRIBUTE_ID = 1;
const LONGEST_DURATION_VALUE = '12';

const SubscriptionCard: React.FC<ISubscriptionCardProps> = ({
    className = '',
    subscription,
    NameTag = 'h4',
}) => {
    const dispatch = useDispatch();
    const { product }: ISubscriptionCardQueryResult = useStaticQuery(query);
    const { slug } = product || {};
    const {
        subscriptionId,
        name,
        canUpgrade,
        canCancel,
        isResigned,
        variantId,
        canUpgradeMessage,
        messages,
        hasSpecialOffer,
        canBeSendSpecialOfferApplication,
        prolongPrice,
    } = subscription;
    const specialOffer = useSelector(selectSubscriptionSpecialOffer(subscriptionId));

    const attributes = getSubscriptionAttributes(subscription);
    const canResign = !isResigned && canCancel;

    const handleUpgrade = () => {
        dispatch(
            addModalAction({
                modalKey: 'SUBSCRIPTION_UPGRADE_MODAL',
                modalProps: { subscriptionId },
            })
        );
    };

    const handleCancel = () => {
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: copy.cancelConfirmTitle,
                    content: copy.cancelConfirmContent,
                    onConfirm: () => {
                        dispatch(cancelSubscriptionAction(subscriptionId));
                    },
                },
            })
        );
    };

    const handleDemandSpecialOffer = () => {
        dispatch(demandSubscriptionSpecialOfferAction());
    };

    useEffect(() => {
        if (hasSpecialOffer) {
            dispatch(getSubscriptionSpecialOfferAction(subscriptionId));
        }
    }, [dispatch, subscriptionId, hasSpecialOffer]);

    return (
        <div className={`${container} ${className}`}>
            <NameTag className={title}>{name}</NameTag>
            <ul className={list}>
                {attributes.map((attribute) => {
                    return (
                        <li
                            className={item}
                            key={`attribute-${attribute.label}-${attribute.value}`}
                        >
                            <p className={itemLabel}>{attribute.label}:</p>
                            <p>{attribute.value}</p>
                        </li>
                    );
                })}
            </ul>
            {(canResign || canUpgrade) && (
                <div className={actionButtons}>
                    {canUpgrade && (
                        <Button
                            className={button}
                            size="small"
                            color="yellow"
                            onClick={handleUpgrade}
                        >
                            {copy.buttonUpgrade}
                        </Button>
                    )}
                    {canResign && (
                        <Button
                            className={button}
                            outline={true}
                            color="red"
                            size="small"
                            onClick={handleCancel}
                        >
                            {copy.buttonCancel}
                        </Button>
                    )}
                </div>
            )}
            {variantId && (
                <div className={actionDisclaimers}>
                    {!isResigned && (
                        <p>
                            {canCancel ? '' : `${copy.cantCancel} `}
                            {prolongPrice && copy.ifNoResign(prolongPrice.grossDisplay)}
                        </p>
                    )}
                    {isResigned && <p>{copy.isResigned}</p>}
                    {canUpgradeMessage && <p>{canUpgradeMessage}</p>}
                </div>
            )}
            {messages.length > 0 && (
                <ul>
                    {messages.map((message, index) => {
                        return <li key={`subscription-message-${index}`}>{message}</li>;
                    })}
                </ul>
            )}
            {hasSpecialOffer && specialOffer && (
                <div className={offer}>
                    <div className={offerContent}>
                        <p className={offerName}>{specialOffer.name}</p>
                        {specialOffer.description && (
                            <Markdown className={offerDescription}>
                                {specialOffer.description}
                            </Markdown>
                        )}
                    </div>
                    {slug && (
                        <LinkButton
                            className={offerButton}
                            to={`/sklep/${slug}${variantId ? `?variant=${variantId}` : ''}`}
                            color="yellow"
                            small={true}
                        >
                            {copy.buttonSpecialOffer}
                        </LinkButton>
                    )}
                </div>
            )}
            {!hasSpecialOffer && canBeSendSpecialOfferApplication && (
                <div className={offer}>
                    <Button
                        className={offerButton}
                        onClick={handleDemandSpecialOffer}
                        color="yellow"
                        size="small"
                    >
                        {copy.buttonDemandSpecialOffer}
                    </Button>
                </div>
            )}
        </div>
    );
};

interface ISubscriptionAttribute {
    label: string;
    value: string | number;
}

function getSubscriptionAttributes(subscription: ISubscription): ISubscriptionAttribute[] {
    const { status, paymentStatus, nextPaymentDate, dateTo, dateFrom, daysToEnd } = subscription;
    const attributesRaw: Array<ISubscriptionAttribute | false> = [
        {
            label: `Status`,
            value: status === 1 ? 'Aktywny' : 'Nieaktywny',
        },
        {
            label: `Płatność`,
            value: paymentStatus === 1 ? 'Opłacono' : 'Nieaktywny',
        },
        nextPaymentDate
            ? {
                  label: `Nadchodząca płatność`,
                  value: getFormattedDate(nextPaymentDate, true),
              }
            : false,
        dateTo && dateFrom
            ? {
                  label: `Czas trwania`,
                  value: `${getFormattedDate(dateFrom, true)} - ${getFormattedDate(dateTo, true)}`,
              }
            : false,
        daysToEnd
            ? {
                  label: `Pozostała ilość dni`,
                  value: daysToEnd,
              }
            : false,
    ];
    return attributesRaw.filter((attribute) => attribute !== false) as ISubscriptionAttribute[];
}

const copy = {
    buttonUpgrade: `Zmień plan na wyższy`,
    buttonCancel: `Anuluj Subskrypcję`,
    buttonSpecialOffer: `Skorzystaj z\xa0oferty specjalnej`,
    buttonDemandSpecialOffer: `Poproś o\xa0ofertę specjalną`,
    cantCancel: `Anulowanie Twojej subskrypcji, będzie dostępne na 30 dni przed końcem terminu ważności twojego abonamentu.`,
    cancelConfirmTitle: `Anulować Subskrypcję\xa0😮?`,
    cancelConfirmContent: `Subskrypcja nie zostanie przedłużona i przestanie być aktywna wraz z końcem opłaconego okresu.`,
    ifNoResign: (price: string) => (
        <>
            Brak anulowania subskrypcji, automatycznie{' '}
            <strong>przedłuży ją o kolejny miesiąc w cenie {price}</strong>.
        </>
    ),
    isResigned: `Subskrypcja została anulowana i przestanie być aktywna wraz z końcem opłaconego okresu.`,
};

const query = graphql`
    query {
        product(productId: { eq: 1 }) {
            ...productFields
        }
    }
`;

export default SubscriptionCard;
