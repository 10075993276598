import React from 'react';
import thunk from 'redux-thunk';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { ErrorBoundary } from 'react-error-boundary';

import cartMiddleware from './shop/cart/cart.middleware';
import uiMiddleware from './ui/ui.middleware';
import rbacMiddleware from './rbac/rbac.middleware';
import alertsMiddleware from './alerts/alerts.middleware';
import mealPlanerMiddleware from './meal-planer/meal-planer.middleware';
import mealPlanerTemplateMiddleware from './meal-planer-template/meal-planer-template.middleware';
import orderMiddleware from './shop/order/order.middleware';
import orderHistoryMiddleware from './shop/order-history/order-history.middleware';
import payuMiddleware from './shop/payu/payu.middleware';
import dietTypesMiddleware from './diet-types/diet-types.middleware';
import filesToDownloadMiddleware from './files-to-download/files-to-download.middleware';
import personalTrainingMiddleware from './personal-training/personal-training.middleware';
import userMiddleware from './user/user.middleware';
import profileMiddleware from './profile/profile.middleware';
import shoppingListsMiddleware from './shopping-lists/shopping-lists.middleware';
import singleShoppingListMiddleware from './single-shopping-list/single-shopping-list.middleware';
import trainingsAtlasMiddleware from './trainings-atlas/trainings-atlas.middleware';
import recipesMiddleware from './recipes/recipes.middleware';
import exerciseAtlasMiddleware from './exercise-atlas/exercise-atlas.middleware';
import addressMiddleware from './address/address.middleware';
import addressesMiddleware from './addresses/addresses.middleware';
import notificationsMiddleware from './notifications/notifications.middleware';
import discountsMiddleware from './shop/discounts/discounts.middleware';
import gamificationMiddleware from './gamification/gamification.middleware';
import partnersZoneMiddleware from './partners-zone/partners-zone.middleware';
import downloadFileMiddleware from './download-files/download-file.middleware';
import surveyMiddleware from './survey/survey.middleware';
import surveysMiddleware from './surveys/surveys.middleware';
import subscriptionMiddleware from './subscription/subscription.middleware';
import subscriptionsMiddleware from './subscriptions/subscriptions.middleware';
import buyingProcessMiddleware from './buying-process/buying-process.middleware';
import productsMiddleware from './shop/products/products.middleware';
import welcomeSlidesMiddleware from './welcome-slides/welcome-slides.middleware';
import forumMiddleware from './forum/forum.middleware';
import coursesMiddleware from './courses/courses.middleware';

import rootReducer from './reducers/index';
import { config } from '../config';
import axiosInstance from '../communication/axiosInstance';
import ErrorFallback from '../components/hoc/error-fallback/error-fallback';
import Logo from '../components/atoms/logo';
import multiClientMiddlewareConfig from '../communication/multi-client-middleware-options';
import BackgroundRehydrator from '../components/hoc/background-rehydrator';

export const INIT_ACTION = '[Store] init';

let composeEnhancers;
if (typeof window !== 'undefined') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}

const middlewareList = applyMiddleware(
    thunk.withExtraArgument(config.endpoints),
    multiClientMiddleware(axiosInstance, multiClientMiddlewareConfig),
    downloadFileMiddleware,
    userMiddleware,
    profileMiddleware,
    rbacMiddleware,
    ...exerciseAtlasMiddleware,
    ...trainingsAtlasMiddleware,
    ...recipesMiddleware,
    orderHistoryMiddleware,
    cartMiddleware,
    buyingProcessMiddleware,
    orderMiddleware,
    payuMiddleware,
    personalTrainingMiddleware,
    mealPlanerMiddleware,
    mealPlanerTemplateMiddleware,
    uiMiddleware,
    alertsMiddleware,
    dietTypesMiddleware,
    filesToDownloadMiddleware,
    shoppingListsMiddleware,
    singleShoppingListMiddleware,
    productsMiddleware,
    discountsMiddleware,
    addressMiddleware,
    addressesMiddleware,
    notificationsMiddleware,
    ...gamificationMiddleware,
    ...partnersZoneMiddleware,
    surveyMiddleware,
    surveysMiddleware,
    subscriptionMiddleware,
    subscriptionsMiddleware,
    welcomeSlidesMiddleware,
    forumMiddleware,
    coursesMiddleware
);

const persistConfig = {
    key: 'root',
    whitelist: ['user', 'profile', 'shop'],
    storage,
    stateReconciler: autoMergeLevel2,
    transforms: [
        createBlacklistFilter('user', ['isUserVerificationFinished']),
        createBlacklistFilter('shop', ['discounts', 'order', 'products']),
    ],
};

export const noWindow = typeof window === 'undefined';

const persistedReducer = noWindow ? rootReducer : persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeEnhancers(middlewareList));

persistStore(store);

store.dispatch({ type: INIT_ACTION });

const fallbackRender = (props) => <ErrorFallback {...props} logo={<Logo />} />;
const errorBoundaryEnabled = process.env.ERROR_BOUNDARY === '1';

const StoreProvider = ({ element }) => {
    return (
        <Provider store={store}>
            <BackgroundRehydrator>
                {errorBoundaryEnabled && (
                    <ErrorBoundary fallbackRender={fallbackRender}>{element}</ErrorBoundary>
                )}
                {!errorBoundaryEnabled && element}
            </BackgroundRehydrator>
        </Provider>
    );
};

export default StoreProvider;
