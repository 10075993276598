import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { onlyText } from 'react-children-utilities';
import { Link } from 'gatsby';

import {
    title,
    checkbox,
    wrapper,
    form,
    rodoNote,
    subtitleWrapper,
    addressDataWrapper,
    cartList,
    editButton,
    submitButton,
    formDisclaimer,
    paymentMethodsWrapper,
    paymentSpace,
    smallTitle,
} from './shop-step-summary.style.module.scss';
import { mainGrid } from '../../../style/grid.module.scss';
import icon from '../../../assets/images/svg/icon-pencil.svg';
import { config } from '../../../config';
import { copy } from '../../../copy';
import { EProductKind } from '../../../models/product-kind.enum';
import { selectCart, selectCartIsLoading } from '../../../redux/shop/cart/cart.selectors';
import { setBuyingProcessGlobalStep } from '../../../redux/buying-process/global/actions/buying-process-global-actions';
import { setOrderConsents, submitOrder } from '../../../redux/shop/order/order.actions';
import { selectCurrentDeliveryMethod } from '../../../redux/shop/order/order.selectors';

import ShopCartSummaryPrice from '../../../components/atoms/shop-cart-summary-price/shop-cart-summary-price';
import CartButton from '../../../components/atoms/cart-button';
import { CartListConnected } from '../../../components/molecules/cart/cart-list';
import Separator from '../../../components/atoms/separator';
import IconButton from '../../../components/atoms/icon-button';
import PaymentPreview from './additional/payment-preview/payment-preview';
import Checkbox from '../../../components/atoms/form-poc/checkbox';
import Title from '../../../components/atoms/title';
import ReadMore from '../../../components/atoms/read-more';
import LinkUnderline from '../../../components/atoms/link-underline';
import DiscountCodeForm from '../../../components/organisms/discount-code-form';
import Loader from '../../../components/atoms/loader';
import CheckAllCheckbox from '../../../components/atoms/form-poc/check-all-checkbox';

const { paymentModelsMap, consentsMap } = config;
const { required } = config.messages.form;
const { consents } = copy;

const validations = {
    regulations: Yup.boolean().required(required).oneOf([true], required),
    rodo: Yup.boolean().required(required).oneOf([true], required),
    withdraw: Yup.boolean().required(required).oneOf([true], required),
    recurringPaymentSubscription: Yup.boolean().required(required).oneOf([true], required),
    recurringPaymentTrainingAtlas: Yup.boolean().required(required).oneOf([true], required),
    rodoHealthData: Yup.boolean().required(required).oneOf([true], required),
};

const cartHasProductKind = (cart, kindId) => {
    return cart?.items?.some((item) => item.product.kind === kindId);
};

const cartHasRenewableInstallments = (cart, paymentModelId) => {
    return cart?.items?.some((item) => item.product.paymentModel === paymentModelId);
};

const cartHasMultiPaymentSubscription = (cart) => {
    return cart?.items?.some((item) => item.subscriptionSp?.isMultiPayment);
};

const ShopStepSummary = () => {
    const dispatch = useDispatch();
    const { cart, address, invoice, delivery } = useSelector((state) => ({
        cart: selectCart(state),
        address: state.buyingProcess.stepTwo.addressDelivery,
        invoice: state.buyingProcess.stepTwo.addressInvoice,
        delivery: selectCurrentDeliveryMethod(state),
    }));
    const isCartLoading = useSelector(selectCartIsLoading);

    const [cartHasDigitalProducts, setCartHasDigitalProducts] = useState(false);
    const [cartHasSubscriptionRecurringPayment, setCartHasSubscriptionRecurringPayment] = useState(
        false
    );
    const [cartHasSubscription, setCartHasSubscription] = useState(false);
    const [cartHasTrainingAtlas, setCartHasTrainingAtlas] = useState(false);

    const handleEdit = (stepId) => {
        dispatch(setBuyingProcessGlobalStep(stepId));
    };

    const getInitialValues = () => {
        return {
            consents: {
                regulations: false,
                rodo: false,
                ...(cartHasDigitalProducts || cartHasSubscription ? { withdraw: false } : {}),
                ...(cartHasSubscriptionRecurringPayment
                    ? { recurringPaymentSubscription: false }
                    : {}),
                ...(cartHasSubscription ? { rodoHealthData: false, warrantyOne: false } : {}),
                ...(cartHasTrainingAtlas ? { recurringPaymentTrainingAtlas: false } : {}),
            },
        };
    };

    const getValidationSchema = () => {
        return Yup.object({
            consents: Yup.object({
                regulations: validations.regulations,
                rodo: validations.rodo,
                ...(cartHasDigitalProducts || cartHasSubscription
                    ? { withdraw: validations.withdraw }
                    : {}),
                ...(cartHasSubscriptionRecurringPayment
                    ? {
                          recurringPaymentSubscription: validations.recurringPaymentSubscription,
                      }
                    : {}),
                ...(cartHasSubscription ? { rodoHealthData: validations.rodoHealthData } : {}),
                ...(cartHasTrainingAtlas
                    ? {
                          recurringPaymentTrainingAtlas: validations.recurringPaymentTrainingAtlas,
                      }
                    : {}),
            }),
        });
    };

    const handleOrderClick = (formValues) => {
        let consentsArr = [];
        for (const [key, value] of Object.entries(formValues.consents)) {
            consentsArr.push({
                source: consentsMap.source.order,
                key: consentsMap.key[key],
                content: onlyText(consents[key]),
                agreed: value ? 1 : 0,
            });
        }

        dispatch(setOrderConsents(consentsArr));
        dispatch(submitOrder());
    };

    useEffect(() => {
        setCartHasSubscriptionRecurringPayment(cartHasMultiPaymentSubscription(cart));
        setCartHasDigitalProducts(cartHasProductKind(cart, EProductKind.Digital));
        setCartHasSubscription(cartHasProductKind(cart, EProductKind.Subscription));
        // 🦀 SMALL CANCER ALERT 🦀
        // We are checking if Training Atlas subscription is in cart by looking
        // for product with renewable installments payment method
        // it will work fine until only Training Atlas will have that payment method
        setCartHasTrainingAtlas(
            cartHasRenewableInstallments(cart, paymentModelsMap.renewableInstallments.id)
        );
    }, [cart]);

    return (
        <div className={`${mainGrid} ${wrapper}`}>
            <div>
                <div className={subtitleWrapper}>
                    <div className={`${smallTitle}`}>Moje zamówienie</div>
                    <IconButton icon={icon} link="/koszyk" className={editButton}>
                        Edytuj
                    </IconButton>
                </div>
                <Separator />

                <div className={cartList}>
                    {isCartLoading && <Loader fullContainer />}
                    {cart?.items?.length > 0 && (
                        <CartListConnected readOnly={true} context="order-summary" />
                    )}
                    {!isCartLoading && cart?.items?.length === 0 && (
                        <div>
                            Twój koszyk jest pusty
                            <Link to={'/sklep'}>
                                <LinkUnderline>Wróć do sklepu</LinkUnderline>
                            </Link>
                        </div>
                    )}
                </div>

                <div>
                    {address && (
                        <div>
                            <div className={subtitleWrapper}>
                                <div>Adres dostawy</div>
                                <IconButton
                                    icon={icon}
                                    onClick={() => handleEdit(1)}
                                    className={editButton}
                                >
                                    Edytuj
                                </IconButton>
                            </div>
                            <Separator />
                            <div className={addressDataWrapper}>
                                <div>{address?.contactPerson}</div>
                                {address?.street && (
                                    <div>
                                        {`ul. ${address?.street} ${address?.houseNumber} ${
                                            address?.apartmentNumber
                                                ? `/${address?.apartmentNumber}`
                                                : ''
                                        }`}
                                    </div>
                                )}
                                {address?.city && (
                                    <div>{`${address?.city} ${address?.zipCode}`}</div>
                                )}
                            </div>
                        </div>
                    )}
                    {invoice && invoice.companyName ? (
                        <div>
                            <div className={subtitleWrapper}>
                                <div>Dane do faktury</div>
                                <IconButton
                                    icon={icon}
                                    onClick={() => handleEdit(1)}
                                    className={editButton}
                                >
                                    Edytuj
                                </IconButton>
                            </div>
                            <Separator />

                            <div className={addressDataWrapper}>
                                <div>{invoice?.companyName}</div>

                                <div>{invoice?.contactPerson}</div>
                                <div>
                                    {`ul. ${invoice?.street} ${invoice?.houseNumber} ${
                                        invoice.apartmentNumber ? `/${invoice.apartmentNumber}` : ''
                                    }`}
                                </div>
                                <div>{`${invoice?.city} ${invoice?.zipCode}`}</div>
                                <div>{`NIP: ${invoice?.taxNumber}`}</div>
                            </div>
                        </div>
                    ) : null}
                </div>

                <div className={paymentMethodsWrapper}>
                    <div>
                        <div className={subtitleWrapper}>
                            <div>Sposób płatności</div>
                            <IconButton
                                icon={icon}
                                onClick={() => handleEdit(1)}
                                className={editButton}
                            >
                                Edytuj
                            </IconButton>
                        </div>
                        <Separator className={paymentSpace} />
                        <PaymentPreview text="Płatność online" />
                    </div>

                    <div>
                        <div className={subtitleWrapper}>
                            <div>Sposób dostawy</div>
                            <IconButton
                                icon={icon}
                                onClick={() => handleEdit(1)}
                                className={editButton}
                            >
                                Edytuj
                            </IconButton>
                        </div>
                        <Separator className={paymentSpace} />
                        {delivery && (
                            <PaymentPreview
                                text={`${delivery.label} ${
                                    delivery.price?.grossDisplay ? delivery.price.grossDisplay : ''
                                }`}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div>
                <Title className={title}>Łączna kwota</Title>
                <ShopCartSummaryPrice isDropdownStyle={false} />
                <DiscountCodeForm />
                <Formik
                    initialValues={getInitialValues()}
                    validationSchema={getValidationSchema()}
                    onSubmit={handleOrderClick}
                    enableReinitialize={true}
                >
                    {() => (
                        <Form className={form}>
                            <div className={formDisclaimer}>
                                Strefa Przemian to&nbsp;unikalne miejsce, ponieważ naszym
                                priorytetem jest m.in. dbanie o&nbsp;Twój komfort
                                i&nbsp;bezpieczeństwo. Przeczytaj treść poniższych punktów, aby
                                przejść dalej:
                            </div>
                            <CheckAllCheckbox
                                checkboxNames={[
                                    'consents.regulations',
                                    'consents.rodo',
                                    cartHasDigitalProducts || cartHasSubscription
                                        ? 'consents.withdraw'
                                        : null,
                                    cartHasSubscriptionRecurringPayment
                                        ? 'consents.recurringPaymentSubscription'
                                        : null,
                                    cartHasTrainingAtlas
                                        ? 'consents.recurringPaymentTrainingAtlas'
                                        : null,
                                    cartHasSubscription ? 'consents.warrantyOne' : null,
                                    cartHasSubscription ? 'consents.rodoHealthData' : null,
                                ].filter((name) => name !== null)}
                                containerClass={checkbox}
                            />
                            <Checkbox name="consents.regulations" containerClass={checkbox}>
                                {consents.regulations}
                            </Checkbox>
                            {(cartHasDigitalProducts || cartHasSubscription) && (
                                <Checkbox name="consents.withdraw" containerClass={checkbox}>
                                    <ReadMore>{consents.withdraw}</ReadMore>
                                </Checkbox>
                            )}
                            {cartHasSubscriptionRecurringPayment && (
                                <Checkbox
                                    name="consents.recurringPaymentSubscription"
                                    containerClass={checkbox}
                                >
                                    <ReadMore>{consents.recurringPaymentSubscription}</ReadMore>
                                </Checkbox>
                            )}
                            {cartHasTrainingAtlas && (
                                <Checkbox
                                    name="consents.recurringPaymentTrainingAtlas"
                                    containerClass={checkbox}
                                >
                                    <ReadMore>{consents.recurringPaymentTrainingAtlas}</ReadMore>
                                </Checkbox>
                            )}
                            {cartHasSubscription && (
                                <Checkbox name="consents.warrantyOne" containerClass={checkbox}>
                                    {consents.warrantyOne}
                                </Checkbox>
                            )}
                            <div className={rodoNote}>
                                <ReadMore>{consents.rodoDisclaimer}</ReadMore>
                            </div>
                            <Checkbox name="consents.rodo" containerClass={checkbox}>
                                {consents.rodo}
                            </Checkbox>
                            {cartHasSubscription && (
                                <Checkbox name="consents.rodoHealthData" containerClass={checkbox}>
                                    <ReadMore>{consents.rodoHealthData}</ReadMore>
                                </Checkbox>
                            )}
                            <CartButton
                                className={`${submitButton}`}
                                fullSize={true}
                                transitions={false}
                                title="ZAMÓW Z OBOWIĄZKIEM ZAPŁATY"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ShopStepSummary;
