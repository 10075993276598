import React, { ChangeEvent, PropsWithChildren } from 'react';
import { ErrorMessage, Field } from 'formik';

import {
    container,
    label,
    input,
    box,
    check,
    dark,
    disabled as disabledClass,
} from './checkbox-group.module.scss';

import TextError from './text-error';

export interface ICheckboxProps {
    name: string;
    containerClass?: string;
    labelClass?: string;
    error?: string;
    darkBox?: boolean;
    withoutFormik?: boolean;
    checked?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    children?: React.ReactNode;
    [key: string]: unknown;
}

const Checkbox = ({
    name,
    containerClass = '',
    labelClass = '',
    error,
    children,
    darkBox,
    withoutFormik,
    checked,
    onChange,
    disabled,
    ...rest
}: PropsWithChildren<ICheckboxProps>) => {
    const Tag = withoutFormik ? 'input' : Field;
    const tagProps = withoutFormik
        ? {
              onChange: onChange,
              checked: checked,
          }
        : {};

    return (
        <div className={`${container} ${containerClass}`}>
            <label
                htmlFor={name}
                className={`${label} ${labelClass} ${disabled ? disabledClass : ''}`}
            >
                <Tag
                    type="checkbox"
                    id={name}
                    name={name}
                    className={input}
                    disabled={disabled}
                    {...tagProps}
                    {...rest}
                />
                <span
                    className={`
                        ${box}
                        ${check}
                        ${darkBox ? dark : ''}
                    `}
                />
                {children}
            </label>
            {withoutFormik ? (
                error && <TextError>error</TextError>
            ) : (
                <ErrorMessage name={name} component={TextError} />
            )}
        </div>
    );
};

export default Checkbox;
