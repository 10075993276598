import { navigate } from 'gatsby';

import { config } from '../../../config';
import {
    checkOrderStatusAction,
    checkPaymentAction,
    clearPayUAction,
    PAYU,
    setCheckCounterAction,
    setCheckInProgressAction,
    setOrderStatusAction,
    types,
} from './payu.actions';
import { errorHandler } from '../../../communication/utils';
import { clearAllAlerts, setAlert } from '../../alerts/alerts.actions';
import { getAbsolutePath, ORDER_ROUTES } from '../../../routes';

const {
    SUBMIT,
    SUBMIT_RETRY,
    SEND_PAYU_TOKEN_REQUEST,
    SEND_PAYU_TOKEN_SUCCESS,
    SEND_PAYU_TOKEN_FAIL,
    SEND_PAYU_TOKEN_RETRY_REQUEST,
    SEND_PAYU_TOKEN_RETRY_SUCCESS,
    SEND_PAYU_TOKEN_RETRY_FAIL,
    CHECK_PAYMENT,
    CHECK_PAYMENT_REQUEST,
    CHECK_PAYMENT_SUCCESS,
    CHECK_PAYMENT_FAIL,
    CHECK_ORDER_STATUS,
    CHECK_ORDER_STATUS_REQUEST,
    CHECK_ORDER_STATUS_SUCCESS,
    CHECK_ORDER_STATUS_FAIL,
} = types;

const { endpoints } = config;
const maxCount = 10;
const paymentStatusMap = config.shop.order.paymentStatusMap;

const payuMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    let fetchConfig;
    next(action);

    switch (type) {
        case SUBMIT:
            dispatch(clearAllAlerts());
            fetchConfig = {
                types: [SEND_PAYU_TOKEN_REQUEST, SEND_PAYU_TOKEN_SUCCESS, SEND_PAYU_TOKEN_FAIL],
                payload: {
                    request: {
                        method: 'post',
                        url: endpoints.shop.orderPayUToken(payload.orderId),
                        data: { token: payload.token },
                        withCredentials: true,
                    },
                    meta: { ...meta, orderId: payload.orderId },
                },
            };
            dispatch(fetchConfig);
            break;

        case SEND_PAYU_TOKEN_SUCCESS:
            if (payload.data.url) {
                navigate(payload.data.url, { replace: true });
            } else {
                dispatch(setCheckInProgressAction(true));
                dispatch(checkOrderStatusAction(meta.previousAction.payload.meta.orderId));
            }
            break;

        case SEND_PAYU_TOKEN_FAIL:
            const postTokenErrors = errorHandler(action.error);
            postTokenErrors.map((err) => dispatch(setAlert(err, PAYU)));
            break;

        case SUBMIT_RETRY:
            dispatch(clearAllAlerts());
            fetchConfig = {
                types: [
                    SEND_PAYU_TOKEN_RETRY_REQUEST,
                    SEND_PAYU_TOKEN_RETRY_SUCCESS,
                    SEND_PAYU_TOKEN_RETRY_FAIL,
                ],
                payload: {
                    request: {
                        method: 'patch',
                        url: endpoints.shop.orderPayment(payload.paymentId),
                        data: { token: payload.token },
                        withCredentials: true,
                    },
                },
            };
            dispatch(fetchConfig);
            break;

        case SEND_PAYU_TOKEN_RETRY_SUCCESS:
            if (payload.data.url) {
                navigate(payload.data.url, { replace: true });
            } else {
                dispatch(setCheckInProgressAction(true));
                dispatch(checkPaymentAction(payload.data.paymentId));
            }
            break;

        case SEND_PAYU_TOKEN_RETRY_FAIL:
            const postTokenRetryErrors = errorHandler(action.error);
            postTokenRetryErrors.map((err) => dispatch(setAlert(err, PAYU)));
            break;

        case CHECK_PAYMENT:
            fetchConfig = {
                types: [CHECK_PAYMENT_REQUEST, CHECK_PAYMENT_SUCCESS, CHECK_PAYMENT_FAIL],
                payload: {
                    request: {
                        method: 'get',
                        url: endpoints.shop.paymentStatus(payload.paymentId),
                        withCredentials: true,
                    },
                    meta: { paymentId: payload.paymentId },
                },
            };

            setTimeout(() => {
                dispatch(fetchConfig);
            }, 2000);
            break;

        case CHECK_PAYMENT_SUCCESS:
        case CHECK_PAYMENT_FAIL:
            const newCheckPaymentCount = getState().shop.payu.checkCounter + 1;
            const paymentId = meta.previousAction.payload.meta.paymentId;

            dispatch(setCheckCounterAction(newCheckPaymentCount));

            if (
                (type === CHECK_PAYMENT_FAIL ||
                    !isPaymentSuccessful(payload.data, paymentStatusMap)) &&
                newCheckPaymentCount < maxCount
            ) {
                dispatch(checkPaymentAction(paymentId));
            } else {
                dispatch(clearPayUAction());
                if (
                    type === CHECK_PAYMENT_FAIL ||
                    !isPaymentSuccessful(payload.data, paymentStatusMap)
                ) {
                    navigate(getAbsolutePath('ORDER_RESULT_VERIFICATION_ERROR', ORDER_ROUTES), {
                        replace: true,
                    });
                } else {
                    navigate(getAbsolutePath('ORDER_SUBSCRIPTION_RESULT', ORDER_ROUTES), {
                        replace: true,
                    });
                }
            }

            break;

        case CHECK_ORDER_STATUS:
            fetchConfig = {
                types: [
                    CHECK_ORDER_STATUS_REQUEST,
                    CHECK_ORDER_STATUS_SUCCESS,
                    CHECK_ORDER_STATUS_FAIL,
                ],
                payload: {
                    request: {
                        method: 'GET',
                        url: endpoints.shop.orderStatus(payload.orderId),
                        withCredentials: true,
                    },
                    meta: { orderId: payload.orderId },
                },
            };

            setTimeout(() => {
                dispatch(fetchConfig);
            }, 2000);
            break;

        case CHECK_ORDER_STATUS_SUCCESS:
        case CHECK_ORDER_STATUS_FAIL:
            const newCheckOrderCount = getState().shop.payu.checkCounter + 1;
            const orderId = meta.previousAction.payload.meta.orderId;

            dispatch(setCheckCounterAction(newCheckOrderCount));

            if (
                (type === CHECK_ORDER_STATUS_FAIL ||
                    !areAllPaymentsSuccessful(payload.data, paymentStatusMap)) &&
                newCheckOrderCount < maxCount
            ) {
                if (type === CHECK_ORDER_STATUS_SUCCESS) {
                    dispatch(setOrderStatusAction(payload.data));
                }
                dispatch(checkOrderStatusAction(orderId));
            } else {
                dispatch(clearPayUAction());
                if (
                    type === CHECK_ORDER_STATUS_FAIL ||
                    !areAllPaymentsSuccessful(payload.data, paymentStatusMap)
                ) {
                    navigate(getAbsolutePath('ORDER_RESULT_VERIFICATION_ERROR', ORDER_ROUTES), {
                        replace: true,
                    });
                } else {
                    navigate(getAbsolutePath('ORDER_SUBSCRIPTION_RESULT', ORDER_ROUTES), {
                        replace: true,
                    });
                }
            }

            break;

        // no default
    }
};

function isPaymentSuccessful(payment, paymentStatusMap) {
    return payment.status === paymentStatusMap['100'].key;
}

function areAllPaymentsSuccessful(order, paymentStatusMap) {
    return order.payments.every((payment) => isPaymentSuccessful(payment, paymentStatusMap));
}

export default payuMiddleware;
