import React, { CSSProperties } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import {
    img,
    dark as darkClass,
    imgBox,
    title as titleClass,
    subtitle as subtitleClass,
    banner,
    content,
    children as childrenClass,
    ratio,
} from './main-banner.module.scss';
import { mainGrid } from '../../style/grid.module.scss';

import SubTitle from '../atoms/subtitle';
import BigTitle from '../atoms/big-title';
import RatioImage, { TRatioImageProps } from '../atoms/ratio-image';

interface IBannerData {
    title?: string;
    subtitle?: string;
    fluidImg?: IGatsbyImageData;
    ratioImage?: TRatioImageProps;
}

export interface IMainBannerProps {
    alt: string;
    bannerData: IBannerData;
    className?: string;
    imgStyle?: CSSProperties;
    contentWrapperClass?: string;
    dark?: boolean;
    children?: React.ReactNode;
}

const MainBanner: React.FC<IMainBannerProps> = ({
    alt,
    bannerData,
    className = '',
    imgStyle,
    contentWrapperClass = '',
    dark = false,
    children,
}) => {
    const { fluidImg, ratioImage, title, subtitle } = bannerData;

    return (
        <div className={`${banner} ${className}`} role="banner">
            <div className={imgBox}>
                {fluidImg && (
                    <GatsbyImage
                        image={fluidImg}
                        alt={alt}
                        imgStyle={imgStyle}
                        className={`${img} ${dark ? darkClass : ''}`}
                    />
                )}
                {!fluidImg && ratioImage && (
                    <RatioImage
                        className={`${img} ${dark ? darkClass : ''} ${ratioImage.className}`}
                        alt={alt || ratioImage.alt}
                        {...ratioImage}
                        ratioClass={`${ratioImage.ratioClass} ${ratio}`}
                    />
                )}
            </div>
            <div className={`${mainGrid} ${content} ${contentWrapperClass}`}>
                {title && (
                    <BigTitle Tag="h1" className={titleClass}>
                        {title}
                    </BigTitle>
                )}

                {subtitle && (
                    <SubTitle light className={subtitleClass}>
                        {subtitle}
                    </SubTitle>
                )}
                {children && <div className={childrenClass}>{children}</div>}
            </div>
        </div>
    );
};

export default MainBanner;
