import axios from 'axios';

import { config } from '../config';

export const getNewOrderPayment = (paymentId) => {
    return axios.patch(
        config.endpoints.shop.orderPayment(paymentId),
        {},
        {
            withCredentials: true,
        }
    );
};
