import { types } from './alerts.actions';

const { SET_ALERT, CLEAR_ALERT, CLEAR_ALL_ALERTS } = types;

const initialState = {};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case SET_ALERT:
            return { ...state, [payload.meta.id]: payload.data };
        case CLEAR_ALL_ALERTS:
            return { ...initialState };
        case CLEAR_ALERT:
            const newState = { ...state };
            delete newState[payload.data];
            return { ...newState };
        default:
            return state;
    }
}
