import React from 'react';

import { label, text, input, checkmark } from './filter-checkbox.module.scss';

const FilterInput = ({ children, checked = false, type = 'checkbox', enabled = true, ...rest }) => {
    return (
        <label className={label}>
            <span className={text}>{children}</span>
            <input
                className={input}
                checked={checked}
                type={type}
                // disabled={!checked && !enabled}
                {...rest}
            />
            <span className={checkmark} />
        </label>
    );
};

export default FilterInput;
