import React from 'react';

import {
    link,
    dark,
    headerBox,
    facebook,
    findUsAlso,
    icons,
    bright,
    twitter,
    instagram,
} from './social-list.module.scss';
import { config } from '../../config';
import Facebook from '../../assets/images/svg/facebook.svg';
import Instagram from '../../assets/images/svg/instagram.svg';
import Twitter from '../../assets/images/svg/twitter.svg';

import SubTitle from '../atoms/subtitle';

const SocialList = ({ light = true, header, externalLinks, className, listStyle }) => {
    const externalLinksMap = config.settings.externalLinks;
    const facebookLink = findExternalLinkById(externalLinks, externalLinksMap.facebook);
    const instagramLink = findExternalLinkById(externalLinks, externalLinksMap.instagram);
    const twitterLink = findExternalLinkById(externalLinks, externalLinksMap.twitter);

    return (
        externalLinks &&
        externalLinks.length > 0 && (
            <div
                className={`
                    ${findUsAlso}
                    ${light ? bright : dark}
                    ${className}
                `}
            >
                {header && <SubTitle className={headerBox}>{header}</SubTitle>}
                <div className={`${icons} ${listStyle}`}>
                    {facebookLink && (
                        <a className={link} href={facebookLink.uri}>
                            <Facebook className={facebook} />
                        </a>
                    )}
                    {instagramLink && (
                        <a className={link} href={instagramLink.uri}>
                            <Instagram className={instagram} />
                        </a>
                    )}
                    {twitterLink && (
                        <a className={link} href={twitterLink.uri}>
                            <Twitter className={twitter} />
                        </a>
                    )}
                </div>
            </div>
        )
    );
};

function findExternalLinkById(externalLinks, id) {
    return externalLinks.find((link) => link.linkId === id);
}

export default SocialList;
