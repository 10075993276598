import { getFullActionName } from '../entities-map';
import { actionTypes } from '../../action-types';
import { arrayToObject } from '../../../utills/data-convertion';

const initialState = {
    data: {},
    loading: null,
    errors: null,
    success: false,
    checkDataLoading: null,
    pagination: {
        perPage: 9,
        nextPageToFetch: 1,
        pageCount: 1,
        totalCount: 0,
    },
};

export const paginationListReducerWithNamedType = (entityType, method) => {
    return function (state = initialState, action) {
        switch (action.type) {
            case getFullActionName(entityType, method, actionTypes.start):
                return {
                    ...state,
                    loading: true,
                    errors: null,
                    success: null,
                };
            case getFullActionName(entityType, method, actionTypes.checkTableDataStart):
                return {
                    ...state,
                    loading: true,
                    errors: null,
                    success: null,
                    checkDataLoading: true,
                };
            case getFullActionName(entityType, method, actionTypes.success):
                return {
                    ...state,
                    data: {
                        ...state.data,
                        ...mapToByIdModel(action.payload.items),
                    },
                    loading: false,
                    errors: null,
                    success: true,
                    ...(action.payload.pagination
                        ? {
                              pagination: {
                                  ...state.pagination,
                                  perPage: action.payload.pagination.perPage,
                                  currentPage: action.payload.pagination.currentPage,
                                  pageCount: action.payload.pagination.pageCount,
                                  totalCount: action.payload.pagination.totalCount,
                              },
                          }
                        : {}),
                };

            case getFullActionName(entityType, method, actionTypes.checkTableDataSuccess):
                return {
                    ...state,
                    data: mapToByIdModel(action.payload.items),
                    loading: false,
                    errors: null,
                    success: true,
                    checkDataLoading: false,
                    ...(action.payload.pagination
                        ? {
                              pagination: {
                                  ...state.pagination,
                                  currentPage: action.payload.pagination.currentPage,
                                  pageCount: action.payload.pagination.pageCount,
                                  totalCount: action.payload.pagination.totalCount,
                              },
                          }
                        : {}),
                };
            case getFullActionName(entityType, method, actionTypes.error):
                return {
                    ...state,
                    loading: false,
                    errors: true,
                    success: false,
                };
            case getFullActionName(entityType, method, actionTypes.checkTableDataError):
                return {
                    ...state,
                    loading: false,
                    errors: true,
                    success: false,
                    checkDataLoading: false,
                };
            case getFullActionName(entityType, method, actionTypes.countUpPageNumber):
                return {
                    ...state,
                    pagination: {
                        ...state.pagination,
                        nextPageToFetch: action.payload,
                    },
                };
            case getFullActionName(entityType, method, actionTypes.clearData):
                return { ...initialState };

            default:
                return state;
        }
    };
};

const mapToByIdModel = (data) => arrayToObject(data, 'id');
