import React, { useEffect, useRef, useState } from 'react';

import {
    button,
    white,
    back,
    buttonWrapper,
    closed,
    closing,
    open,
    opening,
    optionsWrapper,
    filterGroupList,
    searchBox,
    noOptions,
} from './filter-group-list.module.scss';

import FilterInput from '../../atoms/filter-atoms/filter-checkbox';
import InputSearch from '../../atoms/form-poc/input-search';

const ENTRIES_LIMIT_TO_SHOW_SEARCH = 30;

const FilterGroupList = ({
    group,
    open = false,
    clear,
    apply,
    optionClick,
    withButtons = true,
    ...rest
}) => {
    const { values } = group;
    const [openState, setOpenState] = useState(openStates.closed);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const entries = getEntries(values);
    const filteredEntries = entries.filter(([, option]) => {
        return option.label.toLowerCase().includes(searchValue.toLowerCase());
    });

    const listRef = useRef(null);

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue);
    };

    useEffect(() => {
        if (open && !isTransitioning && openState === openStates.closed) {
            setIsTransitioning(true);
        }

        if (open && isTransitioning) {
            setOpenState(openStates.opening);
        }
        if (open && !isTransitioning && openState === openStates.opening) {
            setOpenState(openStates.open);
        }

        if (!open && !isTransitioning && openState === openStates.open) {
            setIsTransitioning(true);
        }

        if (!open && isTransitioning) {
            setOpenState(openStates.closing);
        }
        if (!open && !isTransitioning && openState === openStates.closing) {
            setOpenState(openStates.closed);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, isTransitioning]);

    return (
        <div
            ref={listRef}
            className={`
                ${filterGroupList}
                ${stateClasses[openState]}
            `}
            onTransitionEnd={(e) => {
                if (e.target === listRef.current) {
                    setIsTransitioning(false);
                }
            }}
        >
            {entries.length >= ENTRIES_LIMIT_TO_SHOW_SEARCH && (
                <div className={searchBox}>
                    <InputSearch size="small" onChange={handleSearch} />
                </div>
            )}
            <div className={optionsWrapper}>
                {filteredEntries.map(([key, option], index) => {
                    return (
                        <FilterInput
                            name={group.key}
                            key={`filter-${group.key}-option-${key}`}
                            value={key}
                            enabled={option.enabled}
                            type={group.type}
                            onChange={optionClick}
                            checked={option.applied}
                            {...rest}
                        >
                            {option.label}
                        </FilterInput>
                    );
                })}
                {filteredEntries.length === 0 && <p className={noOptions}>Brak opcji</p>}
            </div>
            {withButtons && (
                <div className={buttonWrapper}>
                    <button
                        type={'button'}
                        className={`${button} ${white}`}
                        name={group.key}
                        onClick={clear}
                    >
                        Wyczyść
                    </button>
                    <button
                        type={'button'}
                        className={`${button} ${back}`}
                        name={group.key}
                        onClick={apply}
                    >
                        Zapisz
                    </button>
                </div>
            )}
        </div>
    );
};

function getEntries(values) {
    const entries = Object.entries(values);
    if (entries.length < ENTRIES_LIMIT_TO_SHOW_SEARCH) {
        return entries;
    }
    const applied = entries.filter(([, option]) => option.applied);
    const notApplied = entries.filter(([, option]) => !option.applied);
    return [...applied, ...notApplied];
}

const openStates = {
    closed: 'closed',
    opening: 'opening',
    open: 'open',
    closing: 'closing',
};

const stateClasses = {
    [openStates.closed]: closed,
    [openStates.opening]: opening,
    [openStates.open]: open,
    [openStates.closing]: closing,
};

export default FilterGroupList;
