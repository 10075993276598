import {
    CLEAR_EXERCISES_LIST,
    SET_EXERCISES_LIST_ERRORS,
    SET_EXERCISES_LIST_ITEMS,
    SET_EXERCISES_LIST_LOADING,
    SET_EXERCISES_LIST_PAGINATION,
    SET_EXERCISES_LIST_SORT,
} from '../actions/actionTypes';

export const initialState = {
    loading: false,
    items: [],
    filters: [],
    sort: {
        key: 'sort',
        label: 'Sortuj',
        slug: 'sort',
        type: 'radio',
        values: [
            {
                key: '-createdAt',
                label: 'od najnowszych',
                slug: 'od-najnowszych',
            },
            {
                key: 'createdAt',
                label: 'od najstarszych',
                slug: 'od-najstarszych',
            },
        ],
        applied: '-createdAt',
    },
    pagination: {
        currentPage: 0,
        perPage: 9,
    },
    errors: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_EXERCISES_LIST_ERRORS:
            return { ...state, errors: action.errors };
        case SET_EXERCISES_LIST_ITEMS:
            return { ...state, items: action.items };
        case SET_EXERCISES_LIST_PAGINATION:
            return { ...state, pagination: action.pagination };
        case SET_EXERCISES_LIST_SORT:
            return { ...state, sort: { ...state.sort, applied: action.sort } };
        case SET_EXERCISES_LIST_LOADING:
            return { ...state, loading: action.payload };
        case CLEAR_EXERCISES_LIST:
            return initialState;
        default:
            return state;
    }
}
