export default function deepMerge<ObjectType extends Record<string, unknown>>(
    originalObject: Partial<ObjectType>,
    newObject: Partial<ObjectType>
): Partial<ObjectType> {
    return Object.entries(originalObject).reduce((acc, [key, value]) => {
        if (newObject[key]) {
            const newObjectValue = newObject[key];
            if (!!value && !!newObjectValue) {
                if (typeof value === 'object' && typeof newObjectValue === 'object') {
                    return {
                        ...acc,
                        [key]: deepMerge(value, newObjectValue),
                    };
                }
            }

            return {
                ...acc,
                [key]: newObject[key],
            };
        }

        return {
            ...acc,
            [key]: value,
        };
    }, {});
}
