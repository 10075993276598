import { setNewsletter } from '../../../../communication/forms';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';

export const setNewsletterToSubscribe = (data) => async (dispatch) => {
    dispatch(setNewsletterToSubscribeStart());
    try {
        const res = await setNewsletter(data);

        if (res.status === 201) {
            dispatch(setNewsletterToSubscribeSuccess());
        }
    } catch (error) {
        dispatch(setNewsletterToSubscribeError(error));
    }
};

export const setNewsletterToSubscribeClearSuccess = () => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.subscribe,
        entitiesMap.newsletter.allowedActions.clearSuccess
    ),
});

export const setNewsletterToSubscribeClearErrors = () => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.subscribe,
        entitiesMap.newsletter.allowedActions.clearErrors
    ),
});

const setNewsletterToSubscribeStart = () => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.subscribe,
        entitiesMap.newsletter.allowedActions.start
    ),
    payload: {},
});

const setNewsletterToSubscribeSuccess = () => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.subscribe,
        entitiesMap.newsletter.allowedActions.success
    ),
    payload: {},
});

const setNewsletterToSubscribeError = (data) => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.subscribe,
        entitiesMap.newsletter.allowedActions.error
    ),
    payload: data,
});
