import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { orderValidate } from '../../redux/shop/order/order.actions';
import { selectDiscountCode } from '../../redux/shop/order/order.selectors';

import DiscountCodeInput from '../molecules/cart/discount-code-input';

const validationSchema = Yup.object({
    discountCode: Yup.string(),
});

const initialValues = {
    discountCode: '',
};

export default function DiscountCodeForm() {
    const discountCode = useSelector(selectDiscountCode);
    const dispatch = useDispatch();

    const handleSubmit = ({ discountCode }, formikBag) => {
        dispatch(orderValidate({ discountCode }, { formikBag }));
    };

    const handleReset = (formikValues, formikBag) => {
        dispatch(orderValidate(initialValues, { formikBag }));
    };

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onReset={handleReset}
        >
            {() => (
                <Form>
                    <DiscountCodeInput
                        name="discountCode"
                        placeholder="(opcjonalnie)"
                        discountCode={discountCode}
                    />
                </Form>
            )}
        </Formik>
    );
}
