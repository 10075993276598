import { getFullActionName } from '../entities-map';
import { actionTypes } from '../../action-types';

const initialState = {
    data: {},
    loading: false,
    errors: null,
    success: false,
};

export const singlePostReducerWithNamedType = (
    entityType,
    method,
    actionType = '',
    payload = {}
) => {
    return function (state = initialState, action) {
        switch (action.type) {
            case getFullActionName(entityType, method, actionTypes.start):
                return {
                    ...state,
                    loading: true,
                    errors: null,
                    success: null,
                };

            case getFullActionName(entityType, method, actionTypes.success):
                return {
                    ...state,
                    data: action.payload,
                    loading: false,
                    errors: null,
                    success: true,
                };

            case getFullActionName(entityType, method, actionTypes.error):
                return {
                    ...state,
                    data: {},
                    loading: false,
                    errors: true,
                    success: false,
                };

            case getFullActionName(entityType, method, actionTypes.clearData):
                return {
                    ...initialState,
                };

            case getFullActionName(
                entityType,
                method,
                actionTypes.clearSuccess
            ):
                return {
                    ...state,
                    success: null,
                };

            case getFullActionName(entityType, method, actionTypes.clearErrors):
                return {
                    ...state,
                    errors: null,
                };

            default:
                return state;
        }
    };
};
