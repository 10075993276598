import React from 'react';

import { wrapper, icon, text } from './discount-label.module.scss';
import DiscountIcon from '../../../assets/images/svg/discount-icon.svg';

interface IDiscountLabelProps {
    className?: string;
    label: string;
}

const DiscountLabel: React.FC<IDiscountLabelProps> = ({ className = '', label }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <div className={icon}>
                <DiscountIcon />
            </div>
            <p className={text}>{label}</p>
        </div>
    );
};

export default DiscountLabel;
