import React from 'react';
import moment from 'moment';

import {
    additionalSingleMovieHolder,
    breakTime,
    btn,
    durationTime,
    gatsbyImageWrapper,
    icon,
    listItem,
    playerItemInfo,
    playIcon,
    time,
    title,
    ratioClass,
} from './movie-additional-training-list/play-list.module.scss';
import Play from '../../../assets/images/svg/small-play-icon.svg';
import TimeIcon from '../../../assets/images/svg/time.svg';
import { config } from '../../../config';
import { humanizedSeconds } from '../../../communication/utils';

import RatioImage from '../ratio-image';

const PlayerListItem = ({
    listType,
    currentListType,
    index,
    activeVideoIndex,
    training,
    playVideo,
    currentDate,
}) => {
    const isActiveVideo = () => {
        return activeVideoIndex === index && currentListType === listType;
    };

    const isAdditionalFromFuture = () => {
        return (
            moment(currentDate).isAfter(moment()) &&
            listType === config.personalTraining.typesMap.additionals.key
        );
    };

    return (
        <>
            <button
                className={`${listItem} ${btn}`}
                onClick={() => playVideo(index, listType)}
                disabled={isAdditionalFromFuture()}
            >
                <div className={additionalSingleMovieHolder}>
                    {!isAdditionalFromFuture() && (
                        <>
                            <RatioImage
                                src={training.coverUri}
                                className={gatsbyImageWrapper}
                                ratioClass={ratioClass}
                                alt="Miniatura treningu"
                            />
                            {isActiveVideo() && <Play className={playIcon} />}
                        </>
                    )}
                </div>

                <div className={playerItemInfo}>
                    <div className={title} title={training.name}>
                        {training.name}
                    </div>

                    <div className={time}>
                        <TimeIcon className={icon} />

                        <span className={durationTime}>
                            {humanizedSeconds(training.videoDuration)}
                        </span>
                    </div>
                </div>
            </button>

            {training.breakTime > 0 && (
                <div className={`${listItem}`}>
                    <div className={breakTime}>
                        Przerwa {humanizedSeconds(training.breakTime)}
                        <TimeIcon className={icon} />
                    </div>
                </div>
            )}
        </>
    );
};
export default PlayerListItem;
