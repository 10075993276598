import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { nameText, wrapper, image, infoWrapper, iconWrapper } from './person.module.scss';

import IconLink from '../../../../atoms/icon-link/icon-link';

export const Person = ({ data, socialData = [] }) => {
    const { name, fluidImage, alt } = data;

    return (
        <div className={wrapper}>
            <div>
                <GatsbyImage image={fluidImage} className={image} alt={alt} />
            </div>
            <div className={infoWrapper}>
                <span className={nameText}>{name}</span>
                <div className={iconWrapper}>
                    {socialData &&
                        socialData.map((socialItem) => {
                            return (
                                <div key={`social-icon--${socialItem.Icon}`}>
                                    <IconLink data={socialItem} />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default Person;
