import { combineReducers } from 'redux';

import { genericListReducer } from '../generic-list/generic-list.reducer';
import isPartnersZoneEnabled from '../../utills/partners-zone/is-partners-zone-enabled';

export const PARTNERS_ZONE = '[PARTNERS_ZONE]';
export const POOL = 'pool';
export const OWN = 'own';

const partnersZoneReducer = isPartnersZoneEnabled()
    ? combineReducers({
          [POOL]: genericListReducer(PARTNERS_ZONE, POOL),
          [OWN]: genericListReducer(PARTNERS_ZONE, OWN),
      })
    : null;

export default partnersZoneReducer;
