import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    title as titleClass,
    listCounter as listCounterClass,
    courseIsChosen,
    hoverNotCurrent,
    wrapper,
} from './category-course-pill.module.scss';

import { setChosenCategory } from '../../../redux/current-course/actions/current-course.actions';

const CategoryCoursePill = ({ className = '', id, title, listCounter }) => {
    const currentCategoryId = useSelector((state) => {
        return state.currentCourse.currentCourse.chosenCategoryData.id;
    });
    const dispatch = useDispatch();

    return (
        <button
            className={`
                ${wrapper}
                ${currentCategoryId === id && courseIsChosen} 
                ${currentCategoryId !== id && hoverNotCurrent}
                ${className}
             `}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            onClick={() => dispatch(setChosenCategory(id))}
        >
            <div className={titleClass}>{title}</div>
            <div className={listCounterClass}>Filmy: {listCounter}</div>
        </button>
    );
};

export default CategoryCoursePill;
