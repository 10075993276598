import { config } from '../../config';

const type = config.subscriptionMap.attributes.accountType.values;

const getProfiles = (state) => state.profile || {};

const getProfileList = (state) => getProfiles(state).list || {};

const getProfileListAsArray = (state) =>
    Object.values(getProfileList(state)) || [];

const getProfileById = (state, id) => getProfileList(state)[id] || null;

const getCurrentProfileId = (state) => getProfiles(state).currentId;

const getCurrentProfile = (state) =>
    getProfileById(state, getCurrentProfileId(state));

const getProfileAvatarUriByProfileId = (state, id) => {
    const profile = getProfileById(state, id);
    return profile && profile.avatarUri ? profile.avatarUri : '';
};

const isMoreThanOneProfile = (state) => {
    const list = getProfileListAsArray(state);
    return list.length > 1;
};

const accountType = (state) => {
    if (getProfileListAsArray(state).length) {
        return isMoreThanOneProfile(state)
            ? type.pair.value
            : type.single.value;
    }
    return null;
};

const profileFilledSurvey = (state, id) => {
    const surveyList = state.user.userData?.surveyProfiles;
    return (
        Array.isArray(surveyList) &&
        surveyList.some((item) => item.profileId === id)
    );
};

const getCurrentProfileUnreadMessagesCount = (state) => {
    const profile = getProfileById(state, getCurrentProfileId(state));
    return profile && profile.unreadMessagesCount
        ? profile.unreadMessagesCount
        : 0;
};

export {
    getProfiles,
    getCurrentProfileId,
    getProfileList,
    getCurrentProfile,
    getProfileListAsArray,
    getProfileById,
    getProfileAvatarUriByProfileId,
    isMoreThanOneProfile,
    accountType,
    profileFilledSurvey,
    getCurrentProfileUnreadMessagesCount,
};
