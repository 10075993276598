import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { wrapper, white } from './empty-screen.module.scss';
import { config } from '../../../../../config';
import { getCurrentProfile } from '../../../../../redux/profile/profile.selectors';
import { selectCurrentMovies } from '../../../../../redux/personal-training/personal-training.selectors';

import Title from '../../../../atoms/title';
import Button from '../../../../atoms/button';

const { personalTraining } = config;

const EmptyScreen = ({ toggleListType }) => {
    const { currentProfile, exercise, additional } = useSelector((state) => {
        return {
            currentProfile: getCurrentProfile(state),
            exercise: selectCurrentMovies(state, personalTraining.typesMap.exercises.key),
            additional: selectCurrentMovies(state, personalTraining.typesMap.additionals.key),
        };
    });

    const [hasOnlyAdditionals, setHasOnlyAdditionals] = useState(false);

    useEffect(() => {
        if (additional && exercise) {
            setHasOnlyAdditionals(exercise.length === 0 && additional.length > 0);
        }
    }, [exercise, additional]);

    return (
        <div className={wrapper}>
            <div>
                <Title className={white} Tag={'h3'}>
                    {currentProfile && currentProfile.firstName}, dziś masz wolne od treningu!
                </Title>
            </div>
            {hasOnlyAdditionals && (
                <>
                    <div className={white}>
                        <span>
                            w zakładce "Dodatkowe" znajdziesz kolejne filmy, które dla Ciebie
                            przygotowaliśmy.
                        </span>
                    </div>

                    <div>
                        <Button
                            onClick={() => {
                                toggleListType(personalTraining.typesMap.additionals.key);
                            }}
                            color={'white'}
                        >
                            PRZEJDŹ DO SEKCJI DODATKOWE
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default EmptyScreen;
