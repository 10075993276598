import React from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import {
    input,
    textarea,
    additionalAction,
    additionalActionsWrapper,
    additionalActionText,
    form,
    loading,
    submitButton,
    submitButtonHolder,
    paperClip,
} from './messenger.module.scss';
import Paperclip from '../../../assets/images/svg/paperclip.svg';
import { config } from '../../../config';
import { SendMessage } from '../../../redux/messenger/messages/send-actions';
import { setAdditionalAttachmentsToSend } from '../../../redux/messenger/additional-attachments-to-send/actions';
import { toBase64 } from '../../../utills/to-base64';

import Input from '../../atoms/form-poc/input';
import Button from '../../atoms/button';
import MessengerTree from '../messenger-tree/messenger-tree';
import AttachmentsToSendList from '../attachments-to-send-list/attachments-to-send-list';
import Loader from '../../atoms/loader';
import TextError from '../../atoms/form-poc/text-error';

const {
    form: { required },
} = config.messages;

const { formsStatusMap } = config;

const initialValues = {
    content: '',
};

const validationSchema = Yup.object({
    content: Yup.string().required(required),
});

const Messenger = ({ className = '', isSearchMode }) => {
    const dispatch = useDispatch();

    const handleSubmit = (data, formikBag) => {
        const dataToSend = {
            content: data.content,
        };
        dispatch(SendMessage(dataToSend, formikBag));
    };

    const openFileOption = () => {
        const inputElement = document.getElementById('myFile');
        inputElement.click();
    };

    const onChangeInput = async (event) => {
        event.preventDefault();
        const { files } = event.target;
        const arrayFiles = Object.keys(files).map((key) => files[key]);
        const mappedArray = [];

        for (let i = 0; i < arrayFiles.length; i++) {
            const { name, size, type } = arrayFiles[i];
            const res = {
                name,
                type,
                size,
                blob: await toBase64(arrayFiles[i]).then((response) => {
                    return response.content;
                }),
            };
            mappedArray.push(res);
        }
        dispatch(setAdditionalAttachmentsToSend(mappedArray));
    };

    return (
        <div className={className}>
            {!isSearchMode && (
                <>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                    >
                        {(formik) => {
                            const formLoading = formsStatusMap.loading === formik.status;
                            return (
                                <Form className={`${form} ${formLoading ? loading : ''}`}>
                                    {formLoading && (
                                        <Loader fullContainer={true} noBackground={true} />
                                    )}
                                    <Input
                                        name="content"
                                        as="textarea"
                                        placeholder="Dodaj swoją wiadomość"
                                        inputClassname={textarea}
                                    />
                                    {formik.errors.global && (
                                        <TextError>{formik.errors.global}</TextError>
                                    )}
                                    <div className={additionalActionsWrapper}>
                                        <div className={additionalAction}>
                                            <input
                                                className={input}
                                                type="file"
                                                name="file"
                                                id="myFile"
                                                multiple={true}
                                                onChange={onChangeInput}
                                            />
                                            <div
                                                className={additionalActionText}
                                                onClick={openFileOption}
                                                onKeyUp={openFileOption}
                                                role="button"
                                                tabIndex={0}
                                            >
                                                Dodaj załącznik
                                                <Paperclip className={paperClip} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={submitButtonHolder}>
                                        <Button color={'yellow'} className={submitButton}>
                                            Wyślij wiadomość
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    <AttachmentsToSendList />
                    <MessengerTree />
                </>
            )}
        </div>
    );
};

export default Messenger;
