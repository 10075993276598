import { types as apiTypes } from '../api/api.actions';
import { addModalAction } from '../actions/actions-modals';

const GAMIFICATION = '[GAMIFICATION]';

export const types = {
    INIT_GAMIFICATION: `${GAMIFICATION} init`,
    POST_TRANSACTION: `${GAMIFICATION} post_transaction`,
    POST_TRANSACTION_VALIDATE: `${GAMIFICATION} post_transaction_validate`,
    UPDATE_TRANSACTIONS: `${GAMIFICATION} update_transactions [SSE]`,
    CLEAR_GAMIFICATION: `${GAMIFICATION} clear`,
    RELOAD_GAMIFICATION: `${GAMIFICATION} reload`,

    FETCH_POST_TRANSACTIONS: `${GAMIFICATION} transactions ${apiTypes.API_REQUEST}`,
    FETCH_POST_TRANSACTIONS_SUCCESS: `${GAMIFICATION} transactions ${apiTypes.API_SUCCESS}`,
    FETCH_POST_TRANSACTIONS_ERROR: `${GAMIFICATION} transactions ${apiTypes.API_FAIL}`,

    FETCH_POST_TRANSACTIONS_VALIDATE: `${GAMIFICATION} transactions_validate ${apiTypes.API_REQUEST}`,
    FETCH_POST_TRANSACTIONS_VALIDATE_SUCCESS: `${GAMIFICATION} transactions_validate ${apiTypes.API_SUCCESS}`,
    FETCH_POST_TRANSACTIONS_VALIDATE_ERROR: `${GAMIFICATION} transactions_validate ${apiTypes.API_FAIL}`,

    SET_ALERT: `${GAMIFICATION} set_alert`,
    CLEAR_ALERT: `${GAMIFICATION} clear_alert`,
    CLEAR_ALL_ALERTS: `${GAMIFICATION} clear_all_alerts`,

    SET_FORBIDDEN_REASON_ID: `${GAMIFICATION} set_forbidden_reason_id`,
};

export const initGamification = () => {
    return {
        type: types.INIT_GAMIFICATION,
    };
};

export const postTransaction = (payload) => {
    return {
        type: types.POST_TRANSACTION,
        payload,
    };
};

export const postTransactionValidate = (payload) => {
    return {
        type: types.POST_TRANSACTION_VALIDATE,
        payload,
    };
};

export const setAlert = (payload) => {
    return {
        type: types.SET_ALERT,
        payload,
    };
};

export const clearAlert = (payload) => {
    return {
        type: types.CLEAR_ALERT,
        payload,
    };
};

export const clearAllAlerts = () => {
    return {
        type: types.CLEAR_ALERT,
    };
};

export const clearGamification = () => {
    return {
        type: types.CLEAR_GAMIFICATION,
    };
};

export const viewBuySubscriptionModal = (contentKey) => {
    return addModalAction({
        modalKey: 'GAMIFICATION_BUY_SUBSCRIPTION_MODAL',
        modalProps: {
            contentKey,
        },
    });
};

export const viewAlreadySentModal = () => {
    return addModalAction({
        modalKey: 'GAMIFICATION_ALREADY_SENT_MODAL',
    });
};

export const reloadGamification = () => {
    return {
        type: types.RELOAD_GAMIFICATION,
    };
};

export const setForbiddenReasonId = (payload) => {
    return {
        type: types.SET_FORBIDDEN_REASON_ID,
        payload,
    };
};
