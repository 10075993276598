import React, { useEffect, useRef, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { getImage, withArtDirection } from 'gatsby-plugin-image';

import {
    title,
    form,
    hero,
    content,
    loading as loadingClass,
    description,
    inputBox,
    success as successClass,
    formGroup,
    globalErrors,
    counter,
    ingredients,
    switcher,
    addRecipe,
    successContent,
    addIngredientButton,
    addStepButton,
    preparations,
    successButtons,
} from './recipe-add.module.scss';
import { config } from '../config';
import {
    createSingleRecipeAction,
    createSingleRecipeClearSuccessAction,
} from '../redux/recipes/single/actions/create-single-recipe';
import {
    editSingleRecipeAction,
    editSingleRecipeClearSuccessAction,
} from '../redux/recipes/single/actions/edit-single-recipe';
import { getSingleRecipeAction } from '../redux/recipes/single/actions/get-single-recipe';
import { getCurrentProfileId } from '../redux/profile/profile.selectors';
import { getAbsolutePath } from '../routes';
import SuccessEmotic from '../assets/images/svg/success-emotics.svg';

import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import Input from '../components/atoms/form-poc/input';
import Button from '../components/atoms/button';
import FileInputField from '../components/molecules/form/file-input-field';
import Separator from '../components/atoms/separator';
import IngredientFieldArray from '../components/molecules/ingredient-field-array';
import StepsFieldArray from '../components/molecules/steps-field-array';
import PillSwitcher from '../components/molecules/pill-switcher';
import Title from '../components/atoms/title';
import Emotic from '../components/atoms/emotic';
import Loader from '../components/atoms/loader';
import Counter from '../components/atoms/counter';

const {
    form: { required },
} = config.messages;

const ingredientKey = 'ingredient';
const quantityKey = 'quantity';
const unitKey = 'unit';

const initialValues = {
    canBePublic: 0,
    recipePhoto: [],
    name: '',
    portions: 1,
    ingredients: [{ [ingredientKey]: '', [quantityKey]: '', [unitKey]: '' }],
    preparationSteps: [''],
};

const validationSchema = Yup.object({
    canBePublic: Yup.number().required(required),
    name: Yup.string().required(required),
    portions: Yup.number().required(required),
    ingredients: Yup.array().of(
        Yup.object().shape({
            [ingredientKey]: Yup.object().required(required),
            [quantityKey]: Yup.number().required(required),
            [unitKey]: Yup.object().required(required),
        })
    ),
    preparationSteps: Yup.array().of(Yup.string().required(required)),
});

const canBePublicPills = [
    { label: 'Prywatny', value: 0 },
    { label: 'Publiczny', value: 1 },
];

const RecipeAdd = ({
    id,
    profileId,
    recipe,
    loading,
    createLoading,
    createSuccess,
    editLoading,
    editSuccess,
    dispatch,
}) => {
    const [success, setSuccess] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState(initialValues);

    const photoInputRef = useRef(null);

    const { heroImg, heroImgMobile } = useStaticQuery(query);

    const bannerData = {
        fluidImg: withArtDirection(getImage(heroImg), [
            {
                image: getImage(heroImgMobile),
                media: `(max-width: 420px)`,
            },
        ]),
        title: id ? `Edytuj przepis` : `Dodaj własny przepis`,
        subtitle: `Zainspiruj innych i dodawaj własne przepisy, dostępne dla innych!`,
    };

    const handleCanBePublicSwitch = (formik, activePill) => {
        formik.setFieldValue('canBePublic', activePill.value);
    };

    const handleFormReset = (formik) => {
        formik.resetForm();
        formik.setStatus(config.formsStatusMap.idle);
        setSuccess(false);
    };

    const handleSubmit = (formValues, formikBag) => {
        if (!formValues.recipePhoto.length) {
            window.scrollTo(0, photoInputRef.current.offsetTop - 200);
            formikBag.setFieldError('recipePhoto', required);
            return;
        }
        const ingredients = formValues.ingredients.map(({ ingredient, quantity, unit }) => {
            return {
                ingredientId: ingredient.value,
                quantity,
                unitId: unit.value,
            };
        });

        const base64 = formValues.recipePhoto[0].content;
        const data = {
            ownerProfileId: profileId,
            canBePublic: formValues.canBePublic,
            name: formValues.name,
            ingredients: ingredients,
            preparationSteps: formValues.preparationSteps,
            portions: formValues.portions,
            ...(base64 ? { coverBase64: base64 } : {}),
        };

        if (id) {
            dispatch(editSingleRecipeAction(id, data, formikBag));
        } else {
            dispatch(createSingleRecipeAction(data, formikBag));
        }
    };

    const getActivePill = (formik) => {
        return canBePublicPills.find((pill) => {
            return pill.value === formik.values.canBePublic;
        });
    };

    const handleCounterChange = (formik, isAdding) => {
        const portions = formik.values.portions;
        formik.setFieldValue('portions', isAdding ? portions + 1 : portions - 1);
    };

    const renderContent = (formik) => {
        if (id && (loading || !recipe)) {
            return <Loader />;
        }

        if (success) {
            return (
                <div className={successClass}>
                    <div className={successContent}>
                        <Emotic emotic={SuccessEmotic} />
                        <Title>{id ? `Zmiany zostały zapisane` : `Przepis został dodany`}</Title>
                    </div>
                    <div className={successButtons}>
                        {id ? null : (
                            <Button
                                type="button"
                                color="yellow"
                                onClick={() => handleFormReset(formik)}
                            >
                                Dodaj kolejny przepis
                            </Button>
                        )}
                        <Link to={getAbsolutePath('APP_YOUR_RECIPES')}>
                            <Button type="button" color="blank">
                                Wróć do strony z przepisami
                            </Button>
                        </Link>
                    </div>
                </div>
            );
        }

        return (
            <Form className={`${form} ${createLoading || editLoading ? loadingClass : ''}`}>
                <div className={formGroup}>
                    <h2 className={title}>Dostępność</h2>
                    <p className={description}>
                        Dodany przez Ciebie przepis będzie widoczny w&nbsp;zakładce "Twoje
                        przepisy". Jeśli chcesz, żeby przepis został sprawdzony przez
                        administratora, a&nbsp;następnie trafił do&nbsp;publicznej bazy przepisów
                        zmień jego status, przełącznikiem znajdującym się poniżej
                    </p>
                    <div className={inputBox}>
                        <div className={switcher}>
                            <PillSwitcher
                                options={canBePublicPills}
                                activeOption={getActivePill(formik)}
                                onChange={(activePill) =>
                                    handleCanBePublicSwitch(formik, activePill)
                                }
                            />
                        </div>
                    </div>
                    <Separator />
                </div>
                <div className={formGroup} ref={photoInputRef}>
                    <h2 className={title}>Zdjęcie</h2>
                    <div className={inputBox}>
                        <FileInputField
                            name="recipePhoto"
                            fileTypes={config.fileTypesMap.images}
                            multiple={false}
                            imgOnly={true}
                            label="Dodaj zdjęcie posiłku"
                        />
                    </div>
                    <Separator />
                </div>
                <div className={formGroup}>
                    <h2 className={title}>Nazwa dania</h2>
                    <div className={inputBox}>
                        <Input name="name" placeholder="np. Brukselki w sosie cytrynowym" />
                    </div>
                    <Separator />
                </div>
                <div className={formGroup}>
                    <h2 className={title}>Ilość porcji</h2>
                    <div className={inputBox}>
                        <Counter
                            min={1}
                            className={counter}
                            value={formik.values.portions}
                            onAddFunc={() => handleCounterChange(formik, true)}
                            onSubtractFunc={() => handleCounterChange(formik)}
                        />
                    </div>
                    <Separator />
                </div>
                <div className={formGroup}>
                    <h2 className={title}>Składniki</h2>
                    <div className={inputBox}>
                        <IngredientFieldArray
                            className={ingredients}
                            addButtonClass={addIngredientButton}
                            formik={formik}
                            name="ingredients"
                            ingredientName={ingredientKey}
                            quantityName={quantityKey}
                            unitName={unitKey}
                            ingredientLabel="Składnik"
                            quantityLabel="Wartość"
                            unitLabel="Jednostka"
                            ingredientPlaceholder="Wybierz składnik z listy"
                            quantityPlaceholder="Np. 200"
                            unitPlaceholder="Np. g"
                        />
                    </div>
                    <Separator />
                </div>
                <div className={formGroup}>
                    <h2 className={title}>Sposób przygotowania</h2>
                    <div className={inputBox}>
                        <StepsFieldArray
                            formik={formik}
                            name="preparationSteps"
                            className={preparations}
                            addButtonClass={addStepButton}
                        />
                    </div>
                    <Separator />
                </div>

                {formik.errors.global && <div className={globalErrors}>{formik.errors.global}</div>}

                <Button color="yellow">Zapisz przepis</Button>
            </Form>
        );
    };

    useEffect(() => {
        if (id && !recipe) {
            dispatch(getSingleRecipeAction(id));
        }
    }, [id, recipe, dispatch]);

    useEffect(() => {
        const getInitialValues = () => {
            let ingredients = [{ [ingredientKey]: '', [quantityKey]: '', [unitKey]: '' }];
            if (recipe.ingredients.length > 0) {
                ingredients = recipe.ingredients.map((ingredient) => {
                    return {
                        [ingredientKey]: {
                            label: ingredient.name,
                            value: ingredient.id,
                        },
                        [quantityKey]: ingredient.quantity,
                        [unitKey]: {
                            label: ingredient.unit,
                            value: ingredient.unitId,
                        },
                    };
                });
            }
            return {
                canBePublic: recipe.canBePublic,
                recipePhoto: [{ uri: recipe.coverUri, name: '' }],
                name: recipe.name,
                portions: recipe.portions,
                ingredients: ingredients,
                preparationSteps: recipe.preparationSteps.map((step) => step.content),
            };
        };

        if (id && recipe) {
            setInitialFormValues(getInitialValues());
        }
    }, [id, recipe]);

    useEffect(() => {
        if (createSuccess || editSuccess) {
            window.scrollTo(0, 250);
            setSuccess(true);
            dispatch(createSingleRecipeClearSuccessAction());
            dispatch(editSingleRecipeClearSuccessAction());
        }
    }, [createSuccess, editSuccess, dispatch]);

    return (
        <Main className={addRecipe} transparentHeader={true}>
            <MainBanner bannerData={bannerData} className={hero} />
            <Formik
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formik) => {
                    return <div className={content}>{renderContent(formik)}</div>;
                }}
            </Formik>
        </Main>
    );
};

const query = graphql`
    {
        heroImg: file(relativePath: { eq: "hero-add-recipe.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        heroImgMobile: file(relativePath: { eq: "hero-add-recipe-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
    }
`;

const mapStateToProps = (state, { id }) => {
    const recipe = state.recipes.single.get.data;
    return {
        recipe: Number(id) === recipe.id ? recipe : null,
        profileId: getCurrentProfileId(state),
        loading: state.recipes.single.get.loading,
        createLoading: state.recipes.single.create.loading,
        createSuccess: state.recipes.single.create.success,
        editLoading: state.recipes.single.edit.loading,
        editSuccess: state.recipes.single.edit.success,
    };
};

export default connect(mapStateToProps)(RecipeAdd);
