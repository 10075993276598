import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';

import { labelBox, labelText } from './input.module.scss';

import Select from './select';
import TextError from './text-error';

const InputSelect = ({
    options,
    name,
    label,
    containerClass,
    isDisabled,
    noOptionsMessage,
    placeholder,
    allowUserInput,
    isSearchable,
    isMulti,
    onChange,
}) => {
    const handleChange = (selectValue, action, form) => {
        let valueObj = selectValue;

        if (typeof selectValue === 'string') {
            valueObj = { value: '', label: selectValue };
        } else if (!selectValue && isMulti) {
            valueObj = [];
        }

        if (action.action !== 'input-blur' && action.action !== 'menu-close') {
            form.setFieldValue(name, valueObj);
        }

        onChange(valueObj);
    };

    return (
        <div className={containerClass}>
            <div className={labelBox}>
                {label && (
                    <label htmlFor={name} className={labelText}>
                        {label}
                    </label>
                )}
            </div>
            <Field name={name}>
                {({ form, field }) => {
                    return (
                        <Select
                            defaultValue={field.value}
                            {...(isMulti
                                ? {}
                                : {
                                      value: field.value.label ? field.value : null,
                                  })}
                            name={name}
                            options={options}
                            onChange={(option, action) => {
                                handleChange(option, action, form);
                            }}
                            onInputChange={(customValue, action) => {
                                allowUserInput && handleChange(customValue, action, form);
                            }}
                            isDisabled={isDisabled}
                            noOptionsMessage={() => noOptionsMessage}
                            placeholder={placeholder}
                            isSearchable={isSearchable}
                            isMulti={isMulti}
                        />
                    );
                }}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    );
};

InputSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    containerClass: PropTypes.string,
    isDisabled: PropTypes.bool,
    noOptionsMessage: PropTypes.string,
    placeholder: PropTypes.string,
    allowUserInput: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
};

InputSelect.defaultProps = {
    label: '',
    options: [],
    containerClass: '',
    isDisabled: false,
    noOptionsMessage: 'Brak wyników',
    placeholder: 'Wybierz z listy',
    allowUserInput: false,
    isSearchable: true,
    isMulti: false,
    onChange: () => {},
};

export default InputSelect;
