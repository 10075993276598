import React, { useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import AnimateHeight from 'react-animate-height';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
    imageWrapper,
    titleText,
    imageContent,
    descriptionText,
    imageDescription,
    banner,
    imageTitle,
    notActive,
    linkPass,
    linkPassBox,
    wip,
    lockIcon,
} from './link-banner.module.scss';
import LockIcon from '../../assets/images/svg/icon-lock.svg';
import { forbiddenClick } from '../../redux/rbac/rbac.actions';
import { entitiesArray } from '../../rbac/permissions';

import LinkPass from '../atoms/link-pass';

const LinkBanner = ({
    to,
    image,
    title,
    imgTitle,
    description,
    className,
    imgContentClass,
    imgClass,
    onImageDescription,
    disabled,
    WIP,
    WIPMessage,
    permissionEntity,
}) => {
    const dispatch = useDispatch();

    const [linkHeight, setLinkHeight] = useState(0);

    const getTag = () => {
        if (WIP) {
            return 'div';
        }
        if (disabled) {
            return 'button';
        }
        return Link;
    };

    const getLinkName = () => {
        if (WIP) {
            return 'Prace w toku!';
        }
        if (disabled) {
            return 'Brak dostępu';
        }
        return 'Przejdź';
    };

    const Tag = getTag();

    return (
        <Tag
            className={`${banner} ${className} ${disabled && !WIP && notActive} ${WIP && wip}`}
            onMouseEnter={() => setLinkHeight('auto')}
            onMouseLeave={() => setLinkHeight(0)}
            {...(disabled && !WIP
                ? { onClick: () => dispatch(forbiddenClick(permissionEntity)) }
                : { to: to })}
        >
            <div className={imageWrapper}>
                {disabled && !WIP && <LockIcon className={lockIcon} />}
                <GatsbyImage
                    image={image}
                    alt={title}
                    style={{ display: 'block' }}
                    className={imgClass}
                />
                <div className={`${imageContent} ${imgContentClass}`}>
                    <h3 className={imageTitle}>{imgTitle || title}</h3>
                    {onImageDescription && (
                        <p className={imageDescription}>{WIP ? WIPMessage : description}</p>
                    )}
                    <div className={linkPassBox}>
                        <AnimateHeight duration={300} height={linkHeight}>
                            <LinkPass className={linkPass} name={getLinkName()} />
                        </AnimateHeight>
                    </div>
                </div>
            </div>
            {!onImageDescription && (
                <>
                    <h3 className={titleText}>{title}</h3>
                    <div className={descriptionText}>{WIP ? WIPMessage : description}</div>
                </>
            )}
        </Tag>
    );
};

LinkBanner.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    to: PropTypes.string,
    title: PropTypes.string,
    imgTitle: PropTypes.node,
    description: PropTypes.node,
    className: PropTypes.string,
    imgContentClass: PropTypes.string,
    imgClass: PropTypes.string,
    onImageDescription: PropTypes.bool,
    disabled: PropTypes.bool,
    WIP: PropTypes.bool,
    WIPMessage: PropTypes.string,
    permissionEntity: PropTypes.oneOf(entitiesArray()),
};

LinkBanner.defaultProps = {
    to: '',
    title: '',
    imgTitle: '',
    description: '',
    className: '',
    imgContentClass: '',
    imgClass: '',
    onImageDescription: false,
    disabled: false,
    WIP: false,
    WIPMessage: '',
    permissionEntity: null,
};

export default LinkBanner;
