import { config } from '../../config';

import {
    ADDRESSES,
    types,
    setAddressesAction,
    restoreInitialStateAction,
} from './addresses.actions.js';
import { statusIdle } from '../ui/ui.actions';

const {
    GET_ADDRESSES,
    FETCH_ADDRESSES_REQUEST,
    FETCH_ADDRESSES_SUCCESS,
    FETCH_ADDRESSES_FAIL,
    CLEAR_ADDRESSES,
} = types;

const addressesMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload } = action;
    next(action);

    switch (type) {
        case GET_ADDRESSES:
            const fetchAddressesConfig = {
                types: [
                    FETCH_ADDRESSES_REQUEST,
                    FETCH_ADDRESSES_SUCCESS,
                    FETCH_ADDRESSES_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.addresses.get.all,
                        withCredentials: true,
                    },
                },
                meta: {
                    entity: ADDRESSES,
                },
            };
            dispatch(fetchAddressesConfig);
            break;

        case FETCH_ADDRESSES_SUCCESS:
            dispatch(setAddressesAction(payload.data.items));
            break;

        case CLEAR_ADDRESSES:
            dispatch(statusIdle(ADDRESSES));
            dispatch(restoreInitialStateAction());
            break;

        default:
            break;
    }
};

export default addressesMiddleware;
