import React from 'react';
import { Link } from 'gatsby';

import { list, listActiveItem, listItem, underLine } from './trening-switcher.module.scss';

const TreningTabSwitcher = ({ labels }) => {
    return (
        <ul className={list}>
            {labels &&
                labels.map((label) => {
                    return (
                        <li className={`${listItem}`} key={`label--${label.id}`}>
                            <Link to={label.link} activeClassName={listActiveItem}>
                                <>
                                    {label.name}
                                    <div className={underLine} />
                                </>
                            </Link>
                        </li>
                    );
                })}
        </ul>
    );
};

export default TreningTabSwitcher;
