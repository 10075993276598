import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loader,
    modal,
    separator,
    title,
    header,
    subtitle,
    content,
    img,
    container,
    description,
    editButton,
    ratioClass,
} from './meal-modal.module.scss';
import PencilIcon from '../../../assets/images/svg/icon-pencil.svg';
import { addModalAction, removeModalAction } from '../../../redux/actions/actions-modals';
import { getPos } from '../../../redux/meal-planer/meal-planer.actions';
import { selectPositionById } from '../../../redux/meal-planer/meal-planer.selectors';
import { getImgUriFromMedia } from '../../../utills/get-img-uri-from-media';

import CustomModal from '../../../templates/custom-modal';
import Loader from '../../atoms/loader';
import Separator from '../../atoms/separator';
import AddMealModal from './add-meal-modal';
import RatioImage from '../../atoms/ratio-image';

const MealModal = ({ id, modalId, onSubmitEditMeal }) => {
    const dispatch = useDispatch();
    const position = useSelector((state) => selectPositionById(state, id));

    const imgUri = position.meal ? getImgUriFromMedia(position.meal) : null;

    const handleEditClick = () => {
        dispatch(
            addModalAction({
                renderComponent: (modalId) => (
                    <AddMealModal
                        modalId={modalId}
                        meal={position.meal}
                        onSubmitEditMeal={onSubmitEditMeal}
                    />
                ),
            })
        );
        dispatch(removeModalAction(modalId));
    };

    useEffect(() => {
        if (!position || !position.meal) dispatch(getPos(id, { modalId }));
    }, [dispatch, id, position, modalId]);

    return (
        <CustomModal className={modal} modalId={modalId} imageOnTop={!!imgUri}>
            {position.meal ? (
                <div className={container}>
                    {imgUri && (
                        <RatioImage
                            src={imgUri}
                            ratioClass={ratioClass}
                            className={img}
                            alt="Miniatura posiłku"
                        />
                    )}
                    <div className={content}>
                        <div className={header}>
                            <h2 className={title}>{position.meal.name}</h2>
                            <button onClick={handleEditClick} className={editButton}>
                                <PencilIcon /> Edytuj
                            </button>
                        </div>
                        <h3 className={subtitle}>Szczegóły</h3>
                        <Separator className={separator} />
                        <p className={description}>
                            {position.meal.description || 'Brak szczegółów'}
                        </p>
                    </div>
                </div>
            ) : (
                <Loader className={loader} />
            )}
        </CustomModal>
    );
};

MealModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    onSubmitEditMeal: PropTypes.func,
};

MealModal.defaultProps = {
    onSubmitEditMeal: () => {},
};

export default MealModal;
