import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    DISCOUNTS,
    requestDiscount as dispatchRequestDiscount,
    requestDiscountsAll as dispatchRequestDiscountsAll,
} from '../redux/shop/discounts/discounts.actions';
import {
    selectDiscount,
    selectDiscountByBusinessMeaning,
} from '../redux/shop/discounts/discounts.selectors';
import { selectIsEntityError, selectIsEntityLoading } from '../redux/ui/ui.selectors';
import getDiscountInfo from '../utills/get-discount-info';

export const DISCOUNTS_ENABLE = process.env.DISCOUNTS_ENABLE === '1';

interface IUseProductDiscountsProps {
    productId?: number;
    businessMeaning?: number;
}

export default function useProductDiscounts({
    productId,
    businessMeaning,
}: IUseProductDiscountsProps | undefined = {}) {
    const productDiscount = useSelector(selectDiscount(productId));
    const businessMeaningDiscount = useSelector(selectDiscountByBusinessMeaning(businessMeaning));
    const isLoading = useSelector(selectIsEntityLoading(DISCOUNTS));
    const isError = useSelector(selectIsEntityError(DISCOUNTS));
    const dispatch = useDispatch();

    const discount = businessMeaning ? businessMeaningDiscount : productDiscount;

    const requestDiscountsAll = useCallback(() => {
        if (DISCOUNTS_ENABLE) {
            dispatch(dispatchRequestDiscountsAll());
        }
    }, [dispatch]);

    const requestDiscount = useCallback(() => {
        if (DISCOUNTS_ENABLE) {
            dispatch(dispatchRequestDiscount(productId));
        }
    }, [dispatch, productId]);

    return {
        ...discount,
        ...getDiscountInfo(discount),
        isLoading,
        requestDiscountsAll,
        requestDiscount,
        isEnabled: DISCOUNTS_ENABLE,
        isError,
    };
}
