// extracted by mini-css-extract-plugin
export var absoluteWrapper = "movie-group-player-module--absolute-wrapper--64y4R";
export var additionalMoviesHolder = "movie-group-player-module--additional-movies-holder--0yg0L";
export var breakThumbnail = "movie-group-player-module--breakThumbnail--VH9N5";
export var breakTimeBox = "movie-group-player-module--break-time-box--96sbn";
export var breakTimeContent = "movie-group-player-module--breakTime-content--7YSaq";
export var movieContainer = "movie-group-player-module--movie-container--uRebz";
export var movieWrapper = "movie-group-player-module--movie-wrapper--V-dXv";
export var pointsButtonWrapper = "movie-group-player-module--points-button-wrapper--gQ-0Q";
export var visible = "movie-group-player-module--visible--vXiZH";
export var wrapper = "movie-group-player-module--wrapper--X9vdJ";