export const RBAC = '[RBAC]';

export const actions = {
    SET_PERMISSIONS: `${RBAC} set permissions`,
    CLEAR_PERMISSIONS: `${RBAC} clear permissions`,
    // user triggered actions
    FORBIDDEN_CLICK: `${RBAC} forbidden_click`,
};

export const setPermissions = (permissions) => ({
    type: actions.SET_PERMISSIONS,
    payload: permissions,
});

export const clearPermissions = () => ({
    type: actions.CLEAR_PERMISSIONS,
});

// middleware actions
export const forbiddenClick = (permissionEntity, onClose) => ({
    type: actions.FORBIDDEN_CLICK,
    payload: permissionEntity,
    meta: onClose,
});
