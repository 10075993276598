import genericListMiddleware from '../generic-list/generic-list.middleware';
import { OWN, PARTNERS_ZONE, POOL } from './partners-zone.reducer';
import { types } from './partners-zone.actions';
import { config } from '../../config';
import { setAlert } from '../alerts/alerts.actions';
import { editModalAction } from '../actions/actions-modals';
import { reloadGamification } from '../gamification/gamification.actions';
import isPartnersZoneEnabled from '../../utills/partners-zone/is-partners-zone-enabled';
import { errorHandler } from '../../communication/utils';
import { genActionName, actions } from '../generic-list/generic-list.actions';

const { document } = actions;

const partnersZonePoolMiddleware = genericListMiddleware(PARTNERS_ZONE, POOL);
const partnersZoneOwnMiddleware = genericListMiddleware(PARTNERS_ZONE, OWN);

const partnersZoneMainMiddleware = (state) => (next) => (action) => {
    const { type, payload, meta, error } = action;
    const { dispatch } = state;

    next(action);

    switch (type) {
        case types.BUY_CODE:
            dispatch({
                types: [types.BUY_CODE_REQUEST, types.BUY_CODE_SUCCESS, types.BUY_CODE_FAIL],
                payload: {
                    request: {
                        url: config.endpoints.partnersZone.buy(payload),
                        method: 'POST',
                        withCredentials: true,
                    },
                },
                meta: {
                    ...meta,
                    entity: PARTNERS_ZONE,
                },
            });
            break;
        case types.BUY_CODE_SUCCESS:
            dispatch(
                editModalAction({
                    id: meta.previousAction.meta.modalId,
                    data: {
                        modalProps: {
                            code: payload.data.code,
                        },
                    },
                })
            );
            dispatch(reloadGamification());
            break;
        case types.BUY_CODE_FAIL:
            errorHandler(error).forEach((errorContent) =>
                dispatch(setAlert(errorContent, PARTNERS_ZONE))
            );
            break;
        //no default
    }
};

const partnersZoneTranslatorMiddleware = () => (next) => (action) => {
    const { type, payload } = action;

    switch (type) {
        case genActionName(`${PARTNERS_ZONE} ${POOL}`, document.set):
            next({
                ...action,
                payload: {
                    ...payload,
                    items: payload.items.sort((a, b) => a.sequence - b.sequence),
                },
            });
            break;
        default:
            next(action);
    }
};

const partnersZoneMiddleware = isPartnersZoneEnabled()
    ? [
          partnersZoneTranslatorMiddleware,
          partnersZoneMainMiddleware,
          partnersZonePoolMiddleware,
          partnersZoneOwnMiddleware,
      ]
    : [];

export default partnersZoneMiddleware;
