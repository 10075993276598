// extracted by mini-css-extract-plugin
export var animatedKeton = "cart-list-item-module--animated-keton--Bsnou";
export var basePricing = "cart-list-item-module--base-pricing--uieH-";
export var bigCart = "cart-list-item-module--big-cart--8xwWU";
export var cartListItem = "cart-list-item-module--cart-list-item--Yh6Zl";
export var count = "cart-list-item-module--count--cpNuZ";
export var counterButton = "cart-list-item-module--counter-button--Os97u";
export var discountBenefits = "cart-list-item-module--discount-benefits--gGHr0";
export var discountBenefitsBadge = "cart-list-item-module--discount-benefits-badge--rMGnf";
export var discountConditionLabel = "cart-list-item-module--discount-condition-label--VlrwE";
export var discountConditions = "cart-list-item-module--discount-conditions--SkNoY";
export var dropdownItemLayout = "cart-list-item-module--dropdown-item-layout--+2enP";
export var imgWrapper = "cart-list-item-module--img-wrapper--BZlzc";
export var number = "cart-list-item-module--number--QsR24";
export var pointsCheckbox = "cart-list-item-module--points-checkbox--mOcP2";
export var pointsWrapper = "cart-list-item-module--points-wrapper--6bBIU";
export var priceDisclaimer = "cart-list-item-module--price-disclaimer--2boCy";
export var priceText = "cart-list-item-module--price-text--yEGmb";
export var pricing = "cart-list-item-module--pricing--GXo4D";
export var ratioClass = "cart-list-item-module--ratio-class--xncqb";
export var reverse = "cart-list-item-module--reverse--NOnLJ";
export var thrash = "cart-list-item-module--thrash--AUEXw";
export var title = "cart-list-item-module--title--QYmWg";