import React from 'react';
import { Link } from 'gatsby';

import { content, titleText, container } from './success-message.module.scss';

import Button from '../atoms/button';

const SuccessMessage = ({ IconSvg, imgSrc, title = '', text = '', to, buttonText = '' }) => {
    return (
        <div className={container}>
            {IconSvg && <IconSvg />}
            {imgSrc && <img src={imgSrc} alt="" />}
            <h2 className={titleText}>{title}</h2>
            <p className={content}>{text}</p>
            {to && (
                <Link to={to}>
                    <Button color="yellow">{buttonText}</Button>
                </Link>
            )}
        </div>
    );
};

export default SuccessMessage;
