import React from 'react';
import { Link } from 'gatsby';

import { header, headerActions } from '../../../templates/choose-profile-template.module.scss';
import Emotic from '../../atoms/emotic';
import errorEmotics from '../../../assets/images/svg/error-emotics.svg';

import Title from '../../atoms/title';
import SubTitle from '../../atoms/subtitle';
import Button from '../../atoms/button';

export default function NoProfiles() {
    return (
        <div className={header}>
            <Emotic emotic={errorEmotics} />
            <Title Tag="h1">Nie znaleziono profilu!</Title>
            <SubTitle>Skontaktuj się z administratorem w celu rozwiązania problemu.</SubTitle>
            <div className={headerActions}>
                <Link to="/kontakt">
                    <Button color>Kontakt</Button>
                </Link>
            </div>
        </div>
    );
}
