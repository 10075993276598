import { types } from './surveys.actions';

const { SET_SURVEYS, RESTORE_INITIAL_STATE } = types;

export const initialState = {
    items: [],
    pagination: {
        currentPage: 1,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
    },
};

const surveysReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_SURVEYS:
            return {
                ...state,
                items: payload.items,
                pagination: payload.pagination,
            };
        case RESTORE_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default surveysReducer;
