import React from 'react';
import AnimateHeight from 'react-animate-height';

import {
    title,
    subtitle,
    separator,
    row,
    checkboxContainer,
    fourColumns,
} from './form.module.scss';
import { config } from '../../../config';
import { checkboxes } from './survey-config';

import Input from '../../atoms/form-poc/input';
import Separator from '../../atoms/separator';
import CheckboxGroup from '../../atoms/form-poc/checkbox-group';
import FileInputField from '../../molecules/form/file-input-field';

const FormDiet = ({ formik }) => {
    return (
        <>
            <h3 className={title}>Odżywianie</h3>

            <h4 className={subtitle}>
                Jakie są Twoje najbardziej priorytetowe cele w kontekście diety?
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="dietTargets"
                    options={checkboxes.dietTargets}
                    containerClass={checkboxContainer}
                />
            </div>
            <AnimateHeight height={formik.values.dietTargets.includes('specific') ? 'auto' : 0}>
                <div className={row}>
                    <Input as="textarea" name="specificDietTargetDescription" label="Opis" />
                </div>
            </AnimateHeight>

            <h4 className={subtitle}>Czy masz jakieś problemy zdrowotne?</h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="healthIssues" />
            </div>

            <h4 className={subtitle}>Jakie są Twoje oczekiwania względem przyszłej diety?</h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="dietExpectations" />
            </div>

            <h4 className={subtitle}>Ile razy w tygodniu jesz słodycze?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="sweetsFrequency"
                    options={checkboxes.dietFrequency}
                    singleChoice={true}
                    containerClass={`
                        ${checkboxContainer}
                        ${fourColumns}
                    `}
                />
            </div>

            <h4 className={subtitle}>Ile razy w tygodniu jesz nabiał?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="dairyFrequency"
                    options={checkboxes.dietFrequency}
                    singleChoice={true}
                    containerClass={`
                        ${checkboxContainer}
                        ${fourColumns}
                    `}
                />
            </div>

            <h4 className={subtitle}>Ile razy w tygodniu jesz produkty z pszenicą?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="wheatProductsFrequency"
                    options={checkboxes.dietFrequency}
                    singleChoice={true}
                    containerClass={`
                        ${checkboxContainer}
                        ${fourColumns}
                    `}
                />
            </div>

            <h4 className={subtitle}>Ile razy w tygodniu pijesz soki lub napoje?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="juicesFrequency"
                    options={checkboxes.dietFrequency}
                    singleChoice={true}
                    containerClass={`
                        ${checkboxContainer}
                        ${fourColumns}
                    `}
                />
            </div>

            <h4 className={subtitle}>Ile razy w tygodniu pijesz alkohol?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="alcoholFrequency"
                    options={checkboxes.dietFrequency}
                    singleChoice={true}
                    containerClass={`
                        ${checkboxContainer}
                        ${fourColumns}
                    `}
                />
            </div>

            <h4 className={subtitle}>Ile razy w tygodniu jesz fast foody?</h4>
            <Separator className={separator} />
            <div className={row}>
                <CheckboxGroup
                    name="fastFoodFrequency"
                    options={checkboxes.dietFrequency}
                    singleChoice={true}
                    containerClass={`
                        ${checkboxContainer}
                        ${fourColumns}
                    `}
                />
            </div>

            <h4 className={subtitle}>Czy bierzesz lekarstwa? Jeżeli tak, jakie?</h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="medicationsTaken" />
            </div>

            <h4 className={subtitle}>Czy stosujesz suplementy diety? Jeżeli tak, jakie?</h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="supplementsTaken" />
            </div>

            <h4 className={subtitle}>
                Opisz Twój przykładowy dzień jedzenia <br />
                (prosimy o konkretne informacje, np. jajecznica z 3 jajek i bułka, potem… itd.)
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="dailyNutritionDescription" />
            </div>

            <h4 className={subtitle}>
                Stres - czy oceniasz siebie jako osobę zestresowaną czy spokojną?
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <Input as="textarea" label="Opis" name="stressLevelDescription" />
            </div>

            <h4 className={subtitle}>
                Jeżeli masz przykładowe zdjęcia swoich posiłków, prosimy o ich przesłanie.
            </h4>
            <Separator className={separator} />
            <div className={row}>
                <FileInputField
                    name="mealPhotos"
                    label="Wgraj zdjęcia"
                    imgOnly={true}
                    maxFileSize={8192000}
                    maxFileCount={20}
                    fileTypes={config.fileTypesMap.images}
                />
            </div>

            <h4 className={subtitle}>Jeżeli masz wykonane badania, prosimy o ich przesłanie.</h4>
            <Separator className={separator} />
            <div className={row}>
                <FileInputField
                    name="medicalExaminationFiles"
                    label="Wgraj badania"
                    maxFileSize={8192000}
                    maxFileCount={10}
                />
            </div>
        </>
    );
};

export default FormDiet;
