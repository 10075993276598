import { types as apiTypes } from '../api/api.actions';
import { SSE } from './sse-subscription.reducer';

const actions = {
    document: {
        set: 'set',
        setStatus: 'set_status',
        clear: 'clear',
    },
    api: {
        request: apiTypes.API_REQUEST,
        success: apiTypes.API_SUCCESS,
        fail: apiTypes.API_FAIL,
    },
    command: {
        subscribe: 'subscribe',
    },
};

const sseSubscribe = (payload) => ({
    type: `${SSE} ${actions.command.subscribe}`,
    payload,
});
const sseSetEvent = (payload) => ({
    type: `${SSE} ${actions.document.set}`,
    payload,
});
const sseSetEventStatus = (payload) => ({
    type: `${SSE} ${actions.document.setStatus}`,
    payload,
});
const sseClearEvents = () => ({
    type: `${SSE} ${actions.document.clear}`,
});

export {
    actions,
    sseSubscribe,
    sseSetEvent,
    sseClearEvents,
    sseSetEventStatus,
};
