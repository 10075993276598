import { deleteNewsletter } from '../../../../communication/forms';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';

export const setNewsletterToUnSubscribe = (data) => async (dispatch) => {
    dispatch(setNewsletterToUnSubscribeStart());
    try {
        const res = await deleteNewsletter(data);

        if (res.status === 200) {
            dispatch(setNewsletterToUnSubscribeSuccess());
        }
    } catch (error) {
        dispatch(setNewsletterToUnSubscribeError(error));
    }
};

export const setNewsletterToUnSubscribeClearSuccess = () => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.unsubscribe,
        entitiesMap.newsletter.allowedActions.clearSuccess
    ),
});

export const setNewsletterToUnSubscribeClearErrors = () => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.unsubscribe,
        entitiesMap.newsletter.allowedActions.clearErrors
    ),
});

const setNewsletterToUnSubscribeStart = () => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.unsubscribe,
        entitiesMap.newsletter.allowedActions.start
    ),
    payload: {},
});

const setNewsletterToUnSubscribeSuccess = () => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.unsubscribe,
        entitiesMap.newsletter.allowedActions.success
    ),
    payload: {},
});

const setNewsletterToUnSubscribeError = (data) => ({
    type: getFullActionName(
        entitiesMap.newsletter.baseName,
        entitiesMap.newsletter.methodName.unsubscribe,
        entitiesMap.newsletter.allowedActions.error
    ),
    payload: data,
});
