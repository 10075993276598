import React, { useEffect, useRef } from 'react';

import { opened, dropdown, triangle } from './dropdown.module.scss';

export interface IDropdownProps {
    open?: boolean;
    closeFunc?: () => void;
    isTriangle?: boolean;
    className?: string;
    children?: React.ReactNode;
}

const Dropdown: React.FC<IDropdownProps> = ({
    children,
    open = false,
    closeFunc,
    isTriangle = true,
    className = '',
}) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleDropdownClose = (e: MouseEvent) => {
            const dropdown = dropdownRef.current;

            if (e.target === dropdown || dropdown?.contains(e.target as Node)) return;

            if (typeof closeFunc === 'function') {
                closeFunc();
            }

            document.removeEventListener('click', handleDropdownClose);
        };

        if (open) {
            document.addEventListener('click', handleDropdownClose);
        }

        return () => {
            document.removeEventListener('click', handleDropdownClose);
        };
    }, [open, closeFunc]);

    return (
        <div
            className={`
                ${dropdown}
                ${isTriangle ? triangle : ''}
                ${className}
                ${open ? opened : ''}
            `}
            ref={dropdownRef}
        >
            {children}
        </div>
    );
};

export default Dropdown;
