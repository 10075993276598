import { getFullActionName } from '../../../reusable-reducers/entities-map';
import { entitiesMap } from '../../../reusable-reducers/entities-map';

export const clearFavoriteRecipesAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.favorite.baseName,
            entitiesMap.recipes.favorite.methodName.get,
            entitiesMap.recipes.favorite.allowedActions.clearData
        ),
        payload: {},
    };
};
