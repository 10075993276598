import React from 'react';
import { Link } from 'gatsby';

import { link, nav, title } from './footer-nav.module.scss';

const FooterNav = ({ content }) => {
    return (
        <nav className={nav}>
            <h5 className={title}>{content.title}</h5>
            <ul>
                {content.links.map((item, index) => {
                    const Tag = item.openInNewTab ? 'a' : Link;
                    const tagProps = item.openInNewTab
                        ? { href: item.target, target: '_blank' }
                        : { to: item.target };
                    return (
                        <li key={`footer-nav-item-${index}`}>
                            <Tag {...tagProps} className={link}>
                                {item.text}
                            </Tag>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default FooterNav;
