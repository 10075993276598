import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../atoms/loader';

const InfiniteContainer = ({
    children,
    className,
    offset,
    disabled,
    loading,
    onScroll,
    loaderFullContainer,
}) => {
    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollTop + clientHeight >= scrollHeight - offset) {
            onScroll();
        }
    };

    return (
        <div
            style={{ overflowY: 'auto' }}
            {...(loading || disabled ? {} : { onScroll: handleScroll })}
            className={className}
        >
            {children}
            {loading && <Loader fullContainer={loaderFullContainer} small={!loaderFullContainer} />}
        </div>
    );
};

InfiniteContainer.propTypes = {
    className: PropTypes.string,
    offset: PropTypes.number,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    loaderFullContainer: PropTypes.bool,
    onScroll: PropTypes.func,
};

InfiniteContainer.defaultProps = {
    className: '',
    offset: 100,
    disabled: false,
    loading: false,
    loaderFullContainer: false,
    onScroll: () => {},
};

export default InfiniteContainer;
