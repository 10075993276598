import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
    list,
    wrapper,
    banner,
    userCant,
    userCantText,
    userCantButtons,
} from './exercise-atlas.module.scss';
import { entity } from '../rbac/permissions';
import UserCan from '../rbac/hoc';
import { getAbsolutePath } from '../routes';
import { LIST, TRAININGS_ATLAS } from '../redux/trainings-atlas/trainings-atlas.reducer';
import { businessMeaning } from '../global-enums/product';

import SEO from '../components/seo';
import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import ExerciseCard from '../components/molecules/exercise/exercise-card';
import LinkButton from '../components/atoms/link-button';
import EnhancedList from '../components/organisms/enhanced-list/enhanced-list';
import useProductDiscounts from '../hooks/use-product-discounts';
import Loader from '../components/atoms/loader';

const TrainingAtlas = () => {
    const data = useStaticQuery(query);
    const { requestDiscountsAll, ...discount } = useProductDiscounts({
        businessMeaning: businessMeaning.trainingAtlas,
    });

    const userCanTrainingAtlas = UserCan({
        action: entity.TRAININGS_ATLAS,
        yes: () => true,
        no: () => false,
    });

    const bannerConfig = {
        bannerData: {
            fluidImg: getImage(data.exerciseBanner),
            title: 'Atlas treningów',
            subtitle: 'Pełnometrażowe programy treningowe',
        },
        bannerImgStyle: {
            objectFit: 'cover',
            objectPosition: 'top center',
            filter: 'brightness(60%)',
        },
    };

    useEffect(() => {
        requestDiscountsAll();
    }, [requestDiscountsAll]);

    return (
        <>
            <SEO title="Atlas treningów" />
            <Main transparentHeader isPaddingEqualToHeaderOnPhone={true} className={wrapper}>
                <MainBanner
                    className={banner}
                    bannerData={bannerConfig.bannerData}
                    imgStyle={bannerConfig.bannerImgStyle}
                />

                {!userCanTrainingAtlas && discount && (
                    <section className={userCant}>
                        {!discount.isLoading ? (
                            <>
                                <p className={userCantText}>
                                    Niestety, Twój plan nie obejmuje Atlasu treningów, obecnie
                                    możesz korzystać tylko z&nbsp;darmowych treningów. Wykup Atlas
                                    trenigów w&nbsp;cenie
                                    <strong> {discount?.price?.grossDisplay}</strong>
                                    /miesiąc lub&nbsp;przejdź na&nbsp;plan Strefa Przemian{' '}
                                    <strong>Premium</strong>
                                </p>
                                <div className={userCantButtons}>
                                    <LinkButton to="/cennik" color="blank">
                                        Chcę przejść na Plan Premium
                                    </LinkButton>
                                    <LinkButton
                                        to={`/sklep/${discount?.slug ? discount?.slug : ''}`}
                                        color="yellow"
                                    >
                                        Kup dostęp za {discount?.price?.grossDisplay} miesięcznie
                                    </LinkButton>
                                </div>
                            </>
                        ) : (
                            <Loader />
                        )}
                    </section>
                )}

                <EnhancedList
                    className={list}
                    entity={TRAININGS_ATLAS}
                    subEntity={LIST}
                    targetLocation={getAbsolutePath('APP_TRAININGS')}
                    itemComponent={ExerciseCard}
                />
            </Main>
        </>
    );
};

const query = graphql`
    {
        exerciseBanner: file(relativePath: { eq: "trainings-banner.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;

export default TrainingAtlas;
