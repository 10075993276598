import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { wrapper, imageWrapper } from './join-to-facebook-group.module.scss';

const JoinToFacebookGroup = ({ image, children }) => {
    return (
        <div className={wrapper}>
            <div className={imageWrapper}>
                <GatsbyImage image={getImage(image)} alt="group cover" />
            </div>
            <div>{children}</div>
        </div>
    );
};

export default JoinToFacebookGroup;
