import React from 'react';

import { wrapper, title, description } from './empty-attachments.module.scss';

import Title from '../../atoms/title';

const EmptyAttachments = () => {
    return (
        <div className={wrapper}>
            <Title Tag="h3" className={title}>
                Brak załączników
            </Title>
            <div className={description}>Nie posiadasz obecnie żadnych załączników.</div>
        </div>
    );
};

export default EmptyAttachments;
