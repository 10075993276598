import { DIET_TYPES, setDietTypes, types } from './diet-types.actions';
import { config } from '../../config';

const dietTypesMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload } = action;
    next(action);

    let items;

    switch (type) {
        case types.FETCH:
            const fetchConfig = {
                types: [
                    types.FETCH_REQUEST,
                    types.FETCH_SUCCESS,
                    types.FETCH_FAIL,
                ],
                payload: {
                    request: {
                        url: `${config.endpoints.mealPlanner.dietTypes}`,
                        withCredentials: true,
                        params: {
                            'filter[tags]': 3,
                        },
                    },
                },
                meta: {
                    entity: DIET_TYPES,
                },
            };
            dispatch(fetchConfig);
            return;

        case types.FETCH_SUCCESS:
            items = [];
            for (const item of payload.data.items) {
                items.push({
                    value: item.tagId,
                    label: item.name,
                });
            }

            dispatch(
                setDietTypes({
                    items: items,
                    pagination: payload.data.pagination,
                })
            );
            return;
        // no default
    }
};

export default dietTypesMiddleware;
