import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { button, counter, number } from './counter.module.scss';

const Counter = ({
    initialValue,
    value,
    min,
    max,
    onChange,
    numberStyle,
    className,
    onAddFunc,
    onSubtractFunc,
}) => {
    const [count, setCount] = useState(initialValue);

    const handleCountChange = (isAdding) => {
        if (typeof onAddFunc === 'function' && isAdding) {
            onAddFunc();
        } else if (typeof onSubtractFunc === 'function') {
            onSubtractFunc();
        } else {
            const newCount = isAdding ? count + 1 : count - 1;
            setCount(newCount);
            if (typeof onChange === 'function') {
                onChange(newCount);
            }
        }
    };

    useEffect(() => {
        if (value !== null) {
            setCount(value);
        }
    }, [value]);

    return (
        <div className={`${counter} ${className}`}>
            <button
                type="button"
                className={button}
                onClick={() => handleCountChange()}
                disabled={min !== null && count <= min}
                aria-label="odejmij"
            >
                -
            </button>
            <div className={`${number} ${numberStyle}`}>{count}</div>
            <button
                type="button"
                className={button}
                onClick={() => handleCountChange(true)}
                disabled={max !== null && count >= max}
                aria-label="dodaj"
            >
                +
            </button>
        </div>
    );
};

Counter.propTypes = {
    initialValue: PropTypes.number,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    onAddFunc: PropTypes.func,
    onSubtractFunc: PropTypes.func,
};

Counter.defaultProps = {
    initialValue: 1,
    value: null,
    min: null,
    max: null,
    onChange: null,
    onAddFunc: null,
    onSubtractFunc: null,
};

export default Counter;
