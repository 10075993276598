import React from 'react';
import { useDispatch } from 'react-redux';

import { changeCurrentProfileId } from '../../../redux/profile/profile.actions';

import {
    header,
    avatarButton,
    avatarName,
    profileItem,
    profileList,
    profilePickerWrapper,
} from '../../../templates/choose-profile-template.module.scss';
import chooseProfileEmotics from '../../../assets/images/svg/choose-profile.svg';

import Emotic from '../../atoms/emotic';
import Title from '../../atoms/title';
import SubTitle from '../../atoms/subtitle';
import Avatar from '../../atoms/avatar';

const ChooseProfile = ({ profiles }) => {
    const dispatch = useDispatch();
    const handleProfileSelection = (profileId) => () => {
        dispatch(changeCurrentProfileId(profileId));
    };
    return (
        <>
            <div className={header}>
                <Emotic emotic={chooseProfileEmotics} />
                <Title Tag="h1">Witaj Ponownie!</Title>
                <SubTitle>Wybierz użytkownika</SubTitle>
            </div>
            <nav className={profilePickerWrapper}>
                <ul className={profileList}>
                    {profiles.map((item, index) => {
                        return (
                            <li key={`profiles-${index}`} className={profileItem}>
                                <button
                                    onClick={handleProfileSelection(item.profileId)}
                                    className={avatarButton}
                                >
                                    <Avatar
                                        avatarUri={item.avatarUri}
                                        size="huge"
                                        hasShadow={false}
                                        isDark={true}
                                    />
                                </button>
                                <p className={avatarName}>{item.firstName}</p>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </>
    );
};

export default ChooseProfile;
