export function selectForumThreadCategories(state) {
    return state.forum.threadCategories;
}

export function selectForumThreads(state) {
    return state.forum.threads;
}

export function selectForumThreadsPinned(state) {
    return state.forum.threadsPinned;
}

export function selectForumThread(state) {
    return state.forum.thread;
}

export function selectForumComments(state) {
    return state.forum.comments;
}
