import { types } from './addresses.actions';
import { config } from '../../config';

const { SET_ADDRESSES, RESTORE_INITIAL_STATE } = types;
const { addressTypes } = config;

export const initialState = {
    delivery: [],
    invoice: [],
};

const addressesReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_ADDRESSES:
            return {
                delivery: payload.filter(
                    (item) => item.type === addressTypes.delivery.id
                ),
                invoice: payload.filter(
                    (item) => item.type === addressTypes.invoice.id
                ),
            };
        case RESTORE_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default addressesReducer;
