import { getFullActionName } from '../../../reusable-reducers/entities-map';
import { entitiesMap } from '../../../reusable-reducers/entities-map';

export const clearYourRecipesAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.your.baseName,
            entitiesMap.recipes.your.methodName.get,
            entitiesMap.recipes.your.allowedActions.clearData
        ),
        payload: {},
    };
};
