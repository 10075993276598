import * as Yup from 'yup';

import { config } from '../../../config';

const {
    form: { required },
} = config.messages;

export const initialValues = {
    height: '',
    weight: '',
    age: '',
    waist: '',
    thigh: '',
    activityDescription: '',
    sleepHours: '',
    collaborationExpectations: '',

    dietTargets: [],
    specificDietTargetDescription: '',
    healthIssues: '',
    dietExpectations: '',
    sweetsFrequency: '',
    dairyFrequency: '',
    wheatProductsFrequency: '',
    juicesFrequency: '',
    alcoholFrequency: '',
    fastFoodFrequency: '',
    medicationsTaken: '',
    supplementsTaken: '',
    dailyNutritionDescription: '',
    stressLevelDescription: '',
    mealPhotos: [],
    medicalExaminationFiles: [],

    preferredTrainer: '',
    trainingTargets: [],
    specificTrainingTargetDescription: '',
    bodyParts: [],
    trainingContraindications: '',
    painComplaints: '',
    injuriesDescription: '',
    trainingExperience: '',
    preferredTrainingDuration: '',
    favoriteExercises: '',
    dislikedExercises: '',
    preferredTrainingPlace: '',
    preferredTrainingEquipment: [],
    otherPreferredTrainingEquipment: '',
    trainingProblems: '',
    trainingExpectations: '',

    additionalInformation: '',
};

export const validationSchemas = [
    Yup.object({
        height: Yup.number().required(required),
        weight: Yup.number().required(required),
        age: Yup.number().required(required),
        waist: Yup.number().required(required),
        thigh: Yup.number().required(required),
        sleepHours: Yup.number().required(required),
        activityDescription: Yup.string().required(required),
        collaborationExpectations: Yup.string().required(required),
    }),
    Yup.object({
        dietTargets: Yup.array().required(required),
        specificDietTargetDescription: Yup.string().when('dietTargets', {
            is: (dietTargets) => dietTargets.includes('specific'),
            then: Yup.string().required(required),
        }),
        healthIssues: Yup.string().required(required),
        dietExpectations: Yup.string().required(required),
        sweetsFrequency: Yup.string().required(required),
        dairyFrequency: Yup.string().required(required),
        wheatProductsFrequency: Yup.string().required(required),
        juicesFrequency: Yup.string().required(required),
        alcoholFrequency: Yup.string().required(required),
        fastFoodFrequency: Yup.string().required(required),
        medicationsTaken: Yup.string().required(required),
        supplementsTaken: Yup.string().required(required),
        dailyNutritionDescription: Yup.string().required(required),
        stressLevelDescription: Yup.string().required(required),
    }),
    Yup.object({
        preferredTrainer: Yup.string().required(required),
        trainingTargets: Yup.array().required(required),
        specificTrainingTargetDescription: Yup.string().when('trainingTargets', {
            is: (trainingTargets) => trainingTargets.includes('specific'),
            then: Yup.string().required(required),
        }),
        bodyParts: Yup.array().required(required),
        trainingContraindications: Yup.string().required(required),
        painComplaints: Yup.string().required(required),
        injuriesDescription: Yup.string().required(required),
        trainingExperience: Yup.string().required(required),
        preferredTrainingDuration: Yup.string().required(required),
        favoriteExercises: Yup.string().required(required),
        dislikedExercises: Yup.string().required(required),
        preferredTrainingPlace: Yup.string().required(required),
        preferredTrainingEquipment: Yup.array().required(required),
        otherPreferredTrainingEquipment: Yup.string().when('preferredTrainingEquipment', {
            is: (preferredTrainingEquipment) => preferredTrainingEquipment.includes('other'),
            then: Yup.string().required(required),
        }),
        trainingProblems: Yup.string().required(required),
        trainingExpectations: Yup.string().required(required),
    }),
    Yup.object({
        additionalInformation: Yup.string().required(required),
    }),
];

export const checkboxes = {
    dietTargets: [
        { value: 'weightLoss', label: 'odchudzanie' },
        { value: 'wellBeing', label: 'poprawa samopoczucia' },
        { value: 'muscleBuilding', label: 'budowanie mięśni' },
        { value: 'energyGrow', label: 'wzrost energii' },
        { value: 'healthImprovement', label: 'poprawa zdrowia' },
        { value: 'specific', label: 'specyficzny cel' },
    ],
    dietFrequency: [
        { value: '4', label: 'wcale' },
        { value: '1', label: 'raz w tygodniu' },
        { value: '2', label: 'kilka razy w tygodniu' },
        { value: '3', label: 'praktycznie codziennie' },
    ],
    preferredTrainer: [
        { value: '1', label: 'z Anią Kolasińską' },
        { value: '2', label: 'z Bartkiem Szemrajem' },
    ],
    trainingTargets: [
        { value: 'fatBurning', label: 'spalanie tkanki tłuszczowej' },
        { value: 'figureFirming', label: 'ujędrnienie sylwetki' },
        { value: 'conditionImprovement', label: 'poprawa kondycji' },
        { value: 'wellBeing', label: 'lepsze samopoczucie' },
        { value: 'mobilityImprovement', label: 'zwiększenie mobilności' },
        { value: 'muscleBuilding', label: 'budowanie mięśni' },
        { value: 'painRelief', label: 'pozbycie się dolegliwości bólowych' },
        { value: 'specific', label: 'szczególny cel' },
    ],
    bodyParts: [
        { value: 'abdomen', label: 'brzuch' },
        { value: 'arms', label: 'ręce' },
        { value: 'back', label: 'plecy' },
        { value: 'shoulders', label: 'barki' },
        { value: 'chest', label: 'klatka piersiowa' },
        { value: 'thighs', label: 'uda' },
        { value: 'buttocks', label: 'pośladki' },
        { value: 'calves', label: 'łydki' },
    ],
    trainingExperience: [
        { value: '1', label: 'brak' },
        { value: '2', label: 'kilka miesięcy' },
        { value: '3', label: 'ponad rok' },
    ],
    preferredTrainingDuration: [
        { value: '1', label: 'do 30 minut' },
        { value: '2', label: 'do 60 minut' },
        { value: '3', label: 'tyle ile będzie konieczne' },
    ],
    preferredTrainingPlace: [
        { value: '1', label: 'w domu' },
        { value: '2', label: 'na siłowni' },
    ],
    preferredTrainingEquipment: [
        { value: 'dumbbell', label: 'hantle' },
        { value: 'barbells', label: 'sztanga' },
        { value: 'swissball', label: 'swissball' },
        { value: 'miniBands', label: 'mini bands' },
        { value: 'roller', label: 'roller' },
        { value: 'kettlebell', label: 'kettlebell' },
        { value: 'ownBody', label: 'z ciężarem własnego ciała' },
        { value: 'other', label: 'inne' },
    ],
};
