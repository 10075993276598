import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { getSrc } from 'gatsby-plugin-image';

import { actionRow, button, checkbox, grid, player, title } from './intro-video-page.module.scss';
import { config } from '../config';
import { saveMovieLog } from '../communication/audit';
import { getAbsolutePath } from '../routes';
import { types, updateProfile } from '../redux/profile/profile.actions';
import { getCurrentProfileId } from '../redux/profile/profile.selectors';

import Main from '../layouts/main';
import Title from '../components/atoms/title';
import VideoPlayer from '../components/molecules/video-player';
import SEO from '../components/seo';
import Checkbox from '../components/atoms/form-poc/checkbox';
import Button from '../components/atoms/button';

const { audit, videos } = config;

const IntroVideoPage = ({ routeData }) => {
    const { banner } = useStaticQuery(query);
    const dispatch = useDispatch();

    const currentProfileId = useSelector((state) => getCurrentProfileId(state));

    const [dontShowChecked, setDontShowChecked] = useState(false);

    const handleChange = () => {
        setDontShowChecked(!dontShowChecked);
    };

    const submitIntroVideoWatched = () => {
        if (dontShowChecked) {
            dispatch(
                updateProfile({ watchedOnboardingVideo: dontShowChecked }, null, null, {
                    onboardingVideo: true,
                })
            );
        }
        dispatch({
            type: types.UPDATE_SINGLE_PROFILE,
            payload: {
                id: currentProfileId,
                watchedOnboardingVideoInSession: true,
            },
        });
        navigate(getAbsolutePath('APP'));
    };

    return (
        <>
            <SEO title={routeData.label} />
            <Main className={grid}>
                <Title tag="h1" className={title}>
                    {routeData.label}
                </Title>
                <div className={player}>
                    <VideoPlayer
                        videoUrl={videos.intro.src}
                        followProgress={true}
                        onWatched={handleWatched}
                        autoplay={false}
                        coverUrl={getSrc(banner)}
                    />
                    <div className={actionRow}>
                        <Checkbox
                            containerClass={checkbox}
                            name="dontShow"
                            withoutFormik={true}
                            onChange={handleChange}
                            checked={dontShowChecked}
                        >
                            Nie pokazuj tego filmu więcej
                        </Checkbox>

                        <Button
                            onClick={submitIntroVideoWatched}
                            color={'yellow'}
                            className={button}
                        >
                            Dalej
                        </Button>
                    </div>
                </div>
            </Main>
        </>
    );
};

async function handleWatched() {
    const movieData = {
        module: audit.module.onBoarding,
        group: audit.group.movie,
        action: audit.action.watch,
    };
    await saveMovieLog(movieData);
}

const query = graphql`
    {
        banner: file(relativePath: { eq: "welcome-banner.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;

export default IntroVideoPage;
