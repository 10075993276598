// extracted by mini-css-extract-plugin
export var accountButton = "account-widget-module--account-button--ogDPa";
export var authLinks = "account-widget-module--auth-links--1XZpO";
export var authMobile = "account-widget-module--auth-mobile--sYoSi";
export var authMobileContainer = "account-widget-module--auth-mobile-container--uWjtW";
export var authMobileLinks = "account-widget-module--auth-mobile-links--sw1-M";
export var authMobileLogo = "account-widget-module--auth-mobile-logo--cNm6W";
export var avatarBox = "account-widget-module--avatar-box--VjCh7";
export var chevron = "account-widget-module--chevron--r5mZF";
export var closed = "account-widget-module--closed--veDhV";
export var desktopContainer = "account-widget-module--desktop-container--Gatn4";
export var desktopNavButton = "account-widget-module--desktop-nav-button--bikev";
export var desktopNavItem = "account-widget-module--desktop-nav-item--yEPOR";
export var desktopNavLink = "account-widget-module--desktop-nav-link--W9-l-";
export var desktopNavList = "account-widget-module--desktop-nav-list--dAa4G";
export var dropdownContainer = "account-widget-module--dropdown-container--4ygol";
export var level1 = "account-widget-module--level1--wT-qQ";
export var linkActive = "account-widget-module--link-active--87HLl";
export var linkPartiallyActive = "account-widget-module--link-partially-active--975I-";
export var mobileContainer = "account-widget-module--mobile-container--gB5sC";
export var mobileLogoutBox = "account-widget-module--mobile-logout-box--Nplto";
export var mobileLogoutButton = "account-widget-module--mobile-logout-button--JpkjB";
export var mobileNav = "account-widget-module--mobile-nav--5+tfU";
export var mobileNavButton = "account-widget-module--mobile-nav-button--62mRF";
export var mobileNavItem = "account-widget-module--mobile-nav-item--IMe4E";
export var mobileNavLink = "account-widget-module--mobile-nav-link--oafMH";
export var mobileNavList = "account-widget-module--mobile-nav-list--IbfTE";
export var mobilePanelBottom = "account-widget-module--mobile-panel-bottom--o39U1";
export var mobilePanelContainer = "account-widget-module--mobile-panel-container--JEjXU";
export var mobileSwitchProfileButton = "account-widget-module--mobile-switch-profile-button--rlbWZ";
export var mobileSwitchProfileRow = "account-widget-module--mobile-switch-profile-row--LghoQ";
export var msgCounter = "account-widget-module--msg-counter--E4Qat";
export var name = "account-widget-module--name--6XlJf";
export var separator = "account-widget-module--separator--bTCfU";