import React from 'react';

import { config } from '../../config';
import { PARTNERS_ZONE, OWN } from '../../redux/partners-zone/partners-zone.reducer';
import useAutoFetchGenericList from '../../hooks/use-auto-fetch-generic-list';

import BusinessPartnersList from '../../components/molecules/business-partners-list';
import IntersectBox from '../../components/atoms/intersect-box';
import Loader from '../../components/atoms/loader';
import PartnerCodeTile from '../../components/molecules/partner-code-tile';

export default function GamificationPartnersCodes() {
    const { items, getNextPage, apiStatus } = useAutoFetchGenericList({
        entity: PARTNERS_ZONE,
        subEntity: OWN,
        url: config.endpoints.partnersZone.codes,
        perPage: 50,
    });

    const isLoading = apiStatus === config.apiStatusMap.loading;

    return (
        <>
            <BusinessPartnersList
                items={items}
                isLoading={isLoading}
                ItemComponent={PartnerCodeTile}
            />
            <IntersectBox onIntersecting={getNextPage}>{isLoading && <Loader />}</IntersectBox>
        </>
    );
}
