// extracted by mini-css-extract-plugin
export var banner = "welcome-module--banner--43z3j";
export var dots = "welcome-module--dots--3xJpM";
export var grid = "welcome-module--grid--s1Ucb";
export var list = "welcome-module--list--Xrjxf";
export var listBox = "welcome-module--list-box--MwfVw";
export var loader = "welcome-module--loader--TOIYG";
export var next = "welcome-module--next--8hYCA";
export var prev = "welcome-module--prev--bS7tN";
export var slide = "welcome-module--slide--fahl7";
export var slider = "welcome-module--slider--Ky-J6";
export var title = "welcome-module--title--WZ2oh";