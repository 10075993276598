export const sortAlphabeticallyByKey = (arr, key) => {
    return arr.sort(function (a, b) {
        const nameA = a[key].toUpperCase();
        const nameB = b[key].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
};
