import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import { form, input } from './change-password.module.scss';
import { config } from '../../../config';
import { changePassword } from '../../../redux/user/user.actions';

import Loader from '../../atoms/loader';
import Input from '../../atoms/form-poc/input';
import Button from '../../atoms/button';

const {
    form: { required },
    password: { min, repeat },
} = config.messages;

const initialValues = {
    currentPassword: '',
    newPassword: '',
    passwordRepeat: '',
};

const validationSchema = Yup.object({
    currentPassword: Yup.string().required(required),
    newPassword: Yup.string().min(8, min).required(required),
    passwordRepeat: Yup.string()
        .oneOf([Yup.ref('newPassword')], repeat)
        .required(required),
});

const ChangePassword = ({ changePasswordAction }) => {
    const handleSubmit = (formValues, formikBag) => {
        changePasswordAction(formValues, formikBag);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {(formik) => (
                <Form className={form}>
                    {formik.status === config.formsStatusMap.loading && <Loader fullContainer />}
                    <Input
                        containerClass={input}
                        name="currentPassword"
                        type="password"
                        placeholder="Wprowadź obecne hasło"
                    />
                    <Input
                        containerClass={input}
                        name="newPassword"
                        type="password"
                        placeholder="Wprowadź nowe hasło"
                    />
                    <Input
                        containerClass={input}
                        name="passwordRepeat"
                        type="password"
                        placeholder="Powtórz nowe hasło"
                    />
                    <Button color="yellow">Zapisz nowe hasło</Button>
                </Form>
            )}
        </Formik>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePasswordAction: (formValues, formikBag) =>
            dispatch(changePassword({ formValues, formikBag })),
    };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
