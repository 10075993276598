import React, { useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import { labelBox, labelText, input } from './input.module.scss';
import {
    input as inputSelect,
    inputBox,
    dropdown,
    open,
    selected,
    infiniteBox,
    chevron,
    chevronButton,
    optionButton,
} from './input-select-infinite.module.scss';
import ChevronIcon from '../../../assets/images/svg/chevron-down.svg';

import Dropdown from '../../molecules/dropdown';
import InfiniteContainer from '../../hoc/infinite-continer';
import TextError from './text-error';

const InputSelectInfinite = ({
    className,
    name,
    label,
    placeholder,
    noOptionsMessage,
    options,
    onScroll,
    disabled,
    loading,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleSelect = (form, option) => {
        form.setFieldValue(name, option);
        setDropdownOpen(false);
    };

    return (
        <div className={className}>
            <div className={labelBox}>
                {label && (
                    <label htmlFor={name} className={labelText}>
                        {label}
                    </label>
                )}
            </div>
            <Field name={name}>
                {({ field, form }) => {
                    return (
                        <div className={`${inputBox} ${dropdownOpen ? open : ''}`}>
                            <input
                                placeholder={placeholder}
                                readOnly={true}
                                name={name}
                                className={`${input} ${inputSelect}`}
                                value={field.value.label || ''}
                            />
                            <button
                                type="button"
                                className={chevronButton}
                                onClick={() => setDropdownOpen(true)}
                            >
                                <ChevronIcon className={chevron} />
                            </button>
                            <Dropdown
                                className={dropdown}
                                open={dropdownOpen}
                                closeFunc={() => setDropdownOpen(false)}
                                style={{ height: '400px' }}
                                isTriangle={false}
                            >
                                <InfiniteContainer
                                    onScroll={onScroll}
                                    disabled={disabled}
                                    className={infiniteBox}
                                    loaderFullContainer={false}
                                    loading={loading}
                                >
                                    {options.map((option) => {
                                        return (
                                            <button
                                                key={`btn-option-${option.value}`}
                                                type="button"
                                                className={`
                                                    ${optionButton}
                                                    ${
                                                        field.value.value === option.value
                                                            ? selected
                                                            : ''
                                                    }
                                                `}
                                                onClick={() => handleSelect(form, option)}
                                                {...(!dropdownOpen ? { tabIndex: -1 } : {})}
                                            >
                                                {option.label}
                                            </button>
                                        );
                                    })}
                                    {options.length === 0 && (
                                        <p className={noOptionsMessage}>{noOptionsMessage}</p>
                                    )}
                                </InfiniteContainer>
                            </Dropdown>
                        </div>
                    );
                }}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    );
};

InputSelectInfinite.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    noOptionsMessage: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onScroll: PropTypes.func,
};

InputSelectInfinite.defaultProps = {
    className: '',
    label: '',
    options: [],
    placeholder: 'Wybierz z listy',
    noOptionsMessage: 'Brak wyników',
    disabled: false,
    loading: false,
    onScroll: () => {},
};

export default InputSelectInfinite;
