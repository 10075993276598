import { getCurrentProfileId } from '../redux/profile/profile.selectors';

const addXProfileIdHeader = ({ getState }, req) => {
    const currentProfileId = getCurrentProfileId(getState());

    return {
        ...req,
        headers: {
            ...req.headers,
            'X-Profile-Id': currentProfileId,
        },
    };
};

const multiClientMiddlewareConfig = {
    interceptors: {
        request: [addXProfileIdHeader],
    },
};

export default multiClientMiddlewareConfig;
