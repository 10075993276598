import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { list, wrapper, banner } from './exercise-atlas.module.scss';
import { EXERCISE_ATLAS, LIST } from '../redux/exercise-atlas/exercise-atlas.reducer';
import { getAbsolutePath } from '../routes';

import SEO from '../components/seo';
import Main from '../layouts/main';
import MainBanner from '../components/organisms/main-banner';
import EnhancedList from '../components/organisms/enhanced-list/enhanced-list';
import ExerciseCard from '../components/molecules/exercise/exercise-card';

const ExerciseAtlas = () => {
    const imageQuery = useStaticQuery(query);

    const bannerConfig = {
        bannerData: {
            fluidImg: getImage(imageQuery.exerciseBanner),
            title: 'Atlas ćwiczeń',
            subtitle: 'Baza wszystkich ćwiczeń dostępnych na platformie Strefa Przemian',
        },
        bannerImgStyle: {
            objectFit: 'cover',
            objectPosition: 'top center',
            filter: 'brightness(60%)',
        },
    };

    return (
        <>
            <SEO title="Atlas ćwiczeń" />
            <Main transparentHeader isPaddingEqualToHeaderOnPhone={true} className={wrapper}>
                <MainBanner
                    className={banner}
                    bannerData={bannerConfig.bannerData}
                    imgStyle={bannerConfig.bannerImgStyle}
                />

                <EnhancedList
                    className={list}
                    entity={EXERCISE_ATLAS}
                    subEntity={LIST}
                    targetLocation={getAbsolutePath('APP_EXERCISE')}
                    itemComponent={ExerciseCard}
                />
            </Main>
        </>
    );
};

const query = graphql`
    {
        exerciseBanner: file(relativePath: { eq: "exercises-banner.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;

export default ExerciseAtlas;
