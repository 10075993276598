import React, { useRef } from 'react';

import { arrowButton, dark as darkClass } from './arrow-button.module.scss';
import ArrowSVG from '../../assets/images/svg/nav-arrow.svg';

const ArrowButton = ({
    rotateDeg = 0,
    onClick,
    disabled = false,
    className = '',
    dark = false,
    ariaLabel,
}) => {
    const buttonRef = useRef();

    const handleClick = () => {
        buttonRef.current.blur();

        if (onClick) {
            onClick();
        }
    };

    return (
        <button
            className={`${arrowButton} ${className} ${dark ? darkClass : ''}`}
            onClick={handleClick}
            ref={buttonRef}
            style={{ transform: `rotate(${rotateDeg}deg)` }}
            disabled={disabled}
            aria-label={ariaLabel}
        >
            <ArrowSVG />
        </button>
    );
};

export default ArrowButton;
