import React from 'react';

import ColoredSelectOption from '../components/atoms/form-poc/colored-select-option';

export const getMealTypesForSelect = (
    mealTypesObj,
    withColor = false,
    excluded = []
) => {
    const options = [];

    for (const type of Object.values(mealTypesObj)) {
        if (!excluded.includes(type.mealTypeId)) options.push(type);
    }

    return options.map((mealType) => {
        return {
            ...mealType,
            value: mealType.mealTypeId,
            ...(withColor
                ? {
                      label: (
                          <ColoredSelectOption
                              label={mealType.label}
                              colorClass={mealType.key}
                          />
                      ),
                  }
                : {}),
        };
    });
};
