import React from 'react';
// TODO: change to Static Image
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { onlyText } from 'react-children-utilities';

import {
    wrapper,
    subtitle,
    trainer,
    trainerAnia,
    trainerBartek,
    form,
    emotics,
    inputGrid,
    checkbox,
    rodoNote,
    loader,
    button,
} from './newsletter.module.scss';
import NewsletterEmotics from '../../assets/images/svg/newsletter-emotics.svg';
import { config } from '../../config';
import { copy } from '../../copy';
import { setNewsletterToSubscribe } from '../../redux/newsletter/user-zone/actions/user-zone-newsletter-subscribe';
import { selectNewsletterSubscribe } from '../../redux/newsletter/user-zone/selectors/select-newsletter-subscribe';

import Button from '../atoms/button';
import Title from '../atoms/title';
import Input from '../atoms/form-poc/input';
import Emotic from '../atoms/emotic';
import SubTitle from '../atoms/subtitle';
import Loader from '../atoms/loader';
import Checkbox from '../atoms/form-poc/checkbox';
import ReadMore from '../atoms/read-more';

const { required, email } = config.messages.form;
const { consents } = copy;
const { consentsMap } = config;

const initialValues = {
    newsletter: {
        name: '',
        email: '',
        consents: {
            regulations: false,
            newsletter: false,
            rodo: false,
        },
    },
};

const validationSchema = Yup.object({
    newsletter: Yup.object({
        name: Yup.string().required(required),
        email: Yup.string().email(email).required(required),
        consents: Yup.object({
            regulations: Yup.boolean().required(required).oneOf([true], required),
            newsletter: Yup.boolean().required(required).oneOf([true], required),
            rodo: Yup.boolean().required(required).oneOf([true], required),
        }),
    }),
});

const Newsletter = () => {
    const data = useStaticQuery(query);

    const dispatch = useDispatch();
    const { loading, success } = useSelector(selectNewsletterSubscribe);

    const handleSubmit = (formValues) => {
        const data = {
            name: formValues.newsletter.name,
            email: formValues.newsletter.email,
            consents: [
                {
                    source: consentsMap.source.newsletterBanner,
                    key: consentsMap.key.regulations,
                    content: onlyText(consents.regulations),
                    agreed: formValues.newsletter.consents.regulations ? 1 : 0,
                },
                {
                    source: consentsMap.source.newsletterBanner,
                    key: consentsMap.key.newsletter,
                    content: consents.newsletter,
                    agreed: formValues.newsletter.consents.newsletter ? 1 : 0,
                },
                {
                    source: consentsMap.source.newsletterBanner,
                    key: consentsMap.key.rodo,
                    content: consents.rodo,
                    agreed: formValues.newsletter.consents.rodo ? 1 : 0,
                },
            ],
        };
        dispatch(setNewsletterToSubscribe(data));
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <div className={wrapper}>
                    {loading && (
                        <Loader className={loader} fullContainer={true} noBackground={true} />
                    )}
                    <div className={`${trainer} ${trainerBartek}`}>
                        <GatsbyImage
                            image={getImage(data.bartek)}
                            style={{ display: 'block' }}
                            alt="Bartek"
                        />
                    </div>
                    <Form className={form}>
                        <Emotic emotic={NewsletterEmotics} className={emotics} />
                        <Title>Newsletter</Title>
                        <SubTitle className={subtitle}>
                            {success
                                ? 'Zostałeś poprawnie zapisany/a do newslettera'
                                : `Po zapisaniu się do naszego newslettera otrzymasz BEZPŁATNIE
                           do przetestowania nasze 7 dniowe menu oraz PREZENT!`}
                        </SubTitle>
                        {!success && (
                            <>
                                <div className={inputGrid}>
                                    <Input
                                        name="newsletter.name"
                                        placeholder="Twoje imię i nazwisko"
                                    />
                                    <Input
                                        name="newsletter.email"
                                        placeholder="Twój adres e-mail"
                                    />
                                </div>
                                <Checkbox
                                    name="newsletter.consents.regulations"
                                    containerClass={checkbox}
                                    darkBox={true}
                                >
                                    {consents.regulations}
                                </Checkbox>
                                <Checkbox
                                    name="newsletter.consents.newsletter"
                                    containerClass={checkbox}
                                    darkBox={true}
                                >
                                    <ReadMore>{consents.newsletter}</ReadMore>
                                </Checkbox>
                                <div className={rodoNote}>
                                    <ReadMore>{consents.rodoDisclaimer}</ReadMore>
                                </div>
                                <Checkbox
                                    name="newsletter.consents.rodo"
                                    containerClass={checkbox}
                                    darkBox={true}
                                >
                                    {consents.rodo}
                                </Checkbox>
                                <Button className={button}>Wyślij</Button>
                            </>
                        )}
                    </Form>
                    <div className={`${trainer} ${trainerAnia}`}>
                        <GatsbyImage
                            image={getImage(data.ania)}
                            style={{ display: 'block' }}
                            alt="Ania"
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};

const query = graphql`
    {
        bartek: file(relativePath: { eq: "newsletter-bartek.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 370
                    height: 466
                    quality: 100
                    layout: FIXED
                    placeholder: BLURRED
                )
            }
        }
        ania: file(relativePath: { eq: "newsletter-ania.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 370
                    height: 466
                    quality: 100
                    layout: FIXED
                    placeholder: BLURRED
                )
            }
        }
    }
`;

export default Newsletter;
