import React from 'react';
import PropTypes from 'prop-types';

import { card } from './address-select-list.module.scss';

import AddressCard, { AddressPropShape } from '../molecules/address-card';

const AddressSelectList = ({ className, addresses, onSelect, selectedId }) => {
    return (
        <ul className={className}>
            {addresses.map((address) => (
                <li key={`address-item-${address.addressId}`}>
                    <AddressCard
                        address={address}
                        onClick={onSelect}
                        className={card}
                        isSelected={selectedId === address.addressId}
                    />
                </li>
            ))}
        </ul>
    );
};

AddressSelectList.propTypes = {
    className: PropTypes.string,
    addresses: PropTypes.arrayOf(AddressPropShape),
    onSelect: PropTypes.func,
    selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

AddressSelectList.defaultProps = {
    className: '',
    addresses: [],
    onSelect: () => {},
    selectedId: '',
};

export default AddressSelectList;
