import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
    form,
    loading,
    input,
    products,
    addProductButton,
    separator,
    globalErrors,
    header,
    title,
    description,
    successBox,
    successContent,
    successButtons,
} from './add-shopping-list.module.scss';
import SuccessEmotic from '../../assets/images/svg/success-emotics.svg';
import { config } from '../../config';
import {
    clearSingleShoppingList,
    createShoppingList,
    editShoppingList,
    getSingleShoppingList,
    SINGLE_SHOPPING_LIST,
    SINGLE_SHOPPING_LIST_CHANGE,
} from '../../redux/single-shopping-list/single-shopping-list.actions';
import { selectLoaderByEntity } from '../../redux/ui/ui.selectors';
import { selectSingleShoppingList } from '../../redux/single-shopping-list/single-shopping-list.selectors';
import { getCurrentProfileId } from '../../redux/profile/profile.selectors';

import IngredientFieldArray from '../molecules/ingredient-field-array';
import Button from '../atoms/button';
import Separator from '../atoms/separator';
import Input from '../atoms/form-poc/input';
import Loader from '../atoms/loader';
import Emotic from '../atoms/emotic';
import Title from '../atoms/title';
import LinkButton from '../atoms/link-button';

const {
    form: { required },
} = config.messages;
const { apiStatusMap } = config;

const ingredientKey = 'ingredient';
const quantityKey = 'quantity';
const unitKey = 'unit';

const initialValues = {
    name: '',
    ingredients: [{ [ingredientKey]: '', [quantityKey]: '', [unitKey]: '' }],
};

const validationSchema = Yup.object({
    name: Yup.string().required(required),
    ingredients: Yup.array().of(
        Yup.object().shape(
            {
                [ingredientKey]: Yup.object().when([quantityKey, unitKey], {
                    is: (quantityValue, unitValue) => quantityValue || unitValue,
                    then: Yup.object().required(required),
                }),
                [quantityKey]: Yup.number()
                    .when([ingredientKey, unitKey], {
                        is: (ingredientValue, unitValue) => ingredientValue || unitValue,
                        then: Yup.number().required(required),
                    })
                    .test(
                        'isGreaterThanZero',
                        'Wartość musi być większa od zera',
                        (quantity) => quantity > 0 || quantity === undefined
                    ),
                [unitKey]: Yup.object().when([ingredientKey, quantityKey], {
                    is: (ingredientValue, quantityValue) => ingredientValue || quantityValue,
                    then: Yup.object().required(required),
                }),
            },
            [
                [ingredientKey, quantityKey],
                [ingredientKey, unitKey],
                [quantityKey, unitKey],
            ]
        )
    ),
});

const AddShoppingList = ({ id }) => {
    const dispatch = useDispatch();
    const shoppingList = useSelector(selectSingleShoppingList);
    const { fetchStatus, changeStatus, profileId } = useSelector((state) => {
        return {
            fetchStatus: selectLoaderByEntity(state, SINGLE_SHOPPING_LIST),
            changeStatus: selectLoaderByEntity(state, SINGLE_SHOPPING_LIST_CHANGE),
            profileId: getCurrentProfileId(state),
        };
    });

    const [initialFormValues, setInitialFormValues] = useState(initialValues);
    const [success, setSuccess] = useState(false);

    const containerRef = useRef(null);

    const handleSubmit = (formValues, formikBag) => {
        const notEmptyIngredients = formValues.ingredients
            .filter((item) => item.ingredient)
            .map(({ ingredient, quantity, unit }) => {
                return {
                    ingredientId: ingredient.value,
                    name: ingredient.label,
                    unitId: unit.value,
                    quantity: quantity,
                };
            });

        const data = {
            profileId: profileId,
            name: formValues.name,
            ingredients: notEmptyIngredients,
        };

        if (id) {
            dispatch(editShoppingList(id, data, formikBag));
        } else {
            dispatch(createShoppingList(data, formikBag));
        }
    };

    const handleFormReset = (formik) => {
        formik.resetForm();
        formik.setStatus(config.formsStatusMap.idle);
        setSuccess(false);
    };

    const renderContent = (formik) => {
        if (id && fetchStatus === apiStatusMap.error) {
            return <div>Nie udało się pobrać listy do edycji, spróbuj później...</div>;
        }

        if (id && (fetchStatus === apiStatusMap.loading || !shoppingList)) {
            return <Loader />;
        }

        return (
            <Form className={`${form} ${changeStatus === apiStatusMap.loading ? loading : ''}`}>
                <Input
                    name="name"
                    label="Nazwa listy zakupowej"
                    containerClass={input}
                    placeholder="Np. zdrowe śniadania"
                />
                <IngredientFieldArray
                    formik={formik}
                    name="ingredients"
                    ingredientName={ingredientKey}
                    quantityName={quantityKey}
                    unitName={unitKey}
                    ingredientLabel="Produkt"
                    ingredientPlaceholder="Wybierz produkt z listy"
                    quantityPlaceholder="Np. 200"
                    unitPlaceholder="Np. g"
                    className={products}
                    addButtonClass={addProductButton}
                    allowUserInput={true}
                />
                <Separator className={separator} />

                {formik.errors.global && <div className={globalErrors}>{formik.errors.global}</div>}

                <Button color="yellow">Zapisz listę</Button>
            </Form>
        );
    };

    useEffect(() => {
        if (id && !shoppingList) {
            dispatch(getSingleShoppingList(id));
        }
    }, [shoppingList, id, dispatch]);

    useEffect(() => {
        if (id && shoppingList) {
            setInitialFormValues(getInitialValues(shoppingList));
        }
    }, [shoppingList, id]);

    useEffect(() => {
        if (changeStatus === apiStatusMap.success) {
            window.scrollTo(0, 0);
            setSuccess(true);
            dispatch(clearSingleShoppingList());
        }
    }, [changeStatus, dispatch]);

    useEffect(() => {
        return () => dispatch(clearSingleShoppingList());
    }, [dispatch]);

    return (
        <div ref={containerRef}>
            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formik) =>
                    !success ? (
                        <>
                            <div className={header}>
                                <h3 className={title}>
                                    {!id ? 'Tworzenie' : 'Edycja'} listy zakupowej
                                </h3>
                                {!id && (
                                    <p className={description}>
                                        Wypełnij poniższe dane aby utworzyć listę zakupów
                                    </p>
                                )}
                            </div>
                            {renderContent(formik)}
                        </>
                    ) : (
                        <div className={successBox}>
                            <div className={successContent}>
                                <Emotic emotic={SuccessEmotic} />
                                <Title>
                                    {id ? `Zmiany zostały zapisane` : `Lista została dodana`}
                                </Title>
                            </div>
                            <div className={successButtons}>
                                {id ? null : (
                                    <Button
                                        type="button"
                                        color="yellow"
                                        onClick={() => handleFormReset(formik)}
                                    >
                                        Dodaj kolejną listę
                                    </Button>
                                )}
                                <LinkButton
                                    to={id ? `../../${id}` : `../`}
                                    type="button"
                                    color="blank"
                                >
                                    Wróć
                                </LinkButton>
                            </div>
                        </div>
                    )
                }
            </Formik>
        </div>
    );
};

function getInitialValues(shoppingList) {
    let ingredients = [{ [ingredientKey]: '', [quantityKey]: '', [unitKey]: '' }];
    if (shoppingList.positions.length > 0) {
        ingredients = shoppingList.positions.map((ingredient) => {
            return {
                [ingredientKey]: {
                    label: ingredient.name,
                    value: ingredient.ingredientId || '',
                },
                [quantityKey]: ingredient.quantity,
                [unitKey]: {
                    label: ingredient.unit.name,
                    value: ingredient.unitId,
                },
            };
        });
    }
    return {
        name: shoppingList.name,
        ingredients: ingredients,
    };
}

export default AddShoppingList;
