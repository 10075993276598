import { types } from './files-to-download.actions';

const { SET_FILES_TO_DOWNLOAD, CLEAR_FILES_TO_DOWNLOAD } = types;

export const initialState = {
    pages: {},
    pagination: {
        currentPage: 1,
        pageCount: 0,
        perPage: 12,
        totalCount: 0,
    },
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_FILES_TO_DOWNLOAD:
            return {
                pages: {
                    ...state.pages,
                    [payload.pagination.currentPage]: payload.items,
                },
                pagination: payload.pagination,
            };
        case CLEAR_FILES_TO_DOWNLOAD:
            return initialState;
        default:
            return state;
    }
}
