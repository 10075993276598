import React from 'react';

import { canBeSelected, container, notActive } from './tag.module.scss';
import { ITag } from '../../models/tag.model';

export interface ITagProps {
    selectable?: boolean;
    active?: boolean;
    onClick?: (tag: ITag) => void;
    tag: ITag;
    className?: string;
}

const Tag = ({ selectable = false, active = true, onClick, tag, className = '' }: ITagProps) => {
    const { tagId, color, name, selected } = tag;
    const inactive = selectable && !selected;
    const selectedClass = `${inactive ? notActive : ''}`;
    const selectableClass = `${selectable ? canBeSelected : ''}`;
    const HtmlTag = selectable ? 'button' : 'div';

    const handleClick = () => {
        if (onClick) {
            onClick({ ...tag, selected: !selected });
        }
    };

    const colorStyle = color ? { backgroundColor: `${color}` } : {};

    return (
        <HtmlTag
            key={tagId}
            className={`${container} ${selectableClass} ${selectedClass} ${className}`}
            style={colorStyle}
            onClick={handleClick}
            disabled={!active}
        >
            {name}
        </HtmlTag>
    );
};

export default Tag;
