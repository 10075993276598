import React from 'react';
import PropTypes from 'prop-types';

import { badge } from './badge.module.scss';

import Tag from './tag';

export interface IBadgeProps {
    children: string;
    color?: string;
    className?: string;
}

export default function Badge({ children, color = 'black', className = '' }: IBadgeProps) {
    return <Tag active tag={{ color, name: children }} className={`${badge} ${className}`} />;
}

Badge.propTypes = {
    children: PropTypes.string.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
};
