import React from 'react';
import { Link } from 'gatsby';

import { logoBox, logoDesktop, logoMobile } from './logo.module.scss';

export default function Logo() {
    return (
        <Link to="/" className={logoBox}>
            <img
                className={logoDesktop}
                src="/images/logo.svg"
                title="Metamorfozy ciał i skuteczne odchudzanie - Strefa Przemian"
                alt="logo"
            />
            <img
                className={logoMobile}
                src="/images/logo-sygnet.svg"
                title="Metamorfozy ciał i skuteczne odchudzanie - Strefa Przemian"
                alt="logo"
            />
        </Link>
    );
}
