import React from 'react';

import LinkUnderline from './components/atoms/link-underline';

export const copy = {
    consents: {
        regulations: (
            <>
                Przeczytałem/am i akceptuję{` `}
                <LinkUnderline
                    Tag="a"
                    href="/docs/regulamin-serwisu-strefa-przemian.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Regulamin.
                </LinkUnderline>
            </>
        ),
        rodo: `Oświadczam, że zapoznałem/am się z zasadami przetwarzania danych osobowych i akceptuję je.`,
        rodoDisclaimer: (
            <>
                Administratorem danych osobowych Użytkownika, wprowadzonych do
                Serwisu, jest Bartosz Szemraj prowadzący działalność gospodarczą
                pod firmą Bartosz Szemraj - strefa przemian.pl pod adresem: ul.
                Konstruktorska 10c/166, 02-673 Warszawa, adres e-mail:
                kontakt@strefaprzemian.pl, tel.: 669-191-963. Wszelkie
                informacje na temat danych osobowych znajdziesz w{` `}
                <LinkUnderline
                    Tag="a"
                    href="/docs/regulamin-serwisu-strefa-przemian.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Regulaminie Serwisu.
                </LinkUnderline>
            </>
        ),
        rodoHealthData: `Oświadczam, że wyrażam zgodę na przetwarzanie przez Administratora danych osobowych o moim stanie zdrowia w celu prawidłowego świadczenia przez Administratora na moją rzecz wybranych przeze mnie usług, zgodnie z postanowieniami Regulaminu. Wyrażenie zgody jest dobrowolne, jednakże niezbędne w celu realizacji wybranych usług abonamentowych przez Administratora. Wyrażona zgoda może być odwołana w każdym czasie poprzez przesłanie wiadomości na adres: kontakt@strefaprzemian.pl.`,
        newsletter: `Wyrażam zgodę na otrzymywanie od Bartosza Szemraj prowadzącego działalność gospodarczą pod firmą Bartosz Szemraj - strefa przemian.pl newsletteru na podany adres e-mail zawierającego informacje handlowe drogą elektroniczną w postaci wiadomości e-mail wysyłanych za pośrednictwem newsletteru. Wyrażenie zgody jest niezbędne w celu otrzymywania newsletteru oraz przedstawienia indywidualnej oferty produktów i usług drogą elektroniczną.`,
        newsletterShort: `Zapisz mnie do newslettera.`,
        recurringPaymentSubscription: `Upoważniam Administratora do obciążania mojej karty płatniczej w czasie trwania Abonamentu, w cyklu co 30 dni, kwotą należnej raty wykupionego Abonamentu, zgodnie z dokonanym wyborem liczby i wysokości rat oraz zobowiązuję się do niezwłocznego powiadomienia Administratora o wszelkich zmianach dotyczących numeru karty, daty jej ważności lub jakichkolwiek innych zmianach utrudniających realizację płatności.`,
        recurringPaymentTrainingAtlas: `Upoważniam Administratora do obciążania mojej karty płatniczej w cyklu co 30 dni, kwotą subskrypcji Atlasu treningów oraz zobowiązuję się do niezwłocznego powiadomienia Administratora o wszelkich zmianach dotyczących numeru karty, daty jej ważności lub jakichkolwiek innych zmianach utrudniających realizację płatności.`,
        warrantyOne: (
            <>
                Wybieram opcję „gwarantowana przemiana albo zwrot pieniędzy{` `}
                <LinkUnderline
                    Tag="a"
                    href="/docs/regulamin-serwisu-strefa-przemian.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    (szczegóły w Regulaminie).
                </LinkUnderline>
            </>
        ),
        warrantyTwo: (
            <>
                Gwarantowana przemiana albo zwrot pieniędzy.{` `}
                <LinkUnderline
                    Tag="a"
                    href="/docs/regulamin-serwisu-strefa-przemian.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Zobacz zasady.
                </LinkUnderline>
            </>
        ),
        withdraw: `Wyrażam zgodę na dostarczenie treści o charakterze cyfrowym przed upływem terminu do odstąpienia od umowy, co będzie jednoznaczne z utratą prawa odstąpienia od umowy, zgodnie z art. 38 pkt 13 ustawy z dnia 30 maja 2014 r. o prawach konsumenta (tj. z dnia 28 stycznia 2020 r. (Dz.U. z 2020 r. poz. 287 z późn. zm.)).`,
    },
};
