import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { button, wrapper, title, description } from './empty-messages-screen.module.scss';
import {
    fetchMessages,
    setFirstMessageAvailable,
} from '../../../redux/messenger/messages/fetch-actions';

import Title from '../../atoms/title';
import Button from '../../atoms/button';

const EmptyMessagesScreen = ({ className = '' }) => {
    const dispatch = useDispatch();

    const handleOnClickNewMessage = () => {
        dispatch(setFirstMessageAvailable());
    };

    useEffect(() => {
        const timeInterval = 10000;

        const interval = setInterval(() => {
            dispatch(fetchMessages(false, true));
        }, timeInterval);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch]);

    return (
        <div className={`${wrapper} ${className}`}>
            <Title Tag="h3" className={title}>
                Brak wiadomości
            </Title>
            <div className={description}>
                Aktualnie nie mamy do Ciebie żadnych wiadomości. Napisz do nas, a postaramy się jak
                najszybciej odpisać
            </div>
            <Button className={button} color={'yellow'} onClick={handleOnClickNewMessage}>
                ZACZNIJ KONWERSACJĘ
            </Button>
        </div>
    );
};

export default EmptyMessagesScreen;
