// extracted by mini-css-extract-plugin
export var button = "payu-card-form-module--button--L5qQX";
export var card = "payu-card-form-module--card--E4hg+";
export var cardCvv = "payu-card-form-module--card-cvv--WgWWQ";
export var cardExpiration = "payu-card-form-module--card-expiration--cB3Lz";
export var cardNumber = "payu-card-form-module--card-number--ftvoK";
export var container = "payu-card-form-module--container--6NNJa";
export var error = "payu-card-form-module--error--09AT7";
export var errorsBox = "payu-card-form-module--errors-box--1Ul3x";
export var header = "payu-card-form-module--header--a0Rgo";
export var input = "payu-card-form-module--input--Iy5jB";
export var label = "payu-card-form-module--label--zb-FN";
export var title = "payu-card-form-module--title--ZifHN";