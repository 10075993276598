import { actions, genActionName } from './generic-list.actions';

const { set, clear, clearItems } = actions.document;

export const genericListInitState = {
    items: [],
    filters: {},
    sort: {},
    search: {},
    pagination: {
        currentPage: 0,
    },
};

export const genericListReducer = (entity, subEntity) => {
    return function (state = genericListInitState, action) {
        const { type, payload } = action;

        switch (type) {
            case genActionName(`${entity} ${subEntity}`, set):
                return {
                    ...state,
                    ...payload,
                };
            case genActionName(`${entity} ${subEntity}`, clearItems):
                return {
                    ...state,
                    items: genericListInitState.items,
                    pagination: genericListInitState.pagination,
                };
            case genActionName(`${entity} ${subEntity}`, clear):
                return genericListInitState;
            default:
                return state;
        }
    };
};
