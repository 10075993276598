import React, { useLayoutEffect, useState } from 'react';

import {
    header,
    mobileNavList,
    mobileNavLink,
    mobileNavItem,
    mobileNav,
    linkActive,
    cart,
    hamburger,
    headerShopCart,
    navigation,
    scroll,
    clear,
    widgets,
} from './top-bar.module.scss';
import { mainGrid } from '../../style/grid.module.scss';
import HamburgerIconSVG from '../../assets/images/svg/icon-hamburger.svg';

import AccountWidget from '../molecules/account-widget';
import MainNavigation from '../molecules/main-navigation';
import CartWidget from '../molecules/cart/cart-widget';
import MobileNavPanel from './mobile-nav-panel';
import NestedNav from '../molecules/nested-nav';
import Logo from '../atoms/logo';

const mainNavItems = [
    {
        absolutePath: '/',
        label: 'Home',
        key: 'HOME',
    },
    {
        absolutePath: '/o-nas/',
        label: 'O nas',
        key: 'ABOUT',
    },
    {
        absolutePath: '/cennik/',
        label: 'Cennik',
        key: 'PRICING',
    },
    {
        absolutePath: '/sklep/',
        label: 'Sklep',
        key: 'SHOP',
    },
    {
        absolutePath: '/blog/',
        label: 'Blog',
        key: 'BLOG',
    },
    {
        absolutePath: '/faq/',
        label: 'FAQ',
        key: 'FAQ',
    },
    {
        absolutePath: '/kontakt/',
        label: 'Kontakt',
        key: 'CONTACT',
    },
];

const TopBar = ({ transparent, isShopCart = false, customColor = '' }) => {
    const [scrolled, setScrolled] = useState(false);
    const [isMainNavOpen, setIsMainNavOpen] = useState(false);

    useLayoutEffect(() => {
        const handleScroll = () => setScrolled(window.scrollY > 0);

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const mainNavClasses = {
        nav: mobileNav,
        navList: mobileNavList,
        navItem: mobileNavItem,
        navLink: mobileNavLink,
        navLinkActive: linkActive,
    };

    const style = customColor ? { style: { backgroundColor: customColor } } : {};

    return (
        <>
            <header
                {...style}
                className={`
                    ${mainGrid}
                    ${header}
                    ${isShopCart ? headerShopCart : ''}
                    ${scrolled ? scroll : ''}
                    ${transparent ? clear : ''}
                `}
            >
                <Logo />
                {!isShopCart ? (
                    <>
                        <MainNavigation
                            navItems={!isShopCart ? mainNavItems : []}
                            className={navigation}
                        />
                        <div className={widgets}>
                            <AccountWidget />
                            <CartWidget className={cart} />
                            <button className={hamburger} onClick={() => setIsMainNavOpen(true)}>
                                <HamburgerIconSVG />
                            </button>
                        </div>
                    </>
                ) : null}
            </header>
            <MobileNavPanel open={isMainNavOpen} closeFunc={() => setIsMainNavOpen(false)}>
                <NestedNav
                    navItems={mainNavItems}
                    classes={mainNavClasses}
                    onItemClick={() => setIsMainNavOpen(false)}
                    collapse={!isMainNavOpen}
                />
            </MobileNavPanel>
        </>
    );
};

export default TopBar;
