import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { wrapper } from './ania-bartek-about.module.scss';
import Facebook from '../../../../assets/images/svg/facebook.svg';
import Instagram from '../../../../assets/images/svg/instagram.svg';
import Youtube from '../../../../assets/images/svg/youtube.svg';

import Person from './person/person';

const AniaBartekAbout = () => {
    const imageQuery = useStaticQuery(query);

    const bartekData = {
        name: 'Bartek Szemraj',
        fluidImage: getImage(imageQuery.bartek),
        alt: 'Zdjęcie Bartka',
    };

    const bartekSocialMedia = [
        {
            Icon: Facebook,
            link: 'https://www.facebook.com/bartekszemraj/',
        },
        {
            Icon: Instagram,
            link: 'https://www.instagram.com/bartekszemraj/?hl=pl',
        },
        {
            Icon: Youtube,
            link: 'https://www.youtube.com/channel/UCvRhY-GuNPU02pMsh2ejl9w',
        },
    ];

    const aniaData = {
        name: 'Ania Kolasińska',
        fluidImage: getImage(imageQuery.ania),
        alt: 'Zdjęcie Ani',
    };

    const aniaSocialMedia = [
        {
            Icon: Facebook,
            link: 'https://www.facebook.com/aniakolasinskastrefaprzemian/',
        },
        {
            Icon: Instagram,
            link: 'https://www.instagram.com/aniakolasinska/?hl=pl',
        },
        {
            Icon: Youtube,
            link: 'https://www.youtube.com/channel/UCR65XPQSlBKYpNsCYXgAk8w',
        },
    ];

    return (
        <div className={wrapper}>
            <Person data={bartekData} socialData={bartekSocialMedia} />
            <Person data={aniaData} socialData={aniaSocialMedia} />
        </div>
    );
};

const query = graphql`
    {
        bartek: file(relativePath: { eq: "bartek-social-zone.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
        ania: file(relativePath: { eq: "ania-social-zone.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
        }
    }
`;

export default AniaBartekAbout;
