import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import {
    form,
    checkbox,
    checkboxAll,
    buttonBox,
    button,
} from './notification-settings-form.module.scss';
import { config } from '../../config';

import Checkbox from '../atoms/form-poc/checkbox';
import Button from '../atoms/button';
import Loader from '../atoms/loader';

const { formsStatusMap } = config;

const NotificationSettingsForm = ({ className, settings, onSubmit }) => {
    const formikRef = useRef(null);
    const [hasChanges, setHasChanges] = useState(false);
    const formikRefStatus = formikRef?.current?.status;

    const initialValues = getInitialValues(settings);

    const handleCancel = (formik) => {
        formik.resetForm();
        setHasChanges(false);
    };

    const handleValuesChange = (values) => {
        const newHasChanges = Object.keys(initialValues).some((key) => {
            return values[key] !== initialValues[key];
        });
        setHasChanges(newHasChanges);
    };

    const areAllSettingsChecked = (formik) => {
        return Object.values(formik.values).every((value) => value);
    };

    const handleToggleAll = (event, formik) => {
        const isChecked = event.target.checked;
        const newValues = Object.keys(formik.values).reduce((acc, key) => {
            acc[key] = isChecked;
            return acc;
        }, {});
        formik.setValues(newValues);
    };

    const handleSubmit = (values, formikBag) => {
        onSubmit(values, formikBag);
    };

    useEffect(() => {
        if (formikRef?.current?.status === formsStatusMap.success) {
            setHasChanges(false);
        }
    }, [formikRefStatus]);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={handleValuesChange}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {(formik) => {
                return (
                    <Form className={`${form} ${className}`}>
                        {formik.status === formsStatusMap.loading && (
                            <Loader fullContainer={true} />
                        )}
                        {Object.keys(settings).map((key) => {
                            return (
                                <Checkbox
                                    name={key}
                                    key={`checkbox-${key}`}
                                    containerClass={checkbox}
                                >
                                    {settings[key].label}
                                </Checkbox>
                            );
                        })}
                        <Checkbox
                            containerClass={checkboxAll}
                            name="checkAllSettings"
                            withoutFormik={true}
                            onChange={(event) => handleToggleAll(event, formik)}
                            checked={areAllSettingsChecked(formik)}
                        >
                            Wszystkie powiadomienia i wiadomości mailowe
                        </Checkbox>
                        <div className={buttonBox}>
                            <Button
                                className={button}
                                color="yellow"
                                size="small"
                                disabled={!hasChanges}
                            >
                                Zapisz ustawienia
                            </Button>
                            {hasChanges && (
                                <Button
                                    className={button}
                                    type="button"
                                    onClick={() => handleCancel(formik)}
                                    color="blank"
                                    size="small"
                                >
                                    Anuluj
                                </Button>
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

function getInitialValues(settings) {
    return Object.keys(settings).reduce((acc, key) => {
        acc[key] = settings[key].value;
        return acc;
    }, {});
}

NotificationSettingsForm.propTypes = {
    className: PropTypes.string,
    settings: PropTypes.objectOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.bool,
        })
    ).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

NotificationSettingsForm.defaultProps = {
    className: '',
};

export default NotificationSettingsForm;
