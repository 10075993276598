import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { container, header } from './notification-settings.module.scss';
import { updateProfile } from '../../../redux/profile/profile.actions';
import { getCurrentProfile } from '../../../redux/profile/profile.selectors';

import NotificationSettingsForm from '../../molecules/notification-settings-form';

const NotificationSettings = () => {
    const dispatch = useDispatch();
    const { notificationSettings } = useSelector(getCurrentProfile);

    const handleSubmit = (values, formikBag) => {
        dispatch(updateProfile({ notificationSettings: values }, formikBag));
    };

    return (
        <div className={container}>
            <h3 className={header}>
                Tutaj możesz zarządzać ustawieniami swoich powiadomień
                i&nbsp;wiadomości mailowych (włączyć je&nbsp;lub wyłączyć).
            </h3>
            <NotificationSettingsForm
                onSubmit={handleSubmit}
                settings={notificationSettings}
            />
        </div>
    );
};

export default NotificationSettings;
