import React from 'react';
import PropTypes from 'prop-types';

import { wrapper, avatar, dot, black } from './recipe-author.module.scss';

import Avatar from '../avatar';

const RecipeAuthor = ({ author, className, color }) => {
    const fullName = `${author.firstName} ${author.lastName}`;
    const colorClass = colorClasses[color];
    return (
        <div className={`${wrapper} ${className} ${colorClass}`}>
            <Avatar className={avatar} avatarUri={author.avatarUri} alt={fullName} />
            autor <span className={dot}>•</span> {fullName}
        </div>
    );
};

const colorClasses = {
    black: black,
    white: '',
};

RecipeAuthor.propTypes = {
    author: PropTypes.shape({
        firsName: PropTypes.string,
        lastName: PropTypes.string,
        avatarUri: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    color: PropTypes.oneOf(['white', 'black']),
};

RecipeAuthor.defaultProps = {
    className: '',
    color: 'white',
};

export default RecipeAuthor;
