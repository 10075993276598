import React from 'react';
import ReactSelect, { GroupBase, Props as ReactSelectProps } from 'react-select';

import './input-select.scss';

export default function Select<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
    className = '',
    ...props
}: ReactSelectProps<Option, IsMulti, Group>) {
    return (
        <ReactSelect
            className={`custom-select ${className}`}
            classNamePrefix="custom-select"
            {...props}
        />
    );
}
