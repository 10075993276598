// extracted by mini-css-extract-plugin
export var addProductButton = "add-shopping-list-module--add-product-button--sPFaA";
export var description = "add-shopping-list-module--description--EbTJO";
export var form = "add-shopping-list-module--form--Jy3iF";
export var globalErrors = "add-shopping-list-module--global-errors--aBN7l";
export var header = "add-shopping-list-module--header--vTWyr";
export var input = "add-shopping-list-module--input--b5A-h";
export var loading = "add-shopping-list-module--loading--IPP3B";
export var products = "add-shopping-list-module--products--NWDd7";
export var separator = "add-shopping-list-module--separator--D7zJ4";
export var successBox = "add-shopping-list-module--success-box--OySPI";
export var successButtons = "add-shopping-list-module--success-buttons--pjXVg";
export var successContent = "add-shopping-list-module--success-content--cnKKF";
export var title = "add-shopping-list-module--title--9oDsS";