// extracted by mini-css-extract-plugin
export var container = "meal-modal-module--container--1Tno7";
export var content = "meal-modal-module--content--wshNR";
export var description = "meal-modal-module--description--ONubZ";
export var editButton = "meal-modal-module--edit-button--IhbX5";
export var header = "meal-modal-module--header--mBz6z";
export var img = "meal-modal-module--img--256Oa";
export var loader = "meal-modal-module--loader--r2Azp";
export var modal = "meal-modal-module--modal--wuIPh";
export var ratioClass = "meal-modal-module--ratio-class--Kiaa0";
export var separator = "meal-modal-module--separator--HljFo";
export var subtitle = "meal-modal-module--subtitle--Lymog";
export var title = "meal-modal-module--title--KFfjD";