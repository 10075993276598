import { DiscountActions, types } from './discounts.actions';
import { IProduct } from '../../../models/product.model';
import { IPointsBenefitLabel, IProductDiscount } from '../../../models/product-promotion.model';

export type TDiscountState = Record<
    number,
    Pick<IProduct, 'productId' | 'name' | 'businessMeaning' | 'slug' | 'price' | 'basePrice'> & {
        promotions: IProductDiscount[];
        labelPointsBenefit: IPointsBenefitLabel;
    }
>;

const INITIAL_STATE: TDiscountState = {};

export default function discountsReducer(state = INITIAL_STATE, action: DiscountActions) {
    switch (action.type) {
        case types.SET_DISCOUNTS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
