import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
    button,
    fullwidth as fullwidthClass,
    small as smallClass,
    outline as outlineClass,
    circle as circleClass,
    inline as inlineClass,
    noMargin as noMarginClass,
    icon as iconClass,
    black,
    yellow,
    green,
    lime,
    blank,
    whiteYellow,
    white,
    grey,
    blankWhite,
    transparentBlack,
    red,
    disabled as disabledClass,
} from './button.module.scss';

const LinkButton = ({
    to,
    className = '',
    children,
    color,
    fullwidth,
    icon: Icon,
    inline,
    small,
    outline,
    circle,
    noMargin,
    external,
    disabled,
    ...rest
}) => {
    const Tag = external ? 'a' : Link;
    const tagProps = external
        ? {
              href: to,
              target: '_blank',
              rel: 'noopener noreferrer',
          }
        : { to };

    return (
        <Tag
            className={`
                ${button} ${getColorClass(color)}
                ${fullwidth ? fullwidthClass : ''}
                ${inline ? inlineClass : ''}
                ${small ? smallClass : ''}
                ${outline ? outlineClass : ''}
                ${circle ? circleClass : ''}
                ${noMargin ? noMarginClass : ''}
                ${disabled ? disabledClass : ''}
                ${className}
            `}
            {...tagProps}
            {...rest}
        >
            {children}
            {Icon && <Icon className={iconClass} />}
        </Tag>
    );
};

function getColorClass(color) {
    return colorClassMap[color] || '';
}

const colorClassMap = {
    black,
    yellow,
    green,
    lime,
    blank,
    whiteYellow,
    white,
    grey,
    blankWhite,
    transparentBlack,
    red,
};

LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.oneOf([
        'black',
        'yellow',
        'green',
        'blank',
        'whiteYellow',
        'white',
        'grey',
        'blankWhite',
        'transparentBlack',
        'lime',
    ]),
    onClick: PropTypes.func,
    fullwidth: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.elementType,
    inline: PropTypes.bool,
    small: PropTypes.bool,
    reverse: PropTypes.bool,
    outline: PropTypes.bool,
    circle: PropTypes.bool,
    noMargin: PropTypes.bool,
    external: PropTypes.bool,
    children: PropTypes.node,
};

LinkButton.defaultProps = {
    className: '',
    color: 'black',
    onClick: null,
    fullwidth: false,
    disabled: false,
    icon: null,
    inline: true,
    small: false,
    reverse: false,
    outline: false,
    circle: false,
    noMargin: false,
    external: false,
};

export default LinkButton;
