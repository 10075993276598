import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';

import { title, separator, simpleView } from './common-recipe-molecules.module.scss';
import { input, form, note as noteClass, submitButton } from './recipe-notes.module.scss';
import ChatIcon from '../../../assets/images/svg/icon-chat.svg';
import { config } from '../../../config';

import Button from '../../atoms/button';
import Input from '../../atoms/form-poc/input';
import Loader from '../../atoms/loader';
import RecipeNote from '../../atoms/recipe/recipe-note';
import Separator from '../../atoms/separator';

const { required } = config.messages.form;
const { formsStatusMap } = config;

const validationSchema = Yup.object({
    content: Yup.string().required(required),
});

const RecipeNotes = ({ className, id, notes, onNoteSubmit, onNoteDelete, unitId }) => {
    const handleSubmit = (formValues, formikBag) => {
        onNoteSubmit({ formValues, formikBag, id, unitId });
    };

    const initialValues = {
        content: '',
        ...notes,
    };

    return (
        <div className={`${className} ${simpleView}`}>
            <h2 className={title}>
                Uwagi <ChatIcon />
            </h2>
            <Separator className={separator} />
            {notes.map((note) => (
                <RecipeNote
                    id={id}
                    unitId={unitId}
                    key={`note-${note.id}`}
                    note={note}
                    className={noteClass}
                    onNoteDelete={onNoteDelete}
                />
            ))}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formik) => (
                    <Form className={form}>
                        {formik.status === formsStatusMap.loading && (
                            <Loader fullContainer={true} />
                        )}
                        <Input
                            name="content"
                            as="textarea"
                            containerClass={input}
                            rows={3}
                            placeholder="Twój komentarz..."
                        />
                        <Button color="yellow" className={submitButton} size="small">
                            Dodaj komentarz
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

RecipeNotes.propTypes = {
    className: PropTypes.string,
    id: PropTypes.number,
    notes: PropTypes.array,
    onNoteSubmit: PropTypes.func,
    onNoteDelete: PropTypes.func,
};

RecipeNotes.defaultProps = {
    className: '',
    id: null,
    notes: [],
};

export default RecipeNotes;
