import React from 'react';
import moment from 'moment';

import { link } from './planer-scroll-to-day-btn.module.scss';
import { cssDateVar } from '../../../utills/meal-planer';

const ScrollToDay = ({ day }) => {
    const dayLabelText = moment(day).format('ddd DD');

    const handleBackClick = () => {
        const dayLabel = document.getElementById(cssDateVar(day));
        dayLabel.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    return (
        <button type={'button'} className={link} value={cssDateVar(day)} onClick={handleBackClick}>
            {dayLabelText}
        </button>
    );
};

export default ScrollToDay;
