import React from 'react';

import { list, emptyListInfo } from './list.module.scss';

const List = ({
    items,
    ItemComponent,
    itemIdKey = 'id',
    listClassName = '',
    path,
    loading = false,
    itemProps,
    ...rest
}) => {
    return (
        <div className={`${list} ${listClassName}`} {...rest}>
            {items &&
                items.length > 0 &&
                items.map((item, index) => (
                    <ItemComponent
                        key={`list-item-${item[itemIdKey] || index}`}
                        item={item}
                        {...item}
                        path={path}
                        itemProps={itemProps}
                    />
                ))}
            {items && items.length === 0 && !loading && (
                <div className={emptyListInfo}>Niestety! Lista jest pusta.</div>
            )}
        </div>
    );
};

export default List;
