import { types as apiTypes } from '../api/api.actions';
import { prepareGenericListParams } from './generic-list.utils';

const genActionName = (entity, method) => {
    return `${entity} ${method}`;
};

const actions = {
    document: {
        set: 'set',
        clear: 'clear',
        clearItems: 'clear items',
    },
    api: {
        request: apiTypes.API_REQUEST,
        success: apiTypes.API_SUCCESS,
        fail: apiTypes.API_FAIL,
    },
    command: {
        get: 'get',
        fetch: 'fetch',
        filter: 'filter',
        sort: 'sort',
        search: 'search',
        del: 'delete',
        update: 'update',
        addItem: 'add_item',
    },
};

const getItems = ({ state, actionEntity, payload, fieldName, page = 1, params }) => ({
    type: genActionName(actionEntity, actions.command.get),
    payload: {
        params: params || prepareGenericListParams({ state, payload, fieldName, page }),
        url: payload.url,
    },
});

const clearList = (entity, subEntity) => ({
    type: genActionName(`${entity} ${subEntity}`, actions.document.clear),
});

const clearItems = (entity, subEntity) => ({
    type: genActionName(`${entity} ${subEntity}`, actions.document.clearItems),
});

export { genActionName, actions, getItems, clearList, clearItems };
