import { getAllMediaWithRelations, getMediaWithRelation } from './get-relation';
import { IMedia, IMediaImage } from '../models/media.model';

export function getMediaImage(
    media: IMedia[] | undefined = [],
    relation: number,
    fallback?: number | undefined
): IMediaImage {
    const mediaItem = getMediaWithRelation(media, relation, true, fallback);
    return getMediaImageFromMediaItem(mediaItem);
}

export function getAllMediaImages(
    media: IMedia[] | undefined = [],
    relations: number[]
): IMediaImage[] {
    const mediaItems = getAllMediaWithRelations(media, relations);
    return mediaItems.map((item) => getMediaImageFromMediaItem(item));
}

export function getMediaImageFromMediaItem(mediaItem?: IMedia): IMediaImage {
    return {
        alt: mediaItem?.alt || '',
        url: mediaItem?.url || '',
        sequence: mediaItem?.sequence || 0,
    };
}
