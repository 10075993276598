import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    title,
    container,
    header,
    avatarContainer,
    avatarCurrent,
    avatarNotCurrent,
    avatarName,
    changeButton,
    editButton,
    editButtonText,
    justifyCenter,
    myAccount,
    sidebar,
} from './my-account.module.scss';
import FaqEmotics from '../assets/images/svg/faq-emotics.svg';
import { getCurrentProfileId, getProfileListAsArray } from '../redux/profile/profile.selectors';
import { getRouteNodeAbsoluteNav } from '../routes';
import { changeCurrentProfileId } from '../redux/profile/profile.actions';
import { handleAvatarString } from '../redux/utills/nameUtill';

import Main from '../layouts/main';
import SEO from '../components/seo';
import Title from '../components/atoms/title';
import Separator from '../components/atoms/separator';
import Emotic from '../components/atoms/emotic';
import AccountNav from '../components/organisms/my-account/account-nav';
import Avatar from '../components/atoms/avatar';
import AvatarChange from '../components/molecules/avatar-change';

const MyAccount = ({ children }) => {
    const myAccountNavItems = getRouteNodeAbsoluteNav('APP_MY_ACCOUNT');

    const dispatch = useDispatch();

    const { profiles, currentProfileId } = useSelector((state) => ({
        profiles: getProfileListAsArray(state),
        currentProfileId: getCurrentProfileId(state),
    }));

    return (
        <>
            <SEO title="Moje konto" />
            <Main className={myAccount} isPaddingEqualToHeaderOnPhone={true}>
                <div className={header}>
                    <Emotic emotic={FaqEmotics} />
                    <Title Tag="h1" className={title}>
                        Moje konto
                    </Title>
                    <Separator />
                </div>
                <div className={container}>
                    <div className={sidebar}>
                        <div
                            className={`
                                ${avatarContainer}
                                ${profiles.length === 1 ? justifyCenter : ''}
                            `}
                        >
                            {profiles.map((profile) => {
                                if (profile.id === currentProfileId) {
                                    return (
                                        <div
                                            key={`profile-avatar--${profile.id}`}
                                            className={avatarCurrent}
                                        >
                                            <AvatarChange name="sidebar-avatar-upload">
                                                <div className={editButton}>
                                                    <div>
                                                        <Avatar
                                                            avatarUri={profile.avatarUri}
                                                            isDark={true}
                                                            size="large"
                                                            hasShadow={false}
                                                            hasBorder={true}
                                                        />
                                                    </div>
                                                    <span className={editButtonText}>edytuj</span>
                                                </div>
                                            </AvatarChange>
                                            {profile.firstName && (
                                                <p className={avatarName}>
                                                    {handleAvatarString(
                                                        profile.firstName,
                                                        profile.lastName
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <button
                                            key={`profile-avatar--${profile.id}`}
                                            className={`
                                                ${changeButton}
                                                ${avatarNotCurrent}
                                            `}
                                            onClick={() =>
                                                dispatch(changeCurrentProfileId(profile.id))
                                            }
                                        >
                                            <Avatar
                                                avatarUri={profile.avatarUri}
                                                size="small"
                                                isDark={true}
                                                hasShadow={false}
                                                hasBorder={true}
                                            />
                                        </button>
                                    );
                                }
                            })}
                        </div>
                        <AccountNav navItems={myAccountNavItems} />
                    </div>
                    <div>{children}</div>
                </div>
            </Main>
        </>
    );
};

export default MyAccount;
