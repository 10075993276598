import React from 'react';

import { actions } from './rbac.actions';
import { addModalAction } from '../actions/actions-modals';
import RestrictedModal from '../../components/molecules/restricted-modal';

const { FORBIDDEN_CLICK } = actions;

const rbacMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, meta } = action;
    next(action);

    switch (type) {
        case FORBIDDEN_CLICK:
            const onClose =
                meta?.onClose && typeof meta.onClose === 'function'
                    ? { onClose: meta.onClose }
                    : {};
            dispatch(
                addModalAction({
                    ...onClose,
                    renderComponent: (modalId) => (
                        <RestrictedModal
                            modalId={modalId}
                            permissionEntity={payload}
                        />
                    ),
                })
            );
            break;
        // no default
    }
};

export default rbacMiddleware;
