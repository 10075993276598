import { types } from './single-shopping-list.actions';

const { SET_SINGLE_SHOPPING_LIST, RESTORE_INITIAL_STATE } = types;

const initialState = null;

const singleShoppingListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SINGLE_SHOPPING_LIST:
            return { ...payload };
        case RESTORE_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default singleShoppingListReducer;
