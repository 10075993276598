import { types } from './payu.actions';

const { SET_CHECK_IN_PROGRESS, SET_CHECK_COUNTER, CLEAR_PAYU, SET_ORDER_STATUS } = types;

export const initialState = {
    isChecking: false,
    checkCounter: 0,
    orderStatus: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_CHECK_IN_PROGRESS:
            return {
                ...state,
                isChecking: payload,
            };
        case SET_CHECK_COUNTER:
            return {
                ...state,
                checkCounter: payload,
            };
        case SET_ORDER_STATUS:
            return {
                ...state,
                orderStatus: payload,
            };
        case CLEAR_PAYU:
            return initialState;
        default:
            return state;
    }
}
