import { getSingleRecipe } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';

export const getSingleRecipeAction = (id) => async (dispatch) => {
    dispatch(getSingleRecipeStartAction());

    try {
        const res = await getSingleRecipe(id);

        if (res.status === 200) {
            dispatch(getSingleRecipeSuccessAction(res.data));
        }
    } catch (error) {
        dispatch(getSingleRecipeErrorAction(error));
    }
};

const getSingleRecipeStartAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.get,
        entitiesMap.recipes.single.allowedActions.start
    ),
});

export const getSingleRecipeSuccessAction = (data) => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.get,
        entitiesMap.recipes.single.allowedActions.success
    ),
    payload: data,
});

const getSingleRecipeErrorAction = (error) => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.get,
        entitiesMap.recipes.single.allowedActions.error
    ),
    payload: error,
});

export const getSingleRecipeClearErrorsAction = () => ({
    type: getFullActionName(
        entitiesMap.recipes.single.baseName,
        entitiesMap.recipes.single.methodName.get,
        entitiesMap.recipes.single.allowedActions.clearErrors
    ),
});
