import { formatFormikErrors } from '../../communication/utils';
import { config } from '../../config';

export const setFormikStatus = ({ formikBag, status, error }) => {
    if (formikBag) {
        formikBag.setStatus(status);

        if (status === config.formsStatusMap.fail) {
            formikBag.setErrors(formatFormikErrors(error));
        }
    }
};
