import React, { MouseEventHandler, TouchEventHandler, useEffect, useRef, useState } from 'react';

import { container, list, button, disabled } from './thread-category-list.module.scss';
import { IThreadCategory } from '../../models/thread.model';

import Button from '../atoms/button';

export interface IThreadCategoryListProps {
    className?: string;
    categories: IThreadCategory[];
    onCategorySelect?(category: IThreadCategory): void;
    activeCategoryId?: number;
}

const ThreadCategoryList: React.FC<IThreadCategoryListProps> = ({
    className = '',
    categories,
    onCategorySelect,
    activeCategoryId,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLUListElement>(null);

    const [maxDelta, setMaxDelta] = useState(0);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isStart, setIsStart] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [x, setX] = useState(0);
    const [startX, setStartX] = useState(0);

    const handleCategorySelect = (category: IThreadCategory) => {
        return () => {
            if (typeof onCategorySelect === 'function') {
                onCategorySelect(category);
            }
        };
    };

    const handleMouseMove: MouseEventHandler = (event) => {
        if (!isStart) return;
        setIsDragging(true);
        handleMove(event.clientX);
    };

    const handleTouchMove: TouchEventHandler = (event) => {
        if (!isStart) return;
        setIsDragging(true);
        handleMove(event.targetTouches[0].clientX);
    };

    const handleMove = (clientX: number) => {
        const deltaX = clientX - startX;
        setStartX(clientX);
        setX((prevX) => {
            const newX = prevX + deltaX;
            if (newX > 0) {
                return 0;
            } else if (newX < maxDelta) {
                return maxDelta;
            } else {
                return newX;
            }
        });
    };

    useEffect(() => {
        const handleResize = () => {
            const containerEl = containerRef.current;
            const listEl = listRef.current;
            if (!containerEl || !listEl) return;

            const listHeight = listEl.getBoundingClientRect().height;
            const listWidth = listEl.getBoundingClientRect().width;
            const containerWidth = containerEl.getBoundingClientRect().width;

            // 832px - tablet breakpoint
            if (window.innerWidth < 832) {
                containerEl.style.height = `${listHeight}px`;
            } else {
                containerEl.style.height = `auto`;
            }

            if (listWidth > containerWidth) {
                setMaxDelta(containerWidth - listWidth);
                setIsDragActive(true);
            } else {
                setMaxDelta(0);
                setIsDragActive(false);
                setX(0);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!isDragActive) return;

        const listEl = listRef.current;
        if (!listEl) return;

        const handleMouseDown = (event: MouseEvent) => {
            if (event.target !== listEl && !listEl.contains(event.target as Node)) return;
            handleStart(event.clientX);
        };

        const handleTouchStart = (event: TouchEvent) => {
            if (event.target !== listEl && !listEl.contains(event.target as Node)) return;
            handleStart(event.targetTouches[0].clientX);
        };

        const handleStart = (clientX: number) => {
            setIsStart(true);
            setStartX(clientX);
        };

        const handleMouseUp = () => {
            setIsDragging(false);
            setIsStart(false);
        };

        const handleTouchEnd = () => {
            setIsDragging(false);
            setIsStart(false);
        };

        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isDragActive]);

    return (
        <div ref={containerRef} className={`${container} ${className}`}>
            <ul
                ref={listRef}
                className={list}
                onMouseMove={handleMouseMove}
                onTouchMove={handleTouchMove}
                style={{ transform: `translateX(${x}px)` }}
            >
                {categories.map((category, index) => {
                    const isActive = category.categoryId === activeCategoryId;
                    return (
                        <li key={`thread-category-${category.categoryId}`}>
                            <Button
                                className={`${button} ${isDragging ? disabled : ''}`}
                                color={isActive ? 'yellow' : 'blank'}
                                size="small"
                                onClick={handleCategorySelect(category)}
                            >
                                {category.name}
                            </Button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ThreadCategoryList;
