import { types as apiTypes } from '../../api/api.actions';

export const CART = '[CART]';

export const types = {
    // user triggered actions
    ADD_ITEM: `${CART} add_item`,
    DEL_ITEM: `${CART} del_item`,

    // flow/commands actions
    CHECK_CART: `${CART} check_cart`, // check user and guest carts
    PREPARE_SEND_DATA: `${CART} prepare_send_data`, //prepatre items quty for send
    HANDLE_GUEST_CART: `${CART} handle_guest_cart`, // read/write/clear cart to local storage if guest
    PREPARE_CART_DATA: `${CART} prepare_data`,

    // api actions
    SEND_CART_REQUEST: `${CART} ${apiTypes.API_REQUEST}`,
    SEND_CART_SUCCESS: `${CART} ${apiTypes.API_SUCCESS}`,
    SEND_CART_FAIL: `${CART} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_CART_DATA: `${CART} set_data`,
    CLEAR_CART: `${CART} clear`,
    SET_CART_ERRORS: `${CART} set_errors`,
    MERGE_CART_DATA: `${CART} merge_data`,
};

// reducer actions
export const setCartData = (payload) => ({
    type: types.SET_CART_DATA,
    payload: payload,
});

export const clearCartData = () => ({
    type: types.CLEAR_CART,
});

export const setErrors = (payload) => ({
    type: types.SET_CART_ERRORS,
    payload: payload,
});

export const mergeCartData = (payload) => ({
    type: types.MERGE_CART_DATA,
    payload,
});

//middleware actions

export const handleGuestCart = (payload) => ({
    type: types.HANDLE_GUEST_CART,
    payload: payload,
});

export const addSingleProduct = (payload, meta) => ({
    type: types.ADD_ITEM,
    payload: {
        productId: payload.productId,
        variantId: payload.variantId,
        quantity: 1,
    },
    meta: meta,
});

export const delSingleProduct = (payload, meta) => ({
    type: types.DEL_ITEM,
    payload: {
        productId: payload.productId,
        variantId: payload.variantId,
        quantity: 1,
    },
    meta,
});

export const sendCart = (payload, meta) => ({
    type: types.PREPARE_CART_DATA,
    payload: payload,
    meta: meta,
});
