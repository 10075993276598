import { types } from './welcome-slides.actions';

const { SET_WELCOME_SLIDES, CLEAR_WELCOME_SLIDES } = types;

const initialState = [];

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        case SET_WELCOME_SLIDES:
            return payload;
        case CLEAR_WELCOME_SLIDES:
            return initialState;
        default:
            return state;
    }
}
