import React, { useEffect, useState } from 'react';

import { loaderWrapper } from './gamification-list-view.module.scss';
import { config } from '../../config';
import { GAMIFICATION } from '../../redux/gamification/gamification.reducer';
import useGenericList from '../../hooks/use-generic-list';

import PointsList, { IPointsListProps } from '../../components/molecules/points-list';
import PointsTitle from '../../components/atoms/points-title';
import Loader from '../../components/atoms/loader';
import IntersectBox from '../../components/atoms/intersect-box';

export interface IGamificationListView<InputItemType> {
    title: string;
    subEntity: string;
    url: string;
    itemTransformFunction: (item: InputItemType) => IPointsListProps['items'][0];
}

export default function GamificationListView<InputItemType>({
    title,
    subEntity,
    url,
    itemTransformFunction,
}: IGamificationListView<InputItemType>) {
    const { items, getNextPage, apiStatus, pagination, clear } = useGenericList({
        entity: GAMIFICATION,
        subEntity,
        url,
        perPage: 50,
    });
    const [listReloaded, setListReloaded] = useState(false);

    const isLoading = apiStatus === config.apiStatusMap.loading;

    const pointListItems = items.map(itemTransformFunction);

    useEffect(() => {
        if (pagination.currentPage > 0 && !listReloaded) {
            clear();
            getNextPage();
        }

        setListReloaded(true);
    }, [getNextPage, pagination, listReloaded, clear]);

    let content = (
        <>
            <PointsList items={pointListItems} />
            <IntersectBox onIntersecting={getNextPage}>{isLoading && <Loader />}</IntersectBox>
        </>
    );

    if (isLoading && pagination.currentPage === 0) {
        content = (
            <div className={loaderWrapper}>
                <Loader fullContainer />
            </div>
        );
    }

    return (
        <>
            <PointsTitle>{title}</PointsTitle>
            {content}
        </>
    );
}
