import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { title, list, container, deleteButton, deleteBox } from './my-subscriptions.module.scss';
import { ISubscription } from '../../../models/subscription.model';
import { TStatus } from '../../../models/status.model';
import { IDeleteReasonModalProps } from '../custom-modals/delete-reason-modal';
import { IUserDataRedux } from '../../../models/user.model';
import {
    selectSubscriptionsItems,
    selectSubscriptionsStatus,
} from '../../../redux/subscriptions/subscriptions.selectors';
import {
    clearSubscriptionsAction,
    getSubscriptionsAction,
} from '../../../redux/subscriptions/subscriptions.actions';
import { clearSubscriptionAction } from '../../../redux/subscription/subscription.actions';
import {
    selectSubscriptionDemandOfferStatus,
    selectSubscriptionStatus,
} from '../../../redux/subscription/subscription.selectors';
import { selectUserData } from '../../../redux/user/user.selectors';
import { addModalAction } from '../../../redux/actions/actions-modals';
import { deleteAccountAction } from '../../../redux/user/user.actions';

import Loader from '../../atoms/loader';
import DashboardBar from '../../molecules/dashboard-bar';
import SubscriptionCard from '../../molecules/subscription-card';
import Button from '../../atoms/button';

const MySubscriptions: React.FC = () => {
    const dispatch = useDispatch();
    const subscriptions: ISubscription[] = useSelector(selectSubscriptionsItems);
    const status: TStatus | undefined = useSelector(selectSubscriptionsStatus);
    const subscriptionStatus: TStatus | undefined = useSelector(selectSubscriptionStatus);
    const demandStatus: TStatus | undefined = useSelector(selectSubscriptionDemandOfferStatus);
    const user: IUserDataRedux | undefined = useSelector(selectUserData);

    const handleAccountDelete = () => {
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: 'Czy na pewno?',
                    onConfirm: handleAccountDeleteConfirmation,
                },
            })
        );
    };

    const handleAccountDeleteConfirmation = () => {
        dispatch(
            addModalAction({
                modalKey: 'DELETE_REASON_MODAL',
                modalProps: {
                    content: copy.reasonContent,
                    placeholder: copy.reasonPlaceholder,
                    onSubmit: handleDeleteAccountSubmit,
                },
            })
        );
    };

    const handleDeleteAccountSubmit: IDeleteReasonModalProps['onSubmit'] = (
        values,
        helpers,
        modalId
    ) => {
        dispatch(deleteAccountAction(values, helpers, modalId));
    };

    useEffect(() => {
        dispatch(getSubscriptionsAction());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearSubscriptionsAction());
            dispatch(clearSubscriptionAction());
        };
    }, [dispatch]);

    if (!status || status === 'loading' || status === 'idle') {
        return <Loader />;
    }

    if (status === 'fail') {
        return <p>{copy.fail}</p>;
    }

    return (
        <div className={container}>
            {(subscriptionStatus === 'loading' || demandStatus === 'loading') && (
                <Loader fullContainer={true} />
            )}
            {subscriptions.length > 0 ? (
                <div>
                    <h3 className={title}>{copy.title}</h3>
                    <ul className={list}>
                        {subscriptions.map((subscription) => {
                            return (
                                <li key={`subscription-${subscription.subscriptionId}`}>
                                    <SubscriptionCard subscription={subscription} />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : (
                <DashboardBar to="/cennik" buttonText={copy.link} linkType="gatsby">
                    {copy.noSubscriptionText}
                </DashboardBar>
            )}
            {user?.canDeleteAccount && (
                <div className={deleteBox}>
                    <h3 className={title}>{copy.titleDelete}</h3>
                    <Button
                        className={deleteButton}
                        size="small"
                        color="red"
                        onClick={handleAccountDelete}
                    >
                        {copy.buttonDelete}
                    </Button>
                </div>
            )}
        </div>
    );
};

const copy = {
    title: `Twój obecny abonament:`,
    noSubscriptionText: `Nie posiadasz obecnie żadnych aktywnych subskrypcji`,
    link: `Przejdź do cennika`,
    fail: `Coś poszło nie tak. Nie udało się pobrać danych subskrypcji...`,
    titleDelete: 'Usuwanie konta:',
    buttonDelete: 'Usuń konto',
    reasonContent:
        'Przykro nam, że odchodzisz. Mamy jeszcze prośbę, napisz nam co Ci się nie podobało w serwisie Strefa Przemian lub co powinniśmy poprawić?',
    reasonPlaceholder: 'Napisz, dlaczego odchodzisz...',
};

export default MySubscriptions;
