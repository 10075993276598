import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPointsButtonWrapper } from './get-points-button.module.scss';
import {
    postTransaction,
    postTransactionValidate,
} from '../../redux/gamification/gamification.actions';
import {
    selectGamificationLoading,
    selectIsForbiddenReasonId,
} from '../../redux/gamification/gamification.selectors';
import isGamificationEnabled from '../../utills/gamification/is-gamification-enabled';

import Button from '../atoms/button';
import Loader from '../atoms/loader';

export interface IGetPointsButtonProps {
    children: string;
    reasonId: number;
    objectId: number;
}

export default function GetPointsButton({ children, reasonId, objectId }: IGetPointsButtonProps) {
    const isForbidden = useSelector(selectIsForbiddenReasonId({ reasonId, objectId }));
    const isLoading = useSelector(selectGamificationLoading) === 'loading';
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(postTransaction({ reasonId, objectId }));
    };

    useEffect(() => {
        if (objectId) {
            dispatch(postTransactionValidate({ reasonId, objectId }));
        }
    }, [dispatch, reasonId, objectId]);

    if (!isGamificationEnabled()) {
        return null;
    }

    return (
        <div className={getPointsButtonWrapper}>
            {isLoading && <Loader fullContainer />}
            <Button color={isForbidden ? 'grey' : 'yellow'} onClick={onClick}>
                {children}
            </Button>
        </div>
    );
}
