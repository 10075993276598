import React from 'react';
import { useDispatch } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    button,
    title,
    wrapper,
    listItem,
    mr,
    iconsHolder,
    iconSingleHolder,
    timeIcon,
    tvIcon,
    pillContainer,
} from './course-not-owned-information.module.scss';

import TimeIcon from '../../../assets/images/svg/time.svg';
import OkIcon from '../../../assets/images/svg/ok.svg';
import { addSingleProduct } from '../../../redux/shop/cart/cart.actions';
import { humanizedSeconds } from '../../../communication/utils';

import Button from '../../atoms/button';
import Markdown from '../../hoc/markdown';

const CourseNotOwnedInformation = ({ courseData, className = '' }) => {
    const { description, points, price, productId, moviesCount, moviesTimeCount } = courseData;

    // TODO: Gatsby Image - change to Static Image
    const queryData = useStaticQuery(query);
    const dispatch = useDispatch();

    const addToCart = () => {
        const payload = {
            productId: productId,
            variantId: null,
        };
        dispatch(addSingleProduct(payload));
    };

    return (
        <div className={`${wrapper} ${className}`}>
            <div className={title}>
                <Markdown>{description}</Markdown>
            </div>
            {points && (
                <ul className={pillContainer}>
                    {points.map((point, index) => (
                        <li key={`point--${index}`} className={listItem}>
                            <OkIcon /> {point?.content}
                        </li>
                    ))}
                </ul>
            )}
            <div className={iconsHolder}>
                <div className={`${iconSingleHolder} ${mr}`}>
                    <GatsbyImage image={getImage(queryData.tv)} className={tvIcon} alt="tv icon" />
                    filmy: {moviesCount}
                </div>
                <div className={iconSingleHolder}>
                    <TimeIcon className={timeIcon} /> {humanizedSeconds(moviesTimeCount)}
                </div>
            </div>
            <Button className={button} color={'yellow'} onClick={() => addToCart()}>
                KUP TEN KURS ZA {price.grossDisplay}
            </Button>
        </div>
    );
};

const query = graphql`
    {
        tv: file(relativePath: { eq: "tv.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 25
                    height: 20
                    quality: 100
                    placeholder: BLURRED
                    layout: FIXED
                )
            }
        }
    }
`;

export default CourseNotOwnedInformation;
