import React from 'react';

import { FilterCommonProps, FilterListItems } from '../../../models/generic-list-filters.model';

import FilterGroup from './filter-group';
import FilterSelect from '../../atoms/filter-atoms/filter-select';

export interface IFilterList {
    items: FilterListItems;
    itemProps: Partial<Omit<FilterCommonProps, 'group'>>;
}

export default function FilterList({ items, itemProps }: IFilterList) {
    return (
        <>
            {Object.entries(items).map(([key, filterGroup]) => {
                let FilterTag: React.ElementType = FilterGroup;

                if (filterGroup.type === 'select') {
                    FilterTag = FilterSelect;
                }

                return (
                    <FilterTag
                        key={`filter-group-${key}`}
                        group={{ key, ...filterGroup }}
                        {...itemProps}
                    />
                );
            })}
        </>
    );
}
