import { changeSingleRecipeIngredientToProfile } from '../../../../communication/recipes';
import { entitiesMap, getFullActionName } from '../../../reusable-reducers/entities-map';

export const changeSingleRecipeIngredientToProfileAction = (id, profileId, data) => async (
    dispatch
) => {
    dispatch(changeSingleRecipeIngredientToProfileStartAction());
    try {
        await changeSingleRecipeIngredientToProfile(id, profileId, data);
        dispatch(changeSingleRecipeIngredientToProfileSuccessAction());
    } catch {
        dispatch(changeSingleRecipeIngredientToProfileErrorAction());
    }
};

const changeSingleRecipeIngredientToProfileStartAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.changeIngredientToProfile,
            entitiesMap.recipes.single.allowedActions.start
        ),
        payload: {},
    };
};

const changeSingleRecipeIngredientToProfileSuccessAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.changeIngredientToProfile,
            entitiesMap.recipes.single.allowedActions.success
        ),
        payload: {},
    };
};

const changeSingleRecipeIngredientToProfileErrorAction = () => {
    return {
        type: getFullActionName(
            entitiesMap.recipes.single.baseName,
            entitiesMap.recipes.single.methodName.changeIngredientToProfile,
            entitiesMap.recipes.single.allowedActions.error
        ),
        payload: {},
    };
};
