import { ADD_MODAL, REMOVE_MODAL, REMOVE_MULTIPLE_MODALS, EDIT_MODAL } from './actionTypes';

export const addModalAction = (modalData) => {
    const newModal = {
        id: new Date().getTime(),
        data: { ...modalData },
    };

    return {
        type: ADD_MODAL,
        payload: newModal,
    };
};

export const editModalAction = (payload) => {
    return {
        type: EDIT_MODAL,
        payload,
    };
};

export const removeModalAction = (id) => {
    return {
        type: REMOVE_MODAL,
        payload: id,
    };
};

export const removeMultipleModalsAction = (idArr) => {
    return {
        type: REMOVE_MULTIPLE_MODALS,
        payload: idArr,
    };
};
