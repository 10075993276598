import { types as apiTypes } from '../api/api.actions';

export const NOTIFICATIONS = '[NOTIFICATIONS]';

export const types = {
    GET_NOTIFICATIONS: `${NOTIFICATIONS} get`,
    GET_NOTIFICATIONS_COUNT: `${NOTIFICATIONS} get count`,
    GET_NOTIFICATIONS_COUNT_INTERVAL: `${NOTIFICATIONS} get count in interval`,
    MARK_NOTIFICATIONS_AS_SEEN: `${NOTIFICATIONS} mark as seen`,
    MARK_NOTIFICATIONS_AS_READ: `${NOTIFICATIONS} mark as read`,
    CLEAR_NOTIFICATIONS: `${NOTIFICATIONS} clear`,
    CLEAR_NOTIFICATIONS_ITEMS: `${NOTIFICATIONS} clear items`,

    FETCH_NOTIFICATIONS_REQUEST: `${NOTIFICATIONS} ${apiTypes.API_REQUEST}`,
    FETCH_NOTIFICATIONS_SUCCESS: `${NOTIFICATIONS} ${apiTypes.API_SUCCESS}`,
    FETCH_NOTIFICATIONS_FAIL: `${NOTIFICATIONS} ${apiTypes.API_FAIL}`,

    FETCH_NOTIFICATIONS_COUNT_REQUEST: `${NOTIFICATIONS} ${apiTypes.API_REQUEST} count`,
    FETCH_NOTIFICATIONS_COUNT_SUCCESS: `${NOTIFICATIONS} ${apiTypes.API_SUCCESS} count`,
    FETCH_NOTIFICATIONS_COUNT_FAIL: `${NOTIFICATIONS} ${apiTypes.API_FAIL} count`,

    SEND_NOTIFICATIONS_SEEN_REQUEST: `${NOTIFICATIONS} ${apiTypes.API_REQUEST} seen`,
    SEND_NOTIFICATIONS_SEEN_SUCCESS: `${NOTIFICATIONS} ${apiTypes.API_SUCCESS} seen`,
    SEND_NOTIFICATIONS_SEEN_FAIL: `${NOTIFICATIONS} ${apiTypes.API_FAIL} seen`,

    SEND_NOTIFICATIONS_READ_REQUEST: `${NOTIFICATIONS} ${apiTypes.API_REQUEST} read`,
    SEND_NOTIFICATIONS_READ_SUCCESS: `${NOTIFICATIONS} ${apiTypes.API_SUCCESS} read`,
    SEND_NOTIFICATIONS_READ_FAIL: `${NOTIFICATIONS} ${apiTypes.API_FAIL} read`,

    SET_NOTIFICATIONS: `${NOTIFICATIONS} set`,
    SET_NOTIFICATIONS_INTERVAL_ID: `${NOTIFICATIONS} set interval`,
    SET_NOTIFICATIONS_COUNT: `${NOTIFICATIONS} set count`,
    SET_NEW_NOTIFICATIONS_AVAILABLE: `${NOTIFICATIONS} new available`,
    RESTORE_NOTIFICATIONS_INITIAL_ITEMS: `${NOTIFICATIONS} restore items`,
    RESTORE_NOTIFICATIONS_INITIAL_STATE: `${NOTIFICATIONS} restore`,
};

export const getNotificationsIntervalAction = () => ({
    type: types.GET_NOTIFICATIONS_COUNT_INTERVAL,
});

export const setNotificationsIntervalId = (intervalId) => ({
    type: types.SET_NOTIFICATIONS_INTERVAL_ID,
    payload: intervalId,
});

export const getNotificationsCountAction = () => ({
    type: types.GET_NOTIFICATIONS_COUNT,
});

export const getNotificationsAction = (page, perPage) => ({
    type: types.GET_NOTIFICATIONS,
    payload: { page, perPage },
});

export const clearNotificationsAction = () => ({
    type: types.CLEAR_NOTIFICATIONS,
});

export const setNotificationsAction = (data) => ({
    type: types.SET_NOTIFICATIONS,
    payload: data,
});

export const setNotificationsCountAction = (count) => ({
    type: types.SET_NOTIFICATIONS_COUNT,
    payload: count,
});

export const markNotificationsAsReadAction = () => ({
    type: types.MARK_NOTIFICATIONS_AS_READ,
});

export const markNotificationsAsSeen = () => ({
    type: types.MARK_NOTIFICATIONS_AS_SEEN,
});

export const restoreNotificationsInitialItemsAction = () => ({
    type: types.RESTORE_NOTIFICATIONS_INITIAL_ITEMS,
});

export const clearNotificationsItemsAction = () => ({
    type: types.CLEAR_NOTIFICATIONS_ITEMS,
});

export const restoreNotificationsInitialStateAction = () => ({
    type: types.RESTORE_NOTIFICATIONS_INITIAL_STATE,
});

export const setNewNotificationsAvailableAction = () => ({
    type: types.SET_NEW_NOTIFICATIONS_AVAILABLE,
});
