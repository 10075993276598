import React from 'react';

import { subtitle, light as lightClass } from './subtitle.module.scss';

export interface ISubTitleProps {
    Tag?: React.ElementType;
    className?: string;
    children?: React.ReactNode;
    light?: boolean;
}

const SubTitle = ({ children, light, className = '', Tag = 'h3' }: ISubTitleProps) => (
    <Tag
        className={`${subtitle} 
            ${light ? lightClass : ''}
            ${className}`}
    >
        {children}
    </Tag>
);

export default SubTitle;
