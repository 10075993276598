import React from 'react';
import { FieldArray } from 'formik';

import { container, deleteButton, addButton, inputGroup } from './steps-field-array.module.scss';
import PlusIcon from '../../assets/images/svg/plus.svg';
import TrashIcon from '../../assets/images/svg/icon-trash.svg';

import Input from '../atoms/form-poc/input';
import Button from '../atoms/button';

const StepsFieldArray = ({ name, formik, className = '', addButtonClass }) => {
    const handleAddStep = (arrayHelpers) => {
        arrayHelpers.insert(formik.values[name].length, '');
    };

    const handleDeleteStep = (arrayHelpers, index) => {
        if (formik.values[name].length === 1) {
            arrayHelpers.replace(index, '');
            return;
        }
        arrayHelpers.remove(index);
    };

    return (
        <FieldArray name={name}>
            {(arrayHelpers) => (
                <div className={`${container} ${className}`}>
                    {formik.values[name].map((step, index) => (
                        <div className={inputGroup} key={`input-group-${name}-${index}`}>
                            <Input
                                label={`Krok ${index + 1}`}
                                name={`${name}.${index}`}
                                as="textarea"
                                rows={5}
                                placeholder="Opisz kolejny krok"
                            />
                            <button
                                type="button"
                                className={deleteButton}
                                onClick={() => handleDeleteStep(arrayHelpers, index)}
                                aria-label={`usuń krok`}
                            >
                                <TrashIcon />
                            </button>
                        </div>
                    ))}
                    <Button
                        type="button"
                        icon={PlusIcon}
                        color="blank"
                        size="small"
                        className={`${addButton} ${addButtonClass}`}
                        onClick={() => handleAddStep(arrayHelpers)}
                    >
                        Dodaj kolejny krok
                    </Button>
                </div>
            )}
        </FieldArray>
    );
};

export default StepsFieldArray;
