import React from 'react';
import PropTypes from 'prop-types';

import { titleText, topImage, container, closeButton } from './custom-modal.module.scss';

import ButtonCloseModal from '../components/atoms/button-close-modal';

const CustomModal = ({ className, modalId, title, imageOnTop, forceAction, children }) => {
    return (
        <div
            className={`
                ${container}
                ${className}
                ${imageOnTop ? topImage : ''}
            `}
        >
            {!forceAction && <ButtonCloseModal className={closeButton} modalId={modalId} />}
            {title && <h2 className={titleText}>{title}</h2>}
            {children}
        </div>
    );
};

CustomModal.propTypes = {
    className: PropTypes.string,
    modalId: PropTypes.number,
    title: PropTypes.string,
    imageOnTop: PropTypes.bool,
    forceAction: PropTypes.bool,
    onClose: PropTypes.func,
};

CustomModal.defaultProps = {
    className: '',
    modalId: null,
    title: '',
    imageOnTop: false,
    forceAction: false,
    onClose: () => {},
};

export default CustomModal;
