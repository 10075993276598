import { combineReducers } from 'redux';
import { genericListReducer } from '../generic-list/generic-list.reducer';

export const RECIPES = '[Recipes]';
export const PUBLIC = 'Public';
export const YOUR = 'Your';
export const FAVORITE = 'Favorite';
export const ALL = 'All';

const recipesReducer = combineReducers({
    [PUBLIC]: genericListReducer(RECIPES, PUBLIC),
    [YOUR]: genericListReducer(RECIPES, YOUR),
    [FAVORITE]: genericListReducer(RECIPES, FAVORITE),
    [ALL]: genericListReducer(RECIPES, ALL),
    // add more reducers for subEntities if needed
});

export default recipesReducer;
