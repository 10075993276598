import { types as apiTypes } from '../../api/api.actions';

export const ORDER_HISTORY = '[ORDER_HISTORY]';

export const types = {
    // flow/commands actions
    GET_ORDERS_LIST: `${ORDER_HISTORY} get list`, // check user and guest carts
    GET_SINGLE_ORDER: `${ORDER_HISTORY} get single order`, //prepatre items quty for send

    // api actions
    SEND_ORDER_HISTORY_REQUEST: `${ORDER_HISTORY} ${apiTypes.API_REQUEST}`,
    SEND_ORDER_HISTORY_SUCCESS: `${ORDER_HISTORY} ${apiTypes.API_SUCCESS}`,
    SEND_ORDER_HISTORY_FAIL: `${ORDER_HISTORY} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_ORDER_HISTORY: `${ORDER_HISTORY} set_data`,
    CLEAR_ORDER_HISTORY: `${ORDER_HISTORY} clear`,
};

// reducer actions
export const setOrderHistory = (payload) => ({
    type: types.SET_ORDER_HISTORY,
    payload: payload,
});

export const clearOrderHistory = () => ({
    type: types.CLEAR_ORDER_HISTORY,
});

// middleware actions
export const getSingleOrder = (payload) => ({
    type: types.GET_SINGLE_ORDER,
    payload: payload,
});

export const getOrdersList = () => ({
    type: types.GET_ORDERS_LIST,
});
