// extracted by mini-css-extract-plugin
export var copy = "footer-module--copy--71Kr1";
export var created = "footer-module--created--x4HzO";
export var createdLink = "footer-module--created-link--6Q-rC";
export var createdLogo = "footer-module--created-logo--zusUm";
export var footer = "footer-module--footer--ef16N";
export var logo = "footer-module--logo--VkX-G";
export var nav = "footer-module--nav--xlccN";
export var post = "footer-module--post--62zj-";
export var social = "footer-module--social--2797v";
export var socialList = "footer-module--social-list--CUQSQ";