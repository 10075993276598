import React from 'react';

import { wrapper, btnTitle } from './action-buttons.module.scss';

import Button from '../../atoms/button';

const ActionButtons = ({ className = '', buttons }) => {
    return (
        <div className={`${wrapper} ${className}`}>
            {buttons.map((btn) => {
                const Icon = btn.icon;
                return (
                    <Button
                        key={`ac-btn-${btn.id}`}
                        onClick={btn.action}
                        noMargin
                        size="small"
                        color={btn.color}
                    >
                        {Icon && <Icon />}
                        <p className={btnTitle}>{btn.title}</p>
                    </Button>
                );
            })}
        </div>
    );
};

export default ActionButtons;
