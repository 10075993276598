import React from 'react';
import PropTypes from 'prop-types';

import { icon, wrapper, item, centered, grey, white } from './metadata.module.scss';

const Metadata = ({ metaItems, className, color, centeredStyle }) => {
    return metaItems.length > 0 ? (
        <div
            className={`${wrapper} 
                ${className} 
                ${colorClasses[color]}
                ${centeredStyle && centered}`}
        >
            {metaItems.map(({ Icon, data }, index) => {
                return (
                    <div className={item} key={`card-meta-${index}`}>
                        <Icon className={icon} />
                        {data}
                    </div>
                );
            })}
        </div>
    ) : null;
};

const colorClasses = {
    grey: grey,
    white: white,
};

Metadata.propTypes = {
    metaItems: PropTypes.arrayOf(
        PropTypes.shape({
            Icon: PropTypes.func,
            data: PropTypes.string,
        })
    ),
    className: PropTypes.string,
    color: PropTypes.oneOf(['white', 'grey']),
};

Metadata.defaultProps = {
    metaItems: [],
    className: '',
    color: 'white',
};

export default Metadata;
