const moduleName = `[ADDITIONAL ATTACHMENTS TO SEND]`;

export const actions = {
    SET_DATA: `${moduleName} set Data`,
    REMOVE_PILL: `${moduleName} remove pill`,
    CLEAR_PILLS: `${moduleName} clear pills`,
};

export const setAdditionalAttachmentsToSend = (payload) => {
    return {
        type: actions.SET_DATA,
        payload,
    };
};

export const removeAttachmentPillByIndex = (index) => ({
    type: actions.REMOVE_PILL,
    payload: index,
});

export const clearAttachmentsPill = () => ({
    type: actions.CLEAR_PILLS,
});
