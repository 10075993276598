import { types } from './shopping-lists.actions';

const { SET_SHOPPING_LISTS, RESTORE_INITIAL_STATE } = types;

export const initialState = {
    pages: {},
    pagination: {
        currentPage: 1,
        pageCount: 0,
        perPage: 10,
        totalCount: 0,
    },
};

const shoppingListsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_SHOPPING_LISTS:
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [payload.pagination.currentPage]: payload.items,
                },
                pagination: payload.pagination,
            };
        case RESTORE_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
};

export default shoppingListsReducer;
