import { types } from './diet-types.actions';

const { SET_DIET_TYPES, CLEAR_DIET_TYPES } = types;

const initialState = {
    items: [],
    pagination: null,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_DIET_TYPES:
            return payload;
        case CLEAR_DIET_TYPES:
            return initialState;
        default:
            return state;
    }
};
