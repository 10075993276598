import React from 'react';
import PropTypes from 'prop-types';

import { container } from './recipe-search-list.module.scss';

import RecipeSearchItem from '../../atoms/recipe/recipe-search-item';

const RecipeSearchList = ({ className = '', recipes, onRecipeSelect }) => {
    return (
        <div className={`${container} ${className}`}>
            {recipes.map((recipe) => {
                return (
                    <div key={`recipe-search-item-${recipe.id}`}>
                        <RecipeSearchItem
                            key={`recipe-search-item-${recipe.id}`}
                            recipe={recipe}
                            onRecipeSelect={onRecipeSelect}
                        />
                    </div>
                );
            })}
        </div>
    );
};

RecipeSearchList.propTypes = {
    className: PropTypes.string,
    recipes: PropTypes.array,
    onRecipeSelect: PropTypes.func,
};

RecipeSearchList.defaultProps = {
    className: '',
    recipes: [],
};

export default RecipeSearchList;
