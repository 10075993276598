// extracted by mini-css-extract-plugin
export var addIngredientButton = "recipe-add-module--add-ingredient-button--0C2r1";
export var addRecipe = "recipe-add-module--add-recipe--+gBmF";
export var addStepButton = "recipe-add-module--add-step-button--7P7hO";
export var content = "recipe-add-module--content--6D47o";
export var counter = "recipe-add-module--counter--XDwWu";
export var description = "recipe-add-module--description--rlf6G";
export var form = "recipe-add-module--form--gIpJf";
export var formGroup = "recipe-add-module--form-group--O34xw";
export var globalErrors = "recipe-add-module--global-errors--lQzEf";
export var hero = "recipe-add-module--hero--0eHuE";
export var ingredients = "recipe-add-module--ingredients--knfyv";
export var inputBox = "recipe-add-module--input-box--Zi16s";
export var loading = "recipe-add-module--loading--R+LoR";
export var preparations = "recipe-add-module--preparations--CQwal";
export var success = "recipe-add-module--success--HbqJO";
export var successButtons = "recipe-add-module--success-buttons--ZHtyI";
export var successContent = "recipe-add-module--success-content--Jl1s5";
export var switcher = "recipe-add-module--switcher--h7r+h";
export var title = "recipe-add-module--title--JulSz";