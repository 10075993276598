// extracted by mini-css-extract-plugin
export var banner = "exercise-page-module--banner--hZZKA";
export var bannerContent = "exercise-page-module--bannerContent--bdnxa";
export var cluesBox = "exercise-page-module--clues-box---TREO";
export var description = "exercise-page-module--description--Zm7Gt";
export var loader = "exercise-page-module--loader--IhwbS";
export var relatedExercises = "exercise-page-module--relatedExercises--R-hD4";
export var relatedSeparator = "exercise-page-module--relatedSeparator--6KEkw";
export var seeAllButton = "exercise-page-module--seeAllButton--kWrU1";
export var separator = "exercise-page-module--separator--7pLpY";
export var video = "exercise-page-module--video--Rtqjw";
export var wrapper = "exercise-page-module--wrapper--Zh2dR";